import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  img {
    width: 100%;
    border-radius: 8px;
  }

  a:last-child {
    display: none;
  }

  @media (max-width: 768px) {
    a:first-child {
      display: none;
    }

    a:last-child {
      display: block;
    }
  }
`;
export const Skeleton = styled.div`
  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  width: 100%;
  aspect-ratio: 45 / 7;
  animation: 1.5s shine linear infinite;

  @keyframes shine {
    to {
      background-position-x: -200%;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    aspect-ratio: 9/5;
  }
`;
