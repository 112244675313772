import styled from "styled-components";

export const PrimaryButtonContainer = styled.span`
  display: flex;
  gap: 16px;
`;

export const ModalArea = styled.div`
  position: fixed;
  top: 50%;
`