import * as React from 'react';

export function MedtrackIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
      <rect width={24} height={24} fill="#35BD78" rx={12} />
      <path
        fill="#fff"
        d="M12 12.656a.656.656 0 1 0 0-1.312.656.656 0 0 0 0 1.312Z"
      />
      <path
        fill="#fff"
        d="M12 6.313A5.694 5.694 0 0 0 6.312 12 5.694 5.694 0 0 0 12 17.688 5.694 5.694 0 0 0 17.688 12 5.694 5.694 0 0 0 12 6.312Zm2.873 3.098-1.282 3.205a1.75 1.75 0 0 1-.975.975l-3.205 1.282a.219.219 0 0 1-.284-.284l1.282-3.205a1.75 1.75 0 0 1 .975-.975l3.205-1.282a.219.219 0 0 1 .284.284Z"
      />
    </svg>
  );
}
