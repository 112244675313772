import { apiGet } from "./helpers/api";
import { normalize, schema } from "normalizr";

const report = new schema.Entity("reports");

export const fetchReports = (dispatch, classroomId) => {
  if (!classroomId) return;
  dispatch({ type: "REPORT_FETCH_REQUESTED" });
  apiGet(`/api/v1/classrooms/${classroomId}/reports`)
    .then((response) => {
      dispatch({
        type: "REPORT_FETCHED",
        ...normalize(response.body.data, report),
      });
    })
    .catch((err) => {
      dispatch({ type: "REPORT_FETCH_ERROR" });
    });
};

export const selectedClassroomIdChanged = (dispatch, classroomId) =>
  dispatch({ type: "SELECTED_CLASSROOM_CHANGED", classroomId });
