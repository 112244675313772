import React, { useContext } from 'react'
import { Context } from '../context';
import { Container, LessonsCount, ProgressBar, ProgressContainer, ProgressWrapper, Skeleton, Title, TitleWrapper } from "./styles";

export function Progress() {
  const { lessonsUser, loading, } = useContext(Context)

  return loading ? <Skeleton />
    : (
      <>
        {lessonsUser ?
          <Container>
            <TitleWrapper>
              <Title>{lessonsUser.classroom.name}</Title>
              <LessonsCount>{`${lessonsUser.classroom.lessonsWatchedCount}/${lessonsUser.classroom.lessonsCount}`} Aulas</LessonsCount>
            </TitleWrapper>
            <ProgressWrapper>
              <ProgressContainer>
                <ProgressBar value={Math.round(lessonsUser.classroom.progress * 100)} />
              </ProgressContainer>
              <p>{Math.round(lessonsUser.classroom.progress * 100)}%</p>
            </ProgressWrapper>
          </Container>
          : <></>
        }
      </>
    )
}