import React, { useEffect, useState } from "react";
import QuestionNavigationContainer from "./QuestionNavigationContainer";
import QuestionPaginationContainer from "./QuestionPaginationContainer";
import { Label } from "semantic-ui-react";
import Video from "../library/Video";
import QuestionRatingFormContainer from "./QuestionRatingFormContainer";
import { QuestionAlternativesList } from "./QuestionAnswersAlternatives";
import { QuestionDifficultyLevel } from "./QuestionDifficultyLevel";
import { DifficultyWrapper } from "./QuestionDifficultyLevel/styles";
import { EMRTip } from "./EMRTip";
import { RightAnswerBadge } from "./RightAnswerBadge";
import {
  extraInfoStyle,
  formWrapper,
  labelStyle,
  numberClass,
  questionsComments,
  videoWrapper,
} from "./QuestionStyles";
import QuestionDescription from "./QuestionDescription";

const getDifficulty = (difficultyLevel) => {
  if (difficultyLevel == "easy") {
    return "Fácil";
  }
  if (difficultyLevel == "medium") {
    return "Média";
  }
  if (difficultyLevel == "hard") {
    return "Difícil";
  }
};

export default function Question(props) {
  const {
    question,
    isFinished,
    isExam,
    showAnswer,
    index,
    mock,
    showQuestionExtraInfo,
    showQuestionVideoUrl,
  } = props;
  const [answer, setAnswer] = useState(null);
  const difficulty = getDifficulty(question?.difficultyLevel);

  /**
   *
   * @param {string} extraInfo Field returned from question, which contains alternatives and also, which one is correct.
   */
  const parseResultFromExtraInfo = (extraInfo) => {
    if (!question.isCommentTemplate) setAnswer(null);
    const regex = /Resposta:\s*([A-E])/;
    const result = extraInfo.match(regex);
    if (result) {
      setAnswer(result[1]);
    }
  };

  useEffect(() => {
    if (question && question.extraInfo) {
      parseResultFromExtraInfo(question?.extraInfo);
    }
  }, [question]);

  return question ? (
    <>
      {question?.cancelled && (
        <Label size="small" style={labelStyle} color="red">
          Anulada
        </Label>
      )}
      <h4 className={numberClass}>Questão {index + 1}</h4>

      <QuestionDescription
        key={question.id}
        questionId={question.id}
        description={question.title}
        questionType={question.questionType}
        isFinished={isFinished}
        isExam={isExam}
        mockId={mock.id}
      />

      <QuestionNavigationContainer isFinished={isFinished} isExam={isExam} />

      {showAnswer && (
        <>
          {difficulty && (
            <DifficultyWrapper>
              <QuestionDifficultyLevel
                difficultyLevel={question.difficultyLevel}
              />
            </DifficultyWrapper>
          )}

          {(showQuestionVideoUrl || showQuestionExtraInfo) &&
            question.videoUrl && (
              <div className={videoWrapper}>
                <h3 className={questionsComments}>Comentário da Questão</h3>
                {showQuestionVideoUrl && (
                  <Video isQuestion url={question.videoUrl} />
                )}
                {question?.hint && (
                  <EMRTip text={question.hint} title="Dica EMR" />
                )}

                {showQuestionExtraInfo && question?.extraInfo && (
                  <>
                    {question?.isCommentTemplate ? (
                      <QuestionAlternativesList
                        isQuestionInverted={question?.isCommentInverted}
                        text={question.extraInfo}
                      />
                    ) : (
                      <p style={extraInfoStyle}>{question?.extraInfo}</p>
                    )}

                    {question?.isCommentTemplate && answer && (
                      <RightAnswerBadge rightAnswer={`Alternativa ${answer}`} />
                    )}
                    <div className={formWrapper}>
                      <QuestionRatingFormContainer
                        form={`ratingQuestion${question.id}`}
                        questionId={question.id}
                      />
                    </div>
                  </>
                )}
              </div>
            )}
        </>
      )}

      <QuestionPaginationContainer isFinished={isFinished} isExam={isExam} />
    </>
  ) : null;
}
