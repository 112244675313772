import React, { useEffect, useState } from "react"
import { apiPost } from "../../../processes/helpers/api"
import { EMRButton } from "../../Button"
import Input from "../../Input"
import Template from "../template"
import { ButtonWrapper, Subtitle, Title } from './styles'
import { useHistory } from "react-router-dom"
import { getThemeByUniversity } from "../../../utils/getThemeByUniversity"
import { trackClickEvents } from "../../../../services/mixpanel/events/trackClickEvents"

function ForgotPassword() {
  const { IES } = getThemeByUniversity();
  const [email, setEmail] = useState('')
  const [emailSentDate, setEmailSentDate] = useState(null)
  const [secondsPassed, setSecondsPassed] = useState(0)
  const [errorMessage, setErrorMessage] = useState(null)
  const [alreadySent, setAlreadySent] = useState(false)

  const SECONDS_TO_RETRY = 60

  const isEmailSent = !!emailSentDate

  const isButtonDisable = isEmailSent

  const history = useHistory()

  function handleSendEmail() {
    trackClickEvents({eventName: `Clique em ${alreadySent ? 'Enviar novamente' : 'Enviar'} (Tela esqueci minha senha)`})
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    const isValidEmail = emailRegex.test(email)

    if (!isValidEmail) {
      setErrorMessage('E-mail inválido')
      return
    }

    recoveryPassword()
    setEmailSentDate(new Date())
    setAlreadySent(true)
    setSecondsPassed(0)
  }

  const recoveryPassword = () => {
    apiPost("/api/v1/auth/password", false, true)
      .send({
        email: email,
        redirectUrl: `https://${window.location.host}/reset-password-v2`,
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    let intervalId = null
    if (secondsPassed < SECONDS_TO_RETRY && emailSentDate) {
      intervalId = setInterval(() => {
        const secondsPassed = Math.floor((new Date() - emailSentDate) / 1000)
        setSecondsPassed(secondsPassed)
      }, 1000)
    } else {
      setSecondsPassed(0)
      setEmailSentDate(null)
      clearInterval(intervalId)
    }

    return () => clearInterval(intervalId)
  }, [emailSentDate, secondsPassed, SECONDS_TO_RETRY, setSecondsPassed])

  return (
    <Template>
      <Title>
        {isEmailSent ? 'E-mail enviado com sucesso!' : 'Esqueceu sua senha?'}
      </Title>
      <Subtitle>
        {isEmailSent
          ? 'Caso não tenha recebido o e-mail, tente novamente:'
          : 'Sem problemas, insira seu e-mail que enviaremos as instruções para recuperar sua senha.'
        }
      </Subtitle>
      {!isEmailSent &&
        <Input
          label='E-mail*'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={errorMessage}
        />
      }
      <ButtonWrapper>
        <EMRButton
          fullSized
          disabled={isButtonDisable}
          onClick={handleSendEmail}
        >
          {
            isButtonDisable
              ? `Aguarde ${SECONDS_TO_RETRY - secondsPassed} segundos`
              : alreadySent ? 'Enviar novamente' : 'Enviar'
          }
        </EMRButton>

        <EMRButton
          fullSized
          mode='outline'
          onClick={() => {
            const param = IES === 'revalida' ? '?ies=revalida' : ''
            history.push(`signin-v2${param}`)
          }}
        >
          Voltar
        </EMRButton>
      </ButtonWrapper>
    </Template>
  )
}

export default ForgotPassword