import { apiGet, apiPost, apiPut } from "./helpers/api";
import { normalize, schema } from "normalizr";

const notificationsUserSchema = new schema.Entity("notificationsUsers");

export const fetchNotificationsUser = (dispatch) => {
  return apiGet("/api/v1/notifications_users").then((response) => {
    dispatch({
      type: "NOTIFICATIONS_USERS_FETCHED",
      ...normalize(
        response.body.data,
        new schema.Array(notificationsUserSchema)
      ),
    });
  });
};

export const createNotificationsUser = (params, dispatch) => {
  const {
    notification: { id },
    ...rest
  } = params;

  return apiPost(`/api/v1/notifications_users`)
    .send({ notification_id: id, ...rest })
    .then((response) =>
      dispatch({
        type: "NOTIFICATIONS_USER_CREATED",
        ...normalize(response.body.data, notificationsUserSchema),
      })
    );
};

export const updateNotificationsUser = (params, dispatch) => {
  const {
    notificationsUser: { id },
    ...rest
  } = params;

  return apiPut(`/api/v1/notifications_users/${id}`)
    .send(rest)
    .then((response) =>
      dispatch({
        type: "NOTIFICATIONS_USER_UPDATED",
        ...normalize(response.body.data, notificationsUserSchema),
      })
    );
};
