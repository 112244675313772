import React from "react";
import { Box, BoxContent } from "./styles";
import { IoBulb } from "react-icons/io5";

export function InfoBox() {
  return (
    <Box>
      <IoBulb size={24} />
      <BoxContent>
        <h3>Vem aí o Medtrack 2.0 em 2025!</h3>
        <p>
          Com atividades diárias, foco na priorização por relevância e um novo
          modo agenda, o Medtrack 2.0 está chegando para você, se prepare para
          avançar rumo a aprovação.
        </p>
      </BoxContent>
    </Box>
  );
}
