export const ACTIVE_SCREEN = {
  CODE_VALIDATION: 'CODE_VALIDATION',
  QR_CODE_VALIDATION: 'QR_CODE_VALIDATION',
  DEVICE_VALIDATED: 'DEVICE_VALIDATED',
  // ----  abaixo esta sem utilização
  INVALID_FINGERPRINT: 'INVALID_FINGERPRINT',
  VALIDATE_BROWSER: 'VALIDATE_BROWSER',
  REDIRECT_TO_APP: 'REDIRECT_TO_APP',
  REGISTER_DEVICE: 'REGISTER_DEVICE',
  UNAUTHORIZED_ACCESS: 'UNAUTHORIZED_ACCESS',
  ALTERNATIVE_ACCESS: 'ALTERNATIVE_ACCESS',
  LOADING: 'LOADING',
};

// ----  abaixo esta sem utilização

export const REGISTER_DEVICE_STEPS = {
  WELCOME: 'WELCOME',
  REGISTER_NEW_DEVICE: 'REGISTER_NEW_DEVICE',
  SECURITY_CONFIRMATION: 'SECURITY_CONFIRMATION',
  SUCCESS: 'SUCCESS',
};

export const CODE_VALIDATION_SCREEN_TYPE = {
  IP_VALIDATION: 'IP_VALIDATION',
  DEFAULT: 'DEFAULT',
};

export const CODE_VALIDATION_ACTION_TYPE = {
  TEMPORARY_ACCESS: 'TEMPORARY_ACCESS',
};

export const EMERGENCY_ACCESS_MODAL_TYPE = {
  DEFAULT: 'DEFAULT',
  ALREADY_ASKED: 'ALREADY_ASKED',
};

export const TEMPORARY_ACCESS_DAY = {
  '1 dia': 1,
  '3 dias': 3,
  '5 dias': 5,
};
