import React from "react";
import {
  Container,
  Description,
  TitleContainer,
  SubjectTag,
  Content
} from "./styles";
import { Button } from "semantic-ui-react";

export function MockRevisionReport({
  title,
  impact,
  buttonDisabled,
  setModalOpen,
}) {
  const getImpact = () => {
    if (impact == "LOW") return "Baixo impacto";
    if (impact == "MEDIUM") return "Médio impacto";
    if (impact == "HIGH") return "Alto impacto";
  };

  function handleOpenAndCloseModal() {
    setModalOpen((prevState) => !prevState);
  }
  return (
    <Container>
      <Content>
        <TitleContainer>
          <h3>{title}</h3>
          <SubjectTag mode={getImpact()}>{getImpact()}</SubjectTag>
        </TitleContainer>
        <Description>Agende revisões personalizadas para esta aula</Description>
      </Content>

      <Button
        secondary
        className="cancelButton"
        disabled={buttonDisabled}
        onClick={handleOpenAndCloseModal}
      >
        <span style={{ fontSize: 16, fontWeight: 500 }}>Agendar revisões</span>
      </Button>
    </Container>
  );
}
