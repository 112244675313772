import { statusConstants } from "../utils/statusConstants";

const initialState = {
  status: "idle",
  selectedClassroomId: null,
};

export default function reportsUtilsReducer(state = initialState, action) {
  switch (action.type) {
    case "REPORT_FETCH_REQUESTED":
      return { ...state, status: statusConstants.LOADING };
    case "REPORT_FETCHED":
      return {
        ...state,
        status: statusConstants.RESOLVED,
      };
    case "SELECTED_CLASSROOM_CHANGED":
      return {
        ...state,
        selectedClassroomId: action.classroomId,
        status: statusConstants.LOADING,
      };
    case "REPORT_FETCH_ERROR":
      return {
        ...state,
        status: statusConstants.ERROR,
      };
    default:
      return state;
  }
}
