import React from "react";
import { BsArrowRightShort } from "react-icons/bs";
import { Backdrop, Image, Info, Link, Modal, Title } from "./styles";

export function Warning({ onRedirect }) {
  function redirect() {
    onRedirect();
  }

  return (
    <Backdrop visible>
      <Modal>
        <Image />
        <Title>Seu tempo de teste grátis acabou.</Title>
        <Info>Seja nosso aluno e se matricule clicando no botão abaixo.</Info>
        <Link onClick={redirect}>
          Faça sua Matrícula <BsArrowRightShort size={32} />{" "}
        </Link>
      </Modal>
    </Backdrop>
  );
}
