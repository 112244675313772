import { LOCATION_CHANGE } from "connected-react-router";
import { matchPath } from "react-router";

const initialState = {
  currentMockId: null,
  currentIndex: 0,
  selectedAlternatives: {},
  questionMarkedAsDoubtIds: [],
  questionsMarkedAsFavoriteIds: [],
  preferences: {
    showQuestionExtraInfo: true,
    showQuestionVideoUrl: true,
    showCorrectAnswer: true
  },
  activeMockTabName: "Meus Simulados",
  isHighlighterActive: false,
  highlightColor: "yellow",
  clearHighlightsTriggered: false,
  hasStoredHighlights: false,
};

export default function mockReducer(state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      const match = matchPath(action.payload.location.pathname, {
        path: "/mocks/:mockId"
      });
      const currentMockId =
        match && match.params.mockId && Number(match.params.mockId);

      return { ...initialState, currentMockId };
    case "CURRENT_MOCK_CHANGED":
      return { ...initialState, currentMockId: action.payload.id };
    case "CURRENT_INDEX_CHANGED":
      if (state.currentIndex === action.index) return state;
      return {
        ...state,
        currentIndex: action.index
      };
    case "QUESTION_AS_FAVORITE_ADDED":
      if (state.questionsMarkedAsFavoriteIds.includes(action.questionId)) return state;

      return {
        ...state,
        questionsMarkedAsFavoriteIds: [
          ...state.questionsMarkedAsFavoriteIds,
          action.questionId
        ]
      };
    case "REMOVE_QUESTION_AS_FAVORITE":
      return {
        ...state,
        questionsMarkedAsFavoriteIds: state.questionsMarkedAsFavoriteIds.filter(
          (id) => id !== action.questionId
        )
      };
    case "FETCH_FAVORITES_QUESTIONS":
      if (state.questionsMarkedAsFavoriteIds === action.questionIds) return state;
      return {
        ...state,
        questionsMarkedAsFavoriteIds: action.questionIds
      };
    case "ALTERNATIVE_SELECTED":
      if (state.selectedAlternatives[action.alternative.questionId] === action.alternative) return state;
      return {
        ...state,
        selectedAlternatives: {
          ...state.selectedAlternatives,
          [action.alternative.questionId]: action.alternative
        }
      };
    case "MOCK_RESETTED":
      return initialState;
    case "MOCK_PREFERENCE_CHANGED":
      return {
        ...state,
        preferences: {
          ...state.preferences,
          [action.preference]: !state.preferences[action.preference]
        }
      };
    case "SET_ACTIVE_MOCK_TAB_NAME":
      if (state.activeMockTabName === action.activeMockTabName) return state;
      return {
        ...initialState,
        activeMockTabName: action.activeMockTabName
      };
    case "MOCK_QUESTION_TOGGLE_HIGHLIGHTER":
      if (state.isHighlighterActive === action.isHighlighterActive) return state;
      return {
        ...state,
        isHighlighterActive: action.isHighlighterActive,
      };
    case "MOCK_QUESTION_CHANGE_HIGHLIGHTER_COLOR":
      if (state.highlightColor === action.highlightColor) return state;
      return {
        ...state,
        highlightColor: action.highlightColor,
      };
    case "MOCK_QUESTION_CLEAR_HIGHLIGHTS":
      if (state.clearHighlightsTriggered === action.clearHighlightsTriggered) return state;
      return {
        ...state,
        clearHighlightsTriggered: action.clearHighlightsTriggered,
      };
    case "MOCK_QUESTION_CHECK_FOR_STORED_HIGHLIGHTS":
      if (state.hasStoredHighlights === action.hasStoredHighlights) return state;
      return {
        ...state,
        hasStoredHighlights: action.hasStoredHighlights,
      };
    default:
      return state;
  }
}
