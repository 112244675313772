import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import SurveyModal from "./SurveyModal";
import { getActiveSurvey } from "../../selectors/surveysSelectors";
import { getCurrentUser } from "../../selectors/userSelectors";

function SurveyModalContainer(props) {
  const { survey, currentUser } = props;
  const [openModal, setOpenModal] = useState(false);

  const closeModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  useEffect(() => {
    survey && setOpenModal(true);
  }, [survey]);

  return survey && currentUser ? (
    <SurveyModal
      survey={survey}
      openModal={openModal}
      closeModal={closeModal}
      {...props}
    />
  ) : null;
}

const mapStateToProps = (state) => {
  return {
    currentUser: getCurrentUser(state),
    survey: getActiveSurvey(state),
  };
};

export default connect(mapStateToProps)(SurveyModalContainer);
