import React from "react";
import { Container } from "./styles";
export function AlertCircleOutline({ color }) {
  function getColor() {
    if (color == "Alto impacto") return "#FF5B5B";
    if (color == "Médio impacto") return "#E9C610";
    if (color == "Baixo impacto") return "#49B1EB";
  }

  return (
    <Container>
      <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M35 20C35 11.7188 28.2812 5 20 5C11.7188 5 5 11.7188 5 20C5 28.2812 11.7188 35 20 35C28.2812 35 35 28.2812 35 20Z"
          stroke={getColor()}
          stroke-width="2"
          stroke-miterlimit="10"
        />
        <path
          d="M19.5515 12.9727L19.9999 22.5L20.4476 12.9727C20.4503 12.9118 20.4407 12.8509 20.4191 12.7939C20.3976 12.7369 20.3646 12.6848 20.3223 12.6409C20.28 12.5971 20.2291 12.5623 20.1729 12.5387C20.1167 12.5152 20.0562 12.5033 19.9952 12.5039V12.5039C19.935 12.5045 19.8755 12.5172 19.8203 12.5413C19.765 12.5653 19.7152 12.6003 19.6738 12.644C19.6324 12.6878 19.6002 12.7394 19.5792 12.7959C19.5582 12.8524 19.5488 12.9125 19.5515 12.9727V12.9727Z"
          stroke={getColor()}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M20 28.743C19.691 28.743 19.3889 28.6513 19.1319 28.4796C18.875 28.3079 18.6747 28.0639 18.5564 27.7784C18.4382 27.4929 18.4072 27.1787 18.4675 26.8756C18.5278 26.5725 18.6766 26.2941 18.8951 26.0756C19.1137 25.8571 19.3921 25.7083 19.6952 25.648C19.9983 25.5877 20.3124 25.6186 20.5979 25.7369C20.8835 25.8552 21.1275 26.0554 21.2992 26.3124C21.4709 26.5693 21.5625 26.8714 21.5625 27.1805C21.5625 27.5949 21.3979 27.9923 21.1049 28.2853C20.8118 28.5783 20.4144 28.743 20 28.743Z"
          fill={getColor()}
        />
      </svg>
    </Container>
  );
}
