import React from "react";
import { Field } from "redux-form";
import { Checkbox } from "semantic-ui-react";
import memoize from "fast-memoize";
import buildSimpleFormField from "./buildSimpleFormField";

const FormField = buildSimpleFormField(Checkbox, (input, meta, rest) => ({
  ...input,
  invalid: meta.touched && meta.invalid,
  ...rest,
}));

const buildValidator = memoize((required, other) => {
  return [(value) => (required && !value ? "required" : undefined)].concat(
    other || []
  );
});

export default function CheckBoxField(props) {
  return (
    <Field
      {...props}
      component={FormField}
      validate={buildValidator(props.required, props.validate)}
    />
  );
}
