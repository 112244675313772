import styled from "styled-components";
import { colors } from "../../../../components/library/theme";
import { tagVariants } from "../TypeTag";

export const Container = styled.div`
  cursor: ${({ isLocked }) => (isLocked ? "default" : "pointer")};
  align-items: center;
  border-bottom: solid 1px ${colors.medtrack.grey100};
  border-top: ${({ hasTopBorder }) =>
    hasTopBorder ? `solid 1px ${colors.medtrack.grey100}` : "none"};
  display: flex;
  padding-left: 8px;

  &:hover {
    background: ${colors.medtrack.grey000};
  }

  @media (max-width: 991px) {
    position: relative;
  }
`;
export const Bar = styled.div`
  background-color: ${({ isLocked, category }) =>
    isLocked ? colors.interface.grey200 : tagVariants[category].textColor};
  border-radius: 8px;
  width: 4px;
  min-width: 4px;
`;
export const TaskName = styled.p`
  font-size: 14px;
  font-weight: 600;
  margin-right: 40px;
  line-height: 21px;
  width: 100%;
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ isLocked }) =>
    isLocked ? colors.interface.grey400 : colors.interface.grey400};

  @media (max-width: 1040px) {
    margin: 0;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media (max-width: 640px) {
    width: 100%;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media (max-width: 460px) {
    width: 60%;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
export const ArrowButton = styled.button`
  cursor: pointer;
  margin-left: auto;
  width: 32px;
  min-width: 32px;
  height: 32px;
  background-color: ${colors.interface.grey200};
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  padding: 0;

  @media (max-width: 991px) {
    height: 24px;
    width: 24px;
    min-width: 24px;
    position: absolute;
    right: 8px;

    svg {
      width: 16px;
      height: 16px;
    }
  }
`;

export const LockedButton = styled.div`
  margin-left: auto;
  width: 32px;
  min-width: 32px;
  height: 32px;
  background-color: ${colors.interface.grey100};
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  padding: 0;
  transition: colors 300ms;

  @media (max-width: 991px) {
    height: 24px;
    width: 24px;
    min-width: 24px;
    position: absolute;
    right: 8px;

    svg {
      width: 16px;
      height: 16px;
    }
  }
`;

export const StyledDescription = styled.span`
  display: block;
  color: ${colors.interface.grey300};
  font-size: 12px;

  @media (max-width: 740px) {
    display: ${(props) => (props.isRevision ? "block" : "none")};
  }
`;
export const StyledMobileDescription = styled.span`
  color: ${colors.interface.grey300};
  font-size: 12px;
  line-height: 18px;

  @media (max-width: 740px) {
    display: block;
  }
`;
export const InfosWrapper = styled.div`
  display: flex;
  align-items: center;
  min-width: ${({ isRevision }) => (isRevision ? "430px" : "410px")};

  @media (max-width: 1440px) {
    min-width: ${({ isRevision }) => (isRevision ? "330px" : "330px")};
  }

  @media (max-width: 991px) {
    gap: 8px;
    max-width: fit-content;
  }
`;
export const Wrapper = styled.div`
  height: 64px;
  padding: 16px;
  padding-left: 8px;
  display: flex;
  align-items: center;
  flex-grow: 1;

  > .content {
    display: flex;
    width: 100%;

    @media (max-width: 767px) {
      flex-direction: column;

      > div:nth-child(2) {
        display: none;
      }

      > div:nth-child(3) {
        margin-left: 41px;
        margin-top: -4px;
      }
    }
  }

  @media (max-width: 991px) {
    min-height: 79px;
    height: auto;
    width: 100%;
    flex-grow: unset;
    padding: 16px 8px 16px 0px;
    overflow-x: hidden;
  }
`;
