import { Addbutton, Container, CustomInput, Item, List, Overflow } from './styles'
import React, { useEffect, useState } from 'react'

import reduceLength from './reduceLength'
import toStr from './toStr'

const Autocomplete = ({ data, defaultValue, label, placeholder, onNew, onSelect, limit, inputName, icon, iconPosition, clearValue, htmlfor }) => {
  const [shelf, setShelf] = useState(data)
  const [visible, setVisible] = useState(false)
  const [value, setValue] = useState(defaultValue)

  useEffect(() => {
    setShelf(data)
  }, [data])

  const handleChange = (e) => {
    const text = toStr((e.target).value)
    const filter = data.filter(obj => obj.name && toStr(obj.name).includes(text) && obj)

    setShelf(filter)
    setValue(text)
  }

  const handleBlur = () => setVisible(false)
  const handleFocus = () => setVisible(true)

  const handleClick = (obj) => {
    onSelect && onSelect(obj)
    setValue(obj.name.toString())
    setVisible(false)
  }

  useEffect(() => {
    if (clearValue === 0) {
      setValue(defaultValue)
    }
  }, [clearValue, defaultValue])

  return (
    <Container icon={icon} iconPosition={iconPosition}>
      {visible && <Overflow onClick={handleBlur} />}
      {label && <p>{label}</p>}

      <CustomInput name={inputName} onFocus={handleFocus} value={value} onChange={handleChange} placeholder={placeholder} isDropdownListVisible={visible} id={htmlfor} />

      <List visible={visible}>
        {data &&
          (data.length === 0 || shelf.length === 0 ? (
            <span>Nenhum resultado encontrado</span>
          ) : (
            reduceLength(shelf, limit).map((obj) => (
              <Item onClick={() => handleClick(obj)} key={obj.id}>
                {obj.name}
              </Item>
            ))
          ))}
        {onNew && <Addbutton onClick={onNew}>+ Adicionar assunto</Addbutton>}
      </List>
    </Container>
  )
}

export default Autocomplete
