import React from "react"

function ArrowDownIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="6"
      fill="none"
      viewBox="0 0 10 6"
    >
      <path
        stroke="#C2CED6"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M1.063 1.031L5 4.97 8.938 1.03"
      ></path>
    </svg>
  )
}

export default ArrowDownIcon