import React, { useState } from "react";
import {
  filterByPeriod,
  filterByStatus,
  filterByBigArea,
  filterByStartDate,
} from "./constants";
import { EMRButton } from "../../../Button";
import { IoFilter } from "react-icons/io5";
import { useMedTrack } from "../../hooks/useMedTrack";
import {
  ButtonContainer,
  DesktopFilterContainer,
  Filter,
  OpenFilterButton,
  Overlay,
} from "./styles";

export function DesktopFilter({
  onFilter,
  onChangeFilters,
  isR3CM,
  isRevision,
}) {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const { activeFilters, resetFilters, clearReset } = useMedTrack();

  function handleToggleFilters() {
    setIsFilterOpen((prev) => !prev);
    clearReset();
  }

  function handleSubmit(event) {
    event.preventDefault();
    onFilter();
    setIsFilterOpen(false);
  }

  function handleResetFilters(event) {
    event.preventDefault();
    resetFilters();
    setIsFilterOpen(false);
  }

  return (
    <DesktopFilterContainer isR3CM={isR3CM} isRevision={isRevision}>
      <OpenFilterButton mode="gray" onClick={handleToggleFilters}>
        <IoFilter size={24} />
        Filtro
      </OpenFilterButton>

      {isFilterOpen && (
        <>
          <Overlay onClick={() => setIsFilterOpen(false)} />

          <Filter onSubmit={handleSubmit}>
            <h1>Filtrar por</h1>

            <div>
              {!isR3CM && (
                <fieldset>
                  <h2>Grande área</h2>
                  {filterByBigArea.map((item, index) => (
                    <label htmlFor={item.id} key={index}>
                      <input
                        id={item.id}
                        type="radio"
                        name="bigArea"
                        value={item.value}
                        checked={activeFilters.bigArea === item.value}
                        onChange={(event) => onChangeFilters(event)}
                      />
                      {item.icon}
                      {item.label}
                    </label>
                  ))}
                </fieldset>
              )}

              {!isRevision && (
                <>
                  <fieldset>
                    <h2>Período</h2>
                    {filterByPeriod.map((item, index) => (
                      <label htmlFor={item.id} key={index}>
                        <input
                          id={item.id}
                          type="radio"
                          name="period"
                          value={item.value}
                          checked={activeFilters.period === item.value}
                          onChange={(event) => onChangeFilters(event)}
                        />
                        {item.label}
                      </label>
                    ))}
                  </fieldset>

                  <hr />

                  <fieldset>
                    <h2>Ordenação</h2>
                    {filterByStartDate.map((item, index) => (
                      <label htmlFor={item.id} key={index}>
                        <input
                          id={item.id}
                          type="radio"
                          name="startDate"
                          value={item.value}
                          checked={
                            activeFilters.orderByStartDate === item.value
                          }
                          onChange={(event) => onChangeFilters(event)}
                        />
                        {item.label}
                      </label>
                    ))}
                  </fieldset>
                </>
              )}

              <hr />

              <fieldset>
                <h2>Status</h2>
                {filterByStatus.map((item, index) => (
                  <label htmlFor={item.id} key={index}>
                    <input
                      id={item.id}
                      type="radio"
                      name="status"
                      value={item.value}
                      checked={activeFilters.status === item.value}
                      onChange={(event) => onChangeFilters(event)}
                    />
                    {item.label}
                  </label>
                ))}
              </fieldset>
            </div>

            <hr />

            <h3>
              Defina como deseja visualizar ou limpe para manter visualização
              padrão.
            </h3>

            <ButtonContainer>
              <EMRButton
                fontSize={"md"}
                fullSized
                mode="cancel"
                type="button"
                onClick={handleResetFilters}
              >
                Limpar filtro
              </EMRButton>

              <EMRButton
                mode={"standard"}
                fontSize={"md"}
                fullSized
                type="submit"
              >
                Aplicar filtro
              </EMRButton>
            </ButtonContainer>
          </Filter>
        </>
      )}
    </DesktopFilterContainer>
  );
}
