import { reduxForm, formValueSelector, change } from "redux-form";
import { getFormMessage } from "../../../selectors/formMessagesSelectors";
import {
  notifySubmitSucceeded,
  formChangedNotification,
  formSubmitFailedNotification,
} from "../../../processes/helpers/formMessages";

const setFormValue = (formName) => (dispatch) => (fieldName, value) => {
  return dispatch(change(formName, fieldName, value));
};

export default function buildForm(name) {
  return {
    getMessage: getFormMessage(name),
    getValue: formValueSelector(name),
    setValue: setFormValue(name),
    connect(options) {
      const {
        onChange,
        onSubmitFail,
        onSubmitSuccess,
        successMessage,
        defaultErrorMessage,
        ...rest
      } = options;
      return reduxForm({
        form: name,
        onSubmitSuccess(res, dispatch, props) {
          successMessage &&
            notifySubmitSucceeded(dispatch, name, successMessage);
          onSubmitSuccess && onSubmitSuccess(res, dispatch, error, props);
        },
        onSubmitFail(errors, dispatch, error, props) {
          const message =
            (errors || {})._error ||
            defaultErrorMessage ||
            (error || {}).message;
          message && dispatch(formSubmitFailedNotification(name, message));
          onSubmitFail && onSubmitFail(errors, dispatch, error, props);
        },
        onChange(values, dispatch, props, prevValues) {
          // Redux-form 8+ triggers onChange event when loads initialData
          // which will unnecessary call onChange callbacks
          if (Object.keys(prevValues).length !== 0) {
            dispatch(formChangedNotification(name));
            onChange && onChange(values, dispatch, props, prevValues);
          }
        },
        ...rest,
      });
    },
  };
}
