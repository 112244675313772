import React, { useState } from "react";
import Button from "../library/Button";
import Spinner from "../library/Spinner";
import MockContainer from "../mocks/MockContainer";
import { spacing, uiColors } from "../library/theme";
import { css } from "glamor";

const loadingClass = css({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  padding: spacing.l
});
const mockButtonClass = css({
  backgroundColor: `${uiColors.background} !important`
});
const mockWrapperClass = css({
  padding: spacing.l,
  display: "flex",
  justifyContent: "center",
  backgroundColor: uiColors.border
});

export default function LessonMock({
  mock,
  onLessonMockFinished,
  createMockHandler,
  loading
}) {
  if (loading)
    return (
      <div className={loadingClass}>
        <Spinner inline />
      </div>
    );

  const [isLoading, setIsloading] = useState(false);
  return mock ? (
    <MockContainer
      lessonMock
      mockParamsId={mock?.id}
      onLessonMockFinished={onLessonMockFinished}
    />
  ) : (
    <div className={mockWrapperClass}>
      <Button
        primary
        className={mockButtonClass}
        disabled={isLoading}
        onClick={() => createMockHandler(setIsloading)}
      >
        Iniciar Simulado
      </Button>
    </div>
  );
}
