import { connect } from "react-redux";
import buildForm from "../library/forms/buildForm";
import AnnotationsForm from "./AnnotationsForm";
import { createAnnotation } from "../../processes/annotationProcesses";

const form = buildForm("annotationForm");

function mapStateToProps(state, ownProps) {
  return {
    initialValues: {},
    lessonId: ownProps.lessonId,
    currentClassroomId: state.currentClassroomId,
  };
}

export default connect(mapStateToProps)(
  form.connect({
    onSubmit: createAnnotation,
  })(AnnotationsForm)
);
