import { createSelector } from "reselect";
import { statusConstants } from "../utils/statusConstants";
import { getAllClassrooms, getClassroomsLoading } from "./classroomSelectors";
import orderByDate from "../utils/orderByDate";

const getMocks = (state) => state.entities.mocks;
export const getMockId = (state) => state.mock.currentMockId;
const getAssociationId = (_, id) => id;
export const getMocksList = (state) => state.mocksList;
export const getMockPreferences = (state) => state.mock.preferences;

export const getMock = createSelector(
  getMocks,
  getMockId,
  (mocks, id) => mocks[id]
);

export const getMockFromMockTemplate = createSelector(
  getMocks,
  getAssociationId,
  (mocks, mockTemplateId) => {
    //This sort fn is a hack for temporary fix duplicate mock for a user for a mock_template bug.
    return Object.values(mocks)
      .filter((mock) => mock.mockTemplateId === mockTemplateId)
      .sort((a, b) => !!b.finishedAt - !!a.finishedAt)[0];
  }
);

export const canAccessMock = createSelector(
  getAllClassrooms,
  getClassroomsLoading,
  (classrooms, loading) => {
    if (loading) {
      return statusConstants.LOADING;
    }
    if (classrooms.some((classroom) => !!classroom?.mocksEnabled)) {
      return statusConstants.ALLOWED;
    }
    return statusConstants.DENY;
  }
);

export const makeMocksList = createSelector(
  getMocks,
  getMocksList,
  (mocks, list) => {
    return {
      ...list,
      data: list.ids.map((id) => mocks[id]).filter((item) => item),
    };
  }
);

export const makeMocksIntelligent = createSelector(
  getMocks,
  getMocksList,
  (mocks, list) => {
    return {
      ...list,
      data: list.ids.map((id) => mocks[id]).filter((item) => item),
    };
  }
);

export const getMockInProgress = createSelector(
  makeMocksList,
  (list) =>
    list.data
      .filter((mock) => mock.progress < 100 && mock.isSmartEvaluation === false)
      .sort(orderByDate("updatedAt"))[0]
);

export const getIntelligentMockInProgress = createSelector(
  makeMocksList,
  (list) =>
    list.data
      .filter((mock) => mock.progress < 100 && mock.isSmartEvaluation === true)
      .sort(orderByDate("updatedAt"))[0]
);

