import styled, { css } from "styled-components";
import { colors } from "../../components/library/theme";
import * as Dialog from "@radix-ui/react-dialog";

const statusColors = {
  "Alto impacto": css`
    background-color: ${colors.status.red000};
    color: ${colors.status.red300};
  `,
  "Médio impacto": css`
    background-color: ${colors.status.yellow000};
    color: ${colors.status.yellow200};
  `,
  "Baixo impacto": css`
    background-color: ${colors.status.blue100};
    color: ${colors.status.blue400};
  `
};

const boderColor = {
  "Alto impacto": css`
    border: 1px solid ${colors.status.red200};
  `,
  "Médio impacto": css`
    border: 1px solid ${colors.status.yellow100};
  `,
  "Baixo impacto": css`
    border: 1px solid ${colors.status.blue300};
  `
};

export const DialogOverlay = styled(Dialog.Overlay)`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: fixed;
  overflow: auto;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: rgba(54, 62, 70, 0.3);
  z-index: 10;
  padding: 0;
`;

export const DialogContent = styled(Dialog.Content)`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background-color: ${colors.interface.white000};
  margin: 94px 14px 32px 24px;
  padding: 32px 24px 24px 24px;

  @media only screen and (min-width: 992px) {
    width: 695px;
    margin: 64px auto 56px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 28px;

  @media (max-width: 768px) {
    margin-bottom: 16px;
  }
`;

export const DialogTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  height: 100%;

  h2 {
    color: ${colors.interface.grey400};
    font-size: 18px;
    line-height: 24px;
    margin: 0;

    @media (max-width: 435px) {
      width: 154px;
    }
    @media (max-width: 768px) {
      font-size: 16px;
    }
  }
  svg {
    color: ${colors.interface.grey200};
    height: 22px;
    width: 22px;
  }
`;

export const TooltipContainer = styled.div`
  position: relative;
  z-index: 30;
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    display: none;
  }
  :hover {
    > div {
      display: initial;
    }
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const DialogClose = styled(Dialog.Close)`
  cursor: pointer;

  svg {
    color: ${colors.interface.grey400};
  }
`;
export const ReviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Title = styled.p`
  font-size: 16px;
  line-height: 24x;
  color: ${colors.interface.grey500};

  span {
    text-transform: uppercase;
    font-weight: 600;
  }

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

export const RevisionBoxContainer = styled.div(
  ({ isReviewType }) => css`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
    margin-bottom: 24px;

    @media (min-width: 768px) {
      grid-template-columns: repeat(${isReviewType ? "3" : "3"}, 1fr);
    }

    p {
      font-size: 14px;
    }
  `
);

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  flex-direction: column-reverse;
  margin-top: 24px;

  .cancelButton,
  .confirmButton {
    width: 100%;
    height: 48px;
    font-weight: bold;
  }

  @media only screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

export const CancelButton = styled(Dialog.Close)`
  cursor: pointer;
`;

export const SuggestionRelevanceContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px;
  margin-top: 24px;
  margin-bottom: 24px;
  position: relative;
  border-radius: 4px;
  ${(props) => boderColor[props.mode]}
  header {
    h2 {
      text-transform: uppercase;
      font-size: 14px;
      color: ${colors.interface.grey400};
      line-height: 18px;
    }

    p {
      width: 80%;
      font-size: 14px;
      line-height: 21px;
      color: ${colors.interface.grey400};
    }
  }

  @media (max-width: 768px) {
    paddding-top: 32px;
    padding-inline: 16px;
    padding-bottom: 16px;

    margin-bottom: 18px;
  }
`;

export const SubjectTag = styled.div`
  width: fit-content;
  height: 24px;
  border-radius: 70px;
  font-size: 14px;

  font-weight: 600;
  padding: 2px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  ${(props) => statusColors[props.mode]}
`;

export const SubjectDescription = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
  gap: 22px;

  p {
    font-size: 16px;
    line-height: 24px;
    color: ${colors.interface.grey400};
    font-family: "Poppins", sans-serif;
  }

  @media (max-width: 768px) {
    gap: 20px;

    p {
      font-size: 14px;
    }
  }
`;

export const ReviewText = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: ${colors.interface.grey400};

  span {
    font-weight: 600;
  }
`;
