import { css } from 'glamor'
import {
  buttonColors,
  spacing,
  styles,
  typographyColors,
  uiColors
} from './theme'
const linkResetStyle = {
  textDecoration: 'none',
  color: buttonColors.primary,
  cursor: 'pointer'
}

export default function applyGlobalStyles() {
  css.global('body', {
    ...styles.defaultFontFamily,
    ...styles.bodyText
  })
  css.global('.pushable:not(body)', {
    transform: 'none'
  })

  css.global('h1, h2, h3, h4, h5, h6', {
    ...styles.defaultFontFamily,
    color: typographyColors.defaultTitle,
    fontWeight: '500',
    margin: 0,
    padding: 0
  })
  css.global('h1, h2, h3, h4, h5, h6 + p', {
    marginBottom: spacing.xs
  })
  css.global('p', {
    ...styles.defaultFontFamily,
    ...styles.bodyText,
    margin: 0,
    padding: 0
  })
  css.global('p + p', {
    marginBottom: spacing.xs
  })
  css.global('a', linkResetStyle)
  css.global('a:visited', linkResetStyle)
  css.global('a:focus', linkResetStyle)
  css.global('a:hover', linkResetStyle)
  css.global('a:active', linkResetStyle)

  // THEME OVERRIDES
  css.global('.ui.form input', {
    ...styles.defaultFontFamily
  })
  css.global('.ui.form input', {
    minHeight: spacing.xl
  })
  css.global('.ui.fluid.dropdown', {
    minHeight: spacing.xl,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  })
  css.global('.ui.fluid.dropdown>.dropdown.icon', {
    marginTop: 'auto',
    marginBottom: 'auto',
    paddingTop: '0.7em'
  })
  css.global('.ui.fluid.multiple.search.selection.dropdown>input.search', {
    minHeight: 'auto'
  })
  css.global('.ui.comments', {
    maxWidth: '100%'
  })
  css.global('.ui.comments .comment .comments', {
    ...styles.roundedCorners,
    marginTop: spacing.s,
    backgroundColor: uiColors.contentCards
  })
  css.global('.ui.comments .comment .text', {
    ...styles.bodyText,
    fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif"
  })
  css.global('.button', {
    fontWeight: '300 !important'
  })
  css.global('.ui.primary.button, .ui.primary.buttons .button', {
    backgroundColor: buttonColors.primary
  })
  css.global('.ui.primary.button:hover, .ui.primary.buttons .button:hover', {
    backgroundColor: buttonColors.primaryDark
  })
  css.global('.ui.secondary.button, .ui.secondary.buttons .button', {
    backgroundColor: buttonColors.secondary,
    color: buttonColors.primary,
    border: `1px solid ${buttonColors.primary}`
  })
  css.global(
    '.ui.secondary.button:hover, .ui.secondary.buttons .button:hover, ui.primary.button:active',
    {
      backgroundColor: buttonColors.primary
    }
  )
  css.global('.ui.primary.button:active, .ui.primary.button:focus', {
    backgroundColor: buttonColors.primary
  })
  css.global('.ui.secondary.button:active, .ui.secondary.button:focus', {
    backgroundColor: buttonColors.secondary,
    color: buttonColors.primary
  })
  css.global('.ui.progress .bar', {
    minWidth: 0
  })
  css.global('input', {
    fontFamily: "Poppins"
  })
}
