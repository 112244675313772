import React from 'react';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { colors } from '../../library/theme';
import { Container, Progress, ProgressText } from './styles';

export default function MockTemplateSmartExams({ value }) {
  const status = String(Math.floor(value * 100)).padStart(2, '0');

  return (
    <Container>
      <Progress>
        <CircularProgressbar
          value={value * 100}
          strokeWidth={16}
          styles={buildStyles({
            pathColor: colors.brand.pure,
            trailColor: colors.interface.grey100,
          })}
        />
      </Progress>
      <ProgressText>{status}%</ProgressText>
    </Container>
  );
}
