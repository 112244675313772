const getComments = (action) => action.entities.comments;

export default function commentsReducer(state = {}, action) {
  switch (action.type) {
    case "COMMENT_CREATED":
    case "COMMENTS_FETCHED":
      return { ...state, ...getComments(action) };
    default:
      return state;
  }
}
