import React, { useEffect } from "react";
import { connect } from "react-redux";
import { fetchSpecialitiesReport } from "../../processes/specialitiesProcesses";
import { fetchStatistics } from "../../processes/statisticsProcesses";
import { getReportSpecialities } from "../../selectors/specialitySelector";
import MyPerformanceSpeciality from "./MyPerformanceSpeciality";

function MyPerformanceSpecialityContainer(props) {
  const { dispatch } = props;

  useEffect(() => {
    fetchSpecialitiesReport(dispatch);
    fetchStatistics(dispatch, {
      statisticableTypeEq: "Speciality",
      keyEq: "value",
    });
  }, [dispatch]);

  return <MyPerformanceSpeciality {...props} />;
}

function mapStateToProps(state) {
  return {
    specialities: getReportSpecialities(state),
  };
}

export default connect(mapStateToProps)(MyPerformanceSpecialityContainer);
