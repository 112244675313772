const initialState = {
  subjectReportIds: [],
  loading: true,
};

const getSubjectReportsFetchedIds = (action) =>
  action.result ? action.result : initialState.subjectReportIds;

export default function subjectReportListReducer(state = initialState, action) {
  switch (action.type) {
    case "SUBJECT_REPORTS_FETCH_REQUESTED":
      return { ...state, loading: true };
    case "SUBJECT_REPORTS_FETCHED":
      return {
        ...state,
        subjectReportIds: getSubjectReportsFetchedIds(action),
        loading: false,
      };
    default:
      return state;
  }
}
