import styled from "styled-components";
import { colors } from "../../../components/library/theme";
import * as Dialog from "@radix-ui/react-dialog";

export const DialogOverlay = styled(Dialog.Overlay)`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: fixed;
  overflow: auto;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: rgba(54, 62, 70, 0.3);
  z-index: 10;
  padding: 0;
`;

export const DialogContent = styled(Dialog.Content)`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background-color: ${colors.interface.white000};
  padding: 32px 24px 24px 24px;
  width: min(600px, calc(100vw - 48px));
  margin: auto;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
`;

export const DialogTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 8px;

  h2 {
    color: ${colors.interface.grey500};
    font-size: 18px;
    margin: 0;
  }

  button {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: transparent;
    border: none;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s;

    svg {
      stroke: ${colors.interface.grey300};
      fill: ${colors.interface.grey300};
    }

    &:hover,
    &:active {
      background-color: ${colors.interface.grey100};
    }
  }
`;

export const DialogSubTitle = styled.span`
  color: ${colors.interface.grey600};
  font-size: 16px;
`;

export const SubtitleContainer = styled.div`
  color: ${colors.interface.grey400};
  font-size: 16px;

  strong,
  span {
    display: block;
    line-height: 24px;
  }
`;

export const DialogClose = styled(Dialog.Close)`
  cursor: pointer;

  svg {
    color: ${colors.interface.grey400};
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;

  button {
    width: 100%;
    height: 48px;
    font-weight: 600;
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column-reverse;
    width: 100%;

    button {
      width: 100%;
    }
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
`;
