import { createSelector } from "reselect";
import { orderByPosition } from "../utils/orderByPosition";
import { getLessonId } from "./lessonSelectors";

const getAttachments = (state) => state.entities.attachments;

export const getAttachmentsByLesson = createSelector(
  getLessonId,
  getAttachments,
  (lessonId, attachments) =>
    Object.values(attachments)
      .filter((attachment) => attachment.attachmentableId === lessonId)
      .sort(orderByPosition)
);
