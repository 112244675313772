import styled, { css } from "styled-components";
import { colors } from "../../../../components/library/theme";
import { tagVariants } from ".";

export const Tag = styled.div(
  ({ variant, isDisabled }) => css`
    display: flex;
    min-width: 93px;
    width: fit-content;
    height: 32px;
    padding: 0 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 58px;
    background-color: ${isDisabled
      ? colors.medtrack.grey100
      : tagVariants[variant].bgColor};

    span {
      font-size: 12px;
      line-height: 18px;
      color: ${isDisabled
        ? colors.medtrack.grey400
        : tagVariants[variant].textColor};
    }

    svg {
      flex-shrink: 0;
    }

    svg path {
      fill: ${isDisabled
        ? colors.medtrack.grey400
        : tagVariants[variant].colors.iconColor};
    }

    @media screen and (max-width: 991px) {
      min-width: 88px;
      width: fit-content;
    }

    @media (max-width: 640px) {
      order: 1;
      display: ${isDisabled ? "flex" : "none"};
    }
  `
);

export const DescriptionContainer = styled.div`
  margin-left: 24px;
  display: flex;
  gap: 4px;
  align-items: center;

  svg {
    flex-shrink: 0;
  }

  @media screen and (max-width: 740px) {
    display: ${(props) => (props.isRevision ? "flex" : "none")};
  }
`;
