import styled from 'styled-components'
import { colors } from '../../library/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-height: 70vh;
  padding-bottom: 80px;
`;

export const AnnotationsBySpecialtyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const SpecialtyCard = styled.div`
  background: ${colors.interface.white000};
  box-shadow: 0px 1px 4px rgba(16, 24, 40, 0.1), 0px 1px 3px rgba(16, 24, 40, 0.06);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  z-index: ${(props) => props.zIndex};
`;

export const SpecialtyTitleContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  background: ${colors.interface.grey000};
  align-items: center;
  border-top: ${(props) =>
    props.isFirstSpecialtySubject
      ? 'none'
      : `1px solid ${colors.interface.grey100}`};
  border-bottom: 1px solid ${colors.interface.grey100};
  border-radius: 4px 4px 0 0;
  padding: 24px;

  h3:first-letter,
  span:first-letter {
    text-transform: capitalize;
  }

  h3,
  span {
    line-height: 1.3;
    font-weight: 600;
    color: ${colors.interface.grey400};
  }

  h3 {
    font-size: 16px;
    margin: 0;
  }

  span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 12px;
    border-radius: 59px;
    background: ${colors.interface.grey100};
    padding: 8px 16px;
  }
`;

export const SubjectContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px 4px 0 0;
`;

export const MockQuestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-top: ${(props) =>
    props.isFirstQuestion ? 'none' : `1px solid ${colors.interface.grey200}`};
  padding: 24px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  flex-direction: row;
  gap: 14px;

  @media screen and (max-width: 580px) {
    button {
      padding: 0;
      justify-content: flex-start;
      white-space: nowrap;
      width: fit-content;
    }
  }
`;

export const MockTitle = styled.h4`
  font-size: 14px;
  line-height: 1.3;
  font-weight: 600;
  color: ${colors.interface.grey400};
  margin: 0;
`;

export const Divider = styled.div`
  height: 0px;
  border-top: 1px solid ${colors.interface.grey100};
`;

export const QuestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PlaceholderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  margin: 158px auto auto;

  @media (max-width: 640px) {
    margin: 56px auto auto;

    svg {
      width: 195px;
    }
  }
`;

export const PlaceholderDescription = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 1.3;
  text-align: center;
  color: ${colors.interface.grey300};
  max-width: 392px;

  @media (max-width: 640px) {
    font-size: 14px;
    max-width: 308px;
  }
`;