import React, { useEffect, useState } from "react";
import { medtrackApi } from "../../../../../services/medtrack";
import Dropdown from "./dropdown";
import { getBigAreaIcons } from "../../utils/getBigAreaIcons";
import { LatestUpdate } from "../ModalBigArea/styles";

export function BigAreaDropdown({
  selectedBigArea,
  setSelectedBigArea,
  isFirstAccess,
}) {
  const [bigAreaData, setBigAreaData] = useState();
  const [latestBigAreaUpdatedAt, setLatestBigAreaUpdatedAt] = useState(null);

  async function getLatestUpdatedAt() {
    if (isFirstAccess) return;
    const { body } = await medtrackApi.get("/big-areas/active-priority");
    const latestBigAreaSelected = body?.data;

    if (latestBigAreaSelected) {
      setSelectedBigArea({
        id: latestBigAreaSelected.bigArea.id,
        option: latestBigAreaSelected.bigArea.description,
        icon: getBigAreaIcons(latestBigAreaSelected.bigArea.name),
      });

      const formattedUpdatedAt = new Date(
        latestBigAreaSelected.priority.createdAt
      ).toLocaleDateString("pt-br", {
        day: "numeric",
        month: "long",
        year: "numeric",
      });
      setLatestBigAreaUpdatedAt(formattedUpdatedAt);
    }
  }

  useEffect(() => {
    if (!bigAreaData) {
      medtrackApi.get("/big-areas").then((bigAreasRes) => {
        const bigAreaState = bigAreasRes.body.data.reduce(
          (acc, curr) => {
            acc.options.push(curr.description);
            acc.ids.push(curr.id);
            acc.icons.push(getBigAreaIcons(curr.name));

            return acc;
          },
          {
            options: [],
            ids: [],
            icons: [],
          }
        );
        setBigAreaData(bigAreaState);
      });
    }
  }, [bigAreaData]);

  useEffect(() => {
    getLatestUpdatedAt();
  }, [isFirstAccess]);

  return (
    <>
      <Dropdown
        optionsIcons={bigAreaData?.icons}
        options={bigAreaData?.options}
        ids={bigAreaData?.ids}
        hideLabel
        placeholder="Escolha a grande área"
        selectedIcon={selectedBigArea?.icon}
        selected={selectedBigArea?.option}
        setSelectedId={(value) =>
          setSelectedBigArea((prevState) => ({ ...prevState, id: value }))
        }
        setSelectedIcon={(value) =>
          setSelectedBigArea((prevState) => ({ ...prevState, icon: value }))
        }
        setSelected={(value) =>
          setSelectedBigArea((prevState) => ({ ...prevState, option: value }))
        }
      />
      {latestBigAreaUpdatedAt && (
        <LatestUpdate>Atualizado dia {latestBigAreaUpdatedAt}</LatestUpdate>
      )}
    </>
  );
}
