import React from "react";
import { Pagination as SPagination } from "semantic-ui-react";

const paginationStyle = {
  display: "flex",
  justifyContent: "center"
};

export default function Pagination({ list, fetchHandler, loading }) {
  return list?.totalPages > 1 && !loading ? (
    <div style={paginationStyle}>
      <SPagination
        activePage={list?.currentPage}
        totalPages={list?.totalPages}
        onPageChange={(_, data) => fetchHandler("", data.activePage)}
        firstItem={null}
        lastItem={null}
        boundaryRange={0}
      />
    </div>
  ) : null;
}
