import styled from "styled-components";
import { colors } from "../../../../../components/library/theme";

export const Container = styled.div`
  width: 100%;
  height: 64px;
  background: ${colors.interface.white000};
  display: flex;
  align-items: center;
  padding-left: 8px;
  border-bottom: 1px solid #edf0f2;
`;

export const ItemDescription = styled.div`
  width: 100%;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 740px) {
    padding: 8px;
    > div:last-child {
      width: 24px;
      height: 24px;
    }
  }
`;

export const DescriptionContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;

  @media (max-width: 740px) {
    align-items: initial;
  }
`;

export const SecondDescription = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;

  @media (max-width: 740px) {
    flex-direction: column;
    gap: 4px;
    align-items: flex-start;

    div:last-child {
      height: 22px;
    }
  }
`;
