import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  
  @media (max-width: 1919px) {
    flex-direction: row;
  }
`

export const Text = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  display: none;

  @media (max-width: 1919px) {
    display: block;
  }
`

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: solid 1px #c2ced6;
  border-radius: 4px;
  cursor: pointer;
  gap: 8px;
  height: 48px;
  width: 100%;

  & > span {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
  
  @media (max-width: 1919px) {
    height: 40px;
    width: 40px;
    
    & > span {
      display: none;
    }
  }
`
