import { createSelector } from "reselect";

function getAllFormMessages(state) {
  return state.formMessages;
}

export const getFormMessage = (formName) => {
  return createSelector(getAllFormMessages, (messages) => {
    return messages[formName]
      ? {
          message: messages[formName].message,
          messageType: messages[formName].type,
          messageVisible: messages[formName].visible,
        }
      : {};
  });
};
