import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import NewPasswordForm from "./NewPasswordForm";
import { newPassword } from "../../processes/sessionProcesses";

function mapStateToProps() {
  return {
    initialValues: {},
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "newPasswordForm",
    onSubmit: newPassword,
  })(NewPasswordForm)
);
