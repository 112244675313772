import React from "react";
import { useHistory } from "react-router-dom";
import { EMRButton } from "../../../Button";
import { HasEmergency } from "../../assets/HasEmergency";
import warning from "../../assets/warning.svg";
import { ACTIVE_SCREEN } from "../../enum";
import {
  ButtonContainer,
  Content,
  Description,
  Illustration,
  Paragraph,
  ReturnButton,
  Title,
  WarningText
} from "../../styles";
import { WarningContainer } from "./styles";
const textType = {
  emergencyAccess: {
    title:
      "Você não pode acessar deste dispositivo pois o acesso emergencial está em uso.",
    description:
      "Ao usar o acesso emergencial você perde a conexão aos demais dispositivos conectados. Como você efetuou o acesso emergencial de outro dispositivo você só conseguirá se manter conectado a partir dele."
  },
  unauthorizedAccess: {
    title: "Você não pode acessar deste dispositivo.",
    description:
      "Este dispositivo não está cadastrado e já atingiu o limite máximo de dispositivos por conta. Acesse a plataforma Eu Médico Residente de um dispositivo previamente cadastrado ou através dos acessos alternativos."
  }
};
export default function UnauthorizedAccessScreen({ location }) {
  const history = useHistory();
  const hasEmergencyAcess = location.state.emergencyAccess;
  function handleToSignIn() {
    history.push("/");
  }

  return (
    <>
      <Illustration>
        <HasEmergency />
      </Illustration>
      <Content>
        <Description>
          <Title>
            {hasEmergencyAcess
              ? textType.emergencyAccess.title
              : textType.unauthorizedAccess.title}
          </Title>
          <Paragraph>
            {hasEmergencyAcess
              ? textType.emergencyAccess.description
              : textType.unauthorizedAccess.description}
          </Paragraph>
        </Description>
        {hasEmergencyAcess && (
          <WarningContainer>
            <img src={warning} />
            <WarningText>
              Para recuperar o acesso aos demais dispositivos você precisará
              entrar em contato com nosso suporte.
            </WarningText>
          </WarningContainer>
        )}
        <ButtonContainer>
          <EMRButton
            fullSized
            fontSize={"lg"}
            mediumWeight
            onClick={handleToSignIn}
          >
            Entendi
          </EMRButton>
          {!hasEmergencyAcess && (
            <ReturnButton
              fullSized
              fontSize={"lg"}
              mediumWeight
              mode="transparent"
              onClick={() => {
                history.replace("/signin-security", {
                  activeScreen: ACTIVE_SCREEN.ALTERNATIVE_ACCESS,
                  email: location.state.credentials.email,
                  password: location.state.credentials.password,
                  credentials: {
                    email: location.state.credentials.email,
                    password: location.state.credentials.password
                  },
                  fingerprint: location.state.fingerprint
                });
              }}
            >
              Acessos alternativos
            </ReturnButton>
          )}
        </ButtonContainer>
      </Content>
    </>
  );
}
