import { useEffect, useRef, useState } from "react";

/**
 *
 * @param {Object} props - The props of the component
 * @param {function} props.onClickInside
 *
 * @param {function} props.onClickOutside
 *
 * @param {Array} props.ignoredRefs
 *
 * @param {Array} props.additionalRefs
 *
 * @returns {{
 * isClickedInside, isClickedOutside, ref
 * }} - The useClickInOut hook
 */

export function useClickInOut({
  onClickInside,
  onClickOutside,
  ignoredRefs = [],
}) {
  const [isClickedInside, setIsClickedInside] = useState(false);
  const [isClickedOutside, setIsClickedOutside] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const handleClick = (event) => {
      if (ref.current && ref.current.contains(event.target)) {
        setIsClickedInside(true);
        setIsClickedOutside(false);

        if (onClickInside) {
          onClickInside();
        }
      } else {
        for (const ignoredRef of ignoredRefs) {
          if (ignoredRef.current && ignoredRef.current.contains(event.target)) {
            return;
          }
        }

        setIsClickedInside(false);
        setIsClickedOutside(true);

        if (onClickOutside) {
          onClickOutside();
        }
      }
    };

    if (ref.current) {
      document.addEventListener("mousedown", handleClick);

      return () => {
        document.removeEventListener("mousedown", handleClick);
      };
    }
  }, [onClickInside, onClickOutside, ignoredRefs, ref]);

  return { isClickedInside, isClickedOutside, ref };
}
