import React, { useContext } from "react";
import { context } from "../context";
import {
  BackButton,
  ButtonsWrapper,
  Text,
  Title,
  YoutubeVideo
} from "./styles";
import { EMRButton } from "../../Button";

export function Step3() {
  const { firstName, loading, setStep, finish } = useContext(context);

  function goBack() {
    setStep(1);
  }

  function handleFinish() {
    finish();
  }

  return (
    <>
      <Title>Tudo pronto, {firstName()}</Title>
      <Text>
        Está curioso para saber mais sobre a plataforma? Assista ao vídeo e
        aprimore seus estudos!
      </Text>
      <YoutubeVideo
        src={process.env.TRIAL_YOUTUBE_URL}
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      />
      <ButtonsWrapper>
        <BackButton
          disabled={loading}
          onClick={goBack}
          mode="outline"
          fontSize="lg"
          fullSized
        >
          Voltar
        </BackButton>
        <EMRButton
          disabled={loading}
          loading={loading}
          fullSized
          fontSize="lg"
          onClick={handleFinish}
        >
          Acessar plataforma
        </EMRButton>
      </ButtonsWrapper>
    </>
  );
}
