import React, { useContext } from 'react';
import { context } from '../context';
import { Card } from './card';
import { Container, Header, Title, Wrapper } from './styles';

export function Step2() {
  const { suggestionsData } = useContext(context);
  const { highlight_classroom, second_classroom, third_classroom } =
    suggestionsData;

  const plansCount = Object.keys(suggestionsData).reduce((acc, type) => {
    const isPresent = suggestionsData[type];
    if (isPresent) acc++;
    return acc;
  }, 0);

  return (
    <>
      <Header>
        <Title>Selecione um curso para testar gratuitamente.</Title>
        <p>
          Trouxemos algumas sugestões de curso para que você possa escolher e
          testar por <strong>{process.env.FRIAL_DAYS}&nbsp;dias grátis</strong> a plataforma EMR.
        </p>
      </Header>
      <Container>
        <Wrapper length={plansCount}>
          {second_classroom && <Card {...second_classroom} />}
          {highlight_classroom && <Card highlight {...highlight_classroom} />}
          {third_classroom && <Card {...third_classroom} />}
        </Wrapper>
      </Container>
    </>
  );
}
