import { mixpanel } from "..";
// @ts-check
/**
 * @param {{
 * mockName: string,
 * status: string,
 * studyTime: string
 * score: number
 * progress: string
 * questionId: number,
 * questionNumber: number,
 * isFrialUser: boolean
 * }} mockData
 */

export function trackAnsweredQuestionMockEvent(mockData) {
  const { mockName, studyTime, score, progress, questionId, questionNumber,isFrialUser } =
    mockData;

  const answeredQuestionData = {
    MockName: mockName,
    StudyTime: studyTime,
    Score: score,
    Progress: progress,
    QuestionId: questionId,
    QuestionNumber: questionNumber,
    UserFrial: isFrialUser
  };

  mixpanel.track("Plataforma - Responder Simulado", answeredQuestionData);
}
