import { Button, Container } from "./styles";
import React from "react";
export function FilterButton({
  data,
  isLoading,
  selectedValue,
  setSelectedValue,
  onSelectFilter,
  isFavoriteQuestionsFilter,
  ...rest
}) {
  function handleChange(event) {
    setSelectedValue(event.target.value);
    if (onSelectFilter) {
      onSelectFilter(event.target.value);
    }
  }
  return (
    <Container isAnnotationBookFilter={isFavoriteQuestionsFilter} {...rest}>
      {data?.map((filter) => (
        <Button
          value={filter.action}
          active={selectedValue === filter.action}
          disabled={isLoading}
          onClick={handleChange}
          key={filter.action}
        >
          {filter.title}
        </Button>
      ))}
    </Container>
  );
}
