import React from "react";
import propTypes from "prop-types";
import {
  CheckIconContainer,
  RightAnswerBadge as Container,
  RightAnswerText,
} from "./styles";
import { WhiteCheckIcon } from "./assets/whiteCheckIcon";

/**
 *
 * @param {{rightAnswer: string}} rightAnswer - The right answer of the question
 */
export function RightAnswerBadge({ rightAnswer }) {
  return (
    <Container>
      <CheckIconContainer>
        <WhiteCheckIcon />
      </CheckIconContainer>
      <RightAnswerText>{rightAnswer}</RightAnswerText>
    </Container>
  );
}

RightAnswerBadge.propTypes = {
  rightAnswer: propTypes.string.isRequired,
};
