import { SubmissionError } from "redux-form";
import { normalize, schema } from "normalizr";
import { apiPut } from "./helpers/api";
import { notifySubmitSucceeded } from "./notifierProcesses";
import { replace } from "connected-react-router";

export const user = new schema.Entity("users");

export const updateProfile = (values, dispatch, props) => {
  return apiPut("/api/v1/auth")
    .send(values)
    .then((response) => {
      dispatch({
        type: "USER_UPDATED",
        ...normalize(response.body.data, user),
      });
      notifySubmitSucceeded(
        dispatch,
        "Uhuu, Seu perfil foi atualizado com sucesso!"
      );
      dispatch(replace("/"));
    })
    .catch((err) => {
      throw new SubmissionError({
        _error: err.response.body.errors.full_messages[0],
      });
    });
};
