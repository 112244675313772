import React from 'react';
import { Container } from './styles';
import { getBigAreaIcons } from '../../utils/getBigAreaIcons';
import { MedTrackTooltip } from '../MedTrackTooltip';
import { IoInformationCircle } from 'react-icons/io5';

export function BigAreaTag({ bigArea }) {
  if (!bigArea) return <></>;
  return (
    <Container>
      {getBigAreaIcons(bigArea.name)}
      {bigArea.description}
      <MedTrackTooltip
        width="195px"
        variant="bigArea"
        text="Grande-área definida para as tarefas vigentes e futuras. Caso queira alterar acesse as configurações."
      >
        <IoInformationCircle size={20} fill="#C2CED6" />
      </MedTrackTooltip>
    </Container>
  );
}
