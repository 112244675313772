import { underscore } from "inflection";

export default function toQueryString(values) {
  let params = "";
  Object.keys(values).map((k) => {
    const name = underscore(k);
    const value = values[k];
    if (value === null || value === undefined) return;
    if (Array.isArray(value)) {
      return value.map((i) => (params += `q[${name}][]=${i}&`));
    }
    params += `q[${name}]=${value}&`;
  });
  return params;
}
