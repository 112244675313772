const initialState = {
  type: null,
  title: null,
  message: null,
  icon: null,
  visible: false,
};

export default function notifierReducer(state = initialState, action) {
  switch (action.type) {
    case "NOTIFIER_REQUESTED":
      return {
        ...state,
        ...action.payload,
        visible: true,
      };
    case "NOTIFIER_DISMISS_REQUESTED":
      return initialState;
    default:
      return state;
  }
}
