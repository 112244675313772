import * as React from 'react';

export function MaterialsIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
      <rect width={24} height={24} fill="#49B1EB" rx={12} />
      <g fill="#fff" clipPath="url(#a)">
        <path d="M6.75 18.125h-.438a.875.875 0 0 1-.875-.875V8.062a.875.875 0 0 1 .875-.874h.438a.875.875 0 0 1 .875.875v9.187a.875.875 0 0 1-.875.875Zm4.813-8.313a.875.875 0 0 0-.876-.874h-1.75a.875.875 0 0 0-.874.874v.766a.11.11 0 0 0 .109.11h3.281a.11.11 0 0 0 .11-.11v-.765Zm-3.5 7.438a.875.875 0 0 0 .874.875h1.75a.875.875 0 0 0 .876-.875v-.82a.055.055 0 0 0-.055-.055h-3.39a.055.055 0 0 0-.056.055v.82ZM11.508 11.563h-3.39a.055.055 0 0 0-.056.054v3.828c0 .03.025.055.055.055h3.39c.031 0 .056-.024.056-.055v-3.828a.055.055 0 0 0-.055-.055ZM13.75 18.125h-.875A.875.875 0 0 1 12 17.25V6.75a.875.875 0 0 1 .875-.875h.875a.875.875 0 0 1 .875.875v10.5a.875.875 0 0 1-.875.875Zm4.81-.945-.882-9.297c-.04-.428-.463-.738-.944-.692l-.87.082c-.482.046-.839.43-.799.858l.882 9.297c.04.428.463.738.944.692l.87-.082c.482-.046.839-.43.799-.858Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M5 5h14v14H5z" />
        </clipPath>
      </defs>
    </svg>
  );
}
