import { createSelector } from "reselect";

const getSurveys = (state) => state.entities.surveys;
const getSurveysUsers = (state) => state.entities.surveysUsers;
const getCurrentClassroomId = (state) => state.currentClassroomId;

export const getSurveyUsers = createSelector(getSurveysUsers, (surveysUsers) =>
  Object.values(surveysUsers)
);

export const getSurveysByClassroom = createSelector(
  getSurveys,
  getCurrentClassroomId,
  (surveys, currentClassroomId) =>
    Object.values(surveys).filter((survey) =>
      survey.classroomIds.includes(currentClassroomId)
    )
);

export const getActiveSurvey = createSelector(
  getSurveysByClassroom,
  getSurveyUsers,
  (surveys, surveysUsers) => {
    return Object.values(surveys).filter(
      (survey) =>
        !surveysUsers
          .map((surveyUser) => surveyUser.surveyId)
          .includes(survey.id)
    )[0];
  }
);
