import styled from "styled-components";
import { colors } from "../../library/theme";
import { style } from "glamor";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-height: 70vh;
  padding-bottom: 80px;
`;

export const QuestionsBySpecialtyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const SpecialtyCard = styled.div`
  background: ${colors.interface.white000};
  box-shadow: 0px 1px 4px rgba(16, 24, 40, 0.1),
    0px 1px 3px rgba(16, 24, 40, 0.06);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  z-index: ${(props) => props.zIndex};
`;

export const SpecialtyTitleContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  background: ${colors.interface.grey000};
  align-items: center;
  border-top: ${(props) =>
    props.isFirstSpecialtySubject
      ? "none"
      : `1px solid ${colors.interface.grey100}`};
  border-bottom: 1px solid ${colors.interface.grey100};
  border-radius: 4px 4px 0 0;
  padding: 24px;

  h3:first-letter,
  span:first-letter {
    text-transform: capitalize;
  }

  h3,
  span {
    line-height: 1.3;
    font-weight: 600;
    color: ${colors.interface.grey400};
  }

  h3 {
    font-size: 16px;
    margin: 0;
  }

  span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 12px;
    border-radius: 59px;
    background: ${colors.interface.grey100};
    padding: 8px 16px;
  }
`;

export const SubjectContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px 4px 0 0;
`;

export const MockQuestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-top: ${(props) =>
    props.isFirstQuestion ? "none" : `1px solid ${colors.interface.grey200}`};
  padding: 24px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  flex-direction: row;
  gap: 14px;

  @media screen and (max-width: 640px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;

    button {
      padding: 0;
      justify-content: flex-start;
      white-space: nowrap;
      width: fit-content;
    }
  }
`;

export const MockTitle = styled.h4`
  font-size: 14px;
  line-height: 1.3;
  font-weight: 600;
  color: ${colors.interface.grey400};
  margin: 0;
`;

export const Divider = styled.div`
  height: 0px;
  border-top: 1px solid ${colors.interface.grey100};
`;

export const QuestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PlaceholderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  margin: 158px auto auto;

  @media (max-width: 640px) {
    margin: 56px auto auto;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
`;

export const ActionsContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;

  @media (max-width: 640px) {
    width: 100%;

    > button {
      width: 100%;
      border: 1px solid #c2ced6;
      border-radius: 4px;
      padding: 6px 16px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      > p {
        color: ${colors.brand.pure};
      }
    }
  }
`;

export const UnfavoriteButton = styled.button`
  width: 40px;
  height: 40px;
  display: none;
  justify-content: center;
  align-items: center;
  position: relative;
  background: none;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  transition: 0.2s ease-in-out;
  :hover {
    background-color: ${colors.interface.grey100};
  }

  > svg {
    fill: ${colors.brand.pure};
  }

  @media (min-width: 640px) {
    display: flex;
  }

  > div {
    display: none;
    min-width: unset;
    width: 102px;
    top: -29px;
    left: -30px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    font-family: "Poppins";
    background-color: #edf0f2;
    box-shadow: 0px 2px 4px -2px #1018280f;
    box-shadow: 0px 4px 8px -2px #1018281a;

    :after {
      bottom: 0px;
      left: 38%;
      transform: rotate(225deg) translateX(-90%);
      border-radius: 4px 0 0 0;
      border-width: 1px 0 0 1px;
    }

    @media (max-width: 700px) {
      top: 32px;
      bottom: initial;
      left: -5px;
      border-width: initial;

      :after {
        left: 84%;
      }
    }

    @media (max-width: 435px) {
      left: -51px;
    }
  }

  :hover {
    & > div {
      display: flex;
    }
  }
`;

export const UnfavoriteButtonMobile = styled.button`
  width: 40px;
  height: 40px;
  display: none;
  justify-content: center;
  align-items: center;

  background: none;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  transition: 0.2s ease-in-out;
  :hover {
    background-color: ${colors.interface.grey100};
  }

  > svg {
    fill: ${colors.brand.pure};
  }

  @media (max-width: 640px) {
    display: flex;
  }
`;

export const FavoriteQuestionsNumber = styled.h4`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.3;
  color: ${colors.interface.grey400};
  background-color: ${colors.interface.grey000};
  border-radius: 50%;
  border: 1px solid ${colors.interface.grey100};
  height: 32px;
  width: 32px;
  margin: 0;
`;

export const PlaceholderDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: center;
  color: ${colors.interface.grey300};

  > p {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }

  > span {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
  }
`;
