import styled from "styled-components";

import * as Dialog from "@radix-ui/react-dialog";
import { colors } from "../../../../components/library/theme";
export const DialogOverlay = styled(Dialog.Overlay)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  overflow: auto;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: rgba(54, 62, 70, 0.3);
  z-index: 10;
`;

export const DialogContent = styled(Dialog.Content)`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background-color: ${colors.interface.white000};
  padding: 24px;
  width: min(772px, calc(100vw - 48px));
  margin: 54px 14px 32px 24px;
  position: relative;
  padding: 24px;
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 628px;
`;

const ModalTitle = styled.p`
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.5rem;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  & svg {
    fill: ${colors.interface.grey300};
    height: 24px;
    width: 24px;
    &:hover {
      cursor: pointer;
    }
  }
`;

const ModalText = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
`;

const ModalTab = styled.div`
  background-color: ${colors.interface.grey000};
  border: 1px solid ${colors.interface.grey100};
  border-radius: 0.25rem;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  padding: 1rem;
  margin-top: 1.5rem;
`;

const TabText = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
`;

const ModalButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-top: 1.5rem;
  width: 100%;
  & div {
    width: 50%;
  }
  button {
    font-weight: 600;
  }
`;

export {
  ModalButtonsContainer,
  ModalContainer,
  ModalHeader,
  ModalTab,
  ModalText,
  ModalTitle,
  TabText,
};
