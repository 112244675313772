import styled from "styled-components";
import { colors } from "../../../../components/library/theme";
import { DynamicLink } from "../NotifierDropdown/DynamicLink";

export const Container = styled.article`
  width: 100%;
  display: flex;
  padding: 16px;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid ${colors.interface.grey150};
  background: ${({ wasRead }) =>
    wasRead ? colors.medtrack.grey000 : colors.interface.white000};

  &:hover {
    border-color: ${colors.brand.light200};
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  @media (max-width: 780px) {
    align-items: flex-start;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
  justify-content: space-between;

  > div {
    display: flex;
    align-items: center;
    gap: 12px;

    > h3 {
      margin: 0;
      font-size: 14px;
      line-height: 21px;
      color: ${({ wasRead }) =>
        wasRead ? colors.interface.grey500 : colors.interface.grey600};
    }

    @media (max-width: 780px) {
      align-items: flex-start;

      > h3 {
        font-size: 12px;
        line-height: 18px;
        max-width: 80%;
      }
    }
  }

  @media (max-width: 780px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }
`;

export const DateWrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;

  > div {
    display: flex;
    gap: 24px;
    align-items: center;
    width: max-content;

    > span {
      font-size: 12px;
      line-height: 18px;
      color: ${({ wasRead }) =>
        wasRead ? colors.interface.grey300 : colors.interface.grey400};

      @media (max-width: 780px) {
        font-size: 10px;
        line-height: 15px;
      }
    }
  }
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Description = styled.p`
  font-size: 14px;
  line-height: 21px;
  color: ${colors.interface.grey600};

  @media (max-width: 780px) {
    font-size: 12px;
    line-height: 18px;
  }
`;

export const StyledLink = styled(DynamicLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  gap: 4px;
  cursor: pointer;
  color: ${colors.brand.pure};
  font-size: 14px;
  line-height: 21px;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 780px) {
    font-size: 12px;
    line-height: 18px;
  }
`;

export const DesktopCheckIcon = styled.div`
  display: flex;

  @media (max-width: 780px) {
    display: none;
  }
`;

export const MobileCheckIcon = styled.div`
  display: none;

  @media (max-width: 780px) {
    display: block;
    width: 100%;
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-left: 24px;

  > svg {
    path {
      fill: "#9BA5AB";
    }
  }
`;
