import React from "react";
import { IoCheckmarkOutline, IoClose } from "react-icons/io5";
import {
  CloseButton,
  ColorButton,
  HighlightColors,
  HighlighterMenuContainer,
  HighlighterMenuHeader,
  HighlighterMenuWrapper,
} from "./styles";
import { COLOR_MAPPER } from "../../../questions/QuestionDescription/highlighterConstants";
import { EMRButton } from "../../../../componentsV2/Button";

/**
 * HighlighterMenu Component
 * 
 * @param {Object} props - Component properties
 * @param {boolean} props.isMenuOpen - Flag to control the visibility of the menu
 * @param {function} props.onCloseMenu - Function to handle the closing of the menu
 * @param {boolean} props.isHighlighterActive - Flag to indicate if the highlighter is active
 * @param {"yellow" | "red" | "green" | "white"} props.selectedHighlightColor - Currently selected highlight color
 * @param {function} props.onToggleHighlighter - Function to toggle the highlighter on/off
 * @param {function("yellow" | "red" | "green" | "white"): void} props.onSelectColor - Function to select a highlight color
 * @param {function} props.onClearTextHighlights - Function to clear all text highlights
 * @param {boolean} props.hasStoredHighlights - Flag to indicate if there are stored highlights
 * 
 * @returns {JSX.Element} The HighlighterMenu component
 */
export function HighlighterMenu({
  isMenuOpen,
  onCloseMenu,
  isHighlighterActive,
  selectedHighlightColor,
  onToggleHighlighter,
  onSelectColor,
  onClearTextHighlights,
  hasStoredHighlights,
}) {
  return (
    <HighlighterMenuWrapper open={isMenuOpen}>
      <HighlighterMenuHeader>
        <span>Marcação de texto</span>
        <CloseButton onClick={onCloseMenu}>
          <IoClose size={24} color="#9BA5AB" />
        </CloseButton>
      </HighlighterMenuHeader>

      <HighlighterMenuContainer>
        <span>Selecione a cor e ative a marcação</span>

        <HighlightColors>
          {Array.from(Object.keys(COLOR_MAPPER))
            .filter((color) => color !== "disabled")
            .map((color) => (
              <ColorButton
                key={color}
                color={COLOR_MAPPER[color].hex}
                onClick={() => onSelectColor(color)}
              >
                {selectedHighlightColor === color && (
                  <IoCheckmarkOutline
                    color={
                      color === "red" ? "#fff" : "#000"
                    }
                    size={16}
                  />
                )}
              </ColorButton>
            ))}
        </HighlightColors>

        <EMRButton
          onClick={onToggleHighlighter}
          mode={'standard'}
          fontSize={'sm'}
          fullSized
          shortHeight
          mediumWeight
        >
          {isHighlighterActive ? "Desativar marcação" : "Ativar marcação"}
        </EMRButton>

        <EMRButton
          onClick={onClearTextHighlights}
          disabled={!hasStoredHighlights}
          mode={'transparent'}
          fontSize={'md'}
          fullSized
          shortHeight
          style={{ padding: 0, width: '100%', height: 21 }}
        >
          Limpar todas as marcações
        </EMRButton>
      </HighlighterMenuContainer>
    </HighlighterMenuWrapper>
  );
}
