import styled, { css } from "styled-components";
import { colors } from "../../../../components/library/theme";


function gestStepBgColor(currentStep) {
    if (currentStep === "initial"){
        return {
            bg: colors.interface.grey100, 
            icon: colors.interface.grey200, 
            border: 'transparent'
        }
    }

    return  {
        bg: colors.status.green000,
        icon: colors.status.green200,
        border: colors.status.green200
    };    
}

export const StepsWrapper = styled.div`
    width: 100%;
    align-items: center;
    justify-content: space-between;
    display: flex;
    position: relative;
`



export const Step = styled.div(({isActive, currentStep }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border: 1px solid transparent;
    border-radius: 999px;
    background-color: ${isActive ? colors.status.green200 : gestStepBgColor(currentStep).bg};
    border-color: ${isActive ? "transparent" : gestStepBgColor(currentStep).border};

    svg{
        color: ${isActive ? colors.interface.white000 : gestStepBgColor(currentStep).icon};
    }
  
`)


export const Line = styled.hr`
    border: 1px solid ${colors.status.green200};
    position: absolute;
    width: 90%;
    top: 18px;
    left: 0;
    z-index: -1;

`