import React from "react";
import { FaArrowRight } from "react-icons/fa";
import { EMRButton } from "../../Button";
import { AuthGirlIllustration } from "../../SignInSecurity/assets/AuthGirl";
import { ContentContainter, Description } from "./styles";
export function FinalStep({ onContinue }) {
  return (
    <ContentContainter>
      <AuthGirlIllustration />
      <Description>
        <h1>Dispositivo cadastrado com sucesso!</h1>
        <h2>
          Você já pode seguir usando nossa plataforma normalmente. Lembre-se de
          que você só pode usar seus dispositivos cadastrados. Em caso de
          dúvidas, entre em contato com nosso suporte. Bons estudos.
        </h2>
      </Description>
      <EMRButton fullSized onClick={onContinue}>
        Continuar Estudos
        <FaArrowRight />
      </EMRButton>
    </ContentContainter>
  );
}
