import React, { useState } from 'react';
import {
  ButtonContainer,
  DialogContent,
  DialogOverlay,
  StyledEMRButton,
  StyledSpinner,
  Text,
  Title,
} from './styles';
import * as Dialog from '@radix-ui/react-dialog';

export function UncheckTodoModal({
  isOpen,
  onStatusChange,
  uncheckTodo,
  setUncheckTodo,
}) {
  const [isLoading, setIsLoading] = useState(false);

  function handleClose() {
    setUncheckTodo({});
  }

  async function handleUncheck() {
    setIsLoading(true);

    try {
      await onStatusChange(uncheckTodo.todoId, uncheckTodo.todoStatus);
      setIsLoading(false);
      handleClose();
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  }

  return (
    <Dialog.Root open={isOpen}>
      <Dialog.Portal>
        <DialogOverlay>
          <DialogContent>
            <Title>Deseja desmarcar a tarefa?</Title>

            <Text>
              Sua semana já está definida como concluída. Caso decida desmarcar
              a tarefa sua semana volta ao status anterior.
            </Text>

            <ButtonContainer>
              <StyledEMRButton onClick={handleClose} mode={'cancel'} fullSized>
                Cancelar
              </StyledEMRButton>

              <StyledEMRButton
                mode={'red'}
                fullSized
                onClick={handleUncheck}
                disabled={isLoading}
              >
                {isLoading ? (
                  <StyledSpinner inline size="small" />
                ) : (
                  'Desmarcar'
                )}
              </StyledEMRButton>
            </ButtonContainer>
          </DialogContent>
        </DialogOverlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
