import { setCookie } from "cookies-next";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { trackSignUpEvent } from "../../../../services/mixpanel/events/trackSignUpEvent";
import { apiPost } from "../../../processes/helpers/api";
import { setSession } from "../../../processes/sessionProcesses";
import { getThemeByUniversity } from "../../../utils/getThemeByUniversity";
import { EMRButton } from "../../Button";
import Dropdown from "../../Dropdown";
import Input from "../../Input";
import Template from "../template";
import { getValidator } from "../validations";
import { FinishAuth2RegisterForm } from "./FinishAuth2RegisterForm";
import { YEARS } from "./consts";
import Divisor from "./divisor";
import * as services from "./services";
import SignUpWith from "./signUpWith";
import {
  ButtonWrapper,
  EnareObs,
  Goals,
  Login,
  PhoneContainer,
  SmallText,
  Terms,
  Text
} from "./styles";
import { trackClickEvents } from "../../../../services/mixpanel/events/trackClickEvents";
import { countryOptions } from "../../../constants/countryOptions";

const initialState = {
  email: "",
  healthCareField: "Em qual prova você quer passar?*",
  name: "",
  password: "",
  countryCode: "55",
  phoneNumber: "",
  repeatPassword: "",
  stateSelected: "Em qual estado será sua prova prioritária?*",
  yearSelected: "Em que ano prestará a prova?*"
};

function SignUp() {
  const { IES } = getThemeByUniversity();
  const [loading, setLoading] = useState(false);
  const [healthcareFields, setHealthcareFields] = useState([]);
  const [urlParams, setUrlParams] = useState();
  const [hasSuperSimuladoParams, setHasSuperSimuladoParams] = useState();
  const [states, setStates] = useState([]);
  const [screen, setScreen] = useState("signUp"); // "signUp" | "setPassword" | "finishAuth2Register"
  const [data, setData] = useState(initialState);
  const [errors, setErrors] = useState({
    email: "",
    healthCareField: "",
    name: "",
    password: "",
    phoneNumber: "",
    repeatPassword: "",
    stateSelected: "",
    yearSelected: ""
  });

  const stateOptions = states.map((state) => state.name);
  const healthcareOptions = healthcareFields.map((field) => field.name);
  const countryNameOptions = countryOptions.map((country) => country.name);
  const countryCodeOptions = countryOptions.map((country) => country.code);

  function getIdFromHealthcareField(name) {
    const field = healthcareFields.find((field) => field.name === name);
    return field.id;
  }

  function getStateIdFromName(name) {
    const state = states.find((state) => state.name === name);
    return state.id;
  }

  function changeData(field, value) {
    setData((state) => ({ ...state, [field]: value }));
  }

  function validateField(field, ...params) {
    const validator = getValidator(field);
    return validator(...params);
  }

  const validatePassword = (e) => {
    const pw = e.target.value;
    setErrors((state) => ({
      ...state,
      password: validateField("password", pw)
    }));
  };

  const validateRepeatInput = (e) => {
    const pw = e.target.value;
    setErrors((state) => ({
      ...state,
      repeatPassword: validateField("repeatPassword", pw, data.password)
    }));
  };

  async function handleContinue() {
    const errors = {
      name: validateField("name", data.name),
      email: validateField("email", data.email),
      phoneNumber: validateField("phoneNumber", data.phoneNumber, IES),
      healthCareField: validateField(
        "healthCareField",
        healthcareOptions,
        data.healthCareField
      ),
      stateSelected:
        IES === "revalida"
          ? false
          : validateField("stateSelected", stateOptions, data.stateSelected),
      yearSelected: validateField("yearSelected", data.yearSelected)
    };

    setErrors(errors);

    const hasValidationError = Object.values(errors).some((error) =>
      Boolean(error)
    );

    if (hasValidationError) return;

    try {
      setLoading(true);
      const { token } = await services.signUpWithEmail({
        name: data.name,
        email: data.email,
        phone:
          IES === "revalida"
            ? `${data.countryCode.split(" ")[1]}${data.phoneNumber}`
            : data.phoneNumber,
        super_simulado_lp: hasSuperSimuladoParams,
        healthcare_role_description: getIdFromHealthcareField(
          data.healthCareField
        ),
        preferred_exam_state:
          IES === "revalida" ? 0 : getStateIdFromName(data.stateSelected),
        exam_year:
          data.yearSelected == "Outros anos" ? 9999 : data.yearSelected,
        utm_source: urlParams.utm_source,
        utm_campaign: urlParams.utm_campaign,
        utm_medium: urlParams.utm_medium,
        utm_content: urlParams.utm_content,
        utm_term: urlParams.utm_term,
        banca_super_simulado: urlParams.banca_super_simulado
      });

      services.saveDataToLocalStorage({
        registerMethod: "email",
        data: {
          ...data,
          password: "",
          repeatPassword: ""
        },
        token
      });
      setLoading(false);

      setScreen("setPassword");
    } catch (error) {
      setLoading(false);
      alert(error.message);
    }
    trackClickEvents({ eventName: "Clique em Continuar (Tela de cadastro)" });
  }

  const invalidPhones = [
    "(00) 00000-0000",
    "(11) 11111-1111",
    "(22) 22222-2222",
    "(33) 33333-3333",
    "(44) 44444-4444",
    "(55) 55555-5555",
    "(66) 66666-6666",
    "(77) 77777-7777",
    "(88) 88888-8888",
    "(99) 99999-9999"
  ];

  async function handleFinishAuth2Register({ email, phoneNumber }) {
    const errors = {
      email: validateField("email", email),
      phoneNumber: validateField("phoneNumber", phoneNumber),
      healthCareField: validateField(
        "healthCareField",
        healthcareOptions,
        data.healthCareField
      ),
      stateSelected: validateField(
        "stateSelected",
        stateOptions,
        data.stateSelected
      ),
      yearSelected: validateField("yearSelected", data.yearSelected)
    };

    setErrors(errors);

    const hasValidationError = Object.values(errors).some((error) =>
      Boolean(error)
    );

    if (invalidPhones.includes(phoneNumber)) {
      alert("Número de WhatsApp inválido");
      return;
    }

    if (hasValidationError) return;

    try {
      const { token, provider } = services.getDataFromLocalStorage();
      setLoading(true);
      const response = await services.signUpWithProviderStep2({
        email,
        exam_year:
          data.yearSelected == "Outros anos" ? 9999 : data.yearSelected,
        healthcare_role_description: getIdFromHealthcareField(
          data.healthCareField
        ),
        phone: phoneNumber,
        preferred_exam_state:
          IES === "revalida" ? 0 : getStateIdFromName(data.stateSelected),
        token,
        provider,
        utm_source: urlParams.utm_source,
        utm_campaign: urlParams.utm_campaign,
        utm_medium: urlParams.utm_medium,
        utm_content: urlParams.utm_content,
        utm_term: urlParams.utm_term
      });

      setLoading(false);

      localStorage.setItem("session", JSON.stringify(response));

      trackSignUpEvent({
        name: data.name,
        email: data.email,
        signUpDate: new Date(Date.now()),
        provider: provider
      });

      window.location = "/";
    } catch (error) {
      setLoading(false);
      alert(error.message);
    }
  }

  async function handleSign(email, password) {
    apiPost("/api/v1/auth/sign_in", true, true)
      .send({ email, password })
      .then((res) => {
        setCookie(process.env.METRICO_THEME_COOKIE, IES, {
          domain: process.env.DOMAIN_URL
        });
        setSession(res);

        if (hasSuperSimuladoParams) {
          window.open("/", "_blank");
        } else {
          window.location = "/";
        }
      })
      .catch((res) => {
        setLoading(false);
      });
  }

  async function handleFinishEmailRegister() {
    const errors = {
      password: validateField("password", data.password),
      repeatPassword: validateField(
        "repeatPassword",
        data.repeatPassword,
        data.password
      )
    };

    setErrors(errors);

    const hasValidationError = Object.values(errors).some((error) =>
      Boolean(error)
    );

    if (hasValidationError) return;

    try {
      setLoading(true);
      await services.signUpWithEmailStep2({
        password: data.password,
        token: services.getDataFromLocalStorage().token,
        super_simulado_lp: hasSuperSimuladoParams
      });

      trackSignUpEvent({
        name: data.name,
        email: data.email,
        signUpDate: new Date(Date.now()),
        provider: "EMAIL"
      });

      await handleSign(data.email, data.password);
    } catch (error) {
      setLoading(false);
      alert(error.message);
    }
  }

  async function handleSignUpWithProvider(provider) {
    try {
      const { token, userData } = await services.signUpWithProvider(provider);
      for (const [key, value] of Object.entries(userData)) {
        changeData(key, value);
      }
      services.saveDataToLocalStorage({
        registerMethod: "provider",
        data: {
          ...data,
          ...userData,
          password: "",
          repeatPassword: ""
        },
        token,
        provider
      });
      setScreen("finishAuth2Register");
    } catch (error) {
      alert(error.message);
    }
  }

  const firstName = data.name.split(" ")[0];

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setUrlParams({
      utm_source: params.get("utm_source"),
      utm_campaign: params.get("utm_campaign"),
      utm_medium: params.get("utm_medium"),
      utm_content: params.get("utm_content"),
      utm_term: params.get("utm_term"),
      banca_super_simulado: params.get("banca_super_simulado")
    });
    setHasSuperSimuladoParams(Boolean(params.get("super_simulado_lp")));
    services.getStates().then(setStates);
    services.getHealthcareFields().then(setHealthcareFields);
    const registerData = services.getDataFromLocalStorage();
    if (registerData) {
      setData(registerData.data);
    }
    if (registerData?.registerMethod === "provider") {
      setScreen("finishAuth2Register");
    }
  }, []);

  useEffect(() => {
    document.body.style.overflowY = "hidden";

    return () => (document.body.style.overflowY = "");
  }, [screen]);

  useEffect(() => {
    setData(initialState);
  }, []);

  return (
    <Template>
      {screen === "finishAuth2Register" && (
        <FinishAuth2RegisterForm
          data={data}
          errors={errors}
          firstName={firstName}
          healthcareOptions={healthcareOptions}
          onChangeData={changeData}
          onFinishRegister={handleFinishAuth2Register}
          stateOptions={stateOptions}
          loading={loading}
        />
      )}

      {screen === "setPassword" && (
        <>
          <Text>
            Finalize seu cadastro, <strong>{firstName}</strong>
          </Text>
          <Input
            onBlur={validatePassword}
            value={data.password}
            onChange={(e) => changeData("password", e.target.value)}
            label="Senha*"
            type="password"
            error={errors.password}
          />
          <Input
            onBlur={validateRepeatInput}
            value={data.repeatPassword}
            onChange={(e) => changeData("repeatPassword", e.target.value)}
            label="Confirme sua senha*"
            error={errors.repeatPassword}
            ispassword={true}
          />
          <SmallText>Sua senha deve conter no mínimo 6 caracteres.</SmallText>
          <ButtonWrapper onClick={handleFinishEmailRegister}>
            <EMRButton loading={loading} fullSized>
              Acessar plataforma
            </EMRButton>
          </ButtonWrapper>
        </>
      )}

      {screen === "signUp" && (
        <>
          {!hasSuperSimuladoParams && (
            <>
              <Login>
                <h1>Crie sua conta gratuitamente</h1>
                <div>
                  Já possui uma conta?
                  <Link
                    to={IES ? `signin-v2?ies=${IES}` : "signin-v2"}
                    onClick={() =>
                      trackClickEvents({
                        eventName: "Clique em Faça login (Tela cadastro)"
                      })
                    }
                  >
                    Faça login
                  </Link>
                </div>
              </Login>
              <SignUpWith onRegisterWith={handleSignUpWithProvider} />
              <Divisor />
            </>
          )}

          <Input
            label="Nome e sobrenome*"
            error={errors.name}
            value={data.name}
            onChange={(e) => changeData("name", e.target.value)}
          />
          <Input
            label="E-mail*"
            error={errors.email}
            value={data.email}
            onClick={() =>
              trackClickEvents({
                eventName: "Clique no campo E-mail (Tela de cadastro)"
              })
            }
            onChange={(e) => changeData("email", e.target.value)}
          />
          {IES === "revalida" ? (
            <PhoneContainer>
              <Dropdown
                options={countryNameOptions}
                selected={data.countryCode}
                error={errors.phoneNumber}
                setSelected={(value) => {
                  changeData(
                    "countryCode",
                    countryOptions.find((country) => country.name === value)
                      .code
                  );
                }}
                label={countryCodeOptions[2]}
              />
              <Input
                label="Número"
                error={errors.phoneNumber}
                value={data.phoneNumber}
                onClick={() =>
                  trackClickEvents({
                    eventName: "Clique no campo WhatsApp (Tela de cadastro)"
                  })
                }
                onChange={(e) => changeData("phoneNumber", e.target.value)}
              />
            </PhoneContainer>
          ) : (
            <Input
              inputMask="(99) 99999-9999"
              label="WhatsApp*"
              error={errors.phoneNumber}
              value={data.phoneNumber}
              onClick={() =>
                trackClickEvents({
                  eventName: "Clique no campo WhatsApp (Tela de cadastro)"
                })
              }
              onChange={(e) => changeData("phoneNumber", e.target.value)}
            />
          )}

          <Goals>
            <Dropdown
              options={healthcareOptions}
              selected={data.healthCareField}
              setSelected={(value) => {
                changeData("healthCareField", value),
                  trackClickEvents({
                    eventName:
                      "Clique no campo Em qual prova você quer passar? (Tela de cadastro)",
                    content: { Prova: value }
                  });
              }}
              error={errors.healthCareField}
              label="Em qual prova você quer passar?*"
            />
            <Dropdown
              options={YEARS}
              selected={data.yearSelected}
              setSelected={(value) => {
                changeData("yearSelected", value),
                  trackClickEvents({
                    eventName:
                      "Clique no campo Em que ano prestará a prova? (Tela de cadastro)",
                    content: { Ano: value === "Outros anos" ? 9999 : value }
                  });
              }}
              error={errors.yearSelected}
              label={"Em que ano prestará a prova?*"}
            />
            {IES !== "revalida" && (
              <>
                <Dropdown
                  options={stateOptions}
                  selected={data.stateSelected}
                  setSelected={(value) => {
                    changeData("stateSelected", value),
                      trackClickEvents({
                        eventName:
                          "Clique no campo Em qual estado será sua prova prioritária? (Tela de cadastro)",
                        content: { Estado: value }
                      });
                  }}
                  error={errors.stateSelected}
                  label={"Em qual estado será sua prova prioritária?*"}
                />
                <EnareObs>
                  Caso seu objetivo seja ENARE, marcar Distrito Federal
                </EnareObs>
              </>
            )}
          </Goals>
          <ButtonWrapper>
            <EMRButton fullSized onClick={handleContinue} loading={loading}>
              Continuar
            </EMRButton>
            <Terms>
              Ao continuar, você concorda com os{" "}
              <a
                href="https://www.eumedicoresidente.com.br/termos-de-uso"
                target="_blank"
              >
                Termos de Serviço
              </a>{" "}
              e a{" "}
              <a
                href="https://www.eumedicoresidente.com.br/politicas-de-privacidade"
                target="_blank"
              >
                Política de Privacidade
              </a>{" "}
              da EMR.
            </Terms>
          </ButtonWrapper>
        </>
      )}
    </Template>
  );
}

export default SignUp;
