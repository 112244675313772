import React, { useEffect, useState } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import { IoClose } from "react-icons/io5";
import Spinner from "../../library/Spinner";
import { fetchQuestionByAnnotationId } from "../../../processes/questionProccess";
import Video from "../../library/Video";
import { Label } from "semantic-ui-react";
import { QuestionAlternativesList } from "../../../components/questions/QuestionAnswersAlternatives";
import { QuestionDifficultyLevel } from "../../../components/questions/QuestionDifficultyLevel";
import { DifficultyWrapper } from "../../../components/questions/QuestionDifficultyLevel/styles";
import { EMRTip } from "../../../components/questions/EMRTip";
import { getFormattedTitle } from "./getFormattedTitle";
import { getFormattedDescription } from "./getFormattedDescription";
import { Alternative } from "../Alternative/index";
import {
  DialogOverlay,
  DialogContent,
  CloseButton,
  QuestionTitle,
  QuestionDescription,
  AlternativesContainer,
} from "./styles";
import {
  extraInfoStyle,
  labelStyle,
  questionsComments,
  videoWrapper,
} from "../../../components/questions/QuestionStyles";

export function QuestionModal({ annotationId }) {
  const [isLoading, setIsLoading] = useState(true);
  const [questionData, setQuestionData] = useState({});

  useEffect(() => {
    const id = { id: annotationId };
    fetchQuestionByAnnotationId(id)
      .then((question) => setQuestionData(question))
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <Dialog.Portal>
      <DialogOverlay>
        <DialogContent>
          <Dialog.Close asChild>
            <CloseButton>
              <IoClose size={30} />
            </CloseButton>
          </Dialog.Close>

          {isLoading ? (
            <Spinner text="Carregando questão..." />
          ) : (
            <>
              {questionData?.cancelled && (
                <Label size="small" style={labelStyle} color="red">
                  Anulada
                </Label>
              )}
              <QuestionTitle>
                {getFormattedTitle(
                  questionData?.question?.title,
                  questionData?.question?.code
                )}
              </QuestionTitle>

              <QuestionDescription>
                {getFormattedDescription(questionData?.question?.title)}
              </QuestionDescription>

              <AlternativesContainer>
                {questionData?.alternatives ? (
                  questionData?.alternatives?.map((alternative) => (
                    <Alternative
                      key={alternative.id}
                      alternative={alternative}
                      questionData={questionData}
                    />
                  ))
                ) : (
                  <Alternative
                    key={questionData?.alternatives[0].id}
                    alternative={questionData?.alternatives[0]}
                    questionData={questionData}
                  />
                )}
              </AlternativesContainer>
              <>
                <DifficultyWrapper>
                  <QuestionDifficultyLevel
                    difficultyLevel={questionData?.difficultyLevel}
                  />
                </DifficultyWrapper>

                {questionData?.question.video_url && (
                  <div className={videoWrapper}>
                    <h3 className={questionsComments}>Comentário da Questão</h3>

                    <Video isQuestion url={questionData.question.video_url} />

                    {questionData?.question.hint && (
                      <EMRTip
                        text={questionData.question.hint}
                        title="Dica EMR"
                      />
                    )}

                    {questionData.question.extra_info && (
                      <>
                        {questionData.question?.is_comment_template ? (
                          <QuestionAlternativesList
                            text={questionData.question.extra_info}
                          />
                        ) : (
                          <p style={extraInfoStyle}>
                            {questionData.question.extra_info}
                          </p>
                        )}
                      </>
                    )}
                  </div>
                )}
              </>
            </>
          )}
        </DialogContent>
      </DialogOverlay>
    </Dialog.Portal>
  );
}
