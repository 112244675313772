import React, { useEffect, useState, useCallback, Children } from "react";
import { Transition } from "semantic-ui-react";
import { css } from "glamor";
import { RiArrowRightSLine, RiArrowLeftSLine } from "react-icons/ri";
import { typographyColors, styles, colors } from "./theme";

const DEFAULT_DURATION = 10000;
const ANIMATION_DURATION = 600;

const container = css(styles.roundedCorners, {
  width: "100%",
  position: "relative",
});
const arrowClass = css({
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "32px",
  position: "absolute",
  right: 0,
  zIndex: 3,
  cursor: "pointer",
  color: typographyColors.inverseTitle,
  backgroundColor: "rgb(0, 0, 0, 0.05)",
  opacity: 0.5,
  ":hover": {
    opacity: 1,
  },
});
const nextClass = css(arrowClass, {
  right: 0,
});
const pastClass = css(arrowClass, {
  left: 0,
});
const navigationDots = {
  cursor: 'pointer',
  padding: 0,
  height: 8,
  border: 'none',
  borderRadius: 8,
  transition: 'width 0.5s ease',
}
const paginationContainer = {
  display: 'flex',
  zIndex: 1,
  gap: 16,
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
  bottom: 15
}

function navigationDotColor(isFrial, isActive) {
  if (isFrial && isActive) {
    return colors.brand.light200
  } else if (isFrial && !isActive) {
    return colors.interface.grey200
  } else if (isActive) {
    return colors.interface.grey500
  }
  return colors.interface.grey300
}

function navigationDotWidth(isFrial, isActive) {
  if (isFrial && isActive) {
    return 24
  }
  return 8
}

export default function Carousel(props) {
  const {
    children,
    enablePause = true,
    duration = DEFAULT_DURATION,
    animation = "slide left",
    animationDuration = ANIMATION_DURATION,
    className = {},
    showPagination = false,
    onLoad,
    isFrial
  } = props;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [total, setTotal] = useState(0);
  const [pause, setPause] = useState(false);

  const nextItem = useCallback(() => {
    currentIndex == total
      ? setCurrentIndex(0)
      : setCurrentIndex(currentIndex + 1);
  }, [currentIndex, total]);
  const pastItem = useCallback(() => {
    currentIndex == 0
      ? setCurrentIndex(total)
      : setCurrentIndex(currentIndex - 1);
  }, [currentIndex, total]);

  useEffect(() => {
    setTotal(Children.count(children) - 1);

    const intervalId = setInterval(() => {
      !pause && setCurrentIndex(currentIndex === total ? 0 : currentIndex + 1);
    }, duration);

    if (onLoad) onLoad()
    return function cleanup() {
      clearInterval(intervalId);
    };
  }, [currentIndex, pause, total, children]);

  useEffect(() => {
    setCurrentIndex(0);
  }, [children]);

  const mouseHandler = useCallback(() => {
    enablePause && setPause(!pause);
  }, [pause, enablePause]);

  function jumpTo(index) {
    setCurrentIndex(index)
  }

  return (
    <div className={css(container, className)}>

      {showPagination &&
        <div style={paginationContainer}>
          {Array.apply(null, { length: total + 1 }).map((_, index) => (
            <button
              key={index}
              onClick={() => jumpTo(index)}
              style={{ ...navigationDots, backgroundColor: navigationDotColor(isFrial, index === currentIndex), width: navigationDotWidth(isFrial, index === currentIndex) }}
            />
          ))}
        </div>
      }

      {total > 0 && !showPagination && (
        <>
          <div className={nextClass} onClick={nextItem}>
            <RiArrowRightSLine size={28} />
          </div>
          <div className={pastClass} onClick={pastItem}>
            <RiArrowLeftSLine size={28} />
          </div>
        </>
      )}

      {Children.map(
        children,
        (element, index) =>
          currentIndex === index && (
            <div
              key={index}
              onMouseOver={mouseHandler}
              onMouseOut={mouseHandler}
            >
              <Transition
                transitionOnMount={true}
                visible={true}
                animation={animation}
                duration={animationDuration}
              >
                {element}
              </Transition>
            </div>
          )
      )}
    </div>
  );
}
