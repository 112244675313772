import React from "react";
import { css } from "glamor";
import { spacing } from "./theme";

const actionBaseClass = css({
  // marginRight: 20,
  marginLeft: "auto",
  textAlign: "right",
});
const mobileActionClass = css(actionBaseClass, {
  marginBottom: spacing.m,
  width: "100%",
});

export function PageAction({ content, isMobile, mobile = false }) {
  return content && isMobile === mobile ? (
    <div className={css(actionBaseClass, isMobile && mobileActionClass)}>
      {content}
    </div>
  ) : null;
}
