import * as React from "react";

export function BadFace(props) {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="#606A71">
        <path d="M34.139 5.86A19.939 19.939 0 105.982 34.1 19.939 19.939 0 0034.14 5.86zM20 37.668C10.258 37.667 2.333 29.74 2.333 20c0-9.742 7.925-17.667 17.667-17.667 9.741 0 17.667 7.925 17.667 17.667 0 9.741-7.926 17.667-17.667 17.667z" />
        <path d="M12.133 30.733a1.165 1.165 0 01-.828-1.99c.151-.152 3.766-3.745 8.72-3.873 3.131-.08 6.048 1.223 8.67 3.873a1.165 1.165 0 01-.379 1.916 1.167 1.167 0 01-1.279-.275c-2.151-2.175-4.49-3.246-6.953-3.182-3.999.103-7.092 3.154-7.125 3.185a1.163 1.163 0 01-.826.346zM25.764 11.88c-1.176 0-2.13 1.607-2.13 3.585s.954 3.585 2.13 3.585c1.177 0 2.131-1.607 2.131-3.585s-.955-3.584-2.13-3.584zm-11.528 0c-1.176 0-2.132 1.607-2.132 3.585s.956 3.585 2.132 3.585c1.176 0 2.13-1.607 2.13-3.585s-.954-3.584-2.13-3.584z" />
      </g>
      <defs>
        <clipPath id="clip0_8931_1111">
          <path fill="#fff" d="M0 0H40V40H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
