import styled, { css } from "styled-components";
import { colors } from "../../../../components/library/theme";

export const Container = styled.section`
  height: 100%;
`;

export const InfoBoxFrial = styled.div`
  width: 100%;
  margin: 16px 0;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  transition: all 0.3s ease;
  background-color: ${colors.medtrack.green000};
  border: 1px solid transparent;
  border-color: ${(props) =>
    props.isRevisionModeActive ? colors.medtrack.green200 : colors.medtrack.green100};
  border-radius: 4px;

  p {
    font-size: 12px;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-self: flex-start;
  grid-area: title;
  flex: 1;

  h3 {
    color: ${colors.medtrack.grey600};
    font-size: 16px;
    font-weight: ${(props) => (props.isRevisionModeActive ? 700 : 600)};
    line-height: 24px;
    margin-bottom: 0;
  }

  svg {
    flex-shrink: 0;
  }

  @media (max-width: 740px) {
    & > span {
      display: none;
    }
  }
`;

export const Header = styled.header`
  margin: 16px 24px;
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  gap: 0px 24px;
  align-items: center;
  justify-content: space-between;
  transition: all 0.3s ease;
  background: ${(props) =>
    props.isRevisionModeActive ? colors.medtrack.green000 : "none"};
  border: 1px solid transparent;
  border-color: ${(props) =>
    props.isRevisionModeActive ? colors.medtrack.green200 : "transparent"};
  padding: ${(props) => (props.isRevisionModeActive ? "8px 24px" : "8px 0")};

  @media (max-width: 740px) {
    margin: 0;
    padding: 24px 24px 16px;
    gap: 16px 0px;
    height: auto;
    border: none;
    background: none;

    & > div:first-of-type {
      display: flex;
    }
    & > button:last-child {
      margin-left: 16px;
    }
  }
`;

export const MedtrackRevisionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  transition: all 0.3s ease;

  h3 {
    color: ${colors.medtrack.grey600};
  }

  @media (max-width: 740px) {
    order: 1;
    width: 100%;
    height: 40px;
    padding: 0;
  }
`;

export const MedtrackRevisionMode = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  i {
    color: ${colors.medtrack.grey600};
    font-size: 14px;
    line-height: 1.5;
    font-weight: 600;
    user-select: none;
  }

  @media (max-width: 740px) {
    order: 1;
    width: 100%;
    height: 40px;
    background: ${(props) =>
    props.isRevisionModeActive ? colors.medtrack.green000 : "none"};
    border: 1px solid transparent;
    border-color: ${(props) =>
    props.isRevisionModeActive ? colors.medtrack.green200 : "transparent"};
    border-radius: 4px;
    transition: all 0.3s ease;

    & > span {
      display: none;
    }
  }
`;

export const RevisionSwitch = styled.div`
  display: inline-block;
  height: 20px;
  position: relative;
  width: 40px;
  box-shadow: 0px 1px 2px 0px #1018280f, 0px 1px 3px 0px #1018281a;
  border-radius: 20px;
  border: 1px solid;
  transition: all 0.2s ease;

  &[data-active="true"] {
    border-color: ${colors.medtrack.green150};
  }

  &[data-active="false"] {
    border-color: ${colors.interface.grey150};
  }

  &:hover {
    border-color: ${colors.medtrack.green100};

    &[data-active="true"] {
      border-color: ${colors.medtrack.green150};
    }

    input + div {
      background-color: ${colors.medtrack.grey300};
    }

    input:checked + div {
      background-color: ${colors.medtrack.green150};
    }
  }

  input {
    display: none;
  }

  input:checked + div {
    background-color: ${colors.medtrack.green150};
  }

  input:checked + div:before {
    transform: translateX(20px);
  }

  input:disabled + div {
    cursor: not-allowed;
  }
`;

export const CheckboxSlider = styled.div`
  background-color: ${colors.interface.grey150};
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;
  border-radius: 17px;

  :before {
    background-color: ${colors.interface.white000};
    bottom: 1px;
    left: 1px;
    content: "";
    width: 16px;
    height: 16px;
    position: absolute;
    transition: 0.4s;
    border-radius: 50%;
  }
`;

export const ContentContainer = styled.div`
  padding: 24px;
  gap: 16px;
  display: flex;
  flex-direction: column;
  height: 100%;

  @media (max-width: 991px) {
    height: auto;
    padding-top: 16px;
  }
`;

export const FooterContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;

  @media (max-width: 992px) {
    align-self: center;
    width: fit-content;
  }
`;

export const ConfigurationButton = styled.button`
  width: 40px;
  height: 40px;
  background-color: ${colors.interface.grey150};
  transition: all 0.2s ease;
  border: none;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  :hover {
    background-color: ${colors.interface.grey200};
  }
`;

export const ConfigurationButtonWrapper = styled.div`
  position: relative;
`;

export const ConfigurationDropdown = styled.div`
  width: 316px;
  position: absolute;
  background-color: ${colors.interface.grey100};
  display: flex;
  flex-direction: column;
  gap: 1px;
  top: 43px;
  right: 0;
  z-index: 10;
  border-radius: 4px;
  overflow: hidden;
  gap: 1px;
  border: 1px ${colors.interface.grey100} solid;
  box-shadow: 0px 2px 4px 0px #2727271a;
`;

export const OpitionConfigurationDropdown = styled.button`
  width: 100%;
  height: 59px;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  transition: all 0.2s ease;
  border: none;
  cursor: pointer;
  font-size: 14px;
  color: ${colors.medtrack.grey400};
  background-color: ${colors.interface.white000};
  font-weight: 400;
  font-family: "Poppins";
  padding: 0px 24px;
  :hover {
    background-color: ${colors.brand.light100};
    color: ${colors.medtrack.grey500};

    svg {
      fill: ${colors.medtrack.grey500};
    }
  }
`;
