import styled, { css } from "styled-components";
import { colors } from "../../../../components/library/theme";
import { REGISTER_DEVICE_STEPS } from "../../enum";

function gestStepBgColor(current, hasPassed) {
  if (current) {
    return {
      bg: colors.brand.stepper,
      icon: colors.interface.white000,
      border: "transparent",
    };
  } else if (hasPassed) {
    return {
      bg: colors.brand.pure000,
      icon: colors.brand.stepper,
      border: colors.brand.stepper,
    };
  }
  return {
    bg: colors.interface.grey100,
    icon: colors.interface.grey200,
    border: "transparent",
  };
}

function progressBarCalculate(currentStep) {
  switch (currentStep) {
    case REGISTER_DEVICE_STEPS.SUCCESS:
      return "100%";
    default:
      return "50%";
  }
}

export const StepsWrapper = styled.div`
  width: 100%;
  max-width: 532px;
  align-items: center;
  justify-content: space-between;
  display: flex;
  position: relative;
`;

export const StepContainer = styled.div(
  ({ isActive }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    span {
      font-size: 14px;
      font-weight: ${({ isActive }) => (isActive ? "600" : "400")};
      color: ${isActive ? colors.brand.stepper : "#606A71"};
      max-width: 102px;
      line-height: 21px;
      text-align: center;
    }
    @media screen and (max-width: 768px) {
      span {
        font-size: 12px;
        line-height: ${({ isActive }) => (isActive ? "15.6px" : "18px")};
      }
    }
  `
);
export const Step = styled.div(
  ({ isActive, hasPassed }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border: 1px solid transparent;
    border-radius: 999px;
    background-color: ${gestStepBgColor(isActive, hasPassed).bg};
    border-color: ${gestStepBgColor(isActive, hasPassed).border};

    svg {
      color: ${gestStepBgColor(isActive, hasPassed).icon};
    }

    @media screen and (max-width: 768px) {
      width: 32px;
      height: 32px;
    }
  `
);

export const Line = styled.hr(
  ({ currentStep }) => css`
    border: none;
    position: absolute;
    width: calc(100% - 56px);
    top: 18px;
    z-index: -1;
    left: 28px;
    height: 2px;
    background-image: linear-gradient(
      to right,
      ${colors.brand.stepper} ${progressBarCalculate(currentStep)},
      ${colors.medtrack.grey100} ${progressBarCalculate(currentStep)}
    );

    @media screen and (max-width: 768px) {
      left: 36px;
      width: calc(100% - 72px);
      top: 9px;
    }
  `
);
