import styled, { css } from "styled-components";
import { tagVariants } from ".";
import { colors } from "../../../../../components/library/theme";

export const Tag = styled.div(
  ({ variant, isDisabled }) => css`
    display: flex;
    height: 32px;
    padding: 0 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 58px;
    background-color: ${isDisabled
      ? colors.medtrack.grey100
      : tagVariants[variant].bgColor};

    span {
      font-size: 12px;
      line-height: 18px;
      color: ${isDisabled
        ? colors.medtrack.grey400
        : tagVariants[variant].textColor};
    }

    svg {
      width: 16px;
      height: 16px;
    }

    svg path {
      fill: ${isDisabled
        ? colors.medtrack.grey400
        : tagVariants[variant].colors.iconColor};
    }

    @media screen and (max-width: 460px) {
      width: 100%;
    }
  `
);

export const DescriptionContainer = styled.div`
  margin-left: 24px;
  display: flex;
  gap: 4px;
  align-items: center;

  svg {
    flex-shrink: 0;
  }

  @media screen and (max-width: 740px) {
    display: none;
    margin-left: 0px;
  }
`;
