const getOrganizers = (action) => action.entities.organizers;

export default function organizersReducer(state = {}, action) {
  switch (action.type) {
    case "ORGANIZERS_FETCHED":
      return { ...state, ...getOrganizers(action) };
    default:
      return state;
  }
}
