import React from "react";

export function Illustration() {
  return (
    <svg
      width="298"
      height="298"
      viewBox="0 0 298 298"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1168_17618)">
        <path
          d="M93.6375 19.4236C93.6375 19.4236 43.0284 45.2124 17.7873 84.9477C-7.45382 124.683 -5.4567 175.786 25.2147 213.711C55.8861 251.637 114.51 246.687 163.597 270.733C212.683 294.778 266.839 289.822 290.049 238.899C313.26 187.976 278.26 168.92 272.249 110.002C266.238 51.0836 213.117 -35.1797 93.6375 19.4236Z"
          fill="#92E3A9"
        />
        <path
          opacity="0.7"
          d="M93.6375 19.4236C93.6375 19.4236 43.0284 45.2124 17.7873 84.9477C-7.45382 124.683 -5.4567 175.786 25.2147 213.711C55.8861 251.637 114.51 246.687 163.597 270.733C212.683 294.778 266.839 289.822 290.049 238.899C313.26 187.976 278.26 168.92 272.249 110.002C266.238 51.0836 213.117 -35.1797 93.6375 19.4236Z"
          fill="white"
        />
        <path
          d="M295.359 151.507H292.588V151.033C292.586 149.738 292.071 148.497 291.155 147.582C290.24 146.667 288.999 146.152 287.705 146.15H285.828C284.532 146.15 283.29 146.664 282.373 147.58C281.456 148.495 280.941 149.737 280.939 151.033V151.507H278.174C277.804 151.506 277.438 151.578 277.096 151.719C276.755 151.86 276.445 152.067 276.183 152.329C275.922 152.59 275.715 152.9 275.574 153.242C275.433 153.583 275.361 153.949 275.362 154.319V167.13C275.362 167.876 275.658 168.591 276.185 169.118C276.713 169.646 277.428 169.942 278.174 169.942H295.359C296.105 169.94 296.819 169.643 297.346 169.116C297.873 168.589 298.17 167.875 298.171 167.13V154.319C298.171 153.573 297.875 152.858 297.348 152.331C296.82 151.803 296.105 151.507 295.359 151.507ZM282.275 151.033C282.276 150.091 282.652 149.189 283.318 148.524C283.984 147.859 284.887 147.486 285.828 147.486H287.705C288.646 147.486 289.548 147.86 290.213 148.525C290.878 149.19 291.252 150.092 291.252 151.033V151.507H282.275V151.033Z"
          fill="#263238"
        />
        <path
          d="M291.886 159.415H288.346V155.882H285.18V159.415H281.64V162.588H285.18V166.121H288.346V162.588H291.886V159.415Z"
          fill="white"
        />
        <path
          d="M289.883 75.1624C289.332 75.1624 288.787 75.0539 288.278 74.843C287.77 74.6321 287.308 74.3231 286.919 73.9335C286.53 73.5439 286.222 73.0814 286.011 72.5725C285.801 72.0637 285.694 71.5184 285.695 70.9678V59.6664C285.694 59.1159 285.801 58.5706 286.011 58.0617C286.222 57.5529 286.53 57.0904 286.919 56.7008C287.308 56.3112 287.77 56.0021 288.278 55.7913C288.787 55.5804 289.332 55.4718 289.883 55.4718C290.433 55.4718 290.978 55.5804 291.487 55.7913C291.995 56.0021 292.457 56.3112 292.846 56.7008C293.235 57.0904 293.543 57.5529 293.754 58.0617C293.964 58.5706 294.071 59.1159 294.07 59.6664V70.9478C294.074 71.5 293.968 72.0475 293.759 72.5587C293.55 73.0699 293.243 73.5347 292.853 73.9264C292.464 74.3181 292.001 74.629 291.491 74.8411C290.981 75.0533 290.435 75.1625 289.883 75.1624Z"
          fill="white"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M289.883 75.1624C289.332 75.1624 288.787 75.0539 288.278 74.843C287.77 74.6321 287.308 74.3231 286.919 73.9335C286.53 73.5439 286.222 73.0814 286.011 72.5725C285.801 72.0637 285.694 71.5184 285.695 70.9678V65.3238H294.07V70.9678C294.071 71.5184 293.964 72.0637 293.754 72.5725C293.543 73.0814 293.235 73.5439 292.846 73.9335C292.457 74.3231 291.995 74.6321 291.487 74.843C290.978 75.0539 290.433 75.1624 289.883 75.1624Z"
          fill="#263238"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M268.815 58.5176C268.471 58.0875 268.215 57.5936 268.062 57.0643C267.909 56.5349 267.862 55.9806 267.925 55.4332C267.987 54.8857 268.156 54.3559 268.424 53.8743C268.691 53.3926 269.051 52.9685 269.483 52.6264L278.28 45.5597C279.151 44.8673 280.261 44.5487 281.367 44.674C282.473 44.7992 283.484 45.358 284.178 46.2277C284.87 47.0977 285.188 48.2069 285.063 49.3115C284.938 50.4161 284.379 51.4258 283.51 52.1188L274.706 59.1789C274.276 59.5227 273.783 59.7785 273.254 59.9314C272.725 60.0843 272.171 60.1314 271.624 60.0699C271.077 60.0085 270.547 59.8398 270.065 59.5734C269.583 59.3071 269.158 58.9483 268.815 58.5176Z"
          fill="white"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M268.815 58.5176C268.471 58.0875 268.215 57.5936 268.062 57.0643C267.909 56.535 267.862 55.9806 267.925 55.4332C267.987 54.8857 268.156 54.3559 268.424 53.8743C268.691 53.3926 269.051 52.9685 269.483 52.6265L273.885 49.0931L279.128 55.6322L274.726 59.1588C274.297 59.5069 273.803 59.7666 273.273 59.9229C272.743 60.0791 272.187 60.1289 271.637 60.0693C271.088 60.0097 270.556 59.8419 270.071 59.5756C269.587 59.3093 269.16 58.9497 268.815 58.5176Z"
          fill="#263238"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M226.93 27.7661C226.408 27.7663 225.899 27.9298 225.474 28.2336L215.743 20.6793C216.219 19.7966 216.443 18.7992 216.389 17.7973C216.335 16.7954 216.005 15.828 215.435 15.0019L221.38 7.40085C221.883 7.59484 222.435 7.62268 222.955 7.4803C223.475 7.33791 223.936 7.03278 224.27 6.60958C224.604 6.18637 224.794 5.66732 224.812 5.12843C224.829 4.58953 224.674 4.0591 224.369 3.61478C224.063 3.17047 223.624 2.83561 223.114 2.65925C222.605 2.48288 222.052 2.47427 221.537 2.63466C221.022 2.79506 220.572 3.11605 220.253 3.55063C219.934 3.9852 219.762 4.51054 219.763 5.04973C219.76 5.56937 219.922 6.07667 220.224 6.49914L214.44 13.9065C213.664 13.2533 212.722 12.8292 211.719 12.6818C210.716 12.5343 209.691 12.6693 208.76 13.0716C207.829 13.4738 207.029 14.1275 206.449 14.9593C205.869 15.7911 205.532 16.768 205.476 17.7805H198.717C198.643 17.1805 198.356 16.6269 197.909 16.2203C197.462 15.8138 196.883 15.5812 196.279 15.565C195.675 15.5487 195.085 15.7498 194.616 16.1317C194.147 16.5136 193.831 17.051 193.725 17.6462C193.619 18.2413 193.73 18.8548 194.038 19.375C194.346 19.8952 194.831 20.2876 195.404 20.481C195.976 20.6743 196.599 20.6558 197.16 20.4286C197.72 20.2015 198.18 19.7809 198.456 19.2433H205.603C205.812 20.2144 206.282 21.1102 206.962 21.8343C207.643 22.5583 208.507 23.0832 209.464 23.3525C210.42 23.6217 211.431 23.6252 212.389 23.3625C213.347 23.0998 214.216 22.5809 214.901 21.8616L224.579 29.3891C224.388 29.8912 224.363 30.4412 224.507 30.9588C224.651 31.4763 224.956 31.9343 225.379 32.2657C225.802 32.5972 226.319 32.7848 226.856 32.8011C227.393 32.8174 227.921 32.6616 228.363 32.3564C228.806 32.0513 229.138 31.6127 229.314 31.1049C229.489 30.597 229.497 30.0465 229.337 29.5337C229.176 29.021 228.857 28.5728 228.424 28.2548C227.991 27.9368 227.468 27.7655 226.93 27.7661Z"
          fill="#263238"
        />
        <path
          d="M29.2421 75.2426C28.4331 75.2408 27.6577 74.9187 27.0857 74.3466C26.5136 73.7746 26.1915 72.9992 26.1897 72.1902V46.8088H32.2946V72.1902C32.2946 72.9997 31.973 73.7761 31.4005 74.3486C30.8281 74.921 30.0517 75.2426 29.2421 75.2426Z"
          fill="white"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.2421 75.2426C28.4331 75.2409 27.6577 74.9187 27.0857 74.3466C26.5136 73.7746 26.1915 72.9992 26.1897 72.1902V56.5806H32.2946V72.1902C32.2946 72.9997 31.973 73.7761 31.4005 74.3486C30.8281 74.921 30.0517 75.2426 29.2421 75.2426Z"
          fill="#263238"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M33.1692 45.326H25.321V47.5502H33.1692V45.326Z"
          fill="white"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.9912 75.2426C19.1821 75.2408 18.4068 74.9187 17.8347 74.3466C17.2626 73.7746 16.9405 72.9992 16.9387 72.1902V46.8088H23.0436V72.1902C23.0436 72.9997 22.722 73.7761 22.1496 74.3486C21.5771 74.921 20.8007 75.2426 19.9912 75.2426Z"
          fill="white"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.9912 75.2426C19.1821 75.2409 18.4068 74.9187 17.8347 74.3466C17.2626 73.7746 16.9405 72.9992 16.9387 72.1902V56.5806H23.0436V72.1902C23.0436 72.9997 22.722 73.7761 22.1496 74.3486C21.5771 74.921 20.8007 75.2426 19.9912 75.2426Z"
          fill="#263238"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.9185 45.326H16.0703V47.5502H23.9185V45.326Z"
          fill="white"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M108.057 20.3053L110.489 14.4676C110.53 14.3735 110.598 14.294 110.685 14.2392C110.772 14.1845 110.873 14.157 110.976 14.1603C111.079 14.1654 111.178 14.2022 111.259 14.2655C111.34 14.3289 111.4 14.4158 111.43 14.5143L114.376 24.226L116.62 17.0725C116.651 16.9718 116.713 16.8835 116.797 16.8199C116.881 16.7564 116.982 16.7209 117.088 16.7185C117.192 16.7213 117.293 16.7544 117.378 16.8137C117.463 16.8731 117.53 16.956 117.569 17.0524L118.818 20.6459H125.57C126.952 19.2016 127.756 17.3001 127.828 15.3025C127.841 13.4365 127.164 11.6317 125.927 10.2345C124.69 8.83727 122.981 7.94626 121.127 7.73256C119.274 7.51887 117.406 7.99755 115.884 9.07669C114.362 10.1558 113.292 11.7593 112.88 13.5792C112.455 11.7721 111.382 10.1839 109.863 9.11684C108.344 8.04975 106.486 7.57815 104.642 7.79174C102.798 8.00533 101.097 8.8892 99.8619 10.2753C98.6271 11.6613 97.9448 13.4529 97.9448 15.3091C98.019 17.1592 98.713 18.9305 99.9152 20.3387L108.057 20.3053Z"
          fill="#263238"
        />
        <path
          d="M118.464 21.6478C118.36 21.6482 118.258 21.6163 118.174 21.5565C118.089 21.4966 118.024 21.4118 117.99 21.3139L117.121 18.8158L114.844 26.0762C114.811 26.1785 114.747 26.2678 114.661 26.3314C114.574 26.395 114.47 26.4296 114.363 26.4302C114.255 26.4298 114.151 26.3944 114.065 26.3294C113.98 26.2643 113.918 26.173 113.889 26.0695L110.876 16.1374L108.872 20.9999C108.834 21.0908 108.77 21.1684 108.688 21.223C108.606 21.2777 108.51 21.307 108.412 21.3072H100.724C102.373 23.1507 104.431 24.7804 105.967 26.2966C108.291 28.5943 110.569 30.9721 112.94 33.1897C115.979 30.1907 119.038 27.1783 122.051 24.1793C122.886 23.3444 123.794 22.5161 124.636 21.6478H118.464Z"
          fill="#263238"
        />
        <path
          d="M5.7903 170.939L9.06543 172.075L12.6093 161.859L9.33412 160.723L5.7903 170.939Z"
          fill="white"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeMiterlimit="10"
        />
        <path
          d="M4.23756 172.561L9.29224 174.315L9.95985 172.39L4.90517 170.637L4.23756 172.561Z"
          fill="white"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeMiterlimit="10"
        />
        <path
          d="M5.72204 163.352L13.8184 166.16L20.2384 147.652L12.1421 144.843L5.72204 163.352Z"
          fill="white"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeMiterlimit="10"
        />
        <path
          d="M9.659 155.434L15.635 157.507L19.1788 147.29L13.2028 145.218L9.659 155.434Z"
          fill="#263238"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeMiterlimit="10"
        />
        <path
          d="M8.60182 162.84L11.877 163.976L15.4208 153.76L12.1456 152.624L8.60182 162.84Z"
          fill="#263238"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeMiterlimit="10"
        />
        <path
          d="M5.15023 163.158L14.4014 166.367L15.2047 164.051L5.95356 160.842L5.15023 163.158Z"
          fill="white"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeMiterlimit="10"
        />
        <path
          d="M14.1168 144.458L18.938 146.13L20.0084 143.044L15.1872 141.372L14.1168 144.458Z"
          fill="white"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeMiterlimit="10"
        />
        <path
          d="M11.3682 145.226L20.6194 148.435L21.4227 146.119L12.1716 142.91L11.3682 145.226Z"
          fill="white"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeMiterlimit="10"
        />
        <path
          d="M17.6472 141.789L21.5212 130.607"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeMiterlimit="10"
        />
        <path
          d="M233.041 280.752C252.345 280.752 267.994 277.223 267.994 272.87C267.994 268.517 252.345 264.988 233.041 264.988C213.738 264.988 198.089 268.517 198.089 272.87C198.089 277.223 213.738 280.752 233.041 280.752Z"
          fill="#263238"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M206.09 94.7195C206.09 94.7195 201.361 97.6116 201.414 98.8807C201.468 100.15 199.651 123.294 199.444 126.186C199.237 129.078 196.485 135.049 196.271 137.761C196.058 140.473 196.739 143.191 197.534 144.44C198.329 145.689 201.247 149.684 201.247 149.684L209.096 137.561C209.288 134.913 209.328 132.257 209.216 129.606C209.096 123.828 206.09 94.7195 206.09 94.7195Z"
          fill="white"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M236.287 37.3709C236.287 37.3709 227.938 36.8366 223.182 42.6275C218.427 48.4185 219.449 57.2753 219.415 59.9937C219.382 62.7122 211.306 70.6806 212.201 73.9268C213.097 77.1729 215.474 77.5403 214.713 80.2521C213.951 82.9639 208.982 84.5936 209.703 88.0335C210.425 91.4733 213.043 96.3492 213.043 96.3492L261.134 96.6765C261.134 96.6765 262.851 91.6136 261.021 87.8131C259.19 84.0125 256.592 82.7568 257.013 80.9467C257.434 79.1366 259.758 74.7951 258.863 71.5423C257.968 68.2894 254.856 67.3877 254.528 61.5968C254.201 55.8058 253.346 46.2344 248.751 41.5588C244.155 36.8833 236.287 37.3709 236.287 37.3709Z"
          fill="#263238"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M221.499 260.533C220.022 263.379 218.23 266.05 216.156 268.495C213.083 271.935 210.104 273.745 210.205 275.555C210.305 277.365 217.011 277.719 220.558 276.45C224.104 275.181 226.682 272.65 227.397 272.443C228.112 272.235 230.109 272.443 230.156 269.904C230.202 267.366 229.815 260.139 229.815 260.139L221.499 260.533Z"
          fill="white"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M220.544 276.45C224.097 275.181 226.669 272.65 227.383 272.443C228.098 272.236 230.095 272.443 230.142 269.904C230.142 268.569 230.068 265.897 229.975 263.713C229.449 264.698 228.672 265.527 227.721 266.113C226.771 266.7 225.681 267.024 224.565 267.052C224.565 267.052 224.498 265.783 222.561 263.619C221.312 262.203 220.05 263.319 219.315 264.287L218.72 265.182L218.674 265.269C217.903 266.406 217.059 267.493 216.149 268.522C213.076 271.962 210.097 273.772 210.198 275.582C210.298 277.392 216.997 277.719 220.544 276.45Z"
          fill="white"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M246.246 260.52C248.041 263.376 250.123 266.041 252.458 268.475C255.898 271.908 259.071 273.712 259.137 275.522C259.204 277.332 252.565 277.693 248.878 276.43C245.191 275.168 242.346 272.636 241.618 272.456C240.89 272.276 238.899 272.456 238.585 269.931C238.271 267.406 237.877 260.166 237.877 260.166L246.246 260.52Z"
          fill="white"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M248.905 276.43C245.224 275.168 242.372 272.636 241.644 272.456C240.916 272.276 238.926 272.456 238.612 269.931C238.445 268.595 238.258 265.923 238.117 263.733C238.735 264.712 239.584 265.525 240.589 266.1C241.594 266.676 242.724 266.996 243.882 267.032C243.882 267.032 243.815 265.763 245.505 263.592C246.607 262.183 247.989 263.292 248.844 264.26C249.065 264.554 249.292 264.848 249.512 265.155L249.572 265.242C250.47 266.378 251.429 267.464 252.445 268.495C255.884 271.928 259.057 273.732 259.124 275.542C259.191 277.352 252.591 277.692 248.905 276.43Z"
          fill="white"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M206.972 165.066C206.972 165.066 208.087 202.677 209.57 210.091C211.053 217.505 217.672 259.999 217.672 259.999C219.695 260.855 221.874 261.283 224.071 261.255H231.304L231.972 229.608L232.219 183.675L235.846 261.428C235.846 261.428 245.812 261.782 246.887 261.602C247.963 261.422 248.858 261.241 248.858 261.241L255.263 212.048L258.93 179.32L259.13 166.302C259.13 166.302 228.512 165.24 219.616 164.518C210.719 163.797 207.059 163.617 207.059 163.617L206.972 165.066Z"
          fill="#92E3A9"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M248.317 231.472C248.237 232.1 248.156 232.681 248.063 233.215"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M249.139 186.193C249.139 186.193 249.76 212.99 248.671 227.538"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M216.31 184.042C216.31 184.042 217.078 201.769 217.839 205.743"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M244.851 172.747V186.279C246.584 186.746 248.357 187.053 250.147 187.195C252.892 187.375 258.016 187.195 258.016 187.195L258.383 182.071L244.851 172.747Z"
          fill="#263238"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M207.539 183.167C207.539 183.167 214.493 185.364 217.418 185.545C220.344 185.725 223.082 186.092 223.082 186.092L222.722 182.439L207.172 180.976L207.539 183.167Z"
          fill="#263238"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M228.646 80.4191L223.189 86.5707L208.054 97.6116L211.28 164.532C211.28 164.532 223.343 167.778 233.696 168.679C244.049 169.581 257.361 167.21 257.361 167.21L255.404 97.0773C255.404 97.0773 249.512 88.3942 246.493 86.0497C243.474 83.7053 242.092 81.5345 238.091 81.1738C234.09 80.8131 228.646 80.4191 228.646 80.4191Z"
          fill="#92E3A9"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M222.314 83.712L206.09 94.7529L208.635 135.437L207.773 139.524L200.954 147.379L203.859 161.179L202.563 180.649C202.563 180.649 209.009 182.82 213.377 183.354C216.63 183.702 219.898 183.883 223.169 183.895L223.73 157.311C223.73 157.311 225.887 113.181 226.141 111.224C226.395 109.267 228.646 80.4992 228.646 80.4992L222.314 83.712Z"
          fill="white"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M222.575 85.3751L214.72 105.072L223.196 108.592L215.194 110.048L222.281 137.814"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M227.283 75.1758C227.283 75.1758 230.102 93.9781 232.32 98.3196C234.537 102.661 235.165 104.104 235.165 104.104C235.165 104.104 239.987 89.4495 240.956 87.2787C241.924 85.1079 242.96 77.3332 242.96 77.3332C242.96 77.3332 233.348 80.4124 227.283 75.1758Z"
          fill="white"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M227.283 75.1758C227.283 75.1758 227.537 76.8857 227.951 79.3905C229.361 80.4658 233.094 83.1242 235.853 83.2645C237.957 83.3713 240.629 82.0087 242.405 80.92C242.726 78.9496 242.946 77.3332 242.946 77.3332C242.946 77.3332 233.348 80.4124 227.283 75.1758Z"
          fill="#263238"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M223.457 62.1779C223.457 62.1779 221.533 59.4393 220.238 62.1779C218.942 64.9164 222.241 68.9707 223.163 69.5251C225.781 71.0814 225.087 68.1892 225.087 68.1892L223.457 62.1779Z"
          fill="white"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M247.348 63.3601C247.348 63.3601 248.972 60.5281 250.561 63.1998C252.151 65.8715 249.285 70.0728 248.431 70.6673C245.986 72.3571 246.373 69.4583 246.373 69.4583L247.348 63.3601Z"
          fill="white"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M223.002 59.76C223.002 59.76 229.976 58.1302 233.489 56.0529C235.641 54.7532 237.518 53.0445 239.013 51.0234C239.013 51.0234 242.032 56.7275 244.69 59.0386C247.349 61.3496 247.903 61.4832 247.903 61.4832C247.903 61.4832 246.627 71.4688 245.184 74.942C243.742 78.4153 239.033 81.795 234.638 80.8198C232.14 80.2721 227.812 78.4687 226.556 75.4296C225.3 72.3905 223.002 59.76 223.002 59.76Z"
          fill="white"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M240.796 67.4345C238.632 67.4879 231.385 67.5013 227.644 65.2437C226.145 64.3709 224.741 63.3433 223.456 62.1779C223.59 62.8458 223.75 63.6807 223.924 64.549L224.739 65.1435L226.783 72.8648L225.827 73.0718C226.029 73.8611 226.272 74.6392 226.556 75.4029C227.811 78.442 232.139 80.2454 234.637 80.7931C239.026 81.7616 243.721 78.4019 245.177 74.9354C244.834 74.2619 244.732 73.4906 244.89 72.7512C245.311 70.5671 245.832 66.7398 245.832 66.7398L247.321 65.404C247.402 64.9164 247.468 64.4488 247.535 64.0214C245.732 65.2704 242.352 67.3944 240.796 67.4345Z"
          fill="#92E3A9"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M232.841 64.2084C232.841 64.2084 230.51 61.3763 227.45 61.6368"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M238.846 64.7694C239.589 63.9874 240.513 63.3996 241.537 63.0572C242.56 62.7149 243.652 62.6285 244.717 62.8057"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M226.108 60.2208C226.108 60.2208 229.448 58.885 232.407 59.5128"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M239.119 60.2409C239.999 59.7913 240.996 59.6232 241.974 59.7595C242.953 59.8957 243.866 60.3296 244.589 61.0023"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M230.596 63.28C230.611 63.3826 230.606 63.4872 230.58 63.5878C230.555 63.6884 230.51 63.783 230.448 63.8662C230.386 63.9493 230.308 64.0194 230.219 64.0724C230.13 64.1254 230.031 64.1603 229.928 64.175C229.719 64.1515 229.527 64.0493 229.391 63.8893C229.254 63.7293 229.184 63.5234 229.193 63.3134C229.177 63.2106 229.182 63.1056 229.207 63.0047C229.232 62.9037 229.277 62.8088 229.339 62.7254C229.401 62.6421 229.48 62.572 229.569 62.5192C229.659 62.4665 229.758 62.4322 229.861 62.4183C230.07 62.4433 230.261 62.5459 230.397 62.7055C230.533 62.8652 230.604 63.0703 230.596 63.28Z"
          fill="#263238"
        />
        <path
          d="M242.693 64.1483C242.711 64.2513 242.708 64.3569 242.684 64.4586C242.66 64.5603 242.615 64.6559 242.552 64.7397C242.49 64.8235 242.411 64.8937 242.32 64.9459C242.23 64.9981 242.129 65.0312 242.025 65.0433C241.817 65.0214 241.625 64.9206 241.488 64.7617C241.352 64.6028 241.281 64.3977 241.291 64.1884C241.273 64.0854 241.276 63.9798 241.3 63.8781C241.325 63.7764 241.369 63.6807 241.432 63.5969C241.494 63.5131 241.573 63.443 241.664 63.3908C241.755 63.3386 241.855 63.3054 241.959 63.2933C242.167 63.3152 242.359 63.416 242.496 63.5749C242.632 63.7338 242.703 63.939 242.693 64.1483Z"
          fill="#263238"
        />
        <path
          d="M260.68 90.8855C260.68 90.8855 264.574 92.689 266.023 95.5611C267.473 98.4332 279.061 129.552 279.008 131.903C278.954 134.254 275.12 136.966 271.527 137.333C267.933 137.701 262.697 131.189 261.915 130.106C261.134 129.024 260.994 116.36 260.994 116.36L260.68 90.8855Z"
          fill="white"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M242.326 82.5765L244.223 144.788L244.891 184.029C248.527 184.923 252.253 185.405 255.998 185.465C259.122 185.53 262.227 184.976 265.136 183.835C265.136 183.835 264 159.422 263.8 155.441C263.599 151.46 262.277 143.866 262.277 143.866L260.7 90.8856C260.7 90.8856 246.026 84.0192 242.326 82.5765Z"
          fill="white"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M246.493 86.0097L253.219 107.951L247.088 109.614L253.052 112.506L245.612 136.145"
          fill="white"
        />
        <path
          d="M246.493 86.0097L253.219 107.951L247.088 109.614L253.052 112.506L245.612 136.145"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M251.182 106.081L242.84 146.05C242.84 146.05 248.544 147.853 252.712 148.054C256.88 148.254 265.563 148.227 265.563 148.227L274.246 110.97C274.246 110.97 257.153 109.334 251.182 106.081Z"
          fill="#263238"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M254.088 143.331L254.362 130.841L260.981 132.237C260.981 132.237 256.606 141.875 254.088 143.331Z"
          fill="white"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M259.157 126.326C259.157 126.326 257.394 123.801 256.826 123.253C256.259 122.706 253.453 121.089 252.912 121.089C252.371 121.089 249.466 120.909 248.905 120.909C248.344 120.909 247.709 122.178 248.284 122.719C248.858 123.26 250.855 123.441 250.855 123.441L252.224 125.444L246.046 124.903C246.046 124.903 245.017 125.992 245.251 126.907C245.426 127.527 245.649 128.132 245.919 128.717C245.896 130.045 245.965 131.373 246.126 132.691C246.367 133.78 247.462 134.141 248.965 135.042C250.468 135.944 255.497 135.403 255.497 135.403H257.822L259.157 126.326Z"
          fill="white"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M274.219 127.582L258.189 125.424C258.189 125.424 256.94 135.737 255.677 139.17C254.415 142.603 254.088 143.332 254.088 143.332C254.088 143.332 270.886 142.957 274.847 142.597C278.808 142.236 281.032 139.878 280.858 136.625C280.685 133.373 278.701 130.113 278.701 130.113"
          fill="white"
        />
        <path
          d="M274.219 127.582L258.189 125.424C258.189 125.424 256.94 135.737 255.677 139.17C254.415 142.603 254.088 143.332 254.088 143.332C254.088 143.332 270.886 142.957 274.847 142.597C278.808 142.236 281.032 139.878 280.858 136.625C280.685 133.373 278.701 130.113 278.701 130.113"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M207.372 138.843C207.372 138.843 211.113 141.187 212.048 141.915C212.983 142.644 213.063 147.339 213.658 148.241C214.252 149.142 213.551 149.69 212.469 149.69C211.387 149.69 209.75 146.257 209.75 146.257L209.683 144.988L202.042 147.526L200.039 144.093C200.039 144.093 202.31 138.843 207.372 138.843Z"
          fill="white"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M151.707 280.752C171.011 280.752 186.66 277.223 186.66 272.87C186.66 268.517 171.011 264.988 151.707 264.988C132.403 264.988 116.754 268.517 116.754 272.87C116.754 277.223 132.403 280.752 151.707 280.752Z"
          fill="#263238"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M179.005 85.5755C179.005 85.5755 183.587 108.572 184.402 112.827C185.217 117.082 186.713 127.194 188.41 131.629C190.106 136.064 189.552 141.742 188.703 143.338C187.853 144.725 186.876 146.031 185.785 147.239L180.094 139.818C180.094 139.818 176.707 103.456 176.581 98.3063C176.454 93.1565 179.005 85.5755 179.005 85.5755Z"
          fill="white"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M182.024 138.91C182.024 138.91 185.631 141.161 186.266 142.116C186.9 143.071 187.355 144.287 186.86 145.322C186.366 146.357 183.674 149.483 182.993 150.091C182.312 150.699 181.737 148.274 181.657 146.19C181.577 144.106 182.024 138.91 182.024 138.91Z"
          fill="white"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M162.628 265.015C162.628 265.015 173.936 270.512 174.651 271.581C175.366 272.65 172.787 274.072 166.048 274.072C159.309 274.072 159.108 273.184 156.43 272.476C153.751 271.768 148.415 272.296 147.693 270.699C146.972 269.103 148.228 263.606 148.228 263.606L162.628 265.015Z"
          fill="white"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M147.613 267.854C147.613 267.854 156.964 273.531 156.142 275.482C155.321 277.432 148.561 277.432 145.001 276.898C141.441 276.363 133.873 271.554 133.473 269.804C133.072 268.054 134.074 265.369 139.557 264.841C145.041 264.314 147.613 267.854 147.613 267.854Z"
          fill="white"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M176 175.085C176 175.085 170.656 216.236 169.728 222.268C168.8 228.299 165.854 237.877 164.264 245.151C163.363 249.429 162.695 253.752 162.261 258.102C162.098 259.339 162.127 260.594 162.347 261.822C162.574 263.599 162.608 265.015 162.608 265.015C160.229 265.612 157.809 266.027 155.368 266.257C152.355 266.438 148.274 266.438 148.274 266.438L146.938 254.909C146.938 254.909 147.933 237.877 148.521 232.914C149.109 227.952 149.737 188.924 149.71 187.856C149.683 186.787 149.45 177.389 149.45 177.389L167.651 175.439C167.651 175.439 170.135 168.345 173.869 168.699C177.603 169.053 176 175.085 176 175.085Z"
          fill="white"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M173.896 168.699C170.162 168.345 167.678 175.439 167.678 175.439L149.457 177.389C149.457 177.389 149.517 179.887 149.577 182.452C151.52 182.626 153.317 182.726 154.853 182.726C160.691 182.717 166.524 182.389 172.327 181.744L173.108 173.569L174.33 181.898L175.172 181.624C175.693 177.656 176.027 175.072 176.027 175.072C176.027 175.072 177.63 169.053 173.896 168.699Z"
          fill="#263238"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M130.447 176.327C130.447 176.327 130.447 176.581 130.487 177.042C130.734 181.891 132.204 209.991 132.651 215.174C133.152 220.852 130.647 227.057 130.774 232.2C130.901 237.343 132.11 265.196 132.11 265.196L133.446 269.804C133.446 269.804 136.585 267.68 139.598 267.68C142.61 267.68 147.613 267.854 147.613 267.854C147.613 267.854 147.873 264.127 147.847 262.891C147.82 261.656 147.706 257.214 147.706 257.214C147.706 257.214 152.803 233.976 153.11 232.026C153.417 230.076 157.371 196.372 157.345 195.29C157.318 194.208 158.18 179.146 158.18 179.146L130.447 176.327Z"
          fill="white"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M157.993 182.693C158.106 180.635 158.186 179.166 158.186 179.166L130.447 176.327C130.447 176.327 130.447 176.581 130.487 177.042C130.487 177.63 130.561 178.558 130.627 179.714C137.113 180.876 148.368 182.706 154.853 182.706C155.922 182.726 156.964 182.713 157.993 182.693Z"
          fill="#263238"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M150.885 67.4145C150.885 67.4145 147.953 67.9087 146.838 69.2179C145.72 70.4913 144.714 71.8589 143.832 73.3056C143.678 73.7999 126.432 84.2664 126.432 84.2664L127.675 134.174C127.675 134.174 128.189 141.862 127.274 144.48C125.728 149.628 124.978 154.982 125.05 160.357C125.21 166.736 125.624 177.055 125.624 177.055C125.624 177.055 145.362 180.983 154.853 180.983C160.691 180.969 166.524 180.637 172.326 179.988L173.107 171.805L174.296 180.155L178.351 178.819L181.69 154.105C181.69 154.105 184.015 148.869 183.988 147.72C183.961 146.571 184.576 145.102 183.901 144.12C183.227 143.138 182.024 141.174 182.024 141.174C182.024 141.174 179.005 87.0516 179.005 85.5755C179.005 84.0994 170.075 75.4363 169.414 75.109C168.753 74.7817 164.678 68.7236 159.395 67.0938C154.112 65.4641 150.885 67.4145 150.885 67.4145Z"
          fill="white"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M145.509 74.942L143.532 92.9161L151.654 96.6899L149.623 102.788L169.935 138.482"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M168.198 76.9592L174.29 91.1794L171.678 94.6594L176.033 100.751L174.29 139.932"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M150.13 69.8724C150.278 73.379 150.915 76.8477 152.021 80.1786C153.777 85.0879 165.419 118.959 165.419 118.959L172.947 145.462C172.947 145.462 171.952 118.464 171.925 117.322C171.898 116.18 166.221 78.7025 166.221 78.7025C166.221 78.7025 165.252 72.651 163.723 70.36C162.193 68.069 151.94 63.8143 150.13 69.8724Z"
          fill="#92E3A9"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M149.41 67.2542C149.41 67.2542 144.674 67.7417 145.509 74.942C146.344 82.1423 147.446 87.0516 147.446 87.0516"
          stroke="#263238"
          strokeWidth="1.33586"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M146.865 96.5429C146.865 96.5429 148.869 104.885 150.238 107.176C151.607 109.467 155.748 111.758 158.694 111.758"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M153.197 94.5792C153.197 94.5792 155.401 104.231 155.768 106.034C156.135 107.838 154.92 111.264 152.876 114.717"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M152.288 115.86C152.078 115.744 151.922 115.55 151.854 115.319C151.787 115.089 151.813 114.841 151.927 114.631L152.161 114.203C152.219 114.101 152.296 114.011 152.388 113.939C152.481 113.867 152.587 113.815 152.7 113.784C152.813 113.754 152.931 113.746 153.048 113.762C153.164 113.778 153.276 113.816 153.377 113.876C153.587 113.992 153.743 114.185 153.812 114.415C153.88 114.645 153.856 114.893 153.744 115.105L153.51 115.526C153.453 115.628 153.375 115.719 153.282 115.792C153.189 115.864 153.083 115.918 152.969 115.949C152.855 115.98 152.737 115.988 152.62 115.973C152.503 115.957 152.39 115.919 152.288 115.86Z"
          fill="#263238"
        />
        <path
          d="M159.789 111.939C159.789 112.057 159.765 112.173 159.719 112.281C159.673 112.39 159.606 112.488 159.521 112.57C159.436 112.651 159.336 112.715 159.226 112.757C159.116 112.799 158.998 112.818 158.881 112.814H158.393C158.153 112.808 157.924 112.709 157.757 112.536C157.589 112.364 157.496 112.132 157.498 111.892C157.497 111.775 157.52 111.658 157.565 111.55C157.611 111.441 157.677 111.343 157.762 111.262C157.846 111.18 157.946 111.116 158.056 111.074C158.165 111.032 158.282 111.012 158.4 111.017H158.887C159.128 111.024 159.356 111.124 159.524 111.296C159.693 111.468 159.787 111.698 159.789 111.939Z"
          fill="#263238"
        />
        <path
          d="M146.878 97.1975L145.756 90.084C145.63 89.3506 145.789 88.597 146.2 87.9766C146.611 87.3561 147.242 86.9155 147.966 86.7444C148.747 86.5669 149.565 86.6988 150.25 87.1123C150.936 87.5259 151.434 88.1888 151.64 88.9619L153.557 96.042"
          stroke="#263238"
          strokeWidth="1.33586"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M173.28 102.005C174.888 101.844 176.041 100.211 175.855 98.3572C175.669 96.5036 174.216 95.1315 172.608 95.2926C171 95.4536 169.848 97.0868 170.033 98.9404C170.219 100.794 171.673 102.166 173.28 102.005Z"
          fill="white"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M165.066 70.2531C165.066 70.2531 171.498 79.3036 173.548 97.7519"
          stroke="#263238"
          strokeWidth="1.33586"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M173.719 99.8877C174.597 99.8061 175.232 98.9092 175.136 97.8844C175.041 96.8596 174.252 96.095 173.374 96.1766C172.497 96.2582 171.862 97.1551 171.957 98.1799C172.053 99.2047 172.841 99.9693 173.719 99.8877Z"
          fill="white"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M183.12 140.319C182.726 141.542 182.402 142.786 182.152 144.046C182.152 144.581 182.025 146.177 180.776 145.816C179.527 145.456 179.901 139.077 180.061 138.369C180.221 137.661 183.261 139.952 183.261 139.952"
          fill="white"
        />
        <path
          d="M183.12 140.319C182.726 141.542 182.402 142.786 182.152 144.046C182.152 144.581 182.025 146.177 180.776 145.816C179.527 145.456 179.901 139.077 180.061 138.369C180.221 137.661 183.261 139.952 183.261 139.952"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M142.576 134.668C143.687 135.28 144.929 135.617 146.196 135.65C148.16 135.65 151.747 134.982 153.063 135.323C154.379 135.664 157.738 139.898 157.738 139.898C157.738 139.898 158.74 140.88 158.266 141.374C157.792 141.869 156.823 142.71 156.155 142.189C155.487 141.668 152.148 138.916 152.148 138.916L143.725 142.356L140.265 140.553L142.576 134.668Z"
          fill="white"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M129.679 83.1242C129.679 83.1242 124.128 83.7921 123.187 85.2482C122.245 86.7043 107.036 120.101 107.069 121.577C107.103 123.053 137.48 146.958 137.48 146.958L144.039 134.475L127.628 119.259L134.748 102.735"
          fill="white"
        />
        <path
          d="M129.679 83.1242C129.679 83.1242 124.128 83.7921 123.187 85.2482C122.245 86.7043 107.036 120.101 107.069 121.577C107.103 123.053 137.48 146.958 137.48 146.958L144.039 134.475L127.628 119.259L134.748 102.735"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M139.918 152.99L155.962 153.317L155.882 143.338"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M143.745 142.356L139.504 143.939"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M148.581 60.2142C148.581 60.2142 150.405 74.4545 151.607 76.5785C152.809 78.7025 161.359 87.2119 161.359 87.2119C161.359 87.2119 164.03 77.4 164.331 75.109C164.632 72.818 164.865 63.654 164.865 63.654L148.548 59.072L148.581 60.2142Z"
          fill="white"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M148.581 60.2142C148.581 60.2142 148.795 61.884 149.122 64.1616C150.401 65.3525 151.862 66.3312 153.45 67.0605C155.554 67.9889 161.165 68.5099 164.712 68.0957C164.805 65.6979 164.865 63.654 164.865 63.654L148.548 59.072L148.581 60.2142Z"
          fill="#263238"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M143.852 47.784C143.852 47.784 141.668 45.493 140.586 48.1046C139.504 50.7162 141.922 54.6503 143.211 54.8173C143.976 54.917 144.753 54.8602 145.495 54.6503C145.495 54.6503 146.41 58.4174 147.252 59.5596C148.908 61.654 151.031 63.3327 153.45 64.4622C156.429 65.7981 166.402 66.2723 167.664 64.4622C168.926 62.6521 168.853 53.0072 168.833 52.1923C168.813 51.3774 168.673 39.7154 168.673 39.7154C168.673 39.7154 169.568 35.6677 168.352 32.8824C167.136 30.0972 154.933 26.2031 151.867 27.8128C148.802 29.4225 145.268 32.2279 145.028 35.828C144.787 39.4281 144.934 45.3193 144.934 45.3193L143.852 47.784Z"
          fill="white"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M161.886 42.7077C161.886 42.7077 163.924 52.0587 163.456 53.1808C162.989 54.303 161.526 54.323 159.696 53.3411C157.866 52.3593 158.821 51.0501 158.821 51.0501"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M154.793 53.5081C154.793 53.5081 155.674 55.9594 157.311 56.1264C158.947 56.2934 163.055 56.614 163.055 56.614"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M148.948 43.035L149.209 46.9624C149.209 46.9624 150.705 47.784 154.138 47.784C157.571 47.784 158.7 46.9624 158.7 46.9624L158.607 43.035C158.607 43.035 153.624 40.0894 148.948 43.035Z"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M161.9 43.035L163.142 47.1294C163.142 47.1294 163.65 47.7973 167.089 47.7973C170.529 47.7973 171.658 46.9758 171.658 46.9758L171.558 43.0483C171.558 43.0483 166.568 40.0894 161.9 43.035Z"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M158.627 43.035H161.9"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M148.969 43.035L142.516 46.8021"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M150.559 41.0713C150.559 41.0713 152.309 39.1075 156.276 40.744"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M162.501 41.0713C163.09 40.5985 163.801 40.3019 164.551 40.2157C165.302 40.1294 166.061 40.257 166.742 40.5837"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M154.74 44.8384C154.74 45.5598 154.479 46.1742 154.119 46.1742C153.758 46.1742 153.451 45.5865 153.451 44.8384C153.451 44.0903 153.711 43.5025 154.079 43.5025C154.446 43.5025 154.726 44.1103 154.74 44.8384Z"
          fill="#263238"
        />
        <path
          d="M165.213 44.6714C165.213 45.3928 164.952 46.0073 164.592 46.0073C164.231 46.0073 163.924 45.4195 163.924 44.6714C163.924 43.9233 164.184 43.3355 164.545 43.3355C164.905 43.3355 165.193 43.95 165.213 44.6714Z"
          fill="#263238"
        />
        <path
          d="M154.004 29.5361C154.004 29.5361 155.581 33.016 153.624 34.2851C153.02 34.6345 152.334 34.8186 151.637 34.8186C150.939 34.8186 150.253 34.6345 149.65 34.2851C150.17 35.0038 150.46 35.8631 150.483 36.7501C150.506 37.6371 150.259 38.5102 149.776 39.2545C148.817 40.853 147.698 42.3501 146.437 43.7229C146.437 43.7229 146.791 47.951 146.056 48.4452C145.321 48.9395 143.318 48.1981 143.284 46.9557C143.251 45.7134 142.449 43.2287 142.416 41.9863C142.382 40.744 141.808 37.5112 141.808 37.5112C141.808 37.5112 136.785 35.2736 139.45 32.5418C142.115 29.8099 147.773 27.5724 147.773 27.5724C147.773 27.5724 147.9 22.603 151.339 21.1135C154.779 19.624 162.18 17.3864 162.24 19.8711C162.334 21.1499 162.104 22.4317 161.572 23.5982C161.572 23.5982 167.777 23.3511 172.105 27.6058C176.434 31.8605 176.287 35.808 174.049 35.808C171.811 35.808 171.083 36.3022 171.083 36.3022C171.083 36.3022 175.365 38.7869 173.161 40.0293C170.957 41.2716 168.686 39.7822 168.686 39.7822C168.686 39.7822 169.634 38.0455 166.842 35.7746C164.05 33.5036 154.004 29.5361 154.004 29.5361Z"
          fill="#263238"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M69.2512 280.752C88.5551 280.752 104.204 277.223 104.204 272.87C104.204 268.517 88.5551 264.988 69.2512 264.988C49.9472 264.988 34.2983 268.517 34.2983 272.87C34.2983 277.223 49.9472 280.752 69.2512 280.752Z"
          fill="#263238"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M92.2681 109.848V116.234L101.552 120.488C101.552 120.488 102.521 116.234 101.746 108.686L92.2681 109.848Z"
          fill="#999999"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M46.1874 263.078C44.0624 264.902 42.0546 266.859 40.176 268.936C37.5043 271.948 36.9833 274.947 39.468 276.383C41.9527 277.819 45.8534 277.445 50.1082 274.787C54.3629 272.129 54.1892 271.06 56.6739 270.178C59.1586 269.297 60.755 267.507 60.755 264.675C60.755 261.842 51.7045 261.482 49.7542 261.335C47.8038 261.188 46.1874 263.078 46.1874 263.078Z"
          fill="white"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M60.7354 264.675C60.7354 264.675 56.7278 266.451 54.7841 268.068C52.8404 269.684 48.9664 275.502 44.4379 275.502C42.1317 275.504 39.8638 274.913 37.8521 273.785C37.855 274.323 38.0054 274.849 38.2869 275.307C38.5683 275.766 38.9701 276.138 39.4484 276.383C41.9331 277.799 45.8338 277.445 50.0885 274.787C54.3433 272.129 54.1696 271.06 56.6543 270.178C59.139 269.297 60.7354 267.513 60.7354 264.675Z"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M91.1992 263.078C93.3242 264.902 95.332 266.859 97.2105 268.936C99.8823 271.948 100.403 274.947 97.9186 276.383C95.4339 277.819 91.5331 277.445 87.2784 274.787C83.0237 272.129 83.1974 271.06 80.7127 270.178C78.2279 269.297 76.6316 267.507 76.6316 264.675C76.6316 261.842 85.6821 261.482 87.6324 261.335C89.5828 261.188 91.1992 263.078 91.1992 263.078Z"
          fill="white"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M76.6516 264.675C76.6516 264.675 80.6592 266.451 82.6029 268.068C84.5466 269.684 88.4206 275.502 92.9491 275.502C95.2553 275.504 97.5232 274.913 99.5349 273.785C99.532 274.323 99.3816 274.849 99.1001 275.307C98.8186 275.766 98.4169 276.138 97.9386 276.383C95.4539 277.799 91.5532 277.445 87.2984 274.787C83.0437 272.129 83.2174 271.06 80.7327 270.178C78.248 269.297 76.6516 267.513 76.6516 264.675Z"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M41.0447 162.327C41.0447 162.327 42.1067 202.236 42.641 208.094C43.1754 213.952 44.7517 227.25 44.7517 227.985C44.7517 228.72 45.6334 258.142 45.6334 258.142L44.037 260.814L44.5714 264.541L51.6648 265.957C55.2115 266.665 59.8269 267.734 60.5349 267.026C61.2429 266.318 61.4233 264.895 61.7773 262.945C62.1313 260.994 62.8393 257.441 62.1313 254.609C61.4233 251.777 63.0196 221.613 63.2 220.905C63.3803 220.197 67.4614 191.062 67.4614 191.062C67.4614 191.062 70.1331 215.014 72.0701 222.281C74.0071 229.548 73.3124 240.375 74.0204 244.63C74.7285 248.885 76.1511 263.258 76.3315 264.321C76.5118 265.383 76.8592 266.992 76.8592 266.992C76.8592 266.992 85.7293 265.75 87.3256 265.035C88.9441 264.439 90.6053 263.966 92.295 263.619C92.295 263.619 92.8227 253.507 92.1147 249.426C91.5988 246.558 91.3018 243.655 91.2263 240.743C91.2263 239.5 92.1147 200.126 92.1147 200.126L91.2263 161.987C91.2263 161.987 67.281 164.472 58.0569 162.695C48.8328 160.918 41.225 159.856 41.225 159.856L41.0447 162.327Z"
          fill="#92E3A9"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M58.0702 162.681C48.8461 160.911 41.225 159.856 41.225 159.856L41.0447 162.334C41.0447 162.334 41.1048 164.625 41.205 168.179C45.433 169.361 54.0226 171.438 62.325 171.438C71.1016 171.438 85.0814 168.766 91.3733 167.491L91.2397 161.98C91.2397 161.98 67.2944 164.458 58.0702 162.681Z"
          fill="#263238"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M88.8816 252.558C88.9951 253.781 89.0686 254.482 89.0686 254.482"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M86.1631 171.692C86.1631 171.692 86.831 205.97 86.831 218.908C86.831 227.858 87.9131 241.444 88.581 248.965"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M50.2617 169.755L51.8781 206.945"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M51.5513 209.851V215.996"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M59.9601 68.6434C59.0868 68.6481 58.2272 68.8608 57.4524 69.2638C56.6777 69.6669 56.0101 70.2487 55.505 70.9612C54.4004 72.4187 53.1712 73.7775 51.8314 75.0222L35.968 83.5383L37.5176 144.093L38.0987 164.799C38.0987 164.799 50.2818 168.86 61.8904 168.86C73.4991 168.86 94.1982 164.184 94.1982 164.184C94.1982 164.184 92.4616 83.5116 93.0427 83.5116C93.6238 83.5116 81.6278 73.0919 76.2109 70.3533C70.7939 67.6148 59.9601 68.6434 59.9601 68.6434Z"
          fill="#92E3A9"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M55.4385 75.964C55.4385 75.964 61.5834 87.2854 64.175 92.4619C66.7666 97.6383 71.6024 105.086 71.6024 105.086L79.6911 83.4181"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M59.9806 147.773L59.6667 138.736L41.4121 137.674C41.4121 137.674 41.5925 150.979 41.9465 151.701"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M60.2006 154.172L60.1138 151.634"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M81.842 152.749L81.3076 137.854C81.3076 137.854 87.693 138.382 93.0164 134.835"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M54.4697 102.361C55.0308 104.131 56.7407 105.173 58.2836 104.678C59.8265 104.184 60.608 102.347 60.0403 100.577C59.4725 98.8072 57.7693 97.7652 56.2264 98.2595C54.6835 98.7538 53.902 100.591 54.4697 102.361Z"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M54.6503 71.3686C54.6503 71.3686 52.5997 79.2502 57.2619 100.644"
          stroke="#263238"
          strokeWidth="1.33586"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M58.6978 101.379C58.5603 101.656 58.3462 101.888 58.0809 102.047C57.8156 102.206 57.5103 102.286 57.2011 102.276C56.8919 102.267 56.5918 102.169 56.3366 101.994C56.0813 101.82 55.8816 101.575 55.7611 101.29C55.6405 101.005 55.6043 100.692 55.6566 100.387C55.7089 100.082 55.8476 99.7988 56.0563 99.5704C56.2649 99.342 56.5347 99.1783 56.8336 99.0986C57.1325 99.019 57.448 99.0268 57.7426 99.1212C58.1669 99.296 58.5052 99.6311 58.684 100.054C58.8628 100.476 58.8678 100.952 58.6978 101.379Z"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M92.2679 82.3761C92.2679 82.3761 95.1667 84.5068 95.3604 85.2816C95.5541 86.0564 105.419 109.654 105.419 109.654L91.1057 111.765L91.2994 97.832"
          fill="#92E3A9"
        />
        <path
          d="M92.2679 82.3761C92.2679 82.3761 95.1667 84.5068 95.3604 85.2816C95.5541 86.0564 105.419 109.654 105.419 109.654L91.1057 111.765L91.2994 97.832"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M37.9049 82.7635C37.9049 82.7635 34.612 83.1509 33.8372 85.6623C33.0624 88.1737 27.4585 113.715 27.4585 113.715L47.964 111.765L46.027 92.6088"
          fill="#92E3A9"
        />
        <path
          d="M37.9049 82.7635C37.9049 82.7635 34.612 83.1509 33.8372 85.6623C33.0624 88.1737 27.4585 113.715 27.4585 113.715L47.964 111.765L46.027 92.6088"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M77.5132 70.1663C77.5132 70.1663 82.5961 73.0651 83.0971 80.2788C83.598 87.4925 83.0971 89.3226 83.0971 89.3226"
          stroke="#263238"
          strokeWidth="1.33586"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M85.3954 98.5601C85.3954 98.5601 84.9412 107.09 84.0128 109.554C83.0843 112.019 79.4107 114.938 76.4985 115.405"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M78.8161 97.6383C78.8161 97.6383 78.4086 107.477 78.3686 109.307C78.3285 111.137 80.172 114.323 82.817 117.376"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M83.5758 118.447L83.6536 118.384C84.0175 118.089 84.0733 117.555 83.7782 117.191L83.4038 116.729C83.1087 116.365 82.5745 116.31 82.2106 116.605L82.1328 116.668C81.7689 116.963 81.7132 117.497 82.0082 117.861L82.3827 118.323C82.6778 118.687 83.2119 118.742 83.5758 118.447Z"
          fill="#263238"
        />
        <path
          d="M75.4526 115.717L75.4712 115.816C75.5584 116.276 76.0023 116.578 76.4626 116.491L77.0467 116.38C77.507 116.293 77.8094 115.849 77.7222 115.389L77.7035 115.291C77.6163 114.83 77.1724 114.528 76.7121 114.615L76.1281 114.726C75.6678 114.813 75.3653 115.257 75.4526 115.717Z"
          fill="#263238"
        />
        <path
          d="M85.4952 99.208L85.3015 92.0411C85.2834 91.2935 84.9873 90.5795 84.4711 90.0385C83.9548 89.4976 83.2554 89.1685 82.5095 89.1155C81.7173 89.0565 80.9339 89.3136 80.3306 89.8305C79.7273 90.3474 79.3532 91.0821 79.2901 91.8741L78.689 99.1412"
          stroke="#263238"
          strokeWidth="1.33586"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M102.133 115.652L97.518 114.684L81.2673 111.979L58.4775 107.637L53.0739 105.253C53.0739 105.253 47.9642 98.5734 47.5234 97.9055C47.0826 97.2376 41.305 99.5686 41.512 100.457C41.7191 101.345 42.4004 110.222 42.8479 110.783C43.2954 111.344 48.2848 111.338 48.2848 111.338C48.2848 111.338 72.0899 122.826 73.0784 123.247C86.8178 129.038 93.4437 128.984 95.7881 129.365C98.1325 129.746 99.6621 128.797 101.011 127.248C102.361 125.698 102.133 115.652 102.133 115.652Z"
          fill="#999999"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M46.8294 97.812C46.8294 97.812 44.0575 97.9255 39.9497 100.697C38.4202 101.733 37.6187 102.474 37.6187 102.922C37.7062 103.454 37.8564 103.974 38.0662 104.471C40.7294 103.189 43.5184 102.186 46.3886 101.479"
          fill="#999999"
        />
        <path
          d="M46.8294 97.812C46.8294 97.812 44.0575 97.9255 39.9497 100.697C38.4202 101.733 37.6187 102.474 37.6187 102.922C37.7062 103.454 37.8564 103.974 38.0662 104.471C40.7294 103.189 43.5184 102.186 46.3886 101.479"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M47.9374 104.919C47.9374 104.919 40.3965 106.802 39.1742 107.029C37.9519 107.257 37.7314 106.028 37.7314 105.587C37.7314 105.146 38.0654 104.471 39.3946 103.81C40.7238 103.149 47.4098 101.365 47.4098 101.365"
          fill="#999999"
        />
        <path
          d="M47.9374 104.919C47.9374 104.919 40.3965 106.802 39.1742 107.029C37.9519 107.257 37.7314 106.028 37.7314 105.587C37.7314 105.146 38.0654 104.471 39.3946 103.81C40.7238 103.149 47.4098 101.365 47.4098 101.365"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M48.7194 108.359C48.7194 108.359 39.9495 109.247 39.2815 108.693C39.0772 108.47 38.9221 108.207 38.8266 107.92C38.731 107.634 38.6972 107.33 38.7271 107.029L46.6087 105.36"
          fill="#999999"
        />
        <path
          d="M48.7194 108.359C48.7194 108.359 39.9495 109.247 39.2815 108.693C39.0772 108.47 38.9221 108.207 38.8266 107.92C38.731 107.634 38.6972 107.33 38.7271 107.029L46.6087 105.36"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M46.2742 108.799L39.5081 109.133C39.5081 109.133 39.8353 110.135 40.176 110.242C41.7789 110.638 43.4121 110.9 45.0586 111.024C46.1382 111.059 47.214 111.171 48.278 111.358"
          fill="#999999"
        />
        <path
          d="M46.2742 108.799L39.5081 109.133C39.5081 109.133 39.8353 110.135 40.176 110.242C41.7789 110.638 43.4121 110.9 45.0586 111.024C46.1382 111.059 47.214 111.171 48.278 111.358"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M31.7134 113.328C31.7134 113.328 34.8059 126.687 35.5807 127.648C36.3555 128.61 42.7409 127.648 49.1263 125.711C55.5118 123.774 82.5964 112.172 82.5964 112.172L86.4637 112.941C86.4637 112.941 84.9141 108.105 82.209 107.911C79.5038 107.717 47.9641 111.765 47.9641 111.765L31.7134 113.328Z"
          fill="#999999"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M58.7778 60.1273C58.7778 60.1273 58.7778 60.4145 58.7778 60.9021C58.8379 63.4469 59.0851 71.5289 59.733 73.4792C60.5078 75.797 70.9542 95.3406 71.7557 93.791C72.5572 92.2414 77.3664 71.1548 77.3664 71.1548V62.8391C77.3664 62.8391 74.8483 64.9631 70.2061 64.9631C65.564 64.9631 58.7778 60.1273 58.7778 60.1273Z"
          fill="#999999"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M74.2738 71.589C75.3571 70.9275 76.3927 70.191 77.373 69.3848V62.8391C77.373 62.8391 74.8549 64.9631 70.2128 64.9631C65.5707 64.9631 58.7778 60.1273 58.7778 60.1273C58.7778 60.1273 58.7778 60.4145 58.7778 60.9021C58.7778 61.7971 58.8446 63.3801 58.9248 65.1435C59.108 65.4371 59.3134 65.7162 59.5393 65.9784C60.8751 67.5213 67.2739 71.3953 68.8235 71.7827C70.3731 72.1701 72.5305 72.5508 74.2738 71.589Z"
          fill="#263238"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M82.0154 48.9061C82.0154 48.9061 85.1079 47.1628 85.1079 50.4557C85.1079 53.7486 82.2091 57.6159 82.2091 57.6159L82.0154 48.9061Z"
          fill="#999999"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M54.35 49.0998C54.35 49.0998 50.0886 47.9376 50.0886 51.8116C50.0886 55.6856 53.9626 58.1903 53.9626 58.1903H56.6343C56.6343 58.1903 58.1839 62.0643 59.5398 63.6072C60.8957 65.1502 67.2745 69.0242 68.8241 69.4116C70.3737 69.799 72.4977 70.1864 74.241 69.2179C76.4312 67.8525 78.4495 66.2289 80.2524 64.382C81.0272 63.4202 82.3831 56.8411 82.3831 56.8411C82.3831 56.8411 82.7705 45.0388 81.9957 40.9777C81.2209 36.9167 76.1914 30.5313 69.2248 29.7565C62.2583 28.9817 55.6792 34.5923 54.7174 39.0407C53.7556 43.4892 54.35 49.0998 54.35 49.0998Z"
          fill="#999999"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M66.9203 52.199C66.9203 52.199 65.5844 54.9041 67.8888 55.6789C70.1931 56.4537 73.112 56.26 73.6931 55.0978C74.2742 53.9356 73.3057 52.5864 73.3057 52.5864"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M64.6028 57.0348C64.6028 57.0348 69.4386 61.0958 76.0177 57.2285"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M67.3069 60.7084C67.3069 60.7084 71.3746 63.0328 74.0797 60.321"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M64.9902 49.8746C65.5251 49.8746 65.9587 49.1808 65.9587 48.325C65.9587 47.4692 65.5251 46.7754 64.9902 46.7754C64.4553 46.7754 64.0217 47.4692 64.0217 48.325C64.0217 49.1808 64.4553 49.8746 64.9902 49.8746Z"
          fill="#263238"
        />
        <path
          d="M75.4363 48.1313C75.4363 48.9862 75.0021 49.6809 74.4678 49.6809C73.9334 49.6809 73.4993 49.013 73.4993 48.1313C73.4993 47.2496 73.9334 46.5884 74.4678 46.5884C75.0021 46.5884 75.4363 47.2763 75.4363 48.1313Z"
          fill="#263238"
        />
        <path
          d="M62.2778 46.201C62.2778 46.201 63.2463 44.6514 66.9533 45.2325"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M72.9182 45.4262C72.9182 45.4262 74.6615 42.7144 77.3733 45.4262"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M82.0153 40.9644C81.764 39.8155 81.3287 38.7148 80.7262 37.7049C80.5008 38.7757 80.4222 39.8723 80.4924 40.9644C80.7729 42.3804 81.7681 44.3575 81.7681 45.346C81.7681 46.3345 81.6212 50.2954 82.3292 49.7343C82.3993 49.6602 82.4559 49.5742 82.4962 49.4805C82.5404 46.6339 82.3797 43.7879 82.0153 40.9644Z"
          fill="#263238"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M72.29 32.9492C73.5321 32.8834 74.7684 32.7339 75.9904 32.5017C74.0169 31.0244 71.6911 30.0894 69.2442 29.7899C62.2777 29.0151 55.6986 34.6257 54.7368 39.0741C53.775 43.5226 54.3494 49.1332 54.3494 49.1332C54.3494 49.1332 55.8856 50.2019 56.4534 49.0731C57.0211 47.9443 56.4534 46.1008 57.0211 44.1237C57.5888 42.1466 58.4304 41.1514 58.5707 39.5951C58.711 38.0388 57.7224 35.7812 58.2902 34.5055C58.8579 33.2297 62.3913 30.8319 64.6556 31.6801C67.0917 32.6028 69.6864 33.0341 72.29 32.9492Z"
          fill="#263238"
          stroke="#263238"
          strokeWidth="0.667931"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1168_17618">
          <rect width="298" height="298" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
