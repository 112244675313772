import * as Dialog from "@radix-ui/react-dialog";
import React from "react";
import { IoClose } from "react-icons/io5";
import PropTypes from "prop-types";

import {
  DialogContent,
  DialogOverlay,
  ModalHeader,
  ModalText,
  ModalTitle,
} from "./styles";

/**
 *
 * @param {{
 *   onClose: () => void,
 *   isModalOpened: boolean,
 *   children: React.ReactNode,
 *   title: string,
 *   text: string,
 * }} props
 */
export function SecurityModal({
  onClose,
  isModalOpened,
  children,
  title,
  text,
}) {
  function handleClose() {
    onClose();
  }

  return (
    <Dialog.Root open={isModalOpened} onOpenChange={handleClose}>
      <Dialog.Portal>
        <DialogOverlay>
          <DialogContent>
            <ModalHeader>
              <ModalTitle>{title}</ModalTitle>
              <IoClose size={30} onClick={handleClose} />
            </ModalHeader>
            <ModalText>{text}</ModalText>
            {children}
          </DialogContent>
        </DialogOverlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

SecurityModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isModalOpened: PropTypes.bool.isRequired,
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};
