import styled from 'styled-components'
import { colors } from '../../components/library/theme'

export const InputWrapper = styled.div`
  border: 1px solid;
  border-color: ${colors.brand.pure};
  border-radius: 4px;
  display: flex;
  height: 40px;
  overflow: hidden;
  position: relative;
  width: 100%;
  overflow: visible;

  @media (min-width: 1600px) {
    height: 48px;
  }

  input {
    border-radius: 4px;
    border: 0;
    height: 100%;
    padding: 8px 16px;
    width: 100%;
    outline: none;
    font-size: 14px;

    @media (max-width: 1600px) {
      font-size: 12px;
    }

    &:-webkit-autofill {
      -webkit-font-family: "Poppins";
      -webkit-box-shadow: 0 0 0 30px white inset;
    }

    &:not(:placeholder-shown) + label, 
    &:focus ~ label {
      top: -13px;
      transform: unset;
      height: 22px;
      color: ${(props) => (props.hasValidationError ? colors.status.red300 : colors.brand.pure)};
    }
  }
`
export const Error = styled.small`
  color: ${colors.status.red300};
  font-size: 14px;

  @media (max-width: 1600px) {
    font-size: 12px;
  }
`
export const Label = styled.label`
  background-color: #fff;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  transition: 150ms;
  padding: 0 4px;
  position: absolute;
  pointer-events: none;
  color: ${colors.interface.grey400};
`
export const Button = styled.button`
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  width: 40px;
`