import React from "react";
import { ParagraphVariants } from "./styles";

export function Paragraph({ children, ...props }) {
  return (
    <>
      <ParagraphVariants {...props}>{children}</ParagraphVariants>
    </>
  );
}
