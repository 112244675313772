import styled from "styled-components";

export const StyledQRCode = styled.img`
  width: 284px;
  height: 284px;
  margin: auto;
  pointer-events: none;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 16px;
  width: 580px;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    max-width: 580px;
  }
`;

export const LoadingContainer = styled.div`
  width: 284px;
  height: 284px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;

  svg {
    width: 92px;
    height: 92px;
  }
`;