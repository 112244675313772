import React from 'react';
import { IoRadioButtonOff, IoRadioButtonOn } from 'react-icons/io5';
import { Radio } from './styles';
import { colors } from '../../../../components/library/theme';

export function RadioButton({ onClick, checked, label }) {
  return (
    <Radio onClick={onClick}>
      {checked ? (
        <IoRadioButtonOn size={24} color={colors.brand.pure} />
      ) : (
        <IoRadioButtonOff size={24} color="#606A71" />
      )}

      <span>{label}</span>
    </Radio>
  );
}
