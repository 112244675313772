import React from "react";
import { FiltersContainer, Filter } from "./styles";
import { filterCategories } from "./filtersOptions";
import { useMedTrack } from "../../hooks/useMedTrack";

export function TodoItemsCategoryFilters({ isRevision }) {
  const { setCategoryFilter, activeFilters } = useMedTrack();

  function handleCategoryFilter(filter) {
    const { todoItemCategory: selectedFilter } = filter;
    if (activeFilters.category === selectedFilter) return;

    setCategoryFilter(selectedFilter);
  }

  const filtersOptions = filterCategories(isRevision);

  return (
    <FiltersContainer>
      {filtersOptions.map((filter) => (
        <Filter
          key={filter.todoItemCategory}
          onClick={() => handleCategoryFilter(filter)}
          isFilterActive={activeFilters.category === filter.todoItemCategory}
          colors={filter.colors}
        >
          {filter.icon}
          {filter.label}
        </Filter>
      ))}
    </FiltersContainer>
  );
}
