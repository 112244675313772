import moment from "moment";
import React, { useEffect, useState } from "react";
import { CheckIcon } from "../../icons";
import { NotificationsIcon } from "../NotificationsIcon";
import {
  NotifierDropdownItemContainer,
  NotifierDropdownItemContent,
  NotifierDropdownItemDate,
  NotifierDropdownItemDescription,
  NotifierDropdownItemHeader,
  NotifierDropdownItemTitle,
} from "./styles";
import { DynamicLink } from "./DynamicLink";

/**
 * NotifierDropdownItem Component
 * @param {Object} props - The props of the component
 * @param {string} props.title
 * @param {string} props.description
 * @param {string} props.createdAt
 * @param {boolean} props.wasRead
 * @param {string} props.to
 * @param {string} props.category
 * @param {function} props.onClick
 *
 * @returns {JSX.Element} - The NotifierDropdownItem component
 */

export function NotifierDropdownItem({
  title = "",
  description = "",
  createdAt,
  wasRead,
  to,
  category,
  onClick,
}) {
  const [date, setDate] = useState();

  useEffect(() => {
    if (!date) {
      const newDate =
        window.innerWidth <= 992
          ? moment(createdAt).format("DD/MM/YY")
          : moment(createdAt).format("DD [de] MMMM [de] YYYY");

      setDate(newDate);
    }
  }, [date]);

  return (
    <DynamicLink to={to} onClick={onClick}>
      <NotifierDropdownItemContainer wasRead={wasRead}>
        <NotificationsIcon wasRead={wasRead} variant={category.toLowerCase()} />

        <NotifierDropdownItemContent>
          <NotifierDropdownItemHeader>
            <NotifierDropdownItemTitle>
              {title} {wasRead && <CheckIcon />}
            </NotifierDropdownItemTitle>
            <NotifierDropdownItemDate>{date}</NotifierDropdownItemDate>
          </NotifierDropdownItemHeader>

          <NotifierDropdownItemDescription>
            {description}
          </NotifierDropdownItemDescription>
        </NotifierDropdownItemContent>
      </NotifierDropdownItemContainer>
    </DynamicLink>
  );
}
