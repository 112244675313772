import React, { useCallback } from "react";
import CheckBoxFieldArrayItem from "./CheckBoxFieldArrayItem";

export default (props) => {
  const { fields } = props;
  const handleChange = useCallback(
    (e) => {
      const itemId = Number(e.target.name);
      const idx = (fields?.getAll() || []).indexOf(itemId);
      idx > -1 ? fields.remove(idx) : fields.push(itemId);
    },
    [fields]
  );

  return <CheckBoxFieldArrayItem onChange={handleChange} {...props} />;
};
