import React from "react";
import { Form, DropDownField } from "../library/forms";
import makeDropDownOptions from "../../utils/makeDropDownOptions";
import { css } from "glamor";
import { uiColors } from "../library/theme";

const searchWrapper = css({
  "> div > span > div": {
    "> div": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    ".ui.fluid.dropdown": {
      minWidth: "300px",
      maxWidth: "300px",
      whiteSpace: "nowrap",
    },

    ".ui.selection.dropdown:focus": { borderColor: uiColors.background },
    ".ui.selection.dropdown:hover": { borderColor: uiColors.background },
    ".ui.selection.dropdown>.search.icon": { top: "16px" },
    ".ui.dropdown:not(.button)>.default.text": {
      color: uiColors.backgroundDarkest,
    },
  },
  "@media(max-width: 768px)": {
    "> div > span > div": {
      ".ui.fluid.dropdown": {
        minWidth: "100%",
        maxWidth: "100%",
      },
    },
  },
});

export default function FilterForm(props) {
  return (
    <Form onSubmit={props.handleSubmit} error={props.error}>
      <div className={searchWrapper}>
        <DropDownField
          placeholder="Buscar aulas"
          name="selectedLevelId"
          options={makeDropDownOptions(props.levels)}
          clearable
          selection
          search={!props.dirty}
          fluid
          icon="search"
        />
      </div>
    </Form>
  );
}
