import React from "react";
import Page from "../library/Page";
import StatisticsPanelContainer from "./StatisticsPanelContainer";
import { colors } from "../library/theme";

export default function Statistics(props) {
  const { examTypeCategories } = props;

  const panels = examTypeCategories.map((examTypeCategory) => {
    return {
      menuItem: {
        key: examTypeCategory.id,
        content: examTypeCategory.name.toUpperCase(),
        color: colors.semantic,
      },
      render: () => (
        <StatisticsPanelContainer examTypeCategory={examTypeCategory} />
      ),
    };
  });

  return <Page title="Estatísticas" panels={panels}></Page>;
}
