import React from "react";
import {
  Difficulty,
  IconContainer,
  QuestionDifficultyLevelContainer,
} from "./styles";
import { ThermometerIcon } from "./assets/thermometerIcon";
/**
 *
 * @param {{ difficultyLevel: string }} props
 */
export function QuestionDifficultyLevel({ difficultyLevel }) {
  const dificultyTranslator = (difficultyLevel) => {
    switch (difficultyLevel) {
      case "easy":
        return "Fácil";
      case "medium":
        return "Média";
      case "hard":
        return "Difícil";
      default:
        return "Fácil";
    }
  };
  return (
    <QuestionDifficultyLevelContainer color={difficultyLevel}>
      <IconContainer>
        <ThermometerIcon />
      </IconContainer>
      <Difficulty>
        Nível de dificuldade - {dificultyTranslator(difficultyLevel)}
      </Difficulty>
    </QuestionDifficultyLevelContainer>
  );
}
