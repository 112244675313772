import styled from "styled-components";
import { colors } from "../../library/theme";

export const HighlighterWrapper = styled.div`
  position: relative;
`;

export const HighlighterTooltipContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  & > div {
    display: none;
    z-index: 20;
    top: 107px;
    left: -75px;
    min-width: 199px;
    height: 106px;
    line-height: 18px;

    &::after {
      bottom: 96%;
      left: 89px;
      transform: rotate(135deg);
    }
  }

  &[data-open="false"] {
    &:hover {
      & > div {
        display: initial;
      }
    }
  }

  @media (max-width: 768px) {
    & > div {
      display: none !important;
    }

    :hover {
      & > div {
        display: none;
      }
    }
  }
`;

export const HighlighterButton = styled.button`
  all: unset;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.interface.grey150};
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;

  &[data-active="true"] {
    background-color: ${colors.brand.pure};

    svg g path {
      fill: #fff;
    }
  }

  &[data-active="false"] {
    &:hover {
      background-color: ${colors.interface.grey200};
    }
  }
`;
