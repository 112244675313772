import React, { useContext } from "react";
import { context } from "../context";
import { Card } from "./card";
import { Container, SpinnerWrapper, Title, Wrapper } from "./styles";
import { Spinner } from "../../Spinner";

export function Step2() {
  const { suggestionsData, loading } = useContext(context);
  const { highlight_classroom, second_classroom, third_classroom } =
    suggestionsData;

  const plansCount = Object.keys(suggestionsData).reduce((acc, type) => {
    const isPresent = suggestionsData[type];
    if (isPresent) acc++;
    return acc;
  }, 0);

  if (loading) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  return (
    <>
      <Title>Selecione um curso para testar gratuitamente.</Title>
      <Container>
        <Wrapper length={plansCount}>
          {second_classroom && <Card {...second_classroom} />}
          {highlight_classroom && <Card highlight {...highlight_classroom} />}
          {third_classroom && <Card {...third_classroom} />}
        </Wrapper>
      </Container>
    </>
  );
}
