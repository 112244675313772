const getAlternatives = (action) => action.entities.alternatives;

export default function alternativesReducer(state = {}, action) {
  switch (action.type) {
    case "ALTERNATIVES_FETCHED":
      return { ...state, ...getAlternatives(action) };
    default:
      return state;
  }
}
