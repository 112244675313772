import React from 'react';
import { colors } from '../../../../components/library/theme';

export function RecalculateImg({ className }) {
  return (
    <svg
      className={className}
      viewBox="0 0 198 137"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M128.178 127.615C130.309 127.615 132.381 127.52 134.394 127.329C147.984 126.07 158.73 120.689 165.876 113.177C169.656 109.262 172.514 104.546 174.241 99.376C175.868 94.4813 176.342 89.2745 175.627 84.1648C170.689 48.9425 129.216 66.0704 146.602 44.6475C163.989 23.2246 129.953 -4.02226 116.892 2.42609C103.832 8.87443 118.235 22.6659 85.9609 16.146C53.6869 9.62604 60.3655 38.2728 60.3655 38.2728"
        stroke="#EDF0F2"
        strokeWidth="0.790508"
        strokeMiterlimit="10"
      />
      <path
        d="M41.2859 97.2166C41.2859 97.2166 26.5052 86.5728 20.7988 98.344C20.7988 98.344 26.8002 76.9847 47.2694 69.3453C47.2694 69.3453 53.1203 66.0764 53.3777 68.0509C53.6351 70.0254 55.332 71.0335 56.0745 71.0096C56.817 70.9858 63.0857 71.3795 63.3035 70.439C63.5213 69.4985 63.1055 66.6809 66.2141 66.661C69.3227 66.6411 86.1903 70.8028 91.0136 75.2012C91.0136 75.2012 82.6184 85.3797 87.107 96.6022L83.3252 95.6816C83.3252 95.6816 85.6577 115.963 82.4778 127.76H27.5526C27.5526 127.76 33.0431 128.454 38.6743 124.42L46.8517 113.881C46.8517 113.881 50.4374 108.425 57.7634 107.646C57.7634 107.646 61.9333 107.188 61.8937 105.198C61.8541 103.208 63.3965 106.473 65.4577 103.595C65.7092 103.595 70.7186 103.886 70.7186 103.886C70.7186 103.886 74.4806 104.24 75.1874 100.867C75.8943 97.495 78.5 73.8252 77.5634 72.4035C76.6269 70.9818 76.2685 70.9639 68.3644 71.0116C60.4602 71.0593 53.5144 69.4766 52.5085 73.4255C51.5027 77.3745 50.3404 92.3371 50.3404 92.3371C50.3404 92.3371 45.8854 93.5082 42.4917 98.354C42.5036 98.352 41.2859 100.714 41.2859 97.2166Z"
        fill="#1D2224"
      />
      <path
        d="M41.2866 90.0285V99.3461"
        stroke="#1D2224"
        strokeWidth="0.790508"
        strokeMiterlimit="10"
      />
      <path
        d="M83.0723 94.6873C91.7942 99.3401 115.899 108.847 116.754 72.2821C116.754 72.2821 115.717 62.6802 118.421 61.4275C121.126 60.1748 125.488 56.208 119.871 46.6041L123.536 42.9315C124.031 42.4197 124.303 41.7313 124.293 41.0179C124.283 40.3043 123.991 39.6241 123.481 39.1268C122.971 38.6295 122.286 38.3559 121.575 38.366C120.865 38.3763 120.188 38.6696 119.692 39.1814L112.408 46.9361C112.408 46.9361 111.353 44.9478 109.482 45.9837C107.61 47.0197 100.748 51.416 103.864 60.5964L95.7581 83.9798"
        stroke="#1D2224"
        strokeWidth="0.790508"
        strokeMiterlimit="10"
      />
      <path
        d="M112.398 46.9322C112.398 46.9322 114.34 49.9009 110.206 52.9351"
        stroke="#1D2224"
        strokeWidth="0.790508"
        strokeMiterlimit="10"
      />
      <path
        d="M121.066 48.9106C121.066 48.9106 122.276 52.7264 117.395 55.188C115.669 56.0589 113.774 54.2256 114.59 52.4659C114.603 52.44 114.615 52.4122 114.629 52.3844"
        stroke="#1D2224"
        strokeWidth="0.790508"
        strokeMiterlimit="10"
      />
      <path
        d="M122.543 53.6231C122.543 53.6231 121.476 57.3494 118.556 57.5283C115.972 57.6854 116.405 55.0388 116.405 55.0388"
        stroke="#1D2224"
        strokeWidth="0.790508"
        strokeMiterlimit="10"
      />
      <path
        d="M72.58 51.2887C72.382 51.3404 72.2572 51.1754 72.0731 51.2072C69.4258 50.722 67.4577 48.6223 67.236 47.6122C67.3112 48.163 68.317 48.1073 68.1468 48.67C68.1151 48.7774 68.0517 48.7356 68.0002 48.839C67.9171 49.0379 67.9191 48.7655 67.8022 48.9444C67.1528 47.9801 66.7766 47.5009 66.6618 46.97C66.7398 47.5398 66.6938 48.1198 66.5271 48.67C66.4964 48.7776 66.456 48.8822 66.4063 48.9822C66.0103 49.9207 65.1847 50.7141 63.8046 50.9109C63.4585 50.9549 63.1092 50.9662 62.7612 50.9447C61.7712 50.8871 60.9158 50.8294 60.1872 50.7976C57.7518 50.6882 56.8786 50.8732 57.1518 52.4519C57.3023 53.1662 57.5158 53.8655 57.7894 54.5417C58.2725 55.8183 58.6091 58.0294 58.1141 60.4373V60.4512C57.5399 63.2549 55.7223 65.4222 51.8276 67.4583C51.1376 67.816 50.426 68.13 49.6971 68.3988C42.9394 70.9161 26.7747 77.2929 20.0744 100.676C19.3615 103.168 18.8289 105.435 18.4527 107.504L18.2685 107.397C13.6928 104.643 10.7089 98.4653 11.6157 93.1503C12.0672 90.5117 13.7779 88.6705 15.9717 87.2845C17.5122 86.3261 19.1595 85.5407 20.5397 84.3576C25.1135 80.4345 21.4564 74.3342 23.9888 69.6435C26.844 64.3664 31.3148 61.1134 37.1519 60.8449C38.621 60.9086 40.2743 61.0815 41.8781 61.004C44.2066 61.0875 46.5866 60.8966 48.2913 59.3636C49.6338 58.1705 49.913 56.2259 50.4218 54.6034C50.9948 52.7737 51.4726 50.9153 51.8534 49.0359C52.2016 47.2539 52.8513 45.5451 53.774 43.9834C54.7701 42.2911 56.1749 40.8781 57.8587 39.8754C59.3275 39.0428 60.9649 38.5555 62.6483 38.4497C64.1812 38.5404 65.6722 38.987 67.0043 39.7541C67.4591 40.0241 67.8892 40.3341 68.2893 40.6807C71.2375 43.2059 72.6493 47.481 72.58 51.2887Z"
        fill={colors.brand.pure}
      />
      <path
        d="M117.312 57.3056C117.312 57.3056 116.368 58.4827 116.885 59.6002C117.401 60.7177 118.684 60.9782 119.932 60.489"
        stroke="#1D2224"
        strokeWidth="0.790508"
        strokeMiterlimit="10"
      />
      <path
        d="M119.866 46.6041L117.257 49.2228"
        stroke="#1D2224"
        strokeWidth="0.790508"
        strokeMiterlimit="10"
      />
      <path
        d="M50.3506 92.0805L52.4751 73.6879C52.5611 72.9436 52.9165 72.2572 53.4734 71.7589C54.0306 71.2607 54.7505 70.9855 55.4966 70.9857H75.0075C75.4344 70.9855 75.8565 71.0755 76.2466 71.2499C76.6365 71.4243 76.9855 71.6792 77.2709 71.9982C77.5564 72.3171 77.7716 72.6927 77.9029 73.1005C78.0342 73.5085 78.0785 73.9396 78.033 74.3659L75.1897 100.869C75.1091 101.618 74.7557 102.312 74.1971 102.815C73.6388 103.319 72.9147 103.597 72.1642 103.597H65.1828"
        stroke="#1D2224"
        strokeWidth="0.790508"
        strokeMiterlimit="10"
      />
      <path
        d="M73.4112 75.6703C73.8508 75.6703 74.2072 75.3126 74.2072 74.871C74.2072 74.4296 73.8508 74.0717 73.4112 74.0717C72.9716 74.0717 72.6152 74.4296 72.6152 74.871C72.6152 75.3126 72.9716 75.6703 73.4112 75.6703Z"
        fill="#1D2224"
      />
      <path
        d="M60.2724 96.3476C62.3316 96.8129 62.7435 96.8209 64.7413 97.1191C66.0006 97.308 68.8795 97.4651 68.931 95.5026C68.9785 93.7786 67.052 92.7665 65.6917 92.2158C64.1218 91.6161 62.479 91.2303 60.807 91.0685C60.118 90.9889 59.4258 90.9344 58.73 90.9054C53.9087 90.7066 48.3469 91.8777 45.0264 95.6656C37.3342 104.43 27.1055 110.066 27.1055 110.066"
        stroke="#1D2224"
        strokeWidth="0.790508"
        strokeMiterlimit="10"
      />
      <path
        d="M65.7862 97.2165C66.7762 97.638 68.4533 98.5368 67.7662 100.259C67.4534 101.054 66.8851 101.336 66.105 101.452C64.42 101.696 62.6123 101.336 60.957 100.988C60.3452 100.859 59.2601 100.623 58.6562 100.456"
        stroke="#1D2224"
        strokeWidth="0.790508"
        strokeMiterlimit="10"
      />
      <path
        d="M64.0266 101.492C64.0266 101.492 65.8264 101.47 65.3928 103.15C64.678 105.934 59.1241 104.673 56.5264 104.088"
        stroke="#1D2224"
        strokeWidth="0.790508"
        strokeMiterlimit="10"
      />
      <path
        d="M64.1377 66.8121C72.6002 67.184 85.496 69.6754 97.1898 79.8341"
        stroke="#1D2224"
        strokeWidth="0.790508"
        strokeMiterlimit="10"
      />
      <path
        d="M128.178 127.617L27.5525 127.768"
        stroke="#1D2224"
        strokeWidth="0.790508"
        strokeMiterlimit="10"
      />
      <path
        d="M83.0723 90.8855C83.0723 90.8855 85.0523 109.391 83.0723 127.617"
        stroke="#1D2224"
        strokeWidth="0.790508"
        strokeMiterlimit="10"
      />
      <path
        opacity="0.54"
        d="M170.454 82.4329L187.856 37.6524C188.072 36.7859 188.088 35.8816 187.903 35.0079C187.719 34.1342 187.339 33.3141 186.791 32.6101C186.244 31.9062 185.544 31.3365 184.745 30.9448C183.946 30.5529 183.069 30.3493 182.179 30.3491H113.047C111.495 30.3489 109.988 30.8734 108.769 31.8384C107.55 32.8031 106.69 34.1523 106.327 35.668L87.2718 80.4386C87.0648 81.3025 87.0559 82.2025 87.2452 83.0706C87.4347 83.9387 87.8178 84.7524 88.3655 85.4505C88.9132 86.1484 89.6115 86.7127 90.4077 87.1007C91.2038 87.4886 92.0774 87.6902 92.9623 87.6902H163.747C165.289 87.6902 166.786 87.173 168.001 86.2204C169.216 85.2678 170.08 83.9347 170.454 82.4329Z"
        fill="white"
      />
      <path
        d="M170.454 82.4329L187.856 37.6524C188.072 36.7859 188.088 35.8816 187.903 35.0079C187.719 34.1342 187.339 33.3141 186.791 32.6101C186.244 31.9062 185.544 31.3365 184.745 30.9448C183.946 30.5529 183.069 30.3493 182.179 30.3491H113.047C111.495 30.3489 109.988 30.8734 108.769 31.8384C107.55 32.8031 106.69 34.1523 106.327 35.668L87.2718 80.4386C87.0648 81.3025 87.0559 82.2025 87.2452 83.0706C87.4347 83.9387 87.8178 84.7524 88.3655 85.4505C88.9132 86.1484 89.6115 86.7127 90.4077 87.1007C91.2038 87.4886 92.0774 87.6902 92.9623 87.6902H163.747C165.289 87.6902 166.786 87.173 168.001 86.2204C169.216 85.2678 170.08 83.9347 170.454 82.4329Z"
        fill={colors.brand.pure}
      />
      <path
        d="M170.454 82.4329L187.856 37.6524C188.072 36.7859 188.088 35.8816 187.903 35.0079C187.719 34.1342 187.339 33.3141 186.791 32.6101C186.244 31.9062 185.544 31.3365 184.745 30.9448C183.946 30.5529 183.069 30.3493 182.179 30.3491H113.047C111.495 30.3489 109.988 30.8734 108.769 31.8384C107.55 32.8031 106.69 34.1523 106.327 35.668L87.2718 80.4386C87.0648 81.3025 87.0559 82.2025 87.2452 83.0706C87.4347 83.9387 87.8178 84.7524 88.3655 85.4505C88.9132 86.1484 89.6115 86.7127 90.4077 87.1007C91.2038 87.4886 92.0774 87.6902 92.9623 87.6902H163.747C165.289 87.6902 166.786 87.173 168.001 86.2204C169.216 85.2678 170.08 83.9347 170.454 82.4329Z"
        stroke="#1D2224"
        strokeWidth="0.790508"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M167.907 80.6195L183.963 39.3067C184.162 38.5076 184.177 37.6735 184.006 36.8678C183.836 36.0619 183.486 35.3057 182.981 34.6563C182.476 34.0071 181.831 33.4817 181.094 33.1201C180.357 32.7586 179.548 32.5705 178.728 32.5701H114.946C113.514 32.5697 112.124 33.0536 110.999 33.9438C109.874 34.8342 109.081 36.0788 108.747 37.4774L91.1662 78.7802C90.9756 79.5774 90.9672 80.4075 91.1423 81.2084C91.3171 82.0094 91.6705 82.7598 92.176 83.4036C92.6815 84.0477 93.3258 84.568 94.0604 84.9257C94.795 85.2834 95.6008 85.4692 96.4172 85.4692H161.722C163.143 85.4684 164.524 84.991 165.645 84.1123C166.765 83.2336 167.562 82.0044 167.907 80.6195Z"
        fill={colors.brand.pure}
      />
      <path
        d="M119.548 40.1657C120.394 38.8911 121.778 38.3244 122.639 38.8991C123.5 39.4737 123.512 40.975 122.667 42.2495C122.535 42.4454 122.387 42.6291 122.223 42.7983L128.49 56.0847C129.48 55.4146 130.589 55.2496 131.377 55.7765C131.886 56.1165 132.169 56.6952 132.258 57.3672L136.539 55.9157L140.283 54.6591C140.679 54.5219 141.097 54.3906 141.504 54.2614C141.658 53.7997 141.872 53.3604 142.14 52.955C143.328 51.1655 145.27 50.3701 146.478 51.1655C147.686 51.9608 147.702 54.0426 146.547 55.8223L153.675 65.6111C155.23 64.3306 157.061 63.9349 158.33 64.7819C160.057 65.9352 160.083 68.9456 158.388 71.5047C158.321 71.6061 158.243 71.6896 158.172 71.787L162.924 78.307C162.938 78.3268 162.956 78.3507 162.968 78.3706C163.088 78.5525 163.171 78.7565 163.213 78.9709C163.254 79.185 163.253 79.4055 163.21 79.6195C163.167 79.8336 163.082 80.0369 162.961 80.218C162.839 80.3991 162.683 80.5544 162.502 80.6751C162.321 80.7956 162.118 80.8793 161.905 80.9211C161.691 80.9629 161.472 80.9621 161.258 80.9187C161.045 80.8754 160.843 80.7903 160.662 80.6682C160.482 80.5463 160.328 80.3898 160.207 80.2079L155.988 73.8152C154.654 74.5927 153.236 74.7418 152.196 74.0458C150.545 72.9443 150.466 70.1526 151.937 67.683L145.104 57.3295C144.096 58.0174 142.983 58.1924 142.186 57.6615C141.74 57.3458 141.436 56.8664 141.34 56.3273C141.17 56.373 141.001 56.4228 140.831 56.4705L137.024 57.5144L132.151 58.8327C132.002 59.4193 131.755 59.9764 131.422 60.481C130.234 62.2706 128.294 63.066 127.084 62.2607C125.874 61.4554 125.856 59.3457 127.042 57.5541C127.219 57.2943 127.418 57.0501 127.636 56.8244L121.671 43.2775C120.952 43.7686 120.158 43.8959 119.59 43.5161C118.717 42.9435 118.703 41.4422 119.548 40.1657Z"
        fill="white"
      />
      <path
        d="M164.929 40.8995H174.174C174.597 40.8995 175.01 40.7756 175.364 40.5433C175.718 40.3109 175.997 39.98 176.167 39.5911C176.222 39.4718 176.251 39.3426 176.253 39.2113C176.25 38.9578 176.149 38.7154 175.97 38.5367C175.791 38.3579 175.549 38.2575 175.296 38.2569H165.462C165.931 39.0423 165.697 40.128 164.929 40.8995Z"
        fill="#EDF0F2"
      />
      <path
        d="M158.952 39.947C158.952 40.2 159.053 40.4421 159.231 40.6207C159.409 40.7993 159.651 40.8995 159.902 40.8995H164.93C165.698 40.128 165.931 39.0423 165.47 38.2569H161.031C160.608 38.2567 160.195 38.38 159.841 38.6114C159.487 38.8431 159.208 39.1731 159.037 39.5613C158.983 39.683 158.954 39.814 158.952 39.947Z"
        fill={colors.brand.pure}
      />
      <path
        d="M161.264 40.8995C161.29 40.9506 161.321 40.9991 161.355 41.0446C162.015 41.9493 163.444 42.0388 164.557 41.2196C164.689 41.1222 164.813 41.0152 164.929 40.8995C165.698 40.128 165.931 39.0423 165.47 38.2569C165.444 38.2066 165.413 38.1587 165.379 38.1137C164.725 37.2031 163.296 37.1195 162.183 37.9328C162.05 38.0318 161.923 38.1402 161.805 38.2569C161.043 39.0304 160.809 40.1101 161.264 40.8995Z"
        fill="white"
      />
      <path
        d="M156.627 45.7729C156.629 46.0262 156.731 46.2684 156.91 46.4467C157.089 46.6251 157.331 46.7253 157.583 46.7253H167.285C166.816 45.9299 167.05 44.8562 167.82 44.0847H158.712C158.289 44.0847 157.875 44.2086 157.521 44.4408C157.167 44.6733 156.888 45.0042 156.718 45.3931C156.663 45.5122 156.632 45.6414 156.627 45.7729Z"
        fill={colors.brand.pure}
      />
      <path
        d="M167.283 46.7253H171.837C172.26 46.7257 172.673 46.6028 173.027 46.3716C173.381 46.1403 173.66 45.8106 173.831 45.4229C173.886 45.3016 173.915 45.1702 173.916 45.0372C173.916 44.9119 173.892 44.788 173.845 44.6723C173.797 44.5566 173.727 44.4516 173.639 44.3631C173.551 44.2746 173.446 44.2044 173.331 44.1567C173.216 44.1088 173.092 44.0843 172.968 44.0847H167.818C167.046 44.8562 166.814 45.9359 167.283 46.7253Z"
        fill="#EDF0F2"
      />
      <path
        d="M167.283 46.7254C167.304 46.7775 167.332 46.8264 167.366 46.8705C168.022 47.7812 169.451 47.8647 170.562 47.0515C170.697 46.9533 170.823 46.8443 170.94 46.7254C171.703 45.9539 171.93 44.8742 171.481 44.0848C171.456 44.0337 171.426 43.9852 171.392 43.9397C170.73 43.0349 169.301 42.9455 168.19 43.7647C168.058 43.8611 167.933 43.9681 167.818 44.0848C167.046 44.8563 166.814 45.936 167.283 46.7254Z"
        fill="white"
      />
      <path
        d="M164.052 52.5117H169.859C170.282 52.5119 170.695 52.3886 171.049 52.1569C171.403 51.9255 171.682 51.5954 171.853 51.2073C171.908 51.088 171.937 50.9585 171.938 50.8275C171.937 50.5732 171.836 50.3298 171.657 50.1502C171.478 49.9707 171.235 49.8695 170.982 49.8691H164.592C165.048 50.6585 164.814 51.7382 164.052 52.5117Z"
        fill={colors.brand.pure}
      />
      <path
        d="M154.639 51.5632C154.64 51.8153 154.741 52.0569 154.919 52.2347C155.097 52.4126 155.338 52.5121 155.59 52.5117H164.052C164.814 51.7382 165.042 50.6585 164.593 49.8691H156.718C156.295 49.8691 155.881 49.993 155.527 50.2252C155.173 50.4576 154.894 50.7885 154.724 51.1774C154.67 51.2987 154.641 51.43 154.639 51.5632Z"
        fill="#EDF0F2"
      />
      <path
        d="M160.387 52.5115C160.413 52.5619 160.444 52.6098 160.478 52.6547C161.131 53.5654 162.561 53.6489 163.674 52.8357C163.807 52.7366 163.933 52.6283 164.052 52.5115C164.814 51.7381 165.042 50.6584 164.592 49.869C164.566 49.8179 164.536 49.7694 164.501 49.7238C163.842 48.8191 162.412 48.7416 161.3 49.5488C161.168 49.6463 161.044 49.7532 160.927 49.869C160.159 50.6405 159.918 51.7261 160.387 52.5115Z"
        fill="white"
      />
      <path
        d="M126.508 79.8022H132.315C132.738 79.8026 133.151 79.6793 133.505 79.4477C133.859 79.216 134.138 78.8862 134.309 78.4978C134.375 78.3527 134.404 78.193 134.392 78.0337C134.38 77.8745 134.328 77.721 134.241 77.5871C134.154 77.4535 134.035 77.3442 133.894 77.2694C133.754 77.1946 133.597 77.1568 133.438 77.1596H127.05C127.504 77.949 127.27 79.0287 126.508 79.8022Z"
        fill={colors.brand.pure}
      />
      <path
        d="M103.452 78.8538C103.453 78.9785 103.477 79.1021 103.525 79.2173C103.573 79.3326 103.643 79.4372 103.731 79.5253C103.819 79.6133 103.923 79.6831 104.038 79.7307C104.153 79.7782 104.276 79.8024 104.401 79.8022H126.507C127.27 79.0288 127.497 77.9491 127.048 77.1597H105.531C105.108 77.1597 104.694 77.2835 104.34 77.5158C103.986 77.7482 103.707 78.0791 103.537 78.468C103.483 78.5893 103.454 78.7206 103.452 78.8538Z"
        fill="#EDF0F2"
      />
      <path
        d="M122.842 79.8022C122.868 79.8525 122.899 79.9004 122.933 79.9454C123.587 80.858 125.016 80.9396 126.129 80.1263C126.263 80.0273 126.389 79.9189 126.507 79.8022C127.27 79.0287 127.497 77.949 127.048 77.1596C127.022 77.1085 126.991 77.06 126.957 77.0145C126.295 76.1098 124.866 76.0322 123.755 76.8395C123.623 76.9369 123.499 77.0439 123.383 77.1596C122.615 77.9311 122.381 79.0168 122.842 79.8022Z"
        fill="white"
      />
      <path
        d="M18.7832 108.443C18.0459 108.151 17.3328 107.8 16.6508 107.395C12.071 104.641 9.08718 98.4652 9.996 93.1482C10.4494 90.5096 12.1601 88.6704 14.3619 87.2825C15.9004 86.3241 17.5556 85.5407 18.9298 84.3576C23.5035 80.4345 19.8445 74.3321 22.3769 69.6435C25.4558 63.9567 30.4058 60.6222 36.9279 60.8389C40.0583 60.9423 44.1232 61.6641 46.6794 59.3635C48.0218 58.1705 48.303 56.2258 48.8119 54.6033C49.3855 52.7736 49.864 50.9152 50.2454 49.0358C50.5883 47.254 51.2338 45.545 52.1541 43.9833C53.15 42.2892 54.5548 40.8745 56.2388 39.8693C59.4068 38.0341 63.8242 37.8651 67.0081 39.752C67.4615 40.0227 67.8907 40.332 68.2911 40.6766C71.2373 43.2138 72.6471 47.4869 72.5798 51.2966"
        stroke="#1D2224"
        strokeWidth="0.790508"
        strokeMiterlimit="10"
      />
      <path
        d="M66.5965 46.634C66.7609 47.7554 67.44 48.7894 68.6459 49.0996C70.0081 49.4475 70.7308 50.7798 71.1149 52.1179C71.6608 54.0813 71.521 56.1729 70.7189 58.0453C69.9744 59.807 68.6478 61.8233 66.4045 62.1394C62.2999 62.7359 60.2566 60.9901 60.2566 60.9901"
        stroke="#1D2224"
        strokeWidth="0.790508"
        strokeMiterlimit="10"
      />
      <path
        d="M63.1775 52.8053C63.5668 52.8053 63.8824 52.4883 63.8824 52.0974C63.8824 51.7065 63.5668 51.3895 63.1775 51.3895C62.7881 51.3895 62.4727 51.7065 62.4727 52.0974C62.4727 52.4883 62.7881 52.8053 63.1775 52.8053Z"
        fill="#1D2224"
      />
      <path
        d="M69.3765 52.3361C69.7658 52.3361 70.0814 52.0193 70.0814 51.6282C70.0814 51.2373 69.7658 50.9203 69.3765 50.9203C68.9872 50.9203 68.6716 51.2373 68.6716 51.6282C68.6716 52.0193 68.9872 52.3361 69.3765 52.3361Z"
        fill="#1D2224"
      />
      <path
        d="M61.5418 104.878C62.5675 105.51 61.7398 106.552 60.9953 106.904C59.7163 107.501 58.063 107.606 56.669 107.773C46.0701 109.026 42.5318 123.636 35.879 126.352C29.2262 129.069 11.3725 131.053 20.0806 100.674C26.7849 77.2949 42.9496 70.9182 49.7074 68.4009C50.4348 68.1322 51.1448 67.8189 51.8339 67.4623C55.7285 65.4302 57.5521 63.2549 58.1243 60.4493C58.1243 60.4493 58.1243 60.4493 58.1243 60.4374C58.6193 58.0294 58.2867 55.8183 57.8016 54.5398C57.5234 53.8646 57.3072 53.165 57.1561 52.45C56.7958 50.4159 58.36 50.7022 62.7516 50.9448C64.7672 51.0641 65.9077 50.1494 66.3988 48.9842C66.4469 48.8832 66.4861 48.778 66.5156 48.6701C66.7221 48.0103 66.7468 47.3066 66.5869 46.634"
        stroke="#1D2224"
        strokeWidth="0.790508"
        strokeMiterlimit="10"
      />
      <path
        d="M66.2146 52.2684C66.2146 52.2684 66.9551 54.9866 66.8799 55.3882C66.8046 55.7899 65.8562 55.4797 65.8562 55.4797"
        stroke="#1D2224"
        strokeWidth="0.790508"
        strokeMiterlimit="10"
      />
      <path
        d="M65.7033 57.7888C64.2837 58.5563 62.6423 58.0732 62.0007 56.7946"
        stroke="#1D2224"
        strokeWidth="0.790508"
        strokeMiterlimit="10"
      />
      <path
        d="M55.1223 71.0096C54.2511 70.0969 53.4948 68.7548 52.9978 66.8121"
        stroke="#1D2224"
        strokeWidth="0.790508"
        strokeMiterlimit="10"
      />
      <path
        d="M63.177 70.9857L64.1373 66.8121L65.1808 62.3143"
        stroke="#1D2224"
        strokeWidth="0.790508"
        strokeMiterlimit="10"
      />
      <path
        d="M67.1025 87.6043C67.4716 85.7857 66.4731 84.0458 64.8722 83.7181C63.2714 83.3905 61.6745 84.5992 61.3055 86.4176C60.9364 88.2362 61.9349 89.976 63.5357 90.3035C65.1366 90.6312 66.7334 89.4226 67.1025 87.6043Z"
        fill="#EDF0F2"
      />
      <path
        d="M128.351 117.192L126.727 126.303C126.682 126.558 126.548 126.79 126.35 126.957C126.151 127.124 125.901 127.215 125.642 127.215H117.15C116.889 127.216 116.636 127.123 116.436 126.953C116.237 126.783 116.104 126.548 116.061 126.289L114.576 117.192H128.351Z"
        stroke="#EDF0F2"
        strokeWidth="0.790508"
        strokeMiterlimit="10"
      />
      <path
        d="M129.895 116.174C129.873 116.451 129.747 116.709 129.543 116.898C129.34 117.087 129.073 117.191 128.796 117.192H114.144C113.871 117.192 113.608 117.09 113.405 116.907C113.202 116.723 113.074 116.471 113.046 116.198C113.031 116.044 113.049 115.889 113.097 115.742C113.146 115.596 113.224 115.461 113.328 115.347C113.431 115.233 113.557 115.142 113.698 115.079C113.839 115.017 113.991 114.985 114.144 114.985H128.796C128.948 114.985 129.098 115.016 129.237 115.077C129.376 115.138 129.501 115.227 129.604 115.339C129.707 115.451 129.786 115.582 129.836 115.726C129.886 115.87 129.907 116.022 129.895 116.174Z"
        stroke="#EDF0F2"
        strokeWidth="0.790508"
        strokeMiterlimit="10"
      />
      <path
        d="M121.932 114.969C121.932 114.502 123.12 108.682 122.13 103.522C121.591 100.69 120.403 98.0596 118.011 96.618"
        stroke="#EDF0F2"
        strokeWidth="0.790508"
        strokeMiterlimit="10"
      />
      <path
        d="M122.134 103.53C122.134 103.53 122.405 99.6086 125.779 99.3382"
        stroke="#EDF0F2"
        strokeWidth="0.790508"
        strokeMiterlimit="10"
      />
      <path
        d="M122.496 109.513C123.456 108.837 124.612 108.501 125.783 108.558"
        stroke="#EDF0F2"
        strokeWidth="0.790508"
        strokeMiterlimit="10"
      />
      <path
        d="M122.496 106.727C122.496 106.727 120.045 103.812 116.128 106.119"
        stroke="#EDF0F2"
        strokeWidth="0.790508"
        strokeMiterlimit="10"
      />
      <path
        d="M120.767 105.554C120.767 105.554 115.247 100.491 114.166 107.343C114.166 107.343 115.72 108.359 118.015 107.343C120.31 106.327 120.767 105.554 120.767 105.554Z"
        stroke="#EDF0F2"
        strokeWidth="0.790508"
        strokeMiterlimit="10"
      />
      <path
        d="M123.347 100.531C123.347 100.531 123.149 98.2525 125.778 97.5069C128.408 96.7612 129.899 99.881 129.899 99.881C129.899 99.881 127.869 102.134 123.347 100.531Z"
        stroke="#EDF0F2"
        strokeWidth="0.790508"
        strokeMiterlimit="10"
      />
      <path
        d="M123.957 108.556C123.957 108.556 123.866 106.252 126.927 106.727C129.988 107.202 130.491 109.825 130.491 109.825C130.491 109.825 125.478 111.67 123.957 108.799"
        stroke="#EDF0F2"
        strokeWidth="0.790508"
        strokeMiterlimit="10"
      />
      <path
        d="M120.268 98.7575C120.268 98.9206 117.967 98.5587 117.821 98.507C116.706 98.173 115.914 97.3975 115.393 96.3715C115.233 96.0533 114.304 94.2698 114.568 93.9596C114.576 93.9596 120.044 92.1899 120.268 98.7575Z"
        stroke="#EDF0F2"
        strokeWidth="0.790508"
        strokeMiterlimit="10"
      />
      <path
        d="M174.245 99.376C172.518 104.547 169.66 109.264 165.879 113.179C158.73 120.692 147.984 126.072 134.397 127.331V99.376H174.245Z"
        stroke="#EDF0F2"
        strokeWidth="0.790508"
        strokeMiterlimit="10"
      />
      <path
        d="M165.879 113.179C158.73 120.691 147.984 126.072 134.397 127.331V113.179H165.879Z"
        stroke="#EDF0F2"
        strokeWidth="0.790508"
        strokeMiterlimit="10"
      />
      <path
        d="M161.743 106.553C161.743 105.438 160.843 104.534 159.733 104.534C158.622 104.534 157.722 105.438 157.722 106.553C157.722 107.668 158.622 108.572 159.733 108.572C160.843 108.572 161.743 107.668 161.743 106.553Z"
        stroke="#EDF0F2"
        strokeWidth="0.790508"
        strokeMiterlimit="10"
      />
    </svg>
  );
}
