import React, { useState } from "react";
import { HiChevronRight, HiChevronUp } from "react-icons/hi";
import { Divider } from "../Divider";
import { faqB2B, faqEMR, faqFrial } from "./questions";
import {
  Answer,
  CommonQuestionsCard,
  Container,
  ContentContainer,
  Header,
  Line,
  OctadeskHelpCard,
  Question,
  QuestionContainer,
} from "./styles";
import { connect } from "react-redux";

function getFAQByUserType({ isFrial, isB2B }) {
  if (isFrial) return faqFrial
  if (isB2B) return faqB2B
  return faqEMR
}

function HelpPage(props) {
  const { isFrialUser, isB2BUser } = props
  const [activeIndex, setActiveIndex] = useState(null);
  const faq = getFAQByUserType({ isFrial: isFrialUser, isB2B: isB2BUser })

  return (
    <Container>
      <Header>
        <h3>Central de ajuda</h3>
      </Header>
      <Divider />

      <ContentContainer>
        <CommonQuestionsCard>
          <h4>Perguntas frequentes</h4>

          {faq.map((question, index) => (
            <QuestionContainer key={index}>
              <Question
                onClick={() =>
                  activeIndex === index
                    ? setActiveIndex(null)
                    : setActiveIndex(index)
                }
              >
                {question.question}
                {activeIndex === index ? <HiChevronUp /> : <HiChevronRight />}
              </Question>
              {activeIndex === index && <Answer>{question.answer}</Answer>}
              <Line />
            </QuestionContainer>
          ))}
        </CommonQuestionsCard>
        <OctadeskHelpCard>
          <h4>Entre em contato conosco</h4>

          <iframe
            width="100%"
            height="900px"
            scrolling="no"
            src="https://o139685-13e.octadesk.com/custom-form/00000000-0000-0000-0000-000000000000?linkStyle=https%3A%2F%2Fhml.eumedicoresidente.com.br%2Foctadesk-formulario-contato.css"
          ></iframe>
        </OctadeskHelpCard>
      </ContentContainer>
    </Container>
  );
}

function mapStateToProps(state) {
  const isFrialUser = state?.isFrialUser;
  const isB2BUser = state.userType.isB2B;

  return { isFrialUser, isB2BUser };
}

export default connect(mapStateToProps)(HelpPage);