import styled from 'styled-components';
import { colors } from '../../../components/library/theme';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 460px;
`;

export const modeColors = {
  disabled: {
    background: colors.interface.grey100,
    border: 'none',
    fill: colors.interface.grey200,
  },
  active: { background: colors.brand.pure, border: 'none', fill: '#fff' },
  done: {
    background: colors.brand.light100,
    border: `solid 1px ${colors.brand.pure}`,
    fill: colors.brand.pure,
  },
};

export const StepContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    visibility: ${(props) => (props.isCurrentStep ? 'visible' : 'hidden')};
    font-size: 12px;
    color: #9ba5ab;
  }
`;

export const Circle = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${(props) => modeColors[props.mode].border};
  background-color: ${(props) => modeColors[props.mode].background};
  z-index: 1;
`;
export const Line = styled.div`
  height: 1px;
  position: absolute;
  top: 15px;
  left: 32px;
  width: 85%;
  background-color: ${colors.interface.grey200};
`;
export const Bar = styled.div`
  background-color: ${colors.brand.pure};
  width: ${(props) => (props.fullSize ? 100 : 0)}%;
  height: 1px;
`;
