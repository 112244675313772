import {
  AchievementsIcon,
  EventsIcon,
  MaterialsIcon,
  MedtrackIcon,
  NewsIcon,
  OffensiveIcon,
  SecurityIcon,
  SuggestionIcon,
  WarningsIcon,
} from '../../icons';

export const categories = {
  achievements: {
    icon: AchievementsIcon,
    title: 'Conquistas',
  },
  events: {
    icon: EventsIcon,
    title: 'Eventos',
  },
  materials: {
    icon: MaterialsIcon,
    title: 'Materiais',
  },

  roadmap: {
    icon: MedtrackIcon,
    title: 'Medtrack',
  },
  news: {
    icon: NewsIcon,
    title: 'Novidades',
  },
  offensive: {
    icon: OffensiveIcon,
    title: 'Ofensivas',
  },
  security: {
    icon: SecurityIcon,
    title: 'Segurança',
  },
  suggestion: {
    icon: SuggestionIcon,
    title: 'Sugestões',
  },
  warnings: {
    icon: WarningsIcon,
    title: 'Avisos',
  },
};
