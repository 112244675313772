import * as Dialog from "@radix-ui/react-dialog";
import { css } from "glamor";
import React, { useEffect, useState } from "react";
import { cleanText } from "../../components/AnnotationsBook/QuestionModal/getFormattedDescription";
import Spinner from "../../components/library/Spinner";
import { apiGet } from "../../processes/helpers/api";
import { notifyNotSubmitSucceeded, notifySubmitSucceeded } from "../../processes/notifierProcesses";
import { EMRButton } from "../Button";
import {
  ButtonContainer,
  DialogClose,
  DialogContent,
  DialogOverlay,
  DialogTitle,
  Header,
  ModalFooter,
  StyledTextarea,
  SubtitleContainer
} from "./styles";
import ReactHtmlParser from 'react-html-parser';

const styledSpinner = css({
  ".ui.loader:after": {
    borderColor: "rgba(0, 0, 0, 0.14) transparent transparent"
  }
});

export function AnnotationModal({
  dispatch,
  onSuccess,
  onError,
  mockId,
  questionId,
  question
}) {
  const [annotationContent, setAnnotationContent] = useState("");
  const [textareaCount, setTextareaCount] = useState(0);
  const [annotationCount, setAnnotationCount] = useState(1);
  const [isSavingAnnotation, setIsSavingAnnotation] = useState(false);
  const session = JSON.parse(localStorage.getItem("session"));
  const userId = session.currentUserId;

  function getQuestionTitle(questionTitle, questionCode) {
    const doc = document.createElement("div");
    doc.innerHTML = questionTitle;
    const notFormattedQuestionTitle = doc.children[0].innerHTML;
    const formattedQuestionTitle = notFormattedQuestionTitle.replace(/([()])/g, "");

    const quentionNumber = questionCode.split("Q");
    const number = quentionNumber[quentionNumber.length - 1].match(/\d+/)[0];
    return ReactHtmlParser(cleanText(formattedQuestionTitle + " " + "Q" + number));
  }


  function handleSaveAnnotation() {
    const dataValues = {
      annotation: annotationContent,
      question_id: questionId,
      user_id: userId,
      mock_id: mockId
    };

    setIsSavingAnnotation(true);

    try {
      fetch("/api/v1/notes", {
        method: "POST",
        body: JSON.stringify(dataValues),
        headers: {
          "Content-Type": "application/json"
        }
      }).then(() => {
        notifySubmitSucceeded(dispatch, 'Anotação criada com sucesso!'),
          window.scrollTo(0, 0);
        onSuccess();
        onclose();
        setIsSavingAnnotation(false);
      });
    } catch {
      notifyNotSubmitSucceeded(dispatch, "Anotação não pode ser salva");
      window.scrollTo(0, 0);
      onError();
      setIsSavingAnnotation(false);
    }
  }

  useEffect(() => {
    try {
      apiGet(
        `/api/v1/notes/positionNote?user_id=${userId}&&question_id=${questionId}`
      ).then((response) => {
        setAnnotationCount(response.body.notePosition);
      });
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <Dialog.Portal>
      <DialogOverlay>
        <DialogContent>
          <Header>
            <DialogTitle>
              <h2>Adicionar anotação ao caderno de erros</h2>
            </DialogTitle>
          </Header>

          <SubtitleContainer>
            <strong>Anotação {annotationCount} </strong>
            {getQuestionTitle(question.title, question.code)}
          </SubtitleContainer>

          <StyledTextarea
            name=""
            id=""
            placeholder="Escreva sua anotação"
            maxLength="600"
            onChange={(e) => {
              setAnnotationContent(e.target.value);
              setTextareaCount(e.target.value.length);
            }}
          />

          <ModalFooter>
            <span> {textareaCount}/600 caracteres</span>

            <ButtonContainer>
              <DialogClose asChild>
                <EMRButton
                  mode={"cancel"}
                  fontSize={"lg"}
                  disabled={isSavingAnnotation}
                >
                  Cancelar
                </EMRButton>
              </DialogClose>

              <EMRButton
                mode={"standard"}
                fontSize={"lg"}
                disabled={textareaCount <= 0 || isSavingAnnotation}
                onClick={() => handleSaveAnnotation()}
              >
                {isSavingAnnotation ? (
                  <Spinner inline size="small" className={styledSpinner} />
                ) : (
                  "Salvar anotação"
                )}
              </EMRButton>
            </ButtonContainer>
          </ModalFooter>
        </DialogContent>
      </DialogOverlay>
    </Dialog.Portal>
  );
}
