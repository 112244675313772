import styled from "styled-components";
import { IoIosArrowRoundDown } from "react-icons/io";
import { colors } from "../../components/library/theme";

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  padding: 48px 24px 82px 24px;

  @media (max-width: 768px) {
    padding: 24px;
  }
`;
export const Title = styled.h1`
  font-size: 32px;
  max-width: 404px;
  font-weight: 600;
  color: ${colors.interface.grey400};

  @media (max-width: 768px) {
    font-size: 24px;
  }
`;
export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  color: ${colors.interface.grey400};

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
export const Section = styled.section`
  max-width: 796px;
  width: 100%;
`;
export const Infos = styled.h2`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.interface.grey400};
`;
export const Iframe = styled.iframe`
  width: 100%;
  height: unset;
  aspect-ratio: 16/9;
  border: 0;
`;
export const Arrow = styled(IoIosArrowRoundDown)`
  position: absolute;
  display: block;
  left: 0;
  bottom: 0;
  min-width: 32px;
  min-height: 32px;

  @media (max-width: 768px) {
    position: unset;
    margin-top: 40px;
  }
`;
export const List = styled.ul`
  list-style: none;
  padding: 0;
  font-size: 16px;
  line-height: 24px;
`;
