import React, { useCallback, useState } from "react";
import { connect } from "react-redux";
import Notifications from "./NotificationsDropdown";
import {
  getDropdownNotifications,
  getUnreadNotificationsCount,
} from "../../selectors/notificationSelectors";
import { getAllClassrooms } from "../../selectors/classroomSelectors";
import { usePoolingNotifications } from "./usePoolingNotifications";

function NotificationsDropdownContainer(props) {
  const [openNotifications, setOpenNotifications] = useState(false);

  usePoolingNotifications();

  const toggleDropdown = useCallback(() => {
    setOpenNotifications(!openNotifications);
  }, [openNotifications]);

  return (
    <Notifications
      openNotifications={openNotifications}
      toggleDropdown={toggleDropdown}
      {...props}
    />
  );
}

function mapStateToProps(state) {
  const isFrialUser = state.isFrialUser
  return {
    unreadCount: getUnreadNotificationsCount(state),
    notifications: getDropdownNotifications(state),
    classrooms: getAllClassrooms(state),
    isFrialUser
  };
}

export default connect(mapStateToProps)(NotificationsDropdownContainer);
