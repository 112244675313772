function formSubmitSucceededNotification(id, formName, message) {
  const payload = {
    formName,
    message,
    id,
  };
  return { type: "FORM_SUBMIT_SUCCEEDED", payload };
}

function formSubmitExpiredNotification(id, formName) {
  const payload = {
    formName,
    id,
  };
  return { type: "FORM_SUBMIT_SUCCESS_EXPIRED", payload };
}

export function formSubmitFailedNotification(formName, message) {
  const payload = {
    formName,
    message,
    id: new Date().getTime(),
  };
  return { type: "FORM_SUBMIT_FAILED", payload };
}

export function formChangedNotification(formName) {
  const payload = {
    formName,
  };
  return { type: "FORM_CHANGED", payload };
}

export function notifySubmitSucceeded(dispatch, formName, message) {
  const messageId = new Date().getTime();
  dispatch(formSubmitSucceededNotification(messageId, formName, message));
  setTimeout(() => {
    dispatch(formSubmitExpiredNotification(messageId, formName));
  }, 3000);
}
