import styled from "styled-components";

export const TooltipDefaultContainer = styled.div`
  font-weight: 400;
  font-size: 12px;
  border-radius: 4px;
  border: solid 1px #c2ced6;
  position: ${props => props.isContentBlocked ? 'unset' : 'absolute'};
  padding: 8px;
  z-index: 1;
  width: ${props => props.isContentBlocked ? '100%' : 'fit-content'};
  max-width: ${props => props.isContentBlocked ? '269px' : 'unset'};
  height: fit-content;
  min-width: ${props => props.isContentBlocked ? 'unset' : '314px'};
  background-color: #edf0f2;
  text-align: left;
  line-height: 21px;
  right: unset;
  top: 0px;
  left: 32px;
  transform: translateY(-50%);

  @media (max-width: 991px) {
    display: ${props => props.isContentBlocked ? 'none' : 'initial'};
  }

  @media (max-width: 700px) {
    top: 30px;
    transform: translateX(-80%);
  }

  @media (max-width: 435px) {
    transform: translateX(-60%);
  }

  ::after {
    content: "";
    display: block;
    height: 10px;
    width: 10px;
    border: solid #c2ced6;
    background-color: #edf0f2;
    transform: ${props => props.isContentBlocked ? 'rotate(45deg) translateY(50%)' : 'rotate(45deg)'};
    border-top-left-radius: 2px;
    position: absolute;

    border-width: 0 0 1px 1px;
    top: unset;
    right: unset;
    bottom: ${props => props.isContentBlocked ? '50%' : '38%'};
    left: ${props => props.isContentBlocked ? '-2px' : '-5px'};


    @media (max-width: 700px) {
      bottom: 95%;
      left: 225px;
      border-width: 1px 0px 0px 1px;
    }

    @media (max-width: 435px) {
      left: 161px;
    }
  }
`;
