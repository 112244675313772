import * as Dialog from "@radix-ui/react-dialog";
import React, { useEffect, useState } from "react";
import { IoClose, IoReloadOutline } from "react-icons/io5";
import { medtrackApi } from "../../../../../services/medtrack";
import { colors } from "../../../../components/library/theme";
import { useMedTrack } from "../../hooks/useMedTrack";
import { requestRecalculateRoute } from "../../utils/requestRecalculateRoute";
import { Infobox } from "../Infobox";
import {
  ButtonContainer,
  CloseButton,
  DialogClose,
  DialogContent,
  DialogOverlay,
  Hr,
  StyledEMRButton,
  StyledSpinner,
  StyledWelcomeImage,
  TextWrapper,
} from "./styles";

export function LateStudiesModal({ onRecalculateRoute }) {
  const isRecalculatingRoute = false;
  const { selectedMedtrackType } = useMedTrack();
  const [isRecalculateModalAvailable, setIsRecalculateModalAvailable] =
    useState(false);

  async function handleModalOpenChange(e) {
    const medtrackType = selectedMedtrackType.type !== 'REVIEW' ? 'DEFAULT' : 'REVIEW';

    await medtrackApi.post("/user-activity/register", {
      action: `VIEW_RECALCULATE_ROUTE_MODAL_ON_${medtrackType}`,
    });

    setIsRecalculateModalAvailable(!isRecalculateModalAvailable);
  }

  async function handleClickRecalculateButton(e) {
    await requestRecalculateRoute(selectedMedtrackType.id);
    onRecalculateRoute(e);
  }

  async function checkIfCanShowRecalculateModal(selectedScheduleId) {
    const response = await medtrackApi.get(
      `/recalculate/is-modal-available?scheduleId=${selectedScheduleId}`
    );
    const { isModalAvailable } = response.body;

    setIsRecalculateModalAvailable(isModalAvailable);
  }

  useEffect(() => {
    if (selectedMedtrackType.id) {
      checkIfCanShowRecalculateModal(selectedMedtrackType.id);
    }
  }, [selectedMedtrackType]);

  return (
    <Dialog.Root
      open={isRecalculateModalAvailable}
      onOpenChange={handleModalOpenChange}
    >
      <Dialog.Portal>
        <DialogOverlay>
          <DialogContent>
            <Dialog.Close asChild>
              <CloseButton>
                <IoClose size={24} color={colors.interface.grey300} />
              </CloseButton>
            </Dialog.Close>

            <StyledWelcomeImage />

            <TextWrapper>
              <h2>Ops! Alguns estudos estão atrasados</h2>
              <p>
                A semana virou e notei que você não conseguiu realizar algumas
                tarefas. Mas não se preocupe, pois o Medtrack pode recalcular
                sua rota.
              </p>
            </TextWrapper>

            <Infobox variant={"lightGrey"}>
              Ao recalcular rota, nossa Inteligência Artificial irá repriorizar
              todo o seu cronograma a partir de agora, de acordo com suas metas
              e disponibilidade de tempo.
            </Infobox>

            <Hr />

            <ButtonContainer>
              <DialogClose asChild>
                <StyledEMRButton mode={"cancel"} fullSized>
                  Cancelar
                </StyledEMRButton>
              </DialogClose>

              <Dialog.Close asChild>
                <StyledEMRButton
                  mode={"standard"}
                  fullSized
                  onClick={handleClickRecalculateButton}
                >
                  <IoReloadOutline size={24} />
                  {isRecalculatingRoute ? (
                    <StyledSpinner inline size="small" />
                  ) : (
                    "Recalcular rota"
                  )}
                </StyledEMRButton>
              </Dialog.Close>
            </ButtonContainer>
          </DialogContent>
        </DialogOverlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
