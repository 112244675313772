import React from "react";
import { EMRButton } from "../../../Button";
import login from "./login.svg";
import {
  Container,
  ImageContainer,
  ModalContent,
  ModalText,
  TextContainer
} from "./styles";
function MaxSessionModal({ onClose }) {
  return (
    <Container>
      <ModalContent>
        <ImageContainer>
          <img src={login} />
        </ImageContainer>
        <TextContainer>
          <span>
            Você foi desconectado devido ao uso simultâneo em outro dispositivo.
          </span>
          <ModalText>
            A Eu Médico Residente reforça que{" "}
            <b>o compartilhamento do conteúdo dos seus cursos é CRIME</b>{" "}
            tipificado no Art. 184 do Código Penal, passível de detenção, de 3
            (três) meses a 1 (um) ano.
          </ModalText>
        </TextContainer>
        <EMRButton fullSized onClick={onClose} type="button">
          Entendi
        </EMRButton>
      </ModalContent>
    </Container>
  );
}

export default MaxSessionModal;
