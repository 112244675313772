import React from 'react';
import { StyledTable, TableWrapper } from './styles';

export function MocksTable(props) {
  return (
    <TableWrapper>
      <StyledTable variant={props.variant}>{props.children}</StyledTable>
    </TableWrapper>
  );
}
