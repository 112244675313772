import React from "react";

export default function MarkerIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_9737_232)">
        <path
          d="M19.1667 18.4029C19.1667 18.823 18.8229 19.1667 18.4028 19.1667C17.2264 19.1667 16.6535 18.3112 16.241 17.6773C15.859 17.1044 15.668 16.8753 15.3472 16.8753C14.9576 16.8753 14.6597 17.2114 14.1937 17.7537C13.659 18.3876 12.9944 19.1667 11.8792 19.1667C10.7639 19.1667 10.1069 18.38 9.58749 17.7384C9.14444 17.2037 8.85416 16.8753 8.47222 16.8753C8.1743 16.8753 7.99097 17.0662 7.58611 17.5703C7.03611 18.2425 6.27986 19.1667 4.65277 19.1667C2.54444 19.1667 0.833328 17.4558 0.833328 15.3477C0.833328 13.2395 2.54444 11.5286 4.65277 11.5286C5.07291 11.5286 5.41666 11.8723 5.41666 12.2924C5.41666 12.7125 5.07291 13.0562 4.65277 13.0562C3.39236 13.0562 2.36111 14.0874 2.36111 15.3477C2.36111 16.6079 3.39236 17.6391 4.65277 17.6391C5.55416 17.6391 5.92847 17.1808 6.40208 16.6003C6.86041 16.0427 7.42569 15.3477 8.47222 15.3477C9.59513 15.3477 10.2444 16.1344 10.7639 16.776C11.2069 17.3106 11.4972 17.6391 11.8792 17.6391C12.2611 17.6391 12.5667 17.303 13.0326 16.7607C13.5674 16.1267 14.2319 15.3477 15.3472 15.3477C16.4625 15.3477 17.0965 16.2031 17.509 16.8371C17.891 17.4099 18.0819 17.6391 18.4028 17.6391C18.8229 17.6391 19.1667 17.9828 19.1667 18.4029ZM7.70069 13.0562H8.41874C9.22847 13.0562 10.0076 12.7354 10.5806 12.1626L16.4778 6.26597L13.7354 3.52389L7.83819 9.4205C7.26527 9.99336 6.94444 10.7724 6.94444 11.5821V12.3001C6.94444 12.7202 7.28055 13.0562 7.70069 13.0562ZM18.6014 4.14258C18.9681 3.77595 19.1667 3.28711 19.1667 2.76772C19.1667 2.24833 18.9681 1.75949 18.6014 1.4005C17.8451 0.644331 16.6153 0.644331 15.859 1.4005L14.8201 2.43928L17.5625 5.18136L18.6014 4.14258Z"
          fill="#606A71"
        />
      </g>
      <defs>
        <clipPath id="clip0_9737_232">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
