import * as Dialog from "@radix-ui/react-dialog";
import React from "react";
import { IoClose } from "react-icons/io5";

import {
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogOverlay,
  DialogTitle,
  YoutubeVideo,
} from "./styles";

export function FrialModal() {
  const modalContent = {
    title: "Aprenda como usar a plataforma",
    subtitle:
      "Assista ao vídeo abaixo para entender o funcionamento da plataforma e qual a melhor maneira de utilizá-la.",
    videoUrl: process.env.TRIAL_YOUTUBE_URL,
  };

  return (
    <Dialog.Portal>
      <DialogOverlay>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{modalContent.title}</DialogTitle>
            <DialogClose asChild>
              <IoClose size={30} />
            </DialogClose>
          </DialogHeader>

          <DialogDescription>{modalContent.subtitle}</DialogDescription>

          <YoutubeVideo
            src={modalContent.videoUrl}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          />
        </DialogContent>
      </DialogOverlay>
    </Dialog.Portal>
  );
}
