import styled from "styled-components";
import { EMRButton } from "../../../Button";

export const Button = styled(EMRButton)`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: white;
  width: 244px;
  height: 48px;
  margin-top: 16px;
  margin-bottom: 16px;
  align-self: center;
  min-width: 244px;

  @media (max-width: 992px) {
    width: 100%;
  }
`;

export const EmptyState = styled.span`
  color: #9ba5ab;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  margin-top: 16px;
  margin-bottom: 16px;
  width: 244px;
  align-self: center;
`;
