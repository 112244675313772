const initialValues = {
  departments: {},
  specialities: {},
  states: {},
  years: {},
  examTypeCategories: {},
  examTypes: {},
};

const getConfigs = (action) => action.entities;

export default function configsReducer(state = initialValues, action) {
  switch (action.type) {
    case "CONFIGS_FETCHED":
      return { ...state, ...getConfigs(action) };
    default:
      return state;
  }
}
