import { createSelector } from "reselect";
import orderByDate from "../utils/orderByDate";
import onlyNotNull from "../utils/onlyNotNull";

const getMockTemplates = (state) => state.entities.mockTemplates;
const getAssociationId = (_, id) => id;
const getProps = (_, props) => props;
const getMockTemplateExamIds = (state) => state.mockTemplatesExamList.ids;
const getMockTemplatesHighlightedList = (state) =>
  state.mockTemplatesHighlightedList;
const getMockTemplatesExercisesList = (state) =>
  state.mockTemplatesExercisesList;
const getMocks = (state) => state.entities.mocks;
const getMockTemplateType = (_, mockTemplateType) => mockTemplateType;

export const getAllMockTemplates = createSelector(
  getMockTemplates,
  (mockTemplates) => Object.values(mockTemplates)
);

export const getMockTemplateById = (state, mockTemplateId) => {
  return getAllMockTemplates(state).find(mockTemplate => mockTemplate.id === mockTemplateId)
}

export const getMockTemplateFromLevel = createSelector(
  getAllMockTemplates,
  getAssociationId,
  (mockTemplates, levelId) =>
    mockTemplates?.filter((mockTemplate) =>
      mockTemplate.levelIds.includes(levelId)
    )
);

export const getMockTemplatesExam = createSelector(
  getMockTemplates,
  getProps,
  getMockTemplateExamIds,
  (mockTemplates, year, ids) =>
    ids
      ?.map((id) => mockTemplates[id])
      .filter((mockTemplate) => mockTemplate?.year == year)
      .sort(orderByDate("createdAt"))
);

export const getMockTemplatesExamYears = createSelector(
  getMockTemplates,
  getMockTemplateExamIds,
  (mockTemplates, ids) => {
    return [
      ...new Set(ids?.map((id) => mockTemplates[id]?.year).filter(onlyNotNull)),
    ].sort();
  }
);

export const makeMockTemplatesHighlightedList = createSelector(
  getMockTemplates,
  getMockTemplatesHighlightedList,
  (mockTemplates, list) => {
    return {
      ...list,
      data: list.ids.map((id) => mockTemplates[id]).filter((item) => item),
    };
  }
);

export const getMockInProgressFromTemplatesHighlighted = createSelector(
  getMocks,
  makeMockTemplatesHighlightedList,
  (mocks, list) => {
    const mockIds = [
      ...new Set(
        list.data.map((mockTemplate) => mockTemplate.meta.mocks).flat()
      ),
    ];

    return Object.values(mocks)
      .filter((mock) => mockIds.includes(mock.id) && mock.progress < 100)
      .sort(orderByDate("updatedAt"))[0];
  }
);

export const makeMockTemplatesExercisesList = createSelector(
  getMockTemplates,
  getMockTemplatesExercisesList,
  (mockTemplates, list) => {
    return {
      ...list,
      data: list.ids.map((id) => mockTemplates[id]).filter((item) => item),
    };
  }
);

export const getMockInProgressFromTemplatesExercises = createSelector(
  getMocks,
  makeMockTemplatesExercisesList,
  (mocks, list) => {
    const mockIds = [
      ...new Set(
        list.data.map((mockTemplate) => mockTemplate.meta.mocks).flat()
      ),
    ];

    return Object.values(mocks)
      .filter((mock) => mockIds.includes(mock.id) && mock.progress < 100)
      .sort(orderByDate("updatedAt"))[0];
  }
);

export const getMockByTemplateType = createSelector(
  getMockTemplateType,
  getMockInProgressFromTemplatesHighlighted,
  getMockInProgressFromTemplatesExercises,
  (mockTemplateType, mockHighlighted, mockExercises) =>
    mockTemplateType == "highlighted" ? mockHighlighted : mockExercises
);

export const makeMockTemplatesExamList = createSelector(
  getMockTemplates,
  getMockTemplatesExam,
  (mockTemplates, list) => {
    return {
      ...list,
      data: list.ids.map((id) => mockTemplates[id]).filter((item) => item),
    };
  }
);
