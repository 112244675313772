import { colors } from '../../library/theme';

export const COLOR_MAPPER = {
  yellow: {
    className: "yellow-highlight",
    hex: colors.status.yellow100,
  },
  red: {
    hex: colors.status.red200,
    className: "red-highlight",
  },
  green: {
    hex: "#27EA8F",
    className: "green-highlight",
  },
};

export const DEFAULT_COLOR = COLOR_MAPPER.yellow;

export const SELECTION_TOOLTIP_ID = 'highlighter-selection-tooltip';

export const HighlightRangePosition = Object.freeze({
  ISOLATED: 'ISOLATED',
  EXACT: 'EXACT',
  INSIDE: 'INSIDE',
  CONTAINS: 'CONTAINS'
});
