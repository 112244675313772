import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { medtrackApi } from "../../../../services/medtrack";
import { useSelector } from "react-redux";
const MedTrackContext = createContext();

export const periodOptions = {
  MONTH: "month",
  WEEK: "week",
  LAST_WEEK: "last_week",
  ALL: "",
};

export const orderByStartDateOptions = {
  ASC: "asc",
  DESC: "desc",
};

const filtersInitialState = {
  category: "",
  cursor: null,
  orderByStartDate: orderByStartDateOptions.ASC,
  period: periodOptions.ALL,
  size: 4,
  status: "",
  bigArea: "",
};

export function MedTrackProvider({ children }) {
  const [dontShowAgain, setDontShowAgain] = useState(false);
  const [state, dispatch] = useReducer(useCases, filtersInitialState);
  const [isRecalculateButtonAvailable, setIsRecalculateButtonAvailable] =
    useState(false);
  const [shouldResetFilters, setShouldResetFilters] = useState(false);
  const [basesBigArea, setBasesBigArea] = useState(null);
  const [isLoadingTodoGroups, setIsLoadingTodoGroups] = useState(true);
  const [isLoadingSchedule, setIsLoadingSchedule] = useState(true);
  const [todoGroups, setTodoGroups] = useState([]);
  const reduxState = useSelector((state) => state.isMedTrackFirstAccess);
  const [selectedMedtrackType, setSelectedMedtrackType] = useState({
    type: "",
    id: null,
  });

  function useCases(state, action) {
    switch (action.type) {
      case "ADD_CATEGORY":
        return { ...state, category: action.payload };
      case "ADD_PERIOD":
        return { ...state, period: action.payload };
      case "ADD_STATUS":
        return { ...state, status: action.payload };
      case "ADD_CURSOR":
        return { ...state, cursor: action.payload };
      case "ADD_BIG_AREA":
        return { ...state, bigArea: action.payload };
      case "ADD_START_DATE":
        return { ...state, orderByStartDate: action.payload };
      case "RESET_FILTERS":
        return filtersInitialState;
      default:
        return state;
    }
  }

  function clearReset() {
    setShouldResetFilters(false);
  }

  function setCategoryFilter(value) {
    dispatch({ type: "ADD_CATEGORY", payload: value });
    dispatch({ type: "ADD_CURSOR", payload: null });
  }

  function setStatusFilter(value) {
    dispatch({ type: "ADD_STATUS", payload: value });
    dispatch({ type: "ADD_CURSOR", payload: null });
  }

  function setPeriodFilter(value) {
    dispatch({ type: "ADD_PERIOD", payload: value });
    dispatch({ type: "ADD_CURSOR", payload: null });
  }

  function setBigAreaFilter(value) {
    dispatch({ type: "ADD_BIG_AREA", payload: value });
    dispatch({ type: "ADD_CURSOR", payload: null });
  }

  function setOrderByStartDate(value) {
    dispatch({ type: "ADD_START_DATE", payload: value });
    dispatch({ type: "ADD_CURSOR", payload: null });
  }

  function resetFilters() {
    dispatch({ type: "RESET_FILTERS" });
    setShouldResetFilters(true);
  }

  /**
   *
   * @param {number | null} value
   */
  function setCursor(value) {
    dispatch({ type: "ADD_CURSOR", payload: value });
  }

  async function checkIfCanEnableRecalculateButton(selectedscheduleId) {
    const response = await medtrackApi.get(
      `/recalculate/is-button-available?scheduleId=${selectedscheduleId}`
    );
    const { isButtonAvailable } = response.body;

    setIsRecalculateButtonAvailable(isButtonAvailable);
  }

  function getFiltersQueryParams(dynamicSize = state.size) {
    const filterQueryParams = new URLSearchParams();
    const activeFiltersArray = Object.keys(state);

    activeFiltersArray.forEach((filterKey) => {
      const filterValue = state[filterKey];
      const isCursor = filterKey === "cursor";
      const isSize = filterKey === "size";
      const isNotSizeOrCursor = filterKey !== "size" && filterKey !== "cursor";

      if (isCursor && filterValue != null) {
        filterQueryParams.append(filterKey, filterValue);
      }

      if (isSize) {
        filterQueryParams.append(filterKey, dynamicSize);
      }

      if (isNotSizeOrCursor && filterValue.length > 0) {
        filterQueryParams.append(filterKey, filterValue);
      }
    });

    const filterQueries = filterQueryParams.toString();
    return filterQueries;
  }

  async function getSchedule() {
    try {
      const response = await medtrackApi.get("/schedule");
      const schedules = response.body.schedules;
      return schedules;
    } catch (error) {
      console.error(error);
    }
  }

  async function updateActiveSchedule(scheduleId) {
    try {
      await medtrackApi.put(`/schedule/activate/${scheduleId}`);
    } catch (error) {
      console.error(error);
    }
  }

  async function getTodoGroups() {
    setIsLoadingTodoGroups(true);

    try {
      const activeScheduleId = selectedMedtrackType.id;

      if (activeScheduleId && selectedMedtrackType.type === "REVIEW") {
        const weeks = ["current", "previous", "future"];
        const urls = weeks.map((week) => {
          const size = week === "previous" ? 50 : state.size;
          const filterQueries = getFiltersQueryParams(size);
          return `/todo-group?${filterQueries}&scheduleId=${activeScheduleId}&week=${week}`;
        });
        const responses = await Promise.all(
          urls.map((url) => medtrackApi.get(url))
        );
        const [
          currentTodoGroupsResponse,
          previousTodoGroupsResponse,
          futureTodoGroupsResponse,
        ] = responses;
        const currentTodoGroups = currentTodoGroupsResponse.body.data || [];
        const previousTodoGroups = previousTodoGroupsResponse.body.data || [];
        const futureTodoGroups = futureTodoGroupsResponse.body.data || [];
        const formattedCurrentTodoGroups = currentTodoGroups.map((group) => ({
          ...group,
          type: "current",
        }));
        const formattedPreviousTodoGroups = previousTodoGroups.map((group) => ({
          ...group,
          type: "previous",
        }));
        const formattedFutureTodoGroups = futureTodoGroups.map((group) => ({
          ...group,
          type: "future",
        }));

        const allTodoGroups = [
          ...formattedCurrentTodoGroups,
          ...formattedPreviousTodoGroups,
          ...formattedFutureTodoGroups,
        ];

        setTodoGroups(allTodoGroups);
        setIsLoadingTodoGroups(false);
      } else if (activeScheduleId) {
        const filterQueries = getFiltersQueryParams();
        const url = `/todo-group?${filterQueries}&scheduleId=${activeScheduleId}`;
        const response = await medtrackApi.get(url);
        const data = response.body.data;
        const cursor = response.body.pagination.next;

        setCursor(cursor);
        if (data) setTodoGroups(data);
      } else setIsLoadingTodoGroups(false);
    } catch (error) {
      console.error(error);
    }
    setIsLoadingTodoGroups(false);
  }

  async function getBasesBigArea() {
    if (reduxState.isFirstAccess) return;
    try {
      const response = await medtrackApi.get("/big-areas/active-priority");
      const data = response.body.data;
      setBasesBigArea(data.bigArea);
    } catch (error) {
      console.error(error);
    }
  }

  async function getLastUserAction() {
    const response = await medtrackApi.get(
      "/user-activity/last?action=DONT_SHOW_REVISION_MODE_MODAL"
    );

    return response;
  }

  async function getActiveSchedule() {
    const schedules = await getSchedule();
    return schedules.find((schedule) => schedule.isActive);
  }

  async function fetchSchedule() {
    setIsLoadingSchedule(true);
    const medtrackUserType = reduxState.activeMedtrackType;
    const schedules = await getSchedule();
    const activeScheduleId = schedules.find((schedule) => schedule.isActive).id;
    const activeSchedule = schedules.find(
      (schedule) => schedule.id === activeScheduleId
    ).type;
    try {
      if (activeSchedule !== "REVIEW") {
        setSelectedMedtrackType({
          type: medtrackUserType,
          id: activeScheduleId,
        });
      } else {
        setSelectedMedtrackType({
          type: activeSchedule,
          id: activeScheduleId,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingSchedule(false);
    }
  }

  useEffect(() => {
    if (selectedMedtrackType.id) {
      checkIfCanEnableRecalculateButton(selectedMedtrackType.id);
    }
  }, [selectedMedtrackType]);

  return (
    <MedTrackContext.Provider
      value={{
        getFiltersQueryParams,
        activeFilters: state,
        checkIfCanEnableRecalculateButton,
        isRecalculateButtonAvailable,
        setCategoryFilter,
        setCursor,
        setPeriodFilter,
        setStatusFilter,
        setBigAreaFilter,
        setOrderByStartDate,
        resetFilters,
        shouldResetFilters,
        clearReset,
        basesBigArea,
        getBasesBigArea,
        isLoadingTodoGroups,
        todoGroups,
        getTodoGroups,
        setTodoGroups,
        updateActiveSchedule,
        getSchedule,
        selectedMedtrackType,
        setSelectedMedtrackType,
        getLastUserAction,
        fetchSchedule,
        setDontShowAgain,
        dontShowAgain,
        isLoadingSchedule,
        getActiveSchedule,
      }}
    >
      {children}
    </MedTrackContext.Provider>
  );
}

export function useMedTrack() {
  return useContext(MedTrackContext);
}
