import * as React from "react";

export function HappyFace() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8931_1129)">
        <path
          d="M34.1391 5.86093C30.6305 2.36254 25.945 0.29579 20.9958 0.0634285C16.0465 -0.168933 11.1881 1.44974 7.36713 4.60401C3.5462 7.75829 1.03654 12.2222 0.327211 17.1259C-0.382119 22.0295 0.759695 27.0216 3.53015 31.1293C6.30061 35.2371 10.5012 38.1662 15.3134 39.3459C20.1257 40.5255 25.2047 39.8712 29.5608 37.5105C33.917 35.1498 37.2381 31.2517 38.8771 26.576C40.5161 21.9002 40.3554 16.7817 38.4265 12.2179C37.4218 9.84162 35.9658 7.68276 34.1391 5.86093ZM20 37.6668C10.2585 37.6668 2.33317 29.7415 2.33317 20C2.33317 10.2585 10.2585 2.33316 20 2.33316C29.7415 2.33316 37.6668 10.2585 37.6668 20C37.6668 29.7415 29.7415 37.6668 20 37.6668Z"
          fill="#606A71"
        />
        <path
          d="M28.7443 22.2622H11.2576C11.1044 22.2622 10.9527 22.2924 10.8111 22.351C10.6696 22.4096 10.541 22.4956 10.4327 22.6039C10.3243 22.7122 10.2384 22.8408 10.1798 22.9824C10.1211 23.1239 10.091 23.2756 10.091 23.4288V23.461C10.1007 26.0833 11.149 28.595 13.0062 30.4462C14.8635 32.2975 17.3786 33.3375 20.0009 33.3388C25.4652 33.3388 29.9108 28.8931 29.9108 23.4288C29.9108 23.1194 29.7879 22.8227 29.5692 22.6039C29.3504 22.3851 29.0537 22.2622 28.7443 22.2622ZM20.0009 31.0056C16.2249 31.0056 13.0784 28.2198 12.5142 24.5954H27.4881C26.9253 28.2216 23.7807 31.0056 20.0009 31.0056Z"
          fill="#606A71"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.7635 11.8806C24.5871 11.8806 23.6323 13.4872 23.6323 15.4652C23.6323 17.4433 24.5871 19.0499 25.7635 19.0499C26.9398 19.0499 27.8946 17.4433 27.8946 15.4652C27.8946 13.4872 26.9394 11.8806 25.7635 11.8806ZM14.2348 11.8806C13.0588 11.8806 12.1036 13.4872 12.1036 15.4652C12.1036 17.4433 13.0588 19.0499 14.2348 19.0499C15.4107 19.0499 16.3659 17.4433 16.3659 15.4652C16.3659 13.4872 15.4111 11.8806 14.2348 11.8806Z"
          fill="#606A71"
        />
      </g>
      <defs>
        <clipPath id="clip0_8931_1129">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
