import styled, { css } from "styled-components";

const dividerVariations = {
  default: css`
    background-color: #c2ced6;
  `,
  light: css`
    background-color: #edf0f2;
  `,
};

export const DividerVariants = styled.div`
  width: 100%;
  height: 0.5px;
  ${(props) => dividerVariations[props.variant || "default"]};
  ${({ mb }) => mb && `margin-bottom:${mb}`};

  @media (max-width: 768px) {
    ${({ maxMobileMb }) => maxMobileMb && `margin-bottom:${maxMobileMb}`};
  }
`;
