import { apiGet } from '../../../processes/helpers/api';

export async function requestLastSignedInDevice(email) {
  try {
    const { body } = await apiGet(
      `${process.env.USER_PROXY_URL}/devices/last-signin?email=${email}`
    )
      .set('api-token', process.env.USER_PROXY_API_TOKEN)
      .set('product', 'PLATAFORMA_EMR');

    return body;
  } catch (err) {
    return { fingerprint: null };
  }
}
