export async function requestHasTemporaryAccess(email) {
  const hasTemporaryAccess = await fetch(
    `${process.env.USER_PROXY_URL}/auth/has-temporary-access?email=${email}`,
    {
      method: "GET",
      headers: {
        "api-token": process.env.USER_PROXY_API_TOKEN,
        "Content-Type": "application/json",
        "access-date": new Date().toISOString(),
        product: "PLATAFORMA_EMR",
      },
    }
  );
  const response = await hasTemporaryAccess.json();
  return response;
}
