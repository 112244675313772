import React from "react";
import { connect } from "react-redux";
import NotFound from "../components/NotFound";
import Spinner from "../components/library/Spinner";
import { statusConstants } from "../utils/statusConstants";

const hasAccessToProps = (testAccess) => (state) => {
  return {
    hasAccess: testAccess(state),
  };
};

export function restrictAccess(Component, testAccess) {
  return connect(hasAccessToProps(testAccess))(function RestrictedComponent({
    hasAccess,
    ...props
  }) {
    if (
      typeof hasAccess === "string" &&
      hasAccess === statusConstants.LOADING
    ) {
      return <Spinner />;
    }
    return hasAccess === statusConstants.ALLOWED ? (
      <Component {...props} />
    ) : (
      <NotFound />
    );
  });
}
