import React from "react";
import { EMRButton } from "../../../Button";
import logged from "./logged.svg";
import {
  ButtonsContainer,
  Container,
  ImageContainer,
  ModalContent,
  StyledButton,
  TextContainer,
} from "./styles";

function HasSession({ onClose, isLoading }) {
  return (
    <Container>
      <ModalContent>
        <ImageContainer>
          <img src={logged} />
        </ImageContainer>
        <TextContainer>
          <b>
            Identificamos a tentativa de um novo login em outro dispositivo.
          </b>
          <span>
            Escolha apenas um local para continuar seus estudos. Ao fechar você
            poderá continuar no outro dispositivo.
          </span>
        </TextContainer>
        <ButtonsContainer>
          <StyledButton fullSized type="button" onClick={onClose}>
            Fechar
          </StyledButton>
          <EMRButton fullSized type="submit" loading={isLoading}>
            Continuar aqui
          </EMRButton>
        </ButtonsContainer>
      </ModalContent>
    </Container>
  );
}

export default HasSession;
