import React from "react";
import { Rating as Stars } from "semantic-ui-react";
import { css } from "glamor";

const container = css({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

export default function RatingInput(props) {
  const { className, value, defaultValue, onChange, ...rest } = props;

  return (
    <div className={css(container, className)}>
      <Stars
        icon="star"
        maxRating={5}
        rating={value || defaultValue}
        size="huge"
        {...rest}
      />
    </div>
  );
}
