import { mixpanel } from "..";
// @ts-check
/**
 * @param {{
 * tabName: string,
 * filterByOrganizers: string
 * }} myPerformanceData
 */

export function trackStatisticsEvent(myPerformanceData) {
  mixpanel.track("Plataforma - Estatísticas - Interação Menu", {
    TabName: myPerformanceData.tabName,
    FilterByOrganizers: myPerformanceData.filterByOrganizers,
  });
}
