import React, { useState, useEffect } from "react";
import { setCookie, getCookie } from "cookies-next";
import { Container } from "./styles";
import { EMRButton } from "../../../frontend/componentsV2/Button";

export function CookiesConsentBanner() {
  const [isConsentAccepted, setIsConsentAccepted] = useState(false);

  function handleAcceptCookies() {
    setCookie("cookies-consent", "viewed");
    setIsConsentAccepted(true);
  }

  useEffect(() => {
    const hasBannerBeenAccepted = getCookie("cookies-consent");
    setIsConsentAccepted(hasBannerBeenAccepted);
  }, [isConsentAccepted]);

  return (
    <>
      {!isConsentAccepted && (
        <Container>
          <p>
            Nós utilizamos cookies para proporcionar uma experiência melhor e mais segura em nosso site. Ao utilizar
            nossos serviços você nos autoriza a coletar essas informações.
          </p>

          <EMRButton fullSized fontSize={"lg"} onClick={handleAcceptCookies}>
            Ok, Entendi
          </EMRButton>
        </Container>
      )}
    </>
  );
}
