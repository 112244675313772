import { apiGet } from "./helpers/api";
import { normalize, schema } from "normalizr";
import { underscore } from "inflection";

const question = new schema.Entity("questions", {
  meta: {
    questions: [new schema.Entity("questions")]
  }
});

export default function normalizeQueryString(values) {
  let params = "";
  Object.keys(values).map((k) => {
    const name = underscore(k);
    const value = values[k];
    if (value === null || value === undefined || value === false) return;
    if (Array.isArray(value)) {
      return value.map((i) => (params += `${name}[]=${i}&`));
    }
    params += `${name}=${value}&`;
  });
  return params;
}

export const fetchQuestions = (dispatch, mockId, callback) => {
  apiGet(`/api/v1/mocks/${mockId}/questions`).then((response) => {
    const questions = response.body.data;
    dispatch({
      type: "QUESTIONS_FETCHED",
      ...normalize(questions, new schema.Array(question))
    });

    const favorites = questions.reduce((acc, question) => {
      if (question.isFavorite) {
        acc.push(question.id);
      }
      return acc;
    }, []);

    dispatch({
      type: "FETCH_FAVORITES_QUESTIONS",
      questionIds: favorites
    });
    callback();
  });
};

export const goToIndex = (dispatch, index) => {
  dispatch({ type: "CURRENT_INDEX_CHANGED", index });
  dispatch({ type: 'MOCK_QUESTION_TOGGLE_HIGHLIGHTER', isHighlighterActive: false });
};

export const markAsFavorite = (dispatch, questionId) =>
  dispatch({ type: "QUESTION_AS_FAVORITE_ADDED", questionId });

export const removeFavorite = (dispatch, questionId) =>
  dispatch({ type: "REMOVE_QUESTION_AS_FAVORITE", questionId });

export const fetchAvailableQuestionsCount = ({ title, ...rest }, dispatch) => {
  const filters = normalizeQueryString({ ...rest, count: true }, false);
  dispatch({ type: "AVAILABLE_QUESTIONS_FETCH_REQUESTED" });
  apiGet(`/api/v1/questions?${filters}`).then((response) =>
    dispatch({
      type: "AVAILABLE_QUESTIONS_FETCHED",
      availableQuestionsCount: response.body.data.count
    })
  );
};

export const fetchQuestionByAnnotationId = (annotationID) => {
  return fetch(`/api/v1/notes/showQuestion`, {
    method: "POST",
    body: JSON.stringify(annotationID),
    headers: { "Content-Type": "application/json" }
  })
    .then((response) => response.json())
    .then(({ data }) => data);
};

export const fetchQuestionById = (questionId) => {
  return apiGet(
    `/api/v1/questions/get_question_with_alternatives?question_id=${questionId}`
  ).then((response) => response.body.data);
};
