import { normalize, schema } from "normalizr";
import { apiPost, apiGet, apiDel } from "./helpers/api";

const annotation = new schema.Entity("annotations");

export const fetchAnnotations = (dispatch, classroomId, lessonId) => {
  apiGet(
    `/api/v1/classrooms/${classroomId}/lessons/${lessonId}/annotations`
  ).then((response) => {
    dispatch({
      type: "ANNOTATIONS_FETCHED",
      ...normalize(response.body.data, new schema.Array(annotation)),
    });
  });
};

export const createAnnotation = (values, dispatch, props) => {
  return apiPost(
    `/api/v1/classrooms/${props.currentClassroomId}/lessons/${props.lessonId}/annotations`
  )
    .send(values)
    .then((response) => {
      dispatch({
        type: "ANNOTATION_CREATED",
        ...normalize(response.body.data, annotation),
      });
      props.reset();
    });
};

export const deleteAnnotation = (dispatch, annotation) => {
  const { id, classroomId, lessonId } = annotation;
  apiDel(
    `/api/v1/classrooms/${classroomId}/lessons/${lessonId}/annotations/${id}`
  ).then(() => {
    dispatch({
      type: "ANNOTATION_DELETED",
      id,
    });
  });
};
