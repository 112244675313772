import styled from 'styled-components'
import { colors } from '../../components/library/theme'
import * as Dialog from '@radix-ui/react-dialog'

export const DialogOverlay = styled(Dialog.Overlay)`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: fixed;
  overflow: auto;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: rgba(54, 62, 70, 0.3);
  z-index: 10;
  padding: 0;
`

export const DialogContent = styled(Dialog.Content)`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background-color: ${colors.interface.white000};
  padding: 32px 24px 24px 24px;
  width: min(780px, calc(100vw - 48px));
  margin: auto;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`

export const DialogTitle = styled.div`
  h2 {
    color: ${colors.interface.grey500};
    font-size: 24px;
    line-height: 32px;
    margin: 0;
  }
`

export const SubtitleContainer = styled.div`
  color: ${colors.interface.grey400};
  font-size: 16px;
  margin-bottom: 24px;

  strong,
  span {
    display: block;
    line-height: 24px;
  }
`

export const StyledTextarea = styled.textarea`
  border: 1px solid ${colors.interface.grey200};
  color: ${colors.interface.grey400};
  font-size: 16px;
  line-height: 24px;
  border-radius: 4px;
  resize: none;
  height: 260px;
  padding: 16px;

  ::placeholder {
    color: ${colors.interface.grey200};
    font-size: 16px;
    font-weight: 400;
  }

  :focus-within,
  :focus {
    outline: none;
    border: 1px solid ${colors.brand.light200};
  }
`

export const DialogClose = styled(Dialog.Close)`
  cursor: pointer;

  svg {
    color: ${colors.interface.grey400};
  }
`
export const ModalFooter = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  margin-top: 24px;

  span {
    flex: 1;
    text-align: left;
    color: ${colors.interface.grey200};
    font-size: 16px;
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;

  button {
    width: 242px;
    height: 48px;
    font-weight: 600;
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column-reverse;
    width: 100%;

    button {
      width: 100%;
    }
  }
`
