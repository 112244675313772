import { createGlobalStyle, keyframes } from "styled-components";

const toastRemove = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
`;

const toastRemoveOnMobile = keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(calc(-100% - 94px));
  }
`;

const GlobalStyle = createGlobalStyle`
    .toastRemove {
       animation: ${toastRemove} 0.5s ease-in-out;

       @media screen and (max-width: 768px) {
        animation: ${toastRemoveOnMobile} 0.5s ease-in-out forwards;
       }
    }

    #toast-container {
      z-index: 5;
      pointer-events: none;
      padding-top: 94px;
      padding-right: 8px;
      gap: 2rem;
      align-items: flex-end;
      justify-content: flex-start;
      display: flex;
      flex-direction: column;
      position: fixed;
      left: 0;
      top: 0;
      height: 100vh;
      width: 100vw;
      overflow: hidden;

      @media screen and (max-width: 768px) {
        justify-content: flex-start;
        align-items: center;
        padding-right: 0;
      }
    } 
  
     
`;

export default GlobalStyle;
