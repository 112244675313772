import { createSelector } from "reselect";
import { getCurrentClassroomId } from "./classroomSelectors";

const getBanners = (state) => state.entities.banners;
const getBannerType = (_, bannerType) => bannerType;

export const getBannersByType = createSelector(
  getBanners,
  getBannerType,
  getCurrentClassroomId,
  (banners, bannerType, classroomId) =>
    Object.values(banners).filter((banner) => {
      switch (bannerType) {
        case "lessons":
          return (
            banner.bannerType == "lessons" &&
            banner.classroomIds.includes(classroomId)
          );
        default:
          return banner?.bannerType == bannerType;
      }
    })
);
