import React, { useEffect, useState, useRef } from "react";
import { EMRButton } from "../../../Button";
import AuthenticationIllustration from "../../assets/Authentication.svg";
import warning from "../../assets/warning.svg";
import { Content, Description, Paragraph, Title } from "../../styles";
import {
  WarningContainer,
  InputWrapper,
  ButtonContainer,
  Illustration
} from "./styles";
import { Spinner } from "../../../Spinner";
import { io } from "socket.io-client/dist/socket.io";
import { generateFingerprint } from "../../utils/generateFingerprint";
import { requestValidateFingerprint } from "../../utils/requestValidateDevice";
import CryptoJS from "crypto-js";
import { getCookie, setCookie } from "cookies-next";
import { apiPost } from "../../../../processes/helpers/api";
import { setSession } from "../../../../processes/sessionProcesses";
import { saveTheme } from "../../../Frial/signIn";

export default function ValidateBrowser({ credentials }) {
  const [wasClicked, setWasClicked] = useState(false);
  const copyText = `${process.env.SERVER_URL}validate-browser`;
  const peerConnection = useRef(null);
  const sendChannel = useRef(null);
  const candidates = useRef([]);
  const socket = useRef(io(process.env.SECURITY_SOCKET_URL));
  const fingerprint = useRef(generateFingerprint());

  function handleCopy() {
    window.navigator.clipboard.writeText(copyText);
    setWasClicked(true);
  }

  function joinRoom() {
    socket.current.emit("join_room", {
      roomId: getCookie("email"),
      user: {
        fingerprint: fingerprint.current
      }
    });
  }

  useEffect(() => {
    socket.current.on("send_enable_browser_request", function (data) {
      if (data.type === "offer") startConnection(data);
    });

    socket.current.on("candidate", function (data) {
      if (peerConnection.current.currentRemoteDescription) {
        peerConnection.current.addIceCandidate(new RTCIceCandidate(data));
      } else {
        candidates.current.push(data);
      }
    });

    joinRoom();
  }, []);

  async function validateAllowedBrowser(otherBrowserFingerprint) {
    const response = await requestValidateFingerprint(
      getCookie("email"),
      otherBrowserFingerprint
    );

    if (response.isValid) {
      const encryptedFingeprint = CryptoJS.AES.encrypt(
        otherBrowserFingerprint,
        "emr-fingerprint-encrypt"
      ).toString();

      setCookie("@fingerprint", encryptedFingeprint);
      sendChannel.current.send(JSON.stringify({ success: true }));
      peerConnection.current.close();
      performSignIn();
    } else {
      sendChannel.current.send(JSON.stringify({ success: false }));
    }
  }

  function performSignIn() {
    apiPost("/api/v1/auth/sign_in", true, true)
      .send(credentials)
      .then((res) => {
        saveTheme();
        setSession(res);
        window.location = "/";
      });
  }

  function startConnection(offer) {
    peerConnection.current = new RTCPeerConnection();

    peerConnection.current.ondatachannel = (event) => {
      sendChannel.current = event.channel;

      sendChannel.current.onmessage = (event) => {
        socket.current.disconnect();
        validateAllowedBrowser(JSON.parse(event.data).fingerprint);
      };
    };

    peerConnection.current.onicecandidate = (event) => {
      if (event.candidate) {
        socket.current.emit("candidate", { message: event.candidate });
      }
    };

    recieveOffer(offer);
  }

  async function recieveOffer(offer) {
    try {
      await peerConnection.current.setRemoteDescription(offer);
      const answer = await peerConnection.current.createAnswer();
      await peerConnection.current.setLocalDescription(answer);

      socket.current.emit("send_enable_browser_request", {
        roomId: getCookie("email"),
        message: answer,
        user: {
          fingerprint: fingerprint.current
        }
      });

      candidates.current.forEach((candidate, index) => {
        peerConnection.current.addIceCandidate(new RTCIceCandidate(candidate));
        candidates.current.splice(index, 1);
      });
    } catch (error) {
      console.error("Erro ao estabelecer conexão com a oferta:", error);
    }
  }

  return (
    <>
      <Illustration src={AuthenticationIllustration} />
      <Content>
        <Description>
          <Title>Este acesso é de um navegador diferente.</Title>
          <Paragraph>
            Parece que você está acessando de um navegador diferente. Para
            autenticar este navegador, copie o link abaixo e cole no navegador
            utilizado anteriormente. Você precisa estar conectado em sua conta.
          </Paragraph>
        </Description>

        <InputWrapper>
          <input type="text" readOnly value={copyText} />

          <ButtonContainer>
            <EMRButton fontSize={"lg"} mediumWeight onClick={handleCopy}>
              {wasClicked ? "Link copiado!" : "Copiar link"}
            </EMRButton>
          </ButtonContainer>
        </InputWrapper>

        {wasClicked ? (
          <Spinner style={{ margin: "auto" }} />
        ) : (
          <WarningContainer>
            <img src={warning} />
            <span>
              Ao acessar a plataforma através do navegador usado anteriormente,
              você poderá acessá-la por aqui posteriormente. Essa autenticação
              será necessária apenas uma vez.
            </span>
          </WarningContainer>
        )}
      </Content>
    </>
  );
}
