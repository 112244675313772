import React from "react";
import { css } from "glamor";
import { spacing, styles, uiColors } from "../library/theme";
import { Form, TextField } from "../library/forms";
import Button from "../library/Button";

const formWrapper = css(styles.roundedCorners, {
  backgroundColor: uiColors.fieldBackground,
  padding: spacing.m,
  marginBottom: spacing.m,
});
const fieldsWrapper = css({
  display: "flex",
});
const buttonStyle = {
  marginLeft: spacing.xs,
};

export default function CommentForm(props) {
  return (
    <div className={formWrapper}>
      <Form onSubmit={props.handleSubmit}>
        <h5>Deixe seu comentário</h5>
        <div className={fieldsWrapper}>
          <TextField
            name="text"
            placeholder="Escreva sua mensagem aqui..."
            width="100%"
            fluid
          />
          <Button
            content="Comentar"
            labelPosition="left"
            icon="conversation"
            style={buttonStyle}
            primary
          />
        </div>
      </Form>
    </div>
  );
}
