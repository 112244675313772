import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { fetchMocksList } from "../../processes/mockProccess";
import { makeMocksList } from "../../selectors/mockSelectors";
import Mocks from "./Mocks";

const defaultFilters = {
  myMocks: true,
  s: ["id desc"],
};

function MocksContainer(props) {
  const { dispatch } = props;

  useEffect(() => {
    fetchMocksList(defaultFilters, dispatch);
  }, [dispatch, JSON.stringify(defaultFilters)]);

  const fetchHandler = useCallback(
    (_, { activePage = 1 }) => {
      fetchMocksList(defaultFilters, dispatch, { page: activePage });
    },
    [dispatch]
  );

  return <Mocks fetchHandler={fetchHandler} {...props} />;
}

function mapStateToProps(state) {
  return {
    list: makeMocksList(state),
  };
}

export default connect(mapStateToProps)(MocksContainer);
