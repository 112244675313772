import * as mixpanelBrowser from "mixpanel-browser";
import { apiPost } from "../../frontend/processes/helpers/api";

mixpanelBrowser.init(`${process.env.MIX_PANEL_PROJECT_KEY}`);

export const mixpanel = mixpanelBrowser;

export const mixpanelApi = {
  // @ts-check
  /**
   * @param {{
   * UserId: number,
   * Email: string,
   * Name: string,
   * IsAdmin: boolean,
   * Phone: string,
   * AvatarUrl: string,
   * }} profileData
   */

  setProfile: (profileData) => {
    apiPost("api/v1/mix_panel/set_profile", true)
      .send(profileData)
      .then((response) => response)
      .catch((err) => console.error(err));
  },
};
