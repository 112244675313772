import React from "react"

export function RevisionIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Icons">
        <path id="Vector" d="M6.78793 11.3611C6.78869 10.5828 7.01619 9.82192 7.44216 9.17288C7.86813 8.52385 8.47382 8.01529 9.18398 7.71037C9.89414 7.40545 10.6775 7.31761 11.4367 7.45776C12.196 7.59791 12.8977 7.95987 13.4547 8.49869V2.50002C13.4547 2.01379 13.2631 1.54747 12.9221 1.20366C12.5811 0.859841 12.1187 0.666687 11.6365 0.666687L4.36367 0.666687C3.56027 0.667657 2.79005 0.989893 2.22196 1.56271C1.65387 2.13553 1.3343 2.91216 1.33333 3.72224V12.2778C1.3343 13.0879 1.65387 13.8645 2.22196 14.4373C2.79005 15.0101 3.56027 15.3324 4.36367 15.3334H10.7274C9.68256 15.3334 8.68055 14.9149 7.94177 14.1699C7.20298 13.425 6.78793 12.4146 6.78793 11.3611ZM4.36367 4.94446C4.36367 4.78239 4.42752 4.62695 4.54118 4.51234C4.65484 4.39774 4.80899 4.33335 4.96973 4.33335H9.81826C9.979 4.33335 10.1332 4.39774 10.2468 4.51234C10.3605 4.62695 10.4243 4.78239 10.4243 4.94446C10.4243 5.10654 10.3605 5.26198 10.2468 5.37659C10.1332 5.49119 9.979 5.55558 9.81826 5.55558H4.96973C4.80899 5.55558 4.65484 5.49119 4.54118 5.37659C4.42752 5.26198 4.36367 5.10654 4.36367 4.94446ZM14.4892 15.1543C14.3756 15.2689 14.2214 15.3332 14.0607 15.3332C13.9 15.3332 13.7459 15.2689 13.6322 15.1543L12.1734 13.6834C11.7414 13.9614 11.2399 14.1098 10.7274 14.1111C10.188 14.1111 9.66066 13.9498 9.21216 13.6477C8.76366 13.3455 8.41409 12.916 8.20767 12.4135C8.00125 11.911 7.94724 11.3581 8.05247 10.8246C8.1577 10.2912 8.41745 9.80118 8.79887 9.41659C9.18029 9.03199 9.66625 8.77008 10.1953 8.66397C10.7243 8.55786 11.2727 8.61232 11.7711 8.82046C12.2694 9.0286 12.6954 9.38108 12.995 9.83331C13.2947 10.2855 13.4547 10.8172 13.4547 11.3611C13.4533 11.8779 13.3062 12.3836 13.0304 12.8192L14.4892 14.2902C14.6028 14.4048 14.6667 14.5602 14.6667 14.7222C14.6667 14.8843 14.6028 15.0397 14.4892 15.1543Z" fill="white" />
      </g>
    </svg>
  );
}
