import { hot } from "react-hot-loader/root";
import React from "react";
import NotifierContainer from "./notifier/NotifierContainer";

function Lobby(props) {
  return (
    <React.Fragment>
      <NotifierContainer />
      {props.children}
    </React.Fragment>
  );
}

export default hot(Lobby);
