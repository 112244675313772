import React from 'react'
import { css, select } from 'glamor'
import Link from './Link'
import { buttonColors, spacing, typographyColors, uiColors } from './theme'

const iconClass = css({
  marginRight: spacing.m,
  opacity: 0.3,
  pointerEvents: 'none'
})
const activeClass = css(
  {
    transition: '.3s all',
    color: `${buttonColors.primary} !important`,
    borderLeft: `8px solid ${uiColors.backgroundDarkest}`
  },
  select(`& .${iconClass}`, {
    transition: '.3s opacity',
    opacity: 1
  })
)
const item = css({
  display: 'flex',
  alignItems: 'center',
  padding: `8px ${spacing.m}`,
  fontSize: 16,
  color: `${typographyColors.defaultBody} !important`,
  transition: '.3s all',
  borderLeft: `8px solid transparent`,
  '&:hover': {
    ...activeClass
  },

  '@media (max-width: 991px)': { padding: `${spacing.xs} ${spacing.m}` },
})
const disabledClass = css({
  opacity: 0.4
})



export default function MenuItem(props) {
  const { label, icon, active, children, className, isDisabled, mouseHover, tooltip, ...rest } = props
  const baseClass = css(item, className && className, active && activeClass, isDisabled && disabledClass)
  const Icon = icon

  function handleMouseOver(event) {
    isDisabled && mouseHover(event)
  }

  function handleMouseLeave() {
    tooltip.current.style.display = 'none'
  }


  return (
    <Link onMouseLeave={handleMouseLeave} onMouseOver={handleMouseOver} className={baseClass} {...rest}>
      {icon && <Icon size={22} className={iconClass} />}
      {label || children}
    </Link>
  )
}
