import React from "react";

export function LessonRevisionIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.15622 13.7501C3.96435 13.7497 3.77588 13.6994 3.60935 13.6041C3.23435 13.3916 3.00122 12.9791 3.00122 12.5313V3.46881C3.00122 3.01974 3.23435 2.60849 3.60935 2.39599C3.77985 2.298 3.97354 2.24761 4.17018 2.25009C4.36681 2.25256 4.55918 2.30781 4.72716 2.41006L12.4725 7.04631C12.6339 7.14752 12.767 7.28807 12.8592 7.45479C12.9514 7.6215 12.9998 7.8089 12.9998 7.99943C12.9998 8.18996 12.9514 8.37736 12.8592 8.54408C12.767 8.71079 12.6339 8.85134 12.4725 8.95256L4.72591 13.5901C4.554 13.694 4.3571 13.7493 4.15622 13.7501Z"
        fill="#35BD78"
      />
      <path
        d="M4.15622 13.7501C3.96435 13.7497 3.77588 13.6994 3.60935 13.6041C3.23435 13.3916 3.00122 12.9791 3.00122 12.5313V3.46881C3.00122 3.01974 3.23435 2.60849 3.60935 2.39599C3.77985 2.298 3.97354 2.24761 4.17018 2.25009C4.36681 2.25256 4.55918 2.30781 4.72716 2.41006L12.4725 7.04631C12.6339 7.14752 12.767 7.28807 12.8592 7.45479C12.9514 7.6215 12.9998 7.8089 12.9998 7.99943C12.9998 8.18996 12.9514 8.37736 12.8592 8.54408C12.767 8.71079 12.6339 8.85134 12.4725 8.95256L4.72591 13.5901C4.554 13.694 4.3571 13.7493 4.15622 13.7501Z"
        fill="#DB4E8B"
      />
    </svg>
  );
}
