import styled from "styled-components";
import { colors } from "../../../components/library/theme";

export const Container = styled.div`
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 400px;
  background-color: red;
`;

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  gap: 8px;

  p {
    max-width: 600px;
    text-align: center;
    font-size: 16px;
  }

  @media (max-width: 991px) {
    p {
      max-width: 500px;
    }
  }
`;

export const Title = styled.h2`
  margin: 0;
  font-size: 18px;
  color: ${colors.interface.grey400};
  text-align: center;
  font-weight: 400;
  font-weight: 600;
`;

const threeCardsStyle = `
  display: grid;
  grid-template-columns: 1fr 305px 1fr;
`;

const twoCardsOrLess = `
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
`;

export const Wrapper = styled.div`
  ${(props) => (props.length === 3 ? threeCardsStyle : twoCardsOrLess)}
  gap: 16px;
  align-items: flex-end;
  width: 100%;

  @media (max-width: 991px) {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
  }
`;
