import { getCookie } from "cookies-next";
import { detectPlatform } from "./detectPlatform";

export async function requestRegisterActiveDevice(fingerprint) {
  const response = await fetch(`${process.env.USER_PROXY_URL}/devices`, {
    method: "POST",
    headers: {
      "api-token": process.env.USER_PROXY_API_TOKEN,
      "Content-Type": "application/json",
      "access-date": new Date().toISOString(),
      product: "PLATAFORMA_EMR",
    },
    body: JSON.stringify({
      email: getCookie("email"),
      platform: detectPlatform(),
      fingerprint,
    }),
  });
  return response;
}
