import { apiGet } from "../../../processes/helpers/api";
import { detectPlatform } from "./detectPlatform";

export async function requestIsActiveDeviceRegistered(email) {
  const { body: hasDevice } = await apiGet(
    `${process.env.USER_PROXY_URL}/devices/has-device?email=${email}`
  )
    .set("api-token", process.env.USER_PROXY_API_TOKEN)
    .set("product", "PLATAFORMA_EMR")
    .set("platform", detectPlatform());

  return hasDevice;
}
