import React from "react";
import { Iframe, Section } from "./styles";

export function Video() {
  return (
    <Section>
      <Iframe
        src={process.env.SAFETY_ONBOARD_VIDEO}
        frameborder="0"
        allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
        title="VIDEO DE SEGURANÇA DA PLATAFORMA FINAL FINAL"
      />
    </Section>
  );
}
