import { detectPlatform } from "./detectPlatform";

export async function requestValidateFingerprint(email, fingerprint) {
  try {
    const response = await fetch(
      `${process.env.USER_PROXY_URL}/devices/validate-fingerprint`,
      {
        method: "POST",
        headers: {
          "ngrok-skip-browser-warning": true,
          "api-token": process.env.USER_PROXY_API_TOKEN,
          "Content-Type": "application/json",
          "access-date": new Date().toISOString(),
          product: "PLATAFORMA_EMR"
        },
        body: JSON.stringify({
          email,
          fingerprint,
          platform: detectPlatform()
        })
      }
    );

    if (response.status !== 200) {
      return { isValid: false };
    }

    const data = await response.json();
    return data;
  } catch (error) {
    return {
      isValid: false
    };
  }
}
