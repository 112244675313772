import React from "react";
import { Grid } from "semantic-ui-react";
import { spacing } from "../library/theme";
import Empty from "./Empty";
import MyPerformanceSubjectFormContainer from "./MyPerformanceSubjectFormContainer";
import ProeficiencyComparisonCardContainer from "./ProeficiencyComparisonCardContainer";

const container = {
  padding: spacing.m,
};

export default function MyPerformanceSubject({
  subjects,
  specialityIdEq,
  nameCont,
}) {
  const hasSearch = specialityIdEq || nameCont;
  const emptyText = hasSearch
    ? "Sem resultados para sua pesquisa"
    : "Selecione um filtro";

  return (
    <div style={container}>
      <MyPerformanceSubjectFormContainer />
      {subjects?.length && hasSearch ? (
        <Grid>
          {subjects.map((subject) => (
            <Grid.Column computer={8} tablet={16}>
              <ProeficiencyComparisonCardContainer
                type="Subject"
                statitsticKey="success_rate"
                key={`subject${subject.id}Report`}
                {...subject}
              />
            </Grid.Column>
          ))}
        </Grid>
      ) : (
        <Empty text={emptyText} />
      )}
    </div>
  );
}
