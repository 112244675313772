import { Children } from "react";
import styled from "styled-components";
import { colors } from "../../../../components/library/theme";

export const Container = styled.div`
  border: solid 1px;
  border-radius: 4px;
  border-color: ${({ isRevision, isCurrentWeek }) =>
    isRevision && isCurrentWeek
      ? colors.medtrack.green200
      : colors.medtrack.grey100};
  overflow: hidden;

  @keyframes showOverflow {
    to {
      overflow: visible;
    }
  }

  &[data-open="true"] {
    animation: showOverflow 500ms forwards;
  }

  @media (max-width: 640px) {
    &[data-open="true"] {
      overflow: visible;
    }
  }
`;
export const AccordionTitle = styled.div`
  cursor: pointer;
  display: flex;
  gap: 16px;
  font-size: 12px;
  padding: 14px 16px;
  align-items: center;
  font-weight: 600;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  background-color: ${({ isRevision, isCurrentWeek }) => {
    if (!isRevision) return colors.medtrack.grey000;
    if (isCurrentWeek) return colors.medtrack.green000;
    return colors.medtrack.white000;
  }};

  svg {
    transition: 500ms;
    min-height: 24px;
    margin-left: auto;
    transform: ${(props) => props.active && "rotate(180deg)"};

    @media (max-width: 640px) {
      transition: 300ms;
    }
  }

  @media (max-width: 991px) {
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    gap: 8px;

    svg {
      position: absolute;
      right: 16px;
    }
  }
`;
export const AccordionContent = styled.div`
  transition: 500ms;
  max-height: ${(props) =>
    props.active ? `${Children.count(props.children) * 105}px` : 0};

  .todoItem:last-child {
    border: 0 !important;
  }

  @media (max-width: 640px) {
    transition: 300ms;
  }
`;
export const Tag = styled.span`
  display: flex;
  height: 16px;
  justify-content: center;
  align-items: center;
  padding: 0 8px;
  background-color: ${({ color }) => color};
  font-size: 12px;
  color: #fff;
  border-radius: 12px;

  @media (max-width: 991px) {
    font-size: 10px;
  }
`;
export const DateWrapper = styled.div`
  display: flex;
  gap: 16px;
`;
export const BulletPoint = styled.span`
  display: ${({ isRevision, isCurrentWeek }) => {
    if (isRevision && !isCurrentWeek) return "block";
    return "none";
  }};
  font-size: 16px;
  line-height: 24px;
  margin-left: 8px;
`;
