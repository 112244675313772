import * as React from "react";

export function NeutralFace() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8931_1117)">
        <path
          d="M34.139 5.86093C30.6304 2.36254 25.945 0.29579 20.9958 0.0634285C16.0465 -0.168933 11.1881 1.44974 7.36713 4.60401C3.5462 7.75829 1.03654 12.2222 0.327212 17.1258C-0.382119 22.0295 0.75969 27.0216 3.53015 31.1293C6.3006 35.2371 10.5012 38.1662 15.3134 39.3458C20.1256 40.5255 25.2047 39.8712 29.5608 37.5105C33.917 35.1498 37.2381 31.2517 38.8771 26.5759C40.516 21.9002 40.3554 16.7817 38.4265 12.2179C37.4218 9.84161 35.9658 7.68276 34.139 5.86093ZM20 37.6668C10.2585 37.6668 2.33316 29.7415 2.33316 20C2.33316 10.2585 10.2585 2.33316 20 2.33316C29.7415 2.33316 37.6668 10.2585 37.6668 20C37.6668 29.7415 29.7415 37.6668 20 37.6668Z"
          fill="#606A71"
        />
        <path
          d="M28.2339 28.9673H11.7644C11.455 28.9673 11.1583 28.8444 10.9395 28.6256C10.7208 28.4069 10.5979 28.1101 10.5979 27.8007C10.5979 27.4913 10.7208 27.1946 10.9395 26.9758C11.1583 26.7571 11.455 26.6342 11.7644 26.6342H28.2339C28.5433 26.6342 28.84 26.7571 29.0588 26.9758C29.2776 27.1946 29.4005 27.4913 29.4005 27.8007C29.4005 28.1101 29.2776 28.4069 29.0588 28.6256C28.84 28.8444 28.5433 28.9673 28.2339 28.9673Z"
          fill="#606A71"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.7643 11.8806C24.5879 11.8806 23.6332 13.4872 23.6332 15.4652C23.6332 17.4433 24.5879 19.0499 25.7643 19.0499C26.9407 19.0499 27.8955 17.4433 27.8955 15.4652C27.8955 13.4872 26.9403 11.8806 25.7643 11.8806ZM14.2356 11.8806C13.0597 11.8806 12.1045 13.4872 12.1045 15.4652C12.1045 17.4433 13.0597 19.0499 14.2356 19.0499C15.4116 19.0499 16.3668 17.4433 16.3668 15.4652C16.3668 13.4872 15.412 11.8806 14.2356 11.8806Z"
          fill="#606A71"
        />
      </g>
      <defs>
        <clipPath id="clip0_8931_1117">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
