import styled from "styled-components";
import { colors } from "../../../../../../components/library/theme";

export const Container = styled.div`
  width: 100%;
  height: 8px;
  border-radius: 4px;
  background-color: ${colors.interface.grey100};
`;
export const Bar = styled.div`
  height: 8px;
  border-radius: 4px;
  width: ${(props) => `${props.value}%`};
  background-color: ${(props) => props.isSecondPhase ? colors.medtrack.green200 : colors.brand.light200};
`;
