import React from "react";
import { css } from "glamor";
import Button from "../library/Button";
import Popup from "../library/Popup";
import RatingField from "../library/forms/RatingField";
import { TextAreaField, Form } from "../library/forms";
import { spacing } from "../library/theme";

const popupContent = css({
  width: 180,
  padding: `${spacing.s} 0`,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: spacing.m,
});
const popupContentItem = css({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: spacing.s,
});

export default function LessonRatingForm(props) {
  return (
    <Popup
      position="bottom center"
      on="click"
      trigger={<Button primary>Avalie esta aula</Button>}
      content={
        <Form className={popupContent}>
          <div className={popupContentItem}>
            <RatingField name="value" required />
          </div>
          <TextAreaField
            name="text"
            rows={3}
            fluid
            placeholder="Escreva sua avaliação..."
          />
          <Button
            primary
            fluid
            onClick={props.handleSubmit}
            disabled={props.invalid || props.pristine}
          >
            Avaliar
          </Button>
        </Form>
      }
    />
  );
}
