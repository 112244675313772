import styled, { css } from "styled-components";
import { colors } from "../../../components/library/theme";

const statusColors = {
  "Alto impacto": css`
    background-color: ${colors.status.red000};
    color: ${colors.status.red300};
  `,
  "Médio impacto": css`
    background-color: ${colors.status.yellow000};
    color: ${colors.status.yellow200};
  `,
  "Baixo impacto": css`
    background-color: ${colors.status.blue100};
    color: ${colors.status.blue400};
  `
};

export const Container = styled.div`
  width: 100%;
  min-height: 72px;
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  background-color: ${colors.interface.white000};
  box-shadow: 0 2px 5px #00000010;
  border-radius: 4px;
  margin-bottom: 24px;

  @media (max-width:1311px){
    flex-direction:column;

    & > button{
      margin-top: 20px !important;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  h3 {
    font-size: 14px;
    line-height: 18px;
    color: ${colors.interface.grey500};
    margin: 0;
  }
`;

export const SubjectTag = styled.div`
  width: fit-content;
  height: 24px;
  border-radius: 99px;

  font-size: 12px;
  line-height: 130%;
  font-weight: 600;

  padding: 2px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;

  ${(props) => statusColors[props.mode]}
`;

export const Description = styled.p`
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: ${colors.interface.grey500};
`;
