import {
  apiDel,
  apiGet,
  apiPatch,
  apiPost,
  apiPut,
} from "../../frontend/processes/helpers/api";

function getMedtrackApiUrl() {
  return process.env.MEDTRACK_API_URL;
}

export const medtrackApi = {
  get: (endpoint, body = {}) =>
    apiGet(`${getMedtrackApiUrl()}${endpoint}`, true).send(body),
  post: (endpoint, body = {}) =>
    apiPost(`${getMedtrackApiUrl()}${endpoint}`, true).send(body),
  put: (endpoint, body = {}) =>
    apiPut(`${getMedtrackApiUrl()}${endpoint}`, true).send(body),
  delete: (endpoint, body = {}) =>
    apiDel(`${getMedtrackApiUrl()}${endpoint}`, true).send(body),
  patch: (endpoint, body = {}) =>
    apiPatch(`${getMedtrackApiUrl()}${endpoint}`, true).send(body),
};
