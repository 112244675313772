import React, { useContext } from "react";
import { IoArrowForward } from "react-icons/io5";
import { Illustration } from "./illustration";
import {
  Button,
  IllustrationWrapper,
  Text,
  Title,
  TitleWrapper
} from "./styles";
import { context } from "../context";

export function Step1() {
  const { firstName, setStep, data } = useContext(context);

  function handleClick() {
    setStep(1);
  }

  function formatDate(date) {
    if (!date) return "";
    return new Date(date).toLocaleDateString("pt-br");
  }

  return (
    <>
      <TitleWrapper>
        <Title>Bem-vindo(a), {firstName()}!</Title>
        <Text>
          Que bom ter você conosco! Seu super simulado será liberado no dia{" "}
          {formatDate(data?.next_exam)}. Até lá aproveite nossa plataforma.
        </Text>
      </TitleWrapper>
      <IllustrationWrapper>
        <Illustration />
      </IllustrationWrapper>
      <Button onClick={handleClick}>
        Avançar
        <IoArrowForward size={24} />
      </Button>
    </>
  );
}
