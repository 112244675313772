import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 99999px;
  background-color: ${({ checked }) => (checked ? "#D3FCE9" : "#f7fafc")};

  cursor: pointer;
  transition: all 2ms;

  > div {
    display: none;
  }

  > svg path {
    fill: ${({ checked }) => (checked ? "#009a5a" : "#606A71")};
  }

  :hover {
    background-color: #edf0f2;

    > div {
      display: initial;
    }
  }
  @media (max-width: 500px) {
    width: 48px;
    height: 48px;

    > svg {
      width: 32px;
      height: 32px;
    }
  }
`;

export const Tooltip = styled.div`
  display: none;
  width: 122px;
  position: absolute;
  background-color: #c2ced6;
  color: #363e46;
  top: calc(100% + 16px);
  text-align: center;
  padding: 8px;
  border-radius: 4px;
  border: solid 2px #c2ced6;
  box-shadow: 0px 2px 4px -2px #1018280f;

  :after {
    background-color: #c2ced6;
    border: solid 2px #c2ced6;
    border-radius: 4px 0 0 0;
    border-width: 2px 0 0 2px;
    content: "";
    height: 15px;
    left: 50%;
    position: absolute;
    transform: translateX(-50%) rotate(45deg);
    top: -10px;
    width: 15px;
  }
`;
