import React from "react";
import { PracticeIcon, RevisionIcon, LessonRevisionIcon } from "./icons";
import { IoBook } from "react-icons/io5";
import { colors } from "../../../../components/library/theme";

const medtrackRevisionFilterCategories = [
  {
    todoItemCategory: "",
    label: "Todos",
    icon: null,
    colors: {
      icon: colors.brand.pure,
      hover: colors.brand.pure,
    },
  },
  {
    todoItemCategory: "revision_lesson",
    label: "Aula de Revisão",
    icon: <LessonRevisionIcon />,
    colors: {
      icon: colors.medtrack.pink300,
      hover: colors.medtrack.pink100,
    },
  },
  {
    todoItemCategory: "practice",
    label: "Prática",
    icon: <PracticeIcon />,
    colors: {
      icon: colors.medtrack.blue200,
      hover: colors.medtrack.blue100,
    },
  },
  {
    todoItemCategory: "revision",
    label: "Revisão",
    icon: <RevisionIcon />,
    colors: {
      icon: colors.medtrack.pink200,
      hover: colors.medtrack.pink100,
    },
  },
];
const medtrackfilterCategories = [
  {
    todoItemCategory: "",
    label: "Todos",
    icon: null,
    colors: {
      icon: colors.brand.pure,
      hover: colors.brand.pure,
    },
  },
  {
    todoItemCategory: "theory",
    label: "Teoria",
    icon: <IoBook size={16} color={colors.interface.white000} />,
    colors: {
      icon: colors.medtrack.purple200,
      hover: colors.medtrack.purple100,
    },
  },
  {
    todoItemCategory: "practice",
    label: "Prática",
    icon: <PracticeIcon />,
    colors: {
      icon: colors.medtrack.blue200,
      hover: colors.medtrack.blue100,
    },
  },
  {
    todoItemCategory: "revision",
    label: "Revisão",
    icon: <RevisionIcon />,
    colors: {
      icon: colors.medtrack.pink200,
      hover: colors.medtrack.pink100,
    },
  },
];

export function filterCategories(isRevision) {
  return isRevision
    ? medtrackRevisionFilterCategories
    : medtrackfilterCategories;
}
