const initialState = {
  loading: true,
  availableQuestionsCount: null,
};

export default function mockModalReducer(state = initialState, action) {
  switch (action.type) {
    case "AVAILABLE_QUESTIONS_FETCH_REQUESTED":
      return {
        loading: true,
        availableQuestionsCount: null,
      };
    case "AVAILABLE_QUESTIONS_FETCHED":
      return {
        loading: false,
        availableQuestionsCount: action.availableQuestionsCount,
      };
    default:
      return state;
  }
}
