import styled from 'styled-components'

export const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.4px;
  width: 100%;

  p {
    font-size: 14px;
  }
`

export const InputContainer = styled.div`
  margin-bottom: 24px;

  label {
    color: #606A71;
    font-size: 14px;
    line-height: 2.1px;
  }

  > span {
    display: block;
    color: #9BA5AB;
    font-size: 12px;
    line-height: 1.8px;
    margin-top: 4px;
  }

  button {
    margin-top: 40px;
  }

  :first-child {
    margin-top: 24px;
  }
`

export const Container = styled.div`
  width: 100%;
  max-width: 520px;
`
export const Description = styled.p`
  font-weight: 600;
  margin: 4px 0 1.6px 0;
`

export const ErrorText = styled.p`
  font-size: 12px;
  color: #E64444;
`

export const RandomError = styled.span`
  margin-top: -2.3px;
`

export const StyledButton = styled.button`
  div {
    scale: 70%;
  }
`
