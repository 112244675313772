import { TooltipChart, TooltipDefault } from "./models";
import React from "react";
export const Tooltip = ({ text, type = "default", children, isContentBlocked }) => {
  switch (type) {
    case "chart":
      return <TooltipChart text={text}>{children}</TooltipChart>;
    default:
      return <TooltipDefault isContentBlocked={isContentBlocked} text={text}>{children}</TooltipDefault>;
  }
};
