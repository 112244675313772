import { mixpanel } from "..";
// @ts-check
/**
 * @param {{
 * name: string,
 * status: string,
 * score: number | null,
 * progress: number | null,
 * studyTime: number,
 * isFrialUser,
 * mockTemplateType: 'Meus Simulados' | 'Simulados Gerais' | 'Simulados de Fixação' | 'Simulados Inteligentes',
 * }} mockTemplateData
 */

export function trackContinueMockTemplateEvent(mockTemplateData) {
  mixpanel.track("Plataforma - Simulados - interação de navegação", {
    Name: mockTemplateData.name,
    Status: mockTemplateData.status,
    Score: mockTemplateData.score,
    Progress: mockTemplateData.progress,
    StudyTime: mockTemplateData.studyTime,
    UserFrial: mockTemplateData.isFrialUser,
    NomeDoBotao:
      mockTemplateData.status == "Pendente"
        ? "Começar simulado"
        : "Continuar simulado",
    NomeDaAba: mockTemplateData.mockTemplateType,
  });
}
