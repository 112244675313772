import styled from "styled-components";
import { colors } from "../../../../components/library/theme";

export const Container = styled.div`
  max-width: 770px;
  margin: auto;
  height: 348px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  gap: 64px;


  @media (max-width: 768px) {
    max-width: min(342px, 90%);
  }
`
export const Logo = styled.img`
  width: 302px;
  height: 64px;

  @media (max-width: 768px) {
    width: 210px;
    height: 45px;
  }
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  & > h2 {
  color: ${colors.interface.grey600};
  font-size: 24px;
  line-height: 31.2px;
  }

  & > small {
  display: block;
  color: ${colors.interface.grey500};
  font-size: 16px;
  line-height: 24px;
  }

  @media (max-width: 768px) {
   gap: 16px;

   & > h2 {
    font-size: 18px;
    line-height: 23.4px;
   }

   & > small {
    font-size: 14px;
    line-height: 21px;
   }

  }
`

export const Checkmark = styled.img`
  width: 92px;
  height: 92px;
`

export const ErrorIcon = styled.img`
  width: 92px;
  height: 92px;
`

export const ButtonWrapper = styled.div`
width: 244px;
height: 48px;
 
  & > button {
    font-size: 16px;
  }
`