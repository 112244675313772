import React from "react";
import { connect } from "react-redux";
import LessonProgress from "./LessonProgress";
import { getLastUpdatedLessonsUserByClassroom } from "../../selectors/lessonsUserSelectors";
import { getLevelById } from "../../selectors/levelSelectors";
import {
  getLevelIdByLesson,
  getCurrentLesson,
  makeIsLessonMock,
  makeIsLessonPdf,
} from "../../selectors/lessonSelectors";
import { getLevelGroupByClassroom } from "../../selectors/levelGroupSelectors";
import { getCurrentClassroomId } from "../../selectors/classroomSelectors";

function mapStateToProps(state) {
  const lessonsUser = getLastUpdatedLessonsUserByClassroom(state);
  const lesson = getCurrentLesson(state, lessonsUser?.lessonId);
  const levelId = getLevelIdByLesson(state, lessonsUser?.lessonId);
  const level = getLevelById(state, levelId);
  const levelGroup = getLevelGroupByClassroom(state, level?.levelGroupId);

  return {
    currentClassroomId: getCurrentClassroomId(state),
    isLessonMock: makeIsLessonMock(state, lesson?.id),
    lessonsUser,
    lesson,
    level,
    levelGroup,
  };
}

export default connect(mapStateToProps)(LessonProgress);
