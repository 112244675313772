import { apiGet } from "./helpers/api";
import { normalize, schema } from "normalizr";

const level = new schema.Entity("levels");

export const fetchLevels = (dispatch, classroomId) => {
  apiGet(`/api/v1/classrooms/${classroomId}/levels`).then((response) => {
    dispatch({
      type: "LEVELS_FETCHED",
      ...normalize(response.body.data, new schema.Array(level))
    });
  });
};

export const getPurchasedLevels = (dispatch) => {
  return apiGet("/api/v1/user_level/enabled_level_user_frial").then(
    (response) =>
      dispatch({
        type: "LEVELS_PURCHASED_FETCHED",
        level_ids: response.body.level_ids
      })
  );
};
