import React from "react";
import { Accordion } from "../../Accordion";
import { AccordionsContainer } from "./AccordionsContainer";

export function RevisionAccordions({ formattedTodoGroups, updatedTodoGroup }) {
  let revisionTodoGroups = {
    current: [],
    previous: [],
    future: [],
  };

  formattedTodoGroups.forEach((group) => {
    if (group.type === "current") {
      revisionTodoGroups.current.push(group);
    } else if (group.type === "previous") {
      revisionTodoGroups.previous.push(group);
    } else {
      revisionTodoGroups.future.push(group);
    }
  });

  return (
    <>
      {revisionTodoGroups.current.map((group, index) => (
        <Accordion
          isBases={false}
          isRevision={true}
          updatedTodoGroup={updatedTodoGroup}
          key={index}
          data={group}
        />
      ))}

      {revisionTodoGroups.previous.length > 0 && (
        <AccordionsContainer title={"Semanas anteriores"}>
          {revisionTodoGroups.previous.map((group, index) => (
            <Accordion
              isBases={false}
              isRevision={true}
              updatedTodoGroup={updatedTodoGroup}
              key={index}
              data={group}
            />
          ))}
        </AccordionsContainer>
      )}

      {revisionTodoGroups.future.length > 0 && (
        <AccordionsContainer title={"Semanas futuras"}>
          {revisionTodoGroups.future.map((group, index) => (
            <Accordion
              isBases={false}
              isRevision={true}
              updatedTodoGroup={updatedTodoGroup}
              key={index}
              data={group}
            />
          ))}
        </AccordionsContainer>
      )}
    </>
  );
}
