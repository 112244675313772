import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { matchPath } from "react-router";
import { Grid, Sidebar } from "semantic-ui-react";
import OnlyEnrolledStudentsModal from "../componentsV2/Frial/onlyEnrolledStudentsModal";
import SuggestionsModal from "../componentsV2/SuggestionsModal";
import { fetchBanners } from "../processes/bannerProcesses";
import { fetchClassroms } from "../processes/classroomProcesses";
import { fetchConfigs } from "../processes/configProccess";
import { fetchLessons } from "../processes/lessonProcesses";
import { fetchLessonsUsers } from "../processes/lessonUsersProcesses";
import { fetchLevelGroups } from "../processes/levelGroupProcesses";
import { fetchLevels, getPurchasedLevels } from "../processes/levelProcesses";
import { fetchRatings } from "../processes/ratingProcesses";
import {
  isFrialUser,
  sessionValidate,
  userType,
} from "../processes/sessionProcesses";
import {
  hasMedtrackEnabled,
  isMedTrackFirstAccess,
  requestSchedules,
} from "../processes/medTrackProcess";
import {
  getClassroomsLoading,
  getFetchedClassrooms,
} from "../selectors/classroomSelectors";
import { getCurrentUser } from "../selectors/userSelectors";
import HeaderContainer from "./header/HeaderContainer";
import { uiColors, utilityColors } from "./library/theme";
import MainMenuContainer from "./mainMenu/MainMenuContainer";
import NotifierContainer from "./notifier/NotifierContainer";
import { trackClickEvents } from "../../services/mixpanel/events/trackClickEvents";
import { useHistory } from "react-router-dom";
import SuperSimuladoModal from "../componentsV2/SuperSimuladoModal";
import { verifyIsSuperSimulado } from "../componentsV2/SuperSimuladoModal/verifySuperSimulado";
import { ignoreTemplate } from "./ignoreTemplate";

const gridStyle = { minHeight: "100vh", flexWrap: "nowrap" };

const leftColumn = {
  display: "flex",
  flexDirection: "column",
  flexGrow: "unset",
  backgroundColor: uiColors.fieldBackground,
  padding: 0,
  minWidth: 250,
  width: 250,
  marginTop: 70,
};
const rightColumn = {
  backgroundColor: uiColors.contentCards,
  overflow: "hidden",
  padding: 0,
  paddingTop: 70,
  flexGrow: 1,
};

const sidebarStyle = {
  display: "flex",
  flexDirection: "column",
  padding: 0,
  boxShadow: `0 2px 5px ${utilityColors.shadow}`,
  height: "auto",
  position: "fixed",
  top: "0px",
  bottom: "0px",
  overflowY: "auto",
};

function LMSMiddleware(props) {
  const { location, dispatch, user } = props;
  const userValidated = useRef(false);

  useEffect(() => {
    if (!userValidated.current) {
      sessionValidate(dispatch).then(() => {
        userValidated.current = true;
      });
    }
    user && isFrialUser(dispatch, user);
  }, [dispatch, user]);

  if (ignoreTemplate(location)) {
    return <>{props.children}</>;
  } else {
    return <LMS {...props} />;
  }
}

function LMS(props) {
  const {
    dispatch,
    location,
    currentClassroomId,
    isFrial,
    onlyEnrolledStudentsModal,
    classroomList,
    user,
    classroomLoading,
  } = props;
  const [iframePath, setIframePath] = useState(location.pathname);
  const [superSimulado, setSuperSimulado] = useState({
    loading: true,
    is_lp_super_simulado_user: false,
    next_exam: null,
  });
  const history = useHistory();
  const currentPath = location.pathname;
  const isLessonPath = !!matchPath(currentPath, {
    path: "/classrooms/:id/lessons",
  });
  const isUserInRoundPage = iframePath.includes("round");
  const [showSidebar, setShowSidebar] = useState(false);
  const toogleSidebar = useCallback(() => {
    setShowSidebar(!showSidebar);
  }, [showSidebar]);
  const hideSidebar = useCallback(() => setShowSidebar(false));
  const onShowMobileSidebar = useCallback(
    () => (document.documentElement.scrollTop = 0)
  );

  useEffect(() => {
    userType(dispatch);
    sessionValidate(dispatch);
    fetchClassroms(dispatch);
    fetchConfigs(dispatch);
    fetchRatings(dispatch);
    fetchBanners(dispatch);
    getPurchasedLevels(dispatch);
    isMedTrackFirstAccess(dispatch);
    hasMedtrackEnabled(dispatch);
    requestSchedules(dispatch);
  }, [dispatch]);

  useEffect(() => {
    user && isFrialUser(dispatch, user);
  }, [dispatch, user]);

  useEffect(() => {
    if (currentClassroomId) {
      fetchLevelGroups(dispatch, currentClassroomId);
      fetchLevels(dispatch, currentClassroomId);
      fetchLessons(dispatch, currentClassroomId);
      fetchLessonsUsers(dispatch, currentClassroomId);
    }
  }, [dispatch, currentClassroomId]);

  useEffect(() => {
    function getIframePath(event) {
      if (event.data.redirect) {
        history.push(event.data.redirect);
        return;
      }

      if (event.data.location) {
        setIframePath(event.data.location);
      }
    }

    window.addEventListener("message", getIframePath);

    return () => {
      window.removeEventListener("message", getIframePath);
    };
  }, []);

  useEffect(() => {
    setIframePath(currentPath);
  }, [currentPath]);

  useEffect(() => {
    if (isFrial) {
      verifyIsSuperSimulado().then((response) =>
        setSuperSimulado({ ...response, loading: false })
      );
    }
  }, [isFrial]);

  function onBoardModals() {
    if (superSimulado.loading) return <></>;
    if (isFrial && !classroomLoading && classroomList.length === 0) {
      if (superSimulado.is_lp_super_simulado_user) {
        return <SuperSimuladoModal data={superSimulado} />;
      }
      return <SuggestionsModal />;
    }
    return <></>;
  }

  return (
    <>
      <OnlyEnrolledStudentsModal isOpen={onlyEnrolledStudentsModal} />

      {onBoardModals()}

      <Sidebar.Pushable>
        <Sidebar
          animation="push"
          icon="labeled"
          visible={showSidebar}
          onHide={hideSidebar}
          onShow={onShowMobileSidebar}
          style={sidebarStyle}
          onClick={hideSidebar}
        >
          <MainMenuContainer {...props} mobile />
        </Sidebar>
        <Sidebar.Pusher dimmed={showSidebar}>
          <HeaderContainer
            toogleSidebar={toogleSidebar}
            isLessonPath={isLessonPath}
          />
          <Grid padded style={gridStyle} columns="equal">
            {!isLessonPath && !isUserInRoundPage && (
              <Grid.Column only="computer" style={leftColumn}>
                <MainMenuContainer {...props} />
              </Grid.Column>
            )}
            <Grid.Column
              id="platform-page"
              style={rightColumn}
              computer={isLessonPath ? 16 : 13}
              mobile={16}
            >
              <NotifierContainer />

              {props.children}
            </Grid.Column>
          </Grid>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </>
  );
}

function mapStateToProps(state) {
  const classroomList = getFetchedClassrooms(state);
  const user = getCurrentUser(state);
  const isFrial = state?.isFrialUser;
  const classroomLoading = getClassroomsLoading(state);

  return {
    currentClassroomId: state.currentClassroomId,
    onlyEnrolledStudentsModal: state.onlyEnrolledStudentsModal.isModalVisible,
    classroomLoading,
    isFrial,
    classroomList,
    user,
  };
}

export default connect(mapStateToProps)(LMSMiddleware);
