import { css } from "glamor";
import React from "react";
import { IoMdWarning } from "react-icons/io";
import { Icon } from "semantic-ui-react";
import { trackExportMaterialEvent } from "../../../services/mixpanel/events/trackExportMaterialEvent";
import formatDate from "../../utils/formatDate";
import Link from "../library/Link";
import {
  colors,
  spacing,
  styles,
  typographyColors,
  uiColors
} from "../library/theme";

const iconName = (type) => {
  switch (type) {
    case "PDF":
      return "file pdf outline";
    case "MP3":
      return "file audio outline";
    case "XLSX":
      return "file excel outline";
    case "DOC":
      return "file outline";
    case "MIND":
      return "sitemap";
    default:
      return "cloud download";
  }
};

const container = {
  padding: `${spacing.m} ${spacing.m} 0`
};
const attachmentListItem = css(styles.roundedCorners, {
  display: "flex",
  flex: 1,
  gap: spacing.xs,
  marginTop: spacing.xxs,
  padding: `${spacing.s} ${spacing.s}`,
  cursor: "pointer",
  justifyContent: "space-between",
  lineHeight: 1.2,
  alignItems: "center",
  color: typographyColors.defaultBody,
  backgroundColor: `${uiColors.contentCards}`,
  "& + &": {
    borderTop: `1px solid ${uiColors.backgroundDark}20`
  },
  ":hover": {
    color: typographyColors.defaultBody,
    backgroundColor: `${uiColors.backgroundDark}20`
  }
});
const subtitle = {
  lineHeight: 1.2,
  marginBottom: spacing.s
};
const updatedAtStyle = {
  fontSize: 10,
  color: typographyColors.defaultTitle
};

export default function AttachmentList(props) {
  function handleMaterialClick(attachment) {
    trackExportMaterialEvent({
      classroomId: props.currentClassroomId,
      fileName: attachment.name,
      lessonId: props.lessonId,
      lessonName: props.lesson.name,
      isFrialUser: props.isFrialUser
    });
  }

  function formatFlashCardsURL(URL) {
    return "/flashcards" + URL.split("flashcards")[1];
  }

  return (
    <div style={container}>
      <h3>Material Complementar</h3>
      <p style={subtitle}>Materiais e ferramentas disponíveis</p>
      <div>
        {props.attachments.map((attachment, index) => {
          const isFlashCardsAttachment =
            attachment?.link?.includes("flashcards") &&
            attachment.fileType === "link";
          const attachmentURL = isFlashCardsAttachment
            ? formatFlashCardsURL(attachment.link) +
              "&redirectTo=" +
              window.location.pathname
            : attachment.url;

          return (
            <Link
              key={`Attachment${attachment.id}`}
              href={attachmentURL}
              target="_blank"
              className={attachmentListItem}
              onClick={() => handleMaterialClick(attachment)}
              replace={isFlashCardsAttachment}
            >
              <div>
                <p>{attachment.name || `Arquivo ${index + 1}`}</p>
                {attachment.showUpdatedAt && (
                  <p style={updatedAtStyle}>
                    <IoMdWarning size={10} /> Atualizado em{" "}
                    {formatDate(attachment.updatedAt, "DD/MM/YYYY")}
                  </p>
                )}
              </div>
              <Icon
                name={iconName(attachment.fileType)}
                color={colors.semantic}
              />
            </Link>
          );
        })}
      </div>
    </div>
  );
}
