import React, { useState, useEffect } from "react";
import FavoriteQuestionsPage from "./FavoriteQuestionsPage";
import Spinner from "../library/Spinner";
import { connect } from "react-redux";
import { isExpiredUser } from "../../selectors/userSelectors";
import { apiGet } from "../../processes/helpers/api";
import { filterOptions } from "./FavoriteQuestionsPage/filters";
import { trackClickEvents } from "../../../services/mixpanel/events/trackClickEvents";
import Page from "../library/Page";
import { Search } from "./FavoriteQuestionsPage/search";

function FavoriteQuestionsContainer({ expiredUser }) {
  const [favoriteQuestions, setFavoriteQuestions] = useState([]);
  const [isLoadingQuestions, setIsLoadingQuestions] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(filterOptions[0]);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");

  async function fetchFavoriteQuestions() {
    setIsLoadingQuestions(true);

    const filter =
      selectedFilter.action === "ALL"
        ? ""
        : `&big_area=${selectedFilter.action}`;

    const searchText = search ? `&search=${search}` : "";

    try {
      const response = await apiGet(
        `/api/v1/questions/get_favorite_questions?&page=${currentPage}${filter}${searchText}`
      );
      if (response.body) {
        setFavoriteQuestions(response.body);
        trackClickEvents({
          eventName: "Questões favoritas - interação de navegação",
          content: {
            NomeDoFiltro: selectedFilter.title
          }
        });
      }
      setIsLoadingQuestions(false);
    } catch {
      setIsLoadingQuestions(false);
    }
  }

  function handleFilter(filter) {
    document.querySelector("#favorite_search_input").value = "";
    setSearch("");
    setSelectedFilter(filter);
    setCurrentPage(1);
  }

  useEffect(() => {
    if (!expiredUser) fetchFavoriteQuestions();
  }, [selectedFilter, currentPage, search]);

  if (expiredUser) return null;

  return (
    <Page
      title="Questões favoritas"
      backlink="/mocks"
      action={<Search setSearch={setSearch} />}
    >
      {isLoadingQuestions ? (
        <Spinner />
      ) : (
        <FavoriteQuestionsPage
          favoriteQuestions={favoriteQuestions}
          selectedFilter={selectedFilter}
          setSelectedFilter={handleFilter}
          isLoadingQuestions={isLoadingQuestions}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          fetchFavoriteQuestions={fetchFavoriteQuestions}
        />
      )}
    </Page>
  );
}

function mapStateToProps(state) {
  const expiredUser = isExpiredUser(state);

  return {
    expiredUser
  };
}

export default connect(mapStateToProps)(FavoriteQuestionsContainer);
