import React from "react";
import { Modal } from "semantic-ui-react";
import Link from "../library/Link";
import Button from "../library/Button";

export default function NotificationModal(props) {
  const { notification, confirmationHandler } = props;

  return notification ? (
    <Modal open size="mini">
      <Modal.Header>{notification.title}</Modal.Header>
      <Modal.Content>
        <Link href={notification.link} target="_blank">
          <p>{notification.text}</p>
        </Link>
      </Modal.Content>
      <Modal.Actions>
        <Button
          content="Continuar"
          icon="envelope open"
          labelPosition="right"
          onClick={confirmationHandler}
        />
      </Modal.Actions>
    </Modal>
  ) : null;
}
