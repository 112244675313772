import styled from "styled-components";
import { EMRButton } from "../../../Button";

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  z-index: 50;
  justify-content: center;
  align-items: center;
`;
export const ModalContent = styled.div`
  background-color: #fff;
  padding: 24px;
  border-radius: 4px;
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);
  display: flex;
  gap: 24px;
  max-width: 600px;
  flex-direction: column;
  align-items: left;
  margin: auto 24px;
`;
export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  img {
    width: 100%;
    max-width: 390px;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  span {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  b {
    color: #363e46;
    font-size: 18px;
    font-weight: 600;
    line-height: 23.4px;
  }
  @media screen and (max-width: 768px) {
    b {
      font-size: 16px;
      line-height: 24px;
    }
    span {
      font-size: 14px;
      line-height: 21px;
    }
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;

  gap: 16px;
  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;
export const StyledButton = styled(EMRButton)`
  border: 1px solid #9ba5ab;
  color: #9ba5ab;
  background: transparent;
  :hover {
    background-color: #9ba5ab !important;
    color: #fff;
  }
`;
