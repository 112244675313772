import { detectPlatform } from "./detectPlatform";

export async function requestEmergencyAccess(email, fingerprint) {
  const response = await fetch(
    `${process.env.USER_PROXY_URL}/auth/emergency-access`,
    {
      method: "POST",
      headers: {
        "api-token": process.env.USER_PROXY_API_TOKEN,
        "Content-Type": "application/json",
        "access-date": new Date().toISOString(),
        product: "PLATAFORMA_EMR",
      },
      body: JSON.stringify({
        email,
        fingerprint,
        platform: detectPlatform(),
      }),
    }
  );

  return response;
}
