import React, { useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import { FilterButton } from "../../componentsV2/FilterButton";
import { MocksTable } from "../../componentsV2/MocksTable";
import { fetchPaginatedMock } from "../../processes/mockProccess";
import Pagination from "../library/Pagination";
import Spinner from "../library/Spinner";
import { spacing } from "../library/theme";
import Empty from "./empty";
import MockListItemContainer from "./MockListItemContainer";
import MockProgressContainer from "./MockProgressContainer";

const contentStyle = {
  textAlign: "center",
};
const loadingWrapper = {
  margin: spacing.xxl,
};

export default function MockTemplatesIntelligent(props) {
  const [mocks, setMocks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [selectedValue, setSelectedValue] = useState("ALL");
  const buttonFIlterList = [
    {
      action: "ALL",
      title: "Todos",
    },
    {
      action: "IN_PROGRESS",
      title: "Em andamento",
    },
    {
      action: "DONE",
      title: "Finalizados",
    },
    {
      action: "PENDING",
      title: "Pendentes",
    },
  ];

  const handleFetch = (action, page) => {
    setIsLoading(true);

    if (action) setStatus(action);
    if (action === "ALL") setStatus("");

    fetchPaginatedMock(action || status, "SMART", page)
      .then((mockList) => setMocks(mockList))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    handleFetch();
  }, []);

  return (
    <Grid>
      <Grid.Column computer={16} style={contentStyle}>
        <FilterButton
          data={buttonFIlterList}
          isLoading={isLoading}
          selectedValue={selectedValue}
          setSelectedValue={setSelectedValue}
          onSelectFilter={handleFetch}
        />
        <MockProgressContainer mode="intelligent" />
        {isLoading ? (
          <div style={loadingWrapper}>
            <Spinner inline />
          </div>
        ) : mocks?.dataMocks?.length === 0 ? (
          <Empty activeFilter={selectedValue} />
        ) : (
          <MocksTable variant={"mock"}>
            <thead>
              <tr>
                <th>Criação</th>
                <th>Nº de questões</th>
                <th>Nome</th>
                <th>Status</th>
                <th>Nota</th>
                <th>Progresso</th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              {mocks.dataMocks?.map((mock) => (
                <MockListItemContainer
                  key={mock.id}
                  mock={mock}
                // isExam={false}
                />
              ))}
            </tbody>
          </MocksTable>
        )}
        <Pagination
          fetchHandler={handleFetch}
          list={mocks}
          loading={isLoading}
        />
      </Grid.Column>
    </Grid >
  );
}
