import React, { useCallback, useState } from "react";
import { connect } from "react-redux";
import MockListItem from "./MockListItem.js";
import { deleteMock, updateMockTitle } from "../../processes/mockProccess.js";

function MockListItemContainer(props) {
  const { dispatch, mock, activeTabName } = props;
  const [newTitle, setNewTitle] = useState(mock.name);

  const deleteMockHandler = useCallback(() => {
    deleteMock(dispatch, mock.id);
  }, [dispatch, mock.id]);

  const updateMockHandler = useCallback(() => {
    updateMockTitle(dispatch, mock.id, newTitle);
  }, [dispatch, mock.id, newTitle]);

  return (
    <MockListItem
      activeTabName={activeTabName}
      deleteMockHandler={deleteMockHandler}
      updateMockHandler={updateMockHandler}
      setNewTitle={setNewTitle}
      {...props}
    />
  );
}

function mapStateToProps(state) {
  const isFrialUser = state?.isFrialUser;
  const activeTabName = state?.mock.activeMockTabName;
  const isLoading = state?.loading;

  return {
    isFrialUser,
    activeTabName,
    isLoading,
  };
}

export default connect(mapStateToProps)(MockListItemContainer);
