import React from "react";
import { css } from "glamor";
import { stateColors } from "../library/theme";

const container = css({
  textAlign: "center",
  width: "100%",
  color: stateColors.empty,
});

export default function EmptyClassrooms(props) {
  return <h5 className={container}>Sua lista de cursos está vazia!</h5>;
}
