import React, { useCallback, useState } from "react";
import { Button, Confirm } from "semantic-ui-react";

export default ({
  confirmationText,
  confirmButton = "Confirmar",
  cancelButton = "Cancelar",
  onClick,
  ...rest
}) => {
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const toogleOpenConfirmationHandler = useCallback(() => {
    setOpenConfirmation(!openConfirmation);
  }, [openConfirmation]);

  const confirmationHandler = useCallback(() => {
    onClick();
    setOpenConfirmation(false);
  }, []);

  return (
    <React.Fragment>
      {confirmationText && (
        <Confirm
          open={openConfirmation}
          onCancel={toogleOpenConfirmationHandler}
          onConfirm={confirmationHandler}
          content={confirmationText || "Você tem certeza dessa ação?"}
          size="mini"
          confirmButton={confirmButton}
          cancelButton={cancelButton}
        />
      )}
      <Button
        onClick={confirmationText ? toogleOpenConfirmationHandler : onClick}
        {...rest}
      />
    </React.Fragment>
  );
};
