import { apiGet } from "./helpers/api";
import { normalize, schema } from "normalizr";

const bannerSchema = new schema.Entity("banners");

export const fetchBanners = (dispatch) => {
  apiGet("/api/v1/banners/").then((response) => {
    dispatch({
      type: "BANNERS_FETCHED",
      ...normalize(response.body.data, new schema.Array(bannerSchema)),
    });
  });
};
