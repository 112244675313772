import React from "react";

export function PediatricsIcon() {
  return (
    <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2675_761)">
        <path d="M6.75021 5.625C8.30412 5.625 9.56271 4.36641 9.56271 2.8125C9.56271 1.25859 8.30412 0 6.75021 0C5.19631 0 3.93771 1.25859 3.93771 2.8125C3.93771 4.36641 5.19631 5.625 6.75021 5.625ZM4.87287 14.3719L5.77287 13.2469L3.61076 11.4469L1.99709 13.4648C1.59631 13.9641 1.58576 14.6742 1.96896 15.1875L3.65646 17.4375C3.9342 17.8066 4.35607 18 4.78146 18C5.07326 18 5.37209 17.9086 5.62521 17.7188C6.24748 17.2547 6.37053 16.3723 5.90646 15.75L4.87287 14.3719ZM9.88967 11.4469L7.72756 13.2469L8.62756 14.3719L7.59396 15.75C7.1299 16.3723 7.25295 17.2547 7.87521 17.7188C8.12834 17.9086 8.42365 18 8.71896 18C9.14787 18 9.56974 17.8066 9.84396 17.4375L11.5315 15.1875C11.9147 14.6742 11.9041 13.9641 11.5033 13.4648L9.88967 11.4469ZM13.2436 5.09766C12.7971 4.46133 11.9217 4.31016 11.2854 4.75312L9.85803 5.75508C8.00529 7.05586 5.49162 7.05586 3.6424 5.75508L2.21506 4.75664C1.57873 4.31016 0.703339 4.46484 0.256855 5.09766C-0.18963 5.73398 -0.0349423 6.60938 0.59787 7.05586L2.02521 8.05781C2.62287 8.47617 3.26974 8.79258 3.93771 9.03867V10.125H9.56271V9.04219C10.2307 8.79609 10.8776 8.47969 11.4752 8.06133L12.9026 7.05938C13.5389 6.60938 13.6901 5.73398 13.2436 5.09766Z" fill="#356BBD" />
      </g>
      <defs>
        <clipPath id="clip0_2675_761">
          <rect width="13.5" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

