import React from 'react';
import { Spinner } from '../Spinner';
import { Container } from './styles';
import PropTypes from 'prop-types';

/**
 * Componente EMRButton que renderiza botões com várias variações de estilo e comportamento.
 *
 * @param {object} props
 * @param {'standard' | 'outline' | 'cancel' | 'transparent' | 'blue' | 'yellow' | 'gray' | 'red' | 'redirect'} [props.mode='standard'] - Define o estilo do botão.
 * @param {boolean} [props.shortHeight=false] - Se verdadeiro, o botão terá uma altura menor.
 * @param {boolean} [props.loading=false] - Se verdadeiro, o botão mostrará um indicador de carregamento.
 * @param {boolean} [props.fullSized=false] - Se verdadeiro, o botão expandirá para ocupar a largura total do contêiner.
 * @param {boolean} [props.mediumWeight=false] - Se verdadeiro, o texto do botão usará um peso de fonte médio.
 * @param {React.ReactNode} props.children - O conteúdo do botão.
 */
export const EMRButton = ({
  buttonTypeVariations,
  fontSizeVariations,
  buttonName,
  children,
  loading,
  fullSized = false,
  mediumWeight = false,
  shortHeight = false,
  ...props
}) => {
  const buttonProps = {
    buttonTypeVariations,
    fontSizeVariations,
    buttonName,
    children,
    fullSized,
    mediumWeight,
    shortHeight,
    ...props,
  };

  return (
    <Container disabled={loading} {...buttonProps}>
      {loading ? <Spinner /> : children}
    </Container>
  );
};

EMRButton.propTypes = {
  mode: PropTypes.oneOf([
    'standard',
    'outline',
    'cancel',
    'transparent',
    'blue',
    'yellow',
    'gray',
    'red',
    'redirect',
  ]),
  shortHeight: PropTypes.bool,
  loading: PropTypes.bool,
  fullSized: PropTypes.bool,
  mediumWeight: PropTypes.bool,
  children: PropTypes.node.isRequired,
};
