import React, { useContext } from "react";
import { IoMdCheckmark } from "react-icons/io";
import { Circle, Container, Line, Bar, modeColors } from "./styles";
import { colors } from "../../../components/library/theme";
import { context } from "../context";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Label = styled.span`
  color: ${colors.interface.grey300};
  font-size: 12px;
  font-weight: 400;
  opacity: ${(props) => (props.mode === "active" ? 1 : 0)};
`;

function CircleEl({ mode, label }) {
  return (
    <Wrapper>
      <Circle mode={mode}>
        <IoMdCheckmark size={16} fill={modeColors[mode].fill} />
      </Circle>
      <Label mode={mode}>{label}</Label>
    </Wrapper>
  );
}

export function StepsIndicator() {
  const { step } = useContext(context);
  const steps = ["Boas-vindas", "Seleção do curso", "Onboarding"];

  return (
    <Container>
      <Line>
        <Bar fullSize={step === 2} />
      </Line>
      {steps.map((_step, index) => (
        <CircleEl
          label={_step}
          mode={index > step ? "disabled" : index == step ? "active" : "done"}
        />
      ))}
    </Container>
  );
}
