import React from "react";
import { Card } from "semantic-ui-react";
import { utilityColors } from "../library/theme";
import { Line } from "react-chartjs-2";
import EmptyGraph from "./EmptyGraph";

const options = {
  responsive: true,
  scales: {
    y: {
      beginAtZero: true,
      suggestedMax: 10,
    },
    x: {
      ticks: {
        display: false,
      },
    },
  },
};

export default function GraphCard({ scoreChartData, classroom }) {
  const parsedData = scoreChartData
    ? {
        labels: scoreChartData?.labels,
        datasets: [
          {
            label: "Minha nota",
            data: scoreChartData.user,
            borderColor: utilityColors.information,
          },
          {
            label: "Nota geral",
            data: scoreChartData.all,
            borderColor: utilityColors.danger,
          },
          {
            label: "Nota dos 10% melhores",
            data: scoreChartData.top,
            borderColor: utilityColors.warning,
          },
        ],
      }
    : null;

  return (
    <Card fluid padded>
      <Card.Content>
        <Card.Header>Estatísticas de Notas - Simulados Gerais</Card.Header>
      </Card.Content>
      <Card.Content>
        {classroom?.mocksEnabled && scoreChartData ? (
          <Line data={parsedData} options={options} />
        ) : (
          <EmptyGraph />
        )}
      </Card.Content>
    </Card>
  );
}
