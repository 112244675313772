import * as React from 'react';

export function NewsIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
      <rect width={24} height={24} fill="#27EA8F" rx={12} />
      <g clipPath="url(#a)">
        <path
          fill="#fff"
          d="M10.688 19a.679.679 0 0 1-.639-.437L8.96 15.73a.44.44 0 0 0-.251-.252l-2.834-1.09a.684.684 0 0 1 0-1.276l2.833-1.09a.438.438 0 0 0 .251-.25l1.09-2.834a.683.683 0 0 1 1.277 0l1.09 2.832a.44.44 0 0 0 .25.252l2.817 1.083a.697.697 0 0 1 .455.66.679.679 0 0 1-.438.623l-2.833 1.09a.437.437 0 0 0-.251.251l-1.09 2.834a.68.68 0 0 1-.638.437ZM7.406 9.813a.401.401 0 0 1-.374-.257l-.461-1.2a.199.199 0 0 0-.115-.114L5.257 7.78a.401.401 0 0 1 0-.749l1.199-.461a.2.2 0 0 0 .115-.115l.457-1.188a.41.41 0 0 1 .33-.265.401.401 0 0 1 .423.254l.46 1.199a.2.2 0 0 0 .116.115l1.199.461a.401.401 0 0 1 0 .749l-1.2.46a.2.2 0 0 0-.114.116L7.78 9.556a.401.401 0 0 1-.375.257ZM15.938 12a.438.438 0 0 1-.409-.28l-.624-1.624a.22.22 0 0 0-.126-.126l-1.623-.624a.437.437 0 0 1 0-.817l1.623-.624a.22.22 0 0 0 .126-.126l.62-1.612a.45.45 0 0 1 .36-.289.437.437 0 0 1 .46.278l.625 1.623a.219.219 0 0 0 .126.126l1.623.624a.437.437 0 0 1 0 .817l-1.623.624a.218.218 0 0 0-.126.126l-.624 1.623a.437.437 0 0 1-.408.281Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M5 5h14v14H5z" />
        </clipPath>
      </defs>
    </svg>
  );
}
