import React from 'react';
import { colors } from '../../../../../components/library/theme';
import { Tag } from '../styles';

const tagColors = {
  current: {
    text: 'ATUAL',
    bgColor: colors.status.blue300,
  },
  late: {
    text: 'ATRASADA',
    bgColor: colors.status.yellow200,
  },
  finished: {
    text: 'FINALIZADA',
    bgColor: colors.status.green200,
  },
};

export function MedtrackStatusTag({ tag }) {
  if (tag === 'none') return null;

  return <Tag color={tagColors[tag].bgColor}>{tagColors[tag].text}</Tag>;
}
