import React from "react";
import { css } from "glamor";
import ImagePlaceholder from "../images/default.png";
import { useCallback } from "react";
import { styles } from "../theme";

const layout = css({ position: "relative", width: "100%", height: 85 });
const wrapper = css({
  position: "absolute",
  display: "flex",
  flex: 1,
  alignItems: "center",
  justifyContent: "center",
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  height: "100%",
  boxSizing: "border-box",
});
const imageInput = {
  position: "relative",
  padding: "0 0 0 100%",
  opacity: 0,
  maxWidth: "100%",
  boxSizing: "border-box",
  display: "block",
  cursor: "pointer",
  height: "84px",
};
const previewBaseClass = css(styles.border, {
  position: "absolute",
  borderRadius: "50%",
  width: 85,
  height: 85,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
});
const placeholderClass = css({ position: "absolute" });

function freeMemory(url) {
  url && window.URL.revokeObjectURL(url);
}
const doFocus = (e) => e.target.focus();

export default function ImageInput(props) {
  const {
    id,
    value,
    onFileChange,
    clear,
    invalid,
    previewStyle,
    ...rest
  } = props;

  let previewUrl = "";
  let objectUrl = "";
  if (typeof value === "string") {
    previewUrl = value;
  } else if (value) {
    objectUrl = previewUrl = URL.createObjectURL(value);
  }

  React.useEffect(() => () => freeMemory(objectUrl), [objectUrl]);

  const previewClassName = css(previewBaseClass, previewStyle && previewStyle, {
    backgroundImage: `url(${previewUrl})`,
  });

  const onChangeHandler = useCallback(
    (e) => {
      e.preventDefault();
      onFileChange(e.target.files);
      e.target.value = "";
    },
    [onFileChange]
  );

  return (
    <div className={layout}>
      <div className={wrapper}>
        {previewUrl ? (
          <div className={previewClassName} />
        ) : (
          <img src={ImagePlaceholder} className={placeholderClass} />
        )}
        <input
          accept="image/*"
          {...rest}
          title={value ? value.name : undefined}
          style={imageInput}
          onClick={doFocus}
          onChange={onChangeHandler}
        />
      </div>
    </div>
  );
}
