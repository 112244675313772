import React from "react";
import { Link } from "react-router-dom";

export function DynamicLink({ children, to, ...rest }) {
  let isExternalLink = false;
  let link = to;
  try {
    const url = new URL(to);
    const sameHost = window.location.hostname === url.hostname;
    if (sameHost) {
      link = url.pathname + url.search;
      isExternalLink = false;
    } else isExternalLink = true;
  } catch {}

  return isExternalLink ? (
    <a href={link} target="_blank" {...rest}>
      {children}
    </a>
  ) : (
    <Link to={link} {...rest}>
      {children}
    </Link>
  );
}
