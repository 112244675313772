import { colors } from "../../library/theme";
import styled from "styled-components";

export const QuestionDescriptionContainer = styled.section`
  font-size: 15px;
  color: ${colors.interface.grey600};
  padding-bottom: 32px;

  & > p {
    font-size: 15px;
    color: ${colors.interface.grey600};

    * {
      color: ${colors.interface.grey600};
    }
  }
`;
