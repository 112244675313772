import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchNotifications } from "../../processes/notificationProcesses";
import { fetchNotificationsUser } from "../../processes/notificationsUserProcesses";

export function useLoadNotifications() {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    const fetchData = async () => {
      await Promise.all([
        fetchNotifications(dispatch),
        fetchNotificationsUser(dispatch),
      ]);
      setLoading(false);
    };

    fetchData();
  }, [dispatch]);

  return { loading };
}
