import styled from "styled-components";
import { colors } from "../../components/library/theme";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 532px;
  gap: 40px;
  padding-top: 48px;
  margin: 0 auto;

  img {
    width: 227px;
    margin: 0 auto;
  }

  a {
    width: fit-content;
    font-size: 16px;
    margin: 0 auto;

    &:hover {
      text-decoration: underline;
    }
  }

  @media screen and (max-width: 991px) {
    gap: 24px;
    padding: 24px;

    a {
      font-size: 14px;
      margin-top: 8px;
    }
  }
`;

export const Description = styled.div`
  svg {
    display: block;
    margin: 0 auto;
  }

  @media screen and (max-width: 991px) {
    svg {
      width: 229px;
      height: auto;
    }
  }
`;

export const Title = styled.h1`
  font-size: 18px;
  font-weight: 600;
  color: ${colors.interface.grey400};
  margin: 24px 0 16px;

  @media (max-width: 991px) {
    font-size: 16px;
  }
`;

export const Paragraph = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: ${colors.interface.grey400};

  @media (max-width: 991px) {
    font-size: 14px;
    line-height: 21px;
  }
`;
