import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { fetchMockTemplatesExercises } from "../../processes/mockTemplateProccesses";
import {
  getAllClassrooms,
  getCurrentClassroomId,
} from "../../selectors/classroomSelectors";
import { makeMockTemplatesExercisesList } from "../../selectors/mockTemplateSelectors";
import MockTemplatesExercises from "./MockTemplatesExercises";
import { getMockFromMockTemplate } from "../../selectors/mockSelectors";

const defaultFilters = {
  onlyDistinct: true,
  highlightFalse: true,
  s: ["id desc"],
};

function MockTemplatesExercisesContainer(props) {
  const { dispatch, currentClassroomId } = props;
  const filters = { fromClassrooms: currentClassroomId, ...defaultFilters };

  useEffect(() => {
    fetchMockTemplatesExercises(filters, dispatch);
  }, [dispatch, JSON.stringify(filters)]);

  const fetchHandler = useCallback(
    (_, { activePage = 1 }) => {
      fetchMockTemplatesExercises(filters, dispatch, { page: activePage });
    },
    [dispatch, JSON.stringify(filters)]
  );

  const changeCurrentClassroomHandler = useCallback(
    (_, { value }) => {
      dispatch({ type: "CURRENT_CLASSROOM_CHANGED", id: value });
    },
    [dispatch]
  );

  return (
    <MockTemplatesExercises
      changeCurrentClassroomHandler={changeCurrentClassroomHandler}
      fetchHandler={fetchHandler}
      {...props}
    />
  );
}

function mapStateToProps(state) {
  const allMocks = makeMockTemplatesExercisesList(state);
  const mocks = [];

  allMocks.data.forEach((mockTemplate) => {
    const mock = getMockFromMockTemplate(state, mockTemplate.id);
    mocks.push(mock) 
   });

  return {
    list: makeMockTemplatesExercisesList(state),
    mocks,
    currentClassroomId: getCurrentClassroomId(state),
    classrooms: getAllClassrooms(state),
  };
}

export default connect(mapStateToProps)(MockTemplatesExercisesContainer);
