import React from "react";
import { Route, Switch } from "react-router-dom";
import AnnotationsBookContainer from "../components/AnnotationsBook/AnnotationsBookContainer";
import Classroom from "../components/classrooms/ClassroomContainer";
import HelpPage from "../components/HelpPage";
import LMS from "../components/LMS";
import NotFound from "../components/NotFound";
import ClassroomsContainer from "../components/classrooms/ClassroomsContainer";
import LessonContainer from "../components/lessons/LessonContainer";
import MockContainer from "../components/mocks/MockContainer";
import MockModalContainer from "../components/mocks/MockModalContainer";
import MockPage from "../components/mocks/MockPage";
import ProfileEditFormContainer from "../components/profileEdit/ProfileEditFormContainer";
import StatisticsContainer from "../components/statistics/StatisticsContainer";
import Flashcards from "../componentsV2/Flashcards";
import Register from "../componentsV2/Frial/register";
import MyPerformanceContainer from "../components/myPerformance/MyPerformanceContainer";
import Home from "../componentsV2/Home";
import MedTrack from "../componentsV2/MedTrack";
import { canAccessMock } from "../selectors/mockSelectors";
import { restrictAccess } from "./helpers";
import FavoriteQuestionsContainer from "../components/FavoriteQuestions/FavoriteQuestionsContainer";
import ValidateBrowserAuth from "../componentsV2/SignInSecurity/screens/ValidateBrowser/ValidateBrowserAuth";
import { SafetyOnBoarding } from "../componentsV2/SafetyOnBoarding";
import { AccessDenied } from "../componentsV2/AccessDenied";
import NotificationsPage from "../componentsV2/Notifications/";

require("./studyTimeScript");

const SignInRedirect = (props) => {
  props.history.replace("/");
  return null;
};

export default function LmsRoutes(props) {
  const { medtrackEnabled } = props;

  return (
    <LMS {...props}>
      <Switch>
        <Route path="/classrooms" component={ClassroomsContainer} exact />
        <Route path="/flashcards" component={Flashcards} exact />
        <Route path="/signin" component={SignInRedirect} exact />
        <Route path="/classrooms/:id/" component={Classroom} exact />
        <Route
          path="/my-performance"
          component={MyPerformanceContainer}
          exact
        />
        <Route path="/" component={Home} exact />
        {medtrackEnabled && (
          <Route path="/medtrack" component={MedTrack} exact />
        )}
        <Route path="/validate-browser" component={ValidateBrowserAuth} exact />
        <Route
          path="/classrooms/:id/lessons/:id"
          component={LessonContainer}
          exact
        />

        <Route
          path="/profile/edit"
          component={ProfileEditFormContainer}
          exact
        />
        <Route
          path="/mocks"
          component={restrictAccess(MockPage, canAccessMock)}
          exact
        />
        <Route
          path="/mocks/:mockId"
          component={restrictAccess(MockContainer, canAccessMock)}
          exact
        />
        <Route path="/support" component={ClassroomsContainer} exact />
        <Route path="/notifications" component={NotificationsPage} exact />
        <Route path="/statistics" component={StatisticsContainer} exact />
        <Route
          path="/annotations-book"
          component={AnnotationsBookContainer}
          exact
        />
        <Route
          path="/favorite-questions"
          component={FavoriteQuestionsContainer}
          exact
        />
        <Route path="/help" component={HelpPage} exact />
        <Route path="/register-v2" component={Register} exact />
        <Route path="/register-v2/final" component={Register} exact />

        <Route
          path="/segurancadaplataforma"
          component={SafetyOnBoarding}
          exact
        />

        <Route path="/access-denied" component={AccessDenied} exact />

        <Route path="*" component={NotFound} />
      </Switch>

      <Switch>
        <Route
          path="/mocks/new"
          component={restrictAccess(MockModalContainer, canAccessMock)}
          exact
        />
      </Switch>
    </LMS>
  );
}
