import React, { useRef } from "react";
import { Modal, Overlay } from "./styles";
import { Step1 } from "./step1";
import { StepsIndicator } from "./stepsIndicator";
import { getCurrentUser } from "../../selectors/userSelectors";
import { connect } from "react-redux";
import { Provider, context } from "./context";
import { useContext } from "react";
import { Step2 } from "./step2";
import { Step3 } from "./step3";

function SuperSimuladoOnBoardModal() {
  const { step } = useContext(context);
  const steps = useRef([<Step1 />, <Step2 />, <Step3 />]);

  return (
    <Overlay>
      <Modal>
        <StepsIndicator />
        {steps.current[step]}
      </Modal>
    </Overlay>
  );
}

function Main(props) {
  return (
    <Provider user={props.user} {...props}>
      <SuperSimuladoOnBoardModal />
    </Provider>
  );
}

function mapStateToProps(state, ownProps) {
  return { ...ownProps, user: getCurrentUser(state) };
}
export default connect(mapStateToProps)(Main);
