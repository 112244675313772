import * as React from 'react';
import { colors } from '../../../components/library/theme';

export function InvalidFingerprintIllustration(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={308}
      height={275}
      fill="none"
      {...props}
    >
      <path
        fill={colors.brand.pure}
        d="M293.607 130.515c-3.213-15.716-8.855-31.058-15.563-45.162-11.565-24.331-30.513-44.536-54.753-56.53-59.48-29.434-102.404-17.17-125.705 6.745-23.302 23.914-16.557 64.999-41.698 82.782-25.141 17.782-42.924 25.754-44.15 58.254-1.227 32.499 14.716 57.027 40.47 64.999 25.755 7.971 64.387 33.726 141.65 19.009 77.263-14.717 96.272-55.801 101.178-85.235 2.453-14.809 1.606-30.004-1.429-44.862Z"
      />
      <path
        fill="#fff"
        d="M293.607 130.515c-3.213-15.716-8.855-31.058-15.563-45.162-11.565-24.331-30.513-44.536-54.753-56.53-59.48-29.434-102.404-17.17-125.705 6.745-23.302 23.914-16.557 64.999-41.698 82.782-25.141 17.782-42.924 25.754-44.15 58.254-1.227 32.499 14.716 57.027 40.47 64.999 25.755 7.971 64.387 33.726 141.65 19.009 77.263-14.717 96.272-55.801 101.178-85.235 2.453-14.809 1.606-30.004-1.429-44.862Z"
        opacity={0.9}
      />
      <path
        fill="#C9C9C9"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M112.498 40.791h-5.555v24.62h5.555v-24.62Z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M112.498 44.496h-5.555V46.9h5.555v-2.404ZM112.498 59.772h-5.555v2.403h5.555v-2.403Z"
      />
      <path
        fill="#878787"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M118.98 40.791h-7.775v24.62h7.775v-24.62Z"
      />
      <path
        fill="#C9C9C9"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M118.428 40.791h-5.555v24.62h5.555v-24.62Z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M118.428 44.496h-5.555V46.9h5.555v-2.404ZM118.428 59.772h-5.555v2.403h5.555v-2.403Z"
      />
      <path
        fill="#878787"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M124.904 40.791h-7.776v24.62h7.776v-24.62Z"
      />
      <path
        fill="#C9C9C9"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M124.351 40.791h-5.555v24.62h5.555v-24.62Z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M124.351 44.496h-5.555V46.9h5.555v-2.404ZM124.351 59.772h-5.555v2.403h5.555v-2.403Z"
      />
      <path
        fill="#C9C9C9"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M130.827 40.791h-7.776v24.62h7.776v-24.62Z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M135.892 65.663h13.049l3.47-3.14h-16.519v3.14Z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="m150.934 65.822-.417-.717-14.895-.03.429.747h14.883Z"
      />
      <path
        fill="#C9C9C9"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="m128.337 41.307-4.809 2.78 12.325 21.314 4.809-2.781-12.325-21.313Z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="m130.193 44.502-4.809 2.781 1.203 2.081 4.809-2.781-1.203-2.08ZM137.844 57.725l-4.809 2.78 1.203 2.082 4.81-2.781-1.204-2.081Z"
      />
      <path
        fill="#C9C9C9"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="m152.326 62.738-11.792-.03-12.325-21.315 11.792.03 12.325 21.315Z"
      />
      <path
        fill={colors.brand.pure}
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M158.752 53.197s-2.146-.766-2.76-4.139c-.613-3.372-1.686-8.124-.459-9.198 1.226-1.073 3.525-1.686 3.832.46.307 2.147-.153 6.286.307 7.205.46.92 1.993.307 1.993-1.533 0-3.029-1.227-6.132-.791-9.198.613-4.237 4.905-4.703 5.365 0 .258 2.6.331 5.256.331 7.868 0 1.073 1.84 1.686 1.686-.92-.11-1.84 1.227-3.986 3.066-3.373 1.84.614.307 8.738-.306 10.118-.613 1.38-4.446 1.637-4.446 1.637v3.526l-4.599.154-.153-3.526a6.943 6.943 0 0 1-3.066.92Z"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M162.891 46.477c.055.766.092 1.226.092 1.226M163.198 34.586s-1.006.699-.932 2.796c.049 1.276.294 4.808.484 7.39M164.927 41.818l.159 5.133M167.171 50.283a7.812 7.812 0 0 1-1.073.092M170.372 41.662a4.733 4.733 0 0 0-.546 2.177c0 1.226.08 4.666-.699 5.599-.19.209-.422.376-.68.49M157.066 48.563a4.959 4.959 0 0 0 1.275 2.452 2.258 2.258 0 0 0 2.723.074M156.919 40.266s-1.552 0-1.012 2.716c.233 1.147.448 2.557.724 3.888M162.75 50.603l.313 3.343"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="m170.709 54.27-.558 4.574-.38 3.115-.441 3.624h-11.032l-.441-3.624-.381-3.115-.558-4.575h13.791Z"
      />
      <path
        fill={colors.brand.pure}
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="m170.151 58.846-.38 3.115h-11.914l-.38-3.115h12.674Z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M176.768 65.586h-70.806v4.69h70.806v-4.69Z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M176.768 65.586h-16.324v4.69h16.324v-4.69Z"
      />
      <path
        fill="#919191"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M158.856 70.295c7.586 2.796 6.218 8.082 6.218 8.082h7.181v-8.082h-13.399Z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M161.278 70.295c7.579 2.796 6.212 8.082 6.212 8.082h7.187v-8.082h-13.399Z"
      />
      <path
        fill="#919191"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M110.26 70.295c7.586 2.796 6.218 8.082 6.218 8.082h7.181v-8.082H110.26Z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M112.682 70.295c7.579 2.796 6.212 8.082 6.212 8.082h7.187v-8.082h-13.399Z"
      />
      <path
        fill="#919191"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M275.438 41.668h-63.503v88.153h63.503V41.668Z"
      />
      <path
        fill={colors.brand.pure}
        d="M272.685 45.494h-57.99v80.501h57.99v-80.5Z"
      />
      <path
        fill="#fff"
        d="M272.685 45.494h-57.99v80.501h57.99v-80.5Z"
        opacity={0.5}
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M227.443 119.881a23.916 23.916 0 0 1-5.801 6.132h51.043V45.494h-9.406l-1.227.386c-9.198 2.9-18.702 11.296-24.092 26.552"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M237.064 75.106a87.23 87.23 0 0 0-3.519 22.381c-.338 9.1-2.275 15.643-4.863 20.346M267.013 65.928c-8.432 4.905-18.249 13.147-21.916 25.785-1.968 6.745-.122 12.828 3.526 18.034M250.597 112.283c5.059 5.924 12.264 10.584 18.096 13.717h3.992V62.951a56.125 56.125 0 0 0-3.618 1.84M224.334 66.484c4.44-9.05 4.906-16.231 4.397-20.99h-14.024v36.829c2.073-2.95 4.611-6.892 7.677-12.178.19-.325.368-.613.546-.957M229.828 101.345c-.68 4.458-2.728 7.695-5.144 7.695-2.974 0-5.39-4.905-5.39-10.976 0-6.07 2.416-10.976 5.39-10.976 2.974 0 5.39 4.906 5.39 10.976M232.385 75.534c0 2.336-1.03 4.237-2.311 4.237-1.282 0-2.306-1.9-2.306-4.237 0-2.336 1.03-4.237 2.306-4.237 1.275 0 2.311 1.895 2.311 4.237Z"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M261.807 93.433c2.226 1.061 3.679 2.913 3.679 5.016 0 3.293-3.618 5.967-8.088 5.967-4.47 0-8.082-2.674-8.082-5.967 0-3.293 3.618-5.972 8.082-5.972.712 0 1.423.069 2.122.208"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M260.807 98.45c0 1.38-1.514 2.501-3.391 2.501-1.876 0-3.391-1.122-3.391-2.502 0-1.38 1.521-2.508 3.391-2.508 1.871 0 3.391 1.123 3.391 2.508ZM266.277 83.236c0 1.913-1.895 3.465-4.237 3.465s-4.237-1.552-4.237-3.465c0-1.913 1.895-3.465 4.237-3.465s4.237 1.552 4.237 3.465ZM235.66 63.983c1.595 0 2.888-3.19 2.888-7.125s-1.293-7.126-2.888-7.126-2.888 3.19-2.888 7.126c0 3.935 1.293 7.125 2.888 7.125ZM243.558 118.09a4.046 4.046 0 1 1-8.092.002 4.046 4.046 0 0 1 8.092-.002ZM262.426 58.01a2.502 2.502 0 0 1-1.545 2.322 2.507 2.507 0 1 1 1.545-2.322ZM246.636 75.724a1.354 1.354 0 0 1-.828 1.25 1.35 1.35 0 0 1-1.768-1.759 1.35 1.35 0 0 1 1.763-.734 1.348 1.348 0 0 1 .833 1.242Z"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M272.685 45.494h-57.99v80.501h57.99v-80.5Z"
      />
      <path
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M177.909 160.283h-15.753v5.93h15.753v-5.93Z"
      />
      <path
        fill="#919191"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M199.046 161.178h-62.7l2.968-48.602h62.706l-2.974 48.602Z"
      />
      <path
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M197.249 161.178h-62.7l2.968-48.602h62.7l-2.968 48.602Z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M193.931 158.473h-55.733l2.637-43.2h55.733l-2.637 43.2Z"
      />
      <path
        fill={colors.brand.pure}
        d="M166.571 149.007c-.178 0-.355-.026-.525-.078l-8.911-2.743a1.816 1.816 0 0 1-.938-.667 1.866 1.866 0 0 1-.357-1.107l.584-16.243c0-.491.191-.961.531-1.308a1.804 1.804 0 0 1 1.284-.543h17.829c.482.001.944.196 1.285.543.34.347.532.817.532 1.308l-.583 16.243c0 .398-.125.785-.358 1.104-.233.319-.56.553-.934.667l-8.911 2.743c-.171.053-.349.08-.528.08v.001Zm-6.838-11.202v1.217c0 .437.171.856.474 1.165.304.309.715.483 1.144.483h2.264v4.316c.001.436.172.855.475 1.164a1.6 1.6 0 0 0 1.143.483h1.196v-5.962h.278v3.954h1.195c.429-.001.84-.175 1.143-.483.304-.309.474-.728.475-1.165v-2.306h4.238c.429-.001.84-.175 1.143-.483.303-.309.474-.728.474-1.165v-1.217h-5.851v-.287h3.883v-1.218a1.667 1.667 0 0 0-.475-1.163 1.604 1.604 0 0 0-1.143-.482h-2.265v-4.318a1.66 1.66 0 0 0-.477-1.164 1.604 1.604 0 0 0-1.145-.48h-1.195v5.962h-.276v-3.957h-1.196c-.429 0-.84.173-1.144.482a1.67 1.67 0 0 0-.475 1.165v2.31h-4.234a1.6 1.6 0 0 0-1.143.483 1.66 1.66 0 0 0-.474 1.164v1.217h5.851v.287l-3.883-.002Zm6.979 0h-.281v-.286h.276v.285l.005.001Z"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M97.052 72.744V36.51M97.052 254.085V76.35"
      />
      <path
        fill="#919191"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="m179.797 178.936 5.519 74.399a1.072 1.072 0 0 0 2.14-.092l-.828-74.307h-6.831Z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="m183.961 178.936 2.913 75.257a1.07 1.07 0 0 0 .564-.95l-.81-74.307h-2.667Z"
      />
      <path
        fill="#919191"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="m214.486 178.936 5.519 74.399a1.075 1.075 0 0 0 1.113.959 1.072 1.072 0 0 0 1.027-1.051l-.828-74.307h-6.831Z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="m218.325 178.936 3.397 75.147a1.056 1.056 0 0 0 .405-.84l-.81-74.307h-2.992Z"
      />
      <path
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="m123.046 179.743-5.519 73.584a1.064 1.064 0 0 1-1.067.993 1.071 1.071 0 0 1-1.092-1.067l.816-73.706 6.862.196ZM88.388 179.742l-5.52 73.584a1.066 1.066 0 0 1-1.825.672 1.063 1.063 0 0 1-.308-.764l.816-73.707 6.837.215Z"
      />
      <path
        fill="#919191"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M223.347 167.945H79.472v11.59h143.875v-11.59Z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M223.347 167.945h-41.232v11.59h41.232v-11.59Z"
      />
      <path
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M223.347 169.785H79.472v3.195h143.875v-3.195Z"
      />
      <path
        fill="#919191"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M225.419 166.283H77.399v3.876h148.02v-3.876Z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M225.413 166.283h-40.649v3.876h40.649v-3.876ZM181.772 91.367h-17.071a3.924 3.924 0 0 0-3.924 3.925v10.424a3.915 3.915 0 0 0 2.421 3.627c.477.197.987.298 1.503.298h7.554l-3.918 10.945 7.506-10.945h5.929a3.935 3.935 0 0 0 2.777-1.148 3.924 3.924 0 0 0 1.148-2.777V95.292a3.925 3.925 0 0 0-3.925-3.925Z"
      />
      <path
        fill="#263238"
        d="M173.471 96.275c.765 0 1.379.21 1.843.63.471.412.706.982.706 1.71 0 .722-.231 1.273-.695 1.656-.464.375-1.078.563-1.843.563l-.044.85h-1.358l-.055-1.877h.496c.641 0 1.134-.084 1.479-.253.346-.17.519-.479.519-.928 0-.323-.095-.58-.287-.772-.184-.192-.438-.287-.761-.287-.339 0-.604.092-.795.276-.184.184-.276.438-.276.761h-1.457c-.007-.448.088-.85.287-1.203.199-.353.489-.629.872-.828.39-.198.846-.298 1.369-.298Zm-.718 8.047a.989.989 0 0 1-.695-.254.885.885 0 0 1-.265-.651c0-.258.088-.471.265-.64a.962.962 0 0 1 .695-.265.91.91 0 0 1 .673.265.846.846 0 0 1 .265.64.885.885 0 0 1-.265.651.933.933 0 0 1-.673.254Z"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M276.64 254.479h16.556M15.417 254.479h252.638"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M112.695 146.116c-1.797-3.066-7.082-14.87-7.082-14.87a24.19 24.19 0 0 1 .772-3.728c.436-1.226 1.533-4.942 1.312-5.715-.22-.773-3.403-2.195-3.728-2.306-.325-.11.436-1.974-.772-2.416-1.208-.441-1.644.221-1.644.221l-.46 3.066-1.41 2.208a17.868 17.868 0 0 0-1.84-1.227c-.987-.552-3.293-.772-3.403.111-.11.883 1.84 1.643 2.52 2.195.68.552 1.54 3.182 2.312 3.955.702.766 1.361 1.571 1.975 2.41l.441 1.429s1.502 14.465 1.68 18.065c.178 3.599.718 13.122 1.797 14.557 1.079 1.435 1.263 2.342 4.292 1.263 3.03-1.079 3.594-2.52 4.293-5.758.699-3.238.742-10.406-1.055-13.46Z"
      />
      <path
        fill={colors.brand.pure}
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M113.774 159.594c.718-3.232.718-10.425-1.079-13.491a34.635 34.635 0 0 1-.705-1.288c-4.066-1.183-7.929.749-7.929.749l-.981.429c.129 1.502.227 2.759.264 3.526.184 3.593.717 13.122 1.796 14.557 1.08 1.435 1.264 2.342 4.293 1.263 3.029-1.079 3.618-2.508 4.341-5.745Z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M99.585 97.502c.443 1.627.69 3.3.736 4.985 0 2.398-.736 3.876.374 4.612 1.11.735 2.029.741 1.839 2.219a3.962 3.962 0 0 1-.926 2.214s-.368 7.567-.736 8.309c-.367.742-5.169-.184-6.279-.374-1.11-.19-4.243 1.294-6.279-2.766-2.036-4.059-.368-10.154 3.324-13.294a90.365 90.365 0 0 1 7.947-5.905Z"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M100.321 102.455s-1-.749-1.527.564M99.425 105.51a4.501 4.501 0 0 0 .135 2.232M101.265 114.182a1.231 1.231 0 0 1-1.37-.453c-.101-.139-.171-.299-.206-.467"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M103.356 148.888s-.613 2.809-1.159 4.293c-.546 1.483-10.332 4.206-14.594 4.77a72.552 72.552 0 0 1-8.462.558l.27 7.763s8.253.166 8.836 0c.582-.165 14.477-6.377 14.477-6.377l5.997-.785s2.521-.613 2.619-2.569c.098-1.956-2.128-7.518-3.14-8.383-1.012-.864-3.146-3.158-4.427-1.747-1.282 1.41-.417 2.477-.417 2.477Z"
      />
      <path
        fill={colors.brand.pure}
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M84.954 162.825c0-3.354.858-3.44 1.84-4.77a73.768 73.768 0 0 1-7.635.453l-.202 7.126s4.52.417 7.272.613c-.227.35-1.275-1.263-1.275-3.422Z"
      />
      <path
        fill={colors.brand.pure}
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M111.603 177.911a28.756 28.756 0 0 0-12.055-8.051l-10.768 4.593a24.62 24.62 0 0 0-3.299-5.74l-2.011-2.453 1.294-7.033s7.75-5.31 8.75-7.61l.037-.073c1.557 2.409 8.315 12.84 9.584 13.852 1.435 1.153 5.74 1.294 7.604-.43 1.864-1.723 2.729-2.869 2.299-5.451 0 0-2.869-6.175-4.451-8.903-1.583-2.729-5.023-12.485-6.746-15.502-1.226-2.159-1.735-3.679-2.606-4.697a17.007 17.007 0 0 0-2.128-2.907c-1.723-1.839-6.463-2.299-7.468-2.729-1.006-.429-1.147-.576-6.887-2.293-5.74-1.717-12.202-2.729-16.795.859 0 0-6.169 7.321-8.585 19.377-2.416 12.055-2.588 24.258-2.87 26.41-.282 2.152-2.728 12.632-3.446 18.66-.717 6.028 5.574 12.08 9.45 13.956 3.875 1.877 20.523 2.153 30.285 0 9.763-2.152 25.264-11.914 25.264-11.914s.571-6.034-4.452-11.921Z"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="m86.223 151.388-1.968-.068M85.481 148.254a59.031 59.031 0 0 1 8.033 3.385l-5.053-.172"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M112.149 151.879c.567.889.915 1.9 1.018 2.949 0 1.356.227 3.171-.337 2.606-.564-.564-.797-2.495-.797-2.495l.116-3.06Z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M108.752 150.297s2.122-1.152 3.066.233c.944 1.386.693 4.152 0 5.519-.693 1.368-1.484.062-1.386-1.153.098-1.214-1.68-4.599-1.68-4.599Z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M107.955 148.827s2.128-1.153 3.066.233c.938 1.386.687 4.145 0 5.518-.687 1.374-1.484.062-1.386-1.152.098-1.214-1.68-4.599-1.68-4.599Z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M106.98 147.237s2.128-1.153 3.066.233c.938 1.386.693 4.151 0 5.519-.693 1.367-1.484.061-1.386-1.153.098-1.214-1.68-4.599-1.68-4.599Z"
      />
      <path
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M101.596 91.743a12.083 12.083 0 0 1-1.643 6.28c-3.14 4.801-6.09 4.243-7.359 8.124-1.269 3.882-3.164 7.911-1.14 10.155 2.023 2.244 3.679.92 3.679.92s1.294 3.323-2.03 6.647c-3.323 3.323-9.044 2.03-10.522 1.478a9.823 9.823 0 0 1-2.582-1.478s-.926 2.588-5.519 2.588-9.81-3.14-9.81-8.867c0-5.728 3.133-9.597 3.875-11.817.742-2.219-1.294-9.044 1.84-13.288 3.133-4.243 7.97-7.358 11.65-7.358 3.68 0 10.86 2.017 10.89 2.03.485.263 3.588-1 4.428-.945 3.084.172 4.206 2.704 4.243 5.531Z"
      />
      <path
        stroke="#919191"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M89.614 118.006c.67.089 1.348.089 2.018 0M98.935 94.963a12.964 12.964 0 0 1-1.785 4.63c-2.87 4.507-6.132 6.132-10.038 10.424-2.508 2.778-2.035 5.549 0 7.003M98.99 90.174c.12.676.179 1.361.178 2.048"
      />
      <path
        stroke="#919191"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M95.133 94.473s-.614 6.346-3.275 10.032c-2.661 3.685-7.971 5.518-7.781 9.418"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M119.845 200.708s3.826 19.659 7.101 29.213c3.274 9.553 6.549 18.291 7.358 19.113.81.822 3.005 3.82 3.005 3.82a6.31 6.31 0 0 1-3.551 1.368c-1.913 0-4.366-2.453-6.555-3.827-2.189-1.373-2.452-2.183-6.825-3.82-4.372-1.637-.815-6.003.276-8.192a9.428 9.428 0 0 0 1.092-3.82 43.253 43.253 0 0 0-9.008-16.655c-6.813-7.64-8.18-25.362-8.18-25.362l13.104-4.905 2.183 13.067Z"
      />
      <path
        fill={colors.brand.pure}
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="m133.764 248.053-.791.159s-4.642-6.279-8.186-8.462a7.718 7.718 0 0 0-4.096-1.361c-1.098 2.183-4.642 6.549-.276 8.186 4.366 1.637 4.642 2.453 6.825 3.82 2.183 1.368 4.642 3.826 6.555 3.826a6.303 6.303 0 0 0 3.55-1.367s-2.189-3.005-3.004-3.82a4.586 4.586 0 0 1-.577-.981Z"
      />
      <path
        fill={colors.brand.pure}
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M131.563 253.444c-2.158-1.49-4.108-4.802-7.622-6.868-3.195-1.876-4.838-1.95-5.519-1.809a2.975 2.975 0 0 0 1.999 1.809c4.372 1.637 4.642 2.453 6.825 3.82 1.38.883 2.9 2.244 4.317 3.048ZM133.764 254.223a3.446 3.446 0 0 1 0 0Z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M95.513 171.085s13.123-4.648 14.349-1.582c1.226 3.066 6.843 14.503 4.795 19.2-2.048 4.697-16.446-13.491-18.494-13.883-2.048-.393-.889-2.361-.65-3.735Z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M108.372 177.077s14.919-1.226 17.783 1.435c2.863 2.661-6.948 34.541-12.264 44.555-8.334 15.661-5.814 19.763-5.814 19.763s.614 2.453 4.704 6.341c4.09 3.888 5.316 4.495 3.881 5.108-1.435.613-7.561 0-8.174-.411-.613-.411-5.108-4.084-9.198-5.31-4.09-1.227-3.477-2.048-1.637-5.728 1.84-3.679 1.637-.613 3.679-6.947a187.215 187.215 0 0 0 5.789-23.823c1.085-6.567 2.741-13.779 2.275-20.456l-1.024-14.527Z"
      />
      <path
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M119.299 187.058a19.818 19.818 0 0 0-7.696-9.149c-6.524-4.164-12.055-8.052-12.055-8.052L88.78 174.45s2.625 4.452-10.866 6.703c-10.387 1.729-19.922-.779-25.067-3.802-.718 3.562-1.466 7.524-1.84 10.424-.717 6.028 5.599 12.056 9.474 13.926 3.875 1.87 35.1 5.739 43.507.337 15.311-9.793 15.311-14.98 15.311-14.98Z"
      />
      <path
        fill={colors.brand.pure}
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M87.56 177.832c-1.441 1.183-4.25 2.452-9.646 3.329-10.387 1.729-19.922-.779-25.067-3.802-.184.914-.368 1.84-.552 2.79 3.526 2.122 10.933 5.715 19.285 4.691 9.965-1.22 14.392-3.942 15.98-7.008Z"
      />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M79.6 190.965a97.147 97.147 0 0 1-1.477 1.839M87.112 179.326a93.32 93.32 0 0 1-6.303 10.02M82.617 183.207s-3.679 4.906-7.56 7.769"
      />
      <path
        fill={colors.brand.pure}
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M112.781 249.156c-4.09-3.875-4.704-6.328-4.704-6.328a1.324 1.324 0 0 1-.318-.454c-.276.288-1.11.834-3.152.049-1.772-.681-3.453-1.84-5.225-2.618-.423.754-.834 1.226-1.729 3.023-1.84 3.679-2.453 4.501 1.637 5.727s8.585 4.906 9.198 5.31c.613.405 6.745 1.024 8.174.411 1.429-.613.209-1.238-3.881-5.12Z"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="m116.546 189.136 6.954-4.292"
      />
      <path
        fill="#919191"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M33.476 150.273s22.148-2.771 36.301 1.227l2.152 53.845H38.72s2.14-28.925-5.243-55.072Z"
      />
      <path
        fill="#757575"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M69.777 151.5c-14.153-3.998-36.301-1.227-36.301-1.227a149.223 149.223 0 0 1 4.703 26.626 132.783 132.783 0 0 1 33.572 23.914L69.777 151.5Z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M111.542 255.706a1.225 1.225 0 0 1-1.153-.797c-4.905-13.22-6.132-47.541-6.205-48.994a1.227 1.227 0 1 1 2.452-.086c0 .355 1.276 35.375 6.053 48.222a1.226 1.226 0 0 1-.724 1.576 1.13 1.13 0 0 1-.423.079ZM84.837 255.706a1.226 1.226 0 0 1-1.146-.797c-4.906-13.22-6.132-47.541-6.212-48.994a1.226 1.226 0 1 1 2.453-.086c0 .355 1.275 35.375 6.058 48.222a1.227 1.227 0 0 1-1.153 1.655Z"
      />
      <path
        fill="#919191"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M111.211 205.318H38.136v-2.453h70.634a2.454 2.454 0 0 1 2.453 2.453h-.012Z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M111.205 204.725H38.136v2.434h73.069v-2.434Z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M69.231 255.706a1.233 1.233 0 0 1-1.217-.931 1.227 1.227 0 0 1-.01-.547c6.936-32.855.614-101.944.528-102.637a1.232 1.232 0 0 1 2.453-.227c.067.699 6.439 70.15-.57 103.367a1.228 1.228 0 0 1-1.183.975ZM31.213 254.812a1.227 1.227 0 0 1-1.184-1.558c15.944-56.598 2.41-102.171 2.275-102.625a1.227 1.227 0 0 1 2.343-.717c.14.459 13.907 46.682-2.257 104.004a1.224 1.224 0 0 1-1.177.896Z"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.226}
        d="M255.411 153.7s-7.536-4.189-8.094 5.304c-.558 9.492-1.95 71.186-1.95 71.186"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.226}
        d="M243.405 159.838s6.132 13.68 3.354 69.518"
      />
      <path
        fill={colors.brand.pure}
        d="M249.445 155.613s-2.889 1.84-4.526-.269c-1.637-2.11 1.11-5.985-1.312-7.641-2.422-1.656-6.61.871-6.61.871s3.219.944 3.115 2.704c-.105 1.76-1.656 2.422-1.84 1.656-.184-.767-1.747-4.642-4.36-3.029a6.462 6.462 0 0 0-3.017 4.108s6.089-.65 6.457 1.637c.368 2.287-2.495-.398-5.009-.546-2.515-.147-4.808.221-4.642 1.742a20.072 20.072 0 0 1-.24 4.023 7.5 7.5 0 0 1 4.906-1.73c2.766.166 2.661 1.926.147 1.779-2.514-.148-4.672 2.244-4.672 2.244s2.293 3.918 5.206 5.856c2.912 1.938 3.961 1.226 4.292-.251.331-1.478-.441-5.323 1.318-5.219 1.76.104-2.912 6.635-.668 7.021 2.244.387 4.053-.263 4.642-1.741l.613-1.478s-.785-3.832-.441-5.323c.343-1.49.754.043 1.153 1.84.398 1.797.649 6.089 1.416 5.881.766-.209 3.225-3.336 3.802-4.569.576-1.232-3.238-4.979-3.195-5.733.043-.754 2.986.681 3.446 1.465.334.597.635 1.211.902 1.84 0 0 1.839-1.153 1.79-4.685-.049-3.532-2.673-2.453-2.673-2.453Z"
      />
      <path
        fill="#263238"
        d="M249.445 155.613s-2.889 1.84-4.526-.269c-1.637-2.11 1.11-5.985-1.312-7.641-2.422-1.656-6.61.871-6.61.871s3.219.944 3.115 2.704c-.105 1.76-1.656 2.422-1.84 1.656-.184-.767-1.747-4.642-4.36-3.029a6.462 6.462 0 0 0-3.017 4.108s6.089-.65 6.457 1.637c.368 2.287-2.495-.398-5.009-.546-2.515-.147-4.808.221-4.642 1.742a20.072 20.072 0 0 1-.24 4.023 7.5 7.5 0 0 1 4.906-1.73c2.766.166 2.661 1.926.147 1.779-2.514-.148-4.672 2.244-4.672 2.244s2.293 3.918 5.206 5.856c2.912 1.938 3.961 1.226 4.292-.251.331-1.478-.441-5.323 1.318-5.219 1.76.104-2.912 6.635-.668 7.021 2.244.387 4.053-.263 4.642-1.741l.613-1.478s-.785-3.832-.441-5.323c.343-1.49.754.043 1.153 1.84.398 1.797.649 6.089 1.416 5.881.766-.209 3.225-3.336 3.802-4.569.576-1.232-3.238-4.979-3.195-5.733.043-.754 2.986.681 3.446 1.465.334.597.635 1.211.902 1.84 0 0 1.839-1.153 1.79-4.685-.049-3.532-2.673-2.453-2.673-2.453Z"
        opacity={0.3}
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M234.826 163.898c1.882-2.324 4.648-5.47 6.972-6.972M232.649 166.723s.478-.669 1.263-1.674M243.024 157.502s2.797 3.949 3.312 8.014M239.848 159.837s.785-.46.49 4.568M241.902 155.14s1.601-1.417.755-4.244M239.744 157.563l-1.14-2.084M237.346 159.69s-3.618-2.735-5.88-2.87"
      />
      <path
        fill={colors.brand.pure}
        d="M259.722 148.162s-.117 4.146-3.354 4.348c-3.238.202-5.237-5.194-8.548-3.894-3.312 1.3-3.63 7.211-3.63 7.211s3.139-2.581 4.832-1.281c1.692 1.3 1.3 3.311.386 2.992-.914-.319-5.832-1.416-6.003 2.3a7.86 7.86 0 0 0 2.054 5.825s3.501-6.543 6.052-5.353c2.551 1.189-2.103 2.232-3.967 4.648-1.864 2.416-3.066 4.961-1.417 5.837a24.023 24.023 0 0 1 3.863 2.974s-.441-3.421 1.607-6.076 3.734-1.355 1.876 1.061-.938 6.211-.938 6.211 5.482.374 9.407-1.226c3.924-1.601 3.949-3.121 2.685-4.477-1.263-1.355-5.629-3.182-4.329-4.905 1.3-1.723 4.661 7.438 6.58 5.451 1.919-1.987 2.496-4.237 1.416-5.837l-1.079-1.595s-4.366-1.839-5.629-3.188c-1.263-1.349.558-.724 2.625.098 2.066.821 6.536 3.501 6.855 2.587.319-.913-1.147-5.518-1.981-6.916-.834-1.399-7.199-.154-7.922-.712-.724-.558 2.716-2.526 3.814-2.453.823.069 1.642.181 2.453.338 0 0 .098-2.625-3.471-4.986-3.569-2.36-4.237 1.018-4.237 1.018Z"
      />
      <path
        fill="#263238"
        d="M259.722 148.162s-.117 4.146-3.354 4.348c-3.238.202-5.237-5.194-8.548-3.894-3.312 1.3-3.63 7.211-3.63 7.211s3.139-2.581 4.832-1.281c1.692 1.3 1.3 3.311.386 2.992-.914-.319-5.832-1.416-6.003 2.3a7.86 7.86 0 0 0 2.054 5.825s3.501-6.543 6.052-5.353c2.551 1.189-2.103 2.232-3.967 4.648-1.864 2.416-3.066 4.961-1.417 5.837a24.023 24.023 0 0 1 3.863 2.974s-.441-3.421 1.607-6.076 3.734-1.355 1.876 1.061-.938 6.211-.938 6.211 5.482.374 9.407-1.226c3.924-1.601 3.949-3.121 2.685-4.477-1.263-1.355-5.629-3.182-4.329-4.905 1.3-1.723 4.661 7.438 6.58 5.451 1.919-1.987 2.496-4.237 1.416-5.837l-1.079-1.595s-4.366-1.839-5.629-3.188c-1.263-1.349.558-.724 2.625.098 2.066.821 6.536 3.501 6.855 2.587.319-.913-1.147-5.518-1.981-6.916-.834-1.399-7.199-.154-7.922-.712-.724-.558 2.716-2.526 3.814-2.453.823.069 1.642.181 2.453.338 0 0 .098-2.625-3.471-4.986-3.569-2.36-4.237 1.018-4.237 1.018Z"
        opacity={0.2}
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M258.054 168.441c-1.036-3.489-2.312-8.394-2.226-11.73M259.397 172.554s-.343-.944-.815-2.409M257.239 155.878s5.856-.111 10.277 2.146M257.423 160.659s.073-1.098 4.905 2.624M254.136 155.404s-.332-2.563-3.735-3.642M255.062 159.2l-2.864-.282M255.558 163.051s-5.206 1.76-6.88 3.937"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.226}
        d="M224.984 175.756s16.471 10.884 16.195 53.042"
      />
      <path
        fill={colors.brand.pure}
        d="M245.293 172.879s-.527 4.771-4.292 4.697c-3.765-.073-5.519-6.488-9.492-5.298-3.974 1.19-4.875 7.972-4.875 7.972s3.875-2.686 5.702-1.018c1.828 1.668 1.184 3.943.16 3.489-1.024-.454-6.598-2.189-7.144 2.085a9.086 9.086 0 0 0 .21 3.644 9.092 9.092 0 0 0 1.63 3.267s4.66-7.218 7.487-5.599c2.827 1.619-2.637 2.373-5.016 4.985-2.379 2.613-3.992 5.433-2.189 6.598a27.775 27.775 0 0 1 4.182 3.802s-.19-3.986 2.422-6.862c2.612-2.875 4.446-1.226 2.066 1.405-2.379 2.63-1.668 7.076-1.668 7.076s6.298.95 10.977-.521c4.678-1.472 4.844-3.226 3.513-4.906-1.33-1.68-6.193-4.206-4.531-6.034 1.662-1.827 4.672 9.027 7.076 6.911 2.404-2.115 3.275-4.648 2.183-6.598l-1.091-1.95s-4.863-2.52-6.194-4.206c-1.33-1.687.712-.779 3.017.361 2.306 1.141 7.218 4.654 7.665 3.637.448-1.018-.803-6.464-1.631-8.174-.828-1.711-8.29-.859-9.075-1.57-.785-.711 3.379-2.655 4.636-2.453.936.156 1.863.361 2.777.613 0 0 .356-3.017-3.538-6.083-3.894-3.066-4.967.73-4.967.73Z"
      />
      <path
        fill="#263238"
        d="M245.293 172.879s-.527 4.771-4.292 4.697c-3.765-.073-5.519-6.488-9.492-5.298-3.974 1.19-4.875 7.972-4.875 7.972s3.875-2.686 5.702-1.018c1.828 1.668 1.184 3.943.16 3.489-1.024-.454-6.598-2.189-7.144 2.085a9.086 9.086 0 0 0 .21 3.644 9.092 9.092 0 0 0 1.63 3.267s4.66-7.218 7.487-5.599c2.827 1.619-2.637 2.373-5.016 4.985-2.379 2.613-3.992 5.433-2.189 6.598a27.775 27.775 0 0 1 4.182 3.802s-.19-3.986 2.422-6.862c2.612-2.875 4.446-1.226 2.066 1.405-2.379 2.63-1.668 7.076-1.668 7.076s6.298.95 10.977-.521c4.678-1.472 4.844-3.226 3.513-4.906-1.33-1.68-6.193-4.206-4.531-6.034 1.662-1.827 4.672 9.027 7.076 6.911 2.404-2.115 3.275-4.648 2.183-6.598l-1.091-1.95s-4.863-2.52-6.194-4.206c-1.33-1.687.712-.779 3.017.361 2.306 1.141 7.218 4.654 7.665 3.637.448-1.018-.803-6.464-1.631-8.174-.828-1.711-8.29-.859-9.075-1.57-.785-.711 3.379-2.655 4.636-2.453.936.156 1.863.361 2.777.613 0 0 .356-3.017-3.538-6.083-3.894-3.066-4.967.73-4.967.73Z"
        opacity={0.3}
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M241.455 196.121c-.883-4.096-1.87-9.891-1.466-13.748M242.62 200.996s-.307-1.122-.718-2.857M241.688 181.547s6.745.429 11.651 3.446M241.461 187.027s.19-1.257 5.414 3.496M238.162 180.711s-.141-2.993-3.962-4.563M238.873 185.175l-3.274-.595M239.088 189.664s-6.175 1.545-8.315 3.894"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.226}
        d="M231.404 208.974s10.609 8.094 11.725 20.101l-2.232-40.208M257.085 203.11c-13.956-2.514-13.956 25.963-13.956 25.963"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.226}
        d="M244.803 228.798s-8.094-58.903 15.33-58.903"
      />
      <path
        fill={colors.brand.pure}
        d="M251.769 201.42c-1.907-2.147-5.96-2.147-8.34 3.335-2.379 5.482-.239 4.532-.239 4.532s4.526-1.435 7.389-2.385c2.864-.951 6.672-3.336 6.911-1.907.239 1.428-1.907 2.857-4.532 3.335-2.624.479-9.529 2.386-8.817 3.839.711 1.453 2.385 5.721 2.385 5.721a60.018 60.018 0 0 1 6.432-4.764c3.103-1.907 5.01-1.196 3.342.95-1.668 2.146-6.438 4.525-5.482 7.389.957 2.864 6.672 6.433 6.672 6.433s.717-5.955 2.385-7.862c1.668-1.907 1.668.239 1.19 3.575a38.658 38.658 0 0 0-.478 4.293l6.671 2.385a47.743 47.743 0 0 1 3.575-4.765l1.227-1.226s-5.243-4.525-5.482-7.358c-.24-2.833 3.575 0 4.764.478 1.19.478 3.097 3.814 3.097 3.814s1.196-5.482 1.435-7.389l.239-1.907s-5.01-1.907-6.917-1.907-2.858 1.429-2.858-.472 1.908-3.103 4.054-2.864c1.941.325 3.854.803 5.721 1.429 0 0 1.429-1.19.95-4.765-.478-3.575-1.668-3.575-4.525-2.624-2.858.95-4.771 3.103-6.917 3.103s-.95-2.625.478-3.336c1.429-.712 5.004-2.146 5.721-2.386.718-.239-3.814-3.574-6.199-4.053-2.385-.478-5.47 1.227-5.243 3.575.448 4.973-6.702 5.948-8.609 3.784Z"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M267.111 225.959c.074.715.123 1.433.147 2.152M262.322 208.852a63.096 63.096 0 0 1 4.507 14.765M261.463 206.938c-1.177-2.539-2.066-4.09-2.066-4.09a7.017 7.017 0 0 0 3.097-2.857 7.967 7.967 0 0 1 2.385-2.864M251.057 210.942s8.585-3.575 8.585-2.864M264.376 206.423s3.814-1.907 7.15-1.907M262.972 212.168a32.955 32.955 0 0 0-4.532 4.532 20.642 20.642 0 0 0-3.336 5.475M265.603 213.102s5.009-1.435 7.628.95"
      />
      <path
        fill={colors.brand.pure}
        d="M274.66 180.895c-2.594-1.533-5.464-1.57-6.874-3.189-1.411-1.618 1.355-2.452 2.833-1.839 1.477.613 4.905 2.373 5.549 2.753.644.38.202-5.218-1-7.358-1.201-2.14-4.494-3.342-6.132-1.613-3.464 3.636-8.872-1.134-8.505-3.98.368-2.845-2.287-5.905-7.971-4.114-5.685 1.79-3.575 2.79-3.575 2.79s4.047 2.483 6.647 4.016c2.6 1.533 6.892 2.852 5.966 3.968-.926 1.116-3.409.435-5.488-1.227-2.079-1.661-8.045-5.635-8.658-4.157-.613 1.478-2.76 5.549-2.76 5.549 2.642.405 5.254.985 7.819 1.736 3.47 1.091 4.182 2.998 1.471 3.145-2.71.148-7.64-1.888-9.198.706-1.557 2.593-.484 9.253-.484 9.253s4.967-3.361 7.499-3.354c2.533.006.914 1.416-1.919 3.243a38.082 38.082 0 0 0-3.55 2.453l2.575 6.604c1.97-.266 3.954-.41 5.942-.429l1.68.123s0-6.929 1.987-8.984c1.987-2.054 2.342 2.698 2.766 3.913.423 1.214-.847 4.838-.847 4.838s4.906-2.692 6.512-3.765l1.601-1.067s-1.84-5.035-3.097-6.47c-1.257-1.434-2.949-1.226-1.514-2.452 1.435-1.227 3.593-.614 4.819 1.189a36.937 36.937 0 0 1 2.668 5.255s1.839.301 4.225-2.403c2.385-2.705 1.606-3.6-.987-5.133Zm-22.548 7.628c-1.907-.956 2.619-2.863 2.619-2.863 1.674-.233-.718 3.82-2.619 2.863Zm3.207-8.897c-.809-.405 1.625-1.227 1.625-1.227 3.52.657-.815 1.632-1.631 1.227h.006Z"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M252.051 193.379a36.504 36.504 0 0 1-1.527 1.521M261.831 178.547a62.928 62.928 0 0 1-8.21 13.067M262.714 176.646c1.147-2.557 1.729-4.244 1.729-4.244a7.072 7.072 0 0 0 4.195.466 7.926 7.926 0 0 1 3.722-.079M260.642 176.019c.539-.472-7.782-4.605-7.782-4.605M265.026 178.521s3.943 1.632 6.132 4.152M259.771 181.196c-2.11-.358-4.25-.51-6.39-.454a20.733 20.733 0 0 0-6.328 1.073M260.771 183.803s4.359 2.839 4.292 6.383"
      />
      <path
        fill={colors.brand.pure}
        d="M237.622 213.916c-2.085-1.226-4.396-1.257-5.519-2.563-1.122-1.306 1.086-1.962 2.275-1.471 1.19.49 3.949 1.913 4.471 2.219.521.307.159-4.206-.81-5.905-.969-1.698-3.618-2.686-4.936-1.3-2.79 2.931-7.144-.913-6.843-3.201.3-2.287-1.84-4.752-6.433-3.311-4.593 1.441-2.876 2.244-2.876 2.244s3.256 1.999 5.347 3.232c2.091 1.232 5.55 2.299 4.802 3.195-.748.895-2.741.355-4.415-.988-1.674-1.342-6.476-4.537-6.966-3.348-.491 1.19-2.22 4.464-2.22 4.464 2.125.328 4.227.795 6.291 1.399 2.79.876 3.361 2.409 1.178 2.526-2.183.116-6.132-1.521-7.359.57-1.226 2.091-.392 7.451-.392 7.451s3.998-2.711 6.034-2.705c2.036.007.736 1.141-1.539 2.613a30.743 30.743 0 0 0-2.858 1.974l2.067 5.31a38.248 38.248 0 0 1 4.783-.337l1.355.092s0-5.574 1.6-7.229c1.601-1.656 1.883 2.17 2.22 3.151.337.981-.681 3.894-.681 3.894s3.962-2.171 5.243-3.029l1.288-.865s-1.484-4.047-2.49-5.206c-1.005-1.159-2.379-.981-1.226-1.986 1.153-1.006 2.888-.479 3.875.95a30.042 30.042 0 0 1 2.153 4.231s1.477.239 3.397-1.931c1.919-2.171 1.275-2.901-.816-4.14Zm-18.15 6.132c-1.533-.766 2.109-2.299 2.109-2.299 1.343-.184-.576 3.078-2.109 2.306v-.007Zm2.593-7.15c-.656-.331 1.306-.981 1.306-.981 2.827.546-.662 1.306-1.306.981Z"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M219.435 223.967c-.399.417-.81.828-1.227 1.226M227.302 212.027a50.567 50.567 0 0 1-6.604 10.517M228.013 210.493c.92-2.054 1.392-3.409 1.392-3.409a5.708 5.708 0 0 0 3.373.374 6.334 6.334 0 0 1 2.992-.061M220.078 206.287s6.703 3.33 6.267 3.679M229.871 212.01s3.171 1.312 4.906 3.342M225.641 214.161a26.415 26.415 0 0 0-5.139-.362c-1.732.016-3.45.308-5.09.864M226.45 216.258s3.507 2.287 3.44 5.132"
      />
      <path
        fill={colors.brand.pure}
        d="M229.111 174.561s-2.624 1.907-4.292 0c-1.668-1.907.717-5.721-1.668-7.15-2.386-1.429-6.194 1.19-6.194 1.19s3.097.717 3.097 2.385c0 1.668-1.429 2.385-1.668 1.668-.239-.718-1.907-4.293-4.292-2.619a6.136 6.136 0 0 0-2.619 4.048s5.715-.951 6.194 1.195c.478 2.146-2.379-.239-4.765-.239-2.385 0-4.531.478-4.292 1.907.13 1.268.13 2.546 0 3.814a7.088 7.088 0 0 1 4.531-1.907c2.619 0 2.619 1.668.239 1.668-2.379 0-4.292 2.385-4.292 2.385s2.385 3.575 5.243 5.243c2.857 1.668 3.814.951 4.053-.478.239-1.429-.717-5.004.951-5.004s-2.38 6.433-.24 6.672c2.141.239 3.815-.478 4.293-1.907l.478-1.429s-.956-3.575-.717-5.004c.239-1.428.717 0 1.189 1.668.472 1.668.957 5.715 1.674 5.482.718-.233 2.858-3.342 3.336-4.531.478-1.19-3.336-4.526-3.336-5.243 0-.718 2.858.478 3.336 1.19.478.711.951 1.667.951 1.667s1.668-1.189 1.428-4.525c-.239-3.336-2.618-2.146-2.618-2.146Z"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.613}
        d="M215.756 183.207c1.649-2.3 4.096-5.427 6.205-6.978M213.855 186.003s.423-.662 1.104-1.655M223.151 176.707s2.864 3.575 3.575 7.389M220.293 179.093s.712-.478.712 4.293M221.961 174.561s1.429-1.429.478-4.053M220.054 176.944l-1.19-1.907M217.908 179.092s-3.575-2.385-5.721-2.385"
      />
      <path
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.226}
        d="M253.179 227.957h-18.427v26.521h18.427v-26.521Z"
      />
    </svg>
  );
}
