import { normalize, schema } from "normalizr";
import { SubmissionError } from "redux-form";
import { trackSignUpEvent } from "../../services/mixpanel/events/trackSignUpEvent";
import { apiPost } from "./helpers/api";
import { setSession } from "./sessionProcesses";

export const user = new schema.Entity("users");

export const signUp = (values, dispatch) => {
  return apiPost("/api/v1/auth")
    .send({ ...values, origin: "web" })
    .then((response) => {
      setSession(response);
      dispatch({
        type: "USER_FETCHED",
        ...normalize(response.body.data, user),
      });
      const userId = response.body.data.id;
      dispatch({ type: "CURRENT_USER_FETCHED", id: userId });
      trackSignUpEvent({
        email: response.body.data.email,
        name: response.body.data.name,
        signUpDate: new Date(Date.now()),
        provider: "EMAIL",
      });
      dispatch(replace("/"));
    })
    .catch((err) => {
      throw new SubmissionError({
        _error: err.response.body.errors.full_messages,
      });
    });
};
