import { normalize, schema } from "normalizr";
import { apiPost, apiGet } from "./helpers/api";
import { notifySubmitSucceeded } from "./notifierProcesses";

const commentSchema = new schema.Entity("comments");

export const fetchComments = (dispatch, classroomId, lessonId) => {
  apiGet(`/api/v1/classrooms/${classroomId}/lessons/${lessonId}/comments`).then(
    (response) => {
      dispatch({
        type: "COMMENTS_FETCHED",
        ...normalize(response.body.data, new schema.Array(commentSchema)),
      });
    }
  );
};

export const createComment = (values, dispatch, props) => {
  return apiPost(
    `/api/v1/classrooms/${props.currentClassroomId}/lessons/${props.lessonId}/comments`
  )
    .send(values)
    .then((response) => {
      dispatch({
        type: "COMMENT_CREATED",
        ...normalize({ ...response.body.data, pristine: true }, commentSchema),
      });
      props.reset();
      notifySubmitSucceeded(
        dispatch,
        "Seu comentário será exibido depois que for aprovado."
      );
    });
};
