import React, { useEffect } from "react";
import { connect } from "react-redux";
import Comments from "./Comments";
import { fetchComments } from "../../processes/commentProcesses";
import { getCommentsByLesson } from "../../selectors/commentSelectors";

function CommentsContainer(props) {
  const { dispatch, currentClassroomId, lessonId } = props;
  useEffect(() => fetchComments(dispatch, currentClassroomId, lessonId), [
    dispatch,
    currentClassroomId,
    lessonId,
  ]);
  return <Comments {...props} />;
}

function mapStateToProps(state, ownProps) {
  const lessonId = ownProps.lessonId;
  return {
    comments: getCommentsByLesson(state, lessonId),
    currentClassroomId: state.currentClassroomId,
    lessonId,
  };
}

export default connect(mapStateToProps)(CommentsContainer);
