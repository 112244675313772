import * as React from "react";
import { colors } from "../../../components/library/theme";

export function HasEmergency(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={236}
      height={192}
      fill="none"
      {...props}
    >
      <path
        fill="#fff"
        stroke="#606A71"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.486}
        d="M219.567 19.951H16.433c-8.33 0-15.084 6.754-15.084 15.084v125.881c0 8.331 6.753 15.085 15.084 15.085h203.133c8.331 0 15.085-6.754 15.085-15.085V35.036c0-8.331-6.754-15.085-15.084-15.085Z"
      />
      <path
        fill={colors.brand.pure}
        stroke="#606A71"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.486}
        d="M229.623 16H6.377a5.028 5.028 0 0 0-5.028 5.028v5.746a5.028 5.028 0 0 0 5.028 5.028h223.246a5.028 5.028 0 0 0 5.028-5.028v-5.746A5.028 5.028 0 0 0 229.623 16Z"
      />
      <path
        fill={colors.brand.pure}
        d="M157.361 104.565c3.473-21.54-11.189-41.813-32.749-45.283-21.559-3.47-41.852 11.18-45.324 32.72-3.473 21.539 11.19 41.812 32.749 45.282 21.559 3.469 41.852-11.18 45.324-32.719Z"
      />
      <path
        fill="#363E46"
        d="M135.969 89.336a17.76 17.76 0 0 1-2.999 9.877 17.791 17.791 0 0 1-7.985 6.548 17.8 17.8 0 0 1-19.392-3.854 17.764 17.764 0 0 1-3.858-19.374 17.784 17.784 0 0 1 6.554-7.978 17.806 17.806 0 0 1 22.469 2.21 17.769 17.769 0 0 1 5.211 12.571ZM90.495 126.164c3.592 3.973 7.893 7.131 12.649 9.289a36.345 36.345 0 0 0 15.031 3.268 36.345 36.345 0 0 0 15.031-3.268c4.756-2.158 9.057-5.316 12.649-9.289v-1.188c.002-2.085-.38-4.15-1.123-6.077a16.01 16.01 0 0 0-3.212-5.154 14.82 14.82 0 0 0-4.811-3.444 13.985 13.985 0 0 0-5.676-1.209h-25.699c-3.936 0-7.71 1.673-10.493 4.652-2.782 2.979-4.346 7.02-4.346 11.232v1.188Z"
      />
      <rect
        width={27.68}
        height={27.654}
        x={132.314}
        y={112.26}
        fill="#fff"
        rx={13.827}
      />
      <path
        fill="#FF5B5B"
        d="M146.155 114.852c-6.201 0-11.245 5.039-11.245 11.234s5.044 11.235 11.245 11.235c6.2 0 11.244-5.04 11.244-11.235 0-6.195-5.044-11.234-11.244-11.234Zm4.071 14.08a.85.85 0 0 1 .269.616.87.87 0 0 1-.539.811.863.863 0 0 1-.953-.205l-2.848-2.846-2.849 2.846a.866.866 0 0 1-1.223-1.222l2.848-2.846-2.848-2.846a.864.864 0 0 1 1.223-1.222l2.849 2.846 2.848-2.846a.866.866 0 0 1 1.223 1.222l-2.848 2.846 2.848 2.846Z"
      />
    </svg>
  );
}
