import { apiGet } from "./helpers/api";
import { normalize, schema } from "normalizr";
import toQueryString from "../utils/toQueryString";

const mockTemplateSchema = new schema.Entity("mockTemplates", {
  meta: { mocks: [new schema.Entity("mocks")] },
});

export const fetchMockTemplate = (dispatch, id) => {
  return apiGet(`/api/v1/mock_templates/${id}`).then((response) => {
    dispatch({
      type: "MOCK_TEMPLATE_FETCHED",
      ...normalize(response.body.data, mockTemplateSchema),
    });
  });
};

export const fetchMockTemplates = (values, dispatch, props) => {
  dispatch({ type: "MOCK_TEMPLATES_FETCH_REQUESTED" });
  const filters = toQueryString(values);
  const page = props?.page !== undefined ? `page=${page || 1}` : "";
  return apiGet(`/api/v1/mock_templates?${filters}${page}`).then((response) => {
    dispatch({
      type: "MOCK_TEMPLATES_FETCHED",
      ...normalize(response.body.data, [mockTemplateSchema]),
    });
  });
};

export const fetchMockTemplatesHighlighted = (values, dispatch, props) => {
  dispatch({ type: "MOCK_TEMPLATES_HIGHLIGHTED_FETCH_LIST_REQUESTED" });
  const filters = toQueryString(values);
  return apiGet(
    `/api/v1/mock_templates?${filters}page=${props?.page || 1}`
  ).then((response) => {
    dispatch({
      type: "MOCK_TEMPLATES_HIGHLIGHTED_LIST_FETCHED",
      ...normalize(response.body.data, [mockTemplateSchema]),
      totalPages: response.body.totalPages,
      currentPage: response.body.currentPage,
      totalCount: response.body.totalCount,
    });
  });
};

export const fetchMockTemplatesExercises = (values, dispatch, props) => {
  dispatch({ type: "MOCK_TEMPLATES_EXERCISES_FETCH_LIST_REQUESTED" });
  const filters = toQueryString(values);
  return apiGet(
    `/api/v1/mock_templates?${filters}page=${props?.page || 1}`
  ).then((response) => {
    dispatch({
      type: "MOCK_TEMPLATES_EXERCISES_LIST_FETCHED",
      ...normalize(response.body.data, [mockTemplateSchema]),
      totalPages: response.body.totalPages,
      currentPage: response.body.currentPage,
      totalCount: response.body.totalCount,
    });
  });
};

export const fetchMockTemplatesExam = (values, dispatch) => {
  dispatch({ type: "MOCK_TEMPLATES_EXAM_FETCH_LIST_REQUESTED" });
  const filters = toQueryString(values);
  return apiGet(`/api/v1/mock_templates?${filters}`).then((response) => {
    dispatch({
      type: "MOCK_TEMPLATES_EXAM_LIST_FETCHED",
      ...normalize(response.body.data, [mockTemplateSchema]),
      totalPages: response.body.totalPages,
      currentPage: response.body.currentPage,
      totalCount: response.body.totalCount,
    });
  });
};
