import React from "react";
import { css } from "glamor";
import LobbyLayout from "../lobby/LobbyLayout";
import Link from "../library/Link";
import Button from "../library/Button";
import { setCookie } from "cookies-next";
import { CardForm, TextField } from "../library/forms";
import { spacing, typographyColors } from "../library/theme";
import { getThemeByUniversity } from "../../utils/getThemeByUniversity";

const linkClass = css({
  color: typographyColors.defaultBody,
  "&:hover": { color: "rgba(0,0,0, 0.5)" },
});
const buttonMargin = css({ marginTop: spacing.m });
const signUpClass = css({
  display: "flex",
  width: "100%",
  justifyContent: "center",
  marginTop: spacing.m,
  marginBottom: spacing.m,
  paddingTop: spacing.m,
});
const paragraphMargin = {
  marginRight: spacing.xxs,
};

const { IES } = getThemeByUniversity();




export default function SignInForm(props) {

  return (
      <LobbyLayout>
        
        <CardForm onSubmit={props.handleSubmit} error={props.error}>
          <TextField
            name="email"
            type="email"
            icon="user"
            iconPosition="left"
            label="Usuário"
            placeholder="E-mail ou nome de usuário..."
            required
            fluid
          />
          <TextField
            name="password"
            type="password"
            icon="lock"
            iconPosition="left"
            label="Senha"
            placeholder="*******"
            required
            fluid
          />
          <div className={buttonMargin}>
            <Button
              color="primary"
              disabled={props.pristine || props.submitting}
              size="large"
              fluid
              onClick={() => {
                setCookie(process.env.METRICO_THEME_COOKIE, IES, {
                  domain: process.env.DOMAIN_URL,
                });
              }}
            >
              Login
            </Button>
          </div>
        </CardForm>
        {
          !IES && (
            <>
              <div className={signUpClass}>
                <p style={paragraphMargin}>Ainda não tem uma conta?</p>
                <Link to="/signup">Criar minha conta</Link>
              </div>
            </>
          )
        }
        <Link to="/forgotten-password" className={linkClass}>
          Esqueci minha senha
        </Link>
      </LobbyLayout >
  )
}
