import React from "react";
import { colors } from "../../../../../components/library/theme";

import { Tag } from "./styles";
import {
  GynecAndObstetricsIcon,
  MedicalClinicIcon,
  PediatricsIcon,
  PreventiveMedicineIcon,
  SurgeryIcon,
} from "../../../icons";

export const tagVariants = {
  medicalClinic: {
    name: "Clínica Médica",
    bgColor: colors.medtrack.grey000,
    textColor: colors.medtrack.grey500,
    icon: () => <MedicalClinicIcon />,
    colors: {
      iconColor: colors.medtrack.blue200,
    },
  },
  surgery: {
    name: "Cirurgia",
    bgColor: colors.medtrack.grey000,
    textColor: colors.medtrack.grey500,
    icon: () => <SurgeryIcon />,
    colors: {
      iconColor: colors.medtrack.orange200,
    },
  },
  gynecAndObstetrics: {
    name: "Ginec. e Obstetrícia",
    bgColor: colors.medtrack.grey000,
    textColor: colors.medtrack.grey500,
    icon: () => <GynecAndObstetricsIcon />,
    colors: {
      iconColor: colors.medtrack.green300,
    },
  },
  pediatrics: {
    name: "Pediatria",
    bgColor: colors.medtrack.grey000,
    textColor: colors.medtrack.grey500,
    icon: () => <PediatricsIcon />,
    colors: {
      iconColor: colors.medtrack.blue700,
    },
  },
  preventiveMedicine: {
    name: "M. Preventiva",
    bgColor: colors.medtrack.grey000,
    textColor: colors.medtrack.grey500,
    icon: () => <PreventiveMedicineIcon />,
    colors: {
      iconColor: colors.medtrack.red700,
    },
  },
};

export function BigAreaTag({ variant, isDisabled = false }) {
  return (
    <Tag isDisabled={isDisabled} variant={variant}>
      {tagVariants[variant].icon()}
      <span>{tagVariants[variant]?.name || ""}</span>
    </Tag>
  );
}
