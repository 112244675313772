import * as React from "react";
import { Face } from "./Face";
import schema from "./schema.json";
import { Container } from "./styles";

export function SmileRating({ onChange, description }) {
  return (
    <Container>
      {schema.map((face) => (
        <Face
          key={face.rate}
          tooltipText={face.description}
          checked={description === face.description}
          rate={face.description}
          onChange={onChange}
          faceIcon={face.icon}
        />
      ))}
    </Container>
  );
}
