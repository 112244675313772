import styled from "styled-components";

export const Input = styled.input`
  border: ${(props) =>
    props.errorMessage ? "1px solid red" : "1px solid #c2ced6"};
  border-radius: 4px;
  height: 48px;
  width: 40px;
  text-align: center;
  color: black;
  caret-color: #c2ced6;
  font-size: 14px;
  ::placeholder {
    color: #c2ced6;
    text-align: center;
    font-size: 14px;
  }
  &:focus {
    outline: none;
  }
`;

export const InputsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  gap: 4px;
  p {
    color: #e64444;
    font-weight: 400;
    line-height: 18px;
    font-size: 12px;
  }
`;
export const InputWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
