export const notifySuccess = options => ({
  type: 'NOTIFIER_REQUESTED',
  payload: {
    type: 'success',
    title: options.title || 'Uhuuu... Deu certo!',
    message: options.message,
    icon: options.icon || 'check circle outline'
  }
})

export const notifyError = options => ({
  type: 'NOTIFIER_REQUESTED',
  payload: {
    type: 'error',
    title: options.title || 'Opss... ocorreu um problema!',
    message: options.message,
    icon: options.icon || 'check circle outline'
  }
})

export const notifyWarning = options => ({
  type: 'NOTIFIER_REQUESTED',
  payload: {
    type: 'warning',
    title: options.title,
    message: options.message,
    icon: options.icon || 'warning sign'
  }
})

export const notifySubmitSucceeded = (dispatch, message) => {
  dispatch(notifySuccess({ message }))
  setTimeout(() => {
    dispatch(dismissNotifier())
  }, 3000)
}

export const notifyNotSubmitSucceeded = (dispatch, message) => {
  dispatch(notifyError({ message }))
  setTimeout(() => {
    dispatch(dismissNotifier())
  }, 3000)
}

export const notifyWarningSucceeded = (dispatch, message) => {
  dispatch(notifyWarning({ message }))
  setTimeout(() => {
    dispatch(dismissNotifier())
  }, 3000)
}

export const dismissNotifier = () => ({
  type: 'NOTIFIER_DISMISS_REQUESTED'
})
