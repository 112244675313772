import CryptoJS from 'crypto-js';

// @ts-check
/**
 * @param {{
 * encrypted: string,
 * key: string
 * }}
 */
export function DecryptAES({ encrypted, key }) {
  return CryptoJS.AES.decrypt(encrypted, key).toString(CryptoJS.enc.Utf8);
}
