import React from "react";
import confirmDialog from "../../../componentsV2/confirmDialog";
import { apiPost } from "../../../processes/helpers/api";
import { Checkbox } from "semantic-ui-react";

async function handleDeleteAnnotationText(questionId, fetchFavoriteQuestions) {
  const response = await apiPost("/api/v1/questions/favorite").send({
    question_id: questionId
  });

  if (response.body.data.success) fetchFavoriteQuestions();
}

export function handleOpenConfirmDeleteModal(question, fetchFavoriteQuestions) {
  const key = "@hide-dialog-remove-favorite";
  const mustIgnoreDialog = localStorage.getItem(key);

  if (mustIgnoreDialog) {
    handleDeleteAnnotationText(question.id, fetchFavoriteQuestions);
    return;
  }

  confirmDialog.show({
    title: "Remover dos Favoritos",
    text: "Deseja remover essa questão da sua lista de favoritos?",
    confirmationButtonText: "Remover",
    onYes: () =>
      handleDeleteAnnotationText(question.id, fetchFavoriteQuestions),
    children: (
      <div style={{ margin: "24px 0" }}>
        <Checkbox
          onChange={() => {
            if (localStorage.getItem(key)) {
              localStorage.removeItem(key);
            } else {
              localStorage.setItem(key, true);
            }
          }}
          label=" Não mostrar novamente"
        />
      </div>
    )
  });
}
