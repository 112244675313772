import React from "react";
import { colors } from "../../../../components/library/theme";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { FeatureContainer } from "./styles";

export function Feature({ children }) {
  function limitSize() {
    const charCount = children.toString().length;
    if (charCount > 20) return children.toString().substring(0, 20) + "...";
    return children;
  }

  return (
    <FeatureContainer>
      <IoIosCheckmarkCircle size={20} fill={colors.brand.pure} />
      <span>{limitSize()}</span>
    </FeatureContainer>
  );
}
