import React, { useEffect } from "react";
import { connect } from "react-redux";
import { fetchOrganizers } from "../../processes/organizerProccess";
import { fetchSubjects } from "../../processes/subjectProccess";
import MockModal from "./MockModal";

export function MockModalContainer(props) {
  const { dispatch } = props;
  useEffect(() => {
    fetchOrganizers(dispatch);
    fetchSubjects(dispatch);
  }, [dispatch]);
  return <MockModal {...props} />;
}

export default connect()(MockModalContainer);
