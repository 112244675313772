/* eslint-disable react-hooks/exhaustive-deps */
import { Container, EmptyResultsText, ErrorText, InputContainer, TagList, SelectedTag, FilteredTag, ResidencesDescription, StyledSearchIcon } from './styles'
import React, { useEffect, useRef, useState } from 'react'
import { AddIcon } from './AddIcon'
import { CloseIcon } from './CloseIcon'

const AutocompleteTags = ({
  tags,
  placeholder,
  onSelectTag,
  selectedTags,
  onDelete,
  hasValidationError,
  errorMessage,
  className = 'autocomplete-container',
  showNumberingPosition,
  variant = 'edition',
  maxSelectedTags,
  isLoadingTags = false,
  htmlfor
}) => {
  const input = useRef(null)
  const [filteredTags, setFilteredTags] = useState([])
  const [searchText, setSearchText] = useState('')
  const [isTagListVisible, setIsTagListVisible] = useState(false)
  function handleChange(e) {
    const text = e.target.value.toLowerCase()
    setSearchText(text)

    const filter = tags.filter((tag) => {
      const formattedTagName = tag.name.split(' ').join('').toLowerCase().trim()
      return formattedTagName.includes(text)
    })

    setFilteredTags(filter)
  }

  function handleFocus() {
    setIsTagListVisible(true)
  }

  function handleSelectTag(e, tag) {
    onSelectTag && onSelectTag(tag)
    setIsTagListVisible(false)
    input.current.value = ''
    e.stopPropagation()
    setFilteredTags([])
    setSearchText('')
  }

  function handleRemoveTag(e, tagIndex) {
    onDelete(tagIndex)
    e.stopPropagation()
  }

  useEffect(() => {
    function checkIfClickedOutside(event) {
      const target = event.target

      if (!target.closest(`.${className}`)) {
        setIsTagListVisible(false)
      }
    }

    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [isTagListVisible, setIsTagListVisible])
  return (
    <>
      <Container className={className} hasValidationError={hasValidationError} isTagListVisible={isTagListVisible}>
        <InputContainer hasValidationError={hasValidationError} isTagListVisible={isTagListVisible} onClick={() => input.current && input.current.focus()} placeholderCount={placeholder.length}>
          {selectedTags
            .sort((a, b) => a.priority - b.priority)
            .map((tag, tagIndex) => (
              <SelectedTag
                variant={variant}
                key={tagIndex}
                onClick={e => {
                  handleRemoveTag(e, tagIndex)
                }}
                showNumberingPosition={Boolean(showNumberingPosition)}
              >
                <small>{tagIndex + 1}º</small>
                <span>{tag.name}</span>
                <CloseIcon />
              </SelectedTag>
            ))}
          {maxSelectedTags && selectedTags.length == maxSelectedTags ? null : (
            <input ref={input} onFocus={handleFocus} onChange={handleChange} placeholder={placeholder} autoComplete={'off'} id={htmlfor} disabled={isLoadingTags} />
          )}
        </InputContainer>

        {searchText.length > 0 && (
          <TagList isTagListVisible={isTagListVisible}>
            {tags && filteredTags?.length === 0 ? (
              <EmptyResultsText>Nenhum resultado encontrado</EmptyResultsText>
            ) : (
              filteredTags.map((tag, index) => (
                <FilteredTag onClick={e => handleSelectTag(e, tag)} key={index}>
                  <AddIcon /> <span>{tag.name}</span>
                </FilteredTag>
              ))
            )}
          </TagList>
        )}

        <StyledSearchIcon className="autoCompleteTags_icon" />
      </Container>
      {hasValidationError && <ErrorText>{errorMessage}</ErrorText>}

      {showNumberingPosition && (
        <ResidencesDescription>
          <small>Máximo de 5 bancas (Por ordem de relevância, sendo a 1ª a mais relevante)</small>

          {/* <InfoBox message="Suas bancas prioritárias ajudarão nossa plataforma a definir os melhores temas para seus estudos." /> */}
        </ResidencesDescription>
      )}
    </>
  )
}

export default AutocompleteTags
