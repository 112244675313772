const getLevels = (action) => action.entities.levels;

export default function levelReducer(state = {}, action) {
  switch (action.type) {
    case "LEVELS_FETCHED":
      return { ...state, ...getLevels(action) };
    default:
      return state;
  }
}
