import React, { useRef, useEffect, useState } from 'react';
import { Check } from './Check';
import { Container, TextWrapper, Logo, StyledSpinner } from './styles';
import { getThemeByUniversity } from '../../../../../utils/getThemeByUniversity';
import logo from '../../../../../../../assets/images/logo.svg';
import revalidaLogo from '../../../../../../../assets/images/logorevalidav2.svg';
import { generateFingerprint } from '../../../utils/generateFingerprint';
import { io } from 'socket.io-client/dist/socket.io';
import { getCookie } from 'cookies-next';
import { EMRButton } from '../../../../Button';

export default function ValidateBrowserAuth() {
  const isRevalida = getThemeByUniversity().IES === 'revalida';
  const [status, setStatus] = useState({
    loading: true,
    success: null,
  });
  const socket = useRef(io(process.env.SECURITY_SOCKET_URL));
  const peerConnection = useRef(null);
  const sendChannel = useRef(null);
  const candidates = useRef([]);
  const fingerprint = useRef(generateFingerprint());
  const timer = useRef(null);

  async function startConnection() {
    peerConnection.current = new RTCPeerConnection();

    sendChannel.current =
      peerConnection.current.createDataChannel('sendDataChannel');

    peerConnection.current.candidate = (event) => {
      if (event.candidate) {
        socket.current.emit('candidate', { message: event.candidate });
      }
    };

    sendChannel.current.onmessage = (event) => {
      const response = JSON.parse(event.data);
      setStatus({ loading: false, success: response.success });
      if (timer.current) clearTimeout(timer.current);
    };

    sendChannel.current.onopen = () => {
      sendChannel.current.send(
        JSON.stringify({ fingerprint: fingerprint.current })
      );
      socket.current.disconnect();
    };

    const offer = await peerConnection.current.createOffer();
    await peerConnection.current.setLocalDescription(offer);

    const email = getCookie('email');

    socket.current.emit('send_enable_browser_request', {
      roomId: email,
      message: offer,
      user: {
        fingerprint: fingerprint.current,
      },
    });
  }

  function mustDestroyIfTakesTooLong() {
    timer.current = setTimeout(() => {
      if (!status.success) {
        socket.current.disconnect();
        if (peerConnection.current) peerConnection.current.close();

        setStatus({
          loading: false,
          success: false,
        });
      }
    }, 10000);
  }

  function joinRoom() {
    const email = getCookie('email');

    socket.current.emit('join_room', {
      roomId: email,
      user: {
        fingerprint: fingerprint.current,
      },
    });
  }

  function handleRedirect() {
    window.location = '/';
  }

  useEffect(() => {
    socket.current.on('connected_room', startConnection);

    socket.current.on('send_enable_browser_request', function (data) {
      if (data.type === 'answer') {
        peerConnection.current.setRemoteDescription(data).then(() => {
          candidates.current.forEach((candidate) => {
            peerConnection.current.addIceCandidate(
              new RTCIceCandidate(candidate)
            );
          });
        });
      }
    });

    socket.current.on('candidate', function (data) {
      if (peerConnection.current.currentRemoteDescription) {
        peerConnection.current.addIceCandidate(new RTCIceCandidate(data));
      } else {
        candidates.current.push(data);
      }
    });

    setTimeout(() => {
      joinRoom();
      mustDestroyIfTakesTooLong();
    }, 1000);
  }, []);

  return (
    <Container>
      <Logo src={isRevalida ? revalidaLogo : logo} />
      {status.loading ? (
        <>
          <StyledSpinner />
          <TextWrapper>
            <strong>Verificando credenciais...</strong>
            <span>
              Esta ação pode levar alguns segundos. Em breve você será
              redirecionado.
            </span>
          </TextWrapper>
        </>
      ) : (
        <>
          <Check success={status.success} />
          <TextWrapper>
            <strong>
              {status.success
                ? 'Navegador validado com sucesso.'
                : 'Não foi possível validar o navegador'}
            </strong>

            <span>
              {status.success
                ? `Você já pode acessar a plataforma do navegador acessado
                atualmente. A partir de agora você pode acessar de ambos os
                navegadores sem necessidade de autenticação.`
                : `Certifique-se que você está acessando do mesmo dispositivo ou navegador padrão, que foi utilizado anteriormente. Em seguida repita o processo para efetivar a validação.`}
            </span>
          </TextWrapper>

          {!status.success && (
            <EMRButton fullSized mediumWeight onClick={handleRedirect}>
              Continuar neste navegador
            </EMRButton>
          )}
        </>
      )}
    </Container>
  );
}
