import { css } from "glamor";
import React, { useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import { MocksTable } from "../../componentsV2/MocksTable";
import { fetchMedtrackMocks } from "../../processes/mockProccess";
import Spinner from "../library/Spinner";
import { spacing, stateColors, uiColors } from "../library/theme";
import MockProgressContainer from "./MockProgressContainer";
import MockTemplateListItemContainer from "./MockTemplateListItemContainer";

const contentWrapper = {
  borderTop: `1px solid ${uiColors.border}`,
  textAlign: "center",
};
const emptyClass = css({
  textAlign: "center",
  color: stateColors.empty,
  padding: `${spacing.m} 0`,
});
const loadingWrapper = {
  margin: spacing.xxl,
};

export default function Mocks(props) {
  const [mocks, setMocks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleFetch = (action, page) => {
    setIsLoading(true);
    fetchMedtrackMocks({ activeMedtrackType: props.activeMedtrackType })
      .then((mockList) => setMocks(mockList))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    handleFetch();
  }, []);

  return (
    <Grid>
      <Grid.Column computer={16} style={contentWrapper}>
        <MockProgressContainer mockTemplateType="highlighted" />
        {isLoading ? (
          <div style={loadingWrapper}>
            <Spinner inline />
          </div>
        ) : (
          <>
            {mocks.length > 0 ? (
              <MocksTable variant={"mockTemplate"}>
                <thead>
                  <th>Criação</th>
                  <th>Nome</th>
                  <th>Status</th>
                  <th>Nota</th>
                  <th>Progresso</th>
                  <th></th>
                </thead>
                <tbody>
                  {mocks.map((mock, index) => {
                    const isTheLastExam = index + 1 === mocks.length;

                    return (
                      <MockTemplateListItemContainer
                        key={mock.id}
                        mockTemplate={mock}
                        isTheLastExam={isTheLastExam}
                      />
                    );
                  })}
                </tbody>
              </MocksTable>
            ) : (
              <p className={emptyClass}>Nenhum resultado encontrado</p>
            )}
          </>
        )}
      </Grid.Column>
    </Grid>
  );
}
