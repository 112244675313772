import { css } from "glamor";
import React, { useCallback } from "react";
import { Select } from "semantic-ui-react";
import { changeExamTypeSlug } from "../../processes/organizerStatatisticProcesses";
import Button from "../library/Button";
import { spacing, styles, uiColors } from "../library/theme";
import Spinner from "../library/Spinner";

const container = {
  padding: `${spacing.m} 0`,
  minHeight: `calc(100vh - 80px)`
};
const selectWrapper = {
  display: "flex",
  alignItems: "center",
  gap: spacing.m,
  maxWidth: 400,
  padding: `0 ${spacing.m}`,
};
const buttonsWrapper = {
  display: "flex",
  padding: `0 ${spacing.m}`,
  gap: spacing.s,
  marginTop: spacing.m,
  width: "100%",
  overflowX: "scroll"
};
const tableWrapper = {
  width: "100%",
  overflowX: "scroll",
};
const tableStyle = css(styles.roundedCorners, {
  marginTop: spacing.m,
  borderRadius: spacing.s,
  padding: `0 ${spacing.m}`,
  width: "100%",
  "> thead, tfoot": {
    "> tr > th:first-child": {
      borderTopLeftRadius: spacing.xs,
      borderBottomLeftRadius: spacing.xs,
    },
    "> tr > th:last-child": {
      borderTopRightRadius: spacing.xs,
      borderBottomRightRadius: spacing.xs,
    },
    "> tr > th": {
      fontSize: 14,
      padding: spacing.s,
    },
    background: uiColors.border,
  },
  "> tfoot": {
    ":before": {
      background: uiColors.contentCards,
      content: "\\200C",
      display: "block",
      lineHeight: "8px",
    },
  },
  "> tbody": {
    background: uiColors.fieldBackground,
    ":before": {
      background: uiColors.contentCards,
      content: "\\200C",
      display: "block",
      lineHeight: "8px",
    },
    "> tr, > tr > th ": {
      minWidth: 150,
      "> th:first-child": {
        minWidth: 200,
      },
    },
  },
});
const emptyClass = css({
  padding: spacing.xxl,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
});

const ChangeExamTypeButton = ({ dispatch, text, value }) => {
  const clickHandler = useCallback(() => {
    changeExamTypeSlug(dispatch, value);
  }, [dispatch, value]);

  return (
    <Button primary onClick={clickHandler} fluid>
      {text}
    </Button>
  );
};

export default function StatisticsPanel(props) {
  const {
    dispatch,
    isRPlus,
    changeOrganizerHandler,
    organizers,
    data,
    organizerStatistics,
    generalRClickHandler,
    totalQuestionsCount,
    totalRelevanceInPercentage,
    totalAlternativesCount,
  } = props;
  const { loading, organizerId } = organizerStatistics;
  const isEnoughData = totalQuestionsCount >= 100;

  return (
    <div style={container}>
      <div style={selectWrapper}>
        Banca:
        <Select
          fluid
          onChange={changeOrganizerHandler}
          placeholder="Selecione"
          options={organizers}
          search
        />
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <>
          {isRPlus && (
            <div style={buttonsWrapper}>
              <Button primary onClick={generalRClickHandler} fluid>
                Visão Geral
              </Button>
              <ChangeExamTypeButton
                dispatch={dispatch}
                text="CM"
                value="R+ CM"
              />
              <ChangeExamTypeButton
                dispatch={dispatch}
                text="CG"
                value="R+ CG"
              />
              <ChangeExamTypeButton
                dispatch={dispatch}
                text="GO"
                value="R+ GO"
              />
              <ChangeExamTypeButton
                dispatch={dispatch}
                text="PED"
                value="R+ PED"
              />
            </div>
          )}
          {loading && <Spinner />}
          <>
            {!loading && !!data?.length ? (
              <div style={tableWrapper}>
                <table className={tableStyle}>
                  <thead>
                    <tr>
                      <th>Assuntos</th>
                      <th>Questões</th>
                      <th>Relevância</th>
                      <th>Questões feitas</th>
                      <th>Meu Desempenho</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, index) => (
                      <tr key={index}>
                        <th>
                          {index + 1} - {item?.subject?.name}
                        </th>
                        <th>{item?.subject?.questionCount ?? 0}</th>
                        <th>{item?.subject?.relevanceInPercentage ?? 0}%</th>
                        <th>{item?.user?.alternativesCount ?? 0}</th>
                        <th>{`${
                          item?.user?.performanceInPercentage ?? 0
                        }%`}</th>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <th></th>
                      <th>{`${totalQuestionsCount} (total)`}</th>
                      <th>{`${totalRelevanceInPercentage}%`}</th>
                      <th>{`${totalAlternativesCount} (total)`}</th>
                      <th></th>
                    </tr>
                  </tfoot>
                </table>
                <p className={emptyClass}>
                  {!isEnoughData &&
                    !!data?.length &&
                    "*  A quantidade de questões dessa banca pode não ser suficiente para gerar dados estatísticos de relevância. Mas não se preocupe, pois nosso banco cresce a cada dia e em breve teremos ainda mais questões"}
                </p>
              </div>
            ) : (
              <p className={emptyClass}>
                {!loading &&
                  !!organizerId &&
                  "Não temos informações sobre essa combinação de questões. Reavalie os filtros escolhidos e, se estiverem corretos, sugira ao nosso suporte a inclusão dessas questões em nosso banco."}
              </p>
            )}
          </>
        </>
      )}
    </div>
  );
}
