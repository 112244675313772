import React from 'react';

export { AchievementsIcon } from './AchievementsIcon';
export { EventsIcon } from './EventsIcon';
export { MaterialsIcon } from './MaterialsIcon';
export { MedtrackIcon } from './MedtrackIcon';
export { NewsIcon } from './NewsIcon';
export { OffensiveIcon } from './OffensiveIcon';
export { SecurityIcon } from './SecurityIcon';
export { SuggestionIcon } from './SuggestionIcon';
export { WarningsIcon } from './WarningsIcon';
export { CheckIcon } from './CheckIcon';
export { PinIcon } from './PinIcon';
export { UpToDateIcon } from './UpToDateIcon';
