import React, { useEffect } from "react";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import { fetchStatistics } from "../../processes/statisticsProcesses";
import { getSpecialities } from "../../selectors/specialitySelector";
import { getFetchedSubjectsForReport } from "../../selectors/subjectsSelectors";
import MyPerformanceSubject from "./MyPerformanceSubject";

function MyPerformanceSubjectContainer(props) {
  const { dispatch } = props;

  useEffect(() => {
    fetchStatistics(dispatch, {
      statisticableTypeEq: "Subject",
      keyEq: "success_rate",
    });
  }, [dispatch]);

  return <MyPerformanceSubject {...props} />;
}

function mapStateToProps(state) {
  const formSelector = formValueSelector("subjectFilter");
  const specialityIdEq = formSelector(state, "specialityIdEq");
  const nameCont = formSelector(state, "nameCont");

  return {
    specialities: getSpecialities(state),
    subjects: getFetchedSubjectsForReport(state),
    nameCont,
    specialityIdEq,
  };
}

export default connect(mapStateToProps)(MyPerformanceSubjectContainer);
