import React, { useEffect } from "react";
import { connect } from "react-redux";
import { fetchAnnotations } from "../../processes/annotationProcesses";
import Annotations from "./Annotations";
import { getAnnotationsByLesson } from "../../selectors/annotationSelectors";

function AnnotationsContainer(props) {
  const { dispatch, currentClassroomId, lessonId } = props;
  useEffect(() => fetchAnnotations(dispatch, currentClassroomId, lessonId), [
    dispatch,
    currentClassroomId,
    lessonId,
  ]);
  return <Annotations {...props} />;
}

function mapStateToProps(state, ownProps) {
  const lessonId = ownProps.lessonId;
  return {
    annotations: getAnnotationsByLesson(state, lessonId),
    currentClassroomId: state.currentClassroomId,
    lessonId,
  };
}

export default connect(mapStateToProps)(AnnotationsContainer);
