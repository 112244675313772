import { apiPost } from "./helpers/api";
import { notifySubmitSucceeded } from "./notifierProcesses";

export const submitReportError = (values, dispatch, props) => {
  const { mockId, questionId } = props;

  apiPost("/api/v1/report_errors")
    .send({ ...values, mockId, questionId })
    .then(() => {
      props.toggleReportErrorModal();
      props.reset();
      notifySubmitSucceeded(
        dispatch,
        "Obrigado. Mensagem enviada com sucesso!"
      );
    });
};
