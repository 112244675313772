import React, { useEffect } from 'react';

import {
  Status,
  HeaderContainer,
  OpenAlternativeContainer,
  AlternativeTitle,
  AnswerDescription,
  AnswerTitle,
  UserAnswerContainer,
  RightAnswerContainer,
  ContentContainer,
} from './styles';

export function OpenAlternative({
  alternative,
  hasAlternatives,
  questionAnswer,
}) {
  const isMarkedAsCorrect = questionAnswer && questionAnswer.value;
  const userAnswer = questionAnswer && questionAnswer.value_text

  const userHasMarkedStatus = isMarkedAsCorrect !== null && isMarkedAsCorrect !== undefined

  return (
    <OpenAlternativeContainer hasAlternatives={hasAlternatives}>
      {hasAlternatives ? (
        <HeaderContainer>
          <AlternativeTitle>{alternative.title}</AlternativeTitle>

        {
        userHasMarkedStatus &&
          <Status isCorrect={isMarkedAsCorrect} hasAlternatives={hasAlternatives}>
            {isMarkedAsCorrect ? 'Acertei' : 'Errei'}
          </Status>
        }
        </HeaderContainer>
      ) : (
        <>
        {
        userHasMarkedStatus &&
          <Status isCorrect={isMarkedAsCorrect} hasAlternatives={hasAlternatives}>
            {isMarkedAsCorrect ? 'Acertei' : 'Errei'}
          </Status>
        }
        </>
      )}

      <ContentContainer>
        <UserAnswerContainer>
          {userAnswer && 
          <AnswerTitle>Sua resposta</AnswerTitle>
          }
          <AnswerDescription>
            {userAnswer || "Sem reposta"}
          </AnswerDescription>
        </UserAnswerContainer>
        <RightAnswerContainer>
          <AnswerTitle>Resposta correta:</AnswerTitle>
          <AnswerDescription>
          {alternative.value_text}
          </AnswerDescription>
        </RightAnswerContainer>
      </ContentContainer>
    </OpenAlternativeContainer>
  );
}
