import React from 'react';
import warning from '../../assets/warning.svg';
import { WarningContainer } from './styles';

export function WarningInfoBox({ text }) {
  return (
    <WarningContainer>
      <img src={warning} />
      <span>{text}</span>
    </WarningContainer>
  );
}
