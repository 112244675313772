import React from "react";
import { css } from "glamor";
import Link from "../library/Link";
import { stateColors, styles } from "../library/theme";

const container = css(styles.roundedCorners, {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: stateColors.empty,
  position: "relative",
  width: "100%",
  paddingBottom: "15%",
  "@media(max-width: 575px)": {
    paddingBottom: "calc(9/16 * 100%)",
  },
});
const bannerBaseClass = css({
  zIndex: 2,
  cursor: "pointer",
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  width: "100%",
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
});

export default function Banner(props) {
  const { banner } = props;
  const bannerClass = css(bannerBaseClass, {
    backgroundImage: `url(${banner.imageUrl})`,
    "@media(max-width: 575px)": {
      backgroundImage: `url(${
        banner.imageMobileUrl ? banner.imageMobileUrl : banner.imageUrl
      })`,
    },
  });

  return (
    <Link
      className={container}
      href={banner.url}
      target="_blank"
      component="div"
    >
      <div className={bannerClass}>
        <h1>{props.index}</h1>
      </div>
    </Link>
  );
}
