import styled from "styled-components";

export const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
`;
export const Modal = styled.div`
  min-width: 600px;
  min-height: 520px;
  background-color: #fff;
  border-radius: 4px;
  padding: 24px;
  gap: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-height: calc(100vh - 32px);

  @media (max-width: 991px) {
    justify-content: unset;
    height: unset;
    overflow: auto;
    min-width: unset;
  }
`;
