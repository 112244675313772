import { medtrackApi } from "../../../../services/medtrack";
import { toast } from "../../Toast";

export async function requestRecalculateRoute(activeScheduleId) {
  try {
    await medtrackApi.post("/user-activity/register", {
      action: "CLICK_ON_RECALCULATE_ROUTE_BUTTON",
    });

    await medtrackApi.post("/recalculate", {
      scheduleId: activeScheduleId,
    });

    toast.success("Rota recalculada com sucesso!");
  } catch (error) {
    console.error(error);
    toast.error("Ocorreu um erro ao recalcular a rota.");
  }
}
