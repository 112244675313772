import React from 'react'
import { Counter } from "./styles"

export function Card({ value, label }) {
  function pad(n) {
    if (n === 0) return 0
    return n.toString().padStart(2, '0')
  }

  return (
    <Counter>
      <p>{pad(value)}</p>
      <span>{label}</span>
    </Counter>
  )
}