const getCertificate = (action) => action.entities.certificate;

export default function certificateReducer(state = {}, action) {
  switch (action.type) {
    case "CERTIFICATE_FETCHED":
      return { ...state, ...getCertificate(action), hasCertificate: true};
    case 'CERTIFICATE_NOT_FOUND':
      return { ...state, hasCertificate: false};
    default:
      return state;
  }
}
