import React, { useCallback } from "react";
import { connect } from "react-redux";
import { createMockWithLoading } from "../../processes/mockProccess";
import { getMockFromMockTemplate } from "../../selectors/mockSelectors";
import { getCurrentUser } from "../../selectors/userSelectors";
import MockTemplateListItem from "./MockTemplateListItem";

function MockTemplateListItemContainer(props) {
  const { dispatch, mockTemplate } = props;

  const createMockHandler = useCallback(
    (setLoading) =>
      createMockWithLoading(
        { mockTemplateId: mockTemplate.id },
        setLoading,
        dispatch
      ),
    [dispatch, mockTemplate]
  );

  return (
    <MockTemplateListItem createMockHandler={createMockHandler} {...props} />
  );
}

function mapStateToProps(state, ownProps) {
  const { mockTemplate } = ownProps;
  const mock = getMockFromMockTemplate(state, mockTemplate.id);
  const isFrialUser = state?.isFrialUser;

  return {
    mock,
    user: getCurrentUser(state),
    isAdmin: getCurrentUser(state)?.isAdmin,
    isFrialUser,
    activeTabName: state.mock.activeMockTabName,
  };
}

export default connect(mapStateToProps)(MockTemplateListItemContainer);
