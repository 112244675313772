import React from 'react';
import { SpinnerIcon } from './styles';
import { colors } from '../../components/library/theme';

export function Spinner({ ...props }) {
  return (
    <SpinnerIcon
      {...props}
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 2.125C14.4281 2.125 15.8423 2.40629 17.1617 2.95281C18.4811 3.49933 19.6799 4.30038 20.6898 5.31021C21.6996 6.32005 22.5007 7.5189 23.0472 8.83832C23.5937 10.1577 23.875 11.5719 23.875 13C23.875 14.4281 23.5937 15.8423 23.0472 17.1617C22.5007 18.4811 21.6996 19.68 20.6898 20.6898C19.6799 21.6996 18.4811 22.5007 17.1617 23.0472C15.8423 23.5937 14.4281 23.875 13 23.875C11.5719 23.875 10.1577 23.5937 8.83831 23.0472C7.5189 22.5007 6.32005 21.6996 5.31021 20.6898C4.30037 19.6799 3.49933 18.4811 2.95281 17.1617C2.40629 15.8423 2.125 14.4281 2.125 13C2.125 11.5719 2.40629 10.1577 2.95281 8.83831C3.49933 7.5189 4.30038 6.32005 5.31022 5.31021C6.32006 4.30037 7.51891 3.49933 8.83832 2.95281C10.1577 2.40629 11.5719 2.125 13 2.125L13 2.125Z"
        stroke={colors.brand.pure000}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 2.125C14.4281 2.125 15.8423 2.40629 17.1617 2.95281C18.4811 3.49933 19.6799 4.30038 20.6898 5.31021C21.6996 6.32005 22.5007 7.5189 23.0472 8.83832C23.5937 10.1577 23.875 11.5719 23.875 13"
        stroke={colors.brand.pure}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SpinnerIcon>
  );
}
