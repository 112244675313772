import React from 'react';

export function MedicalClinicIcon() {
    return (
        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_400_4424)">
                <path d="M6.90909 8C9.08977 8 10.8571 6.20937 10.8571 4C10.8571 1.79063 9.08977 0 6.90909 0C4.72841 0 2.96104 1.79063 2.96104 4C2.96104 6.20937 4.72841 8 6.90909 8ZM3.20779 13.25C3.20779 13.6656 3.53782 14 3.94805 14C4.35828 14 4.68831 13.6656 4.68831 13.25C4.68831 12.8344 4.35828 12.5 3.94805 12.5C3.53782 12.5 3.20779 12.8344 3.20779 13.25ZM9.87013 9.01875V10.55C10.9959 10.7812 11.8442 11.7937 11.8442 13V14.3031C11.8442 14.5406 11.6776 14.7469 11.4463 14.7937L10.4531 14.9937C10.3205 15.0219 10.1909 14.9344 10.1631 14.7969L10.0675 14.3062C10.0398 14.1719 10.1261 14.0375 10.2619 14.0125L10.8571 13.8906V13C10.8571 11.0375 7.8961 10.9656 7.8961 13.0594V13.8938L8.4914 14.0156C8.62403 14.0437 8.71039 14.175 8.68571 14.3094L8.5901 14.8C8.56234 14.9344 8.43279 15.0219 8.30016 14.9969L7.33782 14.8656C7.09416 14.8313 6.91218 14.6219 6.91218 14.3687V13C6.91218 11.7937 7.76039 10.7844 8.8862 10.55V9.1375C8.81834 9.15938 8.75049 9.17188 8.68263 9.19687C8.12743 9.39375 7.53214 9.50313 6.91218 9.50313C6.29221 9.50313 5.69692 9.39375 5.14172 9.19687C4.91347 9.11562 4.68214 9.06563 4.44464 9.03438V11.5844C5.15714 11.8 5.67841 12.4625 5.67841 13.2531C5.67841 14.2188 4.90422 15.0031 3.95114 15.0031C2.99805 15.0031 2.22386 14.2188 2.22386 13.2531C2.22386 12.4625 2.74513 11.8 3.45763 11.5844V9.07187C1.49594 9.40625 0 11.1187 0 13.2V14.6C0 15.3719 0.619967 16 1.38182 16H12.4364C13.1982 16 13.8182 15.3719 13.8182 14.6V13.2C13.8182 10.95 12.0662 9.12813 9.87013 9.01875Z" fill="#1BC8EE" />
            </g>
            <defs>
                <clipPath id="clip0_400_4424">
                    <rect width="13.8182" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}