import styled from 'styled-components';
import { Spinner } from '../../../../Spinner';

export const Container = styled.div`
  max-width: 540px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-top: 48px;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: #606a71;
  text-align: center;

  strong {
    font-size: 18px;
    font-weight: 600;
  }

  span {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const Logo = styled.img`
  height: 48px;
  margin-bottom: 80px;
`;

export const StyledSpinner = styled(Spinner)`
  width: 94px;
  height: 94px;
`;
