import React from "react"

export function FlashcardsIcon(props) {
    return (
        <svg  {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <rect x="13.2087" y="3.06311" width="11.4197" height="15.3" rx="1.90328" transform="rotate(14.8286 13.2087 3.06311)" fill="currentColor" />
            <rect width="11.4197" height="15.2959" rx="1.90328" transform="matrix(-0.966696 0.255929 0.255929 0.966696 10.7781 3.04678)" fill="currentColor" />
            <rect x="6.41743" y="2.41743" width="11.1651" height="17.1651" rx="1.58257" fill="currentColor" stroke="white" strokeWidth="0.834853" />
        </svg>

    )
}

