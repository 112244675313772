import { createSelector } from "reselect";
import { orderByAlphabetic } from "../utils/orderByAlphabetic";

const getConfigs = (state) => state.entities.configs;

export const getSpecialities = createSelector(getConfigs, (configs) => {
  return Object.values(configs["specialities"]).sort(orderByAlphabetic);
});

export const getReportSpecialities = createSelector(getConfigs, (configs) => {
  return Object.values(configs["specialities"])
    .filter((speciality) => speciality.userSuccessRate)
    .sort(orderByAlphabetic);
});
