import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import NotificationListItem from "./NotificationListItem";
import { getNotificationsUserByNotification } from "../../selectors/notificationsUserSelectors";
import {
  createNotificationsUser,
  updateNotificationsUser,
} from "../../processes/notificationsUserProcesses";

function NotificationListItemContainer(props) {
  const { dispatch, toggleDropdown, notification, notificationsUser } = props;

  const handler = notificationsUser
    ? updateNotificationsUser
    : createNotificationsUser;

  const readNotification = useCallback(() => {
    handler({ notificationsUser, notification, read: true }, dispatch);
  }, [dispatch, notification, notificationsUser, toggleDropdown]);

  const fileNotification = useCallback(() => {
    handler({ notificationsUser, notification, filed: true }, dispatch);
  }, [dispatch, notification, notificationsUser]);

  return (
    <NotificationListItem
      readNotification={readNotification}
      fileNotification={fileNotification}
      {...props}
    />
  );
}

function mapStateToProps(state, ownProps) {
  const { notification } = ownProps;
  return {
    notificationsUser: getNotificationsUserByNotification(
      state,
      notification.id
    ),
  };
}

export default connect(mapStateToProps)(NotificationListItemContainer);
