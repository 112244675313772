import React, { useState } from "react";
import { IoArrowForward, IoInformationCircle } from "react-icons/io5";
import { BigAreaDropdown } from "../../../components/BigAreaDropdown";
import { MedTrackTooltip } from "../../../components/MedTrackTooltip";
import { Slider } from "../../../components/Slider";
import { Infobox } from "../../../components/Infobox"
import {
  BigAreaTitle,
  BigAreaTitleWrapper,
  Button,
  Container,
  ContainerWeeklyStudyTimeScreen,
  ContentWrapper,
  Hr,
  Instructions,
  SliderWrapper,
  Subtitle,
  Title,
  Wrapper,
} from "./styles";
import { Illustration } from "./MedtrackIllustration";
import { Description } from "./Description";
import { MedtrackTitle } from "./MedtrackTitle";
import { FrialMetricsOnboarding } from "./FrialMetricsOnboarding";
import { createGoalInput, createResidences } from "./FrialMetricsOnboarding/utils";

export function WeeklyStudyTimeScreen({
  isFrial,
  onNextStep,
  availableStudyTime,
  onChangeAvailableStudyTime,
  isBases,
  isRevision,
  isFirstAccess,
}) {
  const [selectedBigArea, setSelectedBigArea] = useState();
  const [chosenResidences, setChosenResidences] = useState([])
  const [errorStateResidences, setErrorStateResidences] = useState(false)
  const [hasApprovalStatesError, setHasApprovalStatesError] = useState(false)
  const [specialtyId, setSpecialtyId] = useState(-1)
  const [approvalStates, setApprovalStates] = useState([])

  async function handleNextStep(selectedBigArea) {
    const session = JSON.parse(localStorage.getItem("session"));
    const userId = session.currentUserId;

    if (approvalStates.length === 0) {
      return setHasApprovalStatesError(true)
    }

    if (chosenResidences.length === 0) {
      return setErrorStateResidences(true)
    }

    await createResidences({
      chosenResidences,
      userId,
      specialtyId,
      statesToGraduate: approvalStates.map(state => {
        return { id: state.id }
      })
    });

    await createGoalInput(userId);

    onNextStep(selectedBigArea);
  }

  if (isFrial) {
    return (
      <ContainerWeeklyStudyTimeScreen>
        <Container>
          <Wrapper>
            <ContentWrapper>
              <Title style={{ marginBottom: "16px" }}>
                Defina suas informações complementares
              </Title>

              <Description isRevision={false} isFrial={true} />

              <FrialMetricsOnboarding
                chosenResidences={chosenResidences}
                setChosenResidences={setChosenResidences}
                errorStateResidences={errorStateResidences}
                hasApprovalStatesError={hasApprovalStatesError}
                setSpecialtyId={setSpecialtyId}
                approvalStates={approvalStates}
                setApprovalStates={setApprovalStates}
              />
              <MedTrackTooltip
                isHidden={!isFrial}
                width="189px"
                variant="frialTimeAvailability"
                text="Ajuste de disponibilidade de tempo disponível apenas para alunos."
              >
                <SliderWrapper isFrial={isFrial}>
                  <p>
                    Defina sua disponibilidade de horas por semana para estudar:
                  </p>
                  <Slider
                    availableHours={availableStudyTime}
                    onChange={onChangeAvailableStudyTime}
                  />
                </SliderWrapper>
              </MedTrackTooltip>
            </ContentWrapper>

            <Infobox variant={"lightGrey"}>
              Ao se matricular, você desbloqueia a funcionalidade de disponibilidade de horas.
            </Infobox>

            <Button
              onClick={() => handleNextStep(selectedBigArea)}
            >
              Começar os estudos <IoArrowForward fill="#fff" />
            </Button>
          </Wrapper>
        </Container>
      </ContainerWeeklyStudyTimeScreen>
    );
  }

  return (
    <ContainerWeeklyStudyTimeScreen>
      <Container>
        <Wrapper>
          <Illustration isRevision={isRevision} />

          <ContentWrapper>
            <MedtrackTitle isRevision={isRevision} />
            <Description isRevision={isRevision} />
          </ContentWrapper>

          <Hr />
          {(isBases && !isFrial) && (
            <>
              <Subtitle>Defina os detalhes iniciais</Subtitle>

              <ContentWrapper>
                <BigAreaTitleWrapper>
                  <BigAreaTitle>Rodízio de estudos:</BigAreaTitle>

                  <MedTrackTooltip
                    width="254px"
                    variant="titleInfoBigArea"
                    text="O Medtrack se adapta ao seu rodízio atual. Defina a grande-área que está rodando nesse momento e suas tarefas de estudos serão ajustadas automaticamente."
                  >
                    <IoInformationCircle size={20} fill="#C2CED6" />
                  </MedTrackTooltip>
                </BigAreaTitleWrapper>

                <BigAreaDropdown
                  selectedBigArea={selectedBigArea}
                  setSelectedBigArea={setSelectedBigArea}
                  isFirstAccess={isFirstAccess}
                />
              </ContentWrapper>
            </>
          )}

          <ContentWrapper>
            <Instructions>
              Defina sua disponibilidade de horas por semana para estudar:
            </Instructions>

            <Slider
              availableHours={availableStudyTime}
              onChange={onChangeAvailableStudyTime}
            />
          </ContentWrapper>

          <Button
            disabled={isBases && !selectedBigArea}
            onClick={() => handleNextStep(selectedBigArea)}
          >
            Começar os estudos <IoArrowForward fill="#fff" />
          </Button>
        </Wrapper>
      </Container>
    </ContainerWeeklyStudyTimeScreen>
  );
}
