import * as React from "react"
import { colors } from "../../../components/library/theme";

export function Illustration(props) {
  return (
    <svg
      width={240}
      height={240}
      viewBox="0 0 240 240"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M56.711 40.619s-26.83 26.001-34.78 55.5c-7.949 29.5 3.549 60.466 30.588 77.605 27.04 17.139 63.117 2.256 98.997 7.153s69.153-9.06 73.593-45.019c4.44-35.959-21.516-40.618-37.166-75.596-15.649-34.977-66.631-77.288-131.232-19.643z"
        fill="#92E3A9"
      />
      <path
        opacity={0.7}
        d="M56.711 40.619s-26.83 26.001-34.78 55.5c-7.949 29.5 3.549 60.466 30.588 77.605 27.04 17.139 63.117 2.256 98.997 7.153s69.153-9.06 73.593-45.019c4.44-35.959-21.516-40.618-37.166-75.596-15.649-34.977-66.631-77.288-131.232-19.643z"
        fill="#fff"
      />
      <path
        d="M123.185 228.965c60.133 0 108.88-8.658 108.88-19.339 0-10.68-48.747-19.339-108.88-19.339-60.134 0-108.881 8.659-108.881 19.339 0 10.681 48.747 19.339 108.881 19.339z"
        fill="#92E3A9"
      />
      <path
        opacity={0.5}
        d="M123.185 228.965c60.133 0 108.88-8.658 108.88-19.339 0-10.68-48.747-19.339-108.88-19.339-60.134 0-108.881 8.659-108.881 19.339 0 10.681 48.747 19.339 108.881 19.339z"
        fill="#fff"
      />
      <path
        d="M229.955 109.631s0-2.369-3.317-5.692-5.455-5.213-5.455-5.213-1.659 5.45 1.421 8.772a58.554 58.554 0 007.334 6.398l.017-4.265zM225.138 124.434s0-2.369-3.318-5.686c-3.317-3.318-5.449-5.219-5.449-5.219s-1.693 5.455 1.421 8.773a58.323 58.323 0 007.334 6.397l.012-4.265z"
        fill="#92E3A9"
      />
      <path
        d="M229.747 113.67s.248-2.358 3.904-5.308c3.655-2.951 5.968-4.609 5.968-4.609s1.078 5.596-2.341 8.569a58.47 58.47 0 01-7.983 5.585l.452-4.237zM223.93 131.153s.497-2.318 4.434-4.868c3.938-2.55 6.426-3.949 6.426-3.949s.48 5.681-3.227 8.281a58.82 58.82 0 01-8.53 4.717l.897-4.181zM225.025 150.216s0-2.375 3.317-5.692c3.317-3.318 5.45-5.213 5.45-5.213s1.658 5.449-1.422 8.767a58 58 0 01-7.334 6.403l-.011-4.265z"
        fill="#92E3A9"
      />
      <path
        d="M224.968 154.864s.333-2.346-2.476-6.104c-2.81-3.757-4.655-5.935-4.655-5.935s-2.42 5.162.158 8.886a58.299 58.299 0 006.364 7.379l.609-4.226zM221.696 168.68s0-2.369 3.317-5.686c3.318-3.317 5.45-5.213 5.45-5.213s1.664 5.45-1.422 8.767a58 58 0 01-7.334 6.403l-.011-4.271zM223.896 181.159s-.603-2.296 1.766-6.346c2.37-4.051 3.949-6.426 3.949-6.426s2.985 4.852.846 8.846a58.578 58.578 0 01-5.489 8.05l-1.072-4.124z"
        fill="#92E3A9"
      />
      <path
        d="M230.254 109.022a66.48 66.48 0 01-.908 7.362c-1.309 6.77-4.592 11.147-5.901 17.274-1.309 6.127 2.454 14.008 1.444 21.872-1.01 7.864-4.067 12.236-3.193 17.489.875 5.252 5.568 19.971 5.568 19.971M229.713 115.244l3.576-2.747M229.882 112.085l-1.501-2.663M223.643 133.376s2.995-2.257 6.07-4.513M224.98 128.282a46.539 46.539 0 00-5.162-7.718M225.138 153.584a36.954 36.954 0 016.408-8.564"
        stroke="#263238"
        strokeWidth={0.423111}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M224.556 156.912a41.628 41.628 0 00-3.243-6.155c-1.744-2.578-1.997-2.911-1.997-2.911M221.696 173.013a39.432 39.432 0 012.697-5.455 34.912 34.912 0 013.492-4.575M224.991 185.283a10.153 10.153 0 01.897-5.246c1.247-2.415 2.663-5.574 3.244-6.821"
        stroke="#263238"
        strokeWidth={0.423111}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M208.224 141.297s-.259-1.484 1.461-3.949c1.721-2.466 2.855-3.865 2.855-3.865s1.63 3.244.062 5.642a37.347 37.347 0 01-3.915 4.812l-.463-2.64zM212.85 150.063s-.254-1.483 1.467-3.949c1.721-2.465 2.849-3.864 2.849-3.864s1.636 3.238.062 5.641a37 37 0 01-3.915 4.807l-.463-2.635z"
        fill="#92E3A9"
      />
      <path
        d="M208.794 143.807s-.412-1.45-3.024-2.905c-2.612-1.456-4.248-2.257-4.248-2.257s-.062 3.628 2.404 5.117a36.87 36.87 0 005.613 2.64l-.745-2.595zM214.34 154.137s-.564-1.399-3.312-2.567c-2.747-1.168-4.457-1.783-4.457-1.783s.316 3.611 2.923 4.84a37.237 37.237 0 005.861 2.031l-1.015-2.521zM215.722 166.209s-.254-1.483-2.697-3.204-3.988-2.68-3.988-2.68-.446 3.6 1.844 5.348a37.67 37.67 0 005.303 3.216l-.462-2.68z"
        fill="#92E3A9"
      />
      <path
        d="M216.263 169.12s-.468-1.433.892-4.095c1.359-2.663 2.256-4.226 2.256-4.226s2.076 2.973.864 5.585a37.04 37.04 0 01-3.188 5.32l-.824-2.584zM220.427 181.092s.175-1.501 2.51-3.385c2.336-1.885 3.825-2.9 3.825-2.9s.655 3.565-1.534 5.438a36.737 36.737 0 01-5.111 3.515l.31-2.668zM219.812 177.413s-.26-1.483-2.697-3.204c-2.437-1.721-3.988-2.68-3.988-2.68s-.446 3.6 1.845 5.343a36.96 36.96 0 005.303 3.221l-.463-2.68zM219.778 185.498s.13-1.507-1.8-3.792c-1.929-2.284-3.176-3.599-3.176-3.599s-1.342 3.385.435 5.642a36.882 36.882 0 004.315 4.451l.226-2.702z"
        fill="#92E3A9"
      />
      <path
        d="M207.97 140.952a44.338 44.338 0 001.366 4.514c1.562 4.107 4.09 6.493 5.579 10.194 1.489 3.701 0 9.026 1.467 13.872s3.881 7.227 3.904 10.612c.022 3.385-1.315 12.795-1.315 12.795M208.986 144.794l-2.544-1.337M208.535 142.825l.654-1.827M214.763 155.502s-2.127-1.083-4.299-2.155M213.296 152.269a34.495 34.495 0 012.471-5.212M215.998 168.336a23.677 23.677 0 00-4.947-4.676"
        stroke="#263238"
        strokeWidth={0.423111}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M216.743 170.373c.336-1.441.793-2.85 1.365-4.214.813-1.806.937-2.037.937-2.037M220.167 182.716a22.298 22.298 0 012.736-2.82 14.812 14.812 0 001.772-1.546M220.286 180.144a25.25 25.25 0 00-2.285-3.126 22.408 22.408 0 00-2.685-2.487M219.552 188.2a6.467 6.467 0 00-1.128-3.193c-1.044-1.377-2.256-3.205-2.775-3.949"
        stroke="#263238"
        strokeWidth={0.423111}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M214.78 191.72h16.185v8.367a1.407 1.407 0 01-1.405 1.404h-13.376a1.407 1.407 0 01-1.404-1.404v-8.367z"
        fill="#263238"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.323 120.818s0-2.02-2.82-4.84c-2.821-2.821-4.65-4.435-4.65-4.435s-1.41 4.638 1.208 7.458a49.992 49.992 0 006.251 5.445l.011-3.628zM20.239 133.404s0-2.014-2.82-4.835c-2.822-2.82-4.65-4.456-4.65-4.456s-1.41 4.631 1.208 7.458a49.648 49.648 0 006.262 5.461v-3.628z"
        fill="#92E3A9"
      />
      <path
        d="M24.148 124.248s.21-2.003 3.318-4.513c3.108-2.511 5.077-3.915 5.077-3.915s.914 4.755-1.991 7.288a49.765 49.765 0 01-6.77 4.751l.366-3.611zM19.2 139.119s.424-1.975 3.77-4.141c3.345-2.166 5.466-3.385 5.466-3.385s.406 4.824-2.747 7.035a49.672 49.672 0 01-7.25 4.017l.762-3.526zM20.132 155.327s0-2.014 2.82-4.84c2.821-2.827 4.62-4.435 4.62-4.435s1.411 4.638-1.212 7.459a49.18 49.18 0 01-6.245 5.444l.017-3.628z"
        fill="#92E3A9"
      />
      <path
        d="M20.08 159.282s.289-1.992-2.103-5.191c-2.392-3.198-3.95-5.049-3.95-5.049s-2.053 4.389.136 7.56a49.524 49.524 0 005.416 6.273l.502-3.593zM17.3 171.01s0-2.014 2.82-4.84c2.821-2.827 4.632-4.434 4.632-4.434s1.41 4.637-1.207 7.458a50.058 50.058 0 01-6.251 5.444l.006-3.628zM19.184 181.656s-.514-1.946 1.506-5.393 3.385-5.467 3.385-5.467 2.516 4.09.677 7.509a49.893 49.893 0 01-4.665 6.843l-.903-3.492z"
        fill="#92E3A9"
      />
      <path
        d="M24.577 120.299c-.14 2.1-.398 4.191-.773 6.262-1.128 5.76-3.904 9.478-5.015 14.668-1.111 5.19 2.087 11.915 1.23 18.617-.858 6.702-3.464 10.403-2.72 14.865.745 4.462 4.74 16.987 4.74 16.987M24.12 125.591l3.04-2.336M24.261 122.9l-1.275-2.263M18.958 141.037s2.545-1.912 5.162-3.819M20.092 136.676a39.558 39.558 0 00-4.366-6.561M20.239 158.193a31.478 31.478 0 015.45-7.283"
        stroke="#263238"
        strokeWidth={0.423111}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.731 161.025a35.52 35.52 0 00-2.753-5.235c-1.49-2.195-1.692-2.477-1.692-2.477M17.3 174.711a34.316 34.316 0 012.29-4.637 29.865 29.865 0 012.973-3.887M20.103 185.148a8.636 8.636 0 01.762-4.463c1.06-2.053 2.257-4.738 2.759-5.799"
        stroke="#263238"
        strokeWidth={0.423111}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.842 147.745s-.215-1.264 1.247-3.34c1.46-2.076 2.425-3.283 2.425-3.283s1.388 2.753.051 4.806a31.525 31.525 0 01-3.328 4.09l-.395-2.273zM9.78 155.197s-.22-1.263 1.246-3.34c1.467-2.076 2.426-3.289 2.426-3.289s1.388 2.759.05 4.813a31.523 31.523 0 01-3.328 4.09l-.394-2.274z"
        fill="#92E3A9"
      />
      <path
        d="M6.327 149.877s-.35-1.23-2.567-2.465c-2.217-1.236-3.61-1.907-3.61-1.907s-.057 3.08 2.036 4.355a31.644 31.644 0 004.773 2.257l-.632-2.24zM11.043 158.667s-.48-1.191-2.82-2.189c-2.342-.999-3.78-1.518-3.78-1.518s.27 3.075 2.488 4.118a31.674 31.674 0 004.981 1.732l-.869-2.143zM12.222 168.929s-.22-1.264-2.296-2.725c-2.076-1.461-3.385-2.257-3.385-2.257s-.406 3.041 1.563 4.525a31.92 31.92 0 004.513 2.736l-.395-2.279z"
        fill="#92E3A9"
      />
      <path
        d="M12.68 171.405s-.396-1.218.755-3.481c1.151-2.262 1.935-3.593 1.935-3.593s1.766 2.527.734 4.75a31.348 31.348 0 01-2.708 4.513l-.717-2.189zM16.222 181.605s.147-1.275 2.138-2.855c1.992-1.579 3.25-2.465 3.25-2.465s.564 3.03-1.303 4.626a31.594 31.594 0 01-4.35 3.001l.265-2.307zM15.726 178.457s-.22-1.264-2.296-2.725c-2.076-1.461-3.385-2.256-3.385-2.256s-.384 3.063 1.568 4.547a31.669 31.669 0 004.513 2.736l-.4-2.302zM15.67 185.328s.106-1.275-1.53-3.221c-1.636-1.946-2.702-3.058-2.702-3.058s-1.128 2.861.367 4.796a31.594 31.594 0 003.672 3.785l.192-2.302z"
        fill="#92E3A9"
      />
      <path
        d="M5.627 147.452c.32 1.3.707 2.582 1.163 3.841 1.325 3.492 3.48 5.518 4.744 8.666 1.264 3.148 0 7.695 1.247 11.796 1.247 4.101 3.3 6.149 3.317 9.026.017 2.878-1.128 10.883-1.128 10.883M6.49 150.718l-2.16-1.134M6.113 149.048l.552-1.557M11.404 159.823s-1.805-.919-3.655-1.833M10.158 157.076a29.655 29.655 0 012.104-4.434M12.47 170.74a20.033 20.033 0 00-4.208-3.949"
        stroke="#263238"
        strokeWidth={0.423111}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.085 172.46a22.767 22.767 0 011.163-3.582c.694-1.535.795-1.738.795-1.738M16.002 182.965a18.754 18.754 0 012.324-2.409 12.43 12.43 0 001.507-1.315M16.098 180.781a21.307 21.307 0 00-1.94-2.657 18.837 18.837 0 00-2.257-2.121M15.477 187.63a5.542 5.542 0 00-.964-2.719c-.886-1.168-1.935-2.725-2.358-3.334"
        stroke="#263238"
        strokeWidth={0.423111}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.558 191.72h16.186v8.367a1.403 1.403 0 01-1.428 1.404H11.963a1.406 1.406 0 01-1.405-1.404v-8.367z"
        fill="#263238"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M164.74 86.879a19.241 19.241 0 00-3.904 8.462c-1.038 5.264-3.594 15.153-3.272 18.583.321 3.43 1.128 5.343 4.225 5.901 3.097.559 4.863-2.155 5.506-4.468.408-1.522.726-3.067.954-4.626l.637-12.57s-1.128-7.553-1.664-9.144c-.536-1.591-2.482-2.138-2.482-2.138zM206.227 84.363s1.129-.248 2.2 3.667c1.072 3.915 2.364 9.534 4.48 16.462 2.115 6.927 2.527 9.212 2.364 10.758-.164 1.546-1.383 3.261-5.642 3.261s-6.206-1.631-7.334-5.298c-1.128-3.667-.965-14.995-1.044-16.462-.079-1.466 3.667-13.122 4.976-12.388z"
        fill="#92E3A9"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M177.128 206.05s-2.769 2.933-4.4 4.812c-1.63 1.878-2.2 3.012.485 4.152 2.686 1.14 6.849-.564 8.073-2.443 1.224-1.878 3.831-3.994 3.831-3.994s1.382-1.546-.412-3.746c-1.794-2.2-6.764.322-7.577 1.219zM193.754 208.498s2.77.406 4.237 1.692c1.466 1.287 6.606 4.158 10.025 2.77 3.418-1.387 1.224-3.667-.649-5.297a19.888 19.888 0 00-3.425-2.443s-5.46-.406-7.898.813c-2.437 1.218-2.29 2.465-2.29 2.465z"
        fill="#fff"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M173.947 170.593s1.309 9.782.654 12.716c-.654 2.933-2.527 4.648-1.128 7.503a29.518 29.518 0 012.256 6.928c.164.976-.733 1.218 0 2.256.535.67.974 1.41 1.304 2.2 0 .249-.976 1.631-.976 1.958 0 .327.49 2.364.49 2.364s2.607-.564 4.48.163a19.293 19.293 0 013.83 2.201s1.693-.655 2.285-1.631c.32-.511.513-1.091.564-1.692.079-.327.897-2.042.818-3.346-.079-1.303-.981-4.073-.981-5.703 0-1.631 1.224-3.425 1.224-6.031s-1.224-19.316-1.224-19.316l1.128-.412s1.794 9.946 1.794 14.594c0 4.649-1.382 10.431-.485 11.571.897 1.14 1.952 2.121 1.952 2.364 0 .242-1.061 2.036-.813 2.933.387 1.179.713 2.376.976 3.588 0 .491.897 2.364 1.631 2.691.733.328 1.128-.411 5.461-1.303 4.332-.891 6.769-.654 6.358-1.957-.412-1.304-.813-3.019-1.304-3.346-.49-.327.164-.564 0-1.788-.163-1.224-1.06-2.528-1.873-4.485-.812-1.958.322-4.073.649-6.116.327-2.042-.163-5.077-.976-8.394-.812-3.317-.084-11.492-.084-11.492l.411-3.176s-12.473 1.128-20.134 1.055l-8.231-.079s-.541.575-.056 3.182z"
        fill="#263238"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M205.573 136.524s1.128 14.837 2.606 22.566a93.685 93.685 0 011.614 13.218h-5.93c-.248 0 .407-27.469.565-29.9.215-1.99.598-3.959 1.145-5.884z"
        fill="#92E3A9"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M181.021 76.16s-6.928 4.643-10.51 6.273-5.546 3.752-5.788 4.514c-.243.761 3.909 12.467 3.909 12.467s-.242 38.718-.242 45.239c0 6.522-2.037 26.16-1.794 26.16.242 0 6.77 4.242 15.728 4.485 8.959.242 11.847-1.061 14.668-1.061 2.821 0 8.462 0 8.558-.812.096-.813.164-18.831.243-27.226.079-8.394.163-14.346 0-15.728-.164-1.383-2.443-31.22-2.443-31.22s1.693-4.97 2.691-8.965c.999-3.994.976-4.485-.248-6.273-1.224-1.788-10.409-6.663-12.039-7.289a24.503 24.503 0 00-6.206-1.303c-1.534-.107-6.527.739-6.527.739z"
        fill="#92E3A9"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M165.716 109.304s1.06 2.442 2.364 3.503c1.303 1.061 4.321 1.551 4.97 3.182.648 1.63 0 2.849-.734 4.806-.733 1.958-.321 5.219-3.012 5.642-2.691.423-3.504-.564-5.709-2.121-2.206-1.557-3.583-1.794-4.886-4.158-1.303-2.364-1.692-5.642-.564-7.413"
        fill="#92E3A9"
      />
      <path
        d="M165.716 109.304s1.06 2.442 2.364 3.503c1.303 1.061 4.321 1.551 4.97 3.182.648 1.63 0 2.849-.734 4.806-.733 1.958-.321 5.219-3.012 5.642-2.691.423-3.504-.564-5.709-2.121-2.206-1.557-3.583-1.794-4.886-4.158-1.303-2.364-1.692-5.642-.564-7.413"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M180.716 72.95l-.259 6.849s-.649.976 3.261 1.218a29.56 29.56 0 007.497-.564l-.648-7.413s-4.079 1.693-6.443.897a22.828 22.828 0 00-3.408-.987z"
        fill="#fff"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M179.165 71.24s-.564 7.899-.976 10.268c-.412 2.37-.327 3.83.564 4.4.892.57 6.11 2.612 8.801 2.449 2.691-.164 6.77-2.612 7.091-3.346.322-.733-2.437-12.236-2.437-12.236l-.412.49s.734 6.032.491 6.207c-.242.174-9.698-.491-11.413-.565-1.715-.073-1.218-.163-1.218-.163l-.085-6.928-.406-.575z"
        fill="#fff"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M178.906 80.64s8.907 1.314 14.25.281M178.533 82.704s6.206 2.623 14.623.277M179.188 85.141s5.906 3.188 13.968-.192"
        stroke="#263238"
        strokeWidth={0.282074}
        strokeMiterlimit={10}
      />
      <path
        d="M193.596 62.925c.203-2.505.372-5.077-.248-6.358-.976-2.036-3.915-4.4-8.152-4.237-4.237.164-7.825 1.794-7.74 7.007 0 0 .18 1.552.389 3.453a4.666 4.666 0 00-.389 2.663c.131.87.436 1.704.897 2.454.039.485.067.902.079 1.213.079 2.2 1.545 4.806 2.854 5.703 1.309.897 3.746 2.285 5.298 2.285 1.551 0 4.315-1.467 5.641-3.588 1.027-1.693 1.128-2.82 1.128-4.812h.079a17.58 17.58 0 00.412-3.182 9.806 9.806 0 00-.248-2.6z"
        fill="#fff"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M178.2 63.416s.243 2.037.649 1.952c.406-.085.897-3.013.897-3.013l-1.546 1.06zM192.045 63.185s0 2.284.564 2.612c.564.327.976-1.958.976-1.958a4.046 4.046 0 00-1.54-.654z"
        fill="#263238"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M193.348 56.567c-.976-2.036-3.915-4.4-8.152-4.237-4.237.164-7.825 1.794-7.74 7.007 0 0 .18 1.552.389 3.453a4.681 4.681 0 00-.434 1.754c1.585-1.27 4.552-3.086 8.276-2.758a19.322 19.322 0 018.146 2.871 9.541 9.541 0 00-.237-1.732c.203-2.505.401-5.06-.248-6.358z"
        fill="#92E3A9"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M184.626 64.877l-.491 2.934s-.897.079-.564.648c.333.57 2.037 1.225 2.855.982.818-.243 1.63-1.06 1.545-1.388a.566.566 0 00-.406-.406l-1.06-3.018M183.729 70.913a9.671 9.671 0 004.728-.163"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M182.832 64.759c0 .428-.22.772-.491.772s-.485-.344-.485-.772c0-.43.22-.773.485-.773s.491.327.491.773zM189.026 64.719c0 .429-.22.773-.485.773s-.491-.344-.491-.773c0-.429.22-.773.491-.773s.485.367.485.773z"
        fill="#263238"
      />
      <path
        d="M180.959 63.58s.564-1.388 2.364-.898M186.99 62.761s1.692-1.224 2.933.897"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M171.583 119.492s4.976 1.128 6.115.897a7.234 7.234 0 003.91-2.121c1.472-1.467 2.691-2.691 2.691-1.467a3.641 3.641 0 01-1.061 2.607c-.564.654-1.63 1.387-1.873 2.448-.242 1.061.085 1.873 1.388 2.364 1.303.491 5.376 1.218 5.376 2.256 0 1.038-.327 3.509-1.794 4.322-1.466.812-5.049.242-5.049.242s2.934 1.631 2.364 2.043c-.57.411-2.285-.164-3.097-.897-.812-.734-1.128-.818-3.509-2.528-2.381-1.709-4.4-3.751-5.54-4.4a6.824 6.824 0 01-1.693-1.128c-.654-.565-.897-1.693-.411-3.013a3.062 3.062 0 011.794-1.692l.389.067z"
        fill="#fff"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M210.3 110.201s-5.867 1.128-6.437 1.545c-.569.418 0 2.122 0 2.122s-4.975 2.363-6.848 3.667c-1.873 1.303-.164 3.915-.079 4.236 0 0 1.467 4.813 3.909 6.443 2.443 1.63 4.97 1.055 7.419-1.551 2.448-2.607 3.994-6.206 5.134-7.419 1.139-1.213 2.363-2.364 1.957-5.077-.406-2.714-2.527-3.019-2.527-3.019"
        fill="#92E3A9"
      />
      <path
        d="M210.3 110.201s-5.867 1.128-6.437 1.545c-.569.418 0 2.122 0 2.122s-4.975 2.363-6.848 3.667c-1.873 1.303-.164 3.915-.079 4.236 0 0 1.467 4.813 3.909 6.443 2.443 1.63 4.97 1.055 7.419-1.551 2.448-2.607 3.994-6.206 5.134-7.419 1.139-1.213 2.363-2.364 1.957-5.077-.406-2.714-2.527-3.019-2.527-3.019"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M198.318 120.959s-3.746.976-4.807 1.061c-1.06.084-1.224.242-1.873-.249-.648-.49-3.672-2.256-5.077-3.909-1.405-1.653-2.448-1.958-2.285-1.061.164.897-.163 1.546.818 2.2.982.655 1.952 2.449 1.952 3.182 0 .734-.079.976-2.115 1.693-2.037.716-6.686 1.63-6.206 2.2.48.57 1.467.733 2.527.654 1.061-.079 5.461-.733 5.783-.491a.98.98 0 01.096 1.25.966.966 0 01-.339.296c-.733.491-3.339.491-4.891.655-1.551.163-1.63.564-.976.897a8.578 8.578 0 003.289.536c1.303 0 2.821-.13 2.172.276s-1.551.491-3.182.733c-1.63.243-2.2-.485-1.952.164.249.649 2.201.654 4.643.564a13.263 13.263 0 006.279-1.952c1.631-.981 7.577-2.448 7.989-2.77.411-.321 1.055-2.206.648-4.485-.406-2.279-2.493-1.444-2.493-1.444z"
        fill="#fff"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M204.76 111.419a29.831 29.831 0 015.134-3.419"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M182.262 126.775s4.638-.53 5.106-.598c.468-.067 1.326.463.925 1.061-.401.598-.598.463-2.257.796-1.646.25-3.31.361-4.975.332"
        fill="#fff"
      />
      <path
        d="M182.262 126.775s4.638-.53 5.106-.598c.468-.067 1.326.463.925 1.061-.401.598-.598.463-2.257.796-1.646.25-3.31.361-4.975.332"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M182.793 129.957s5.167-.333 5.037.068c-.129.401-.462.925-2.318 1.061-1.856.135-3.949.265-5.704-.598"
        fill="#fff"
      />
      <path
        d="M182.793 129.957s5.167-.333 5.037.068c-.129.401-.462.925-2.318 1.061-1.856.135-3.949.265-5.704-.598"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M131.105 202.033s.88.874-2.629 3.018c-3.509 2.144-6.437 3.12-6.437 4.389 0 1.269 3.899 2.533 7.898 1.557 4-.976 5.642-1.264 7.899-1.653 2.256-.389 2.927-2.341 2.927-5.077 0-2.736-5.946-3.385-6.628-3.509-.683-.124-2.539.203-3.03 1.275z"
        fill="#fff"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M139.878 204.273a26.02 26.02 0 01-5.36 5.85c-3.023 2.256-3.413 3.311-1.85 3.898 1.563.587 6.042.779 9.066-.389 3.024-1.168 4.485-2.923 5.359-3.707.875-.784 1.365-.293 1.365-3.413 0-3.12-3.317-4.191-6.042-3.994-2.724.197-2.538 1.755-2.538 1.755z"
        fill="#fff"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M127.991 140.715s.88 17.743 1.174 22.911c.293 5.167 2.437 24.563 2.82 29.143.384 4.581.39 5.947.39 5.947s-1.755 1.269-1.755 1.562c0 .294-.197 3.018-.197 3.018a18.665 18.665 0 004.095 1.659c1.567.353 3.181.452 4.779.293 0 0 3.215 1.072 5.946 1.851 2.73.778 3.802.389 3.802.389s.779-3.898.971-5.168c.191-1.269 3.509-14.718 3.509-20.873 0-6.155-.779-8.97-1.947-12.575-1.168-3.605 1.557-16.479 2.533-21.155.56-2.609.983-5.246 1.27-7.899 0 0-8.384 4.311-27.39.897z"
        fill="#263238"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M138.907 146.679s-.49 12.975-.49 17.934c0 4.959 1.269 11.018 1.85 16.868.581 5.85.197 15.401-.485 17.06-.683 1.658-1.168 2.256-1.072 3.509.096 1.252.197 3.018.197 3.018"
        stroke="#fff"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M137.345 71.207s-.564-1.755-1.467-1.072c-.903.682-.288 1.952.491 3.221.778 1.27 1.653 1.557 1.754.875.102-.683-.778-3.024-.778-3.024z"
        fill="#fff"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M137.345 71.991l-.976-2.82s-4.09-1.66-2.821-5.755c1.269-4.096 11.018-7.797 13.647-7.797 2.629 0 7.7 3.51 8.58 7.605.881 4.096-2.047 7.797-2.047 7.797s-1.27 3.605-2.257 3.413c-.987-.192-.395-3.024-1.659-5.264-1.263-2.24-2.047-3.385-3.413-2.82-1.365.564-7.018 2.256-7.503 3.018-.485.761-1.354 3.108-1.551 2.623z"
        fill="#263238"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M157.135 112.153l.485 2.533-6.042 9.359 1.365 2.144a41.733 41.733 0 007.797-6.725c3.898-4.287 4.287-4.874 4.287-6.629 0-1.754-1.359-4.778-1.359-4.778s-2.731 4.096-6.533 4.096z"
        fill="#fff"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M124.087 106.5s-1.952 4.677-1.557 6.138c.395 1.461 7.114 10.042 7.114 10.042l1.168-3.216s-2.533-5.263-2.533-7.018c0-1.754.88-5.168.88-5.168l-5.072-.778z"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M141.345 83.296s-8.384 3.216-9.946 3.707c-1.563.49-2.635.778-2.731 1.264-.096.485-4.773 12.479-5.641 14.137-.869 1.659-2.048 3.509-2.048 3.509a39.13 39.13 0 003.509 1.952c1.226.536 2.499.959 3.802 1.264l.564 6.73-5.754 25.342s11.503 3.215 19.181 2.928a47.481 47.481 0 0014.329-2.731c1.467-.491 3.707-1.365 3.707-1.365l-7.334-27.587.564-4.096.389 4s3.899.875 6.138.288c2.24-.587 5.078-2.821 5.078-2.821l-7.216-22.515-13.449-3.707-3.142-.299z"
        fill="#92E3A9"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M150.817 85.31l-6.257-1.72-3.215-.294-2.895 1.129a203.875 203.875 0 00-.129 7.277c0 5.078.564 9.557 2.048 8.677 1.483-.88 8.462-10.33 9.647-12.976.32-.676.588-1.375.801-2.093z"
        fill="#92E3A9"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M153.626 108.35c-.197-.192-1.072-8.58-1.072-8.58M128.279 109.129s-.683-3.312-.485-4.581c.197-1.269 1.557-8.773 1.557-8.773M131.596 123.17s-4.485 1.462-4.485 1.755c0 .293-.101 1.653-.101 1.653l4.586-2.144v-1.264zM143.488 123.853l-.197 1.264 9.748 2.533.486-.97-10.037-2.827z"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M146.704 104.469a.503.503 0 01-.502-.502c0-1.693.84-5.777 1.658-9.726.649-3.142 1.264-6.115 1.264-7.046a3.607 3.607 0 00-1.094-2.77c-1.168-1.083-3.244-1.523-6.166-1.31-6.099.452-6.545 14.105-6.55 14.234a.502.502 0 01-.502.491.502.502 0 01-.485-.519c0-.598.479-14.668 7.48-15.232 3.216-.231 5.546.293 6.922 1.58a4.634 4.634 0 011.416 3.503c0 1.033-.592 3.915-1.28 7.25-.773 3.717-1.642 7.931-1.642 9.522a.498.498 0 01-.145.376.494.494 0 01-.374.149z"
        fill="#263238"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M140.267 80.278s0 2.821-.096 3.385c-.096.564 0 3.024 0 6.341s.564 5.551 1.365 4.773c.801-.779 5.946-7.7 6.24-9.066.172-.767.27-1.55.293-2.335l.389-3.904s-6.042 1.754-8.58.976"
        fill="#fff"
      />
      <path
        d="M140.267 80.278s0 2.821-.096 3.385c-.096.564 0 3.024 0 6.341s.564 5.551 1.365 4.773c.801-.779 5.946-7.7 6.24-9.066.172-.767.27-1.55.293-2.335l.389-3.904s-6.042 1.754-8.58.976"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M150.309 74.428a46.29 46.29 0 01.389 10.234c-.389 5.946-1.094 8.733-2.821 9.59-1.726.858.502 1.828 3.131 1.924 2.629.096 6.533-.294 7.216-8.09.682-7.796-4.97-12.868-5.461-13.844a1.358 1.358 0 00-1.287-.694 1.35 1.35 0 00-1.167.88z"
        fill="#263238"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M131.303 104.176h-.04a.5.5 0 01-.462-.565c0-.276.564-6.803 4.011-6.803s3.526 6.279 3.526 6.55a.504.504 0 01-.502.502.5.5 0 01-.503-.502c0-.057-.073-5.54-2.516-5.54-2.098 0-2.882 4.236-3.007 5.872a.51.51 0 01-.507.486z"
        fill="#263238"
      />
      <path
        d="M131.303 103.284s0 4.875.192 5.845c.191.97 2.634 3.024 5.556 4.293"
        stroke="#263238"
        strokeWidth={1.1283}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M137.638 102.675s.192 4.874-.778 7.018c-.971 2.144-3.899 3.024-5.168 3.509"
        stroke="#263238"
        strokeWidth={1.1283}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M138.417 113.614a.878.878 0 00-.878-.81.882.882 0 00-.831 1.168.87.87 0 00.831.592.873.873 0 00.647-.283.879.879 0 00.231-.667zM132.375 113.422a.88.88 0 10-1.76-.01.88.88 0 001.76.01zM149.626 105.817a3.024 3.024 0 10-6.048-.012 3.024 3.024 0 006.048.012z"
        fill="#fff"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M148.261 105.817a1.655 1.655 0 00-1.025-1.533 1.665 1.665 0 00-1.808.362 1.658 1.658 0 102.833 1.171zM148.261 64.386s-8.479 3.312-9.551 3.876a7.568 7.568 0 01-1.461.683s.485 7.407.682 9.359c.198 1.952 3.312 3.802 6.042 3.706 2.731-.096 4.971-1.563 5.461-2.437.491-.874 1.851-3.509 1.851-3.509s3.215-.564 3.385-3.413c.169-2.849-.779-2.257-1.851-1.659a33.42 33.42 0 00-1.658.976 4.352 4.352 0 00-.491-3.023c-.846-1.439-2.212-3.194-2.409-4.559z"
        fill="#fff"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M141.345 73.452s.778 1.168 1.461 1.072M141.051 75.207s1.653 1.562 4.513.293"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M145.243 71.11s1.997-.873 3.12.683M141.345 70.92s-1.563-.977-2.731-.198"
        stroke="#263238"
        strokeWidth={0.846223}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M142.49 70.552a2.755 2.755 0 01-3.289 2.706 2.75 2.75 0 01-2.007-3.756 2.75 2.75 0 012.548-1.697 2.75 2.75 0 012.748 2.747zM149.344 71.325a2.752 2.752 0 11-5.504.013 2.752 2.752 0 015.504-.013zM142.49 70.552a1.161 1.161 0 011.156.243M149.44 71.76l2.702-.384"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.828 202.744a23.933 23.933 0 01-2.742 4.473c-1.63 2.003-2.042 3.43-.27 4.671 1.77 1.242 4.997 1.05 6.622-.953s4.243-7.526 4.243-7.526-5.71-1.715-7.853-.665zM54.697 203.979s2.82 3.718 4.513 5.642c1.693 1.923 4.891 2.854 6.6 2.758 1.71-.096 4.164-.665 2.505-3.712-1.658-3.046-5.122-6.002-5.122-6.002s-3.622 0-6.11-.288c-2.488-.288-2.51 1.032-2.386 1.602z"
        fill="#fff"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.385 133.957s-1.129 12.975-.745 22.103c.384 9.128-1.297 27.345-1.862 31.801-.564 4.457.514 11.814.565 13.433.05 1.619.107 2.482.107 2.482s.835-.564 3.91 0a27.44 27.44 0 004.213.564 37.109 37.109 0 003.132-6.77c.919-3.046.4-6.205.327-7.813-.074-1.608-.339-3.334-.158-3.622.18-.288 3.949-27.53 4.152-33.533a66.54 66.54 0 011.805-12.067s1.8 8.383 1.94 11.621c.142 3.239-.32 29.63-.09 34.978.232 5.348-.18 6.769-.79 8.101-.609 1.331-.389 1.997.04 3.046.429 1.05 1.777 5.715 1.777 5.715s6.877.384 7.808-.096c.93-.479 1.292-.948 1.275-1.235-.017-.288 3.312-7.148 2.996-10.003-.316-2.854-.677-2.38-.999-7.525-.321-5.145 1.783-48.517 1.777-50.774-.005-2.256-.383 2.093-14.408-.564-14.025-2.657-16.372-1.653-16.772.158z"
        fill="#92E3A9"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M55.103 200.273s4.406-3.814 8.079-4.767l-8.079 4.767z"
        fill="#fff"
      />
      <path
        d="M55.103 200.273s4.406-3.814 8.079-4.767"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.112 198.456s5.507-2.668 7.097-3.334l-7.097 3.334z"
        fill="#fff"
      />
      <path
        d="M37.112 198.456s5.507-2.668 7.097-3.334"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.353 63.461s-1.478-3.334-2.572-2.189c-1.095 1.145-.265 2.663 1.24 4.474 1.507 1.81 2.697.666 2.382-.096-.316-.762-1.05-2.189-1.05-2.189zM60.66 61.65s1.478-3.238 2.685-1.692c1.208 1.546-1.387 5.337-2.025 6.002-.637.666-1.05-4.383-1.05-4.383l.39.073zM28.571 103.38s-.412 5.907-.914 7.43a37.235 37.235 0 00-1.421 11.046c.197 4.474 1.952 14.194 1.985 17.144.034 2.951-.885 3.622.102 4.384.987.761 3.086 3.052 3.847 3.052.762 0 1.924.378 1.896-.192-.028-.57-.4-.479-.722-1.241a26.439 26.439 0 01-1.004-3.402c.231-.841.512-1.668.84-2.476 0 0 .677 2.38.807 3.148.13.767 1.19 1.043 1.128-.384-.062-1.427-.09-2.003-.158-3.526-.067-1.523-1.269-2.855-1.777-3.622-.507-.767-.908-1.235-.925-1.523-.017-.288 1.642-10.38 1.693-13.906.05-3.526.101-6.386.355-7.052.254-.666 1.518-6.668 1.518-6.668l-7.25-2.212zM75.689 103.662s.406 5.941.914 7.509a38.838 38.838 0 011.416 11.176c-.198 4.462-1.963 14.025-1.997 16.975-.034 2.951.891 3.695-.096 4.372s-3.092 2.776-3.853 2.714c-.762-.062-1.918.208-1.896-.361.023-.57.4-.44.722-1.174a24.107 24.107 0 001.01-3.339 23.692 23.692 0 00-.846-2.556s-.677 2.324-.807 3.075c-.13.75-1.184.942-1.128-.48.056-1.422.085-1.991.152-3.509.068-1.518 1.27-2.747 1.783-3.464.513-.716.908-1.162.925-1.444.017-.282-1.641-10.527-1.692-14.059-.051-3.531-.096-6.391-.356-7.08-.26-.688-1.517-6.803-1.517-6.803l7.266-1.552z"
        fill="#fff"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.405 74.417s-5.416 2.572-8.688 4c-3.272 1.427-7.384 3.142-7.334 3.813.051.672-3.486 14.002-4.603 16.767-1.117 2.764-2.16 4.953-2.16 4.953s4 2.674 6.594 3.238c1.673.354 3.37.577 5.078.666l-3.086 27.44s6.205 2.477 15.926 2.573c6.03.044 12.06-.274 18.052-.954 0 0 .914-22.566.818-24.772a20.038 20.038 0 01.13-3.621c1.48.322 3.02.258 4.468-.187a38.849 38.849 0 003.74-1.619s-4.733-18.865-5.511-21.437c-.779-2.573-.418-2.95-1.095-3.239-.677-.287-8.157-3.525-10.6-4.953-2.443-1.427-3.385-1.433-5.523-2.098-2.138-.666-5.106-1.524-6.206-.57z"
        fill="#92E3A9"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M70.143 108.52L68.936 91.6M38.517 117.382l10.567-.287s-.469 13.246-.79 14.667c-.322 1.422-2.465 2.381-5.235 2.285-2.77-.096-4.446-1.427-4.762-2.093M37.925 119.097l11.142-.096M67.26 134.053c-1.038.288-3.514.288-4.056-1.236-.541-1.523-.186-15.147-.186-15.147l5.439.186M62.973 118.81l5.173.761M38.292 107.854s.383-13.049.23-16.479"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47.792 68.03s.823 7.899.857 8.767c.034.87.999 3.239 1.913 4.48.914 1.241 3.47 5.24 4.485 4.474 1.015-.768 3.876-5.05 4.152-7.43.276-2.381.412-8 .412-8s-3.707.192-6.516-.761a61.771 61.771 0 00-5.303-1.53z"
        fill="#fff"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.843 56.217l2.104 6.674s.79 4.953 2.211 6.95c1.422 1.998 6.404 4.864 8.666 4.288 2.262-.575 4.93-2.668 4.829-4.953-.102-2.285-.564-8.953-.502-11.526.062-2.572-.564-7.813-2.121-9.24-1.557-1.428-6.77-2.29-10.51-.762-3.74 1.529-6.28 4.378-4.677 8.57z"
        fill="#263238"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.066 63.461s-.187.096.304-1.81c.491-1.908 1.969-2.861 2.116-3.809.146-.948-1.862-1.241-1.642-2.668.22-1.428 2.183-2.477 6.352-2.956 4.17-.48 6.945 2.002 6.81 3.238-.136 1.235-.508 1.433.101 2.29.61.858.869 2.477.914 3.43.045.954.564 4.378-.338 5.427-.903 1.05-1.58.858-2.477 0-.897-.857-1.23-1.997-3.227-1.997-1.997 0-2.635.762-2.984 1.523-.35.762-.689 1.693-1.828 1.693-1.14 0-1.952-1.05-2.764-2.195a18.446 18.446 0 01-1.337-2.166z"
        fill="#fff"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M54.652 58.79s1.845 5.145 1.766 5.529c-.08.383-.982 1.523-2.037 1.427-1.055-.096-1.58-1.331-1.58-1.331"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M55.17 58.107a1.19 1.19 0 011.078-1.523 4.208 4.208 0 013.194 1.241M52.395 57.543a2.292 2.292 0 00-1.924-.383c-1.128.287-2.487 2.002-2.47 2.29"
        stroke="#263238"
        strokeWidth={1.1283}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.029 60.319c0 .474-.198.857-.491.857-.294 0-.564-.383-.564-.857s.197-.858.485-.858c.287 0 .547.384.57.858zM57.54 60.031c0 .474-.197.858-.485.858s-.564-.384-.564-.858.197-.857.485-.857.542.383.564.857z"
        fill="#263238"
      />
      <path
        d="M52.644 67.844s1.844.858 4.648-.383"
        stroke="#fff"
        strokeWidth={1.1283}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.857 76.16s2.82 9.715 5.85 16.1c3.03 6.387 5.433 10.861 5.433 10.861s4.564-15.34 4.541-20.31c-.022-4.97-.05-5.523-.05-5.523"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60.519 112.807h-5.816l-.424-9.714h5.811l.429 9.714zM103.248 206.935s3.046 2.477 5.235 3.143c2.189.665 3.334 1.049 2.86 2.098-.474 1.05-2.003 1.427-6.476 1.523-4.474.096-5.337-.857-7.052-1.337-1.715-.479-4.096 0-4.858-.947-.761-.948-.479-2.387-.479-3.91 0-1.523 6.578-2.189 8.293-1.619.861.26 1.691.612 2.477 1.049z"
        fill="#fff"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M94.577 208.842s2.86 3.046 5.24 4.378c2.381 1.331 3.713 2.29 3.143 2.956-.57.666-2.381 1.523-7.148 1.049-4.767-.474-5.715-1.529-8-3.148-2.284-1.619-3.334-1.427-4.095-2.284-.762-.858-.762-2.765-.762-2.765l11.622-.186z"
        fill="#fff"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M108.867 138.34s-1.693 22.674-2.381 26.769c-.688 4.096-1.337 6.386-1.241 9.241.096 2.855.953 11.052-.762 15.915-1.715 4.863-1.692 10.764-1.523 12.479.169 1.715.761 3.999.666 4.383-.096.384-2.855 1.901-4.858 2.285a23.13 23.13 0 01-3.813.378 25.677 25.677 0 01-5.997 1.337c-2.86.192-6.77-.666-6.77-.666s0-7.052-.384-11.627c-.383-4.575-1.692-11.903-.096-17.432 1.597-5.529 1.242-8.462 1.129-10.29-.113-1.828 0-10.003-.096-10.29-.096-.288-.666-24.101-.666-24.101s10.38 1.524 17.336.858c6.956-.666 8.767-.666 8.767-.666s1.546-1.715.689 1.427z"
        fill="#263238"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M95.434 208.458a19.365 19.365 0 00-.564-4.575c-.564-1.901-.671-8.665-.384-11.43.288-2.764 1.693-9.714 1.693-13.624 0-3.909.383-9.906.383-9.906"
        stroke="#fff"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M115.151 166.824l-10.854-.096c-.474 0-.474-7.238-.474-7.238l11.328 7.334z"
        fill="#fff"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M92.196 139.678s10.86.665 16.958-.384l-2.098-51.253s-1.428-2.38-5.241-3.718c-3.814-1.337-8.384.564-10.003 2.381-1.619 1.817-.474 52.782-.474 52.782l.858.192z"
        fill="#92E3A9"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M92.67 84.323s-10.764 5.433-12.383 6.386c-1.62.954-1.693 4.378-1.693 6.003s.474 73.734.474 73.734a36.599 36.599 0 0012.101-1.235 31.595 31.595 0 0010.764-5.145l-1.811-38.774-2.03-34.013s-1.23-9.432-5.422-6.956z"
        fill="#fff"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M95.908 80.797s-2.477.288-3.526 1.693a8.147 8.147 0 00-1.331 2.764l-.954 8.575 3.239-.761-1.62 3.808s1.62 4.767 3.43 8.766c1.811 4 4.48 12.192 4.48 12.192L98.385 92.21s0-7.43-.565-9.026c-.564-1.597-1.15-2.387-1.912-2.387zM102.102 86.038l1.907 77.644 11.142 3.142-1.619-59.156s.288-15.232-.666-17.342c-.953-2.11-9.522-6.38-10.38-6.268-.857.113-.384 1.98-.384 1.98z"
        fill="#fff"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M102.102 82.134a4.935 4.935 0 013.239 2.573c.953 2.093 3.43 8.575 3.43 8.575l-3.143.378 3.334 2.38-5.906 22.2-.666-24.027-.564-9.907s-.677-1.602.276-2.172z"
        fill="#fff"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M95.146 77.56s.384 2.763-.191 4.287c-.576 1.523 1.81 6.29 2.764 9.24.953 2.95 1.907 5.337 2.477 5.05.569-.288 2.572-11.43 2.476-12.863-.096-1.433.858-5.715.858-5.715s-5.241 1.523-8.384 0z"
        fill="#fff"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M88.456 74.654c3 0 5.432-2.541 5.432-5.676 0-3.134-2.432-5.675-5.432-5.675-3 0-5.433 2.541-5.433 5.675 0 3.135 2.432 5.676 5.433 5.676z"
        fill="#263238"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M91.243 71.647s-.954-2.477-2.1-1.523c-1.144.953 0 3.046.858 4.287s2.477.666 2.29-.564a4.785 4.785 0 00-1.048-2.2zM105.989 70.107s.48-2.38 1.129-.762a4.4 4.4 0 01-.192 4c-.564.953-.474.858-.666 0a29.499 29.499 0 01-.271-3.238z"
        fill="#fff"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M89.906 67.06s1.81 8.762 2.956 10.86c1.145 2.099 4.953 5.642 8.383 4.192 3.43-1.45 5.337-4.096 5.337-7.334 0-3.238.474-7.334-.096-10.866a7.178 7.178 0 00-6.77-5.906c-3.052-.096-7.813 1.619-8.767 4.67a22.415 22.415 0 00-1.043 4.384z"
        fill="#fff"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M90.001 70.299s5.337-2.1 8.767-4.672c3.43-2.572 4.48-4.473 4.48-4.473s1.619 4.377 2.284 5.81c.666 1.433 1.236 3.047 1.236 3.047s1.049-1.811 1.049-5.642c0-3.83-1.049-6.86-5.077-8.862-4.028-2.003-10.572-.762-13.54 2.098-2.967 2.86-2.38 7.526-1.619 9.337a11.447 11.447 0 002.42 3.357z"
        fill="#263238"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M101.815 66.773s-.564.096-.564 1.81c0 1.716.564 4.57.473 4.954-.09.383-1.128 1.523-1.805.857M97.527 76.11s2.477 1.049 5.642-.858M102.198 66.57s2.009-.768 3.334.383M98.864 66.965a5.754 5.754 0 00-5.145.953"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M97.527 69.723c0 .564-.231 1.05-.519 1.05-.288 0-.524-.469-.524-1.05 0-.581.23-1.044.524-1.044.294 0 .52.469.52 1.044zM103.733 69.345c0 .564-.237 1.05-.525 1.05-.288 0-.525-.469-.525-1.05 0-.581.237-1.05.525-1.05.288 0 .525.469.525 1.05z"
        fill="#263238"
      />
      <path
        d="M109.82 107.95l-1.693-2.764c-.479-.762-2.668-.474-3.717-.474a3.542 3.542 0 00-2.669 1.692c-.378.762-2.093 2.003-1.427 2.764.666.762 2.477.282 2.764.474.288.192 2.669 1.811 3.526 2.099.456.167.944.231 1.428.186l1.788-3.977z"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M112.392 89.756a7.728 7.728 0 014.096 4.57c1.049 3.334 4.57 25.533 3.808 27.643-.761 2.11-2.663 3.712-5.997 2.572-3.334-1.139-8.462-6.578-8.462-6.578l4-10.002 3.142 1.619-.666-8.953"
        fill="#fff"
      />
      <path
        d="M112.392 89.756a7.728 7.728 0 014.096 4.57c1.049 3.334 4.57 25.533 3.808 27.643-.761 2.11-2.663 3.712-5.997 2.572-3.334-1.139-8.462-6.578-8.462-6.578l4-10.002 3.142 1.619-.666-8.953M112.962 109.569s5.049 2.956 5.529 4.005"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M106.576 96.232H88.191v25.726h18.385V96.232z"
        fill="#263238"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M96.484 114.144s3.52-1.907 4.569-2.956 1.241-1.128 1.811-1.128a30.939 30.939 0 004.192-.288c.564-.192.287 1.128 0 1.427-.288.299-2.093.672-2.093.672s3.046-.288 3.142.665a1.54 1.54 0 01-.025.83 1.539 1.539 0 01-.455.694l-.282.287s.282 1.997 0 2.951c-.282.953-.857 1.241-2.098 1.427a20.61 20.61 0 01-4.954-.282c-.953-.192-4.191.474-4.191.474l.384-4.773z"
        fill="#fff"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M83.813 88.994S78.17 90.23 77.235 90.8c-.937.57-4.096 23.248-4.762 27.079-.665 3.83 5.907 4.575 7.898 5.337 1.992.761 16.592 1.884 16.592 1.884l.192-11.813-10.098-2.573-.096-16.293"
        fill="#fff"
      />
      <path
        d="M83.813 88.994S78.17 90.23 77.235 90.8c-.937.57-4.096 23.248-4.762 27.079-.665 3.83 5.907 4.575 7.898 5.337 1.992.761 16.592 1.884 16.592 1.884l.192-11.813-10.098-2.573-.096-16.293"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M87.05 110.714s-3.525-1.619-5.81-.761c-2.285.857-4.191 5.24-4.191 5.24"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M83.141 109.569s-3.334-.192-4.953.479a6.836 6.836 0 00-2.572 1.902"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M101.245 95.375h-6.477v2.099h6.477v-2.1z"
        fill="#fff"
        stroke="#263238"
        strokeWidth={0.564148}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fill="#C6C6C6"
        style={{
          mixBlendMode: "lighten"
        }}
        d="M-0.00000762939 0H239.99999237061V240H-0.00000762939z"
      />
      <g filter="url(#filter0_dd_2247_5838)">
        <path
          d="M120 170c27.614 0 50-22.386 50-50s-22.386-50-50-50-50 22.386-50 50 22.386 50 50 50z"
          fill="#35BD78"
        />
        <path
          d="M120.001 97.617a6.395 6.395 0 016.395 6.395v12.791h-12.791v-12.791a6.396 6.396 0 016.396-6.395zm9.593 19.186v-12.791c0-5.298-4.295-9.593-9.593-9.593s-9.593 4.295-9.593 9.593v12.791a6.396 6.396 0 00-6.396 6.395v15.989a6.396 6.396 0 006.396 6.395h19.186a6.395 6.395 0 006.395-6.395v-15.989a6.395 6.395 0 00-6.395-6.395zm-19.186 3.198h19.186a3.197 3.197 0 013.197 3.197v15.989a3.197 3.197 0 01-3.197 3.197h-19.186a3.197 3.197 0 01-3.198-3.197v-15.989a3.197 3.197 0 013.198-3.197z"
          fill="#fff"
        />
      </g>
      <defs>
        <filter
          id="filter0_dd_2247_5838"
          x={58}
          y={70}
          width={124}
          height={124}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius={2}
            in="SourceAlpha"
            result="effect1_dropShadow_2247_5838"
          />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={3} />
          <feColorMatrix values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.03 0" />
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2247_5838"
          />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius={4}
            in="SourceAlpha"
            result="effect2_dropShadow_2247_5838"
          />
          <feOffset dy={12} />
          <feGaussianBlur stdDeviation={8} />
          <feColorMatrix values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.08 0" />
          <feBlend
            in2="effect1_dropShadow_2247_5838"
            result="effect2_dropShadow_2247_5838"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow_2247_5838"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}
