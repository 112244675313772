import React from "react";

export function Smarthphone() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1010_8493)">
        <path
          d="M21 0H11C9.93913 0 8.92172 0.421427 8.17157 1.17157C7.42143 1.92172 7 2.93913 7 4V28C7 29.0609 7.42143 30.0783 8.17157 30.8284C8.92172 31.5786 9.93913 32 11 32H21C22.0609 32 23.0783 31.5786 23.8284 30.8284C24.5786 30.0783 25 29.0609 25 28V4C25 2.93913 24.5786 1.92172 23.8284 1.17157C23.0783 0.421427 22.0609 0 21 0ZM23 28C23 28.5304 22.7893 29.0391 22.4142 29.4142C22.0391 29.7893 21.5304 30 21 30H11C10.4696 30 9.96086 29.7893 9.58579 29.4142C9.21071 29.0391 9 28.5304 9 28V4C9 3.46957 9.21071 2.96086 9.58579 2.58579C9.96086 2.21071 10.4696 2 11 2H11.7094C11.8061 1.99965 11.9008 2.02756 11.9818 2.0803C12.0629 2.13304 12.1268 2.20832 12.1656 2.29688C12.3209 2.65434 12.5772 2.95864 12.9031 3.17238C13.229 3.38613 13.6103 3.5 14 3.5H18C18.3897 3.5 18.771 3.38613 19.0969 3.17238C19.4228 2.95864 19.6791 2.65434 19.8344 2.29688C19.8732 2.20832 19.9371 2.13304 20.0182 2.0803C20.0992 2.02756 20.1939 1.99965 20.2906 2H21C21.5304 2 22.0391 2.21071 22.4142 2.58579C22.7893 2.96086 23 3.46957 23 4V28Z"
          fill="#606A71"
        />
        <path
          d="M21 3C20.8848 2.9993 20.771 3.02541 20.6676 3.07629C20.5642 3.12717 20.4741 3.2014 20.4044 3.29313C20.1252 3.66757 19.7625 3.97166 19.3451 4.18119C18.9276 4.39071 18.4671 4.49988 18 4.5H14C13.5329 4.49988 13.0724 4.39071 12.6549 4.18119C12.2375 3.97166 11.8748 3.66757 11.5956 3.29313C11.5259 3.2014 11.4358 3.12717 11.3324 3.07629C11.229 3.02541 11.1152 2.9993 11 3C10.7348 3 10.4804 3.10536 10.2929 3.29289C10.1054 3.48043 10 3.73478 10 4V28C10 28.2652 10.1054 28.5196 10.2929 28.7071C10.4804 28.8946 10.7348 29 11 29H21C21.2652 29 21.5196 28.8946 21.7071 28.7071C21.8946 28.5196 22 28.2652 22 28V4C22 3.73478 21.8946 3.48043 21.7071 3.29289C21.5196 3.10536 21.2652 3 21 3Z"
          fill="#606A71"
        />
        <path
          d="M21 0H11C9.93913 0 8.92172 0.421427 8.17157 1.17157C7.42143 1.92172 7 2.93913 7 4V28C7 29.0609 7.42143 30.0783 8.17157 30.8284C8.92172 31.5786 9.93913 32 11 32H21C22.0609 32 23.0783 31.5786 23.8284 30.8284C24.5786 30.0783 25 29.0609 25 28V4C25 2.93913 24.5786 1.92172 23.8284 1.17157C23.0783 0.421427 22.0609 0 21 0ZM23 28C23 28.5304 22.7893 29.0391 22.4142 29.4142C22.0391 29.7893 21.5304 30 21 30H11C10.4696 30 9.96086 29.7893 9.58579 29.4142C9.21071 29.0391 9 28.5304 9 28V4C9 3.46957 9.21071 2.96086 9.58579 2.58579C9.96086 2.21071 10.4696 2 11 2H11.7094C11.8061 1.99965 11.9008 2.02756 11.9818 2.0803C12.0629 2.13304 12.1268 2.20832 12.1656 2.29688C12.3209 2.65434 12.5772 2.95864 12.9031 3.17238C13.229 3.38613 13.6103 3.5 14 3.5H18C18.3897 3.5 18.771 3.38613 19.0969 3.17238C19.4228 2.95864 19.6791 2.65434 19.8344 2.29688C19.8732 2.20832 19.9371 2.13304 20.0182 2.0803C20.0992 2.02756 20.1939 1.99965 20.2906 2H21C21.5304 2 22.0391 2.21071 22.4142 2.58579C22.7893 2.96086 23 3.46957 23 4V28Z"
          fill="#606A71"
        />
      </g>
      <defs>
        <clipPath id="clip0_1010_8493">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
