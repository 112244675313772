import { mixpanel } from "..";
// @ts-check
/**
 * @param {{
 * lessonId: number,
 * lessonName: string,
 * classroomId: number,
 * fileName: string,
 * isFrialUser: boolean
 * }} exportMaterialData
 */

export function trackExportMaterialEvent(exportMaterialData) {
  const profileData = {
    LessonId: exportMaterialData.lessonId,
    LessonName: exportMaterialData.lessonName,
    ClassroomId: exportMaterialData.classroomId,
    NomeDoMaterialComplementar: exportMaterialData.fileName,
    UserFrial: exportMaterialData.isFrialUser
  };

  mixpanel.track(
    "Plataforma - Meus cursos - Bloco de aula - clique em material complementar",
    profileData
  );
}
