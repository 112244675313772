import { medtrackApi } from "../../services/medtrack";
import { apiGet } from "./helpers/api";

export const isMedTrackFirstAccess = (dispatch) => {
  medtrackApi
    .get("/user/exists")
    .then((response) => {
      const isUserFirstAccess = response.body.userExists === false;

      if (isUserFirstAccess) {
        dispatch({ type: "IS_FIRST_ACCESS" });
      } else {
        allowUserAccess(dispatch);
      }
    })
    .catch((error) => {
      console.error(error);
    });
};

export async function hasMedtrackSchedule(dispatch) {
  try {
    await medtrackApi.get("/schedule").then((response) => {
      const scheduleTypes = Object.values(response.body.schedules).flatMap(
        (schedule) => schedule.type
      );

      if (scheduleTypes.includes("DEFAULT")) {
        dispatch({
          type: "HAS_DEFAULT_SCHEDULE",
          payload: true,
        });
      }

      if (scheduleTypes.includes("REVIEW")) {
        dispatch({ type: "HAS_REVISION_SCHEDULE", payload: true });
      }
    });
  } catch (error) {
    console.error(error);
  }
}

export async function requestSchedules(dispatch) {
  try {
    await medtrackApi.get("/user/exists").then((response) => {
      if (response.body.userExists) {
        hasMedtrackSchedule(dispatch);
      }
    });
  } catch (error) {
    console.error(error);
  }
}

export const hasMedtrackEnabled = (dispatch) => {
  apiGet("/api/v1/users/has_medtrack")
    .then(({ body }) => {
      dispatch({
        type: "MEDTRACK_TYPE",
        payload: {
          activeMedtrackType: body.activeMedtrackType,
          userMedtrackOptions: body.availableMedtrackTypes,
          hasDefaultMedtrack: body.hasDefaultMedtrack,
          hasRevisionMedtrack: body.hasRevisionMedtrack,
        },
      });
    })
    .catch((e) => {
      console.error(e);
      dispatch({
        type: "MEDTRACK_TYPE",
        payload: {
          activeMedtrackType: null,
          userMedtrackOptions: [],
          hasRevisionSchedule: false,
          hasDefaultSchedule: false,
        },
      });
    });
};

export const allowUserAccess = (dispatch) => {
  dispatch({ type: "ALLOW_ACCESS" });
};
