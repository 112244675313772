import { apiGet } from "../../processes/helpers/api";

export async function verifyIsSuperSimulado() {
  const response = await apiGet("/api/v1/user/validate_lp_super_simulado");
  const body = response.body;

  if (body.next_exam) {
    return { ...body, next_exam: `${body.next_exam} 00:00:00` };
  }

  return body;
}
