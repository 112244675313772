export function insertToastContainer() {
  const container = document.querySelector('#toast-container');
  if (!container) {
    const el = document.createElement('div');
    el.id = 'toast-container';
    const rootHomeDiv = document.querySelector('.pusher');
    const rootDiv = document.querySelector('body > div');
    (rootHomeDiv ?? rootDiv).appendChild(el);
    return el;
  }

  return container;
}
