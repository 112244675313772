import React from 'react';
import {
  CheckboxContainer,
  Text,
  HiddenCheckbox,
  StyledCheckbox,
  TextWrapper,
} from './styles';
import { IoCheckmark } from 'react-icons/io5';
import { colors } from '../../../../../components/library/theme';

export function BasesLessonCheckbox({
  title,
  subTitle,
  todo,
  handleTaskStatus,
  category,
}) {
  const hasNoLevel = title?.length <= 0;
  const isTheory = category === 'theory';
  const isDisabled = isTheory && hasNoLevel;
  const theoryTitle = hasNoLevel ? 'Defina o assunto' : title;

  return (
    <CheckboxContainer
      isDisabled={isDisabled}
      isChecked={todo.isCheckedByUser}
      onClick={handleTaskStatus}
    >
      <HiddenCheckbox
        onChange={handleTaskStatus}
        isChecked={todo.isCheckedByUser}
      />

      <StyledCheckbox isChecked={todo.isCheckedByUser}>
        <IoCheckmark color={colors.medtrack.white000} size={12} />
      </StyledCheckbox>

      <TextWrapper>
        <Text isDisabled={isDisabled}>{isTheory ? theoryTitle : title}</Text>
        {subTitle && <span>{subTitle}</span>}
      </TextWrapper>
    </CheckboxContainer>
  );
}
