import { mixpanel } from "..";
// @ts-check
/**
 * @param {{
 * provider: string,
 * }} eventData
 */

export function trackLoginWithProvider(eventData) {
  const {
    provider
  } = eventData;

  mixpanel.track(`Plataforma - Clique em Entre com o ${provider}`);
}
