import styled from "styled-components";
import { colors } from "../../components/library/theme";
export const Container = styled.div``;
export const Header = styled.header`
  height: 99px;
  padding: 0 21px;
  display: flex;
  align-items: center;

  h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  padding: 32px;
  gap: 24px;

  > div {
    width: 100%;
    /* height: 100%;*/
    padding: 40px;
    background-color: ${colors.interface.white000};
    border-radius: 4px;

    h4 {
      font-size: 18px;
      font-weight: 600;
      color: ${colors.interface.grey500};
      margin-bottom: 27px;
    }
  }

  @media (max-width: 1360px) {
    flex-direction: column;
  }
`;

export const Question = styled.button`
  width: 100%;
  min-height: fit-content;
  background: none;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  text-align: start;
  cursor: pointer;
  color: ${colors.interface.grey400};
  margin-bottom: 26px;
  padding: 0;
  font-family: "Poppins", sans-serif;
  svg {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
  }
`;
export const QuestionContainer = styled.div``;
export const Answer = styled.div`
  margin-bottom: 24px;

  p {
    font-size: 16px;
    line-height: 1.5;
    color: ${colors.interface.grey400};
    font-weight: 400;
    margin-bottom: 8px;
  }
  
  a {
    font-weight: 400;
    color: ${colors.brand.pure};
    word-break: break-all;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  margin-bottom: 26px;
  background-color: ${colors.interface.grey100};
`;

export const CommonQuestionsCard = styled.div``;
export const OctadeskHelpCard = styled.div`
  max-height: 860px;

  iframe {
    border: none;
  }
`;

export const Footer = styled.footer`
  width: 100%;
  height: 100px;
  background-color: ${colors.status.green200};
`;
