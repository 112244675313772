import React from "react";
import { css } from "glamor";
import { Accordion, Icon } from "semantic-ui-react";
import { styles, spacing, uiColors, typographyColors, colors  } from "../library/theme";
import { IoDownloadOutline, IoRibbon } from "react-icons/io5";
import { EMRButton } from "../../componentsV2/Button";
import { handleDownload } from "../../utils/handleDownload";

const layout = css({
  overflow: "hidden",
  "& + &": { marginTop: 1 },
});
const content = css({
  margin: 0,
  padding: `${spacing.m} !important`,
  border: `1px solid ${uiColors.border}`,
  borderTop: 0,
  backgroundColor: uiColors.fieldBackground
});
const titleWrapper = css(styles.roundedCorners, {
  border: `1px solid ${uiColors.border} !important`,
  background: uiColors.fieldBackground,
  padding: `${spacing.s} !important`,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  lineHeight: 1,
});
const title = css({
  margin: 0,
  flex: 1,
  
});
const chevronIconStyle = { color: typographyColors.defaultTitle };
const ribbonIconStyle = {
  color: uiColors.background,
  minWidth: 22,
};
const titleClass = css({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  gap: spacing.xs,
});

const descriptionClass = css({
  fontSize: 12,
  color: colors.interface.grey300
})

const certificateDetailsClass = css({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: spacing.l,
  justifyContent: 'flex-start',
  alignItems: 'center',
  alignSelf: 'flex-start'
})

const certificateDescriptionClass = css({
  width: '100%',
  maxWidth: '326px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  alignSelf: 'flex-start'
})

const certificateTitleClass = css({
  fontWeight: 600
})

const certificateSubtitleClass = css({
  textAlign: 'left',
  fontWeight: 400
})

const certificatePhotoClass = css({
  width: '462px',
  height: '291px',
  margin: '0',
  border: `1px solid ${colors.interface.grey100}`
})
const certificateContentClass = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: spacing.m
})

export default function CertificateItem({
  certificatePNG,
  certificatePDF,
  onClick,
  active,
  disabled = false,
}) {
  const titleWrapperClass = css(titleWrapper, 'Certificado');
  const contentClass = css(content)

  function handleDownloadCertificate() {
    handleDownload(certificatePDF, 'certificado')
  }

  return (
    <Accordion className={layout.toString()}>
      <Accordion.Title
        className={titleWrapperClass.toString()}
        onClick={!disabled && onClick}
      >
        <span className={titleClass}>
            <IoRibbon style={ribbonIconStyle} size={22} />
            <h5 className={title}>Certificado</h5>
        </span>

        <span className={titleClass}>
          {disabled && <h6 className={descriptionClass}>Finalize todos os módulos para acessar</h6>}
          <Icon name={active ? "chevron up" : "chevron down"} style={chevronIconStyle} />
        </span>
      </Accordion.Title>
      <Accordion.Content className={contentClass.toString()} active={active}>
        <div className={certificateContentClass}>
          <figure className={certificatePhotoClass}>
            <img src={certificatePNG} className={certificatePhotoClass}/>
          </figure>

          <div className={certificateDetailsClass}>
            <div className={certificateDescriptionClass}>
              <h3 className={certificateTitleClass}>Parabéns pela Conquista!</h3>
              <h5 className={certificateSubtitleClass}>Fique a vontade para compartilhar o certificado ou fazer o download em PDF.</h5>
            </div>

              
                <EMRButton fullSized fontSize='lg' mediumWeight onClick={handleDownloadCertificate}>
                  Download do certificado
                  <IoDownloadOutline size={22}/>
                </EMRButton>
          </div>
        </div>
      </Accordion.Content>
    </Accordion>
  );
}
