import { del, get, post } from "superagent";
import { getValidToken } from "../../frontend/processes/helpers/getValidUserProxyToken";
import { logout } from "../../frontend/processes/helpers/logout";

function getMededuApiUrl() {
  return process.env.MEDEDU_API_URL;
}

export const mededuApi = {
  get: async (endpoint) =>
    getValidToken().then((token) =>
      !token
        ? logout()
        : get(`${getMededuApiUrl()}${endpoint}`).set(
            `authorization`,
            `Bearer ${token}`,
          ),
    ),
  post: async (endpoint, body = {}) =>
    getValidToken().then((token) =>
      !token
        ? logout()
        : post(`${getMededuApiUrl()}${endpoint}`)
            .set(`authorization`, `Bearer ${token}`)
            .type("json")
            .accept("json")
            .send(body),
    ),
  del: async (endpoint, body = {}) =>
    getValidToken().then((token) =>
      !token
        ? logout()
        : del(`${getMededuApiUrl()}${endpoint}`)
            .set(`authorization`, `Bearer ${token}`)
            .type("json")
            .accept("json")
            .send(body),
    ),
};
