import { apiPost } from "./helpers/api";

export const changeOrganizer = (dispatch, organizerId) => {
  dispatch({ type: "ORGANIZER_CHANGED", organizerId });
};

export const changeExamTypeCategory = (dispatch, examTypeCategoryId) => {
  dispatch({ type: "EXAM_TYPE_CATEGORY_CHANGED", examTypeCategoryId });
};

export const changeExamTypeSlug = (dispatch, examTypeSlug) => {
  dispatch({ type: "EXAM_TYPE_SLUG_CHANGED", examTypeSlug });
};

export const fetchExternalOrganizerStatistics = (dispatch, params) => {
  const { organizerId, examTypeCategoryId, examTypeSlug } = params;

  if (
    (!organizerId && !examTypeCategoryId) ||
    (!organizerId && !examTypeSlug)
  ) {
    return;
  }

  dispatch({ type: "ORGANIZER_STATISTICS_FETCH_REQUESTED" });

  return apiPost(`/api/v1/organizer_statistics`)
    .send({
      organizerId,
      examTypeCategoryId,
      examTypeSlug,
    })
    .then((response) =>
      dispatch({
        type: "ORGANIZER_STATISTICS_FETCHED",
        organizerId,
        subjects: response.body.data,
      })
    );
};
