import { apiGet } from "./helpers/api";
import { normalize, schema } from "normalizr";

const levelGroup = new schema.Entity("levelGroups");

export const fetchLevelGroups = (dispatch, classroomId) => {
  apiGet(`/api/v1/classrooms/${classroomId}/level_groups`).then((response) => {
    dispatch({
      type: "LEVEL_GROUPS_FETCHED",
      ...normalize(response.body.data, new schema.Array(levelGroup)),
    });
  });
};
