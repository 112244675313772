import { getThemeByUniversity } from "../../utils/getThemeByUniversity";

const { IES, universityTheme } = getThemeByUniversity();

export const spacing = {
  xxs: "4px",
  xs: "8px",
  s: "12px",
  "s+": "16px",
  m: "20px",
  "m+": "24px",
  l: "32px",
  xl: "52px",
  xxl: "84px",
};

export const colors = {
  brand: {
    light000: "#EFFFF7",
    light100: universityTheme.get(IES ? IES : "default").light100,
    light200: universityTheme.get(IES ? IES : "default").light200,
    stepper: universityTheme.get(IES ? IES : "default").stepper,
    pure000: universityTheme.get(IES ? IES : "default").pure000,
    pure: universityTheme.get(IES ? IES : "default").brand,
    dark000: universityTheme.get(IES ? IES : "default").brandDark,
    dark100: "#007344",
    dark200: "#004D2D",
  },
  interface: {
    white000: "#FFFFFF",
    grey000: "#FBFBFD",
    grey100: "#EDF0F2",
    grey150: "#E1E8ED",
    grey200: "#C2CED6",
    grey300: "#9BA5AB",
    grey400: "#606A71",
    grey500: "#363E46",
    grey600: "#1D2224",
  },
  status: {
    red000: "#FFE9E9",
    red100: "#FFBFBF",
    red200: "#FF5B5B",
    red300: "#E64444",
    red400: "#B23535",
    yellow000: "#F5F4E1",
    yellow50: "#F7F4B9",
    yellow100: "#F7F161",
    yellow200: "#E9C610",
    yellow400: "#C4A00E",
    green000: "#D3FCE9",
    green100: "#77E2AC",
    green200: "#35BD78",
    green250: "#009a5a",
    green300: "#22965B",
    blue000: "#E2F5FF",
    blue100: "#BEE8FF",
    blue200: "#6BCAFF",
    blue300: "#49B1EB",
    blue400: "#2185D0",
  },
  medtrack: {
    white000: "#FFFFFF",
    green000: "#EFFFF7",
    green100: "#D3FCE9",
    green150: "#27EA8F",
    green200: "#35BD78",
    green300: "#69BD35",
    blue000: "#D7F0FF",
    blue100: "#89D3FC",
    blue200: "#43ADDA",
    blue700: "#356BBD",
    purple000: "#F0E8FE",
    purple100: "#C29FFF",
    purple200: "#985FFB",
    pink000: "#FFE0FE",
    pink100: "#F69FF3",
    pink200: "#D64EDB",
    pink300: "#DB4E8B",
    grey000: "#F7FAFC",
    grey100: "#EDF0F2",
    grey300: "#C2CED6",
    grey400: "#9BA5AB",
    grey500: "#606A71",
    grey600: "#363E46",
    orange200: "#FFA24D",
    red700: "#D83C3C",
  },
  semantic: universityTheme.get(IES ? IES : "default").semantic,
  shadow: "rgba(0, 0, 0, 0.1)",
  overlay: "rgba(2, 13, 66, 0.40)",
};

export const decorationColors = {
  decorationBrand: colors.brand.pure,
  decorationBrandDark: colors.brand.dark000,
  decorationHard: colors.status.green000,
  decorationBlue: colors.status.blue400,
};

export const utilityColors = {
  information: colors.brand.pure,
  danger: colors.status.red300,
  success: colors.status.green300,
  warning: colors.status.yellow200,
  shadow: colors.shadow,
  overlay: colors.overlay,
};

export const typographyColors = {
  defaultTitle: colors.interface.grey500,
  defaultBody: colors.interface.grey400,
  inverseTitle: colors.interface.white000,
  inverseBody: colors.interface.grey000,
};

export const uiColors = {
  background: colors.brand.pure,
  backgroundDark: colors.brand.dark000,
  backgroundDarker: colors.interface.grey500,
  backgroundDarkest: colors.interface.grey600,
  backgroundLightest: colors.interface.grey000,
  border: colors.interface.grey200,
  borderInverse: colors.interface.white000,
  fieldBackground: colors.interface.white000,
  contentCards: colors.interface.grey100,
};

export const stateColors = {
  focus: colors.brand.pure,
  hover: colors.interface.grey400,
  empty: colors.interface.grey200,
  filled: colors.interface.grey000,
  disabled: colors.interface.grey000,
};

export const buttonColors = {
  primary: colors.brand.pure,
  primaryDark: colors.brand.dark000,
  default: colors.interface.grey000,
  textPrimary: colors.interface.white000,
  textDefault: colors.interface.grey400,
  secondary: colors.interface.white000,
  textSecondary: colors.brand.pure,
  tertiary: colors.interface.grey200,
};

export const styles = {
  defaultFontFamily: {
    fontFamily: "Poppins !important",
    fontWeight: "400",
  },
  roundedCorners: { borderRadius: spacing.xxs },
  border: {
    boxShadow: `0 0 0 1px ${uiColors.border} inset`,
  },
  shadow: { boxShadow: `0 2px 5px ${utilityColors.shadow}` },
  transition: { transition: "all 150ms ease-in" },
  bodyText: {
    color: typographyColors.defaultBody,
    fontSize: 13,
    letterSpacing: "-0.3px",
    lineHeight: 2,
  },
  wrappingText: {
    lineHeight: 2,
  },
  bodyTextBold: {
    fontWeight: 600,
  },
  bodyTextItalic: {
    fontStyle: "italic",
  },
  bodyTextLarge: {
    fontSize: 15,
  },
  monospace: {
    fontFamily: "monospace",
    letterSpacing: "normal",
  },
  bodyTextSmall: {
    fontSize: 12,
  },
  whiteText: {
    color: typographyColors.inverseTitle,
    textShadow: `0px 1px 2px ${utilityColors.shadow}`,
  },
  whiteBodyText: {
    color: typographyColors.inverseBody,
    textShadow: `0px 1px 2px ${utilityColors.shadow}`,
  },
};
