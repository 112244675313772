import React from "react";
import PropTypes from "prop-types";

export function CrossIcon({
  fill = "#FF5B5B",
  height = "16",
  width = "16",
  ...props
}) {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 0.500183C3.41594 0.500183 0.5 3.41612 0.5 7.00018C0.5 10.5842 3.41594 13.5002 7 13.5002C10.5841 13.5002 13.5 10.5842 13.5 7.00018C13.5 3.41612 10.5841 0.500183 7 0.500183ZM9.35344 8.64675C9.40182 8.69271 9.44051 8.7479 9.46723 8.80906C9.49395 8.87021 9.50816 8.9361 9.50901 9.00283C9.50986 9.06957 9.49735 9.1358 9.47221 9.19762C9.44706 9.25944 9.4098 9.3156 9.36261 9.36279C9.31541 9.40998 9.25925 9.44725 9.19743 9.47239C9.13561 9.49753 9.06938 9.51005 9.00265 9.50919C8.93592 9.50834 8.87003 9.49413 8.80888 9.46741C8.74772 9.4407 8.69253 9.402 8.64656 9.35362L7 7.70737L5.35344 9.35362C5.25891 9.44343 5.13303 9.49276 5.00265 9.49109C4.87227 9.48942 4.7477 9.43688 4.6555 9.34468C4.5633 9.25248 4.51076 9.12791 4.50909 8.99753C4.50742 8.86715 4.55675 8.74128 4.64656 8.64675L6.29281 7.00018L4.64656 5.35362C4.55675 5.25909 4.50742 5.13321 4.50909 5.00283C4.51076 4.87245 4.5633 4.74788 4.6555 4.65568C4.7477 4.56348 4.87227 4.51095 5.00265 4.50928C5.13303 4.50761 5.25891 4.55694 5.35344 4.64675L7 6.293L8.64656 4.64675C8.74109 4.55694 8.86697 4.50761 8.99735 4.50928C9.12773 4.51095 9.2523 4.56348 9.3445 4.65568C9.4367 4.74788 9.48924 4.87245 9.49091 5.00283C9.49258 5.13321 9.44325 5.25909 9.35344 5.35362L7.70719 7.00018L9.35344 8.64675Z"
        fill={fill}
      />
    </svg>
  );
}

CrossIcon.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
