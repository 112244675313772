import React from "react";
import * as Dialog from "@radix-ui/react-dialog";
import {
  CheckboxText,
  DialogContent,
  DialogOverlay,
  IllustrationWrapper,
  Infos,
  StyledEMRButton,
  Title,
} from "./styles";

import { CheckBox } from "../CheckBox";

import { medtrackApi } from "../../../../../services/medtrack";
import { useMedTrack } from "../../hooks/useMedTrack";
import { Illustration } from "../ModalActiveRevision/illustration";

export function OnboardingRevisionModal({ onClose }) {
  const { dontShowAgain, setDontShowAgain } = useMedTrack();

  async function handleSendAction() {
    if (dontShowAgain) {
      await medtrackApi.post("/user-activity/register", {
        action: "DONT_SHOW_ONBOARDING_REVISION_MODAL",
      });
    }
    onClose();
  }

  return (
    <Dialog.Root open={true}>
      <Dialog.Portal>
        <DialogOverlay>
          <DialogContent>
            <IllustrationWrapper>
              <Illustration />
            </IllustrationWrapper>
            <Title>Você já pode iniciar sua jornada na Revisão EMR!</Title>
            <Infos>
              Traremos aqui os principais tópicos de cada assunto de modo 100%
              personalizado para seu aprendizado!
              <br />
              <br />
              Para seu melhor aproveitamento, mantenha atualizada sua lista de
              bancas prioritárias clicando na engrenagem da sua plataforma de
              Métricas, e ative o MODO REVISÃO do medtrack{" "}
              <b>10 semanas antes</b> da sua primeira prova. Após a realização
              da sua primeira prova, o medtrack recalculará a sua rota,
              direcionando seus estudos para os assuntos mais relevantes para
              sua próxima prova.
            </Infos>
            <CheckBox
              isChecked={dontShowAgain}
              onCheck={() => setDontShowAgain((prev) => !prev)}
              variant="square"
            >
              <CheckboxText>Não mostrar novamente</CheckboxText>
            </CheckBox>
            <StyledEMRButton onClick={handleSendAction}>
              Entendi
            </StyledEMRButton>
          </DialogContent>
        </DialogOverlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
