import styled from "styled-components";
import { colors } from "../../../../../components/library/theme";

export const Container = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;

  @media screen and (max-width: 400px) {
    width: 75%;
  }
`;
export const Bar = styled.div`
  height: 4px;
  border-radius: 4px;
  width: ${(props) => `${props.value}%`};
  background-color: ${colors.status.green200};
`;
export const BarWrapper = styled.div`
  width: 88px;
  height: 4px;
  border-radius: 4px;
  background-color: ${colors.interface.grey150};
`;
export const Value = styled.span`
  color: ${colors.interface.grey300};
  font-size: 12px;
  font-weight: 400;
`;
