import { LOCATION_CHANGE } from "connected-react-router";
import { matchPath } from "react-router-dom";

const initialState = null;

export default function currentLessonReducer(state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      const match = matchPath(action.payload.location.pathname, {
        path: "/classrooms/:classroomId/lessons/:lessonId",
        exact: true,
      });
      const lessonId =
        match && match.params.lessonId && Number(match.params.lessonId);

      return lessonId || initialState;
    default:
      return state;
  }
}
