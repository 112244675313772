import { spacing, uiColors, styles } from "../library/theme";
import { css } from "glamor";

export const numberClass = css({
  margin: `${spacing.m} 0`,
  display: "flex",
  alignItems: "center",
});
export const labelStyle = { marginTop: spacing.m };
export const videoWrapper = css({
  marginBottom: spacing.m,
  marginTop: spacing["s+"],
  padding: `${spacing["m+"]}`,
  borderBottom: `1px solid ${uiColors.border}`,
  background: "#FFF",
});

export const questionsComments = css({
  marginBottom: spacing["s+"],
  lineHeight: "1.5",
  fontWeight: styles.bodyTextBold.fontWeight,
});
export const extraInfoStyle = {
  whiteSpace: "break-spaces",
  marginTop: spacing["s+"],
  wordBreak: "break-word",
};
export const formWrapper = css({
  marginTop: spacing.m,
  display: "flex",
  justifyContent: "center",
});
export const difficultyStyle = {
  ...styles.bodyTextLarge,
  margin: `${spacing.m} 0`,
  textAlign: "center",
  fontWeight: "500",
  paddingBottom: spacing.m,
  borderBottom: `1px solid ${uiColors.border}`,
};
