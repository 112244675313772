import styled, { css } from "styled-components";
import { colors } from "../../../../components/library/theme";

export const Header = styled.header`
    margin: 24px 24px 16px;
    border-radius: 4px;
    display: flex;
    flex-wrap: wrap;
    gap: 0px 24px;
    align-items: center;
    justify-content: space-between;
    transition: all 0.3s ease;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const SkeletonStyle = styled.div(
  ({ width, height, borderRadius, external }) => css`
    width: ${width};
    height: ${height};
    border-radius: ${borderRadius};
    background: ${external
      ? `linear-gradient(110deg, ${colors.interface.grey200} 8%, ${colors.interface.grey150} 18%, ${colors.interface.grey200} 33%)`
      : `linear-gradient(110deg, ${colors.interface.grey150} 8%, ${colors.medtrack.grey100} 18%, ${colors.interface.grey150} 33%)`};

    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;

    @keyframes shine {
      to {
        background-position-x: -200%;
      }
    }
  `
);

export const ItemsContainer = styled.div`
  width: 100%;
  border: 1px solid ${colors.medtrack.grey100};
  border-radius: 4px;
  overflow: hidden;

  > div:last-child {
    border-bottom: none !important;
  }
`;

export const Content = styled.div`
  width: 100%;
  background: ${colors.interface.white000};
  border-radius: 4px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (max-width: 740px) {
    padding: 8px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 740px) {
    flex-direction: column-reverse;
    gap: 24px;
    div {
      width: 100%;
    }
  }
`;

export const Filters = styled.div`
  display: flex;
  gap: 8px;

  @media (max-width: 740px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row: 2;
  }
`;

export const SkeletonTitle = styled.h3`
  font-size: 16px;
  font-weight: 700;
  color: ${colors.medtrack.grey600};
  line-height: 24px;
  margin-bottom: 0;
`;

export const ItemHeader = styled.div`
  width: 100%;
  background: ${colors.medtrack.grey000};
  display: flex;
  gap: 16px;
  padding: 14px 16px;

  div {
    display: flex;
    gap: 16px;
  }

  @media (max-width: 740px) {
    flex-direction: column-reverse;
    gap: 8px;
  }
`;

export const FilterAndTimeWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  width: fit-content;
`;
