import React, { useState } from "react";
import { IoHeartDislike, IoHeartSharp } from "react-icons/io5";
import { trackMarkQuestionAsDoubtEvent } from "../../../services/mixpanel/events/trackMarkQuestionAsDoubtEvent";
import { apiPost } from "../../processes/helpers/api";
import styled from "styled-components";
import { EMRButton } from "../../componentsV2/Button";

const StyledButton = styled(EMRButton)`
  padding: 0 16px;

  @media (max-width: 1200px) {
    width: 100%;
  }
`;

export function FavoriteButton({
  markAsFavoriteHandler,
  mockTemplateName,
  isMarkedAsFavorite,
  questionId,
  disabled,
  isFrialUser
}) {
  const [loading, setLoading] = useState(false);

  async function handleFavorite() {
    setLoading(true);

    const response = await apiPost("/api/v1/questions/favorite").send({
      question_id: questionId
    });

    if (response.body.data.success) {
      markAsFavoriteHandler(response.body.data.current_state);
      trackMarkQuestionAsDoubtEvent({
        mockTemplateName,
        questionId: questionId,
        isFrialUser
      });
    }

    setLoading(false);
  }

  return (
    <StyledButton
      onClick={() => handleFavorite()}
      fontSize="md"
      mode={isMarkedAsFavorite ? "standard" : "outline"}
      disabled={disabled || loading}
    >
      {isMarkedAsFavorite ? (
        <IoHeartDislike size={24} />
      ) : (
        <IoHeartSharp size={24} />
      )}
      {isMarkedAsFavorite ? "Desfavoritar" : "Favoritar"}
    </StyledButton>
  );
}
