import React, { createContext, useEffect, useState } from "react";
import { apiGet, apiPost } from "../../processes/helpers/api";
import { useHistory } from "react-router-dom";
import { fetchClassroms } from "../../processes/classroomProcesses";

export const context = createContext({});

export function Provider({ children, user, dispatch, data }) {
  const history = useHistory();
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(true);
  const [suggestionsData, setSuggestionsData] = useState({});
  const [selectedClassRoom, setSelectedClassRoom] = useState({});

  useEffect(() => {
    apiGet("/api/v1/classroom_suggestion")
      .then((response) => setSuggestionsData(response.body))
      .catch((err) => alert(err))
      .finally(() => setLoading(false));
  }, []);

  function finish() {
    setLoading(true);
    apiPost("/api/v1/user/create_user_enrollment")
      .send(JSON.stringify({ classroom_id: selectedClassRoom.classroom_id }))
      .then(() => {
        fetchClassroms(dispatch);
        dispatch({
          type: "CURRENT_CLASSROOM_CHANGED",
          id: selectedClassRoom.classroom_id
        });
      });
  }

  function firstName() {
    const names = user.name.split(" ");
    return names[0];
  }

  const values = {
    user,
    step,
    setStep,
    suggestionsData,
    loading,
    finish,
    setSelectedClassRoom,
    selectedClassRoom,
    data,
    firstName
  };

  return <context.Provider value={values}>{children}</context.Provider>;
}
