import React from 'react';
import { EMRButton } from '../../../../componentsV2/Button';
import warning from '../../assets/warning.svg';
import { EMERGENCY_ACCESS_MODAL_TYPE } from '../../enum';
import { SecurityModal } from '../Modal';
import { ModalButtonsContainer, ModalTab, TabText } from '../Modal/styles';

export default function EmergencyAccessModal({
  isOpened,
  modalType,
  handleCloseModal,
  handleToSignInSession,
}) {
  function handleToLogin() {
    handleCloseModal();

    window.location = '/';
  }

  function handleClickContinueButton() {
    handleToSignInSession();
    handleCloseModal();
  }

  return (
    <>
      {isOpened && (
        <>
          {modalType === EMERGENCY_ACCESS_MODAL_TYPE.DEFAULT ? (
            <SecurityModal
              isModalOpened={true}
              onClose={handleCloseModal}
              title="Acesso Emergencial"
              text="Caso você esteja enfrentando problemas com seu acesso, utilize o acesso emergencial. Lembrando que todos seus dispositivos serão desconectados e para acessar novamente por outro dispositivo você deve entrar em contato com o nosso suporte."
            >
              <ModalTab>
                <img src={warning} />
                <TabText>
                  Este acesso emergencial só pode ser utilizado a cada 31 dias.
                </TabText>
              </ModalTab>
              <ModalButtonsContainer>
                <div>
                  <EMRButton
                    fullSized
                    mediumWeight
                    mode="cancel"
                    fontSize="lg"
                    onClick={handleCloseModal}
                  >
                    Cancelar
                  </EMRButton>
                </div>
                <div>
                  <EMRButton
                    fullSized
                    mode="standard"
                    fontSize="lg"
                    mediumWeight
                    onClick={handleClickContinueButton}
                  >
                    Continuar
                  </EMRButton>
                </div>
              </ModalButtonsContainer>
            </SecurityModal>
          ) : (
            <SecurityModal
              title="Você já solicitou o acesso emergencial"
              text="O acesso emergencial só pode ser utilizado uma vez a cada 31 dias, acesse sua conta por um dispositivo cadastrado. Caso esteja enfrentando problemas entre em contato com nosso suporte."
              isModalOpened={true}
              onClose={handleCloseModal}
            >
              <ModalButtonsContainer>
                <div>
                  <EMRButton
                    fullSized
                    mediumWeight
                    mode="cancel"
                    fontSize="lg"
                    onClick={handleToLogin}
                  >
                    Voltar ao login
                  </EMRButton>
                </div>
                <div>
                  <EMRButton
                    fullSized
                    mode="standard"
                    fontSize="lg"
                    mediumWeight
                    onClick={handleClickContinueButton}
                  >
                    Entendi
                  </EMRButton>
                </div>
              </ModalButtonsContainer>
            </SecurityModal>
          )}
        </>
      )}
    </>
  );
}
