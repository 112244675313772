import styled from "styled-components";
import { colors } from "../../../../components/library/theme";
import { EMRButton } from "../../../Button";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border-radius: 8px;
  padding: ${(props) => (props.highlight ? "32px 16px 16px 16px" : "16px")};
  border: solid 1px ${colors.interface.grey200};
  border-width: ${(props) => (props.highlight ? 2 : 1)}px;
  min-height: ${(props) => (props.highlight ? 355 : 338)}px;
  max-width: 305px;
  gap: 16px;

  @media (max-width: 991px) {
    order: ${(props) => (props.highlight ? 1 : 2)};
    margin: ${(props) => (props.highlight ? 0 : "0 18px")};
    width: ${(props) => (props.highlight ? "100%" : "calc(100% - 36px)")};
  }
`;
export const FeatureContainer = styled.div`
  color: ${colors.interface.grey500};
  margin: 0;
  font-size: 16px;
  display: flex;
  gap: 8px;
  align-items: center;
  height: 24px;
  width: 100%;

  span {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  svg {
    min-width: 20px;
  }
`;
export const Button = styled(EMRButton)`
  width: 100%;
  min-height: ${(props) => (props.highlight ? 48 : 40)}px;
  height: ${(props) => (props.highlight ? 48 : 40)}px;
  font-size: ${(props) => (props.highlight ? 16 : 14)}px;
  font-weight: 600;
`;
export const Tag = styled.span`
  background-color: ${colors.interface.grey100};
  padding: 4px 8px;
  border: 1px solid ${colors.brand.pure};
  border-radius: 4px;
  text-transform: uppercase;
`;
export const RecommendedTag = styled.span`
  background-color: ${colors.brand.pure};
  position: absolute;
  top: -14px;
  left: 16px;
  padding: 0 16px;
  height: 28px;
  border-radius: 4px;
  font-size: 14px;
  color: #fff;
  font-weight: 600;
`;
export const Title = styled.p`
  font-size: 18px;
  color: ${colors.interface.grey500};
  font-weight: 600;
  margin: 0;
  display: flex;
  align-items: left;
  width: 100%;
  line-height: 24px;
`;
export const FeaturesWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  gap: 8px;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;
export const Text = styled.p`
  font-size: 16px;
  color: ${colors.interface.grey500};
  font-weight: 600;
  margin: 0;
  line-height: 24px;
  width: 100%;
  gap: 8px;
  height: 24px;
`;
export const TagsWrapper = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  width: 100%;
`;
