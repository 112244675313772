import React from 'react';
import {
  ConfigurationButton,
  ConfigurationButtonWrapper,
  ConfigurationDropdown,
  OpitionConfigurationDropdown,
} from '../../screens/MainScreen/styles';
import {
  IoLibrarySharp,
  IoOptionsSharp,
  IoSettingsSharp,
} from 'react-icons/io5';
import { colors } from '../../../../components/library/theme';

export function ModalSettingsButton({
  toggleConfigurationDropDown,
  isShowConfigurationDropdown,
  closeConfigurationDropDown,
  openAvailableTimeModal,
  openBigAreaModal,
  isBases,
}) {
  return (
    <>
      {isBases ? (
        <ConfigurationButtonWrapper>
          <ConfigurationButton onClick={toggleConfigurationDropDown}>
            <IoSettingsSharp size={24} color={colors.interface.grey400} />
          </ConfigurationButton>
          {isShowConfigurationDropdown && (
            <ConfigurationDropdown onClick={closeConfigurationDropDown}>
              <OpitionConfigurationDropdown onClick={openAvailableTimeModal}>
                <IoOptionsSharp size={24} color={colors.medtrack.grey400} />
                Ajustar disponibilidade de tempo
              </OpitionConfigurationDropdown>
              <OpitionConfigurationDropdown onClick={openBigAreaModal}>
                <IoLibrarySharp size={24} color={colors.medtrack.grey400} />
                Ajustar grande-área prioritária
              </OpitionConfigurationDropdown>
            </ConfigurationDropdown>
          )}
        </ConfigurationButtonWrapper>
      ) : (
        <ConfigurationButton onClick={openAvailableTimeModal}>
          <IoSettingsSharp size={24} color={colors.interface.grey400} />
        </ConfigurationButton>
      )}
    </>
  );
}
