import { matchPath } from "react-router";

export function ignoreTemplate(location) {
  const currentPath = location.pathname;
  const routesToIgnore = [
    "/register-v2",
    "/validate-browser",
    "/segurancadaplataforma"
  ];

  const mustIgnore = routesToIgnore.some((path) =>
    Boolean(matchPath(currentPath, { path }))
  );

  if (mustIgnore) return true;
  return false;
}
