import { createSelector } from "reselect";
import { orderByPosition } from "../utils/orderByPosition";

const getId = (_, id) => id;
const getProps = (_, props) => props;
const getLevelGroups = (state) => state.entities.levelGroups;
const getCurrentClassroomId = (state) => state.currentClassroomId;
const getLessons = (state) => state.entities.lessons;
const getRatings = (state) => state.entities.ratings;
const getLessonsUsers = (state) => state.entities.lessonsUsers;
export const getLevelGroup = (_, props) => props.levelGroup;
export const getLevels = (state) => state.entities.levels;
const getAllLessons = createSelector(getLessons, (lessons) =>
  Object.values(lessons)
);
const getAllRatings = createSelector(getRatings, (ratings) =>
  Object.values(ratings)
);
const getAllLevels = createSelector(getLevels, (levels) =>
  Object.values(levels)
);

export const getLevelGroupsByClassroom = createSelector(
  getCurrentClassroomId,
  getLevelGroups,
  (classroomId, levelGroups) => {
    if (!levelGroups) return;
    return Object.values(levelGroups)
      .filter((levelGroup) =>
        levelGroup.classroomIds.includes(Number(classroomId))
      )
      .sort(orderByPosition);
  }
);

export const getLevelGroupByClassroom = createSelector(
  getLevelGroupsByClassroom,
  getId,
  (levelGroups, levelGroupId) =>
    levelGroups.find((levelGroup) => levelGroup?.id == levelGroupId)
);

export const makeIsCompleted = createSelector(
  getLevelGroup,
  getLevels,
  (levelGroup, levels) =>
    levels &&
    Object.values(levels)
      .filter((level) => level?.levelGroupId === levelGroup.id)
      .every((level) => level.meta.progress >= 1)
);

export const getLessonsByLevelGroup = createSelector(
  getAllLevels,
  getProps,
  getAllLessons,
  (levels, props, lessons) => {
    const levelIds = levels
      .filter((level) => level?.levelGroupId === props.levelGroup.id)
      .map((item) => item.id);
    return lessons.filter((lesson) => levelIds.includes(lesson.levelId));
  }
);

export const makeRatingWarning = createSelector(
  getLessonsByLevelGroup,
  getAllRatings,
  getLessonsUsers,
  (lessons, ratings, lessonsUsers) => {
    return lessons.some((lesson) => {
      const isWatched = Object.values(lessonsUsers).filter(
        (lessonsUser) => lessonsUser.lessonId == lesson.id
      )[0];
      const canBeRated = lesson?.ratingEnabled;
      const isRated = ratings.filter(
        (rate) =>
          rate.ratingableType === "Lesson" && rate.ratingableId === lesson.id
      )[0];

      return isWatched && canBeRated && !isRated;
    });
  }
);
