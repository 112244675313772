import React from "react";
import { css } from "glamor";
import { IoExit } from "react-icons/io5";
import { GiHamburgerMenu } from "react-icons/gi";
import { Image } from "semantic-ui-react";
import { getThemeByUniversity } from "../../utils/getThemeByUniversity";
import Link from "../library/Link";
import { buttonColors, spacing, typographyColors } from "../library/theme";
import NotificationModalContainer from "../notifications/NotificationModalContainer";
import NotificationsDropdownContainer from "../notifications/NotificationsDropdownContainer";
import { IoAlertCircle } from "react-icons/io5";
import { Tooltip } from "../Tooltip";
import {
  AlertTooltipContainer,
  Container,
  GlobalStyles,
  HamburgerMenu,
  LogoWrapper,
  RightWrapper,
  UserInfo,
} from "./styles";
import Timer from "../../componentsV2/Timer";
import Notifier from "../../componentsV2/Notifications/components/Notifier";

const { universityTheme, IES } = getThemeByUniversity();
const logoUniversity = universityTheme.get(IES ? IES : "default").logo;
const logoSize = universityTheme.get(IES ? IES : "default").logoSize;

const signOutLink = css({
  color: typographyColors.inverseBody,
  display: "inline-flex",
  justifyContent: "flex-end",
  alignItems: "center",
  cursor: "pointer",
  "&:hover": {
    color: typographyColors.inverseBody,
    opacity: 0.7,
  },
  "@media(max-width: 992px)": {
    display: "none",
  },
});
const signOutIconStyle = { marginRight: spacing.xs };
const logo = css({ "& + &": { marginLeft: spacing.m } });
const logoWrapper = css({
  minWidth: "156px",
  display: "flex",
  "@media(max-width: 992px)": {
    justifyContent: "center",
    alignItems: "center",
  },
});

export default function Header(props) {
  const {
    toogleSidebar,
    signOutHandler,
    isB2BUserExamInProgress,
    currentUser,
    isFrialUser,
  } = props;
  return (
    <Container id="page-header" isFrialUser={isFrialUser}>
      {isFrialUser && <GlobalStyles />}
      <HamburgerMenu onClick={toogleSidebar}>
        <GiHamburgerMenu size={30} color={buttonColors.textPrimary} />
      </HamburgerMenu>

      <LogoWrapper>
        <Link to="/" className={logoWrapper}>
          {IES ? (
            <img
              style={{ height: 54, objectFit: "contain" }}
              src={logoUniversity}
              className={logo.toString()}
            />
          ) : (
            <Image
              src={logoUniversity}
              size={logoSize}
              className={logo.toString()}
            />
          )}
        </Link>
        {isFrialUser && <Timer />}
      </LogoWrapper>

      <RightWrapper>
        <Notifier />
        <UserInfo>
          <span>{currentUser?.name}</span>
          <span>{currentUser?.email}</span>
        </UserInfo>
        {isB2BUserExamInProgress && (
          <AlertTooltipContainer>
            <IoAlertCircle size={24} color="#E1E8ED" />
            <Tooltip>
              <b>Algumas funções da plataforma estão inativas</b>
              <br />
              Para manter a integridade do processo avaliativo, algumas
              funcionalidades da sua plataforma estarão desativadas apenas
              durante a realização do seu simulado.
            </Tooltip>
          </AlertTooltipContainer>
        )}
        <Link onClick={signOutHandler} className={signOutLink}>
          <IoExit size={24} style={signOutIconStyle} />{" "}
          <span style={{ fontSize: "16px", fontWeight: 500 }}>Sair</span>
        </Link>
      </RightWrapper>
    </Container>
  );
}
