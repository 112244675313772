import React, { useState } from "react";
import { getThemeByUniversity } from "../../../utils/getThemeByUniversity";
import { EMRButton } from "../../Button";
import Dropdown from "../../Dropdown";
import Input from "../../Input";
import { YEARS } from "./consts";
import { ButtonWrapper, EnareObs, Text } from "./styles";
import { trackClickEvents } from "../../../../services/mixpanel/events/trackClickEvents";

export function FinishAuth2RegisterForm({
  data,
  errors,
  firstName,
  healthcareOptions,
  onChangeData,
  onFinishRegister,
  stateOptions,
  loading
}) {
  const [email, setEmail] = useState(data.email ? null : "");
  const [phone, setPhone] = useState(data.phoneNumber ? null : "");
  const { IES } = getThemeByUniversity();

  function handleAcessPlatform(){
    onFinishRegister({
      email: email ?? data.email,
      phoneNumber: phone ?? data.phoneNumber
    })
    trackClickEvents({eventName:"Clique em Acessar plataforma (Tela cadastro com provider)"})
  }

  return (
    <>
      <Text>
        Finalize seu cadastro, <strong>{firstName}</strong>
      </Text>
      <Dropdown
        options={healthcareOptions}
        selected={data.healthCareField}
        setSelected={(value) => {
          onChangeData("healthCareField", value),
          trackClickEvents({eventName: "Clique em Em qual prova você quer passar? (Tela cadastro com provider)", content: {Prova: value}})
        }}
        error={errors.healthCareField}
        label={"Em qual prova você quer passar?*"}
      />
      <Dropdown
        options={YEARS}
        selected={data.yearSelected}
        setSelected={(value) => {
          onChangeData("yearSelected", value),
          trackClickEvents({eventName: "Clique em Em que ano prestará a prova? (Tela cadastro com provider)", content: {Ano: value == 'Outros anos' ? 9999 : value}})
        }}
        error={errors.yearSelected}
        label={"Em que ano prestará a prova?*"}
      />
      {IES !== "revalida" && (
        <>
          <Dropdown
            options={stateOptions}
            selected={data.stateSelected}
            setSelected={(value) =>  {
              onChangeData("stateSelected", value),
              trackClickEvents({eventName: "Clique em Em qual estado será sua prova prioritária? (Tela cadastro com provider)", content: {Estado: value}})
            }}
            error={errors.stateSelected}
            label={"Em qual estado será sua prova prioritária?*"}
          />
          <EnareObs>
            Caso seu objetivo seja ENARE, marcar Distrito Federal
          </EnareObs>
        </>
      )}
      {!data.email && (
        <Input
          label="E-mail*"
          error={errors.email}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      )}
      {!data.phoneNumber && (
        <Input
          inputMask="(99) 99999-9999"
          label="WhatsApp*"
          error={errors.phoneNumber}
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
      )}
      <ButtonWrapper
        onClick={handleAcessPlatform}
      >
        <EMRButton loading={loading} fullSized>
          Acessar plataforma
        </EMRButton>
      </ButtonWrapper>
    </>
  );
}
