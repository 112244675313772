const browser = navigator.userAgent;
const regexIsMobile =
  /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
const regexIsApple = /iPhone|iPad|iPod|Mac OS/i;

function checkAndroid() {
  const userAgent = browser.toLowerCase();
  const isTablet =
    /(tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
      userAgent
    );
  return isTablet ? "TABLET" : "MOBILE";
}

function checkApple() {
  if (browser.includes("iPhone")) {
    return "MOBILE";
  }
  return "TABLET";
}

export function detectPlatform() {
  const isMobile = regexIsMobile.test(browser);

  const isIpad =
    /Macintosh/i.test(navigator.userAgent) &&
    navigator.maxTouchPoints &&
    navigator.maxTouchPoints > 1;

  if (isIpad) {
    return "TABLET";
  }

  if (isMobile) {
    const isApple = regexIsApple.test(browser);
    if (isApple) {
      return checkApple();
    } else {
      return checkAndroid();
    }
  } else {
    const hasTouchScreen = "ontouchstart" in document.documentElement;
    if (hasTouchScreen && browser.toLowerCase().includes("linux")) {
      return "TABLET";
    }
    return "DESKTOP";
  }
}
