import styled from "styled-components";
import { colors } from "../../../../components/library/theme";

export const SliderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 16px;
  height: 40px;
  padding: 0 16px;

  background-color: ${colors.medtrack.grey000};
  border-radius: 4px;

  span {
    color: ${colors.interface.grey400};
    font-weight: 600;
    font-size: 12px;
    line-height: 1.3;
    text-align: center;
    min-width: 54px;
  }
`;

export const SliderInput = styled.input`
  width: 100%;
  height: 8px;

  cursor: pointer;
  appearance: none;
  border-radius: 9999px;

  &::-webkit-slider-thumb {
    width: 24px;
    height: 24px;

    cursor: pointer;
    appearance: none;
    border-radius: 9999px;
    background: ${colors.brand.dark000};
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
  }
`;
