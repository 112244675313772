import React from "react";
import { Form, DropDownField, TextField } from "../library/forms";
import makeDropDownOptions from "../../utils/makeDropDownOptions";
import { Grid } from "semantic-ui-react";
import { spacing, uiColors } from "../library/theme";

const container = {
  paddingBottom: spacing.m,
  borderBottom: `1px solid ${uiColors.border}`,
  marginBottom: spacing.m,
};

export default function MyPerformanceSubjectForm(props) {
  return (
    <Form onSubmit={props.handleSubmit} error={props.error} style={container}>
      <Grid>
        <Grid.Column computer={8} mobile={16}>
          <TextField name="nameCont" placeholder="Filtre por nome" fluid />
        </Grid.Column>
        <Grid.Column computer={8} mobile={16}>
          <DropDownField
            placeholder="Selecione especialidade"
            name="specialityIdEq"
            options={makeDropDownOptions(props.specialities)}
            clearable
            selection
            search={!props.dirty}
            fluid
          />
        </Grid.Column>
      </Grid>
    </Form>
  );
}
