import styled from 'styled-components';
import { colors } from '../../../../components/library/theme';

export const Container = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  height: calc(100% - 156px);
  border-radius: 4px;
  text-align: center;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  @media (max-width: 991px) {
    padding: 64px 42px;
    height: calc(100vh - 271px);

    & > img {
      max-width: 332px;
      width: calc(100% - 42px);
    }
  }
`;

export const Title = styled.span`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: ${colors.interface.grey300};
`;

export const SubTitle = styled.span`
  font-size: 14px;
  line-height: 21px;
  color: ${colors.interface.grey300};
`;
