import { mixpanel } from "..";
// @ts-check
/**
 * @param {{
 * lessonName: string,
 * lessonType: string,
 * lessonId: number,
 * classroomId: number,
 * levelId: number,
 * mockTemplateId: number,
 * isFrialUser: boolean,
 * }} eventData
 */

export function trackAccessToLessonEvent(eventData) {
  const {
    lessonName,
    lessonId,
    lessonType,
    levelId,
    mockTemplateId,
    classroomId,
    isFrialUser
  } = eventData;

  mixpanel.track("Plataforma - Meus cursos - clique no bloco de aula", {
    NomeDoBlocoDeAula: lessonName,
    IDDoBloco: lessonId,
    TipoDoBloco: lessonType,
    LevelName: "",
    LevelId: levelId,
    MockTemplateName: "",
    MockTemplateId: mockTemplateId,
    IDDaTurma: classroomId,
    UserFrial: isFrialUser
  });
}
