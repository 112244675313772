import { colors } from "../../../../components/library/theme";
import styled from "styled-components";
import * as Dialog from "@radix-ui/react-dialog";
import { EMRButton } from "../../../Button";

const getWidth = ({ isR3CM, isRevision }) => {
  if (isR3CM) {
    return "580px";
  } else if (isRevision) {
    return "fit-content";
  }
  return "847px";
};

export const DesktopFilterContainer = styled.div`
  display: block;
  position: relative;

  form {
    position: absolute;
    z-index: 10;
    width: ${(props) => getWidth(props)};
    top: 44px;
    right: 0;
    box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
      hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  }

  @media screen and (max-width: 1132px) {
    display: none;
  }
`;

export const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
`;

export const DialogTrigger = styled(Dialog.Trigger)`
  display: none;

  &[data-state="open"] {
    background-color: ${colors.interface.grey200};
  }

  @media screen and (max-width: 1132px) {
    display: flex;
  }
`;

export const OpenFilterButton = styled(EMRButton)`
  height: 40px;
  gap: 8px;
  font-weight: 600;
  font-size: 14px;
  background: ${colors.interface.grey150};
  padding: 8px 24px;

  &:hover {
    background: ${colors.interface.grey200};
  }

  @media screen and (max-width: 1132px) {
    flex-direction: row;
  }

  @media screen and (max-width: 740px) {
    width: 100%;
  }
`;

export const DialogOverlay = styled(Dialog.Overlay)`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: fixed;
  overflow: auto;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: rgba(54, 62, 70, 0.3);
  z-index: 10;
  padding: 24px 0;
  animation: overlayShow 300ms cubic-bezier(0.16, 1, 0.3, 1);

  @keyframes overlayShow {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const DialogContent = styled(Dialog.Content)`
  z-index: 10;
  background-color: ${colors.interface.white000};
  border-radius: 4px;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  margin: auto;
  width: min(847px, calc(100vw - 48px));
  animation: contentShow 300ms cubic-bezier(0.16, 1, 0.3, 1);
  position: relative;
  padding: 24px;
  margin: auto 0;

  &:focus {
    outline: none;
  }

  @media screen and (max-width: 1132px) {
    width: min(467px, calc(100vw - 48px));
    padding: 0px;
  }

  @keyframes contentShow {
    from {
      opacity: 0;
      transform: scale(0.96);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
`;

export const Filter = styled.form`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  gap: 16px;
  border-radius: 4px;
  padding: 24px;

  h1 {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    color: ${colors.medtrack.grey600};
    margin: 0;
  }

  fieldset {
    border: none;
    padding: 0;
    margin: 0;
  }

  hr {
    width: 100%;
    margin: 0;
    border: solid 1px ${colors.interface.grey100};
    border-bottom: 0;
  }

  & > div hr {
    display: none;

    @media screen and (max-width: 1132px) {
      display: block;
    }
  }

  > div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    fieldset:first-child {
      border-right: 1px solid ${colors.medtrack.grey100};
      padding-right: 24px;

      @media (max-width: 514px) {
        border-right: 0;
        padding-right: 0;
        border-bottom: 1px solid ${colors.medtrack.grey100};
      }
    }

    fieldset:nth-child(n + 2) {
      padding-left: 24px;
    }

    fieldset:nth-child(n + 2):not(:last-child) {
      border-right: 1px solid ${colors.medtrack.grey100};

      @media (max-width: 514px) {
        border-right: none;
        padding-right: none;
        border-bottom: 1px solid ${colors.medtrack.grey100};
      }
    }

    h2 {
      font-size: 14px;
      align-self: flex-start;
      font-weight: 600;
      color: ${colors.interface.grey400};
    }

    label {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 14px;
      width: fit-content;
      line-height: 21px;
      user-select: none;
      white-space: nowrap;
      cursor: pointer;
      margin-bottom: 8px;
      padding: 8px 8px 8px 0;
    }

    label:last-child {
      margin-bottom: 0;
    }

    input[type="radio"] {
      background: #fff;
      border: 1px solid ${colors.medtrack.grey500};
      position: relative;
      display: inline-flex;
      flex-shrink: 0;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      border-radius: 100%;
    }

    input[type="radio"]:checked {
      border: 1px solid ${colors.medtrack.green200};
    }

    input[type="radio"]::before {
      content: "";
      position: absolute;
      inset: 0;
      width: 18px;
      height: 18px;
    }

    input[type="radio"]:checked::before {
      border-radius: 100%;
      background: ${colors.medtrack.green200};
      margin: auto;
    }

    @media screen and (max-width: 1132px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      row-gap: 16px;
      column-gap: 24px;

      hr {
        display: none;
      }

      fieldset:nth-child(n + 2) {
        border-right: none;
        padding-left: 0;
      }

      fieldset:first-child,
      fieldset:nth-child(5) {
        border-right: 1px solid ${colors.medtrack.grey100};
      }

      fieldset:first-child {
        position: relative;
        margin-bottom: 16px;

        ::after {
          content: "";
          display: block;
          height: 1px;
          width: 419px;
          background: ${colors.medtrack.grey100};
          position: absolute;
          bottom: -16px;
          left: 0;

          @media (max-width: 740px) {
            display: none;
          }
        }
      }
    }

    @media screen and (max-width: 515px) {
      display: flex;
      flex-direction: column;
      gap: 16px;

      fieldset:first-child,
      fieldset:last-child {
        border: none;
        padding: 0;
      }

      fieldset:nth-child(n + 2) {
        border-right: none;
        padding-left: 0;
      }

      fieldset:first-child {
        border-bottom: 1px solid ${colors.medtrack.grey100};
      }
    }
  }

  h3 {
    font-size: 14px;
    line-height: 21px;
    color: ${colors.medtrack.grey400};
    line-height: 21px;
    font-weight: 400;
    margin: 0;
  }
`;

export const ModalHeader = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 48px;
`;

export const CloseButton = styled.button`
  display: flex;
  align-self: flex-end;
  flex-shrink: 0;
  background-color: transparent;
  border: none;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  z-index: 1;
  padding: 0;
`;

export const ButtonContainer = styled.footer`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  width: 100%;

  button {
    height: 40px;
    font-weight: 600;
  }

  @media screen and (max-width: 1132px) {
    flex-direction: column-reverse;
    gap: 16px;
  }
`;
