import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { EMRButton } from '../../../Button';
import { toast } from '../../../Toast';
import { AuthenticationIPIllustration } from '../../assets/AuthenticationIP';
import { CodeInput } from '../../components/CodeInput';
import { ACTIVE_SCREEN } from '../../enum';
import { requestValidateCode } from '../../utils/requestValidateCode';
import { ContentContainter } from '../RegisterDeviceScreen/steps/Success/styles';
import { EMERGENCY_ACCESS_MODAL_TYPE } from '../../enum';
import {
  ButtonsContainer,
  CodeWrapper,
  Info,
  InfoBox,
  OptionsContainer,
  OutlineButton,
  Title,
} from './styles';
import EmergencyAccessModal from '../../components/EmergencyAccessModal';
import { requestEmergencyAccess } from '../../utils/requestEmergencyAccess';
import { setSession } from '../../../../processes/sessionProcesses';
import { apiPost } from '../../../../processes/helpers/api';

export default function CodeValidationScreen({ location, handleGoBack }) {
  const history = useHistory();
  const [code, setCode] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [codeError, setCodeError] = useState('');
  const [emergencyAccessModal, setEmergencyAccessModal] = useState({
    isOpened: false,
    modalType: EMERGENCY_ACCESS_MODAL_TYPE.DEFAULT,
  });

  async function handleToSignInSession() {
    const response = await requestEmergencyAccess(
      location.state.email,
      location.state.fingerprint
    );
    if (response.status === 201) {
      apiPost('/api/v1/auth/sign_in', true, true)
        .send({
          email: location.state.email,
          password: location.state.password,
        })
        .then((res) => {
          setSession(res);
          window.location = '/';
        });
    }
  }

  async function validate2faCode() {
    setIsLoading(true);
    const response = await requestValidateCode(
      code,
      false,
      location.state.fingerprint,
      location.state.email
    );
    if (response.status === 200) {
      history.replace('/signin-security', {
        email: location.state.email,
        password: location.state.password,
        activeScreen: ACTIVE_SCREEN.DEVICE_VALIDATED,
      });
    } else if (response.status === 401) {
      toast.warning('Você não está com ambos os dispositivos na mesma rede');
    } else {
      toast.error('Código inválido');
      setCodeError('Código inválido');
    }
    setIsLoading(false);
  }

  function handleEmergencyAccessModal() {
    setEmergencyAccessModal((prevState) => ({
      ...prevState,
      isOpened: true,
    }));
  }

  return (
    <ContentContainter>
      <AuthenticationIPIllustration />
      <InfoBox>
        <Title>Confirmação de segurança</Title>
        <Info>
          Para prosseguir, acesse o aplicativo EMR em seu celular, faça login e
          vá para a seção “Minha Conta”.{' '}
          <strong>
            Lá, você encontrará a opção “Conectar Dispositivos” para gerar o
            código de segurança.
          </strong>
        </Info>
      </InfoBox>
      <CodeWrapper>
        <CodeInput
          errorMessage={codeError}
          onFocus={() => {
            setCodeError(null);
          }}
          onChange={(code) => {
            setCode(code);
          }}
        />
        <OptionsContainer>
          <ButtonsContainer>
            <OutlineButton onClick={handleGoBack}>Voltar</OutlineButton>
            <EMRButton
              fullSized
              loading={isLoading}
              disabled={code.length <= 0}
              onClick={validate2faCode}
            >
              Confirmar código
            </EMRButton>
          </ButtonsContainer>
        </OptionsContainer>
      </CodeWrapper>

      <EMRButton
        style={{ width: 'fit-content' }}
        fontSize={'lg'}
        mode="transparent"
        onClick={handleEmergencyAccessModal}
      >
        {'Acesso emergencial'}
      </EMRButton>

      {emergencyAccessModal.isOpened && (
        <EmergencyAccessModal
          isOpened={emergencyAccessModal.isOpened}
          modalType={emergencyAccessModal.modalType}
          handleToSignInSession={handleToSignInSession}
          handleCloseModal={() => {
            setEmergencyAccessModal((prevState) => ({
              ...prevState,
              isOpened: false,
            }));
          }}
        />
      )}
    </ContentContainter>
  );
}
