import React from "react";
import { HighlightTooltipContainer, TooltipAction, TooltipDivider } from "./styles";

export default function HighlightTooltip({
  top,
  left,
  width,
  isDeleteTooltip,
  onDeleteHighlight,
  onAddHighlight,
  onCopyHighlightedText,
  ...rest
}) {
  const tooltipText = isDeleteTooltip ? "Desmarcar texto" : "Marcar texto";
  const tooltipAction = isDeleteTooltip ? onDeleteHighlight : onAddHighlight;

  return (
    <HighlightTooltipContainer
      left={left}
      top={top}
      width={width}
      isDeleteTooltip={isDeleteTooltip}
      {...rest}
    >
      <TooltipAction
        onClick={tooltipAction}
        className="highlight-control-btn"
      >
        {tooltipText}
      </TooltipAction>

      {isDeleteTooltip && (
        <>
          <TooltipDivider />

          <TooltipAction
            onClick={onCopyHighlightedText}
            className="highlight-control-btn"
          >
            Copiar
          </TooltipAction>
        </>
      )}
    </HighlightTooltipContainer>
  );
}
