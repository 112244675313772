import React from "react";

import { useHistory } from "react-router-dom";
import logo from "../../../../assets/images/logo.svg";
import { EMRButton } from "../Button";
import blockDevice from "./assets/block-device.svg";
import firstAttempt from "./assets/first-attempt.svg";
import secondAttempt from "./assets/second-attempt.svg";
import {
  ButtonContainer,
  Container,
  Content,
  Description,
  Illustration,
  Paragraph,
  Title,
} from "./styles";

const contentBySignInAttemptFails = {
  1: {
    illustration: (
      <img
        src={firstAttempt}
        style={{
          width: "100%",
          maxWidth: 389,
        }}
      />
    ),
    title: "Dispositivo não cadastrado.",
    description:
      "Esse dispositivo não está cadastrado junto a está conta. Acesse por um dispositivo autorizado ou entre em contato com nosso suporte",
  },
  2: {
    illustration: (
      <img
        src={secondAttempt}
        style={{
          width: "100%",
          maxWidth: 364,
        }}
      />
    ),
    title: "Este dispositivo não está cadastrado.",
    description:
      "Como medida de segurança você tem <b>mais 01 (uma) tentativa, após isso sua conta será bloqueada.</b> Caso você não tenha mais acesso ao seu dispositivo cadastrado entre em contato com nosso suporte.",
  },
  3: {
    illustration: (
      <img
        src={blockDevice}
        style={{
          width: "100%",
          maxWidth: 461,
        }}
      />
    ),
    title: "Sua conta foi bloqueada.",
    description:
      "Você tentou acessar sua conta 3 vezes por dispositivos não cadastrados, e por razões de segurança, sua conta foi bloqueada. Por favor, entre em contato com nosso suporte para desbloqueá-la.",
  },
};

export default function NotRegisteredDevice({ userSignInAttemptFails }) {
  const content = contentBySignInAttemptFails[userSignInAttemptFails];
  const reactRouterHistory = useHistory();

  function handleConfirmButton() {
    reactRouterHistory.push(process.env.EMR_URL);
  }

  return (
    <Container>
      <img src={logo} />
      <Content>
        {userSignInAttemptFails && (
          <>
            <Illustration>{content.illustration}</Illustration>

            <Description>
              <Title>{content.title}</Title>

              <Paragraph
                dangerouslySetInnerHTML={{ __html: content.description }}
              ></Paragraph>
            </Description>
          </>
        )}
        <ButtonContainer>
          <EMRButton fullSized fontSize={"lg"} mediumWeight mode="cancel">
            Suporte
          </EMRButton>
          <EMRButton
            fullSized
            fontSize={"lg"}
            mediumWeight
            onClick={handleConfirmButton}
          >
            Entendi
          </EMRButton>
        </ButtonContainer>
      </Content>
    </Container>
  );
}
