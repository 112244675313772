import styled from "styled-components";
import { colors } from "../../library/theme";

export const Container = styled.div`
  width: 313px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.interface.white000};
  border: 1px solid ${colors.interface.grey200};
  border-radius: 4px;
  position: relative;

  > input {
    width: 100%;
    height: 48px;
    padding: 0px 36px 0px 16px;
    color: ${colors.interface.grey300};
    font-size: 14px;
    border: none;
    background: none;
  }

  > svg {
    position: absolute;
    right: 16px;
    fill: ${colors.interface.grey200};
  }

  @media (max-width: 640px) {
    width: 100%;
  }
`;
