import React from "react";
import { IoCheckmark } from "react-icons/io5";
import { REGISTER_DEVICE_STEPS } from "../../enum";
import { Line, Step, StepContainer, StepsWrapper } from "./styles";

export default function DeviceStepper({ currentStep }) {
  const steps = [
    {
      step: REGISTER_DEVICE_STEPS.REGISTER_NEW_DEVICE,
      description: "Cadastre seu dispositivo",
    },
    {
      step: REGISTER_DEVICE_STEPS.SECURITY_CONFIRMATION,
      description: "Confirmação de segurança",
    },
    {
      step: REGISTER_DEVICE_STEPS.SUCCESS,
      description: "Continue seus estudos",
    },
  ];

  return (
    <StepsWrapper>
      <Line currentStep={currentStep} />
      {steps.map((step, index) => (
        <StepContainer key={index} isActive={step.step === currentStep}>
          <Step
            currentStep={currentStep}
            hasPassed={steps.findIndex((s) => s.step === currentStep) > index}
            key={step.step}
            isActive={step.step === currentStep}
          >
            <IoCheckmark size={24} />
          </Step>
          <span>{step.description}</span>
        </StepContainer>
      ))}
    </StepsWrapper>
  );
}
