import React, { useCallback } from "react";
import { connect } from "react-redux";
import { signOut } from "../../processes/sessionProcesses";
import { getCurrentUser } from "../../selectors/userSelectors";
import Header from "./Header";

function HeaderContainer(props) {
  const { dispatch } = props;
  const signOutHandler = useCallback(() => signOut(dispatch), [dispatch]);

  return <Header {...props} signOutHandler={signOutHandler} />;
}

function mapStateToProps(state) {
  const { isB2BUserExamInProgress } = state.isB2BUserExamInProgress
  const currentUser = getCurrentUser(state);
  const isFrialUser = state.isFrialUser

  return {
    isB2BUserExamInProgress,
    currentUser,
    isFrialUser
  };
}

export default connect(mapStateToProps)(HeaderContainer);
