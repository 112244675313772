export async function requestIsPrimaryDeviceRegistered(email) {
  const response = await fetch(
    `${process.env.USER_PROXY_URL}/devices/has-primary-device?email=${email}`,
    {
      method: "GET",
      headers: {
        "api-token": process.env.USER_PROXY_API_TOKEN,
        "Content-Type": "application/json",
        "access-date": new Date().toISOString(),
        product: "PLATAFORMA_EMR"
      }
    }
  );

  if (response.status === 200) {
    const responseBody = await response.text();
    return { status: response.status, body: responseBody === "true" };
  } else {
    return { status: response.status, body: false };
  }
}
