import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  background-color: #fcfbd4;
  display: flex;
  align-items: center;
  padding: 16px 32px;
  gap: 16px;
  max-width: 796px;
  border-radius: 4px;
`;
const Text = styled.p`
  font-size: 16px;
  line-height: 24px;
`;

export function Warning({ children }) {
  return (
    <Wrapper>
      <p>💡</p>
      <Text>
        <strong>Importante:</strong> {children}
      </Text>
    </Wrapper>
  );
}
