import * as React from "react";
import { Container, Tooltip } from "./styles";
import {
  AngryFace,
  BadFace,
  HappyFace,
  NeutralFace,
  SatisfiedFace
} from "./icons";

export function Face({
  faceIcon = "neutral",
  tooltipText,
  checked,
  rate,
  onChange
}) {
  const faceMapper = {
    angry: <AngryFace />,
    bad: <BadFace />,
    neutral: <NeutralFace />,
    satisfied: <SatisfiedFace />,
    happy: <HappyFace />
  };

  return (
    <Container checked={checked} onClick={() => onChange(rate)}>
      {faceMapper[faceIcon]}
      <Tooltip>{tooltipText}</Tooltip>
    </Container>
  );
}
