import React from "react";
import { EMRButton } from "../../../Button";
import { Container, ModalContent, TextContainer } from "./styles";

export function DownloadApp({ onClose, isLoading }) {
  function handleAppStore() {
    if (navigator.userAgent.toLowerCase().indexOf("android") > -1) {
      window.location.href =
        "http://play.google.com/store/apps/details?id=com.eumedicoresidente&hl=pt_BR";
    }
    if (navigator.userAgent.toLowerCase().indexOf("iphone") > -1) {
      window.location.href =
        "http://itunes.apple.com/lb/app/emr/id1580470882?mt=8";
    }
  }
  return (
    <Container>
      <ModalContent>
        <TextContainer>
          <b>Para melhor experiência utilize nosso aplicativo</b>
          <span>
            Com nosso aplicativo, você pode aproveitar ao máximo todas as
            funcionalidades em dispositivos móveis, incluindo acesso a aulas
            offline para assistir onde e quando quiser. Além disso, o aplicativo
            contribui para uma jornada mais segura.
          </span>
        </TextContainer>
        <EMRButton fullSized type="submit" onClick={handleAppStore}>
          Acessar aplicativo
        </EMRButton>
      </ModalContent>
    </Container>
  );
}
