import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import DeviceStepper from "../../components/DeviceStepper";
import { ACTIVE_SCREEN, REGISTER_DEVICE_STEPS } from "../../enum";
import RegisterNewDeviceStep from "./steps/RegisterNewDevice";
import { SecurityConfirmationStep } from "./steps/SecurityConfirmation/index";
import SuccessStep from "./steps/Success";
import WelcomeStep from "./steps/Welcome";

export default function RegisterDeviceScreenContainer({ location }) {
  const credentials = {
    email: location.state.email,
    password: location.state.password,
  };
  const history = useHistory();
  const [currentStep, setCurrentStep] = useState(REGISTER_DEVICE_STEPS.WELCOME);
  const steps = {
    [REGISTER_DEVICE_STEPS.WELCOME]: (
      <WelcomeStep advanceStep={handleAdvanceStep} />
    ),
    [REGISTER_DEVICE_STEPS.REGISTER_NEW_DEVICE]: (
      <RegisterNewDeviceStep
        advanceStep={handleAdvanceStep}
        credentials={credentials}
      />
    ),
    [REGISTER_DEVICE_STEPS.SECURITY_CONFIRMATION]: (
      <SecurityConfirmationStep
        advanceStep={handleAdvanceStep}
        credentials={credentials}
        handleToAlternativeAccess={handleToEmergencyAccess}
      />
    ),
    [REGISTER_DEVICE_STEPS.SUCCESS]: <SuccessStep credentials={credentials} />,
  };

  async function handleAdvanceStep() {
    if (currentStep === REGISTER_DEVICE_STEPS.WELCOME) {
      setCurrentStep(REGISTER_DEVICE_STEPS.REGISTER_NEW_DEVICE);
    }
    if (currentStep === REGISTER_DEVICE_STEPS.REGISTER_NEW_DEVICE) {
      setCurrentStep(REGISTER_DEVICE_STEPS.SECURITY_CONFIRMATION);
    } else if (currentStep === REGISTER_DEVICE_STEPS.SECURITY_CONFIRMATION) {
      setCurrentStep(REGISTER_DEVICE_STEPS.SUCCESS);
    }
  }

  function handleToEmergencyAccess() {
    history.replace("/signin-security", {
      activeScreen: ACTIVE_SCREEN.ALTERNATIVE_ACCESS,
      email: location.state.email,
      password: location.state.password,
      credentials: {
        email: location.state.email,
        password: location.state.password,
      },
      fingerprint: location.state.fingerprint,
    });
  }

  return (
    <>
      {currentStep !== REGISTER_DEVICE_STEPS.WELCOME && (
        <DeviceStepper currentStep={currentStep} />
      )}
      {steps[currentStep]}
    </>
  );
}
