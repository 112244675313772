import React, { useCallback } from "react";
import { connect } from "react-redux";
import NotificationModal from "./NotificationModal";
import { getFirstModalNotification } from "../../selectors/notificationSelectors";
import { createNotificationsUser, updateNotificationsUser } from "../../processes/notificationsUserProcesses";
import { getNotificationsUserByNotification } from "../../selectors/notificationsUserSelectors";

function NotificationModalContainer(props) {
  const { dispatch, notificationsUser, notification } = props;

  const confirmationHandler = useCallback(() => {
    if (notificationsUser) {
      updateNotificationsUser(
        { notificationsUser, read: true, filed: true },
        dispatch
      )
    } else {
      createNotificationsUser(
        { notification, read: true, filed: true },
        dispatch
      );
    }
  }, [dispatch, notification, notificationsUser]);

  return (
    <NotificationModal
      confirmationHandler={confirmationHandler}
      notification={notification}
    />
  );
}

function mapStateToProps(state) {
  const notification = getFirstModalNotification(state);

  return {
    notificationsUser: getNotificationsUserByNotification(
      state,
      notification?.id
    ),
    notification,
  };
}

export default connect(mapStateToProps)(NotificationModalContainer);
