import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { createOrUpdateQuestionAnswer } from "../../processes/answerProccess";
import { getAnswer } from "../../selectors/answerSelector";
import { getFormMessage } from "../../selectors/formMessagesSelectors";
import QuestionsListItem from "./QuestionsListItem";

function QuestionsListItemContainer(props) {
  const { answer, dispatch, form } = props;
  const [showValueText, setShowValueText] = useState(false);

  const toogleValueText = useCallback(
    () => setShowValueText(!showValueText),
    [showValueText]
  );

  useEffect(() => {
    answer && setShowValueText(true);
  }, [answer]);

  const onAnswer = useCallback(
    (value) => {
      createOrUpdateQuestionAnswer({ value }, dispatch, props);
    },
    [dispatch, props]
  );

  return (
    <QuestionsListItem
      toogleValueText={toogleValueText}
      showValueText={showValueText}
      onAnswer={onAnswer}
      {...props}
    />
  );
}

function mapStateToProps(state, ownProps) {
  const answer = getAnswer(state, {
    mockId: ownProps.mockId,
    questionId: ownProps.question.id,
  });

  return {
    initialValues: answer,
    answer,
    formMessage: getFormMessage(ownProps.form)(state),
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    onSubmit: createOrUpdateQuestionAnswer,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    destroyOnUnmount: false,
  })(QuestionsListItemContainer)
);
