import ReactHtmlParser from 'react-html-parser';

export function cleanText(text) {
  text = text.replace('Powered by <a href="https://www.froala.com/wysiwyg-editor?pb=1" title="Froala Editor">Froala Editor</a>', "")
  text = text.replaceAll("<strong>", "")
  text = text.replaceAll("</strong>", "")
  text = text.replace(/(style=".+?")/gm, "")
  return text
}

export function getFormattedDescription(text) {
  if (!text) return null
  const elements = ReactHtmlParser(cleanText(text))
  delete elements[Object.keys(elements)[0]];
  return elements
}