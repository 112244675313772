import styled, { css } from 'styled-components';
import { colors } from '../../../../components/library/theme';

const checkBoxVariants = {
  round: css`
    width: 36px;
    height: 36px;

    button {
      border-radius: 20px;
    }
  `,
  square: css`
    width: 24px;
    height: 24px;

    button {
      border-radius: 4px;
    }
  `,
};

export const CheckboxWrapper = styled.div`
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  margin: 0;
  border: none;
  ${(props) => checkBoxVariants[props.variant]};
  width: ${({ hasChildren }) => hasChildren && 'fit-content'};

  label {
    font-size: 12px;
    line-height: 1.5;
    color: ${colors.interface.grey400};
    cursor: pointer;
    user-select: none;

    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  }

  @media (min-width: 640px) {
    label {
      font-size: 14px;
    }
  }
`;

export const Checkbox = styled.button`
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  width: 20px;
  height: 20px;
  padding: 0;
  border: solid 1px
    ${({ isChecked }) =>
      isChecked ? colors.brand.pure : colors.interface.grey300};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 300ms;
  ${({ disabled, isLoading }) =>
    disabled && !isLoading && `border-color: ${colors.interface.grey200};`};
  background-color: #fff;
  ${({ disabled, isLoading }) =>
    disabled && !isLoading && `background-color: ${colors.interface.grey100};`};
  ${({ isChecked }) => isChecked && `background-color: ${colors.brand.pure};`};

  svg {
    fill: ${({ isChecked }) => (isChecked ? '#fff' : 'none')};
    display: ${({ isChecked }) => (isChecked ? 'block' : 'none')};
    min-width: 18px;
    transition: fill 0.3s;
  }

  &:hover {
    background-color: ${({ disabled }) =>
      disabled ? 'none' : colors.medtrack.green000};

    background-color: ${({ isChecked }) => isChecked && colors.brand.pure};

    svg {
      display: block;
      fill: ${({ disabled }) => (disabled ? 'none' : colors.medtrack.green200)};
      fill: ${({ isChecked }) => isChecked && '#fff'};
    }
  }
`;
