import styled from 'styled-components';
import { colors } from '../../../../components/library/theme';

export const Tooltip = styled.div`
  color: ${colors.medtrack.grey500};
  display: none;
  border-radius: 4px;
  border: solid 1px ${colors.interface.grey200};
  position: absolute;
  padding: 8px;
  z-index: 999;
  background-color: ${colors.interface.grey100};
  text-align: left;
  transform: translateY(-50%);
  left: calc(100% + 8px);
  top: 12px;

  ::after {
    content: '';
    display: block;
    height: 10px;
    width: 10px;
    border: 1px solid ${colors.interface.grey200};
    background-color: ${colors.interface.grey100};
    border-top-left-radius: 2px;
    position: absolute;
    border-width: 0 0 1px 1px;
    top: unset;
    right: unset;
    transform: rotate(45deg);
    left: -5px;
    bottom: 38%;
  }
`;

export const TooltipContainer = styled.span`
  position: relative;
  display: flex;
  height: fit-content;

  :hover {
    ${Tooltip} {
      display: block;
    }
  }
`;

export const TooltipTitle = styled.p`
  color: ${colors.interface.grey400};
  font-size: 14px;
  line-height: 21px;
`;
