import React, { useCallback } from "react";
import { connect } from "react-redux";
import Notifier from "./Notifier";
import { dismissNotifier } from "../../processes/notifierProcesses";

function NotifierContainer(props) {
  const { dispatch } = props;
  const onClickHandler = useCallback(() => dispatch(dismissNotifier()), [
    dispatch,
  ]);
  return <Notifier onClickHandler={onClickHandler} {...props} />;
}

function mapStateToProps(state) {
  return {
    title: state.notifier.title,
    message: state.notifier.message,
    type: state.notifier.type,
    visible: state.notifier.visible,
    icon: state.notifier.icon,
  };
}

export default connect(mapStateToProps)(NotifierContainer);
