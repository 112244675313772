import React, { useEffect } from "react";
import { Button, Container } from "semantic-ui-react";
import Link from "./library/Link";
import { spacing } from "./library/theme";
import { signOut } from "../processes/sessionProcesses";
import { connect } from "react-redux";
import Spinner from "./library/Spinner";

const layout = { padding: spacing.m };
const buttonMargin = { marginTop: spacing.m };

function NotFound(props) {
  const currentUrl = window.location.pathname;
  const { dispatch } = props;

  useEffect(() => {
    if (currentUrl === "/signup-v2") {
      signOut(dispatch, true);
    }
  }, []);

  if (currentUrl === "/signup-v2")
    return (
      <Container textAlign="center" style={layout}>
        Você será redirecionado...
      </Container>
    );
  if (currentUrl === "/medtrack") return <Spinner />;

  return (
    <Container textAlign="center" style={layout}>
      <h2>Página Não Encontrada</h2>
      <p>
        O conteúdo que você está tentando acessar não existe, ou você não tem
        acesso.
      </p>
      <Link to="/" component={Button} style={buttonMargin}>
        Ir para página principal
      </Link>
    </Container>
  );
}

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps)(NotFound);
