import React from 'react';
import { colors } from '../../../components/library/theme';

export function UpToDateIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={322}
      height={310}
      fill="none"
    >
      <g clipPath="url(#a)">
        <path
          fill={colors.brand.pure}
          d="M283.9 72.57a96.511 96.511 0 0 0-27.852-25.1c-17.97-10.763-38.249-17.24-58.769-20.824-64.934-11.33-100.528.658-127.375 20.141-17.78 12.934-23.37 40.433-28.622 60.43-2.948 11.224-5.576 22.567-9.242 33.581-5.694 17.097-18.629 30.905-22.94 48.535-4.566 18.602-4.208 38.197 2.113 56.359 12.49 35.876 33.716 56.668 82.42 63.573 26.091 3.708 47.061-7.161 68.71-20.24 13.346-8.062 27.18-15.328 42.495-18.615 16.177-3.466 32.816-3.946 49.038-7.168 36.285-7.2 47.928-41.821 52.644-74.613 5.708-39.703 2.133-83.096-22.62-116.06Z"
        />
        <path
          fill="#fff"
          d="M283.9 72.57a96.511 96.511 0 0 0-27.852-25.1c-17.97-10.763-38.249-17.24-58.769-20.824-64.934-11.33-100.528.658-127.375 20.141-17.78 12.934-23.37 40.433-28.622 60.43-2.948 11.224-5.576 22.567-9.242 33.581-5.694 17.097-18.629 30.905-22.94 48.535-4.566 18.602-4.208 38.197 2.113 56.359 12.49 35.876 33.716 56.668 82.42 63.573 26.091 3.708 47.061-7.161 68.71-20.24 13.346-8.062 27.18-15.328 42.495-18.615 16.177-3.466 32.816-3.946 49.038-7.168 36.285-7.2 47.928-41.821 52.644-74.613 5.708-39.703 2.133-83.096-22.62-116.06Z"
          opacity={0.9}
        />
        <g
          stroke="#000"
          strokeLinejoin="round"
          strokeWidth={0.693}
          opacity={0.2}
        >
          <path d="M167.704 30.86H92.255l-2.677 6.47h78.126v-6.47ZM167.704 37.33H92.255l-2.677 6.473h78.126V37.33ZM167.704 43.803H92.255l-2.677 6.47h78.126v-6.47ZM167.704 50.273H92.255l-2.677 6.473h78.126v-6.473ZM167.704 56.746H92.255l-2.677 6.47h78.126v-6.47ZM167.704 63.216H92.255l-2.677 6.473h78.126v-6.473Z" />
          <path d="M167.704 69.69H92.255l-2.677 6.469h78.126v-6.47ZM167.704 76.159H92.255l-2.677 6.473h78.126V76.16ZM167.704 82.632H92.255l-2.677 6.47h78.126v-6.47ZM167.704 89.102H92.255l-2.677 6.473h78.126v-6.473ZM167.704 95.575H92.255l-2.677 6.47h78.126v-6.47ZM167.704 102.045H92.255l-2.677 6.473h78.126v-6.473ZM167.704 108.518H92.255l-2.677 6.47h78.126v-6.47ZM167.704 114.988H92.255l-2.677 6.473h78.126v-6.473ZM167.704 121.461H92.255l-2.677 6.47h78.126v-6.47ZM167.704 127.931H92.255l-2.677 6.473h78.126v-6.473Z" />
          <path d="M169.306 28.704H88.775V136.56h80.531V28.704Z" />
        </g>
        <g
          stroke="#000"
          strokeLinejoin="round"
          strokeWidth={0.693}
          opacity={0.2}
        >
          <path d="M61.509 229.617H13.98v25.281H61.51v-25.281Z" />
          <path d="M41.6 239.3h-7.718v3.766H41.6V239.3Z" />
          <path d="M63.94 196.762H11.542v64.682H63.94v-64.682Z" />
          <path d="M61.509 200.038H13.98v25.281H61.51v-25.281Z" />
          <path d="M41.6 209.721h-7.718v3.766H41.6v-3.766ZM42.954 192.666H24.268v4.096h18.686v-4.096Z" />
          <path d="M34.424 179.98h-3.25v12.693h3.25V179.98ZM49.86 150.499H14.924v29.474h34.934v-29.474Z" />
        </g>
        <g
          stroke="#000"
          strokeLinejoin="round"
          strokeWidth={0.693}
          opacity={0.2}
        >
          <path d="M258.229 230.822v-54.048a7.817 7.817 0 0 0-.585-2.975 7.772 7.772 0 0 0-1.671-2.522 7.692 7.692 0 0 0-5.454-2.274h-70.453v62.358" />
          <path d="M180.657 169.003h-71.765a7.695 7.695 0 0 0-5.45 2.276 7.806 7.806 0 0 0-2.254 5.495v54.048M265.19 236.009v18.203h-85.089v-23.39h79.962a5.076 5.076 0 0 1 1.968.393 5.13 5.13 0 0 1 2.777 2.81c.256.629.386 1.304.382 1.984Z" />
          <path d="M180.747 230.822h-81.4a5.08 5.08 0 0 0-1.96.393c-.623.26-1.187.64-1.663 1.12a5.189 5.189 0 0 0-1.497 3.653v18.203h86.52" />
          <path d="M94.227 261.801v-7.638h171.109v7.638M101.188 199.807a5.143 5.143 0 0 0-3.73-1.603H81.96a5.125 5.125 0 0 0-1.978.394 5.16 5.16 0 0 0-1.677 1.127 5.207 5.207 0 0 0-1.514 3.681v58.024M280.827 261.052v-57.646a5.22 5.22 0 0 0-1.512-3.678 5.14 5.14 0 0 0-3.65-1.524h-15.497a4.97 4.97 0 0 0-1.939.385" />
        </g>
        <path
          fill={colors.brand.pure}
          stroke="#263238"
          strokeMiterlimit={10}
          strokeWidth={0.693}
          d="M282.065 236.474s-2.76-4.769-4.14-7.352a55.227 55.227 0 0 1-2.564-5.763s3.352 1.987 4.338 2.782c.986.795 5.127 5.763 5.127 5.763l1.183-1.391-1.38-4.571 4.535 5.167 2.168-9.34 3.352 8.943 6.31-7.949.394 7.949 7.69-6.161-2.76 9.738-2.958 4.173h-21.098l-.197-1.988Z"
        />
        <path
          fill={colors.brand.pure}
          stroke="#263238"
          strokeMiterlimit={10}
          strokeWidth={0.693}
          d="M280.882 236.474s-1.774-.794-4.732-4.371a59.932 59.932 0 0 1-5.127-7.353s6.704 3.776 9.07 5.564a41.736 41.736 0 0 1 5.521 5.763s-1.971-4.968-2.169-7.353c-.197-2.384-.394-7.551-.394-7.551s5.718 5.763 6.112 7.353c.395 1.589 2.564 6.955 2.564 6.955a71.719 71.719 0 0 0 1.577-7.353c.394-2.98 3.549-7.75 3.549-7.75s.395 3.379.592 6.757c.197 3.378-1.775 9.737-1.775 9.737s2.564-6.16 5.127-9.141a59.373 59.373 0 0 1 6.507-6.16s-1.972 6.16-2.761 8.942a28.584 28.584 0 0 1-2.958 6.16 83.832 83.832 0 0 1 5.916-6.756 46.398 46.398 0 0 1 6.112-4.173s-2.169 4.173-3.746 6.557a72.283 72.283 0 0 1-4.732 5.962h-24.253v-1.789Z"
        />
        <path
          fill="#fff"
          stroke="#263238"
          strokeMiterlimit={10}
          strokeWidth={0.693}
          d="m308.644 236.077-1.364 12.122-2.342 20.73h-24.71l-2.343-20.73-1.364-12.122h32.123Z"
        />
        <path
          fill={colors.brand.pure}
          stroke="#263238"
          strokeMiterlimit={10}
          strokeWidth={0.693}
          d="m307.28 248.199-2.343 20.73h-24.71l-2.342-20.73h29.395Z"
        />
        <path
          fill="#263238"
          d="M282.46 253.962c.653 0 1.183-.534 1.183-1.193 0-.658-.53-1.192-1.183-1.192-.654 0-1.183.534-1.183 1.192 0 .659.529 1.193 1.183 1.193ZM291.135 253.962c.654 0 1.183-.534 1.183-1.193 0-.658-.529-1.192-1.183-1.192-.653 0-1.183.534-1.183 1.192 0 .659.53 1.193 1.183 1.193ZM285.811 260.122c.654 0 1.184-.534 1.184-1.192 0-.659-.53-1.193-1.184-1.193-.653 0-1.183.534-1.183 1.193 0 .658.53 1.192 1.183 1.192ZM296.262 260.122c.653 0 1.183-.534 1.183-1.192 0-.659-.53-1.193-1.183-1.193-.654 0-1.183.534-1.183 1.193 0 .658.529 1.192 1.183 1.192ZM303.163 260.122c.653 0 1.183-.534 1.183-1.192 0-.659-.53-1.193-1.183-1.193-.654 0-1.183.534-1.183 1.193 0 .658.529 1.192 1.183 1.192ZM299.811 253.962c.653 0 1.183-.534 1.183-1.193 0-.658-.53-1.192-1.183-1.192s-1.183.534-1.183 1.192c0 .659.53 1.193 1.183 1.193ZM282.46 265.09c.653 0 1.183-.534 1.183-1.192 0-.659-.53-1.193-1.183-1.193-.654 0-1.183.534-1.183 1.193 0 .658.529 1.192 1.183 1.192ZM291.135 265.09c.654 0 1.183-.534 1.183-1.192 0-.659-.529-1.193-1.183-1.193-.653 0-1.183.534-1.183 1.193 0 .658.53 1.192 1.183 1.192ZM299.811 265.09c.653 0 1.183-.534 1.183-1.192 0-.659-.53-1.193-1.183-1.193s-1.183.534-1.183 1.193c0 .658.53 1.192 1.183 1.192Z"
        />
        <path
          fill="#B5B5B5"
          d="M164.25 140.348s-5.818-13.201-10.942-6.328c-5.124 6.872 4.161 15.565 11.131 13.879"
        />
        <path
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={0.334}
          d="M164.25 140.348s-5.818-13.201-10.942-6.328c-5.124 6.872 4.161 15.565 11.131 13.879"
        />
        <path
          fill="#263238"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={0.693}
          d="M164.147 107.141s-3.841 2.218-7.501-.183c-3.659-2.4-1.096-3.497-.362-4.976.733-1.478-1.097-1.844-1.467-3.87-.371-2.028.915-3.69 1.648-3.872.734-.183.552-2.218.552-4.244 0-2.027 2.366-3.871 3.47-3.871 1.105 0 1.83-1.471 2.564-3.315.733-1.844 2.2-3.505 4.732-2.385 2.532 1.121 3.841 1.105 5.126-.739 1.286-1.844 4.386-2.21 5.521-1.288 1.136.922 1.641 2.767 3.841.922 2.201-1.844 4.937-1.47 6.215-.182 1.278 1.287 1.096 2.766 3.155 2.026 2.058-.739 5.3.366 6.033 2.584.734 2.218 4.733 4.61 6.223 4.054 1.491-.557 4.023 0 2.737 2.766-1.285 2.766.189 4.61 1.286 4.61 1.096 0 3.478.74 2.192 3.132-1.285 2.392-2.192 4.244-.733 4.976 1.459.731 3.841.922 2.019 4.062-1.822 3.139-3.297 2.949-2.745 4.769.552 1.82 1.641 2.384 0 3.688-1.64 1.304-3.659 1.844-3.659 2.949 0 1.105 2.192 1.653-.371 2.575a20.71 20.71 0 0 1-4.575 1.113s-3.651-9.96-14.086-13.274c-10.434-3.315-16.46-2.957-18.842-1.661-2.382 1.295-2.973-.366-2.973-.366Z"
        />
        <path
          fill="#fff"
          stroke="#263238"
          strokeMiterlimit={10}
          strokeWidth={0.693}
          d="m167.83 174.737-13.408 6.232s-6.932 38.678-6.436 49.409c.497 10.731 6.436 37.43 6.436 37.43h32.211s-4.953-32.693-2.477-44.417c2.477-11.724 8.423-43.917 8.423-43.917s-9.906-2.742-12.879-3.243c-2.973-.501-11.87-1.494-11.87-1.494Z"
        />
        <path
          fill="#263238"
          d="m152.451 192.804 37.068 3.227c-.299 1.59-.599 3.18-.907 4.77l-36.911-3.18c.229-1.685.505-3.299.75-4.817Zm31.721 30.555c.284-1.351.615-2.973.978-4.769l-36.012-3.132a335.057 335.057 0 0 0-.552 4.817l35.586 3.108v-.024Zm3.155-16.072-36.667-3.18c-.236 1.59-.473 3.18-.694 4.817l36.43 3.18c.339-1.542.647-3.148.962-4.785l-.031-.032Zm-39.317 20.062c-.047 1.145-.055 2.154 0 2.997 0 .58.071 1.208.126 1.868l35.342 2.273v-4.864l-35.468-2.274Zm6.641-46.516-.221.104s-.315 1.78-.789 4.682l37.195 3.243c.332-1.805.639-3.426.892-4.77l-37.077-3.259Zm29.971 71.078-33.481-.358a456.2 456.2 0 0 0 .947 4.865l33.125.381c-.158-1.526-.363-3.163-.536-4.825l-.055-.063Zm-34.916-8.012 34.363 2.034a214.433 214.433 0 0 1-.354-4.888l-34.79-2.059c.284 1.614.552 3.267.836 4.944l-.055-.031Z"
        />
        <path
          fill="#B5B5B5"
          stroke="#263238"
          strokeMiterlimit={10}
          strokeWidth={0.693}
          d="M165.851 167.718s-2.721 10.476-2.966 15.969c-.244 5.493 5.442 9.976 13.613 9.229 8.171-.748 9.654-9.73 9.654-11.979 0-2.25.481-21.168.481-21.168a77.894 77.894 0 0 1-8.676 6.487c-4.677 2.988-12.106 1.462-12.106 1.462Z"
        />
        <path
          fill="#263238"
          stroke="#263238"
          strokeMiterlimit={10}
          strokeWidth={0.693}
          d="M173.28 176.732c7.737-3.736 11.594-11.073 13.337-15.731v-1.24a77.801 77.801 0 0 1-8.676 6.487c-4.732 2.996-12.13 1.502-12.13 1.502s-1.696 6.55-2.532 12.002a31.355 31.355 0 0 0 10.001-3.02Z"
        />
        <path
          fill="#B5B5B5"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={0.693}
          d="M199.796 127.688s-.315-11.064-4.393-17.392c-4.078-6.327-12.674-10.198-22.273-8.552-11.909 2.066-17.257 11.064-19.142 18.655-1.885 7.591.316 18.656.316 26.231 0 7.575 8.786 21.183 11.294 23.401 2.508 2.218 6.381 2.949 10.805.692 4.062-2.083 10.742-9.165 14.82-13.275 4.077-4.109 6.507-16.334 7.138-19.18.631-2.846 1.435-10.58 1.435-10.58Z"
        />
        <path
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={0.693}
          d="M166.529 149.182s-.371 2.384 5.118 2.027M164.88 155.445s6.405 4.428 12.809.191"
        />
        <path
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.386}
          d="M177.689 140.694s1.64-2.769 6.404-.374"
        />
        <path
          fill="#263238"
          d="M164.88 139.039c0 1.328-.615 2.385-1.372 2.385-.757 0-1.372-1.073-1.372-2.385 0-1.311.615-2.385 1.372-2.385.757 0 1.372 1.066 1.372 2.385Z"
        />
        <path
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={0.693}
          d="M159.028 131.48s1.648-3.688 7.098-1.844M175.307 131.154s5.126-2.766 10.797 1.47"
        />
        <path
          fill="#263238"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={0.693}
          d="M173.13 101.744c-11.909 2.066-17.257 11.064-19.142 18.655a27.269 27.269 0 0 0-.694 5.135c.616-1.931 3.81-10.405 12.864-12.718 10.253-2.575 19.213.739 22.32 5.167 3.108 4.427 2.366 10.142 3.478 12.909.694 1.748 4.18 4.387 6.649 6.088.568-3.394 1.191-9.331 1.191-9.331s-.315-11.065-4.393-17.392c-4.078-6.327-12.674-10.159-22.273-8.513Z"
        />
        <path
          fill={colors.brand.pure}
          stroke="#263238"
          strokeMiterlimit={10}
          strokeWidth={0.693}
          d="M158.673 243.485a566.508 566.508 0 0 0 4.117 25.031h-13.526s-3.462-17.972-7.674-27.948c-2.366-5.675-2.942-7.949-2.981-8.879-1.838 4.412-13.29 31.668-15.34 32.59-2.232.994-10.403-.795-13.408-1.749-3.005-.954-3.21-5.238-2.713-6.987.496-1.748 21.294-52.405 23.266-55.641a43.904 43.904 0 0 0 4.212-10.484c.244-1.494-.253-4.992 1.979-6.232 1.349-.755 10.829-2.965 18.141-4.602a913.339 913.339 0 0 1 8.62-1.892s-5.915 22.201-6.925 37.923c-.434 6.971.789 18.354 2.232 28.862.008 0 .008 0 0 .008Z"
        />
        <path
          fill={colors.brand.pure}
          stroke="#263238"
          strokeMiterlimit={10}
          strokeWidth={0.693}
          d="M158.673 243.477c-1.703-2.822-8.612-14.935-9.535-27.55-.828-11.319 3.652-29.895 5.623-37.359 4.78-1.073 8.621-1.892 8.621-1.892s-5.931 22.217-6.941 37.939c-.434 6.972.757 18.354 2.232 28.862Z"
        />
        <path
          stroke="#263238"
          strokeMiterlimit={10}
          strokeWidth={0.693}
          d="M140.517 205.538c.616-2.695 1.073-4.149 1.073-4.149M138.869 231.085s-1.491-5.246-.252-14.721a130.93 130.93 0 0 1 1.041-6.645"
        />
        <path
          fill={colors.brand.pure}
          stroke="#263238"
          strokeMiterlimit={10}
          strokeWidth={0.693}
          d="M247.804 233.271c-5.694-.254-34.166-18.131-34.166-18.131s-8.92 17.614-9.165 21.191c-.244 3.577 6.933 32.185 6.933 32.185h-30.452s-.923-10.04-1.112-21.048c-.087-5.08 0-10.334.371-14.968 1.238-14.554 6.183-55.641 6.183-55.641l4.961.509s1.404.477 3.604 1.248c5.86 2.05 17.352 6.247 22.391 9.22 6.925 4.086 16.563 10.731 21.295 13.799 4.733 3.068 15.845 9.96 18.069 19.157 2.225 9.196-3.217 12.733-8.912 12.479Z"
        />
        <path
          stroke="#263238"
          strokeMiterlimit={10}
          strokeWidth={0.693}
          d="M194.961 178.584c-.67 10.222-2.642 34.569-6.893 46.349a3428.24 3428.24 0 0 1-8.226 22.535c-.087-5.08 0-10.334.371-14.968 1.238-14.554 6.183-55.641 6.183-55.641l4.961.509s1.404.445 3.604 1.216ZM213.638 215.132l-11.389-3.569M197.438 203.829c.331 3.776 1.364 7.949 4.07 10.286M197.54 195.984a39.732 39.732 0 0 0-.244 4.308M196.097 209.258s2.721 5.111 5.939 6.9"
        />
        <path
          fill="#B5B5B5"
          stroke="#263238"
          strokeMiterlimit={10}
          strokeWidth={0.693}
          d="M213.157 172.678a24.8 24.8 0 0 1-.71-5.723c0-3.076.71-4.809-.402-4.706-1.113.104-3.053 1.534-3.66 3.975-.607 2.44.71 4.705.915 5.524.124.502.193 1.017.205 1.534 0 0-8.833.302-10.458.302s-3.25.922-3.25 2.456c0 1.534 1.728 2.385 1.728 2.385l.788.413s-1.017 1.94-.307 2.965a6.63 6.63 0 0 0 1.427 1.534 2.229 2.229 0 0 0-.205 2.146c.263.697.72 1.302 1.317 1.741 0 0-1.317 1.232.41 2.87 1.728 1.637 5.482 1.74 8.116 1.74 2.634 0 4.874.096 4.874.096l1.12.103 9.338 14.022 8.834-9.928s-12.998-12.718-14.725-14.936c-1.727-2.218-5.355-8.513-5.355-8.513Z"
        />
        <path
          fill={colors.brand.pure}
          stroke="#263238"
          strokeMiterlimit={10}
          strokeWidth={0.693}
          d="m232.945 194.942-12.13 11.478s11.144 14.976 17.083 22.503c5.939 7.528 16.839 8.744 21.539 4.984 4.701-3.76 5.206-12.225.994-17.487-4.211-5.262-27.486-21.478-27.486-21.478Z"
        />
        <path
          fill="#B5B5B5"
          stroke="#263238"
          strokeMiterlimit={10}
          strokeWidth={0.693}
          d="M151.772 255.233s17.809 3.1 19.473 3.1c1.664 0 19.237-4.546 20.42-3.592 1.183.953 9.022 7.662 10.923 9.578a16.426 16.426 0 0 1 2.847 3.831h-16.381l-4.985-1.2s-4.227 1.971-6.601 1.971c-2.374 0-10.324-.461-10.324-.461l-13.992-.23s-4.708-7.973-1.38-12.997Z"
        />
        <path
          stroke="#263238"
          strokeMiterlimit={10}
          strokeWidth={0.693}
          d="M194.038 268.158s-4.038-4.547-5.694-5.747a12.043 12.043 0 0 0-3.289-1.693M199.504 268.397s-7.839-8.617-8.786-9.34a14.758 14.758 0 0 0-3.328-1.431"
        />
        <path
          fill={colors.brand.pure}
          d="M112.826 247.094s-7.098 5.747-8.312 9.538c-1.215 3.792.473 8.378 4.274 9.817 3.802 1.439 46.202 2.218 46.202 2.218-.694.326-3.801-2.607-2.035-6.526 1.199-2.655 4.512-6.462 4.512-6.462l-35.381-9.094"
        />
        <path
          stroke="#263238"
          strokeMiterlimit={10}
          strokeWidth={0.693}
          d="M112.826 247.094s-7.098 5.747-8.312 9.538c-1.215 3.792.473 8.378 4.274 9.817 3.802 1.439 46.202 2.218 46.202 2.218-.694.326-3.801-2.607-2.035-6.526 1.199-2.655 4.512-6.462 4.512-6.462l-35.381-9.094"
        />
        <path
          fill="#B5B5B5"
          d="M195.393 137.434s4.586-12.055 6.305-3.444c.574 10.907-3.439 13.204-7.452 15.242"
        />
        <path
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={0.334}
          d="M195.393 137.434s4.586-12.055 6.305-3.444c.574 10.907-3.439 13.204-7.452 15.242"
        />
        <path
          fill="#263238"
          stroke="#263238"
          strokeMiterlimit={10}
          strokeWidth={0.693}
          d="M172.641 267.045H27.127v1.884H172.64v-1.884Z"
        />
        <path
          fill="#fff"
          stroke="#263238"
          strokeMiterlimit={10}
          strokeWidth={0.693}
          d="M119.854 268.929H29.099l-8.392-63.606h90.755l8.392 63.606Z"
        />
        <path
          fill="#fff"
          stroke="#263238"
          strokeMiterlimit={10}
          strokeWidth={0.693}
          d="M118.008 268.929H27.261l-8.392-63.606h90.747l8.392 63.606Z"
        />
        <path
          fill="#263238"
          stroke="#263238"
          strokeMiterlimit={10}
          strokeWidth={0.693}
          d="M34.272 268.929h83.46l-6.309-48.01a563.767 563.767 0 0 0-39.6 27.82 126.783 126.783 0 0 1-37.55 20.19Z"
        />
        <path
          fill="#fff"
          stroke="#263238"
          strokeMiterlimit={10}
          strokeWidth={0.693}
          d="M65.552 242.078c3.293 0 5.962-2.484 5.962-5.548s-2.67-5.548-5.962-5.548c-3.293 0-5.963 2.484-5.963 5.548s2.67 5.548 5.963 5.548Z"
        />
        <path
          stroke="#263238"
          strokeMiterlimit={10}
          strokeWidth={0.693}
          d="M-42.744 268.929H321.5"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M.5 0h321v310H.5z" />
        </clipPath>
      </defs>
    </svg>
  );
}
