import styled from "styled-components";

export const Title = styled.h2`
  font-weight: 600;
  font-size: 18px;
  line-height: 1.3;
  margin: 0;
`
export const Subtitle = styled.p`
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 16px;
`
export const ButtonWrapper = styled.fieldset`
  border: 0;
  margin-top: auto;
  padding: 0;
`
export const ResetPasswordForm = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 auto;
  max-width: 460px;
  width: 100%;
  max-height: 840px;

  & > div:first-of-type {
    margin-bottom: 16px;
  }
`
