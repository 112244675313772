import React, { useEffect, useState } from 'react'
import RegisterFinalStep from './RegisterFinalStep'
import RegisterLoadingStep from './RegisterLoadingStep'
import { connect } from "react-redux";
import { Congratulations } from './Congratulations';
import { getCurrentUser } from '../../../selectors/userSelectors';
import { getCookie } from "cookies-next";

function Register(props) {
  const [isPaymentAproved, setIsPaymentAproved] = useState(false)
  const [registerStep, setRegisterStep] = useState("initial")
  const [stopChecking, setStopChecking] = useState(false)
  const [isFrialCheckout, setIsFrialCheckout] = useState()

  function handleError() {
    setRegisterStep("error")
    setStopChecking(true)
  }

  function handleWaiting() {
    setRegisterStep("waiting")
  }

  function handleSuccess() {
    setRegisterStep("success")
    setStopChecking(true)

    setTimeout(() => {
      setIsPaymentAproved(true)
    }, 3000)
  }

  async function checkPayment() {
    if (stopChecking) return;
    const urlParams = new URLSearchParams(window.location.search);
    const marketplace_id = urlParams.get("transactioncode")
    const marketplace_name = urlParams.get("marketplacename")
    const email = urlParams.get("email")

    const responseJson = await fetch("/guru/payment", {
      method: "POST",
      body: JSON.stringify({ marketplace_id, marketplace_name, email }),
      headers: {
        'Content-Type': 'application/json'
      }
    })

    const response = await responseJson.json()
    if (response.error) handleError()
    if (response.is_payment_valid) handleSuccess()
  }

  useEffect(() => {
    let paymentInterval;

    if (!stopChecking) {
      paymentInterval = setInterval(checkPayment, 5000);
    } else {
      clearInterval(paymentInterval);
    }

    return () => {
      clearInterval(paymentInterval);
    };
  }, [stopChecking]);

  useEffect(() => {
    let loadingInterval;

    if (!stopChecking) {
      loadingInterval = setInterval(handleWaiting, 20000);
    } else {
      clearInterval(loadingInterval);
    }

    return () => {
      clearInterval(loadingInterval);
    };
  }, [stopChecking]);

  useEffect(() => {
    const _isFrialCheckout = getCookie("isFrialCheckout", {
      domain: process.env.DOMAIN_URL,
    });
    setIsFrialCheckout(_isFrialCheckout)
  }, [isFrialCheckout])

  useEffect(() => {
    setInterval(() => handleError(), 30000)
  }, [])

  const finalStep = isFrialCheckout ? <Congratulations user={props.user} /> : <RegisterFinalStep />

  return (
    <>
      {isPaymentAproved
        ? finalStep
        : <RegisterLoadingStep step={registerStep} {...props} />
      }
    </>
  )
}

function mapStateToProps(state) {
  const isFrialUser = state?.isFrialUser
  const user = getCurrentUser(state);
  return {
    isFrialUser,
    user
  };
}

export default connect(mapStateToProps)(Register);
