import { apiGet } from "./helpers/api";
import { normalize, schema } from "normalizr";
import toQueryString from "../utils/toQueryString";

const statisticSchema = new schema.Entity("statistics");

export const fetchStatistics = (dispatch, params) => {
  const filters = toQueryString(params);
  return apiGet(`/api/v1/statistics?${filters}`).then((response) => {
    dispatch({
      type: "STATISTICS_FETCHED",
      ...normalize(response.body.data, new schema.Array(statisticSchema)),
    });
  });
};
