import styled, { css } from 'styled-components';
import { colors } from '../../../../../components/library/theme';

export const CheckboxContainer = styled.div(
  ({ isChecked }) => css`
    width: 100%;
    padding: 13px 16px;
    margin: 16px 0;
    border-radius: 4px;
    border: 1px solid
      ${isChecked ? colors.medtrack.green150 : colors.medtrack.grey100};
    background-color: ${isChecked ? colors.medtrack.green100 : 'transparent'};
    display: flex;
    align-items: center;
    gap: 8px;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    * {
      cursor: pointer;
    }
  `
);

export const Text = styled.label`
  color: ${colors.medtrack.grey500};
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: baseline;
  gap: 16px;
  line-height: 1.5;
  user-select: none;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
  }
`;

export const StyledCheckbox = styled.label(
  ({ isChecked }) => css`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid
      ${isChecked ? colors.medtrack.green200 : colors.medtrack.grey300};
    background-color: ${isChecked ? colors.medtrack.green200 : 'transparent'};
    transition: all 0.2s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    svg {
      display: ${isChecked ? 'flex' : 'none'};
    }
  `
);

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  display: none;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 16px;

  span {
    color: ${colors.medtrack.grey400};
    font-size: 14px;
    font-weight: 400;
  }
`;
