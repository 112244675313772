import React from "react";
import { connect } from "react-redux";
import MockTemplatesMedtrack from "./MockTemplatesMedtrack";

function MockTemplatesMedtrackContainer(props) {
  return <MockTemplatesMedtrack {...props} />;
}

function mapStateToProps(state) {
  return {
    activeMedtrackType: state.isMedTrackFirstAccess.activeMedtrackType,
  };
}

export default connect(mapStateToProps)(MockTemplatesMedtrackContainer);
