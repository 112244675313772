import React from "react";
import { Modal } from "semantic-ui-react";
import { Widget } from "react-typeform-embed";

export default function SurveyUserModal({
  survey,
  openModal,
  closeModal,
  currentUser,
}) {
  return (
    <Modal open={openModal} onClose={closeModal} closeIcon>
      <Modal.Content>
        <Widget
          id={survey.code}
          height={500}
          width="100%"
          iframeProps={{ height: "100%", width: "100%" }}
          hidden={{ user_id: currentUser.id, survey_id: survey.id }}
        />
      </Modal.Content>
    </Modal>
  );
}
