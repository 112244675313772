const getBanners = (action) => action.entities.banners;

export default function bannersReducer(state = {}, action) {
  switch (action.type) {
    case "BANNERS_FETCHED":
      return { ...state, ...getBanners(action) };
    default:
      return state;
  }
}
