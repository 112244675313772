import React from "react";
import { Container, AnnotationText } from "./styles";
import { connect } from "react-redux";
import { SelectedAlternative } from "../Alternative/SelectedAlternative";
import { getFormattedDescription } from "../QuestionModal/getFormattedDescription";

function getColor(value) {
  if (value < 50) return "#FF5B5B";
  if (value < 90) return "#f2711c";
  return "#21ba45";
}

function getAnswer(value) {
  if (value < 50) return "Na questão dissertativa, você apresentou mais erros.";
  if (value < 90)
    return "Na questão dissertativa, você apresentou empate nas respostas.";
  return "Na questão dissertativa, você apresentou mais acertos.";
}

function AlternativeContainer({ answer, title, question_type }) {
  return (
    <Container>
      <AnnotationText>{getFormattedDescription(title)}</AnnotationText>

      {question_type === "alternatives" ? (
        <SelectedAlternative
          alternative={{
            type: answer.is_correct ? "rightAnswer" : "wrongAnswer",
            title: answer.content,
            letter: answer.alternative_letter
          }}
        />
      ) : (
        <>
          <SelectedAlternative
            alternative={{
              type: "custom",
              customColor: getColor(answer.percent_score),
              title: getAnswer(answer.percent_score),
              letter: " "
            }}
          />
        </>
      )}
    </Container>
  );
}

export default connect()(AlternativeContainer);
