import React, { useEffect, useState } from "react";
import { NotifierDropdownItem } from "./NotifierDropdownItem";
import { NotifierEmptyData } from "./NotifierEmptyData";

import {
  NotifierDropdownContainer,
  NotifierDropdownFooter,
  NotifierDropdownFooterText,
  NotifierDropdownHeader,
  NotifierDropdownNotificationCount,
  NotifierDropdownScrollArea,
  NotifierDropdownScrollAreaCorner,
  NotifierDropdownScrollAreaItemContent,
  NotifierDropdownScrollAreaScrollbar,
  NotifierDropdownScrollAreaThumb,
  NotifierDropdownScrollAreaViewport,
  NotifierDropdownTitle,
} from "./styles";

import { useClickInOut } from "../../../../hooks/useClickInOut";

/**
 * NotifierDropdown Component
 * @param {Object} props - The props of the component
 * @param {number | undefined} props.notificationsCount
 * @param {Array} props.notificationsData
 * @param {function} props.onClose
 * @param {function} props.onNotificationClick
 *
 * @returns {JSX.Element} - The NotifierDropdown component
 */

export function NotifierDropdown({
  notificationsCount,
  notificationsData,
  onClose,
  onNotificationClick = () => {},
}) {
  const [notifierRef, setNotifierRef] = useState({
    current: null,
  });
  const { ref, isClickedOutside } = useClickInOut({
    ignoredRefs: [notifierRef],
  });
  const notificationsCountText =
    notificationsCount >= 9 ? "+9" : String(notificationsCount);

  const hasNotificationsCount = notificationsCount > 0;

  useEffect(() => {
    if (isClickedOutside && onClose) {
      onClose();
    }
  }, [isClickedOutside]);

  useEffect(() => {
    if (!notifierRef.current) {
      const notifier = document.getElementById("notifier-icon");

      setNotifierRef({ current: notifier });
    }
  }, [notifierRef]);

  return (
    <NotifierDropdownContainer ref={ref}>
      <NotifierDropdownHeader>
        <NotifierDropdownTitle>Notificações</NotifierDropdownTitle>
        {hasNotificationsCount && (
          <NotifierDropdownNotificationCount>
            {notificationsCountText}
          </NotifierDropdownNotificationCount>
        )}
      </NotifierDropdownHeader>

      {notificationsData.length > 0 ? (
        <NotifierDropdownScrollArea type="always">
          <NotifierDropdownScrollAreaViewport>
            <NotifierDropdownScrollAreaItemContent>
              <>
                {notificationsData.map((notificationData) => (
                  <NotifierDropdownItem
                    {...notificationData}
                    key={notificationData.id}
                    to={notificationData.link}
                    onClick={() => onNotificationClick(notificationData.id)}
                  />
                ))}
              </>
            </NotifierDropdownScrollAreaItemContent>
          </NotifierDropdownScrollAreaViewport>

          <NotifierDropdownScrollAreaScrollbar orientation="vertical">
            <NotifierDropdownScrollAreaThumb />
          </NotifierDropdownScrollAreaScrollbar>
          <NotifierDropdownScrollAreaCorner />
        </NotifierDropdownScrollArea>
      ) : (
        <NotifierEmptyData />
      )}

      <NotifierDropdownFooter>
        <NotifierDropdownFooterText to="/notifications">
          Ver todas as notificações
        </NotifierDropdownFooterText>
      </NotifierDropdownFooter>
    </NotifierDropdownContainer>
  );
}
