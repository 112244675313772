import { getSession } from '../processes/sessionProcesses';


export async function handleDownload(fileURL, fileName) {
  const materialUrl = fileURL;

  try {
    const response = await fetch(`/api/v1/download?file_path=${materialUrl}`,{
      method: 'GET',
      headers: {
        'Client': getSession().headers.client,
        'Access-Token': getSession().headers['access-token'],
        'Uid': getSession().headers.uid,
      }
    });

    const blobText = await response.blob();

    const url = window.URL.createObjectURL(blobText);

    const link = document.createElement('a');
    link.href = url;

    link.setAttribute('download', `${fileName}.pdf`);
    document.body.appendChild(link);
    link.click();

    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);
  } catch (error) {
    console.error('Error no download:', error);
  }
}
