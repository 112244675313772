import React from "react";
import { Grid } from "semantic-ui-react";
import makeDropDownOptions from "../../utils/makeDropDownOptions";
import { DropDownField, Form } from "../library/forms";
import MockModalFooter from "./MockModalFooter";

const makeYearsDropdownOptions = (years) =>
  years.map((year) => ({ text: year, value: year }));

export default function MockModalFormExamFields(props) {
  const {
    states,
    organizers,
    organizerIdEq,
    years,
    mockTemplates,
    yearEq,
    error,
    reset,
  } = props;

  return (
    <Form error={error}>
      <Grid>
        <Grid.Column computer={6} mobile={16}>
          <DropDownField
            name="stateIdEq"
            label="Estado"
            options={makeDropDownOptions(states)}
            onChange={reset}
            placeholder="Selecione um Estado"
            fluid
            inline
            search
          />
        </Grid.Column>
        <Grid.Column computer={6} mobile={16}>
          <DropDownField
            name="organizerIdEq"
            label="Banca"
            options={makeDropDownOptions(organizers)}
            placeholder="Selecione uma Banca"
            required
            fluid
            inline
            search
          />
        </Grid.Column>
        <Grid.Column computer={4} mobile={16}>
          <DropDownField
            name="yearEq"
            label="Ano"
            disabled={!organizerIdEq}
            options={makeYearsDropdownOptions(years)}
            placeholder="Selecione um Ano"
            required
            fluid
            inline
          />
        </Grid.Column>
        <Grid.Column width={16}>
          <DropDownField
            name="mockTemplateId"
            label="Provas disponíveis"
            options={makeDropDownOptions(mockTemplates)}
            disabled={mockTemplates?.length == 0 || !organizerIdEq || !yearEq}
            placeholder="Selecione uma prova"
            hint={mockTemplates?.length == 0 && "Nenhuma prova disponível..."}
            fluid
            inline
            search
          />
        </Grid.Column>
      </Grid>
      <MockModalFooter {...props} />
    </Form>
  );
}
