import React, { useEffect, useState } from "react";
import { IoArrowBack, IoCheckmarkDone } from "react-icons/io5";
import { useHistory } from "react-router-dom";
import { makeIsMobile } from "../../utils/useScreenWidth";
import { NotificationItem, UpToDate } from "./components";
import * as services from "./services";
import * as S from "./styles";
import { connect } from "react-redux";
import { setUnreadCount } from "../../reducers/notificationsReducer";

function NotificationsPage(props) {
  const history = useHistory();
  const isMobile = makeIsMobile();
  const [paginationData, setPaginationData] = useState();
  const [unreadCount, setUnreadCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [activeFilters, setActiveFilters] = useState({
    unread: false,
    currentPage: 1,
    size: 10,
  });

  const shouldDisableButton = notifications.every(
    (notification) => notification.wasRead === true
  );
  async function getNotifications() {
    try {
      const { notifications, pagination } = await services.getNotifications({
        size: activeFilters.size,
        onlyUnread: activeFilters.unread,
        page: activeFilters.currentPage,
      });
      setPaginationData(pagination);
      if (activeFilters.currentPage > 1) {
        return setNotifications((prev) => [...prev, ...notifications]);
      }
      setNotifications(notifications);
    } catch (err) {
      console.error(err);
    }
  }

  async function handleDeleteNotification(id) {
    try {
      await services.deleteNotification(id);
      getNotifications();
    } catch (err) {
      console.error(err);
    }
  }
  async function handlePinNotification(id) {
    try {
      await services.pinNotification(id);
      getNotifications();
    } catch (err) {
      console.error(err);
    }
  }

  async function getUnreadCount() {
    try {
      const count = await services.getUnreadNotificationsCount();
      setUnreadCount(count);
    } catch (err) {
      console.error(err);
    }
  }

  async function handleMarkAllAsRead() {
    if (!notifications.length || unreadCount === 0) return;
    try {
      await services.markAllAsRead();
      getNotifications();
      props.setUnreadCount(0);
    } catch (err) {
      console.error(err);
    }
  }
  function handleUnreadFilter(isMarkedAsUnread) {
    setActiveFilters((prev) => ({ ...prev, unread: isMarkedAsUnread }));
  }

  function onScroll() {
    if (paginationData?.totalRecords === notifications?.length) return;

    const scrollTop = document.documentElement.scrollTop;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const reachedBottom = scrollTop + windowHeight >= documentHeight;

    if (reachedBottom) {
      setActiveFilters((prev) => ({
        ...prev,
        currentPage: prev.currentPage + 1,
      }));
    }
  }

  function handleClickOnNotification(notificationId) {
    const notificationsCopy = [...notifications];
    const notificationIndex = notificationsCopy.findIndex(
      (notification) => notification.id === notificationId
    );
    const notification = notificationsCopy[notificationIndex];
    if (notification.wasRead) return;

    try {
      services.readNotification(notificationId);
      notificationsCopy[notificationIndex] = { ...notification, wasRead: true };
      setNotifications(notificationsCopy);
      props.setUnreadCount(props.unreadCount - 1);
    } catch (err) {
      console.error("Error on notifications", err);
    }
  }

  useEffect(() => {
    getNotifications();
  }, [activeFilters]);

  useEffect(() => {
    getNotifications();
    getUnreadCount();
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", onScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <S.PageWrapper>
      <S.Heading onClick={() => history.goBack()}>
        <IoArrowBack size={20} />
        <h1>Notificações</h1>
      </S.Heading>

      <S.Divider />

      <S.ButtonsWrapper>
        <S.FiltersWrapper>
          <S.Filter
            variant="small"
            onClick={() => handleUnreadFilter(false)}
            isActive={!activeFilters.unread}
          >
            Todas
          </S.Filter>
          <S.Filter
            onClick={() => handleUnreadFilter(true)}
            isActive={activeFilters.unread}
          >
            Não lidas
            {props.unreadCount > 0 && (
              <S.CountIndicator isActive={activeFilters.unread}>
                {props.unreadCount}
              </S.CountIndicator>
            )}
          </S.Filter>
        </S.FiltersWrapper>

        <S.MarkAsReadBtn
          disabled={shouldDisableButton}
          onClick={handleMarkAllAsRead}
        >
          <IoCheckmarkDone size={24} />
          <span>{isMobile ? "Todas lidas" : "Marcar todas como lidas"}</span>
        </S.MarkAsReadBtn>
      </S.ButtonsWrapper>

      {notifications.length ? (
        <S.NotificationsList>
          {notifications?.map((notification) => (
            <NotificationItem
              key={notification.id}
              {...notification}
              onClick={() => handleClickOnNotification(notification.id)}
              handlePin={() => handlePinNotification(notification.id)}
              handleDelete={() => handleDeleteNotification(notification.id)}
            />
          ))}
        </S.NotificationsList>
      ) : (
        <UpToDate />
      )}
    </S.PageWrapper>
  );
}

const mapStateToProps = (state) => ({
  unreadCount: state.entities.notificationsForUsers.unreadCount,
});

const mapDispatchToProps = {
  setUnreadCount,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsPage);
