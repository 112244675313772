const getStatistics = (action) => action.entities.statistics;

export default function statisticsReducer(state = {}, action) {
  switch (action.type) {
    case "STATISTICS_FETCHED":
      return { ...state, ...getStatistics(action) };
    default:
      return state;
  }
}
