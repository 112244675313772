import styled from "styled-components";
import { colors } from "../../components/library/theme";

export const PageWrapper = styled.div``;

export const Heading = styled.button`
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: fit-content;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: ${colors.interface.grey400};

  h1 {
    margin: 0;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
  }

  @media (max-width: 435px) {
    padding: 16px;
  }
`;

export const NotificationsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: center;
  padding: 24px;
  padding-top: 0;

  @media (max-width: 435px) {
    padding: 16px;
  }
`;

export const Divider = styled.hr`
  border: 1px solid ${colors.interface.grey200};
  border-bottom: 0;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;

  @media (max-width: 435px) {
    padding: 16px;
  }
`;

export const FiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;

  @media (max-width: 435px) {
    gap: 8px;
  }
`;

export const Filter = styled.button`
  transition: "colors" 300ms;
  min-width: 128px;
  height: 32px;
  font-weight: ${({ isActive }) => (isActive ? "600" : "400")};
  color: ${({ isActive }) =>
    isActive ? colors.interface.white000 : colors.interface.grey400};
  background: ${({ isActive }) =>
    isActive ? colors.brand.pure : colors.interface.grey150};
  border-radius: 4px;
  border: 0;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  @media (max-width: 640px) {
    min-width: ${({ variant }) => (variant === "small" ? "96px" : "120px")};
  }
`;

export const MarkAsReadBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: ${colors.brand.pure};
  font-size: 14px;
  font-weight: 600;
  background-color: transparent;
  border: 0;
  cursor: pointer;

  &:hover:not(:disabled) {
    span {
      text-decoration: underline;
    }
  }

  &:disabled {
    opacity: 60%;
    cursor: not-allowed;
  }

  @media (max-width: 435px) {
    font-size: 12px;
  }
`;

export const CountIndicator = styled.span`
  min-width: 20px;
  min-height: 20px;
  border-radius: 999px;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ isActive }) =>
    isActive ? colors.medtrack.green150 : colors.interface.grey200};
  color: ${({ isActive }) =>
    isActive ? colors.interface.white000 : colors.interface.grey400};
`;
