import styled from 'styled-components';
import { colors, spacing } from '../../../components/library/theme';

export const CardText = styled.span`
  color: ${colors.interface.grey400};
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  max-width: 190px;
  text-align: left;

  @media (max-width: 768px) {
    max-width: unset;
  }
`;
export const CardContainer = styled.button`
  background-color: #fff;
  border: solid 1px ${colors.brand.pure};
  border-radius: ${spacing.xs};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  transition: 200ms;
  width: 100%;

  &:hover {
    box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
      0px 12px 16px -4px rgba(16, 24, 40, 0.08);
    border: solid 1px ${colors.brand.dark000};
  }

  @media (max-width: 768px) {
    align-items: center;
    font-size: 16px;
    justify-content: center;
    height: 136px;
  }
`;
export const Title = styled.p`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 16px;

  @media (max-width: 768px) {
    font-size: 24px;
    text-align: center;
  }
`;
export const CardsWrapper = styled.div`
  display: flex;
  gap: 24px;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 16px;
  }
`;
export const IconWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
`;
