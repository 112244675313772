import React, { useEffect, useState } from "react";
import { connect, shallowEqual } from "react-redux";
import { fetchMockTemplates } from "../../processes/mockTemplateProccesses";
import { getCurrentClassroomId } from "../../selectors/classroomSelectors";
import { getLevelGroupsByClassroom } from "../../selectors/levelGroupSelectors";
import ClassroomContents from "./ClassroomContents";

function ClassroomContentsContainer(props) {
  const { dispatch, currentClassroomId } = props;
  const filters = { fromClassrooms: currentClassroomId };
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await fetchMockTemplates(filters, dispatch);
      setLoading(false);
    };
    fetchData();
  }, [JSON.stringify(filters)]);

  return <ClassroomContents loading={loading} {...props} />;
}

function mapStateToProps(state) {
  return {
    levelGroups: getLevelGroupsByClassroom(state),
    currentClassroomId: getCurrentClassroomId(state),
  };
}

const areStatesEqual = (next, prev) =>
  shallowEqual(next.entities.levelGroups, prev.entities.levelGroups) &&
  shallowEqual(next.currentClassroomId, prev.currentClassroomId);

export default connect(mapStateToProps, null, null, {
  pure: true,
  areStatesEqual,
})(ClassroomContentsContainer);
