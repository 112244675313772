import { createSelector } from "reselect";

const getSubjects = (state) => state.entities.subjects;
const getSpecialityIds = (_, specialityIds) => specialityIds;
const getReportFetchedIds = (state) => state.subjectReportList.subjectReportIds;

const getAllSubjects = createSelector(getSubjects, (subjects) =>
  Object.values(subjects)
);

export const getSubjectsFromSpecialities = createSelector(
  getSubjects,
  getSpecialityIds,
  (subjects, specialityIds) =>
    subjects &&
    Object.values(subjects).filter(
      (subject) =>
        !specialityIds ||
        specialityIds?.includes("") ||
        specialityIds.length === 0 ||
        specialityIds?.includes(subject.specialityId)
    )
);

export const getFetchedSubjectsForReport = createSelector(
  getAllSubjects,
  getReportFetchedIds,
  (subjects, ids) =>
    subjects.filter(
      (subject) => !!subject?.userSuccessRate && ids.includes(subject?.id)
    )
);
