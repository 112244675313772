import React from "react";
import {
  GynecAndObstetricsIcon,
  MedicalClinicIcon,
  PediatricsIcon,
  PreventiveMedicineIcon,
  SurgeryIcon
} from "../icons";

export function getBigAreaIcons(bigArea) {
  if (bigArea === "gynecAndObstetrics") return <GynecAndObstetricsIcon />;
  if (bigArea === "medicalClinic") return <MedicalClinicIcon />;
  if (bigArea === "pediatrics") return <PediatricsIcon />;
  if (bigArea === "preventiveMedicine") return <PreventiveMedicineIcon />;
  if (bigArea === "surgery") return <SurgeryIcon />;
}
