import styled from 'styled-components'
import { colors } from '../../../../../components/library/theme'

export const Container = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;

    p {
        font-size: 14px;
        color: ${colors.medtrack.grey400};
        user-select: none;
    }
`