import React from "react";
import { Checkbox } from "../Checkbox";
import { Paragraph } from "../Paragraph";
import { Container, CheckboxLabel } from "./styles";

export function RevisionBox({ order, revision, day, checked }) {
  return (
    <Container>
      <Paragraph variant="paragrafo02">{`${order}ª revisão`}</Paragraph>

      <CheckboxLabel checked={checked}>
        <Checkbox name={revision} checked={checked} defaultChecked={true} />
        {day}
      </CheckboxLabel>
    </Container>
  );
}
