const csrfEl = document.querySelector("meta[name=csrf-token]");
let csrf = csrfEl ? csrfEl.content : undefined;
import { getSession } from "../sessionProcesses";

export default function RailsMiddleWare(request) {
  const session = getSession();
  request.header = {
    ...request.header,
    "X-CSRF-Token": csrf,
    ...session?.headers,
  };
}

export const updateCsrfToken = (newToken) => (csrf = newToken);
