import * as React from 'react';

export function AchievementsIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
      <rect width={24} height={24} fill={'#35BD78'} rx={12} />
      <g fill="#fff" clipPath="url(#a)">
        <path d="M12.355 14.177a4.699 4.699 0 0 1-.773-.006 4.816 4.816 0 0 1-3.491-1.995.11.11 0 0 0-.095-.046.11.11 0 0 0-.09.056l-1.969 3.538a.437.437 0 0 0-.008.434.459.459 0 0 0 .39.217H8.45a.418.418 0 0 1 .373.2l1.057 1.769a.444.444 0 0 0 .376.218.482.482 0 0 0 .398-.262l1.808-3.969a.11.11 0 0 0-.053-.144.11.11 0 0 0-.055-.01Zm5.705 1.539-1.955-3.532a.109.109 0 0 0-.185-.01 4.826 4.826 0 0 1-2.141 1.68.44.44 0 0 0-.239.225l-1.007 2.217a.217.217 0 0 0 0 .18l.827 1.822a.483.483 0 0 0 .397.265.446.446 0 0 0 .375-.223l1.053-1.765a.424.424 0 0 1 .377-.2h2.154c.183 0 .32-.095.383-.273a.462.462 0 0 0-.039-.386ZM12.007 10.688a1.313 1.313 0 1 0 0-2.626 1.313 1.313 0 0 0 0 2.626Z" />
        <path d="M12.007 5.438A3.942 3.942 0 0 0 8.07 9.374a3.942 3.942 0 0 0 3.937 3.938 3.942 3.942 0 0 0 3.938-3.938 3.942 3.942 0 0 0-3.938-3.938Zm0 6.125a2.187 2.187 0 1 1 0-4.375 2.187 2.187 0 0 1 0 4.375Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M5 5h14v14H5z" />
        </clipPath>
      </defs>
    </svg>
  );
}
