import React from "react";
import { Actions, Cancel, Card, Confirm, Info, Overlay, Title } from "./styles";
import reactDom from "react-dom";

const Dialog = ({
  infos,
  onHide,
  confirmationButtonText = "Excluir anotação"
}) => (
  <Overlay>
    <Card>
      <Title>{infos.title}</Title>
      <Info hasChildren={!!infos?.children}>{infos.text}</Info>
      {infos?.children}
      <Actions>
        <Cancel
          onClick={onHide}
          fontSize="lg"
          fullSized
          mediumWeight
          mode="cancel"
        >
          Cancelar
        </Cancel>
        <Confirm
          onClick={() => {
            onHide();
            infos.onYes();
          }}
          fontSize="lg"
          fullSized
          mediumWeight
          mode="red"
        >
          {confirmationButtonText}
        </Confirm>
      </Actions>
    </Card>
  </Overlay>
);

let el = null;

export default {
  show: function (infos) {
    el = document.createElement("div");
    reactDom.render(
      <Dialog
        infos={infos}
        confirmationButtonText={infos.confirmationButtonText}
        onHide={() => reactDom.unmountComponentAtNode(el)}
      />,
      document.body.appendChild(el)
    );
  }
};
