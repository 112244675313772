import * as Dialog from "@radix-ui/react-dialog";
import styled from "styled-components";
import { colors } from "../../../../components/library/theme";
import { EMRButton } from "../../../Button";

export const DialogOverlay = styled(Dialog.Overlay)`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: fixed;
  overflow: auto;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: rgba(54, 62, 70, 0.3);
  z-index: 10;
  padding: 0;
  animation: overlayShow 300ms cubic-bezier(0.16, 1, 0.3, 1);

  @keyframes overlayShow {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const DialogContent = styled(Dialog.Content)`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background-color: ${colors.interface.white000};
  padding: 24px;
  width: min(600px, calc(100vw - 48px));
  margin: auto;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  animation: contentShow 300ms cubic-bezier(0.16, 1, 0.3, 1);
  overflow: hidden;

  @keyframes contentShow {
    from {
      opacity: 0;
      transform: scale(0.96);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
`;
export const StyledEMRButton = styled(EMRButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-weight: 600;
  font-size: 16px;
  height: 48px;
  width: 100%;
  margin-top: 24px;

  @media (max-width: 991px) {
    font-size: 14px;
  }
`;
export const IllustrationWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
export const Title = styled.p`
  font-size: 18px;
  font-weight: 600;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 8px;
  line-height: 23.4px;

  span {
    font-style: italic;
  }
`;
export const Infos = styled.p`
  font-size: 14px;
  line-height: 21px;
`;
export const CheckboxText = styled.span`
  margin-left: 8px;
  font-size: 14px;
  line-height: 21px;
`;
