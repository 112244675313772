import React, { useState } from "react";
import logo from "../../../../assets/images/logo.svg";
import { apiPost } from "../../processes/helpers/api";
import { FinalStep } from "./FinalStep";
import { FirstStep } from "./FirstStep";
import { Container } from "./FirstStep/styles";
import SecondStep from "./SecondStep";
import DeviceStepper from "./Stepper";

export default function Device({ location }) {
  const [currentStep, setCurrentStep] = useState("initial");
  const [isLoading, setIsLoading] = useState(false);
  const steps = {
    initial: (
      <FirstStep advanceStep={handleAdvanceStep} isLoading={isLoading} />
    ),
    second: <SecondStep advanceStep={handleAdvanceStep} />,
    final: <FinalStep onContinue={handleContinue} />,
  };
  async function createFingerprint(fingerprintPayload) {
    setIsLoading(true);
    await apiPost("/api/v1/auth/create_fingerprint").send(
      JSON.stringify(fingerprintPayload)
    );
  }
  async function handleAdvanceStep(fingerprintPayload) {
    if (currentStep === "initial") {
      setCurrentStep("second");
    } else if (currentStep === "second") {
      setCurrentStep("final");
    }
  }
  function handleContinue() {
    window.location = "/";
  }
  if (!location) {
    window.location = "/";
  }
  return (
    <Container>
      <img src={logo} />
      <DeviceStepper currentStep={currentStep} />
      {steps[currentStep]}
    </Container>
  );
}
