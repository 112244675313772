import styled from "styled-components";
import { spacing, styles, uiColors } from "./theme";
import { css } from "glamor";

export const ButtonContainer = styled.div`
  margin: 0;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const row = css({ display: "flex", alignItems: "center" });
export const header = css(row, {
  justifyContent: "space-between",
  padding: spacing.m,
  borderBottom: `1px solid ${uiColors.border}`,
  minHeight: "100px",
});
export const mobileHeader = css({
  minHeight: "auto",
});

export const linkStyle = {
  minWidth: 62,
  marginRight: spacing.m,
  cursor: "pointer",
};
export const paneStyle = {
  borderBottom: "none",
  paddingBottom: 0,
};
export const mobileAction = css({
  padding: `${spacing.s} ${spacing.m}`,
});
const menuClass = css({
  paddingLeft: spacing.m,
  marginBottom: "0 !important",
  ".ui.menu .item": {
    ...styles.bodyTextLarge,
    paddingLeft: "0 !important",
    paddingRight: "0 !important",
    marginLeft: `${spacing.m} !important`,
    marginRight: `${spacing.m} !important`,
  },
});

export const tabMenuOptions = {
  secondary: true,
  pointing: true,
  className: menuClass,
};
