import { css } from "glamor";
import React from "react";
import { connect } from "react-redux";
import { Button } from "semantic-ui-react";
import { getMock } from "../../selectors/mockSelectors";
import { getMockTemplateById } from "../../selectors/mockTemplateSelectors";
import { spacing, uiColors } from "../library/theme";

const FIXED_SIZE = 38;
const buttonStyle = {
  width: FIXED_SIZE,
  height: FIXED_SIZE,
  padding: 0,
  borderRadius: "50%",
  marginRight: spacing.xxs,
  marginBottom: spacing.xxs,
};
const buttonStyleWhenPreExam = {
  width: FIXED_SIZE,
  height: FIXED_SIZE,
  padding: 0,
  borderRadius: "50%",
  marginRight: spacing.xxs,
  marginBottom: spacing.xxs,
  color: '#fff',
  backgroundColor: '#aaa'
};
const activeClass = css({
  border: `3px solid ${uiColors.backgroundDarkest} !important`,
  ".ui.button:disabled": { opacity: "1 !important" },
});

function QuestionPaginationItem({
  index,
  currentIndex,
  goToIndexHandler,
  status,
  isMarkedAsDoubt,
  showAnswer,
  showCorrectAnswer,
  isPreExam,
  answered
}) {

  let defaultColor;
  if (isMarkedAsDoubt && !status) {
    defaultColor = "yellow";
  } else if (!!status) {
    defaultColor = "grey";
  }

  const isActive = currentIndex === index;
  const notPreExamColors = showCorrectAnswer && showAnswer ? status : defaultColor

  return (
    <Button
      className={isActive ? activeClass.toString() : ""}
      style={answered && isPreExam ? buttonStyleWhenPreExam : buttonStyle}
      onClick={goToIndexHandler}
      disabled={isActive}
      color={isPreExam ? defaultColor : notPreExamColors}
      circular
    >
      {index + 1}
    </Button>
  );
}

function mapStateToProps(state, ownProps) {
  const mock = getMock(state)
  const mockTemplate = getMockTemplateById(state, mock.mockTemplateId)
  const isPreExam = mockTemplate ? mockTemplate.preExam : false

  return {
    isPreExam,
    ...ownProps
  };
}

export default connect(mapStateToProps)(QuestionPaginationItem);
