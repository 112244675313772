import { createSelector } from "reselect";

const getConfigs = (state) => state.entities.configs;
const getConfigName = (_, name) => name;

export const getConfig = createSelector(
  getConfigs,
  getConfigName,
  (configs, name) => {
    const config = configs[name];
    return config && Object.values(config);
  }
);
