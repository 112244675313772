import React from "react";

export function Illustration() {
  return (
    <svg
      width="326"
      height="199"
      viewBox="0 0 326 199"
      fill="none"
      style={{ transform: "translateX(-33.5px)" }}
    >
      <path
        d="M110.84 20H302.71C303.848 20 304.939 20.452 305.744 21.2565C306.548 22.061 307 23.1522 307 24.29V165.67H106.59V24.29C106.59 23.1591 107.037 22.074 107.832 21.2706C108.628 20.4672 109.709 20.0105 110.84 20Z"
        fill="#92E3A9"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M122.59 31.0601H290.95C292.088 31.0601 293.179 31.512 293.984 32.3166C294.788 33.1211 295.24 34.2123 295.24 35.3501V167.63H118.31V35.3501C118.31 34.214 118.761 33.1243 119.563 32.3201C120.365 31.5159 121.454 31.0627 122.59 31.0601Z"
        fill="#9BA5AB"
      />
      <path
        d="M175.395 87.4346H179.215V88.674H177.064V89.3286H178.89V90.4981H177.072V91.3525H179.322V92.5919H175.405L175.395 87.4346Z"
        fill="white"
      />
      <path
        d="M180.496 90.1487V87.4346H182.163V90.0589C182.123 90.404 182.203 90.7534 182.39 91.0501C182.84 91.4894 183.568 91.5079 184.042 91.0929C184.26 90.792 184.357 90.4254 184.316 90.0603V87.436H185.918V90.1487C185.966 90.7549 185.792 91.3582 185.427 91.8517C184.152 92.9513 182.223 92.9427 180.959 91.8303C180.614 91.3368 180.452 90.7449 180.496 90.1501V90.1487Z"
        fill="white"
      />
      <path
        d="M188.562 92.5933V87.4346H190.164L191.675 89.3144L193.18 87.4346H194.765V92.5919H193.099V90.9118C193.099 90.5053 193.107 90.056 193.115 89.7009L193.099 89.6937C192.857 90.0218 192.588 90.3569 192.33 90.685L191.634 91.555L190.926 90.685C190.659 90.3569 190.4 90.0218 190.156 89.6937L190.14 89.7009C190.149 90.0574 190.156 90.5067 190.156 90.9118V92.5919H188.561L188.562 92.5933Z"
        fill="white"
      />
      <path
        d="M196.177 87.443H199.993V88.6824H197.845V89.337H199.663V90.5065H197.845V91.3609H200.086V92.5974H196.172L196.177 87.443ZM198.293 86.9381H197.261L197.968 85.7686H199.266L198.293 86.9381Z"
        fill="white"
      />
      <path
        d="M201.322 87.4342H203.008C203.965 87.3643 204.924 87.5711 205.762 88.0261C206.902 88.8932 207.098 90.4906 206.201 91.5917C206.118 91.693 206.028 91.7885 205.931 91.8755C205.082 92.4275 204.061 92.6799 203.042 92.5887H201.327L201.322 87.4342ZM203.054 91.3521C204.116 91.3521 204.543 91.1667 204.794 90.9043C205.005 90.6561 205.113 90.3423 205.096 90.0214C205.129 89.6106 204.94 89.2113 204.595 88.9688C204.119 88.7307 203.583 88.6294 203.049 88.6765H202.992V91.3549H203.052L203.054 91.3521Z"
        fill="white"
      />
      <path
        d="M207.941 87.4346H209.608V92.5919H207.941V87.4346Z"
        fill="white"
      />
      <path
        d="M214.1 87.3533C214.655 87.3504 215.203 87.4488 215.72 87.6456V89.0847C215.236 88.8166 214.69 88.6697 214.134 88.6568C213.33 88.557 212.596 89.1075 212.493 89.8848C212.488 89.9234 212.484 89.9633 212.482 90.0018C212.482 90.7506 213.097 91.3624 214.165 91.3624C214.74 91.351 215.302 91.1956 215.798 90.9146V92.3608C215.258 92.569 214.68 92.6732 214.099 92.6674C212.133 92.6674 210.813 91.5122 210.813 90.0175C210.915 88.4401 212.317 87.242 213.948 87.339C213.998 87.3418 214.049 87.3461 214.099 87.3518H214.102L214.1 87.3533Z"
        fill="white"
      />
      <path
        d="M219.951 87.3514C219.936 87.3514 219.92 87.3499 219.905 87.3485C218.293 87.2587 216.911 88.4496 216.82 90.0085C216.82 91.3548 217.852 92.493 219.231 92.667C220.749 92.8595 222.141 91.8255 222.339 90.3565C222.538 88.8889 221.468 87.5425 219.949 87.3514H219.951ZM219.669 91.3534V91.3606C219.662 91.3606 219.653 91.3606 219.646 91.3606C218.864 91.3734 218.218 90.7715 218.205 90.0156C218.205 89.3039 218.78 88.7163 219.514 88.6764C220.278 88.635 220.933 89.2012 220.976 89.94C221.019 90.6788 220.433 91.3121 219.669 91.3534Z"
        fill="white"
      />
      <path
        d="M175.396 94.317H179.834C181.051 94.2571 182.266 94.4467 183.402 94.8732C184.661 95.3239 185.487 96.4977 185.458 97.797C185.472 99.1961 184.515 100.431 183.121 100.811L186.493 105.509H182.402L179.57 101.26H179.008V105.509H175.39L175.396 94.3184V94.317ZM179.909 99.0763C180.396 99.1106 180.883 99.0321 181.332 98.8481C181.67 98.6798 181.871 98.3333 181.843 97.9681C181.868 97.5631 181.62 97.188 181.228 97.0411C180.729 96.8828 180.204 96.82 179.682 96.8557H179.017V99.0778H179.909V99.0763Z"
        fill="white"
      />
      <path
        d="M186.683 94.3164H194.972V97.0063H190.301V98.4283H194.252V100.963H190.301V102.817H195.183V105.507H186.688L186.682 94.3178L186.683 94.3164Z"
        fill="white"
      />
      <path
        d="M195.852 101.917C197.15 102.566 198.573 102.944 200.03 103.028C200.768 103.028 201.296 102.8 201.296 102.23C201.296 101.431 200.278 101.431 198.662 100.932C196.73 100.347 195.396 99.5097 195.396 97.7026C195.396 95.5233 197.314 94.1484 200.156 94.1484C201.579 94.1556 202.987 94.4337 204.3 94.9671V97.9023C203.024 97.2577 201.625 96.8797 200.188 96.7913C199.523 96.7913 199.064 96.9767 199.064 97.4759C199.064 98.2018 200.048 98.2018 201.592 98.681C203.577 99.2986 204.964 100.164 204.964 102.05C204.964 104.306 202.943 105.681 199.994 105.681C198.575 105.678 197.168 105.416 195.849 104.908L195.852 101.917Z"
        fill="white"
      />
      <path
        d="M205.649 94.3164H209.267V105.498H205.649V94.3164Z"
        fill="white"
      />
      <path
        d="M210.567 94.3169H214.224C216.302 94.1685 218.379 94.6164 220.196 95.6033C222.671 97.4831 223.1 100.947 221.154 103.341C220.975 103.562 220.777 103.77 220.565 103.963C219.299 104.967 217.351 105.507 214.295 105.507H210.571L210.565 94.3169H210.567ZM214.325 102.814C216.622 102.814 217.556 102.412 218.1 101.842C218.556 101.304 218.789 100.621 218.751 99.9263C218.821 99.0349 218.408 98.172 217.662 97.6457C216.63 97.1308 215.466 96.9112 214.308 97.0125H214.187V102.823L214.325 102.816V102.814Z"
        fill="white"
      />
      <path
        d="M223.173 94.3164H231.463V97.0063H226.793V98.4283H230.747V100.963H226.796V102.817H231.677V105.507H223.179L223.172 94.3178L223.173 94.3164Z"
        fill="white"
      />
      <path
        d="M238.012 101.456C237.304 100.622 236.695 99.8647 236.062 99.0589L236.027 99.0746C236.044 100.017 236.062 100.976 236.062 101.918V105.504H232.586V94.3237H235.978L239.35 98.3714C240.057 99.2058 240.667 99.9688 241.299 100.769L241.335 100.753C241.317 99.8105 241.299 98.8521 241.299 97.9093V94.3237H244.775V105.504H241.383L238.012 101.456Z"
        fill="white"
      />
      <path
        d="M249.215 97.0063H245.324V94.3164H256.727V97.0063H252.835V105.504H249.218L249.215 97.0063Z"
        fill="white"
      />
      <path
        d="M257.274 94.3164H265.564V97.0063H260.894V98.4283H264.845V100.963H260.894V102.817H265.775V105.507H257.28L257.273 94.3178L257.274 94.3164Z"
        fill="white"
      />
      <path
        d="M159.44 109.153C159.247 109.153 159.055 109.126 158.871 109.071L149.196 106.243C148.36 106.003 147.786 105.259 147.789 104.415V87.6691C147.789 86.6165 148.671 85.7622 149.761 85.7607H169.119C170.208 85.7607 171.091 86.6151 171.093 87.6691V104.415C171.096 105.256 170.525 105.999 169.69 106.24L160.015 109.067C159.829 109.123 159.636 109.15 159.441 109.151L159.44 109.153ZM152.017 97.6043V98.8608C152.02 99.7979 152.806 100.557 153.775 100.557H156.234V105.007C156.234 105.945 157.021 106.705 157.99 106.705H159.288V100.555H159.59V104.633H160.888C161.859 104.633 162.645 103.871 162.645 102.934V100.559H167.246C168.215 100.557 169 99.7979 169 98.8608V97.6057H162.646V97.3105H166.863V96.054C166.86 95.1169 166.075 94.3582 165.106 94.3582H162.648V89.9068C162.645 88.9684 161.857 88.2082 160.885 88.211H159.587V94.3553H159.285V90.2791H157.99C157.021 90.2791 156.235 91.0379 156.232 91.9749V94.3525H151.637C150.666 94.3525 149.879 95.1126 149.879 96.0511C149.879 96.0511 149.879 96.0525 149.879 96.054V97.3091H156.232V97.6043H152.017ZM159.595 97.6043H159.288V97.3091H159.59V97.6014L159.595 97.6043Z"
        fill="white"
      />
      <path
        d="M305.05 177.44H98.3199C87.4899 177.44 78.7 170.88 78.7 162.79H324.7C324.66 170.88 315.88 177.44 305.05 177.44Z"
        fill="#92E3A9"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M111.47 29.9199H57.54"
        stroke="#606A71"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M111.47 85.4199H82.1499"
        stroke="#606A71"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M76.3901 85.4199H64.3501"
        stroke="#606A71"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M111.47 64.8101H51.26"
        stroke="#606A71"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M111.47 54.8701H94.71"
        stroke="#606A71"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M111.47 110.2H51.26"
        stroke="#606A71"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M111.47 132.13H94.71"
        stroke="#606A71"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M74.8201 39.3501H20.8901"
        stroke="#606A71"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.52 39.3501H1"
        stroke="#606A71"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
