import styled from 'styled-components';
import { colors } from '../../../../components/library/theme';

export const Wrapper = styled.div`
  width: 100%;
  height: calc(100vh - 260px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  color: ${colors.interface.grey300};
  font-weight: 600;
  font-size: 14px;
`;
