export default function discardAlternativesReducer(state = [], action) {
  const { alternativeId, mockId } = action;
  switch (action.type) {
    case "DISCARD_ALTERNATIVE":
      return [...state, { alternativeId, mockId }];
    case "UNDISCARD_ALTERNATIVE":
      return state.filter(
        (dA) =>
          dA.alternativeId != action.alternativeId || dA.mockId != action.mockId
      );
    default:
      return state;
  }
}
