import React from "react";
import { colors } from "../../components/library/theme";
import { Warning } from "./icons/warning";
import styled from "styled-components";

const Section = styled.section`
  max-width: 796px;
  width: 100%;
  padding: 32px;
  background-color: ${colors.medtrack.grey000};
  border-radius: 4px;
`;
const TitleWrapper = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
  align-items: center;
`;
const Title = styled.p`
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  color: ${colors.interface.grey400};

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;
const Text = styled.span`
  font-size: 16px;
  line-height: 24px;
  color: ${colors.interface.grey400};
`;
const ExtraInfo = styled.div`
  display: flex;
  background-color: ${colors.interface.grey100};
  padding: 16px;
  border-radius: 4px;
  margin-top: 16px;
  gap: 8px;
  border: solid 1px ${colors.interface.grey200};
`;
const ExtraInfoText = styled.span`
  font-size: 14px;
  line-height: 21px;
  color: ${colors.interface.grey400};
`;

export function Card({ icon, title, text, extraInfo, children }) {
  return (
    <Section>
      {title && (
        <TitleWrapper>
          {icon && icon()}
          <Title>{title}</Title>
        </TitleWrapper>
      )}

      <Text>{text}</Text>
      {children}
      {extraInfo && (
        <ExtraInfo>
          <Warning />
          <ExtraInfoText>{extraInfo}</ExtraInfoText>
        </ExtraInfo>
      )}
    </Section>
  );
}
