import styled, { keyframes } from "styled-components";
import { colors } from "../../components/library/theme";

const ToastVariants = {
  error: {
    backgroundColor: colors.status.red300,
    progressBarColor: colors.medtrack.grey000,
    progressColor: colors.status.red400,
    textColor: colors.interface.white000,
  },
  warning: {
    backgroundColor: colors.status.yellow200,
    progressBarColor: colors.medtrack.grey000,
    progressColor: colors.status.yellow400,
    textColor: colors.interface.white000,
  },
  success: {
    backgroundColor: colors.status.green250,
    progressBarColor: colors.medtrack.grey000,
    progressColor: colors.status.green250,
    textColor: colors.interface.white000,
  },
  notification: {
    backgroundColor: colors.interface.white000,
    progressColor: colors.brand.light200,
    progressBarColor: colors.medtrack.grey000,
    textColor: colors.interface.grey400,
  },
};

const toastProgress_5s = keyframes`
  0% {
    width: 100%;
  }
  100% {
    width: 0%;
  }
`;

const toastAppear = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
`;

const toastAppearOnMobile = keyframes`
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
`;

export const ToastContainer = styled.div`
  pointer-events: auto;
  position: relative;
  margin-right: 17px;
  display: flex;
  height: auto;
  width: ${({ variant }) => (variant === "notification" ? "312px" : "275px")};
  align-items: center;
  justify-content: center;
  text-align: left;
  overflow: hidden;
  border-radius: 4px;
  font-size: ${({ variant }) => (variant === "notification" ? "14px" : "16px")};
  line-height: 24px;
  font-weight: 600;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  padding: ${({ variant }) => (variant === "notification" ? "16px" : "24px")};
  text-align: center;
  color: ${({ variant }) => ToastVariants[variant].textColor};
  transition: all 0.3s ease-in-out;
  background-color: ${({ variant }) => ToastVariants[variant].backgroundColor};
  animation: ${toastAppear} 1s ease-in-out;

  span {
    display: block;
  }
  svg {
    flex-grow: 1;
  }

  @media screen and (max-width: 768px) {
    animation: ${toastAppearOnMobile} 0.5s ease-in-out;
  }
`;

export const ProgressBar = styled.div`
  position: absolute;
  height: 4px;
  bottom: 4px;
  border-radius: 999px;
  width: calc(100% - 8px);
  background: ${({ variant }) => ToastVariants[variant].progressBarColor};
  overflow: hidden;
`;

export const Progress = styled.div`
  animation: ${toastProgress_5s} 5s ease-in-out forwards;
  background: ${({ variant }) => ToastVariants[variant].progressColor};
  height: 100%;
  width: 100%;
  border-radius: 999px;
`;

export const ToastContentWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 8px;

  span {
    width: ${({ variant }) => (variant === "notification" ? "144px" : "auto")};
    text-align: left;
  }

  svg {
    flex-shrink: 0;
  }
`;

export const CloseBtn = styled.button`
  width: fit-content;
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ variant }) =>
    variant === "notification"
      ? colors.interface.grey300
      : colors.interface.white000};
`;
