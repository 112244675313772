import React from "react";
import Caroussel from "../library/Caroussel";
import { styles } from "../library/theme";
import Banner from "./Banner";
import { css } from "glamor";

export default function Banners({ banners, className }) {
  return !!banners?.length ? (
    <div className={css(className, styles.roundedCorners)}>
      <Caroussel animationDuration={0}>
        {banners.map((banner) => (
          <Banner key={`banner${banner.id}`} banner={banner} />
        ))}
      </Caroussel>
    </div>
  ) : null;
}
