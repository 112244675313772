import styled, { css } from "styled-components";
import { colors } from "../../../../components/library/theme";

export const NotifierIconContainer = styled.div(
  ({ isActived }) => css`
    background-color: ${isActived ? colors.brand.pure : colors.brand.dark000};
    position: relative;
    padding: 8px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: ${colors.brand.pure};
      ${!isActived && "cursor: pointer;"}

      #notifications-info {
        outline: 1px solid ${colors.brand.dark000};
      }
    }
  `,
);

export const NotificationsInfo = styled.span(
  ({ isActived }) => css`
    position: absolute;
    right: 8px;
    top: 16px;
    background-color: ${colors.status.red200};
    outline: 1px solid ${isActived ? colors.brand.dark000 : colors.brand.pure};
    border-radius: 100%;
    width: 8px;
    height: 8px;
  `,
);
