import styled, { css } from "styled-components";
import { colors } from "../../components/library/theme";

const fontSizeVariations = {
  sm: { fontSize: 12 },
  md: { fontSize: 14 },
  lg: { fontSize: 16 },
};

const buttonTypeVariations = {
  standard: css`
    background: ${colors.brand.pure};
    color: ${colors.interface.white000};
    border: none;

    &:not(:disabled):hover {
      background: ${colors.brand.dark000};
    }
  `,

  outline: css`
    color: ${colors.brand.pure};
    background: none;
    border: 1px solid ${colors.brand.pure};

    &:not(:disabled):hover {
      background: ${colors.brand.dark000};
      border-color: ${colors.brand.dark000};
      color: ${colors.interface.white000};
    }
  `,

  cancel: css`
    color: ${colors.interface.grey300};
    background: none;
    border: 1px solid ${colors.interface.grey300};

    &:not(:disabled):hover {
      background: ${colors.interface.grey300};
      border-color: ${colors.interface.grey300};
      color: ${colors.interface.white000};
    }
  `,

  transparent: css`
    color: ${colors.brand.pure};
    background: none;
    border: none;
    height: 40px;
    width: 150px;
    padding: 0 16px;

    &:not(:disabled):hover {
      background: ${colors.interface.grey100};
    }
  `,

  blue: css`
    color: ${colors.interface.white000};
    background: ${colors.status.blue300};
    border: none;

    &:not(:disabled):hover {
      background: ${colors.status.blue400};
    }
  `,

  yellow: css`
    background: ${colors.status.yellow200};
    color: ${colors.interface.white000};
    border: none;

    &:not(:disabled):hover {
      background: ${colors.status.yellow100};
    }
  `,

  gray: css`
    background: ${colors.interface.grey200};
    color: ${colors.interface.grey400};
    border: none;

    &:not(:disabled):hover {
      background: ${colors.status.grey100};
    }
  `,

  red: css`
    background: ${colors.status.red200};
    color: ${colors.interface.white000};
    border: none;

    &:not(:disabled):hover {
      background: ${colors.status.red300};
    }
  `,

  redirect: css`
    background: #f7fbfc;
    color: ${colors.brand.pure};
    border: none;
    height: 48px;
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    padding-right: 16px;
    padding-left: 16px;

    &:not(:disabled):hover {
      background: ${colors.interface.grey100};
    }

    svg:first-child {
      font-size: 24px;
      color: ${colors.brand.pure};
    }

    svg:last-child {
      font-size: 24px;
      margin-left: auto;
      stroke: ${colors.interface.grey300};
    }
  `,
};

export const Container = styled.button(
  (props) => css`
    height: ${props.shortHeight ? "32px" : "48px"};
    width: ${props.fullSized ? "100%" : "fit-content"};

    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;

    transition: 0.2s ease-in-out;
    gap: 8px;
    cursor: pointer;

    font-family: Poppins;
    font-weight: ${props.mediumWeight ? 500 : 400};
    line-height: 1;
    padding: 0 30px;

    path {
      transition: 0.2s ease-in-out;
    }

    svg {
      line-height: 1;
    }

    &:disabled {
      opacity: 50%;
      cursor: not-allowed;
    }

    ${(props) => buttonTypeVariations[props.mode || "standard"]}
    ${(props) => fontSizeVariations[props.fontSize || "sm"]}
  `
);
