import React from "react";
import { StyledDescription } from "../styles";
import { DescriptionContainer } from "../../TypeTag/styles";
import { getDescriptionIcon } from "./getDescriptionIcon";

export function DesktopDescription({
  children,
  category,
  subcategory,
  isRevision,
}) {
  return (
    <DescriptionContainer isRevision={isRevision}>
      {getDescriptionIcon({ category, subcategory })}
      <StyledDescription isRevision={isRevision}>{children}</StyledDescription>
    </DescriptionContainer>
  );
}
