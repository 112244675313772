import React from "react";
import formatDate from "../../utils/formatDate";
import { spacing } from "../library/theme";

const container = {
  paddingTop: spacing.m,
  textAlign: "center",
};

export default function MockInvalidTime({ startsAt }) {
  return (
    <div style={container}>
      <h3>Estamos quase lá!</h3>
      <p>
        {`Este simulado pode ser iniciado em: ${formatDate(
          startsAt,
          "DD/MM/YYYY - HH:mm"
        )}.`}
      </p>
    </div>
  );
}
