import React, { createContext, useEffect, useState } from "react";
import { apiGet } from "../../processes/helpers/api";

export const Context = createContext();

export function Provider(props) {
  const { dispatch, lessonsUser, levelGroup, isFrialUser } = props;
  const [loading, setLoading] = useState(true);
  const [loadingBanners, setLoadingBanners] = useState(true);
  const [banners, setBanners] = useState([]);
  const [hasVideoToKeepWatching, setHasVideoToKeepWatching] = useState(false);
  const [hasLastClass, setHasLastClass] = useState(true);

  useEffect(() => {
    apiGet("/api/v1/banners/list_home")
      .then((response) => setBanners(response.body))
      .finally(() => setLoadingBanners(false));
  }, []);

  useEffect(() => {
    apiGet("/api/v1/classrooms/lessons_user_last_updated").then((response) => {
      const classRoomId = response.body.classroom_id;
      if (classRoomId) {
        dispatch({ type: "CURRENT_CLASSROOM_CHANGED", id: classRoomId });
        setHasLastClass(true);
      } else {
        setHasVideoToKeepWatching(false);
        setHasLastClass(false);
      }

      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (lessonsUser && hasLastClass) {
      setHasVideoToKeepWatching(true);
      setLoading(false);
    }
  }, [lessonsUser]);

  const values = {
    lessonsUser,
    levelGroup,
    loading,
    hasVideoToKeepWatching,
    setHasVideoToKeepWatching,
    loadingBanners,
    banners,
    isFrialUser
  };

  return <Context.Provider value={values}>{props.children}</Context.Provider>;
}
