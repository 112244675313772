import React from "react";
import { css } from "glamor";
import { FaBell } from "react-icons/fa";
import { IoIosArrowUp } from "react-icons/io";
import { Dropdown, Icon } from "semantic-ui-react";
import Link from "../library/Link";
import { spacing, typographyColors, utilityColors } from "../library/theme";
import NotificationListItemContainer from "./NotificationListItemContainer";
import { trackClickEvents } from "../../../services/mixpanel/events/trackClickEvents";
const triggerWrapper = css({
  color: typographyColors.inverseBody,
  display: "flex",
  alignItems: "center"
});
const notificationAlert = css({
  borderRadius: "50%",
  backgroundColor: utilityColors.danger,
  height: 10,
  width: 10,
  margin: "-5px"
});
const menuStyle = {
  left: "auto",
  right: 0,
  maxHeight: "400px",
  overflowY: "auto",
  padding: spacing.xs
};
const allStyle = {
  width: "100%",
  display: "flex",
  justifyContent: "center",
  marginTop: spacing.xs
};

const NotificationTrigger = ({ unread, open, toggleDropdown, isFrialUser }) => (
  <div className={triggerWrapper}>
    <Icon.Group onClick={toggleDropdown}>
      <Icon>
        {open ? (
          <IoIosArrowUp size={22} />
        ) : (
          <FaBell
            size={22}
            onClick={() =>
              trackClickEvents({
                eventName: "Clique em icone notificações",
                content: { UserFrial: isFrialUser }
              })
            }
          />
        )}
      </Icon>
      {!!unread && !open && (
        <Icon corner>
          <div className={notificationAlert} />
        </Icon>
      )}
    </Icon.Group>
  </div>
);

export default function NotificationsDropdown(props) {
  const {
    openNotifications,
    toggleDropdown,
    unreadCount,
    notifications,
    isFrialUser
  } = props;

  return (
    <Dropdown
      open={openNotifications}
      trigger={
        <NotificationTrigger
          open={openNotifications}
          toggleDropdown={toggleDropdown}
          unread={unreadCount}
          isFrialUser={isFrialUser}
        />
      }
      icon={null}
    >
      <Dropdown.Menu style={menuStyle}>
        <h4>Notificações</h4>
        {notifications.length > 0 ? (
          notifications.map((notification) => (
            <NotificationListItemContainer
              key={`notifications${notification.id}`}
              notification={notification}
              toggleDropdown={toggleDropdown}
            />
          ))
        ) : (
          <Dropdown.Item>Nenhuma notificação nova por aqui</Dropdown.Item>
        )}
        <Link component={Dropdown.Item} to="/notifications" style={allStyle}>
          Ver todas as notificações
        </Link>
      </Dropdown.Menu>
    </Dropdown>
  );
}
