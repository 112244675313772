import { createSelector } from "reselect";

export const getCurrentUserId = (state) => state.currentUserId;
const getUsers = (state) => state.entities.users;

export const getCurrentUser = createSelector(
  getUsers,
  getCurrentUserId,
  (users, id) => users[id]
);

export function isExpiredUser(state) {
  const user = getCurrentUser(state)
  const createdAt = user?.createdAt

  if (!state.isFrialUser) return false
  if (user?.isAdmin) return false
  if (!createdAt) return null

  return state.isExpiredUser
}