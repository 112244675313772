import React from "react";
import { BsCheckLg } from "react-icons/bs";
import { IoClipboard, IoPlay, IoSchoolSharp } from "react-icons/io5";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { EMRButton } from "../../componentsV2/Button";
import CircularProgress from "../library/CircularProgress";
import { colors } from "../library/theme";

const ProgressContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  border: 1px solid ${colors.interface.grey200};
  border-radius: 4px;
  background-color: ${colors.interface.white000};
  margin: 20px 0;
  padding: 18px 24px;

  a {
    width: fit-content;
  }

  button {
    padding: 0 30px;
  }

  @media (max-width: 768px) {
    a {
      width: 100%;
    }
  }
`;

const InfoWrapper = styled.div`
  margin-right: 32px;
  margin-left: auto;

  @media (max-width: 768px) {
    margin-left: 0;
    margin-right: 0;
  }
`;

const IconContainer = styled.div`
  position: relative;
  width: 20px;
  height: 27px;

  svg {
    position: absolute;
  }

  svg:first-child {
    top: -2px;
    left: -5px;
  }

  svg:last-child {
    top: 10px;
    left: 5px;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  @media (max-width: 768px) {
    width: 80%;
  }
`;

const InfoContent = styled.div`
  display: flex;
  text-align: left;
  flex-direction: column;
  gap: 4px;

  max-width: calc(90vw - 642px);

  span {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: ${colors.interface.grey400};
  }

  span,
  h4 {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  @media (max-width: 992px) {
    max-width: calc(90vw - 442px);
  }

  @media (max-width: 768px) {
    max-width: calc(100vw - 262px);
  }
`;

const Title = styled.h4`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${colors.interface.grey500};
  margin: 0;
`;

const StyledButton = styled(EMRButton)`
  @media (max-width: 768px) {
    width: 100%;
    margin-top: 24px;
    flex-wrap: wrap;
  }
`;

export default function LessonProgress({
  currentClassroomId,
  lessonsUser,
  isLessonMock,
  lesson,
  level,
  levelGroup,
}) {
  const progress = Math.floor(lessonsUser?.progress * 100);
  const history = useHistory();

  function handleClick(currentClassroomId, lesson) {
    history.push(`/classrooms/${currentClassroomId}/lessons/${lesson.id}`);
  }

  return lessonsUser ? (
    <ProgressContainer>
      <InfoContainer>
        {isLessonMock ? (
          <IconContainer>
            <IoClipboard size={30} color={colors.brand.pure} />
            <BsCheckLg size={10} color={colors.interface.white000} />
          </IconContainer>
        ) : (
          <IoSchoolSharp size={30} color={colors.brand.pure} />
        )}

        <InfoContent>
          <Title>{lesson?.name}</Title>
          <span>{`${levelGroup?.name} | ${level?.name}`}</span>
        </InfoContent>
      </InfoContainer>

      <InfoWrapper>
        <CircularProgress percent={progress} />
      </InfoWrapper>

      <StyledButton
        onClick={() => handleClick(currentClassroomId, lesson)}
        mode="outline"
        hasLink
        shortHeight
        mediumWeight
        fontSize="md"
      >
        <IoPlay size={24} />
        Continuar de onde parou
      </StyledButton>
    </ProgressContainer>
  ) : null;
}
