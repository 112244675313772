import { css } from "glamor";
import React from "react";
import { Comment } from "semantic-ui-react";
import { spacing } from "../library/theme";
import CommentFormContainer from "./CommentFormContainer";
import CommentListItem from "./CommentListItem";

const container = css({
  paddingBottom: spacing.l,
});

export default function Comments(props) {
  return (
    <div className={container}>
      <CommentFormContainer lessonId={props.lessonId} />
      <h3>Comentários</h3>
      <Comment.Group>
        {props.comments.length ? (
          props.comments.map((comment, index) => (
            <CommentListItem key={index} comment={comment} />
          ))
        ) : (
          <p>Seja o primeiro a comentar.</p>
        )}
      </Comment.Group>
    </div>
  );
}
