import styled from "styled-components";
import { colors } from "../../library/theme";

export const Container = styled.div`
  background-color: ${colors.brand.light000};
  border: 1px solid ${colors.status.green000};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  padding: 16px;
  svg {
    margin-right: 8px;
  }
`;

export const TextBox = styled.div`
  display: flex;
  flex-direction: column;

  p {
    margin-top: 8px; // placed here since semantic-ui was overriding the margin-top
  }
`;

export const Title = styled.p`
  color: ${colors.status.green200};
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 8px;
`;
export const Text = styled.p`
  color: ${colors.interface.grey400};
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  margin-left: 32px;
  margin-top: 8px;

  @media only screen and (max-width: 768px) {
    margin-left: 5px;
  }
`;

export const LampIconContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
