import * as React from 'react';

export function EventsIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
      <rect width={24} height={24} fill="#49B1EB" rx={12} />
      <path
        fill="#fff"
        d="M8.637 17.031a.967.967 0 0 1-.479-.127 1.075 1.075 0 0 1-.532-.94V8.036c0-.393.204-.753.532-.939a.96.96 0 0 1 .978.013l6.777 4.056a.983.983 0 0 1 0 1.668l-6.778 4.058a.97.97 0 0 1-.498.14Z"
      />
      <path
        fill="#fff"
        d="M8.637 17.031a.967.967 0 0 1-.479-.127 1.075 1.075 0 0 1-.532-.94V8.036c0-.393.204-.753.532-.939a.96.96 0 0 1 .978.013l6.777 4.056a.983.983 0 0 1 0 1.668l-6.778 4.058a.97.97 0 0 1-.498.14Z"
      />
    </svg>
  );
}
