import React from "react";
import { Doughnut } from "react-chartjs-2";
import toPercentage from "../../utils/toPercentage";
import { stateColors, uiColors } from "../library/theme";

const graphStyle = { maxHeight: 175 };

const dataForLessonsWatched = (lessonsCount, lessonsWatchedCount) => ({
  labels: [
    `${lessonsWatchedCount} Concluída - ${toPercentage(
      lessonsWatchedCount,
      lessonsCount
    )}`,
    `${lessonsCount - lessonsWatchedCount} Não Concluída - ${toPercentage(
      lessonsCount - lessonsWatchedCount,
      lessonsCount
    )}`,
  ],
  datasets: [
    {
      label: "Aulas",
      data: [lessonsWatchedCount, lessonsCount - lessonsWatchedCount],
      backgroundColor: [uiColors.background, stateColors.empty],
      border: ["none", "none"],
    },
  ],
});

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "right",
    },
  },
};

export default React.memo(function LessonWatchedGraph({
  lessonsCount,
  lessonsWatchedCount,
  workloadTotalTime,
  workloadWatchedTime,
}) {
  return (
    <Doughnut
      data={dataForLessonsWatched(
        lessonsCount,
        lessonsWatchedCount,
        workloadTotalTime,
        workloadWatchedTime
      )}
      options={options}
      style={graphStyle}
    />
  );
});
