import React from "react";
import { Button, EmptyState } from "./styles";
import { Spinner } from "../../../Spinner";

export function LoadMoreButton({ onClick, isLoading, isEmptyData = true }) {
  return (
    <>
      {isEmptyData ? (
        <EmptyState>Não há mais tarefas para carregar</EmptyState>
      ) : (
        <Button buttonName="load-more" onClick={onClick}>
          {isLoading ? <Spinner /> : "Carregar mais"}
        </Button>
      )}
    </>
  );
}
