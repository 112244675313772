import { createSelector } from "reselect";
import { orderByIdDesc } from "../utils/orderById";
import { getAllNotificationsUsers } from "./notificationsUserSelectors";

const getNotifications = (state) => state.entities.notifications;
const getId = (_, id) => id;

const getAllNotifications = createSelector(getNotifications, (notifications) =>
  Object.values(notifications)
);

export const getFirstModalNotification = createSelector(
  getAllNotifications,
  getAllNotificationsUsers,
  (notifications, notificationsUsers) =>
    notifications.filter(
      (notification) =>
        !notificationsUsers.find((nu) => nu.notificationId == notification.id)
          ?.read && notification.isModal
    )
    .sort(orderByIdDesc)[0]
);

export const getPageNotifications = createSelector(
  getAllNotifications,
  getId,
  (notifications, id) =>
    notifications
      .filter((notification) => notification.id != id)
      .sort(orderByIdDesc)
);

export const getDropdownNotifications = createSelector(
  getAllNotifications,
  getAllNotificationsUsers,
  (notifications, notificationsUsers) =>
    notifications
      .filter(
        (notification) =>
          !notificationsUsers.find((nu) => nu.notificationId == notification.id)
            ?.filed
      )
      .sort(orderByIdDesc)
      .slice(0, 5)
);

export const getUnreadNotificationsCount = createSelector(
  getDropdownNotifications,
  getAllNotificationsUsers,
  (notifications, notificationsUsers) =>
    notifications.filter(
      (notification) =>
        !notificationsUsers.find((nu) => nu.notificationId == notification.id)
          ?.read
    ).length
);

export const getNotification = createSelector(
  getAllNotifications,
  getId,
  (notifications, id) =>
    notifications.find((notification) => notification.id == id)
);
