import React from "react";
import { colors } from "../../../../components/library/theme";
import { Tag } from "./styles";
import { IoBook, IoPlay } from "react-icons/io5";
import { ClipboardCheck } from "./icons/ClipboardCheck";
import { BookSearch } from "./icons/BookSearch";

export const tagVariants = {
  theory: {
    name: "Teoria",
    bgColor: colors.medtrack.purple000,
    textColor: colors.medtrack.purple200,
    icon: () => <IoBook size={16} />,
    colors: {
      iconColor: colors.medtrack.purple200,
    },
  },
  practice: {
    name: "Prática",
    bgColor: colors.medtrack.blue000,
    textColor: colors.medtrack.blue200,
    icon: () => <BookSearch size={16} />,
    colors: {
      iconColor: colors.medtrack.blue200,
    },
  },
  revision: {
    name: "Revisão",
    bgColor: colors.medtrack.pink000,
    textColor: colors.medtrack.pink200,
    icon: () => <ClipboardCheck size={16} />,
    colors: {
      iconColor: colors.medtrack.pink200,
    },
  },
  revision_lesson: {
    name: "Aula de Revisão",
    bgColor: "#FFE0EF",
    textColor: colors.medtrack.pink300,
    icon: () => <IoPlay size={16} />,
    colors: {
      iconColor: colors.medtrack.pink300,
    },
  },
};

export function TypeTag({ variant, isDisabled = false, ...rest }) {
  return (
    <Tag isDisabled={isDisabled} variant={variant} {...rest}>
      {tagVariants[variant].icon()}
      <span>{tagVariants[variant].name}</span>
    </Tag>
  );
}
