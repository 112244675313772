import { apiGet } from "./helpers/api";
import { normalize } from "normalizr";
import { valuesSchema } from "./configProccess";

export const fetchSpecialitiesReport = (dispatch) => {
  apiGet(`/api/v1/specialities_report`).then((response) => {
    dispatch({
      type: "CONFIGS_FETCHED",
      ...normalize({ specialities: response.body.data }, valuesSchema),
    });
  });
};
