import React from "react";

export function VideoDuration() {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 8C10.294 8 8.5 9.794 8.5 12C8.5 14.206 10.294 16 12.5 16C14.706 16 16.5 14.206 16.5 12C16.5 9.794 14.706 8 12.5 8ZM12.5 14.6667C11.0293 14.6667 9.83333 13.4707 9.83333 12C9.83333 10.5293 11.0293 9.33333 12.5 9.33333C13.9707 9.33333 15.1667 10.5293 15.1667 12C15.1667 13.4707 13.9707 14.6667 12.5 14.6667ZM13.638 12.1953C13.8987 12.456 13.8987 12.8773 13.638 13.138C13.508 13.268 13.3373 13.3333 13.1667 13.3333C12.996 13.3333 12.8253 13.268 12.6953 13.138L12.0287 12.4713C11.9033 12.346 11.8333 12.1767 11.8333 12V10.6667C11.8333 10.2987 12.1313 10 12.5 10C12.8687 10 13.1667 10.2987 13.1667 10.6667V11.724L13.638 12.1953ZM16.5 3.33333V6.66667C16.5 7.03467 16.202 7.33333 15.8333 7.33333C15.4647 7.33333 15.1667 7.03467 15.1667 6.66667V3.33333C15.1667 2.23067 14.2693 1.33333 13.1667 1.33333H3.83333C2.73067 1.33333 1.83333 2.23067 1.83333 3.33333V10C1.83333 11.1027 2.73067 12 3.83333 12H6.5C6.86867 12 7.16667 12.2987 7.16667 12.6667C7.16667 13.0347 6.86867 13.3333 6.5 13.3333H3.83333C1.99533 13.3333 0.5 11.838 0.5 10V3.33333C0.5 1.49533 1.99533 0 3.83333 0H13.1667C15.0047 0 16.5 1.49533 16.5 3.33333ZM10.428 7.42933L7.67 8.96133C7.14533 9.25267 6.5 8.87333 6.5 8.27267V5.208C6.5 4.60733 7.14533 4.228 7.67 4.51933L10.428 6.052C10.968 6.352 10.968 7.12867 10.428 7.42867V7.42933Z"
        fill="#35BD78"
      />
    </svg>
  );
}
