import React from "react";
import { Card } from "semantic-ui-react";
import Spinner from "../library/Spinner";
import EmptyGraph from "./EmptyGraph";
import LessonWatchedGraph from "./LessonWatchedGraph";

const content = {
  minHeight: 200,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export default function LessonsCard({ loading, lessonsData }) {
  return loading ? (
    <Spinner text="Carregando informações..." />
  ) : (
    <Card fluid padded>
      <Card.Content>
        <Card.Header>Aulas</Card.Header>
      </Card.Content>
      <Card.Content style={content}>
        {lessonsData?.lessonsCount ? (
          <LessonWatchedGraph
            lessonsWatchedCount={lessonsData?.lessonsWatchedCount ?? 0}
            lessonsCount={lessonsData?.lessonsCount}
          />
        ) : (
          <EmptyGraph />
        )}
      </Card.Content>
    </Card>
  );
}
