export const countryOptions = [
  {
    name: "Argentina +54",
    code: "AR +54",
  },
  {
    name: "Bolívia +591",
    code: "BO +591",
  },
  {
    name: "Brasil +55",
    code: "BR +55",
  },
  {
    name: "Paraguai +595",
    code: "PY +595",
  },
  {
    name: "Uruguai +598",
    code: "UY +598",
  },
  {
    name: "Outros países",
    code: "Outros países",
  },
];
