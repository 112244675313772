import { cleanText } from "./getFormattedDescription";
import ReactHtmlParser from 'react-html-parser';

export function getFormattedTitle(text, code) {
  if (!text) return null
  const doc = document.createElement('div');
  doc.innerHTML = text;

  const questionTitle = doc.children[0].innerHTML.replace(/([()])/g, '');
  const questionCode = code.split('Q');

  const questionCodeFormatted =
    questionCode[questionCode.length - 1].match(/\d+/)[0]

  const titleFormatted = `${questionTitle} Q${questionCodeFormatted}`;

  return ReactHtmlParser(cleanText(titleFormatted));
}