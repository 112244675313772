import { connect } from "react-redux";
import { getQuestionsFromMock } from "../../selectors/questionSelectors";
import QuestionPagination from "./QuestionPagination";

function mapStateToProps(state) {
  return {
    questions: getQuestionsFromMock(state),
  };
}

export default connect(mapStateToProps)(QuestionPagination);
