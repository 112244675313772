import React, { useEffect, useState } from "react";
import { useMedTrack } from "../../hooks/useMedTrack";
import { Accordion } from "../Accordion";
import { ScrollTopButton } from "../ScrollTopButton";
import { Container } from "./styles";
import { FilterNotFound } from "../FiltersNotFound";
import { RevisionAccordions } from "./RevisionAccordions";
import Spinner from "../../../../components/library/Spinner";

export function MedTrackAccordions({ isBases, isRevision, isLoading }) {
  const [formattedTodoGroup, setFormattedTodoGroup] = useState([]);
  const { todoGroups, activeFilters } = useMedTrack();
  const initialFinishedTodoGroupCount = todoGroups.filter(
    (group) => group.progress === 100
  ).length;

  const { checkIfCanEnableRecalculateButton, selectedMedtrackType } =
    useMedTrack();
  const [finishedTodoGroupCount, setFinishedTodoGroupCount] = useState(
    initialFinishedTodoGroupCount
  );
  const todoGroupAccordions = isRevision ? (
    <RevisionAccordions
      isBases={isBases}
      formattedTodoGroups={formattedTodoGroup}
      updatedTodoGroup={formattedTodoGroup}
    />
  ) : (
    <>
      {formattedTodoGroup.map((group, index) => (
        <Accordion
          isBases={isBases}
          isRevision={isRevision}
          updatedTodoGroup={formattedTodoGroup}
          key={index}
          data={group}
        />
      ))}
    </>
  );

  function getItems(items) {
    const newItems = items
      .map((item) => ({
        ...item,
        isCheckedByUser: item.status === "completed",
      }))
      .sort((a, b) => (a.position > b.position ? 1 : -1));

    return newItems;
  }

  useEffect(() => {
    if (formattedTodoGroup.length === 0) return;

    const currentFinishedTodoGroupCount = formattedTodoGroup.filter(
      (group) => group.progress === 100
    ).length;
    setFinishedTodoGroupCount(currentFinishedTodoGroupCount);
    const shouldCheckIfCanRecalculate =
      finishedTodoGroupCount !== currentFinishedTodoGroupCount;

    if (shouldCheckIfCanRecalculate) {
      checkIfCanEnableRecalculateButton(selectedMedtrackType.id);
    }
  }, [todoGroups]);

  useEffect(() => {
    const _formattedTodoGroup = todoGroups.map((group) => ({
      ...group,
      isWeekCompleted: group.tag === "finished",
      items: getItems(group.items),
    }));
    setFormattedTodoGroup(_formattedTodoGroup);
  }, [todoGroups]);

  function renderContent() {
    if (isLoading) return <Spinner />;
    if (todoGroups.length === 0) return <FilterNotFound />;

    return (
      <Container>
        {todoGroupAccordions}
        <ScrollTopButton />
      </Container>
    );
  }

  return <>{renderContent()}</>;
}
