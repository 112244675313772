import * as React from 'react';

export function WarningsIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
      <rect width={24} height={24} fill="#E9C610" rx={12} />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12 7.188c-.237 0-.453.2-.438.437l.22 5.906a.219.219 0 0 0 .437 0l.219-5.906c.015-.236-.201-.438-.438-.438Z"
      />
      <path
        fill="#fff"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M12 16.813a.438.438 0 1 0 0-.876.438.438 0 0 0 0 .876Z"
      />
    </svg>
  );
}
