import styled from "styled-components"

export const Container = styled.div`
  align-items: center;
  display: flex;
  gap: 16px;

  span {
    font-size: 14px;
  }

  @media (max-width: 600px) {
    padding: 0;
    margin-bottom: 64px;
  }
`
export const Line = styled.hr`
  flex-grow: 1;
`