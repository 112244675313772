export function getQueryParam(param) {
  const query = window.location.search.substring(1);
  const vars = query.split("&");
  let i = 0;

  while (i < vars.length) {
    const pair = vars[i].split("=");
    if (pair[0] === param) {
      return decodeURIComponent(pair[1]);
    }
    i++;
  }
  return false;
}
