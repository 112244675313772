import React, { useContext, useEffect, useState } from "react";
import Carousel from "../../../components/library/Caroussel";
import {
  Slide,
  Image,
  DesktopCarousel,
  MobileCarousel,
  Skeleton
} from "./styles";
import { Context } from "../context";

export function BannerCarousel() {
  const { banners, loadingBanners,isFrialUser } = useContext(Context);
  const [slides, setSlides] = useState([]);

  useEffect(() => {
    if (banners && banners.banner) {
      if(isFrialUser){
       const bannersWithFrial = banners.banner.filter(slide =>  slide.frial_enabled == true)
        setSlides(bannersWithFrial)
      }else{
        const bannersWithoutFrial = banners.banner.filter(slide =>  slide.frial_enabled != true)
        setSlides(bannersWithoutFrial)
      }
    }
  }, [banners,isFrialUser]);

  return (
    <>
      {loadingBanners && <Skeleton />}

      {slides.length > 0 && (
        <>
          <DesktopCarousel visible={loadingBanners}>
            <Carousel showPagination animation="fade left">
              {slides.map((slide) => (
                <Slide target="_blank" key={slide.url} href={slide.url}>
                  <Image src={slide.image.url} />
                </Slide>
              ))}
            </Carousel>
          </DesktopCarousel>

          <MobileCarousel visible={loadingBanners}>
            <Carousel showPagination animation="fade left">
              {slides.map((slide) => (
                <Slide target="_blank" key={slide.url} href={slide.url}>
                  <Image src={slide.image_mobile.url} />
                </Slide>
              ))}
            </Carousel>
          </MobileCarousel>
        </>
      )}
    </>
  );
}
