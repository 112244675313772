import styled from "styled-components";
import { colors, spacing } from "../../library/theme";
import * as Dialog from "@radix-ui/react-dialog";

export const DialogOverlay = styled(Dialog.Overlay)`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: fixed;
  overflow: auto;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: rgba(54, 62, 70, 0.3);
  z-index: 10;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SpecialtyAndSubjectContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  > p {
    font-size: 16px;
    font-weight: 600;
    color: ${colors.interface.grey400};
  }

  > span {
    padding: 8px 16px;
    background-color: ${colors.medtrack.grey000};
    border-radius: 9999px;
    color: ${colors.interface.grey400};
    font-size: 12px;
    font-weight: 600;
    line-height: 15.6px;
  }

  p:first-letter,
  span:first-letter {
    text-transform: capitalize;
  }
`;

export const DialogContent = styled(Dialog.Content)`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background-color: ${colors.interface.white000};
  padding: 24px;
  width: min(772px, calc(100vw - 48px));
  margin: 54px 14px 32px 24px;
  position: relative;
  min-height: 60vh;
`;

export const DeletedMessage = styled.span`
  font-size: 14px;
  color: ${colors.interface.grey400};
  background-color: ${colors.interface.grey100};
  border-radius: 4px;
  text-align: center;
  width: 100%;
  display: block;
  padding: 12px 0;
  margin: 16px 0;
`;

export const QuestionTitleContainer = styled.div`
  display: flex;
  margin-top: 24px;
  margin-bottom: 16px;
  align-items: center;
  gap: 16px;
`;

export const QuestionTitle = styled.h2`
  color: ${colors.interface.grey400};
  font-size: 14px;
  line-height: 21px;
`;

export const Divider = styled.div`
  height: 0px;
  border-top: 1px solid ${colors.interface.grey100};
`;

export const QuestionNumber = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.3;
  color: ${colors.interface.grey400};
  background-color: ${colors.interface.grey000};
  border-radius: 50%;
  border: 1px solid ${colors.interface.grey100};
  height: 32px;
  width: 32px;
  margin: 0;
`;

export const QuestionDescription = styled.p`
  color: ${colors.interface.grey400};
  font-size: 14px;
  line-height: 21px;
  margin: 16px 0 16px;

  p,
  span {
    font-size: 14px;
  }
`;

export const QuestionImg = styled.img`
  object-fit: contain;
  border-radius: 6px;
  width: 300px;

  + ol {
    margin-top: 24px;
  }
`;

export const CloseButton = styled.button`
  align-self: flex-end;
  background-color: transparent;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.2s;

  &:hover {
    background-color: ${colors.interface.grey100};
  }

  svg {
    line-height: 0;
    color: ${colors.interface.grey400};
  }

  @media (max-width: 1024px) {
    background-color: ${colors.interface.grey100};
  }
`;

export const AlternativesContainer = styled.ol`
  margin: 0 0 16px;
  counter-reset: list;
  padding: 0;
`;

export const QuestionCommentWrapper = styled.div`
  margin-bottom: ${spacing.m};
  padding: ${spacing.m} 0;
`;
