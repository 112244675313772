import React from "react";
import { css } from "glamor";
import { Icon } from "semantic-ui-react";
import { spacing } from "../theme";
import Button from "../Button";

const layout = css({
  position: "relative",
  display: "flex",
  alignItems: "center",
});
const container = css({
  position: "relative",
  display: "flex",
  alignItems: "center",
  width: 320,
});
const clearIconWrapper = {
  position: "absolute",
  right: spacing.xs,
};
const searchIconStyle = {
  margin: `0 ${spacing.xxs} 0 0`,
  fontSize: 16,
  fontWeigth: "bold",
};
const inputClass = css({
  width: "100%",
  border: "none",
  marginRight: spacing.xs,
  paddingLeft: spacing.xs,
  ":focus": {
    outline: "none",
  },
});
const clearIconStyle = {
  cursor: "pointer",
  fontSize: 12,
  opacity: 0.3,
};
const buttonStyle = {
  display: "flex",
  cursor: "pointer",
  marginLeft: spacing.xs,
  zIndex: 1,
};

export default function SearchInput(props) {
  const { onChange, onClearHandler, value } = props;
  const clear = React.useCallback(() => onClearHandler() || onChange(""), [
    onChange,
    onClearHandler,
  ]);
  return (
    <div className={layout}>
      <div className={container}>
        <input {...props} className={inputClass} />
        {value !== "" && (
          <div style={clearIconWrapper}>
            <Icon name="delete" onClick={clear} style={clearIconStyle} />
          </div>
        )}
      </div>
      <Button style={buttonStyle} text="Buscar">
        <Icon name="search" style={searchIconStyle} />
        Buscar
      </Button>
    </div>
  );
}
