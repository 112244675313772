import { authWithApple } from "./authWithApple";
import { authWithGoogle } from "./authWithGoogle";

export function onUserAuth(provider) {
  switch (provider) {
    case 'google':
      return authWithGoogle()
    case 'apple':
      return authWithApple()
    default:
      throw Error('provider property is not defined.')
  }
}