import { apiGet } from "./helpers/api";
import { normalize, schema } from "normalizr";

const notificationSchema = new schema.Entity("notifications");

export const fetchNotifications = (dispatch) => {
  return apiGet(`/api/v1/notifications`).then((response) => {
    dispatch({
      type: "NOTIFICATIONS_FETCHED",
      ...normalize(response.body.data, new schema.Array(notificationSchema)),
    });
  });
};
