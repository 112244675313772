import styled from "styled-components";
import { colors } from "../library/theme";

export const FrialButton = styled.button`
  width: 100%;
  display: flex;
  gap: 8px;
  align-items: center;
  background-color: white;
  border: 1px solid ${colors.brand.pure};
  border-radius: 4px;
  color: ${colors.brand.pure};
  cursor: pointer;
  padding: 8px;
  transition: all 0.2s ease-in-out;
  font-size: 12px;
  font-weight: 600;
  line-height: 1;

  svg {
    path {
      fill: ${colors.brand.pure};
      transition: all 0.2s ease-in-out;
    }
  }

  &:hover {
    background-color: ${colors.brand.pure};
    color: white;

    svg {
      path {
        fill: white;
      }
    }
  }
`;

export const FrialButtonsContainer = styled.div`
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  margin: auto 16px 24px 16px;
`;
