import React, { useEffect, useState } from "react";
import { Grid, Table } from "semantic-ui-react";
import { FilterButton } from "../../componentsV2/FilterButton";
import { MocksTable } from "../../componentsV2/MocksTable";
import {
  fetchMedtrackMocks,
  fetchPaginatedMock,
} from "../../processes/mockProccess";
import Pagination from "../library/Pagination";
import Spinner from "../library/Spinner";
import { spacing, stateColors } from "../library/theme";
import MockListItemContainer from "./MockListItemContainer";
import MockProgressContainer from "./MockProgressContainer";

const contentStyle = {
  textAlign: "center",
};
const emptyClass = {
  textAlign: "center",
  color: stateColors.empty,
  padding: `${spacing.m} 0`,
};
const loadingWrapper = {
  margin: spacing.xxl,
};

export default function Mocks() {
  const [mocks, setMocks] = useState([]);
  const [medtrackMocks, setMedtrackMocks] = useState([]);
  const [selectedValue, setSelectedValue] = useState("ALL");
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState("");

  const shouldShowMedtrack =
    medtrackMocks?.every((mock) => mock.progress >= 1) &&
    medtrackMocks?.length > 1;

  const buttonFIlterList = [
    {
      action: "ALL",
      title: "Todos",
    },
    {
      action: "IN_PROGRESS",
      title: "Em andamento",
    },
    {
      action: "DONE",
      title: "Finalizados",
    },
    {
      action: "PENDING",
      title: "Pendentes",
    },
  ];

  const handleFetch = (action, page) => {
    setIsLoading(true);

    if (action) setStatus(action);
    if (action === "ALL") setStatus("");

    fetchMedtrackMocks({
      medtrackType: "CUSTOM",
    }).then((mocks) => {
      setMedtrackMocks(mocks);
    });

    fetchPaginatedMock(action || status, "CUSTOM", page)
      .then((mockList) => {
        setMocks(mockList);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    handleFetch();
  }, []);

  return (
    <>
      <Grid>
        <Grid.Column computer={16} style={contentStyle}>
          <FilterButton
            data={buttonFIlterList}
            isLoading={isLoading}
            selectedValue={selectedValue}
            setSelectedValue={setSelectedValue}
            onSelectFilter={handleFetch}
          />
          <MockProgressContainer />
          {isLoading ? (
            <div style={loadingWrapper}>
              <Spinner inline />
            </div>
          ) : (
            <MocksTable variant={"mock"}>
              {mocks?.dataMocks?.length ? (
                <>
                  <thead>
                    <tr>
                      <th>Criação</th>
                      <th>Nº de questões</th>
                      <th>Nome</th>
                      <th>Status</th>
                      <th>Nota</th>
                      <th>Progresso</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {mocks.dataMocks?.map((mock) => (
                      <MockListItemContainer
                        key={mock.id}
                        mock={mock}
                        // isExam={mock?.exam}
                      />
                    ))}
                    {shouldShowMedtrack &&
                      mocks.currentPage === 1 &&
                      medtrackMocks?.map((mock) => (
                        <MockListItemContainer
                          key={mock.id}
                          mock={mock}
                          // isExam={mock?.exam}
                        />
                      ))}
                  </tbody>
                </>
              ) : (
                <Table.Body>
                  <tr>
                    <td style={emptyClass}>Nenhum resultado encontrado</td>
                  </tr>
                </Table.Body>
              )}
            </MocksTable>
          )}
          <Pagination
            fetchHandler={handleFetch}
            list={mocks}
            loading={isLoading}
          />
        </Grid.Column>
      </Grid>
    </>
  );
}
