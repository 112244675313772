import React from "react";
import { Grid } from "semantic-ui-react";
import { css } from "glamor";
import { spacing, styles, uiColors } from "../library/theme";
import ClassroomProgress from "./ClassroomProgress";
import { EMRButton } from "../../componentsV2/Button";
import { IoPlay } from "react-icons/io5";
import { useHistory } from "react-router-dom";
import { trackAccessToCourseEvent } from "../../../services/mixpanel/events/trackAccessToCourseEvent";

const container = css(styles.shadow, styles.roundedCorners, {
  overflow: "hidden",
  "& + &": {
    marginTop: spacing.m,
  },
});
const imageContainer = css({
  height: "100%",
  paddingBottom: "56.25%",
  backgroundSize: "contain",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
});
const leftColumn = { padding: 0 };
const rightColumn = {
  padding: 0,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  border: `1px solid ${uiColors.border}`,
  borderLeft: "none",
  backgroundColor: uiColors.fieldBackground,
};

export default function ClassroomListItem({ classroom,isFrialUser }) {
  const history = useHistory()
  const isClassroomEnabled = classroom.enabled



  function handleClick(id) {
    trackAccessToCourseEvent(name,isFrialUser)

    history.push(`/classrooms/${id}`)


  }

  const { name, id, description, meta, imageUrl } = classroom;

  return (
    <>
      <div className={container}>
        <Grid padded>
          <Grid.Column computer={4} mobile={16} style={leftColumn}>
            <div
              className={css(imageContainer, {
                backgroundImage: `url(${imageUrl})`,
              })}
            ></div>
          </Grid.Column>
          <Grid.Column computer={12} mobile={16} style={rightColumn}>
            <Grid columns="equal" padded>
              <Grid.Column computer={11} mobile={16}>
                <h4>{name}</h4>
                <p>{description}</p>
              </Grid.Column>
              <Grid.Column computer={5} mobile={16} textAlign="center">
                <EMRButton
                  onClick={() => handleClick(id)}
                  fullSized
                  fontSize="lg"
                  disabled={!isClassroomEnabled}
                >
                  <IoPlay size={24} /> CONTINUAR
                </EMRButton>
              </Grid.Column>
            </Grid>
            <ClassroomProgress
              value={meta.progress}
              lessonsWatchedCount={meta.lessonsWatchedCount}
              lessonsCount={meta.lessonsCount}
            />
          </Grid.Column>
        </Grid>
      </div>
    </>
  );
}
