import { TooltipContainer, Tooltip, TooltipTitle } from "./styles";
import React from "react";

function checkBrowserCompatibility() {
  const userAgentString = window.navigator.userAgent;
  const isSafariAgent = userAgentString.indexOf("Safari") > -1;
  const isChromeAgent = userAgentString.indexOf("Chrome") > -1;
  const isFirefoxAgent = userAgentString.indexOf("Mozilla") > -1;

  if (isChromeAgent) return true;
  if (isSafariAgent || isFirefoxAgent) return false;

  return true;
}

export function MedTrackTooltip({
  text,
  children,
  title,
  isHidden = false,
  forceMount = false,
  variant,
  isBases = false,
  width = "fit-content",
}) {
  if (isHidden) return <>{children}</>;
  const isBrowserSupported =
    variant === "todoLocked" ? checkBrowserCompatibility() : true;

  const variantSides = {
    todoLocked: "top",
    todoProgress: "right",
    todoItemProgress: "right",
    titleInfo: "right",
    recalculateButton: "left",
    subjectModal: "right",
    bigArea: "bottom",
    frialTimeAvailability: "right",
  };

  return (
    <TooltipContainer side={variantSides[variant]} forceMount={forceMount}>
      {children}
      <Tooltip
        hasTitle={Boolean(title)}
        width={width}
        variant={variant}
        side={variantSides[variant]}
        isBrowserSupported={isBrowserSupported}
        isBases={isBases}
      >
        {Boolean(title) && <TooltipTitle>{title}</TooltipTitle>}
        <span>{text}</span>
      </Tooltip>
    </TooltipContainer>
  );
}
