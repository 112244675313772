import React, { useEffect, useRef } from "react";

import { Container } from "./styles";
import { useField } from "@unform/core";

export function Checkbox({ name, value = false, checked, ...props }) {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField } = useField(name);

  const defaultChecked = defaultValue === value;

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => {
        return ref.current.checked;
      },
      clearValue: (ref) => {
        ref.current.checked = defaultChecked;
      },
      setValue: (ref, value) => {
        ref.current.checked = value;
      }
    });
  }, [defaultValue, fieldName, registerField, defaultChecked]);

  return (
    <Container>
      <input
        {...props}
        ref={inputRef}
        defaultChecked={defaultChecked}
        value={value}
        type="checkbox"
        id={fieldName}
        checked={checked}
      />
    </Container>
  );
}
