import styled from "styled-components";
import { colors } from "../../../../../../components/library/theme";




export const Info = styled.span`
  font-size: 14px;
  line-height: 21px;
  color: ${colors.medtrack.grey500};
`;

export const ChangeNumber = styled.button`
  width: 100%;
  height: 48px;
  border-radius: 4px;
  background-color: ${colors.medtrack.brand};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ChangeNumberText = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: white;
`;

export const Back = styled.span`
  color: ${colors.medtrack.brand};
  font-size: 16px;
`;

export const Phone = styled.span`
  font-weight: bold;
  color: ${colors.medtrack.grey500};
`;

export const Input = styled.input`
  border: 1px solid #c2ced6;
  border-radius: 4px;
  height: 48px;
  width: 40px;
  text-align: center;
  color: black;
  caret-color: #c2ced6;
  font-size: 14px;
  ::placeholder {
    color: #c2ced6;
    text-align: center;
    font-size: 14px;
  }
  &:focus {
    outline: none;
  }
`;

export const InputsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 24px;
`;

export const Edit = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;

export const EditButton = styled.button`
  height: 32px;
  width: 32px;
  background-color: ${colors.medtrack.grey100};
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
