import { createSelector } from "reselect";

const getNotificationsUsers = (state) => state.entities.notificationsUsers;
const getId = (_, id) => id;

export const getAllNotificationsUsers = createSelector(
  getNotificationsUsers,
  (notificationsUsers) => Object.values(notificationsUsers)
);

export const getNotificationsUserByNotification = createSelector(
  getAllNotificationsUsers,
  getId,
  (notificationsUsers, id) =>
    notificationsUsers.find(
      (notificationsUser) => notificationsUser.notificationId == id
    )
);

export const getNotificationsUsersOnlyUnread = createSelector(
  getAllNotificationsUsers,
  (notificationsUsers) =>
    notificationsUsers.filter((notificationsUser) => !notificationsUser.read)
);
