import { apiGet } from "./helpers/api";

export const fetchCertificate=(dispatch, classroomId, userId) => {

  apiGet(
    `/certificate?user_id=${userId}&classroom_id=${classroomId}`
  ).then((response) => {
    dispatch({
      type: "CERTIFICATE_FETCHED",
      entities: {
        certificate: response.body,
      },
    });
  }).catch(() => {
    dispatch({
      type: "CERTIFICATE_NOT_FOUND"
    })
  })
};