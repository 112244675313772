import styled from "styled-components";
import { colors } from "../../../components/library/theme";
import { EMRButton } from "../../Button";

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 24px;
`;
export const Title = styled.h1`
  color: ${colors.interface.grey400};
  font-size: 24px;
  text-align: center;
  margin: 0;
`;
export const Text = styled.h2`
  color: ${colors.interface.grey400};
  font-size: 16px;
  text-align: center;
  max-width: 514px;
  font-weight: 400;
`;
export const Button = styled(EMRButton)`
  width: 250px;
  height: 49px;
  font-size: 16px;
  font-weight: 600;

  svg {
    min-width: 24px;
    min-height: 24px;
  }
`;
export const IllustrationWrapper = styled.div`
  margin: 24px 0;

  .illustration {
    width: 332px;
  }

  @media (max-width: 991px) {
    .illustration {
      width: 311px;
      margin: 48px 0;
    }
  }
`;
