const initialState = null;

export default function userSignInAttemptFailsReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case "SIGN_IN_ATTEMPT_FAILS":
      return action.value;

    default:
      return state;
  }
}
