import styled, { css } from "styled-components";
import { colors } from "../../../components/library/theme";

function gestStepBgColor(currentStep) {
  if (currentStep === "initial") {
    return {
      bg: colors.interface.grey100,
      icon: colors.interface.grey200,
      border: "transparent",
    };
  }

  return {
    bg: colors.status.green000,
    icon: colors.status.green200,
    border: colors.status.green200,
  };
}

export const StepsWrapper = styled.div`
  width: 100%;
  max-width: 532px;
  align-items: center;
  justify-content: space-between;
  display: flex;
  position: relative;
`;

export const StepContainer = styled.div(
  ({ isActive }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    span {
      font-size: 14px;
      font-weight: ${({ isActive }) => (isActive ? "600" : "400")};
      color: ${isActive ? "#35BD78" : "#606A71"};
      max-width: 102px;
      line-height: 21px;
      text-align: center;
    }
    @media screen and (max-width: 768px) {
      span {
        font-size: 12px;
        line-height: ${({ isActive }) => (isActive ? "15.6px" : "18px")};
      }
    }
  `
);
export const Step = styled.div(
  ({ isActive, currentStep }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border: 1px solid transparent;
    border-radius: 999px;
    background-color: ${isActive
      ? colors.status.green200
      : gestStepBgColor(currentStep).bg};
    border-color: ${isActive
      ? "transparent"
      : gestStepBgColor(currentStep).border};

    svg {
      color: ${isActive
        ? colors.interface.white000
        : gestStepBgColor(currentStep).icon};
    }

    @media screen and (max-width: 768px) {
      width: 32px;
      height: 32px;
    }
  `
);

export const Line = styled.hr`
  border: 1px solid ${colors.status.green200};
  position: absolute;
  width: 80%;
  top: 18px;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
  @media screen and (max-width: 768px) {
    top: 9px;
  }
`;
