import styled from 'styled-components';
import { colors } from '../../library/theme';

export const Backdrop = styled.div`
  align-items: center;
  background: rgba(54, 62, 70, 0.3);
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  overflow: auto;
  z-index: 1001;

  @media (max-width: 991.98px) {
    padding: 24px;
  }
`;
export const Wrapper = styled.div`
  width: 100%;
  margin: auto;
  height: auto;
`;
export const Modal = styled.div`
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 32px;
  max-width: 832px;
  padding: 24px;
  width: 100%;
  margin: 64px auto 56px;

  @media (max-width: 768px) {
    > svg {
      scale: 0.8;
      margin-bottom: -48px;
    }
  }

  @media (max-width: 991.98px) {
    gap: 24px;
    padding: 24px;
    margin: 0;
  }
`;
export const Link = styled.button`
  background-color: ${colors.brand.pure};
  border: none;
  color: #fff !important;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  border-radius: 4px;
  text-align: center;
  width: 100%;
`;
export const Info = styled.p`
  font-size: 16px;
  line-height: 1.5;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;
export const ImageWrapper = styled.div`
  width: 326px;
  height: 326px;
  display: flex;
`;
export const InfosWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
export const Title = styled.h1`
  color: ${colors.brand.pure};
  font-size: 32px;
  font-weight: 600;
  line-height: 1.3;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 24px;
  }
`;
export const Subtitle = styled.h3`
  font-size: 16px;
  line-height: 1.3;
  font-weight: 600;
  color: #606a71;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;
