import { createSelector } from "reselect";
import { makeIsWatched } from "./lessonsUserSelectors";

const getRatings = (state) => state.entities.ratings;
const getId = (_, id) => id;
const getProps = (_, props) => props;

const getAllRatings = createSelector(getRatings, (ratings) =>
  Object.values(ratings)
);

const getLessonRatings = createSelector(
  getAllRatings,
  getProps,
  (ratings, { lessonId }) =>
    ratings.filter(
      (rate) =>
        rate.ratingableType === "Lesson" && rate.ratingableId === lessonId
    )
);

export const getLessonRating = createSelector(getLessonRatings, (ratings) => {
  return ratings[0];
});

const getQuestionRatings = createSelector(
  getAllRatings,
  getId,
  (ratings, ratingableId) => {
    return ratings.filter(
      (rate) =>
        rate.ratingableType === "Question" && rate.ratingableId === ratingableId
    );
  }
);

export const getQuestionRating = createSelector(
  getQuestionRatings,
  (ratings) => Object.values(ratings)[0]
);

export const makeIsLessonRated = createSelector(getLessonRating, (rating) => {
  return !!rating;
});

export const makeIsRatingPending = createSelector(
  makeIsWatched,
  getProps,
  makeIsLessonRated,
  (isWatched, { lessonId }, isRated) => {
    return lessonId && isWatched && !isRated;
  }
);
