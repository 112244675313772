import React from "react";
import { Checkbox } from "semantic-ui-react";
import { spacing } from "../theme";

const container = {
  paddingBottom: spacing.xxs,
};

export default ({ item, onChange, fields, disabled }) => {
  return (
    <div style={container}>
      <Checkbox
        id={`${item.name}-${item.id}`}
        name={String(item.id)}
        label={`${item.name || item.title}`}
        onChange={onChange}
        checked={(fields?.getAll() || []).indexOf(item.id) > -1}
        disabled={disabled}
      />
    </div>
  );
};
