import React from 'react';
import { ClosedAlternative } from './ClosedAlternative';
import { OpenAlternative } from './OpenAlternative';

export function Alternative({ alternative, questionData }) {
  const isOpenAlternative = questionData.question.question_type === 'text';
  
  if (isOpenAlternative) {
    const openQuestionAnswer = questionData.user.answer.alternatives.find(answer => answer.question_id === alternative.id)

    return (
      <OpenAlternative
        alternative={alternative}
        hasAlternatives={questionData.alternatives.length > 0}
        questionAnswer={openQuestionAnswer}
      />
    );
  } else {
    return (
      <ClosedAlternative
        alternative={alternative}
        questionData={questionData}
      />
    );
  }
}
