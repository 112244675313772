import React, { useState } from "react";
import { EMRButton } from "../../../Button";
import Dropdown from "../../../Dropdown";
import warning from "../../assets/warning.svg";
import { TEMPORARY_ACCESS_DAY } from "../../enum";

import {
  ButtonContainer,
  Content,
  Description,
  Paragraph,
  ReturnButton,
  Title,
  WarningContainer,
  WarningText
} from "../../styles";
import { PrimaryButtonContainer } from "./styles";

const dropdownOptions = ["1 dia", "3 dias", "5 dias"];

export default function AlternativeAccessScreen({
  handleToTemporaryAccess,
  handleToOpenEmergencyAccessModal,
  hasTemporaryAccess,
  children
}) {
  const [temporaryAccessDay, setTemporaryAccessDay] = useState(
    dropdownOptions[0]
  );
  return (
    <Content>
      <Description>
        <Title>Acessos alternativos</Title>
        <Paragraph>
          Caso você esteja enfrentando problemas com seu acesso, utilize um dos
          acessos alternativos. Com o acesso temporário você confirma via app e
          continua seus estudos, ou opte pelo acesso emergencial.
        </Paragraph>
      </Description>

      <WarningContainer>
        <img src={warning} />
        <WarningText>
          Você pode optar por 1, 3 ou 5 dias para utilizar um acesso temporário
          nesse dispositivo. Após esse período você será desconectado.
        </WarningText>
      </WarningContainer>

      <ButtonContainer>
        <PrimaryButtonContainer>
          <Dropdown
            style={{ width: 120 }}
            options={dropdownOptions}
            selected={temporaryAccessDay}
            setSelected={setTemporaryAccessDay}
            disabled={hasTemporaryAccess}
            isFullHeight={true}
          />
          <EMRButton
            fullSized
            fontSize={"lg"}
            mediumWeight
            onClick={() =>
              handleToTemporaryAccess(TEMPORARY_ACCESS_DAY[temporaryAccessDay])
            }
            disabled={hasTemporaryAccess}
          >
            Acesso temporário
          </EMRButton>
        </PrimaryButtonContainer>
        <ReturnButton
          fullSized
          fontSize={"lg"}
          mediumWeight
          mode="transparent"
          onClick={handleToOpenEmergencyAccessModal}
        >
          Acesso emergencial
        </ReturnButton>
      </ButtonContainer>

      {children}
    </Content>
  );
}
