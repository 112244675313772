import styled from "styled-components";
import { colors } from "../../../components/library/theme";
import * as Dialog from "@radix-ui/react-dialog";

export const DialogOverlay = styled(Dialog.Overlay)`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: fixed;
  overflow: auto;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: rgba(54, 62, 70, 0.3);
  z-index: 10;
  padding: 0;
`;

export const DialogContent = styled(Dialog.Content)`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background-color: ${colors.interface.white000};
  padding: 32px 24px 24px 24px;
  width: min(460px, calc(100vw - 48px));
  margin: auto;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

export const DialogTitle = styled.div`
  h2 {
    color: ${colors.interface.grey500};
    font-size: 16px;
    margin: 0;
  }
`;

export const DialogClose = styled(Dialog.Close)`
  cursor: pointer;

  svg {
    color: ${colors.interface.grey400};
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;

  button {
    width: 242px;
    height: 48px;
    font-weight: 600;
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column-reverse;
    width: 100%;

    button {
      width: 100%;
    }
  }
`;
