import { getCookie } from "cookies-next";
import { detectPlatform } from "./detectPlatform";

export async function requestTemporaryAccess(temporaryAccessDay, fingerprint) {
  const response = await fetch(
    `${process.env.USER_PROXY_URL}/auth/temporary-access`,
    {
      method: "POST",
      headers: {
        "api-token": process.env.USER_PROXY_API_TOKEN,
        "Content-Type": "application/json",
        "access-date": new Date().toISOString(),
        product: "PLATAFORMA_EMR",
      },
      body: JSON.stringify({
        email: getCookie("email"),
        platform: detectPlatform(),
        period: Number(temporaryAccessDay),
        fingerprint,
      }),
    }
  );
  return response;
}
