import React, { useState, useEffect } from "react";
import AnnotationsBookPage from "./AnnotationsBookPage";
import Spinner from "../library/Spinner";
import { connect } from "react-redux";
import { isExpiredUser } from "../../selectors/userSelectors";

function AnnotationsBookContainer({ expiredUser }) {
  const [annotations, setAnnotations] = useState({
    specialties: [],
    current_page: 0,
    totalPages: 0,
  });
  const [isLoadingAnnotations, setIsLoadingAnnotations] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("ALL");

  function fetchAnnotations({ bigAreaFilter, page }) {
    const session = JSON.parse(localStorage.getItem("session"));
    const userId = session.currentUserId;

    const dataValues = {
      user_id: userId,
      page: page,
      bigArea: bigAreaFilter,
    };

    setIsLoadingAnnotations(true);

    try {
      fetch("/api/v1/notes/findNotesUser", {
        method: "POST",
        body: JSON.stringify(dataValues),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            setAnnotations(data);
          }

          setIsLoadingAnnotations(false);
        });
    } catch {
      setIsLoadingAnnotations(false);
    }
  }

  useEffect(() => {
    if (!expiredUser) fetchAnnotations({ bigAreaFilter: "", page: 1 });
  }, []);

  if (expiredUser) return null;

  return (
    <AnnotationsBookPage
      annotations={annotations}
      selectedFilter={selectedFilter}
      setSelectedFilter={setSelectedFilter}
      isLoadingAnnotations={isLoadingAnnotations}
      fetchAnnotations={fetchAnnotations}
    />
  );
}

function mapStateToProps(state) {
  const expiredUser = isExpiredUser(state);

  return {
    expiredUser,
  };
}

export default connect(mapStateToProps)(AnnotationsBookContainer);
