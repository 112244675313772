import { css } from 'glamor'
import React from 'react'
import { Grid } from 'semantic-ui-react'
import { getThemeByUniversity } from '../../utils/getThemeByUniversity'
import Image from '../library/Image'
import Logo from '../library/images/logo.svg'
import { spacing, uiColors } from '../library/theme'

const { IES, universityTheme } = getThemeByUniversity()
const bgImage = universityTheme.get(IES ? IES : 'default').bg
const gridClass = css({ marginTop: '0 !important' })
const wrapper = css({
  maxWidth: 450,
  margin: 'auto',
  marginTop: spacing.xxl,
  paddingLeft: spacing.m
})
const rightColumn = css({
  height: '100vh',
  backgroundImage: `url(${bgImage})`,
  backgroundColor: uiColors.background,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '50% 50%',
  backgroundSize: 'cover'
})
const logoStyle = { marginBottom: spacing.m }

export default function LobbyLayout({ children }) {
  return (
    <Grid
      textAlign="center"
      verticalAlign="middle"
      className={gridClass.toString()}
    >
      <Grid.Column computer={7} mobile={16}>
        <div className={wrapper}>
          <Grid columns="equal">
            {!IES && (
              <Grid.Column>
                <Image src={Logo} size="small" style={logoStyle} centered />
              </Grid.Column>
            )}
          </Grid>
          {children}
        </div>
      </Grid.Column>
      <Grid.Column
        computer={9}
        only="computer"
        className={rightColumn.toString()}
      ></Grid.Column>
    </Grid>
  )
}
