import { css } from "glamor";
import React, { useCallback, useState } from "react";
import Viewer from "react-viewer";
import { Image } from "semantic-ui-react";
import { uiColors } from "./theme";

const ZOOM_SPEED = 0.1;

const imageClass = css({
  border: `1px solid transparent`,
});

const previewClass = css({
  cursor: "zoom-in",
  ":hover": {
    border: `1px solid ${uiColors.background}`,
  },
});

export default (props) => {
  const { src, alt, preview } = props;
  const [visible, setVisible] = useState(false);

  const toogleVisibleHandler = useCallback(() => {
    setVisible(!visible);
  }, [visible]);

  return (
    <>
      {preview && (
        <Viewer
          visible={visible}
          onClose={toogleVisibleHandler}
          images={[{ src, alt }]}
          zoomSpeed={ZOOM_SPEED}
          showTotal={false}
          scalable={false}
          changeable={false}
          noImgDetails
          onMaskClick={toogleVisibleHandler}
          noNavbar
        />
      )}
      <Image
        onClick={preview ? toogleVisibleHandler : undefined}
        className={css(imageClass, preview && previewClass).toString()}
        size={preview ? "medium" : undefined}
        rounded={preview}
        {...props}
      />
    </>
  );
};
