import React, { useContext } from 'react';
import {
  Button,
  Container,
  FeaturesWrapper,
  RecommendedTag,
  Tag,
  TagsWrapper,
  Text,
  Title,
} from './styles';
import { Feature } from './feature';
import { context } from '../../context';
import { trackClickEvents } from '../../../../../services/mixpanel/events/trackClickEvents';
import { useDispatch } from 'react-redux';
import { hasMedtrackEnabled, isMedTrackFirstAccess, requestSchedules } from '../../../../processes/medTrackProcess';
import { fetchClassroms } from '../../../../processes/classroomProcesses';
import { getPurchasedLevels } from '../../../../processes/levelProcesses';

export function Card({
  highlight,
  id,
  classroom_id,
  title,
  description_1,
  description_2,
  description_3,
  organizers,
}) {
  const { setSelectedClassRoom, finish } = useContext(context);
  const dispatch = useDispatch();

  function getSlug(organizer) {
    const parts = organizer.split(' ');
    const isMainNationalTests = parts[0] === 'PRINCIPAIS';

    return isMainNationalTests ? organizer : parts[0];
  }

  function selectClassroom() {
    isMedTrackFirstAccess(dispatch);
    hasMedtrackEnabled(dispatch);
    requestSchedules(dispatch);
    setSelectedClassRoom({ id, classroom_id });
    finish(classroom_id);
    trackClickEvents({
      eventName: 'Clique em Selecionar (Tela sugestão de curso step 2)',
    });
  }

  return (
    <Container highlight={highlight}>
      {highlight && <RecommendedTag>+ Recomendado</RecommendedTag>}

      <Title>{title}</Title>

      <FeaturesWrapper>
        {description_1 && <Feature>{description_1}</Feature>}
        {description_2 && <Feature>{description_2}</Feature>}
        {description_3 && <Feature>{description_3}</Feature>}
      </FeaturesWrapper>

      <TagsWrapper>
        <Text>Quais bancas iremos focar?</Text>
        {organizers.map((organizer) => (
          <Tag key={organizer}>{getSlug(organizer)}</Tag>
        ))}
      </TagsWrapper>

      <Button onClick={selectClassroom} highlight={highlight}>
        Selecionar
      </Button>
    </Container>
  );
}
