import React from "react";
import QuestionPaginationItemContainer from "./QuestionPaginationItemContainer";

const container = {
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  flexWrap: "wrap",
  maxWidth: "100%",
};

export default function QuestionPagination({
  questions,
  isExam,
  isFinished,
}) {
  return questions.length ? (
    <div style={container}>
      {questions.map((question, index) => (
        <QuestionPaginationItemContainer
          key={index}
          index={index}
          question={question}
          isExam={isExam}
          isFinished={isFinished}
        />
      ))}
    </div>
  ) : null;
}
