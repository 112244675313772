import React from "react";
import { MobileFilter } from "./MobileFilter";
import { DesktopFilter } from "./DesktopFilter";
import { useMedTrack } from "../../hooks/useMedTrack";

export function TodoItemsStatusAndPeriodFilter({
  onFilter,
  isR3CM,
  isRevision,
}) {
  const {
    setPeriodFilter,
    setStatusFilter,
    setBigAreaFilter,
    setOrderByStartDate,
  } = useMedTrack();

  function handleFilterChanges(event) {
    const { value: filterValue, name: filterName } = event.target;
    if (filterName === "period") return setPeriodFilter(filterValue);
    if (filterName === "status") return setStatusFilter(filterValue);
    if (filterName === "bigArea") return setBigAreaFilter(filterValue);
    if (filterName === "startDate") return setOrderByStartDate(filterValue);
  }

  return (
    <>
      <DesktopFilter
        onChangeFilters={handleFilterChanges}
        onFilter={onFilter}
        isR3CM={isR3CM}
        isRevision={isRevision}
      />

      <MobileFilter
        isR3CM={isR3CM}
        onChangeFilters={handleFilterChanges}
        onFilter={onFilter}
        isRevision={isRevision}
      />
    </>
  );
}
