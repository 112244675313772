import React from 'react'
import { IoSchool } from 'react-icons/io5'
import { colors } from '../../../components/library/theme'
import { TagContainer } from './styles'

export function Tag({ text }) {
  return (
    <TagContainer>
      <IoSchool size={12} color={colors.brand.pure} />
      <span>{text}</span>
    </TagContainer>
  )
}