import React from "react";
import { css } from "glamor";
import { spacing, typographyColors, uiColors } from "./theme";

const container = css({
  maxHeight: "60vh",
  overflow: "auto",
  width: "100%",
  backgroundColor: uiColors.backgroundDarkest,
  position: "relative",
  paddingTop: "55%",
  "@media(max-width: 550px)": {
    paddingTop: "150%",
  },
});
const content = css({
  position: "absolute",
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
});
const errorClass = css({
  padding: spacing.xxl,
  color: typographyColors.inverseTitle,
});
const pdfWrapper = css({
  height: "100%",
  width: "100%",
});

export default function PDF({ file }) {
  return (
    <div className={container}>
      <div className={content}>
        {file ? (
          <iframe
            height="100%"
            width="100%"
            src={file}
            className={pdfWrapper}
          />
        ) : (
          <h4 className={errorClass}>PDF não encontrado</h4>
        )}
      </div>
    </div>
  );
}
