import styled from "styled-components";
import { colors } from "../../../../components/library/theme";

export const WarningContainer = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  gap: 8px;
  padding: 16px;
  background-color: #f7fafc;
  border-radius: 4px;
  border: 1px solid #edf0f2;

  span {
    font-weight: 400;
    line-height: 21px;
    font-size: 14px;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  height: 48px;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 16px;

  input {
    width: 100%;
    padding: 12px 16px;
    border-radius: 4px;
    border: 1px solid ${colors.interface.grey400};
    color: ${colors.interface.grey500};
    flex-grow: 1;
  }
`;

export const ButtonContainer = styled.div`
  width: 260px;
  & > button {
    width: 100%;
  }
`;

export const Illustration = styled.img`
  object-fit: contain;
  height: 200px;
`;
