import moment from "moment";
moment.locale("pt-BR");

export default function formatDate(date, format = "") {
  return date ? moment(date).format(format) : "";
}

export const fromNow = (date) => moment(date).fromNow();

export const isAfterToday = (date) => new Date(date) >= new Date();

export const isBeforeToday = (date) => new Date(date) < new Date();

export const convertDateToString = (isoDate) => {
  const monthsDictionary = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
  const formattedDate = new Date(isoDate)
  const day = formattedDate.getDate()
  const month = monthsDictionary[formattedDate.getMonth()]
  const year = formattedDate.getFullYear()
  return `${day} de ${month.toLowerCase()} de ${year}`
}