import styled from "styled-components";
import { colors } from "../../../../../../components/library/theme";

export const Container = styled.div`
  border: solid 1px ${colors.medtrack.grey100};
  border-radius: 4px;
  overflow: hidden;

  @keyframes showOverflow {
    to {
      overflow: visible;
    }
  }

  &[data-open="true"] {
    animation: showOverflow 500ms forwards;
  }

  @media (max-width: 640px) {
    &[data-open="true"] {
      overflow: visible;
    }
  }
`;
export const AccordionTitle = styled.div`
  cursor: pointer;
  display: flex;
  gap: 16px;
  font-size: 12px;
  padding: 14px 16px;
  align-items: center;
  font-weight: 600;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  background-color: ${colors.medtrack.grey000};

  span {
    user-select: none;
  }

  svg {
    transition: 500ms;
    min-height: 24px;
    margin-left: auto;
    transform: ${(props) => props.isOpen && "rotate(180deg)"};

    @media (max-width: 640px) {
      transition: 300ms;
    }
  }

  @media (max-width: 991px) {
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    gap: 8px;

    svg {
      position: absolute;
      right: 16px;
    }
  }
`;
export const AccordionContent = styled.div`
  transition: 300ms;
  max-height: ${({ maxHeight }) => maxHeight}px;

  @media (max-width: 640px) {
    transition: 300ms;
  }
`;
export const Tag = styled.span`
  display: flex;
  height: 16px;
  justify-content: center;
  align-items: center;
  padding: 0 8px;
  background-color: ${({ color }) => color};
  font-size: 12px;
  color: #fff;
  border-radius: 12px;

  @media (max-width: 991px) {
    font-size: 10px;
  }
`;
export const DateWrapper = styled.div`
  display: flex;
  gap: 16px;
`;
