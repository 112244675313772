import React from "react";
import { Container } from "./styles";

export default function MockStatus({ value, isFinished }) {
  function getColor() {
    if (isFinished) return "completed";
    if (value === 0 || value === null) return "pending";
    if (value > 0 && value < 1) return "onGoing";
  }

  const getStatus = () => {
    if (isFinished) return "Finalizado";
    if (value === 0 || value === null) return "Pendente";
    if (value > 0 && value < 1) return "Em andamento";
  };

  const status = getStatus();
  const statusColors = getColor();

  return (
    <>
      <Container mode={statusColors}>{status}</Container>
    </>
  );
}
