import React from "react";
import { Bar, Container } from "./styles";

export function ProgressBar({ progress, isSecondPhase }) {
  return (
    <Container>
      <Bar value={progress} isSecondPhase={isSecondPhase} />
    </Container>
  );
}
