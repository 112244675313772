import React from "react";
import { IoArrowForward, IoLockClosed } from "react-icons/io5";
import { ArrowButton, LockedButton } from "../styles";

export function TodoButton({ isLocked, onClick }) {
  if (isLocked) {
    return (
      <LockedButton>
        <IoLockClosed fill="#9BA5AB" size={18} />
      </LockedButton>
    );
  }

  return (
    <ArrowButton onClick={onClick}>
      <IoArrowForward stroke="#fff" size={18} />
    </ArrowButton>
  );
}
