import styled from "styled-components";
import { colors } from "../../../../../components/library/theme";
import { EMRButton } from "../../../../Button";

export const ContainerStartMedtrackScreen = styled.section`
  display: flex;
  gap: 20px;
  flex-direction: column;
  margin: 0 auto;
  min-height: calc(100vh - 70px);
  padding: 24px;
`;

export const Container = styled.div`
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 991px) {
    padding: 16px;
  }
`;

export const Button = styled(EMRButton)`
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  height: 56px;

  svg {
    min-height: 24px;
    min-width: 24px;
  }

  @media (max-width: 991px) {
    height: 40px;
    font-size: 14px;
  }
`;
export const Wrapper = styled.div`
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 720px;
  width: 100%;

  .illustration {
    margin-bottom: 40px;
  }

  hr:first-of-type {
    margin-bottom: 24px;
  }

  hr:last-of-type {
    display: none;
  }

  @media (max-width: 991px) {
    .illustration {
      max-height: unset;
      margin-bottom: 40px;
    }

    hr:last-of-type {
      display: block;
      margin: 16px 0;
    }

    div:last-of-type {
      margin-bottom: 0;
    }
  }
`;
export const Title = styled.h2`
  margin: 0;
  font-size: 24px;
  font-weight: 600;
  color: ${colors.interface.grey400};

  @media (max-width: 991px) {
    font-size: 14px;
  }
`;
export const Info = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.interface.grey400};

  @media (max-width: 991px) {
    font-size: 14px;
  }
`;
export const Hr = styled.hr`
  width: 100%;
  margin: 0;
  border: solid 1px ${colors.interface.grey100};
  border-bottom: 0;
`;
export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  margin-bottom: 8px;
`;

export const ProgressContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
  height: 100%;
  margin-bottom: 24px;
`;

export const Checkbox = styled.div`
  width: 14px;
  height: 14px;
  padding: 0;
  border: solid 1px ${colors.brand.pure};
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.brand.pure};
`;

export const QuestionsContainer = styled.span`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.width};
`;

export const Instructions = styled.p`
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  color: ${colors.interface.grey400};
  line-height: 1.5;
  margin-bottom: 8px;
`;

export const ProgressText = styled.p`
  font-size: 12px;
`;
