import React from "react";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import LessonRatingForm from "./LessonRatingForm";
import { getCurrentLesson } from "../../selectors/lessonSelectors";
import { createLessonRating } from "../../processes/ratingProcesses";
import { getLessonRating } from "../../selectors/ratingSelectors";
import { makeIsWatched } from "../../selectors/lessonsUserSelectors";
import { getCurrentClassroomId } from "../../selectors/classroomSelectors";

function LessonRatingFormContainer(props) {
  const { rated, isWatched, canRate, lesson, lessonType } = props;

  if (rated || !isWatched || !canRate || !lesson || lessonType == "mock") {
    return null;
  }

  return <LessonRatingForm {...props} />;
}

const getRatingType = (lessonType) => {
  switch (lessonType) {
    case "video":
      return "video_lesson";
    case "pdf":
      return "pdf_lesson";
    default:
      return null;
  }
};

function mapStateToProps(state) {
  const lesson = getCurrentLesson(state, state.currentLessonId);
  const ratingType = getRatingType(lesson?.lessonType);
  return {
    initialValues: {},
    lesson,
    lessonType: lesson?.lessonType,
    ratingableId: lesson?.id,
    ratingType,
    rated: getLessonRating(state, { lessonId: lesson?.id }),
    isWatched: makeIsWatched(state, { lessonId: lesson?.id }),
    canRate: lesson?.ratingEnabled,
    classroomId: getCurrentClassroomId(state),
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    destroyOnUnmount: false,
    onSubmit: createLessonRating,
  })(LessonRatingFormContainer)
);
