export function cleanHTMLTags(text) {
  const doc = document.createElement("div");
  doc.innerHTML = text;
  const HTMLText = doc.children[0].innerHTML;

  if (doc.children[0].childNodes[0].tagName) {
    const parent = doc.children[0];
    const child = parent.children[0];
    return child.innerHTML.replace(/([()])/g, "");
  }

  return HTMLText.replace(/([()])/g, "");
}

export function getQuestionTitle(questionTitle) {
  return cleanHTMLTags(questionTitle);
}
