import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

export default function Flashcards() {
  const iframeRef = useRef(null);
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 100);
  }, []);

  function onIframeLoaded() {
    const iframe = iframeRef.current;
    if (iframe && window) {
      const lobbyId = query.get('lobbyId');
      const deckId = query.get('deckId');
      const redirectTo = query.get('redirectTo');

      if (!lobbyId || !deckId) return;
      setTimeout(() => {
        iframe.contentWindow.postMessage(
          { type: 'data', lobbyId, deckId, redirectTo },
          '*'
        );
      }, 500);
    }
  }

  useEffect(() => {
    const unlisten = history.listen(() => {
      if (iframeRef.current) {
        iframeRef.current.src = process.env.FLASHCARDS_URL;
      }
    });
    return () => unlisten();
  }, [history]);

  return (
    <div>
      {!loading && (
        <iframe
          onLoad={onIframeLoaded}
          ref={iframeRef}
          src={process.env.FLASHCARDS_URL}
          width="100%"
          style={{ height: 'calc(100vh - 80px)', border: 'none' }}
          sandbox="allow-same-origin allow-scripts allow-popups"
        />
      )}
    </div>
  );
}
