const initialState = {
  isB2BUserExamInProgress: true
}

export default function contentBlockReducer(state = initialState, action) {

  switch (action.type) {
    case "CONTENT_BLOCKED":
      return (
        {
          ...state, isB2BUserExamInProgress: true
        }
      )
    case 'CONTENT_AVAILABLE':
      return (
        {
          ...state, isB2BUserExamInProgress: false
        }
      )
    default:
      return state;
  }
}
