import React from "react";
import { Form, Segment, Message } from "semantic-ui-react";

export default function FormComponent(props) {
  const {
    children,
    style,
    error,
    message,
    messageType,
    messageVisible,
    ...rest
  } = props;
  return (
    <Segment textAlign="left" style={style} padded>
      {error && (
        <Message
          header={error ? "Oops, algo deu errado!" : undefined}
          list={Array.isArray(error) ? error : [error]}
          error
        />
      )}
      {message && (
        <Message
          content={message}
          visible={messageVisible}
          success={messageType === "success"}
          error={messageType === "error"}
          warning={messageType === "warning"}
        />
      )}
      <Form {...rest} error={!!error}>
        {children}
      </Form>
    </Segment>
  );
}
