import { mixpanel } from "..";
// @ts-check
/**
 * @param {{
 * eventName: string,
 * content?: object
 * }} eventData
 */
export function trackClickEvents({
  eventName,
  content = {},
  tagName = "Plataforma"
}) {
  mixpanel.track(`${tagName} - ${eventName}`, content);
}
