import { Link } from "react-router-dom";
import React from "react";
import { getThemeByUniversity } from "../../utils/getThemeByUniversity"

const { IES } = getThemeByUniversity()
const enrollmentURL = IES === 'revalida' ? process.env.REVALIDA_ENROLLMENT_URL : process.env.DEFAULT_ENROLLMENT_URL

export const faqEMR = [
  {
    question:
      "Posso tirar dúvidas com os professores? Há alguma interação, além das aulas gravadas?",
    answer:
      <>
        <p>
          Sim! A EMR criou uma comunidade que permite a interação entre alunos, professores e suporte técnico. Nossa comunidade é privada, exclusiva para alunos e está ativa no Discord.
        </p>

        <br />
        <p>
          O Discord é uma ferramenta grátis de conversas via chat, vídeo e voz muito utilizada por comunidades que precisam interagir e trocar informações. Ele  reúne diversas comunidades de vários tamanhos e com variados propósitos, indo desde projetos escolares, entretenimento, desenvolvimento de pessoas e até dicas corporativas.
        </p>

        <br />
        <p>
          Muito versátil, você pode acessar diretamente no navegador do seu Desktop, Tablet ou Smartphone, independente de qual utiliza (Chrome, Safari, Opera etc.) e de qual sistema operacional tenha instalado (Windows, Linux, IOS etc.).
        </p>

        <br />
        <p>
          1) no computador:
        </p>

        <p>
          a) Navegador: acesse www.discord.com e crie uma conta. Se preferir, acesse direto o link da nossa comunidade (você cria a conta e já entra na comunidade: {<Link to={{ pathname: 'https://discord.gg/jDSKMpndbk' }} target="_blank">
            https://discord.gg/jDSKMpndbk
          </Link>}
        </p>

        <p>
          b) Instalando programa para Windows: {<Link to={{ pathname: 'https://apps.microsoft.com/store/detail/discord/XPDC2RH70K22MN?hl=fr-fr&gl=fr&SilentAuth=1&wa=wsignin1.0' }} target="_blank">
            https://apps.microsoft.com/store/detail/discord/XPDC2RH70K22MN?hl=fr-fr&gl=fr&SilentAuth=1&wa=wsignin1.0
          </Link>}
        </p>

        <br />
        <p>
          2) no celular/ tablet:
        </p>

        <p>
          a) IOS: {<Link to={{ pathname: 'https://apps.apple.com/br/app/discord-converse-fale/id985746746' }} target="_blank">
            https://apps.apple.com/br/app/discord-converse-fale/id985746746
          </Link>}
        </p>

        <p>
          b) Android: {<Link to={{ pathname: 'https://play.google.com/store/apps/details?id=com.discord' }} target="_blank">
            https://play.google.com/store/apps/details?id=com.discord
          </Link>}
        </p>
      </>
  },
  {
    question: "O Eu Médico Residente possui apostilas?",
    answer: <p>
      Sim! Nossos cursos Extensivos têm mais de 100 apostilas digitais onde você encontrará textos com leitura fluida, imagens, tabelas, gráficos e várias questões demonstrativas para ajudar a fixar o conteúdo.Tudo pensado para que você acerte o maior número possível de questões no final do ano.Alguns cursos têm outros materiais de apoio como resumos, flashcards e mapas mentais.
    </p>
  },
  {
    question: "Como criar simulados personalizados?",
    answer: <p>
      Em seu ambiente virtual, clique em "simulados" =&gt; no canto superior direito clique em "criar simulados" =&gt; neste momento já conseguirá criar seu simulado usando os filtros que desejar.
    </p>
  },
  {
    question: "Os cursos do Eu Médico Residente possuem certificado?",
    answer: <p>
      Nossos cursos não oferecem certificados por não se tratarem de cursos livres, regulamentados por carga horária.
    </p>
  },
  {
    question:
      "Como posso trocar meu cartão de crédito utilizado para assinaturas recorrentes?",
    answer:
      <>
        <p>
          Para realizar a troca de cartão basta seguir o passo a passo abaixo:
        </p>
        <br />

        <p>
          1 - Acesse {<Link to={{ pathname: 'https://go.eumedicoresidente.com.br/myorders/' }} target="_blank">
            https://go.eumedicoresidente.com.br/myorders/
          </Link>}
        </p>

        <p>
          2 - Clique em Minhas Compras
        </p>

        <p>
          3 - Insira o email cadastrado e clique em Enviar.
        </p>

        <p>
          4 - Acesse seu email, abra a mensagem que você recebeu da plataforma Guru(nosso sistema de cobranças) e clique no botão "Acessar Minhas Compras."
        </p>

        <p>
          5 - Clique em Assinatura e depois na assinatura que está ativa.
        </p>

        <p>
          6 - Passe o mouse por cima do Menu de Ações e clique em "Alterar Forma de Pagamento".
        </p>

        <p>
          7 - Coloque os novos dados para pagamento(cartão).
        </p>

        <p>
          8 - Clique em Enviar.Se precisar de ajuda, chame o Suporte Técnico.
        </p>
      </>
  },
  {
    question: "Qual o horário de atendimento do Suporte?",
    answer: <p>
      De segunda à sexta, das 08h00 às 19h00 e, aos sábados, das 08h00 às 16h00 através do chat online(localizado no botão no canto inferior direito da tela) ou você pode enviar o formulário ao lado.
    </p>
  },
  {
    question: "Como desabilitar o comentário em vídeo dos simulados?",
    answer: <p>
      Em cada simulado no canto superior direito existe um botão chamado "Preferências", ao clicar poderá escolher desabilitar o comentário.
    </p>
  },
  {
    question: "Como posso tirar dúvidas sobre os editais das provas?",
    answer: <p>
      A equipe EMR está a sua disposição para ajudá- lo a entender eventuais dúvidas quanto a itens dos editais, mas recomendamos que todas as dúvidas sejam encaminhadas e validadas pelas Bancas Organizadoras para que você tenha acesso a informações oficiais que não comprometam sua inscrição e postulação.
    </p>
  },
  {
    question: "Quando são emitidas as notas fiscais das minhas mensalidades?",
    answer:
      <>
        <p>
          Em relação a emissão de notas fiscais, a EMR emitirá 12 notas que somam o valor total da compra, sendo uma nota fiscal emitida a cada mês de liberação de curso, independente da quantidade de parcelas que o aluno comprou.
        </p>

        <br />
        <p>
          Por exemplo: se o aluno comprou em 01 /01 / 2023, receberá em seu e - mail cadastrado, 12 notas fiscais(uma em cada mês) à medida que o curso é liberado, independente da forma de pagamento e quantidade de parcelamento do curso.
        </p>
      </>
  }
];

export const faqB2B = [
  {
    question: "Como posso acionar o suporte técnico?",
    answer:
      <>
        <p>
          Pelo ícone de Chat na plataforma ou pelo whatsapp 81 8276 - 3620
        </p>

        <p>
          Nosso atendimento está disponível, de segunda à sexta - feira, das 08h às 19h e, aos sábados, das 08h às 16h.
        </p>

        <p>
          Se preferir, pode enviar sua dúvida, 24h por dia pelo email: contatob2b @eumedicoresidente.com.br ou preencher o formulário ao lado.
        </p>
      </>
  },
  {
    question: "Qual conteúdo estará disponível no meu Extensivo?",
    answer: <>
      <p>
        O conteúdo programático do seu Extensivo foi elaborado de acordo com a demanda acadêmica de sua Faculdade e abordará tópicos organizados pelas cinco grandes áreas da medicina: Clínica Geral, Preventiva, Pediatria, Ginecologia e Obstetrícia e Cirurgia com conteúdos dinâmicos, videoaulas e materiais de apoio para tornar seu aprendizado eficiente e efetivo.
      </p>

      <p>
        Não podemos esquecer dos encontros síncronos para estudarmos conhecimentos gerais
      </p>
    </>
  },
  {
    question: "Não encontrei um conteúdo ou quero sugerir um ajuste ou adição de temas. Como faço?",
    answer:
      <>
        <p>
          O conteúdo programático do seu Extensivo foi elaborado de acordo com a demanda acadêmica de sua Faculdade! Mas toda sugestão é bem vinda! Basta enviar sua sugestão de tema pelo formulário ao lado e nosso time pedagógico avaliará a possibilidade de adição.
        </p>

        <p>
          Da mesma forma, caso perceba que algum conteúdo contém algo inesperado ou um erro, basta usar o botão Reportar Erro ou acionar o nosso suporte pelos canais acima.
        </p>
      </>
  },
  {
    question: "Posso tirar dúvidas com os professores? Há alguma interação, além das aulas gravadas?",
    answer:
      <>
        <p>
          Sim! Diretamente na sua plataforma de estudo, você tem vários caminhos para enviar sua dúvida ou comentário aos nossos professores:
        </p>

        <p>
          Em cada videoaula, existe um campo chamado “deixe seu comentário”. Aqui, os professores têm acesso e podem lhe retornar.
        </p>

        <p>
          Se sua dúvida é em algum Simulado, abaixo de cada questão há dois caminhos de contato: Reportar Erro ou Avaliar o Comentário.Em ambos os casos, nossa equipe de professores e suporte terão acesso a seu feedback ou dúvida e lhe darão o retorno preciso sobre o apontamento indicado.
        </p>

        <p>
          Se preferir, você pode enviar também através do email contatob2b@eumedicoresidente.com.br e nosso time de Suporte fará esta ponte entre você e o professor e lhe ajudará a esclarecer quaisquer questões técnicas ou pedagógicas.`
        </p>
      </>
  },
  {
    question: "O meu Extensivo possui apostilas?",
    answer: <p>
      Ao lado de cada aula, no canto direito, você tem uma série de materiais complementares, entre eles, mapas mentais, materiais de acompanhamento e resumos, que podem ser consultados a qualquer momento, exportados em PDF ou impressos, caso você prefira.
    </p>
  },
  {
    question: "O meu Extensivo possui certificado?",
    answer: <p>
      Nosso curso digital não possui certificado, mas consulte sua faculdade para saber se há maneiras de aproveitamento deste estudo complementar em sua carga horária ou provas.
    </p>
  },
  {
    question: "O que é o módulo de Métricas do meu Extensivo?",
    answer: <p>
      É uma ferramenta muito importante para ajudar a medir o seu desempenho.No módulo de métricas, você poderá atribuir metas de tempo de estudo, de resultados de simulados e exercícios e acompanhar se está conseguindo superar seus objetivos e adequá - los de acordo com sua necessidade.
    </p>
  },
  {
    question: 'Para que serve o módulo “Meu Desempenho”?',
    answer: <p>
      Neste módulo, você poderá ver os resultados de seus simulados e seu desempenho em relação aos outros alunos de sua turma, como forma de lhe oferecer insights para otimizar seus estudos.
    </p>
  },
  {
    question: "Como criar simulados personalizados?",
    answer: <p>
      Em seu ambiente virtual, clique em "simulados" =&gt; no canto superior direito clique em "criar simulados" =&gt; neste momento, já conseguirá criar seu simulado usando os filtros que desejar.
    </p>
  },
  {
    question: 'O que são os simulados “Prova na Íntegra”?',
    answer: <p>
      É uma ferramenta de treino muito interessante, pois você pode vivenciar uma prova completa de uma banca de residência médica real e já aplicada em anos anteriores.Esta função permite, inclusive, que você a banca, especialidade ou Estado desejados.
    </p>
  },
  {
    question: "Como desabilitar o comentário em vídeo dos simulados?",
    answer: <p>
      Em cada simulado no canto superior direito existe um botão chamado "Preferências", ao clicar poderá escolher desabilitar o comentário.
    </p>
  }
]

export const faqFrial = [
  {
    question:
      "Posso tirar dúvidas com os professores? Há alguma interação, além das aulas gravadas?",
    answer:
      <>
        <p>
          Sim! A EMR criou uma comunidade que permite a interação entre alunos, professores e suporte técnico. Nossa comunidade é privada, exclusiva para alunos e está ativa no Discord.
        </p>

        <br />
        <p>
          O Discord é uma ferramenta grátis de conversas via chat, vídeo e voz muito utilizada por comunidades que precisam interagir e trocar informações. Ele  reúne diversas comunidades de vários tamanhos e com variados propósitos, indo desde projetos escolares, entretenimento, desenvolvimento de pessoas e até dicas corporativas.
        </p>

        <br />
        <p>
          Muito versátil, você pode acessar diretamente no navegador do seu Desktop, Tablet ou Smartphone, independente de qual utiliza (Chrome, Safari, Opera etc.) e de qual sistema operacional tenha instalado (Windows, Linux, IOS etc.).
        </p>

        <br />
        <p>
          1) no computador:
        </p>

        <p>
          a) Navegador: acesse www.discord.com e crie uma conta. Se preferir, acesse direto o link da nossa comunidade (você cria a conta e já entra na comunidade: {<Link to={{ pathname: 'https://discord.gg/jDSKMpndbk' }} target="_blank">
            https://discord.gg/jDSKMpndbk
          </Link>}
        </p>

        <p>
          b) Instalando programa para Windows: {<Link to={{ pathname: 'https://apps.microsoft.com/store/detail/discord/XPDC2RH70K22MN?hl=fr-fr&gl=fr&SilentAuth=1&wa=wsignin1.0' }} target="_blank">
            https://apps.microsoft.com/store/detail/discord/XPDC2RH70K22MN?hl=fr-fr&gl=fr&SilentAuth=1&wa=wsignin1.0
          </Link>}
        </p>

        <br />
        <p>
          2) no celular/ tablet:
        </p>

        <p>
          a) IOS: {<Link to={{ pathname: 'https://apps.apple.com/br/app/discord-converse-fale/id985746746' }} target="_blank">
            https://apps.apple.com/br/app/discord-converse-fale/id985746746
          </Link>}
        </p>

        <p>
          b) Android: {<Link to={{ pathname: 'https://play.google.com/store/apps/details?id=com.discord' }} target="_blank">
            https://play.google.com/store/apps/details?id=com.discord
          </Link>}
        </p>
      </>
  },
  {
    question: "Qual o meu período de acesso no teste grátis?",
    answer: <p>
      Seu período de acesso ao teste grátis é de 7 dias, mas caso precise de mais tempo basta falar com
      nosso {<Link to={{ pathname: 'https://wa.me/558182763620' }} target="_blank">
        suporte
      </Link>}.
    </p>
  },
  {
    question: "A quantas aulas eu tenho acesso no teste grátis?",
    answer: <p>
      Durante o teste grátis, você tem acesso a algumas aulas selecionadas. No entanto, algumas aulas estarão bloqueadas e só poderão ser acessadas após a compra do curso completo. Ao adquirir o curso, todas as aulas e materiais complementares serão desbloqueados para você assistir.
    </p>
  },
  {
    question: "As aulas no teste grátis possuem materiais complementares?",
    answer: <p>
      Sim, ao assistir uma aula desbloqueada no teste grátis, você terá acesso aos materiais complementares relacionados a essa aula.
    </p>
  },
  {
    question: "É possível criar simulados durante o teste grátis?",
    answer: <p>
      Claro! Você pode criar simulados de todos os tipos, de acordo com as aulas desbloqueadas.
    </p>
  },
  {
    question: "Como faço para me matricular?",
    answer: <p>
      Você pode clicar no botão no topo da plataforma escrito {<Link to={{ pathname: enrollmentURL }} target="_blank">
        "Matricule-se já”
      </Link>} ou pode entrar em contato conosco pelo através do nosso {<Link to={{ pathname: 'https://wa.me/558198857191' }} target="_blank">
        time comercial
      </Link>}. Seja qual for o caminho, você estará tomando a melhor decisão pra conquistar sua tão sonhada vaga na residência.
    </p>
  },
  {
    question: "Ainda possui dúvidas? Agende uma visita guiada com um consultor EMR.",
    answer: <p>
      Basta entrar em contato com nosso time e agendar um horário de sua preferência, e nossos consultores irão fazer uma visita guiada com você e tirar todas as suas dúvidas. {<Link to={{ pathname: 'https://wa.me/558198857191' }} target="_blank">
        Entre em contato
      </Link>}.
    </p>
  }
];
