import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { finishMock } from "../../processes/mockProccess";
import {
  goToIndex,
  markAsFavorite,
  removeFavorite
} from "../../processes/questionProccess";
import { getMock } from "../../selectors/mockSelectors";
import {
  getQuestionsFromMock,
  makeCurrentQuestion,
  makeIsMarkedAsFavorite
} from "../../selectors/questionSelectors";
import QuestionNavigation from "./QuestionNavigation";
import { getAnswer, makeIsComplete } from "../../selectors/answerSelector";
import { getSelectedAlternativeFromQuestion } from "../../selectors/alternativeSelectors";
import { createAnswer } from "../../processes/answerProccess";

function QuestionNavigationContainer(props) {
  const {
    currentIndex,
    dispatch,
    mock,
    questionId,
    isComplete,
    isExam,
    selectedAlternative
  } = props;
  const [openReportErrorModal, setOpenReportErrorModal] = useState(false);
  const toggleReportErrorModal = useCallback(() => {
    setOpenReportErrorModal(!openReportErrorModal);
  }, [openReportErrorModal]);

  const createAnswerHandler = useCallback(
    () => createAnswer(dispatch, mock.id, selectedAlternative),
    [dispatch, mock.id, selectedAlternative]
  );

  const goToNextQuestionHandler = useCallback(
    () => goToIndex(dispatch, currentIndex + 1),
    [dispatch, currentIndex]
  );

  const goToPrevQuestionHandler = useCallback(() => {
    goToIndex(dispatch, currentIndex - 1);
  }, [dispatch, currentIndex]);

  const finishMockHandler = useCallback(
    () => finishMock(dispatch, mock?.id),
    [dispatch, mock]
  );

  const markAsFavoriteHandler = useCallback(
    (shouldCheckAsFavorite) => {
      if (shouldCheckAsFavorite) {
        markAsFavorite(dispatch, questionId);
      } else {
        removeFavorite(dispatch, questionId);
      }
    },
    [dispatch, questionId]
  );

  useEffect(() => {
    isComplete && !isExam && finishMock(dispatch, mock?.id);
  }, [isComplete]);

  return (
    <QuestionNavigation
      {...props}
      openReportErrorModal={openReportErrorModal}
      toggleReportErrorModal={toggleReportErrorModal}
      createAnswerHandler={createAnswerHandler}
      goToNextQuestionHandler={goToNextQuestionHandler}
      goToPrevQuestionHandler={goToPrevQuestionHandler}
      markAsFavoriteHandler={markAsFavoriteHandler}
      finishMockHandler={finishMockHandler}
    />
  );
}

function mapStateToProps(state) {
  const question = makeCurrentQuestion(state);
  const questionId = question?.id;
  const mock = getMock(state);
  const questionsLength = getQuestionsFromMock(state)?.length;
  const isFrialUser = state?.isFrialUser;
  return {
    isAnswered: getAnswer(state, { mockId: mock.id, questionId: questionId }),
    selectedAlternative: getSelectedAlternativeFromQuestion(state, questionId),
    currentIndex: state.mock.currentIndex,
    isMarkedAsFavorite: makeIsMarkedAsFavorite(state, questionId),
    question,
    questionId,
    questionsLength,
    mock,
    isComplete: makeIsComplete(state),
    isAlternativeMode: question?.questionIds?.length === 0,
    isFrialUser
  };
}

export default connect(mapStateToProps)(QuestionNavigationContainer);
