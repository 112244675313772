import { css } from "glamor";
import React from "react";
import { spacing, uiColors } from "../theme";
import CheckBoxFieldArrayItemContainer from "./CheckBoxFieldArrayItemContainer";
import Label from "./Label";

const container = css({
  "& + &": {
    marginTop: spacing.m,
    paddingTop: spacing.m,
    borderTop: `1px solid ${uiColors.border}`,
  },
});
const layout = {
  wrapper: {
    flex: 1,
    paddingTop: spacing.s,
  },
  label: {
    display: "block",
    marginBottom: spacing.xxs,
  },
};

export default function CheckBoxFieldArray({
  label,
  options,
  placeholder,
  ...rest
}) {
  return (
    <div className={container}>
      {label && (
        <Label
          style={layout.label}
          required={rest.required}
          disabled={rest.disabled}
        >
          {label}
        </Label>
      )}
      <div style={layout.wrapper}>
        {options.length ? (
          options.map((item, index) => (
            <CheckBoxFieldArrayItemContainer
              key={index}
              item={item}
              {...rest}
            />
          ))
        ) : (
          <p>{`${placeholder || "Nenhuma opção disponível"}`}</p>
        )}
      </div>
    </div>
  );
}
