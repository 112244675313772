import React from "react";
import { css } from "glamor";
import MockTemplateSmartExams from './MockTemplateSmartExams';

const container = css({
  textAlign: "left",
  flex: 1,
});

const progressClass = css({
  margin: `0 !important`,
});


export default function MockProgressBar({ value }) {
  return (
    <div className={container}>
      <MockTemplateSmartExams value={value} className={progressClass.toString()}/>
    </div>
  );
}
