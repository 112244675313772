import * as React from 'react';

export function SecurityIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
      <rect width={24} height={24} fill="#FF5B5B" rx={12} />
      <g clipPath="url(#a)">
        <path
          fill="#fff"
          d="M18.1 8.045a.438.438 0 0 0-.36-.403c-2.365-.424-3.353-.731-5.56-1.728a.437.437 0 0 0-.36 0c-2.207.997-3.195 1.304-5.56 1.728a.437.437 0 0 0-.36.403c-.105 1.671.12 3.228.668 4.628a9.556 9.556 0 0 0 1.96 3.076c1.461 1.551 3.014 2.225 3.31 2.344a.437.437 0 0 0 .328 0c.296-.119 1.848-.793 3.31-2.344a9.556 9.556 0 0 0 1.956-3.076c.548-1.4.773-2.957.668-4.628Zm-3.582 2.054-3.03 3.5a.438.438 0 0 1-.312.151h-.018a.437.437 0 0 1-.306-.125l-1.346-1.318a.438.438 0 0 1 .613-.625l1.011.992 2.727-3.148a.438.438 0 0 1 .661.573Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M5 5h14v14H5z" />
        </clipPath>
      </defs>
    </svg>
  );
}
