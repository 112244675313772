import styled from "styled-components";

const colorRenderer = (color) => {
  switch (color) {
    case "easy":
      return "#49B1EB";
    case "medium":
      return "#E9C610";
    case "hard":
      return "#FF5B5B";
    default:
      return "#49B1EB";
  }
};

const QuestionDifficultyLevelContainer = styled.div`
  padding: 5px 8px;
  display: flex;
  background-color: ${({ color }) => colorRenderer(color)};
  max-width: 245px;
  border-radius: 4px;
  height: 32px;
`;

const Difficulty = styled.span`
  font-size: 14px;
  line-height: 1.5;
  font-weight: 600;
  margin: 0;
  color: #fff;
  max-height: fit-content;
`;

const IconContainer = styled.div`
  margin-right: 0.5rem;
`;

const DifficultyWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-top: 1px solid #c2ced6;
  border-bottom: 1px solid #c2ced6;
  padding: 16px 0;
`;
export {
  Difficulty,
  DifficultyWrapper,
  IconContainer,
  QuestionDifficultyLevelContainer,
};
