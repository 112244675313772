import React, { useState, useEffect, useCallback, useRef } from "react";
import ReactPlayer from "react-player";
import { css } from "glamor";
import { colors, styles } from "../theme";
import {
  Container,
  FullScreenButton,
  Logo,
  LogoWrapper,
  Wrapper,
} from "./styles";
import { getThemeByUniversity } from "../../../utils/getThemeByUniversity";

const videoStyle = css(styles.roundedCorners, {
  overflow: "hidden",
  position: "absolute",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  border: "none",
});

const PROGRESS_INTERVAL = 15000;

export default function Video({
  isQuestion,
  url,
  onProgressHandler,
  onEndedHandler,
  options,
  progress,
}) {
  const playerRef = useRef();
  const containerRef = useRef(null);
  const { universityTheme, IES } = getThemeByUniversity();
  const logoUniversity = isQuestion
    ? universityTheme.get(IES ? IES : "default").logo
    : universityTheme.get(IES ? IES : "default").smallLogo;

  function getLogoVariant() {
    if (isQuestion || IES === "revalida") return "QUESTION";
    if (IES) return "B2B";
  }

  const onDurationHandler = useCallback(
    (duration) => {
      if (progress) {
        const playedSeconds = duration * progress;
        playedSeconds && playerRef.current.seekTo(playedSeconds);
      }
    },
    [progress]
  );

  const [videoUrl, setVideoUrl] = useState(url);

  useEffect(() => {
    // Hack for not change URL's too fast before loading the previous content
    // and broke the player
    function loadWhenReady() {
      if (!playerRef.current.player.isLoading) {
        setVideoUrl(url);
        clearTimeout(timeout);
      }
    }
    loadWhenReady();
    const timeout = setTimeout(() => loadWhenReady(), 500);
    return () => clearTimeout(timeout);
  }, [url]);

  const handleFullScreen = () => {
    //TO-DO Comentado porque não esta funcionando ainda no iPad
    if (containerRef.current) {
      if (document.fullscreenElement || document.webkitFullscreenElement) {
        isSafari()
          ? document.webkitExitFullscreen()
          : document.exitFullscreen();
      } else {
        isSafari()
          ? containerRef.current.webkitRequestFullscreen()
          : containerRef.current.requestFullscreen();
      }
    }
  };

  const isSafari = () =>
    navigator.vendor &&
    navigator.vendor.indexOf("Apple") > -1 &&
    navigator.userAgent &&
    navigator.userAgent.indexOf("CriOS") == -1 &&
    navigator.userAgent.indexOf("FxiOS") == -1;

  return (
    <Container ref={containerRef}>
      <Wrapper className="wrapper">
        {videoUrl && IES && (
          <LogoWrapper
            theme={colors.brand.pure}
            isQuestion={isQuestion}
            variant={getLogoVariant()}
          >
            <Logo src={logoUniversity} />
          </LogoWrapper>
        )}
        <FullScreenButton onClick={handleFullScreen} />
        <ReactPlayer
          ref={playerRef}
          url={videoUrl}
          className={videoStyle}
          onProgress={onProgressHandler}
          onEnded={onEndedHandler}
          onDuration={onDurationHandler}
          progressInterval={PROGRESS_INTERVAL}
          options={options}
          width="100%"
          height="100%"
          controls
        />
      </Wrapper>
    </Container>
  );
}
