import styled from "styled-components";

export const Container = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 16px;
  border-radius: 4px;
  overflow: visible;
`;
