import React from "react";
import PropTypes from "prop-types";

export function ThermometerIcon({ height, width, fill, ...props }) {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 4.375V15M12.5203 11.8074C12.4759 11.7793 12.4392 11.7403 12.4138 11.6942C12.3884 11.6481 12.3751 11.5964 12.375 11.5437V3.125C12.375 2.62772 12.1775 2.15081 11.8258 1.79917C11.4742 1.44754 10.9973 1.25 10.5 1.25C10.0027 1.25 9.52581 1.44754 9.17418 1.79917C8.82255 2.15081 8.62501 2.62772 8.62501 3.125V11.5437C8.62492 11.5963 8.6116 11.6479 8.58627 11.694C8.56094 11.74 8.52442 11.7789 8.48008 11.807C7.9247 12.1688 7.47334 12.6693 7.1706 13.2589C6.86786 13.8485 6.72424 14.507 6.75391 15.1691C6.79861 16.1477 7.22443 17.0699 7.94031 17.7386C8.65618 18.4072 9.60528 18.7692 10.5846 18.7471C11.5639 18.725 12.4957 18.3206 13.1807 17.6203C13.8657 16.9201 14.2495 15.9796 14.25 15C14.2503 14.3659 14.0922 13.7418 13.7902 13.1843C13.4881 12.6268 13.0516 12.1535 12.5203 11.8074Z"
        stroke="white"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M10.5 16.875C11.5355 16.875 12.375 16.0355 12.375 15C12.375 13.9645 11.5355 13.125 10.5 13.125C9.46447 13.125 8.625 13.9645 8.625 15C8.625 16.0355 9.46447 16.875 10.5 16.875Z"
        fill="white"
      />
    </svg>
  );
}

ThermometerIcon.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ThermometerIcon.defaultProps = {
  fill: "#FFF",
  height: "20",
  width: "20",
};
