import * as React from 'react';

export function CloseIcon() {
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.06046 4.99984L9.02921 2.03109C9.17011 1.89044 9.24936 1.69958 9.24954 1.5005C9.24971 1.30142 9.1708 1.11042 9.03015 0.969524C8.8895 0.828627 8.69864 0.749374 8.49956 0.749198C8.30048 0.749022 8.10948 0.827939 7.96859 0.968586L4.99984 3.93734L2.03109 0.968586C1.89019 0.82769 1.69909 0.748535 1.49984 0.748535C1.30058 0.748535 1.10948 0.82769 0.968586 0.968586C0.82769 1.10948 0.748535 1.30058 0.748535 1.49984C0.748535 1.69909 0.82769 1.89019 0.968586 2.03109L3.93734 4.99984L0.968586 7.96859C0.82769 8.10948 0.748535 8.30058 0.748535 8.49984C0.748535 8.69909 0.82769 8.89019 0.968586 9.03109C1.10948 9.17198 1.30058 9.25114 1.49984 9.25114C1.69909 9.25114 1.89019 9.17198 2.03109 9.03109L4.99984 6.06234L7.96859 9.03109C8.10948 9.17198 8.30058 9.25114 8.49984 9.25114C8.69909 9.25114 8.89019 9.17198 9.03109 9.03109C9.17198 8.89019 9.25114 8.69909 9.25114 8.49984C9.25114 8.30058 9.17198 8.10948 9.03109 7.96859L6.06046 4.99984Z"
        fill='#27EA8F'
      />
    </svg>
  )
}
