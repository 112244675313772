const getSurveysUsers = (action) => action.entities.surveysUsers;

export default function surveysUsersReducer(state = {}, action) {
  switch (action.type) {
    case "SURVEYS_USERS_FETCHED":
      return { ...state, ...getSurveysUsers(action) };
    default:
      return state;
  }
}
