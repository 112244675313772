import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import MyPerformanceSubjectForm from "./MyPerformanceSubjectForm";
import { getSpecialities } from "../../selectors/specialitySelector";
import { fetchSubjectsReport } from "../../processes/subjectProccess";
import debounce from "../../utils/debounce";

function mapStateToProps(state) {
  return {
    initialValues: {},
    specialities: getSpecialities(state),
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "subjectFilter",
    onChange: debounce(fetchSubjectsReport, 500),
  })(MyPerformanceSubjectForm)
);
