import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createAnswer } from "../../processes/answerProccess";
import { getAlternativeAnswered } from "../../selectors/answerSelector";
import { getMock } from "../../selectors/mockSelectors";
import QuestionAlternativesListItem from "./QuestionAlternativesListItem";
import {
  makeDiscardAlternative,
  makeIsSelected,
} from "../../selectors/alternativeSelectors";
import {
  addToDiscartedAlternatives,
  removeFromDiscartedAlternatives,
  selectAlternative,
} from "../../processes/alternativeProccess";
import { getMockPreferences } from "../../selectors/mockSelectors.js";

export default function QuestionAlternativesListItemContainer(props) {
  const dispatch = useDispatch();
  const { alternative } = props;
  const mock = useSelector((state) => getMock(state));
  const mockId = mock?.id;
  const answered = useSelector((state) =>
    getAlternativeAnswered(state, {
      questionId: alternative.questionId,
      mockId,
    })
  );
  const isAnswer = alternative?.id === answered?.id;
  const isSelected = useSelector((state) =>
    makeIsSelected(state, {
      questionId: alternative.questionId,
      alternativeId: alternative.id,
    })
  );
  const createAnswerHandler = useCallback(
    () => createAnswer(dispatch, mockId, alternative),
    [dispatch, mockId, alternative]
  );

  const selectAlternativeHandler = useCallback(() => {
    selectAlternative(dispatch, alternative);
  }, [dispatch, alternative]);

  const isDiscarted = useSelector((state) =>
    makeDiscardAlternative(state, alternative?.id)
  );

  const toggleDiscardAlternative = useCallback(() => {
    const toggleFunction = isDiscarted
      ? removeFromDiscartedAlternatives
      : addToDiscartedAlternatives;
    toggleFunction(
      { alternativeId: alternative?.id, mockId: mockId },
      dispatch
    );
  }, [dispatch, isDiscarted, alternative?.id, mockId]);

  const { showCorrectAnswer } = useSelector((state) =>
    getMockPreferences(state)
  );

  return (
    <QuestionAlternativesListItem
      {...props}
      alternative={alternative}
      answered={answered}
      createAnswerHandler={createAnswerHandler}
      selectAlternativeHandler={selectAlternativeHandler}
      isAnswer={isAnswer}
      isSelected={isSelected}
      isDiscarted={isDiscarted}
      toggleDiscardAlternative={toggleDiscardAlternative}
      showCorrectAnswer={showCorrectAnswer}
    />
  );
}
