import * as React from 'react';

export function CheckIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={17} fill="none">
      <rect width={16} height={16} y={0.5} fill="#D3FCE9" rx={8} />
      <path
        stroke="#35BD78"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M11.125 6 6.75 11 4.875 9.125"
      />
    </svg>
  );
}
