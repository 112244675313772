import React, { useState, useRef, useEffect } from "react";
import {
  IoPlay,
  IoEllipsisHorizontalSharp,
  IoTrash,
  IoCreate,
} from "react-icons/io5";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { trackContinueMockTemplateEvent } from "../../../services/mixpanel/events/trackContinueMockTemplateEvent";
import { EMRButton } from "../../componentsV2/Button";
import formatDate from "../../utils/formatDate";
import truncateString from "../../utils/truncateString";
import { colors } from "../library/theme";
import MockProgressBar from "./MockProgressBar";
import MockStatus from "./MockStatus";
import { DeleteModal } from "./DeleteModal";
import { EditModal } from "./EditModal";

const ButtonsContainer = styled.div`
  display: flex;
  gap: 8px;
  position: relative;

  > button {
    padding: 0 2%;
  }
`;

const OptionsButton = styled.button`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  
  svg {
    flex-shrink: 0;
    stroke: ${colors.interface.grey300};
    fill: ${colors.interface.grey300};
  }

  &:hover,
  &:active {
    background-color: ${colors.interface.grey100};
  }
`;

const OptionsContainer = styled.div`
  position: absolute;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 4px;
  display: ${(props) => (props.isOpen ? "block" : "none")};
  z-index: 99;
  right: 0;
  width: 234px;
`;

const Option = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  width: 100%;
  padding: 16px;
  line-height: 0;
  font-size: 14px;
  color: ${colors.interface.grey400};
  transition: all 0.2s;

  > svg {
    min-width: 18px;
    width: 18px;
    fill: ${colors.interface.grey400};
    stroke: ${colors.interface.grey400};
  }

  &:hover {
    background-color: ${colors.status.green000};
    color: ${colors.interface.grey500};

    svg {
      fill: ${colors.interface.grey500};
      stroke: ${colors.interface.grey500};
    }
  }
`;

export default function MockListItem({
  mock,
  deleteMockHandler,
  isFrialUser,
  activeTabName,
  updateMockHandler,
  setNewTitle,
}) {
  const history = useHistory();
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const progress = !mock.progress ? 0 : mock.progress;
  const isFinished = !!mock.finished_at || mock.progress === 1;
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
  const [isEditModalOpened, setIsEditModalOpened] = useState(false);
  const [mockTitle, setMockTitle] = useState(mock.name);
  const optionsContainerRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        optionsContainerRef.current &&
        !optionsContainerRef.current.contains(event.target)
      ) {
        setIsOptionsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function toggleOptions() {
    setIsOptionsOpen((prev) => !prev);
  }

  function handleClick(id) {
    const getStatus = () => {
      if (isFinished) return "Finalizado";
      if (progress === 0 || progress === null) return "Pendente";
      if (progress > 0 && progress < 1) return "Em andamento";
    };

    trackContinueMockTemplateEvent({
      name: mock.name,
      score: mock.score,
      progress: mock.progress,
      status: getStatus(),
      isFrialUser,
      mockTemplateType: activeTabName,
    });

    history.push(`/mocks/${id}`);
  }

  function handleOpenDeleteModalConfirmation(mockName) {
    setMockTitle(mockName);
    setIsDeleteModalOpened((prev) => !prev);
    setIsOptionsOpen(false);
  }

  function handleOpenEditModal(mockName) {
    setMockTitle(mockName);
    setIsEditModalOpened((prev) => !prev);
    setIsOptionsOpen(false);
  }

  function handleMockDelete() {
    deleteMockHandler();
    setIsDeleteModalOpened(false);
    setIsOptionsOpen(false);
    window.location.reload();
  }
  return (
    <>
      <tr>
        <td>
          <span>Criação:</span>
          <div style={{ paddingRight: 0 }}>
            {formatDate(mock.created_at, "DD/MM/YYYY")}
          </div>
        </td>
        <td>
          <span>Nº de questões:</span>
          <div style={{ paddingRight: 0 }}>{mock.amountOfQuestions}</div>
        </td>
        <td>
          <div style={{ paddingRight: 0 }}>
            {truncateString(mock?.name, 120)}
          </div>
        </td>
        <td>
          <MockStatus value={progress} isFinished={isFinished} />
        </td>
        <td>
          <span>Nota:</span>
          <div>{isFinished ? mock.score : "---"}</div>
        </td>
        <td>
          <span>Progresso:</span>

          <MockProgressBar value={progress} />
        </td>
        <td width="24%">
          <ButtonsContainer>
            <EMRButton
              onClick={() => handleClick(mock.id)}
              mode="outline"
              fullSized
              shortHeight
              mediumWeight
            >
              <IoPlay style={{ minWidth: 16, maxWidth: 16, height: "auto" }} />

              {progress === 0
                ? "Começar Simulado"
                : isFinished
                  ? "Visualizar Simulado"
                  : "Continuar Simulado"}
            </EMRButton>

            <div ref={optionsContainerRef}>
              <OptionsButton onClick={toggleOptions}>
                <IoEllipsisHorizontalSharp />
              </OptionsButton>
              <OptionsContainer isOpen={isOptionsOpen}>
                <Option onClick={() => handleOpenEditModal(mock.name)}>
                  <IoCreate />
                  Editar nome do simulado
                </Option>

                <Option
                  onClick={() => handleOpenDeleteModalConfirmation(mock.name)}
                >
                  <IoTrash />
                  Excluir simulado
                </Option>
              </OptionsContainer>
            </div>
          </ButtonsContainer>
        </td>
      </tr>
      {isDeleteModalOpened && (
        <DeleteModal
          deleteMockHandler={handleMockDelete}
          isOpen={isDeleteModalOpened}
          handleModal={handleOpenDeleteModalConfirmation}
          mockTitle={mockTitle}
        />
      )}
      {isEditModalOpened && (
        <EditModal
          deleteMockHandler={handleMockDelete}
          isOpen={isEditModalOpened}
          handleModal={handleOpenEditModal}
          mock={mock}
          updateMockHandler={updateMockHandler}
          setNewTitle={setNewTitle}
        />
      )}
    </>
  );
}
