import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import RecoveryPasswordForm from "./RecoveryPasswordForm";
import { recoveryPassword } from "../../processes/sessionProcesses";

function mapStateToProps() {
  return {
    initialValues: {},
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "recoveryPasswordForm",
    onSubmit: recoveryPassword,
  })(RecoveryPasswordForm)
);
