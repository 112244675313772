import * as React from 'react';
import { colors } from '../../components/library/theme';

export function WelcomeImage() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={392}
      height={392}
      fill="none"
    >
      <path
        fill={colors.brand.pure}
        d="M129.509 59.388S70.105 89.658 40.478 136.3c-29.628 46.64-27.283 106.624 8.718 151.139 36.001 44.516 104.813 38.706 162.429 66.93 57.616 28.224 121.183 22.407 148.427-37.365 27.244-59.772-13.838-82.14-20.894-151.297-7.056-69.156-69.407-170.41-209.649-106.318Z"
      />
      <path
        fill="#fff"
        d="M129.509 59.388S70.105 89.658 40.478 136.3c-29.628 46.64-27.283 106.624 8.718 151.139 36.001 44.516 104.813 38.706 162.429 66.93 57.616 28.224 121.183 22.407 148.427-37.365 27.244-59.772-13.838-82.14-20.894-151.297-7.056-69.156-69.407-170.41-209.649-106.318Z"
        opacity={0.7}
      />
      <path
        fill="#263238"
        d="M366.285 214.424h-3.254v-.557a5.735 5.735 0 0 0-5.731-5.731h-2.203a5.74 5.74 0 0 0-5.739 5.731v.557h-3.246a3.304 3.304 0 0 0-2.336.964 3.298 3.298 0 0 0-.964 2.337v15.037a3.3 3.3 0 0 0 3.3 3.3h20.173a3.305 3.305 0 0 0 3.3-3.3v-15.037a3.3 3.3 0 0 0-3.3-3.301Zm-15.359-.557a4.171 4.171 0 0 1 4.171-4.163h2.203a4.164 4.164 0 0 1 4.163 4.163v.557h-10.537v-.557Z"
      />
      <path
        fill="#fff"
        d="M362.208 223.706h-4.155v-4.147h-3.716v4.147h-4.156v3.724h4.156v4.148h3.716v-4.148h4.155v-3.724Z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M359.856 124.813a4.927 4.927 0 0 1-3.479-1.443 4.93 4.93 0 0 1-1.437-3.481v-13.265a4.929 4.929 0 0 1 1.437-3.481 4.918 4.918 0 0 1 6.958 0 4.93 4.93 0 0 1 1.437 3.481v13.241a4.92 4.92 0 0 1-3.028 4.57 4.914 4.914 0 0 1-1.888.378Z"
      />
      <path
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M359.856 124.813a4.908 4.908 0 0 1-3.479-1.443 4.93 4.93 0 0 1-1.437-3.481v-6.624h9.832v6.624a4.925 4.925 0 0 1-1.437 3.481 4.902 4.902 0 0 1-3.479 1.443Z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M335.129 105.275a4.883 4.883 0 0 1-.884-1.706 4.892 4.892 0 0 1 .424-3.744 4.91 4.91 0 0 1 1.244-1.465l10.325-8.294a4.93 4.93 0 0 1 6.923.784 4.925 4.925 0 0 1-.784 6.915l-10.333 8.286a4.926 4.926 0 0 1-3.619 1.046 4.912 4.912 0 0 1-3.296-1.822Z"
      />
      <path
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M335.129 105.276a4.897 4.897 0 0 1-.46-5.45 4.91 4.91 0 0 1 1.244-1.465l5.166-4.148 6.155 7.676-5.167 4.139a4.905 4.905 0 0 1-3.626 1.069 4.905 4.905 0 0 1-3.312-1.821Z"
      />
      <path
        fill="#263238"
        d="M285.964 69.18c-.613 0-1.211.192-1.709.549l-11.423-8.867a6.395 6.395 0 0 0-.361-6.664l6.978-8.922a2.964 2.964 0 1 0-1.897-2.76c-.004.61.185 1.206.541 1.702l-6.79 8.694a6.403 6.403 0 0 0-9.38 1.236 6.408 6.408 0 0 0-1.141 3.311h-7.934a2.962 2.962 0 0 0-2.862-2.6 2.964 2.964 0 1 0 2.556 4.317h8.389a6.412 6.412 0 0 0 10.913 3.074l11.36 8.835a2.956 2.956 0 1 0 2.76-1.905Z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M53.923 124.907a3.593 3.593 0 0 1-3.582-3.583V91.532h7.165v29.792a3.582 3.582 0 0 1-3.583 3.583Z"
      />
      <path
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M53.923 124.907a3.588 3.588 0 0 1-3.582-3.583v-18.322h7.165v18.322a3.584 3.584 0 0 1-3.583 3.583Z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M58.533 89.791h-9.212v2.611h9.212v-2.61ZM43.065 124.907a3.593 3.593 0 0 1-3.583-3.583V91.532h7.166v29.792a3.582 3.582 0 0 1-3.583 3.583Z"
      />
      <path
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M43.065 124.907a3.588 3.588 0 0 1-3.583-3.583v-18.322h7.166v18.322a3.584 3.584 0 0 1-3.583 3.583Z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M47.675 89.791h-9.212v2.611h9.212v-2.61Z"
      />
      <path
        fill="#263238"
        d="m146.436 60.423 2.853-6.852a.607.607 0 0 1 .573-.36.591.591 0 0 1 .533.415l3.457 11.399 2.634-8.397a.591.591 0 0 1 .549-.415.628.628 0 0 1 .565.392l1.466 4.218h7.926a9.574 9.574 0 0 0 2.65-6.272 8.886 8.886 0 0 0-7.865-8.886 8.884 8.884 0 0 0-9.681 6.863 8.882 8.882 0 0 0-17.53 2.03 9.684 9.684 0 0 0 2.313 5.904l9.557-.04Z"
      />
      <path
        fill="#263238"
        d="M158.65 61.999a.59.59 0 0 1-.556-.392l-1.02-2.932-2.673 8.522a.592.592 0 0 1-.914.297.582.582 0 0 1-.207-.305l-3.536-11.658-2.352 5.707a.592.592 0 0 1-.541.36h-9.024c1.937 2.165 4.351 4.078 6.155 5.857 2.728 2.697 5.401 5.488 8.185 8.091 3.567-3.52 7.157-7.056 10.693-10.576.98-.98 2.047-1.952 3.034-2.971h-7.244Z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeMiterlimit={10}
        d="m26.396 237.233 3.844 1.334 4.16-11.992-3.844-1.334-4.16 11.992ZM24.574 239.137l5.933 2.058.783-2.259-5.933-2.058-.783 2.259Z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeMiterlimit={10}
        d="m26.317 228.327 9.503 3.297 7.536-21.725-9.504-3.297-7.535 21.725Z"
      />
      <path
        fill="#263238"
        stroke="#263238"
        strokeMiterlimit={10}
        d="m30.938 219.034 7.014 2.433 4.16-11.992-7.015-2.433-4.16 11.992Z"
      />
      <path
        fill="#263238"
        stroke="#263238"
        strokeMiterlimit={10}
        d="m29.697 227.727 3.844 1.333 4.16-11.992-3.845-1.333-4.16 11.992Z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeMiterlimit={10}
        d="m25.646 228.099 10.858 3.767.943-2.719-10.858-3.766-.943 2.718ZM36.17 206.149l5.659 1.963 1.256-3.622-5.659-1.963-1.256 3.622Z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeMiterlimit={10}
        d="m32.944 207.052 10.859 3.766.943-2.718-10.86-3.767-.942 2.719Z"
      />
      <path
        stroke="#263238"
        strokeMiterlimit={10}
        d="m40.313 203.017 4.547-13.124"
      />
      <path
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M293.138 366.128c22.658 0 41.026-4.142 41.026-9.251 0-5.11-18.368-9.252-41.026-9.252-22.659 0-41.027 4.142-41.027 9.252 0 5.109 18.368 9.251 41.027 9.251Z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M261.503 147.768s-5.551 3.395-5.488 4.884c.063 1.49-2.07 28.656-2.313 32.05-.243 3.395-3.473 10.404-3.724 13.587-.25 3.183.549 6.374 1.482 7.84.933 1.466 4.359 6.154 4.359 6.154l9.212-14.229c.226-3.107.273-6.225.141-9.338-.141-6.781-3.669-40.948-3.669-40.948Z"
      />
      <path
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M296.948 80.454s-9.8-.627-15.382 6.17c-5.582 6.797-4.383 17.193-4.422 20.384-.039 3.191-9.518 12.544-8.467 16.354 1.05 3.811 3.841 4.242 2.948 7.425-.894 3.183-6.727 5.096-5.88 9.133.846 4.038 3.92 9.761 3.92 9.761l56.448.384s2.015-5.942-.134-10.403c-2.148-4.461-5.198-5.935-4.704-8.06.494-2.124 3.223-7.22 2.172-11.039-1.051-3.818-4.704-4.876-5.088-11.673-.384-6.798-1.388-18.032-6.782-23.52-5.394-5.488-14.629-4.916-14.629-4.916Z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M279.59 342.396a50.153 50.153 0 0 1-6.272 9.345c-3.606 4.038-7.103 6.163-6.985 8.287.117 2.125 7.989 2.54 12.152 1.051 4.163-1.49 7.189-4.461 8.028-4.704.839-.243 3.183 0 3.238-2.979.055-2.98-.4-11.462-.4-11.462l-9.761.462Z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M278.469 361.079c4.171-1.49 7.189-4.461 8.028-4.704.839-.243 3.183 0 3.238-2.979 0-1.568-.086-4.704-.196-7.268a7.409 7.409 0 0 1-6.35 3.92s-.079-1.489-2.352-4.03c-1.466-1.662-2.948-.352-3.811.784l-.697 1.051-.055.102a37.583 37.583 0 0 1-2.964 3.818c-3.606 4.038-7.103 6.162-6.985 8.287.117 2.124 7.981 2.509 12.144 1.019ZM308.637 342.38a58.272 58.272 0 0 0 7.291 9.338c4.038 4.03 7.762 6.146 7.84 8.271.079 2.125-7.714 2.548-12.042 1.066-4.328-1.482-7.667-4.453-8.522-4.665-.854-.211-3.191 0-3.559-2.963-.369-2.964-.831-11.462-.831-11.462l9.823.415Z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M311.758 361.056c-4.32-1.482-7.668-4.454-8.523-4.665-.854-.212-3.19 0-3.559-2.964-.196-1.568-.415-4.704-.58-7.275a8.31 8.31 0 0 0 6.766 3.873s-.079-1.49 1.905-4.038c1.294-1.654 2.916-.353 3.92.784.259.345.525.69.784 1.051l.071.102a46.956 46.956 0 0 0 3.371 3.818c4.037 4.029 7.761 6.146 7.84 8.271.078 2.124-7.668 2.524-11.995 1.043Z"
      />
      <path
        fill={colors.brand.pure}
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M262.538 230.339s1.309 44.147 3.05 52.849c1.74 8.703 9.51 58.581 9.51 58.581a18.658 18.658 0 0 0 7.51 1.474h8.491l.784-37.146.29-53.916 4.257 91.266s11.698.415 12.96.203a78.896 78.896 0 0 0 2.313-.423l7.518-57.741 4.304-38.416.236-15.281s-35.939-1.246-46.382-2.093c-10.443-.847-14.739-1.058-14.739-1.058l-.102 1.701Z"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M311.068 308.285a44.909 44.909 0 0 1-.298 2.046M312.032 255.137s.729 31.454-.549 48.53M273.498 252.613s.902 20.807 1.796 25.472"
      />
      <path
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M306.999 239.355v15.884c2.035.548 4.116.907 6.217 1.074 3.222.212 9.235 0 9.235 0l.432-6.013-15.884-10.945ZM263.205 251.586s8.161 2.579 11.595 2.791c3.434.211 6.648.643 6.648.643l-.423-4.289-18.252-1.717.432 2.572Z"
      />
      <path
        fill={colors.brand.pure}
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m287.979 130.983-6.405 7.221-17.766 12.959 3.787 78.549s14.159 3.81 26.311 4.869c12.152 1.058 27.777-1.725 27.777-1.725l-2.297-82.32s-6.915-10.192-10.459-12.944c-3.543-2.752-5.166-5.3-9.862-5.723-4.697-.424-11.086-.886-11.086-.886Z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m280.547 134.848-19.044 12.96 2.987 47.753-1.011 4.798-8.005 9.22 3.411 16.198-1.521 22.853s7.565 2.548 12.693 3.175c3.817.409 7.654.621 11.493.635l.659-31.203s2.532-51.799 2.83-54.096c.298-2.297 2.94-36.064 2.94-36.064l-7.432 3.771Z"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m280.852 136.8-9.22 23.12 9.949 4.132-9.392 1.709 8.318 32.591"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M286.38 124.829s3.308 22.069 5.911 27.165c2.603 5.096 3.34 6.79 3.34 6.79s5.66-17.201 6.797-19.749c1.137-2.548 2.352-11.674 2.352-11.674s-11.282 3.614-18.4-2.532Z"
      />
      <path
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M286.38 124.829s.297 2.007.784 4.947c1.654 1.262 6.036 4.382 9.274 4.547 2.47.125 5.606-1.474 7.691-2.752.377-2.313.635-4.21.635-4.21s-11.266 3.614-18.384-2.532Z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M281.887 109.572s-2.258-3.215-3.779 0c-1.521 3.214 2.352 7.973 3.434 8.624 3.074 1.826 2.258-1.568 2.258-1.568l-1.913-7.056ZM309.931 110.96s1.905-3.325 3.771-.189c1.866 3.136-1.498 8.068-2.501 8.766-2.869 1.983-2.415-1.419-2.415-1.419l1.145-7.158Z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M281.354 106.734s8.185-1.913 12.309-4.351a22.587 22.587 0 0 0 6.483-5.904s3.544 6.695 6.665 9.408c3.12 2.713 3.771 2.869 3.771 2.869s-1.498 11.721-3.191 15.798c-1.694 4.077-7.221 8.044-12.38 6.899-2.932-.643-8.012-2.759-9.486-6.327-1.474-3.567-4.171-18.392-4.171-18.392Z"
      />
      <path
        fill={colors.brand.pure}
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M302.24 115.742c-2.54.063-11.047.078-15.437-2.572a29.13 29.13 0 0 1-4.916-3.598c.157.784.345 1.764.549 2.783l.956.698 2.4 9.063-1.122.243c.237.926.522 1.84.855 2.736 1.474 3.567 6.554 5.684 9.486 6.327 5.151 1.137 10.663-2.807 12.372-6.876a3.87 3.87 0 0 1-.337-2.564c.494-2.563 1.105-7.056 1.105-7.056l1.749-1.568c.094-.572.172-1.121.25-1.622-2.116 1.466-6.083 3.959-7.91 4.006Z"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M292.902 111.955s-2.736-3.324-6.326-3.018M299.951 112.614a7.751 7.751 0 0 1 6.891-2.305M285 107.275s3.92-1.568 7.393-.831M300.272 107.298a5.657 5.657 0 0 1 6.421.894"
      />
      <path
        fill="#263238"
        d="M290.268 110.865a.92.92 0 0 1-.443.931.915.915 0 0 1-.341.12.971.971 0 0 1-.862-1.011.907.907 0 0 1 .171-.691.927.927 0 0 1 .613-.36.982.982 0 0 1 .862 1.011ZM304.466 111.884a.898.898 0 0 1-.784 1.051.964.964 0 0 1-.862-1.004.912.912 0 0 1 .166-.694.9.9 0 0 1 .618-.356.965.965 0 0 1 .862 1.003Z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M325.579 143.268s4.571 2.117 6.272 5.488c1.702 3.371 15.304 39.898 15.241 42.657-.062 2.76-4.562 5.943-8.78 6.374-4.218.432-10.365-7.212-11.282-8.482-.917-1.271-1.082-16.135-1.082-16.135l-.369-29.902Z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m304.035 133.515 2.227 73.022.784 46.06a58.543 58.543 0 0 0 13.038 1.686 27.795 27.795 0 0 0 10.725-1.913s-1.333-28.656-1.568-33.328c-.235-4.673-1.788-13.587-1.788-13.587l-1.85-62.187s-17.224-8.059-21.568-9.753Z"
      />
      <path
        fill="#fff"
        d="m308.927 137.545 7.895 25.754-7.197 1.952 7.001 3.395-8.734 27.746"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m308.927 137.545 7.895 25.754-7.197 1.952 7.001 3.395-8.734 27.746"
      />
      <path
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m314.431 161.104-9.792 46.915s6.695 2.116 11.587 2.352c4.893.235 15.085.203 15.085.203l10.192-43.731s-20.063-1.921-27.072-5.739Z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m317.841 204.828.322-14.661 7.769 1.639s-5.135 11.313-8.091 13.022Z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M323.792 184.867s-2.07-2.963-2.736-3.606c-.667-.643-3.959-2.541-4.594-2.541-.636 0-4.046-.211-4.704-.211-.659 0-1.404 1.489-.73 2.124.675.635 3.019.847 3.019.847l1.607 2.352-7.252-.635s-1.207 1.278-.933 2.352c.205.728.467 1.438.784 2.125a33.68 33.68 0 0 0 .243 4.665c.282 1.277 1.568 1.701 3.332 2.759 1.764 1.059 7.668.424 7.668.424h2.728l1.568-10.655Z"
      />
      <path
        fill="#fff"
        d="m341.471 186.341-18.816-2.532s-1.466 12.105-2.948 16.134c-1.481 4.03-1.866 4.885-1.866 4.885s19.718-.439 24.367-.863c4.649-.423 7.26-3.191 7.056-7.009-.204-3.818-2.532-7.644-2.532-7.644"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m341.471 186.341-18.816-2.532s-1.466 12.105-2.948 16.134c-1.481 4.03-1.866 4.885-1.866 4.885s19.718-.439 24.367-.863c4.649-.423 7.26-3.191 7.056-7.009-.204-3.818-2.532-7.644-2.532-7.644"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M263.008 199.56s4.391 2.751 5.488 3.606c1.098.855 1.192 6.366 1.89 7.424.698 1.059-.126 1.702-1.396 1.702s-3.19-4.03-3.19-4.03l-.079-1.49-8.969 2.98-2.352-4.03s2.666-6.162 8.608-6.162Z"
      />
      <path
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M197.67 366.128c22.658 0 41.027-4.142 41.027-9.251 0-5.11-18.369-9.252-41.027-9.252-22.659 0-41.027 4.142-41.027 9.252 0 5.109 18.368 9.251 41.027 9.251Z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M229.712 137.035s5.378 26.993 6.335 31.987c.956 4.994 2.712 16.864 4.704 22.07 1.991 5.206 1.34 11.87.345 13.743a31.036 31.036 0 0 1-3.426 4.579l-6.68-8.71s-3.975-42.681-4.124-48.726c-.149-6.044 2.846-14.943 2.846-14.943Z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M233.256 199.638s4.233 2.642 4.978 3.763c.745 1.121 1.278 2.548.698 3.763-.58 1.215-3.74 4.884-4.539 5.598-.8.713-1.474-2.133-1.568-4.579-.095-2.446.431-8.545.431-8.545ZM210.488 347.657s13.273 6.452 14.112 7.706c.839 1.255-2.187 2.925-10.098 2.925-7.91 0-8.145-1.043-11.289-1.874-3.144-.831-9.408-.212-10.255-2.085-.847-1.874.627-8.327.627-8.327l16.903 1.655Z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M192.864 350.989s10.976 6.664 10.012 8.953c-.965 2.289-8.899 2.289-13.077 1.662-4.179-.627-13.062-6.272-13.532-8.326-.471-2.054.705-5.206 7.142-5.825 6.437-.619 9.455 3.536 9.455 3.536ZM226.184 242.099s-6.272 48.302-7.362 55.382c-1.09 7.079-4.547 18.322-6.413 26.86a142.322 142.322 0 0 0-2.352 15.201 14.246 14.246 0 0 0 .102 4.367c.267 2.086.306 3.748.306 3.748a56.89 56.89 0 0 1-8.499 1.458c-3.536.212-8.326.212-8.326.212l-1.568-13.532s1.168-19.992 1.858-25.817c.69-5.825 1.427-51.635 1.396-52.889l-.306-12.285 21.364-2.29s2.916-8.326 7.299-7.91c4.383.415 2.501 7.495 2.501 7.495Z"
      />
      <path
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M223.714 234.604c-4.382-.416-7.299 7.91-7.299 7.91l-21.387 2.29.141 5.942c2.281.204 4.39.322 6.194.322a188.38 188.38 0 0 0 20.509-1.153l.917-9.596 1.435 9.777.988-.322c.611-4.657 1.003-7.691 1.003-7.691s1.882-7.064-2.501-7.479Z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M172.715 243.557s0 .298.047.839c.29 5.692 2.015 38.675 2.54 44.759.588 6.664-2.352 13.947-2.203 19.984.149 6.037 1.568 38.729 1.568 38.729l1.568 5.41s3.685-2.493 7.221-2.493 9.408.204 9.408.204.306-4.375.274-5.825c-.031-1.451-.164-6.664-.164-6.664s5.982-27.276 6.342-29.565c.361-2.289 5.002-41.85 4.971-43.12-.031-1.27.98-18.949.98-18.949l-32.552-3.309Z"
      />
      <path
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m205.047 251.029.228-4.14-32.56-3.332s0 .298.047.839c0 .69.086 1.78.165 3.136 7.613 1.364 20.823 3.512 28.436 3.512 1.254.024 2.477.008 3.684-.015Z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M196.706 115.718s-3.442.58-4.751 2.117a34.35 34.35 0 0 0-3.528 4.798c-.181.58-20.424 12.865-20.424 12.865l1.459 58.581s.603 9.024-.471 12.097a61.949 61.949 0 0 0-2.611 18.636c.189 7.487.675 19.6.675 19.6s23.167 4.609 34.308 4.609a188.396 188.396 0 0 0 20.509-1.168l.917-9.604 1.396 9.8 4.759-1.568 3.92-29.008s2.728-6.146 2.697-7.495c-.032-1.348.69-3.073-.102-4.225-.792-1.153-2.203-3.458-2.203-3.458s-3.544-63.527-3.544-65.26c0-1.733-10.482-11.901-11.258-12.285-.776-.385-5.559-7.495-11.76-9.408-6.202-1.913-9.988.376-9.988.376Z"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m190.394 124.554-2.32 21.097 9.533 4.43-2.383 7.158 23.841 41.897M217.027 126.921l7.15 16.692-3.066 4.084 5.112 7.15-2.046 45.99"
      />
      <path
        fill={colors.brand.pure}
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M195.82 118.603c.174 4.116.92 8.188 2.218 12.098 2.062 5.762 15.727 45.519 15.727 45.519l8.836 31.109s-1.168-31.69-1.199-33.03c-.032-1.341-6.696-45.331-6.696-45.331s-1.137-7.103-2.932-9.792-13.83-7.683-15.954-.573Z"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M194.973 115.53s-5.559.573-4.579 9.024c.98 8.452 2.274 14.214 2.274 14.214"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M191.986 149.908s2.352 9.792 3.959 12.481c1.607 2.69 6.468 5.379 9.926 5.379"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M199.418 147.604s2.587 11.328 3.019 13.445c.431 2.117-.996 6.139-3.395 10.192"
      />
      <path
        fill="#263238"
        d="M198.352 172.582a1.066 1.066 0 0 1-.423-1.443l.274-.501a1.033 1.033 0 0 1 1.427-.385 1.076 1.076 0 0 1 .431 1.443l-.274.494a1.044 1.044 0 0 1-1.435.392ZM207.156 167.98a1.028 1.028 0 0 1-1.066 1.027h-.572a1.072 1.072 0 0 1-.972-1.484 1.018 1.018 0 0 1 .98-.625h.572a1.09 1.09 0 0 1 1.058 1.082Z"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="m192.002 150.677-1.317-8.35a3.433 3.433 0 0 1 2.595-3.92 3.631 3.631 0 0 1 4.312 2.603l2.25 8.311"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M222.992 156.32c1.887-.189 3.24-2.106 3.022-4.282-.218-2.176-1.924-3.786-3.811-3.597-1.887.189-3.24 2.106-3.022 4.282.218 2.175 1.924 3.786 3.811 3.597Z"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M213.35 119.05s7.55 10.623 9.957 32.278"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M223.507 153.834c1.031-.095 1.775-1.148 1.664-2.351-.112-1.203-1.038-2.1-2.069-2.005-1.03.096-1.775 1.149-1.663 2.352.112 1.203 1.038 2.1 2.068 2.004Z"
      />
      <path
        fill="#fff"
        d="M234.541 201.292a39.03 39.03 0 0 0-1.136 4.375c0 .627-.149 2.501-1.615 2.077-1.466-.423-1.027-7.91-.839-8.741.188-.831 3.755 1.858 3.755 1.858"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M234.541 201.292a39.03 39.03 0 0 0-1.136 4.375c0 .627-.149 2.501-1.615 2.077-1.466-.423-1.027-7.91-.839-8.741.188-.831 3.755 1.858 3.755 1.858"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M186.953 194.659a9.329 9.329 0 0 0 4.249 1.153c2.305 0 6.515-.784 8.059-.384 1.545.399 5.488 5.37 5.488 5.37s1.176 1.152.62 1.733c-.557.58-1.694 1.568-2.478.956-.784-.611-4.704-3.842-4.704-3.842l-9.886 4.038-4.061-2.117 2.713-6.907Z"
      />
      <path
        fill="#fff"
        d="M171.814 134.158s-6.515.784-7.621 2.493c-1.105 1.709-18.957 40.909-18.918 42.642.039 1.732 35.696 29.792 35.696 29.792l7.699-14.653-19.263-17.86 8.357-19.396"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M171.814 134.158s-6.515.784-7.621 2.493c-1.105 1.709-18.957 40.909-18.918 42.642.039 1.732 35.696 29.792 35.696 29.792l7.699-14.653-19.263-17.86 8.357-19.396M183.832 216.164l18.832.384-.094-11.713M188.325 203.683l-4.979 1.858"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M194.001 107.267s2.14 16.715 3.551 19.208c1.411 2.493 11.447 12.481 11.447 12.481s3.136-11.517 3.488-14.206c.353-2.689.628-13.445.628-13.445l-19.153-5.379.039 1.341Z"
      />
      <path
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M194.001 107.267s.251 1.96.635 4.633a19.227 19.227 0 0 0 5.08 3.403c2.47 1.09 9.055 1.701 13.218 1.215.11-2.814.181-5.213.181-5.213l-19.153-5.379.039 1.341Z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M188.45 92.676s-2.564-2.689-3.834.377c-1.27 3.065 1.568 7.683 3.081 7.879.898.117 1.81.05 2.682-.196 0 0 1.074 4.422 2.062 5.762a20.138 20.138 0 0 0 7.275 5.755c3.497 1.568 15.202 2.124 16.684 0 1.481-2.125 1.395-13.446 1.372-14.402-.024-.957-.188-14.645-.188-14.645s1.05-4.752-.377-8.02c-1.427-3.27-15.75-7.84-19.349-5.951-3.598 1.89-7.746 5.182-8.028 9.408-.282 4.225-.11 11.14-.11 11.14l-1.27 2.893Z"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M209.618 86.718s2.391 10.976 1.842 12.293c-.548 1.318-2.265 1.341-4.413.189-2.149-1.153-1.028-2.69-1.028-2.69M201.292 99.395s1.035 2.878 2.956 3.074c1.92.196 6.742.572 6.742.572M194.432 87.102l.306 4.61s1.756.964 5.786.964c4.029 0 5.354-.964 5.354-.964l-.109-4.61s-5.849-3.457-11.337 0Z"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m209.634 87.102 1.458 4.806s.596.784 4.633.784c4.038 0 5.363-.964 5.363-.964l-.118-4.61s-5.856-3.473-11.336-.016ZM205.792 87.102h3.842M194.456 87.102l-7.574 4.422M196.321 84.797s2.054-2.304 6.711-.384M210.339 84.797a4.705 4.705 0 0 1 4.979-.572"
      />
      <path
        fill="#263238"
        d="M201.229 89.219c0 .847-.305 1.568-.729 1.568-.423 0-.784-.69-.784-1.568 0-.878.306-1.568.737-1.568s.761.713.776 1.568ZM213.522 89.023c0 .847-.305 1.568-.729 1.568-.423 0-.784-.69-.784-1.568 0-.878.306-1.568.729-1.568.424 0 .761.721.784 1.568Z"
      />
      <path
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M200.367 71.258s1.85 4.084-.447 5.574a4.656 4.656 0 0 1-4.665 0 5.16 5.16 0 0 1 .149 5.833 32.353 32.353 0 0 1-3.92 5.245s.416 4.962-.447 5.543c-.862.58-3.214-.29-3.253-1.749-.04-1.458-.98-4.374-1.02-5.833-.039-1.458-.713-5.252-.713-5.252s-5.896-2.627-2.768-5.833c3.129-3.207 9.769-5.833 9.769-5.833s.149-5.833 4.187-7.582c4.037-1.748 12.724-4.374 12.795-1.458a8.97 8.97 0 0 1-.784 4.375s7.283-.29 12.363 4.704c5.081 4.994 4.908 9.627 2.282 9.627-2.627 0-3.481.58-3.481.58s5.025 2.917 2.438 4.375c-2.587 1.458-5.253-.29-5.253-.29s1.113-2.038-2.164-4.704c-3.277-2.665-15.068-7.322-15.068-7.322ZM100.885 366.128c22.659 0 41.027-4.142 41.027-9.251 0-5.11-18.368-9.252-41.027-9.252-22.658 0-41.027 4.142-41.027 9.252 0 5.109 18.369 9.251 41.027 9.251Z"
      />
      <path
        fill="#999"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M127.902 165.526v7.495l10.897 4.994s1.137-4.994.228-13.853l-11.125 1.364Z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M73.814 345.383a78.25 78.25 0 0 0-7.056 6.876c-3.136 3.536-3.748 7.056-.831 8.742 2.916 1.685 7.494 1.246 12.489-1.874 4.994-3.12 4.79-4.375 7.706-5.41 2.917-1.035 4.79-3.136 4.79-6.46S80.29 343.51 78 343.337c-2.29-.172-4.186 2.046-4.186 2.046Z"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M90.89 347.257s-4.705 2.086-6.986 3.983c-2.282 1.897-6.829 8.726-12.144 8.726a15.78 15.78 0 0 1-7.73-2.015 3.451 3.451 0 0 0 1.873 3.05c2.916 1.662 7.495 1.246 12.49-1.874 4.993-3.12 4.79-4.375 7.706-5.409 2.916-1.035 4.79-3.129 4.79-6.461Z"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M126.647 345.383a78.159 78.159 0 0 1 7.056 6.876c3.136 3.536 3.748 7.056.831 8.742-2.916 1.685-7.495 1.246-12.489-1.874-4.994-3.12-4.79-4.375-7.707-5.41-2.916-1.035-4.79-3.136-4.79-6.46s10.623-3.747 12.913-3.92c2.289-.172 4.186 2.046 4.186 2.046Z"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M109.572 347.257s4.704 2.086 6.985 3.983c2.282 1.897 6.829 8.726 12.144 8.726a15.785 15.785 0 0 0 7.731-2.015 3.45 3.45 0 0 1-1.874 3.05c-2.917 1.662-7.495 1.246-12.489-1.874-4.994-3.12-4.791-4.375-7.707-5.409-2.916-1.035-4.79-3.129-4.79-6.461Z"
      />
      <path
        fill={colors.brand.pure}
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M67.777 227.125s1.246 46.844 1.873 53.72c.628 6.875 2.478 22.485 2.478 23.347 0 .863 1.035 35.398 1.035 35.398l-1.874 3.136.627 4.374 8.326 1.663c4.163.831 9.58 2.085 10.412 1.254.83-.831 1.043-2.501 1.458-4.79.416-2.29 1.247-6.46.416-9.785-.831-3.324 1.042-38.729 1.254-39.56.212-.831 5.002-35.029 5.002-35.029s3.136 28.114 5.41 36.644c2.273 8.53 1.458 21.238 2.289 26.232.831 4.994 2.501 21.866 2.712 23.113.212 1.246.62 3.136.62 3.136s10.411-1.459 12.285-2.297c1.9-.7 3.85-1.256 5.833-1.662 0 0 .619-11.87-.212-16.66a67.432 67.432 0 0 1-1.042-10.192c0-1.459 1.042-47.676 1.042-47.676l-1.042-44.766s-28.107 2.917-38.934.831a434.774 434.774 0 0 0-19.757-3.332l-.211 2.901Z"
      />
      <path
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M87.76 227.54c-10.826-2.077-19.772-3.316-19.772-3.316l-.211 2.909s.07 2.689.188 6.86c4.963 1.387 15.045 3.826 24.79 3.826 10.302 0 26.711-3.136 34.096-4.634l-.157-6.468s-28.106 2.909-38.933.823Z"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M123.927 333.035c.133 1.435.219 2.258.219 2.258M120.736 238.117s.784 40.235.784 55.421c0 10.505 1.27 26.452 2.054 35.28M78.596 235.843l1.897 43.653M80.11 282.906v7.213"
      />
      <path
        fill={colors.brand.pure}
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M89.98 117.161a6.46 6.46 0 0 0-5.23 2.721 37.112 37.112 0 0 1-4.312 4.766l-18.62 9.996 1.82 71.078.681 24.304s14.3 4.767 27.926 4.767c13.626 0 37.922-5.488 37.922-5.488s-2.038-94.692-1.356-94.692c.682 0-13.398-12.23-19.757-15.445-6.358-3.214-19.074-2.007-19.074-2.007Z"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M84.672 125.753s7.213 13.289 10.255 19.365c3.042 6.076 8.718 14.818 8.718 14.818l9.494-25.433M90.003 210.042l-.368-10.608-21.427-1.246s.212 15.617.627 16.464M90.262 217.552l-.102-2.979M115.663 215.882l-.627-17.483s7.495.62 13.744-3.544M83.535 156.737c.659 2.078 2.666 3.301 4.477 2.721 1.81-.58 2.728-2.736 2.062-4.814-.667-2.078-2.666-3.301-4.477-2.72-1.81.58-2.728 2.736-2.062 4.813Z"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M83.747 120.36s-2.407 9.251 3.065 34.363"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M88.498 155.585a1.9 1.9 0 0 1-2.772.723 1.901 1.901 0 0 1-.329-2.846 1.893 1.893 0 0 1 1.98-.527 2.046 2.046 0 0 1 1.12 2.65Z"
      />
      <path
        fill={colors.brand.pure}
        d="M127.902 133.28s3.402 2.501 3.63 3.41c.227.91 11.807 28.608 11.807 28.608l-16.801 2.478.227-16.354"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M127.902 133.28s3.402 2.501 3.63 3.41c.227.91 11.807 28.608 11.807 28.608l-16.801 2.478.227-16.354"
      />
      <path
        fill={colors.brand.pure}
        d="M64.092 133.735s-3.865.455-4.775 3.402c-.909 2.948-7.487 32.928-7.487 32.928l24.069-2.289-2.274-22.485"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M64.092 133.735s-3.865.455-4.775 3.402c-.909 2.948-7.487 32.928-7.487 32.928l24.069-2.289-2.274-22.485"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M110.583 118.949s5.966 3.402 6.554 11.869c.588 8.468 0 10.616 0 10.616"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M119.834 152.276s-.533 10.012-1.623 12.905c-1.089 2.893-5.401 6.319-8.82 6.868"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M112.112 151.194s-.478 11.549-.525 13.697c-.047 2.148 2.116 5.888 5.221 9.471"
      />
      <path
        fill="#263238"
        d="m117.699 175.62.091-.074a.997.997 0 0 0 .146-1.401l-.439-.542a.997.997 0 0 0-1.401-.146l-.091.074a.996.996 0 0 0-.146 1.401l.439.541a.996.996 0 0 0 1.401.147ZM108.164 172.415l.022.116a.994.994 0 0 0 1.163.792l.686-.129a.997.997 0 0 0 .793-1.164l-.022-.116a.996.996 0 0 0-1.164-.793l-.685.13a.997.997 0 0 0-.793 1.164Z"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="m119.952 153.037-.227-8.413a3.532 3.532 0 0 0-3.278-3.434 3.528 3.528 0 0 0-3.778 3.238l-.706 8.53"
      />
      <path
        fill="#999"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m139.481 172.339-5.417-1.137-19.075-3.175-26.75-5.096-6.342-2.799s-5.998-7.84-6.515-8.624c-.518-.784-7.3 1.952-7.056 2.995.243 1.042 1.042 11.462 1.568 12.12.525.659 6.381.651 6.381.651s27.942 13.485 29.102 13.979c16.127 6.797 23.905 6.734 26.656 7.181 2.752.447 4.548-.666 6.131-2.485 1.584-1.819 1.317-13.61 1.317-13.61Z"
      />
      <path
        fill="#999"
        d="M74.566 151.398s-3.253.134-8.075 3.387c-1.795 1.216-2.736 2.086-2.736 2.611a8.03 8.03 0 0 0 .525 1.819 50.14 50.14 0 0 1 9.769-3.512"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M74.566 151.398s-3.253.134-8.075 3.387c-1.795 1.216-2.736 2.086-2.736 2.611a8.03 8.03 0 0 0 .525 1.819 50.14 50.14 0 0 1 9.769-3.512"
      />
      <path
        fill="#999"
        d="M75.868 159.74s-8.852 2.211-10.286 2.478c-1.435.266-1.694-1.176-1.694-1.694 0-.517.392-1.309 1.952-2.085s9.408-2.87 9.408-2.87"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M75.868 159.74s-8.852 2.211-10.286 2.478c-1.435.266-1.694-1.176-1.694-1.694 0-.517.392-1.309 1.952-2.085s9.408-2.87 9.408-2.87"
      />
      <path
        fill="#999"
        d="M76.785 163.777s-10.294 1.043-11.078.392a2.516 2.516 0 0 1-.65-1.952l9.25-1.96"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M76.785 163.777s-10.294 1.043-11.078.392a2.516 2.516 0 0 1-.65-1.952l9.25-1.96"
      />
      <path
        fill="#999"
        d="m73.915 164.295-7.942.392s.385 1.176.784 1.301a34.82 34.82 0 0 0 5.731.918 26.77 26.77 0 0 1 3.78.392"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m73.915 164.295-7.942.392s.385 1.176.784 1.301a34.82 34.82 0 0 0 5.731.918 26.77 26.77 0 0 1 3.78.392"
      />
      <path
        fill="#999"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M56.824 169.611s3.63 15.68 4.54 16.809c.909 1.129 8.404 0 15.9-2.274 7.494-2.273 39.285-15.892 39.285-15.892l4.54.902s-1.819-5.676-4.994-5.903c-3.176-.228-40.196 4.523-40.196 4.523l-19.075 1.835ZM88.592 107.165v.909c.07 2.988.36 12.474 1.121 14.763.91 2.721 13.171 25.661 14.112 23.842.941-1.819 6.586-26.57 6.586-26.57v-9.761s-2.956 2.493-8.405 2.493-13.414-5.676-13.414-5.676Z"
      />
      <path
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M106.781 120.618a31.737 31.737 0 0 0 3.637-2.587v-7.683s-2.955 2.493-8.404 2.493-13.422-5.676-13.422-5.676v.909c0 1.051.078 2.909.172 4.979.215.344.457.672.722.98 1.568 1.811 9.078 6.358 10.897 6.813 1.819.455 4.351.901 6.398-.228Z"
      />
      <path
        fill="#999"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M115.867 93.994s3.63-2.046 3.63 1.819c0 3.865-3.402 8.404-3.402 8.404l-.228-10.223Z"
      />
      <path
        fill="#999"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M83.394 94.221s-5.002-1.364-5.002 3.183 4.547 7.487 4.547 7.487h3.136s1.82 4.548 3.41 6.359c1.592 1.811 9.08 6.358 10.898 6.813 1.819.454 4.312.909 6.359-.228a37.841 37.841 0 0 0 7.056-5.676c.909-1.129 2.5-8.851 2.5-8.851s.455-13.854-.454-18.62c-.91-4.767-6.813-12.262-14.99-13.172-8.177-.909-15.9 5.677-17.029 10.898-1.129 5.221-.431 11.807-.431 11.807Z"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M98.149 97.859s-1.568 3.175 1.137 4.085c2.705.909 6.131.682 6.813-.683.682-1.364-.455-2.947-.455-2.947M95.428 103.535s5.677 4.767 13.399.228M98.604 107.847s4.774 2.729 7.949-.454"
      />
      <path
        fill="#263238"
        d="M95.883 95.13c.628 0 1.137-.814 1.137-1.818 0-1.005-.509-1.82-1.137-1.82s-1.137.815-1.137 1.82c0 1.004.51 1.818 1.137 1.818ZM108.145 93.085c0 1.003-.51 1.818-1.137 1.818s-1.137-.784-1.137-1.819c0-1.034.51-1.81 1.137-1.81s1.137.807 1.137 1.81Z"
      />
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M92.7 90.819s1.137-1.82 5.488-1.137M105.189 89.91s2.046-3.184 5.229 0"
      />
      <path
        fill="#263238"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M115.867 84.672a12.816 12.816 0 0 0-1.513-3.826 14.162 14.162 0 0 0-.274 3.826c.329 1.662 1.497 3.983 1.497 5.143 0 1.16-.172 5.81.659 5.151.082-.087.149-.188.196-.298a69.59 69.59 0 0 0-.565-9.996ZM104.452 75.264a32.491 32.491 0 0 0 4.344-.525 16.579 16.579 0 0 0-7.919-3.183c-8.177-.91-15.9 5.676-17.028 10.897-1.13 5.222-.455 11.807-.455 11.807s1.803 1.255 2.47-.07c.666-1.325 0-3.49.666-5.81.666-2.32 1.654-3.489 1.819-5.315.165-1.827-.996-4.477-.33-5.974.667-1.498 4.814-4.312 7.472-3.317a23.166 23.166 0 0 0 8.961 1.49Z"
      />
    </svg>
  );
}
