import React from "react";
import { Modal } from "semantic-ui-react";
import Button from "../library/Button";
import { Form, TextAreaField } from "../library/forms";

const modalContent = {
  width: "100%",
};

export default function ReportErrorModalForm({
  open,
  toggleReportErrorModal,
  handleSubmit,
}) {
  return (
    <Modal size="mini" open={open} onClose={toggleReportErrorModal}>
      <Modal.Header>Reportar Erro na Questão</Modal.Header>
      <Modal.Content style={modalContent}>
        <Form>
          <TextAreaField
            name="text"
            rows={5}
            placeholder="Descreva o erro..."
            fluid
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={toggleReportErrorModal}>Cancelar</Button>
        <Button positive onClick={handleSubmit}>
          Enviar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
