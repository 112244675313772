import { apiGet } from "./helpers/api";
import { normalize, schema } from "normalizr";
import toQueryString from "../utils/toQueryString";

const subject = new schema.Entity("subjects");

export const fetchSubjects = (dispatch, specialitiesIdIn) => {
  const filter = toQueryString({ specialitiesIdIn });
  apiGet(`/api/v1/subjects?${filter}`).then((response) => {
    dispatch({
      type: "SUBJECTS_FETCHED",
      ...normalize(response.body.data, new schema.Array(subject)),
    });
  });
};

export const fetchSubjectsReport = (values, dispatch) => {
  dispatch({ type: "SUBJECT_REPORTS_FETCH_REQUESTED" });
  const filters = toQueryString(values);
  if (!filters) return;
  return apiGet(`/api/v1/subjects_report?${filters}`).then((response) => {
    dispatch({
      type: "SUBJECT_REPORTS_FETCHED",
      ...normalize(response.body.data, new schema.Array(subject)),
    });
    dispatch({
      type: "SUBJECTS_FETCHED",
      ...normalize(response.body.data, new schema.Array(subject)),
    });
  });
};
