import { spacing, styles, typographyColors, uiColors } from "../library/theme";

import { Header } from "semantic-ui-react";
import React from "react";
import { connect } from "react-redux";
import { css } from "glamor";
import { getMockTemplateById } from "../../selectors/mockTemplateSelectors";

const container = css(styles.shadow, styles.roundedCorners, {
  display: "flex",
  backgroundColor: uiColors.fieldBackground,
  overflow: "auto",
  cursor: "pointer",
  padding: spacing.m,
  justifyContent: "space-between",
});
const box = css({
  flex: 1,
  textAlign: "center",
  "& + &": {
    paddingLeft: spacing.m,
    marginLeft: spacing.m,
    borderLeft: `1px solid ${uiColors.border}`,
  },
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const rankingClass = css({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});
const rakingSizeClass = css({
  color: typographyColors.defaultBody,
});

const toPercentage = (number) => (number * 100).toFixed(0);

function MockReportPanel({ mock, isPreExam }) {
  const totalAnsweredQuestions = mock?.correctAnswersCount + mock?.incorrectAnswersCount
  const totalQuestions = mock?.quantity

  return mock ? (
    <div className={container}>
      <div className={box}>
        <Header as="h1">
          {totalAnsweredQuestions}/{totalQuestions}
          <p>Questões Resolvidas</p>
        </Header>
      </div>
      <div className={box}>
        <Header as="h1" color="green">
          {mock.correctAnswersCount}
          {mock?.quantity && (
            <p>
              Respostas Certas (
              {toPercentage(mock.correctAnswersCount / mock?.quantity)}
              %)
            </p>
          )}
        </Header>
      </div>
      <div className={box}>
        <Header as="h1" color="red">
          {mock.incorrectAnswersCount}
          {mock?.quantity && (
            <p>
              Erradas ou Não Respondidas (
              {toPercentage(mock.incorrectAnswersCount / mock?.quantity)}
              %)
            </p>
          )}
        </Header>
      </div>
      <div className={box}>
        <Header as="h1">
          {mock.score}
          <p>Sua nota</p>
        </Header>
      </div>
      {!!mock.mockTemplateId && !isPreExam && (
        <>
          <div className={box}>
            <Header as="h1">
              {mock?.overallScoreAverage}
              <p>Média Geral</p>
            </Header>
          </div>
          <div className={box}>
            <Header as="h1">
              <span className={rankingClass}>
                <h1>{mock?.rankingPosition}</h1>
                <h5 className={rakingSizeClass}>/{mock?.rankingSize}</h5>
              </span>
              <p>Ranking</p>
            </Header>
          </div>
        </>
      )}
    </div>
  ) : null;
}

function mapStateToProps(state, ownProps) {
  const mockTemplate = getMockTemplateById(state, ownProps.mock.mockTemplateId)
  const isPreExam = mockTemplate ? mockTemplate.preExam : false

  return ({
    isPreExam,
    ...ownProps
  })
}

export default connect(mapStateToProps)(MockReportPanel)
