import * as React from "react";
import { colors } from "../../../../../../components/library/theme";

export function CompleteProgressIllustration() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={235}
      height={246}
      fill="none"
      className="illustration"
    >
      <path
        stroke="#CCDDE2"
        strokeMiterlimit={10}
        strokeWidth={1.361}
        d="M62.375 155.249c3.478-7.918 5.674-17.054 5.88-27.649 1.006-51.82 59.599-91.809 124.597-47.855 64.997 43.954 7.552 164.874 7.552 164.874M16.932 244.62c-2.872-2.257-24.765-23.663-11.615-35.915 5.618-5.229 17.712-11.334 29.826-20.644"
      />
      <path
        stroke="#CCDDE2"
        strokeMiterlimit={10}
        strokeWidth={1.361}
        d="M173.595 114.734a5.897 5.897 0 0 0 1.77 3.197"
      />
      <path
        fill="#1D2224"
        d="M122.567 138.263c-.451.119-.831.204-1.133.274-.225.039-.411.079-.539.097h-.027l-.177.031s-.018-.022-.03-.022c-3.046-2.235-2.111-6.395-2.111-6.395 9.106 1.435 10.994-5.941 10.994-5.941-.119-3.536-.792-5.199-2.345-6.155-1.623-1.017-4.212-1.279-8.168-2.132l-.654-.146c-5.884-1.337-8.628-7.376-9.712-10.726a17.78 17.78 0 0 1-.609-2.269c-6.663 2.504-10.836 6.048-13.238 8.707-.95-18.848 17.967-16.14 17.967-16.14 3.184.045 6.348.508 9.411 1.376.694.305 1.358.61 1.991.914.305.155.628.322.914.496.286.174.609.344.889.518.204.119.411.246.609.38a26.037 26.037 0 0 1 9.593 10.866c.03.061.057.123.079.186.096.206.181.418.253.634 4.282 18.735-8.929 24.098-13.957 25.447Z"
      />
      <path
        fill="#1D2224"
        d="M122.567 138.262c-1.328-2.208-.712-4.873-.712-4.873 9.096 1.438 10.99-5.938 10.99-5.938-.195-5.424-1.659-6.441-5.646-7.309-1.477-.332-3.292-.636-5.521-1.133a9.933 9.933 0 0 1-2.647-1.008c-6.057-3.319-7.689-11.996-7.689-11.996-.938.351-1.827.731-2.677 1.124a17.78 17.78 0 0 1-.609-2.269c-6.663 2.504-10.836 6.048-13.238 8.707-.95-18.848 17.967-16.14 17.967-16.14a36.44 36.44 0 0 1 9.411 1.376c.694.305 1.358.61 1.991.914.305.155.628.322.914.496.286.174.609.344.889.518.204.118.411.246.609.38a26.05 26.05 0 0 1 9.593 10.866c.03.061.057.123.079.186.096.207.181.418.253.634 4.282 18.753-8.929 24.116-13.957 25.465Z"
      />
      <path
        fill={colors.brand.pure}
        d="M154.492 221.679c1.407 9.184 3.127 18.22 4.924 22.94H78.65c-9.724-27.609-5.114-57.77-5.114-57.77l-14.343.45c8.627-15.135 5.557-27.932 4.102-32.202 11.268-1.915 22.535-3.42 33.922-3.548-.304 6.518 6.81 14.009 17.07 12.039 4.16-.792 9.23-2.436 9.34-9.724l2.677.469c3.703.609 7.053 1.261 10.229 1.995 8.223 1.894 15.288 4.263 24.092 7.26-12.181 9.017-6.127 29.845-6.127 29.845l.304.694-3.889-.694s1.441 14.286 3.579 28.246Z"
      />
      <path
        fill="#1D2224"
        d="M181.556 144.748v.049l-.752 17.292-.42 9.474c0-.35.018-.713.04-1.063-5.199-1.739-11.877-4.212-17.03-5.96-10.141-3.459-17.782-6.118-26.867-8.207-.548-2.646-1.065-8.323 1.484-16.609 3.654-11.823 4.857-23.818 2.999-26.136a.237.237 0 0 0-.067-.067c-1.321-4.766-4.818-1.681-4.818-1.681s.022.049.031.076a35.328 35.328 0 0 0-2.394-3.478c.029-.172.075-.341.137-.505 1.124-3.381 7.111-4.194 7.111-4.194 8.765.646 13.376 4.553 15.702 9.685 2.05 6.477.841 13.5.576 20.145-.107 2.881-.518 7.309 1.681 9.642 2.031 3.84 7.141 2.18 8.527 1.76.451-.128 1.62-.207 3.155-.256 3.128-.076 7.729-.018 10.534.03.137.003.256.003.371.003ZM200.392 201.071a1.047 1.047 0 0 1-.018.158.055.055 0 0 0 0 .04 16.138 16.138 0 0 1-3.42 7.756c-.022.019-.031.049-.049.067-2.783 3.469-6.819 5.972-10.902 7.544-4.173 1.623-8.403 2.278-11.415 2.052h-.067c-3.685-.274-7.729.204-11.268.868-.371.07-.743.137-1.114.216-2.336.469-4.641 1.054-6.925 1.7l-.43.119-.286.076c-2.132-13.96-3.575-28.255-3.575-28.255l43.549 7.738c1.96.356 3.97.329 5.92-.079Z"
      />
      <path
        stroke="#000"
        strokeMiterlimit={10}
        strokeWidth={1.361}
        d="M110.958 134.711c-2.29 2.272-5.357 2.71-8.369 3.38-6.843 1.523-12.58-.207-12.882-8.073-.194-5.061.342-10.126 3.405-14.329 3.67-5.034 9.307-8.387 14.923-10.835 0 0 2.576 13.18 14.313 13.753 11.737.572 8.88 16.046-4.873 13.467M119.781 138.665c.304 0 .584 0 .868-.022.069-.004.138-.004.207 0h.027l.539-.048"
      />
      <path
        stroke="#000"
        strokeMiterlimit={10}
        strokeWidth={1.361}
        d="M132.784 106.472a26.338 26.338 0 0 0-6.8-5.747 9.398 9.398 0 0 0-.889-.518 23.18 23.18 0 0 0-.914-.496 21.31 21.31 0 0 0-1.992-.914 32.695 32.695 0 0 0-12.717-2.537c-23.486 0-16.96 20.322-16.96 20.322M136.683 156.133c-.811-4.318.204-11.039 1.327-16.415a120.056 120.056 0 0 1 2.111-8.499c1.663-9.77 1.593-15.008.822-17.703-1.322-4.76-4.818-1.675-4.818-1.675"
      />
      <path
        stroke="#000"
        strokeMiterlimit={10}
        strokeWidth={1.361}
        d="M140.943 113.516c-1.322-4.76-4.818-1.675-4.818-1.675a21.588 21.588 0 0 0-2.226-3.907 24.778 24.778 0 0 0-1.115-1.438c1.045-2.774 3.831-4.428 6.7-4.65 7.531-.579 14.956 4.407 17.225 11.572 2.049 6.478.84 13.501.575 20.146-.106 2.88-.517 7.308 1.682 9.641.115.119.225.226.35.332 1.827 1.575 4.738 1.663 8.189 1.428a146.33 146.33 0 0 0 3.155-.255c3.35-.305 7.004-.567 10.534.03l.35.058c-.107 5.756-.46 11.53-.752 17.286-.155 2.801-.305 5.606-.381 8.411-5.198-1.739-11.877-4.212-17.03-5.96l-2.747-.947c-8.804-3-15.857-5.366-24.092-7.26a159.673 159.673 0 0 0-10.229-1.995l-3.938-.691a1.132 1.132 0 0 1-.938-1.124v-13.933a17.103 17.103 0 0 0 13.004-7.893c3.81-6.128 4.172-12.398 2.089-17.901a6.002 6.002 0 0 0-.259-.646 1.582 1.582 0 0 0-.079-.185c0-.019-.018-.049-.027-.07M39.294 163.588c1.218-9.441 1.779-19.043 2.96-28.538M42.449 133.564c.332-2.571.725-5.141 1.218-7.681.018-.125.04-.243.067-.359.274-1.504.579-3 .914-4.495 1.017-4.474 4.568-7.747 7.092-11.393l.156-.39a49.39 49.39 0 0 0 8.676-26.878c.06-3.478-.076-10.355-4.063-11.774-2.238-.8-4.613.765-5.853 2.552-.509.738-.918 1.54-1.218 2.385"
      />
      <path
        stroke="#000"
        strokeMiterlimit={10}
        strokeWidth={1.361}
        d="M78.65 244.619c-9.724-27.61-5.114-57.771-5.114-57.771l-14.34.45-37.83 1.2A15.53 15.53 0 0 1 5.41 171.313l11.238-41.024M17.194 128.286l.986-3.594 1.145-4.075M20.3 117.109l.655-2.354a14.382 14.382 0 0 0-.61-9.44l-1.3-3.107c-.85-2.588-1.114-3.84-.048-6.37a11.198 11.198 0 0 1 2.092-3.244c2.567-2.832 6.182-4.24 9.907-4.72 3.724-.481 7.835-.107 10.832 2.308 2.034 1.639 3.96 5.54 2.628 8.089-.965 1.854-3.009 2.393-4.748 1.729a4.231 4.231 0 0 1-1.601-1.065c-2.053-2.15-6.396-2.62-7.918-.527M180.326 173.915c0-.764.027-1.523.057-2.296v-.061c0-.35.019-.713.04-1.063M176.419 100.764c-3.566 1.907-7.946 6.7-.557 17.968 2.436 3.712 5.009 7.726 5.372 12.309.337 4.58.445 9.175.322 13.765"
      />
      <path
        stroke="#000"
        strokeMiterlimit={10}
        strokeWidth={1.361}
        d="m141.832 191.813 8.479 1.504.609.107 3.889.694 39.654 7.044c1.963.357 3.976.33 5.929-.079a15.556 15.556 0 0 0 12.337-14.618l-5.859-47.968-1.298-10.238a14.361 14.361 0 0 1 1.962-9.261l1.738-2.893s3.028-4.416-2.268-4.739c-2.854-.168-5.823.609-8.403 1.778-1.474.655-3.544 1.828-4.376 3.439a2.99 2.99 0 0 0-.323 2.004c.609 3.145 4.568 4.473 7.181 3.145"
      />
      <path
        stroke="#000"
        strokeMiterlimit={10}
        strokeWidth={1.361}
        d="M208.751 111.594s2.994-1.782.969-6.164c-.719-1.559-2.897-2.64-7.583-2.342-12.486.795-17.377 13.634-7.897 13.494"
      />
      <path
        stroke="#000"
        strokeMiterlimit={10}
        strokeWidth={1.361}
        d="M205.907 103.067c2.771-3.819-2.132-7.473-5.704-7.348-3.368.118-6.776 1.364-9.727 2.923-4.105 2.168-9.404 6.368-7.888 11.682.448 1.566 3.99 3.499 6.304 2.382"
      />
      <path
        stroke="#000"
        strokeMiterlimit={10}
        strokeWidth={1.361}
        d="M182.327 108.435v.019c-1.934 3.694-11.089 0-5.921-7.69a21.616 21.616 0 0 1 4.297-4.504c12.889-10.531 15.769.177 15.751-.46M187.936 112.791s4.714 3.35 3.268 8.932M21.963 85.995c-.305-1.2-.265-2.714.447-4.65 1.026-2.784 4.017-4.611 6.928-5.814a36.083 36.083 0 0 1 3.046-1.085c3.176-.974 6.663-1.492 10.05-1.15 1.141.11 2.27.326 3.37.645a14.83 14.83 0 0 1 3.616 1.59c2.783 1.711 4.668 4.309 5.801 7.72.987 2.941 1.368 6.438-1.075 8.274"
      />
      <path
        fill={colors.brand.light200}
        d="m54.368 130.671-.898 4.348a1.104 1.104 0 0 1-.492.716 1.12 1.12 0 0 1-.857.143c-3.457-.819-6.487-1.58-9.672-2.314-1.58-.372-3.213-.722-4.943-1.085-6.76-1.397-13.512-2.975-20.31-4.193a139.79 139.79 0 0 0-8.548-1.282c-1.602-.186-3.225-.262-4.836-.381a1.714 1.714 0 0 1-.977-.332c-1.523-1.114 0-4.738.331-6.243v-.058c.235-1.154 6.868-.822 16.141.625 3.234.508 6.788 1.142 10.513 1.906 5.235 1.075 9.955 2.235 13.813 3.35.664.195 1.3.381 1.907.566h.018c.77.244 1.495.479 2.178.713 4.309 1.471 6.79 2.75 6.633 3.521Z"
      />
      <path
        stroke="#000"
        strokeMiterlimit={10}
        strokeWidth={1.361}
        d="m54.368 130.671-.898 4.348a1.104 1.104 0 0 1-.492.716 1.12 1.12 0 0 1-.857.143c-3.457-.819-6.487-1.58-9.672-2.314-1.58-.372-3.213-.722-4.943-1.085-6.76-1.397-13.512-2.975-20.31-4.193a139.79 139.79 0 0 0-8.548-1.282c-1.602-.186-3.225-.262-4.836-.381a1.714 1.714 0 0 1-.977-.332c-1.523-1.114 0-4.738.331-6.243v-.058c.235-1.154 6.868-.822 16.141.625 3.234.508 6.788 1.142 10.513 1.906 5.235 1.075 9.955 2.235 13.813 3.35.664.195 1.3.381 1.907.566h.018c.77.244 1.495.479 2.178.713 4.309 1.471 6.79 2.75 6.633 3.521ZM44.885 95.928l.865 1.733c1.087 1.19 2.592 1.943 4.654 1.425 4.044-1.005 4.443-4.358 3.74-7.561a14.427 14.427 0 0 0-.47-1.718c-.947-2.826-2.411-5.1-4.464-6.76a13.706 13.706 0 0 0-4.952-2.553 18.678 18.678 0 0 0-4.525-.721 26.417 26.417 0 0 0-8.89 1.218c-2.872.88-6.8 2.393-8.89 4.991a6.694 6.694 0 0 0-1.096 1.906c-.642 1.749-.752 3.165-.526 4.31l.216 1.066M46.006 74.008l1.005-4.163 1.194-4.903a5.296 5.296 0 0 0-2.278-5.674 5.292 5.292 0 0 0-2.013-.776 4.996 4.996 0 0 0-.85-.067 5.264 5.264 0 0 0-5.159 4.15c-.304 1.42-.685 3.147-1.105 5.093-.4 1.845-.84 3.867-1.31 6"
      />
      <path
        stroke="#000"
        strokeMiterlimit={10}
        strokeWidth={1.361}
        d="M30.198 98.404c-.305 1.495-.479 2.436-.47 2.598v.021c-1.318 8.862-5.198 13.601-9.419 16.07a19.485 19.485 0 0 1-8.43 2.513M39.733 99.997s-7.072 18.848 3.986 25.529c.585.351 1.196.657 1.827.914.757.314 1.538.566 2.336.752M52.886 135.79s.048.871-10.632-.74c-3.654-.549-8.567-1.389-15.172-2.659a515.87 515.87 0 0 1-10.434-2.101c-14.977-3.204-13.816-3.996-13.816-3.996"
      />
      <path
        fill={colors.brand.pure}
        d="M82.977 29.844C80.7 41.919 73.195 54.63 64.096 62.789c-3.722 3.35-8.05 5.685-12.72 6.605a22.33 22.33 0 0 1-4.356.448l1.185-4.9a5.303 5.303 0 0 0-3.009-6.091 5.749 5.749 0 0 0-1.279-.38 4.99 4.99 0 0 0-.853-.068 5.268 5.268 0 0 0-5.158 4.17c-.186.889-.412 1.897-.655 2.999-11.573-7.65-16.591-24.013-16.034-37.3.177-4.416.42-10.23 2.354-14.724a12.648 12.648 0 0 1 2.863-4.264c5.481-5.159 12.985-7.513 20.723-7.866a51.185 51.185 0 0 1 17.274 3.88C74.663 9.619 85.34 17.28 82.977 29.844Z"
      />
      <path
        fill={colors.brand.light200}
        d="M82.977 29.844C80.7 41.919 73.195 54.63 64.096 62.789c-3.722 3.35-8.05 5.685-12.72 6.605l1.08-4.452a5.296 5.296 0 0 0-2.278-5.674 5.296 5.296 0 0 0-2.013-.776 4.994 4.994 0 0 0-.85-.067 5.195 5.195 0 0 0-2.131.447 5.744 5.744 0 0 0-1.28-.38 5 5 0 0 0-.852-.067 5.264 5.264 0 0 0-5.146 4.147c-.186.89-.412 1.898-.655 3-11.573-7.65-16.591-24.013-16.034-37.3.177-4.416.42-10.23 2.354-14.724a12.648 12.648 0 0 1 2.863-4.264c5.481-5.159 12.985-7.513 20.723-7.866a51.185 51.185 0 0 1 17.274 3.88C74.663 9.619 85.34 17.28 82.977 29.844Z"
      />
      <path
        stroke="#000"
        strokeMiterlimit={10}
        strokeWidth={1.361}
        d="M82.977 29.847c-2.275 12.075-9.779 24.786-18.881 32.945-4.873 4.367-10.753 7.022-17.085 7.053l1.194-4.903a5.296 5.296 0 0 0-2.278-5.674 5.291 5.291 0 0 0-2.013-.776 4.985 4.985 0 0 0-.85-.067 5.266 5.266 0 0 0-5.158 4.15c-.305 1.42-.686 3.146-1.106 5.092C22.517 61.336 16.35 42.94 16.97 28.275c.173-4.416.417-10.23 2.354-14.724a12.601 12.601 0 0 1 2.862-4.264C32.97-.853 51.525-.153 64.431 5.301c10.23 4.321 20.91 11.98 18.546 24.546Z"
      />
      <path
        stroke="#000"
        strokeMiterlimit={10}
        strokeWidth={1.361}
        d="M82.916 30.282c2.464 2.948 4.775 7.888 2.74 15.227a15.303 15.303 0 0 1-1.333 3.329c-3.234 5.786-9.477 6.8-13.686 6.74"
      />
      <path
        stroke="#000"
        strokeMiterlimit={10}
        strokeWidth={1.361}
        d="M81.04 36.455c2.132 2.552 4.102 6.594 3.28 12.389a21.2 21.2 0 0 1-.575 2.756C80.698 62.539 69.52 62 65.681 61.467M21.335 51.541c-2.381.49-15.857-2.274-16.022-13.509a10.397 10.397 0 0 1 .841-4.437c2.11-4.757 7.677-6.261 10.884-6.752"
      />
      <path
        stroke="#000"
        strokeMiterlimit={10}
        strokeWidth={1.361}
        d="M18.912 45.91c-2.437.15-15.383-4.47-13.979-15.62.168-1.51.663-2.964 1.453-4.263 2.74-4.419 8.478-5.128 11.718-5.177M73.536 186.851s.277-2.116 1.8-6.897M123.62 153.845v.021c-.109 7.282-5.177 8.932-9.34 9.724-10.26 1.961-17.383-5.521-17.07-12.038M99.157 113.781s.304 3.35-2.61 7.671c-.78 1.163 1.919 1.642 2.561 1.858M110.907 121.03c1.731-2.998 1.158-6.568-1.279-7.975-2.436-1.407-5.815-.118-7.545 2.88-1.731 2.998-1.159 6.568 1.278 7.975 2.437 1.407 5.816.118 7.546-2.88Z"
      />
      <path
        stroke="#000"
        strokeMiterlimit={10}
        strokeWidth={1.366}
        d="M98.818 117.153c1.73-3.011 1.149-6.593-1.3-8-2.447-1.407-5.835-.106-7.565 2.905-1.731 3.012-1.15 6.593 1.298 8 2.449 1.407 5.836.106 7.567-2.905Z"
      />
      <path
        fill="#fff"
        d="M94.601 110.757a2.846 2.846 0 0 1 1.45.38 3.192 3.192 0 0 1 1.495 2.339 5.248 5.248 0 0 1-.703 3.432c-.914 1.569-2.437 2.58-3.944 2.58a2.835 2.835 0 0 1-1.45-.378 3.188 3.188 0 0 1-1.495-2.339 5.259 5.259 0 0 1 .703-3.435c.914-1.565 2.437-2.579 3.944-2.579Zm0-.914c-1.76 0-3.627 1.121-4.73 3.046-1.522 2.637-1.02 5.786 1.124 7.004a3.76 3.76 0 0 0 1.904.502c1.76 0 3.627-1.123 4.732-3.045 1.523-2.634 1.017-5.786-1.127-7.004a3.782 3.782 0 0 0-1.903-.5v-.003Z"
      />
      <path
        fill="#BEE8FF"
        d="M97.63 117.354c1.522-2.637 1.019-5.777-1.124-7.014-2.142-1.237-5.113-.102-6.635 2.534-1.523 2.636-1.02 5.776 1.123 7.013 2.143 1.238 5.113.103 6.636-2.533ZM111.041 120.163c1.522-2.648 1.011-5.798-1.141-7.035-2.153-1.237-5.132-.093-6.654 2.555-1.522 2.649-1.011 5.799 1.142 7.036 2.152 1.237 5.131.093 6.653-2.556Z"
      />
      <path
        stroke="#000"
        strokeMiterlimit={10}
        strokeWidth={1.361}
        d="M99.117 114.886s.914-1.361 2.976 1.048"
      />
      <path
        fill="#353642"
        d="M108.082 118.213c.496-.649.452-1.517-.099-1.938-.551-.42-1.399-.234-1.895.415-.496.65-.452 1.518.099 1.938.551.421 1.399.235 1.895-.415ZM97.808 115.256c.496-.65.452-1.517-.099-1.938-.55-.42-1.4-.234-1.895.415-.496.65-.452 1.517.099 1.938.55.42 1.4.235 1.895-.415Z"
      />
      <path
        stroke="#000"
        strokeMiterlimit={10}
        strokeWidth={1.361}
        d="M105.37 128.739c-6.237 5.299-9.511.578-9.511.578M39.294 159.199c5.393-.965 10.835-1.72 16.18-2.707 2.607-.479 5.212-.944 7.815-1.395 11.596-1.973 23.175-3.508 34.9-3.557a2.344 2.344 0 0 0 2.323-2.022l.216-1.623 1.368-10.12M121.422 138.585v-.292M2.81 244.619H234.855M150.916 193.423s1.438 14.296 3.576 28.256c1.407 9.184 3.127 18.22 4.924 22.94"
      />
      <path
        stroke="#000"
        strokeMiterlimit={10}
        strokeWidth={1.361}
        d="m154.492 221.678.283-.076.432-.119a98.335 98.335 0 0 1 6.925-1.699c.372-.08.744-.147 1.115-.217a46.667 46.667 0 0 1 7.894-.77c1.172 0 2.296-.04 3.371-.098h.067c4.504-.255 8.268-.977 11.414-2.052 5.138-1.748 8.606-4.434 10.903-7.544.018-.018.027-.048.048-.067a20.386 20.386 0 0 0 3.42-7.756.068.068 0 0 1 0-.04l.031-.146"
      />
    </svg>
  );
}
