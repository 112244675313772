import React, { useMemo } from "react";
import LevelGroupListItemContainer from "../levelGroups/LevelGroupListItemContainer";
import Spinner from "../library/Spinner";
import { spacing } from "../library/theme";

const loadingStyle = {
  padding: `${spacing.xxl} 0`,
  textAlign: "center",
};

export default function ClassroomContents({ levelGroups, compact, loading }) {
  if (loading) {
    return (
      <div style={loadingStyle}>
        <Spinner inline />
      </div>
    );
  }


  const memoizedLevelGroups = levelGroups?.map((levelGroup, index) => {
    const isTheLastLevelGroup = index === levelGroups.length - 1;

    return useMemo(() => (
      <LevelGroupListItemContainer
        key={index}
        index={index}
        levelGroup={levelGroup}
        compact={compact}
        isTheLastLevelGroup={isTheLastLevelGroup}
      />
    ), [levelGroups]);
  });

  return <>{memoizedLevelGroups}</>;
}
