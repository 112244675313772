import { apiGet } from "./helpers/api";
import { normalize, schema } from "normalizr";

const alternative = new schema.Entity("alternatives");

export const selectAlternative = (dispatch, alternative) => {
  dispatch({
    type: "ALTERNATIVE_SELECTED",
    alternative,
  });
};

export const fetchAlternatives = (dispatch, mockId) => {
  apiGet(`/api/v1/mocks/${mockId}/alternatives`).then((response) => {
    dispatch({
      type: "ALTERNATIVES_FETCHED",
      ...normalize(response.body.data, new schema.Array(alternative)),
    });
  });
};

export const addToDiscartedAlternatives = (values, dispatch) => {
  return dispatch({ type: "DISCARD_ALTERNATIVE", ...values });
};

export const removeFromDiscartedAlternatives = (values, dispatch) => {
  return dispatch({ type: "UNDISCARD_ALTERNATIVE", ...values });
};
