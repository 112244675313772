function getYears() {
  const NUMBER_OF_YEARS = 4
  const currentYear = new Date().getFullYear()
  const years = []
  for (let year = currentYear + 1; year < currentYear + NUMBER_OF_YEARS; year++) {
    years.push(year)
  }
  years.push('Outros anos')
  return years
}

export const YEARS = getYears()
