import { PlaceholderContainer, PlaceholderDescription } from "./styles";
import React from "react";
import Illustration from "../Illustration";

export default function Placeholder() {
  return (
    <PlaceholderContainer>
      <Illustration />
      <PlaceholderDescription>
        <p>Você não possui nenhuma questão favorita ainda</p>
        <span>Favorite questões quando estiver respondendo algum simulado</span>
      </PlaceholderDescription>
    </PlaceholderContainer>
  );
}
