import React from "react";

const whiteList = ["/reset-password", "/signup", "/forgotten-password"];

export default function RequireSignIn(props) {
  const { history, location } = props;
  React.useEffect(() => {
    if (whiteList.indexOf(location.pathname) != -1) return;
    history.push(`/signin-v2`);
  }, [history]);
  return <div />;
}
