import React from "react";
import { connect } from "react-redux";
import NotificationsPage from "./NotificationsPage";
import { useLoadNotifications } from "./useLoadNotifications";
import {
  getNotification,
  getPageNotifications,
} from "../../selectors/notificationSelectors";

function NotificationsPageContainer(props) {
  const { loading } = useLoadNotifications();

  return <NotificationsPage loading={loading} {...props} />;
}

function mapStateToProps(state, ownProps) {
  const notification = getNotification(
    state,
    ownProps?.match?.params?.notificationId
  );

  return {
    notification,
    notifications: getPageNotifications(state, notification?.id),
  };
}

export default connect(mapStateToProps)(NotificationsPageContainer);
