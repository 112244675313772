import React from "react";
import QuestionsListItemContainer from "./QuestionsListItemContainer";

export default function QuestionsList({ questions, mockId }) {
  return questions.map((question) => (
    <QuestionsListItemContainer
      key={`${question.id}-${Date.now()}`}
      form={`answer-mock-${mockId}-question-${question.id}`}
      question={question}
      mockId={mockId}
    />
  ));
}
