const getQuestionNumber = (a = "") => {
  return Number(a.split("Q")[a.split("Q").length - 1]);
};

export function orderByCode(a, b) {
  if (!getQuestionNumber(a.code)) return 1;
  if (getQuestionNumber(a.code) == getQuestionNumber(b.code)) {
    return a.id - b.id;
  }
  return getQuestionNumber(a.code) - getQuestionNumber(b.code);
}
