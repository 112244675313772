import jwt from "jsonwebtoken";
import { refresher } from "./userProxyTokenRefresher";

export async function getValidToken() {
  if (!refresher.token) return null;

  const decoded = jwt.decode(refresher.token);
  if (!decoded) return null;

  // exp do jwt vem em segundos
  const exp = (decoded.exp ?? 0) * 1000;

  const ONE_MINUTE = 1000 * 60;
  const limit = exp - ONE_MINUTE;

  if (new Date().getTime() >= limit) {
    const ok = await refresher.refresh();
    if (!ok) return null;
  }

  return refresher.token;
}
