import React from "react";
import { Divider } from "../../../../components/Divider";
import { ContentContainer } from "../../screens/MainScreen/styles";
import { Item } from "./item";
import {
  ButtonsContainer,
  Container,
  Content,
  Filters,
  ItemHeader,
  ItemsContainer,
  SkeletonStyle,
  SkeletonTitle,
  FilterAndTimeWrapper,
  Header,
} from "./styles";

export function Skeleton({ showHeader = false }) {
  return (
    <>
      {showHeader && (
        <>
          <Header>
            <SkeletonTitle>MedTrack</SkeletonTitle>
            <FilterAndTimeWrapper>
              <SkeletonStyle
                width="132px"
                height="40px"
                borderRadius="4px"
                external
              />
              <SkeletonStyle
                width="40px"
                height="40px"
                borderRadius="4px"
                external
              />
            </FilterAndTimeWrapper>
          </Header>

          <Divider />
        </>
      )}

      <ContentContainer>
        <Container>
          <ButtonsContainer>
            <Filters>
              <SkeletonStyle
                width="140px"
                height="40px"
                borderRadius="4px"
                external
              />
              <SkeletonStyle
                width="140px"
                height="40px"
                borderRadius="4px"
                external
              />
              <SkeletonStyle
                width="140px"
                height="40px"
                borderRadius="4px"
                external
              />
              <SkeletonStyle
                width="140px"
                height="40px"
                borderRadius="4px"
                external
              />
            </Filters>
            <SkeletonStyle
              width="98px"
              height="40px"
              borderRadius="4px"
              external
            />
          </ButtonsContainer>

          <Content>
            <ItemsContainer>
              <ItemHeader>
                <div>
                  <SkeletonStyle
                    width="167px"
                    height="16px"
                    borderRadius="12px"
                  />
                  <SkeletonStyle
                    width="167px"
                    height="16px"
                    borderRadius="12px"
                  />
                </div>
                <SkeletonStyle width="75px" height="16px" borderRadius="12px" />
              </ItemHeader>
              <Item />
              <Item />
              <Item />
              <Item />
              <Item />
              <Item />
            </ItemsContainer>

            <ItemsContainer>
              <ItemHeader>
                <div>
                  <SkeletonStyle
                    width="167px"
                    height="16px"
                    borderRadius="12px"
                  />
                  <SkeletonStyle
                    width="167px"
                    height="16px"
                    borderRadius="12px"
                  />
                </div>
                <SkeletonStyle width="75px" height="16px" borderRadius="12px" />
              </ItemHeader>
              <Item />
              <Item />
              <Item />
              <Item />
              <Item />
              <Item />
            </ItemsContainer>
          </Content>
        </Container>
      </ContentContainer>
    </>
  );
}
