import DefaultSignInBanner from "../../../assets/images/banner.png";
import EMRLogo from "../../../assets/images/EMR-logo.svg";

import RevalidaSignInBanner from "../../../assets/images/universities/revalida/banner_revalida.png";
import RevalidaBG from "../../../assets/images/universities/revalida/bg.png";
import RevalidaLogo from "../../../assets/images/universities/revalida/logo.png";
import RevalidaSignInLogo from "../../../assets/images/universities/revalida/pdf-logo.png";
import RevalidaWhiteLogo from "../../../assets/images/universities/revalida/revalida-white-logo.png";
import Logo from "../components/library/images/logo-inverse.svg";

import SaoLeopoldoMandicBg from "../../../assets/images/universities/saoLeopoldoMandic/bg.png";
import SaoLeopoldoMandicLoginLogo from "../../../assets/images/universities/saoLeopoldoMandic/login-logo.svg";
import SaoLeopoldoMandicLogo from "../../../assets/images/universities/saoLeopoldoMandic/logo.svg";
import SaoLeopoldoMandicSmallLogo from "../../../assets/images/universities/saoLeopoldoMandic/small-logo.svg";

import UnisaBG from "../../../assets/images/universities/unisa/bg.png";
import UnisaWhiteLogo from "../../../assets/images/universities/unisa/logo-branco.png";
import UnisaPdfLogo from "../../../assets/images/universities/unisa/pdf-logo.png";
import UnisaSmallLogo from "../../../assets/images/universities/unisa/small-logo.svg";

import UninoveBG from "../../../assets/images/universities/uninove/bg.png";
import UninoveLogo from "../../../assets/images/universities/uninove/logo-uninove.png";
import UninoveSmallLogo from "../../../assets/images/universities/uninove/small-logo.svg";

import fsmBG from "../../../assets/images/universities/fsm/bg.png";
import fsmLogo from "../../../assets/images/universities/fsm/logo.svg";
import fsmPdfLogo from "../../../assets/images/universities/fsm/pdf-logo.png";
import fsmSmallLogo from "../../../assets/images/universities/fsm/small-logo.svg";

import b2bTemplateBG from "../../../assets/images/universities/b2bTemplate/bg.png";
import b2bTemplateLogo from "../../../assets/images/universities/b2bTemplate/logo.svg";
import b2bTemplateSmallLogo from "../../../assets/images/universities/b2bTemplate/small-logo.svg";

import { getCookie } from "cookies-next";
import bgEMR from "../components/lobby/bg-lobby.jpg";

export function getThemeByUniversity() {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const IESFromCookie = getCookie(process.env.METRICO_THEME_COOKIE, {
    domain: process.env.DOMAIN_URL,
  });

  const IES = IESFromCookie ? IESFromCookie : urlParams.get("ies");

  const universityTheme = new Map([
    [
      "default",
      {
        light100: "#D3FCE9",
        light200: "#27EA8F",
        brand: "#35BD78",
        stepper: "#35BD78",
        pure000: "#D3FCE9",
        brandDark: "#009A5A",
        semantic: "green",
        logo: Logo,
        bg: bgEMR,
        smallLogo: Logo,
        signInBanner: DefaultSignInBanner,
        signInLogo: EMRLogo,
      },
    ],
    [
      "saoLeopoldoMandic",
      {
        brand: "#252f67",
        brandDark: "#222b5e",
        semantic: "blue",
        logo: SaoLeopoldoMandicLogo,
        bg: SaoLeopoldoMandicBg,
        smallLogo: SaoLeopoldoMandicSmallLogo,
        signInBanner: SaoLeopoldoMandicBg,
        signInLogo: SaoLeopoldoMandicLoginLogo,
      },
    ],
    [
      "revalida",
      {
        light100: "#B1E7E1",
        light200: "#47EAD8",
        brand: "#04A598",
        brandDark: "#087d75",
        stepper: "#3EC4B5",
        pure000: "#D8F3F0",
        semantic: "teal",
        logo: RevalidaLogo,
        bg: RevalidaBG,
        smallLogo: RevalidaWhiteLogo,
        signInBanner: RevalidaSignInBanner,
        signInLogo: RevalidaSignInLogo,
      },
    ],
    [
      "unisa",
      {
        brand: "#3490dc",
        brandDark: "#004F90",
        semantic: "blue",
        logo: UnisaWhiteLogo,
        bg: UnisaBG,
        smallLogo: UnisaSmallLogo,
        signInBanner: UnisaBG,
        signInLogo: UnisaPdfLogo,
      },
    ],
    [
      "uninove",
      {
        brand: "#004a8f",
        brandDark: "#00284D",
        semantic: "blue",
        logo: UninoveLogo,
        bg: UninoveBG,
        smallLogo: UninoveSmallLogo,
        signInBanner: UninoveBG,
        signInLogo: UninoveSmallLogo,
      },
    ],
    [
      "fsm",
      {
        brand: "#0085BD",
        brandDark: "#00428B",
        semantic: "blue",
        logo: fsmLogo,
        bg: fsmBG,
        smallLogo: fsmSmallLogo,
        signInBanner: fsmBG,
        signInLogo: fsmPdfLogo,
      },
    ],
    [
      "b2bTemplate",
      {
        brand: "#035CE2",
        brandDark: "#0A449C",
        semantic: "blue",
        logo: b2bTemplateLogo,
        bg: b2bTemplateBG,
        smallLogo: b2bTemplateSmallLogo,
        signInBanner: b2bTemplateBG,
        signInLogo: b2bTemplateLogo,
      },
    ],
  ]);

  return { IES, universityTheme };
}
