import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchNotifications } from "../../processes/notificationProcesses";
import { fetchNotificationsUser } from "../../processes/notificationsUserProcesses";

const FETCH_INTERVAL = 45000;

export const usePoolingNotifications = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    fetchNotifications(dispatch);
    fetchNotificationsUser(dispatch);
  }, [dispatch]);

  useEffect(() => {
    const fetchingNotifications = setInterval(() => {
      fetchNotifications(dispatch);
      fetchNotificationsUser(dispatch);
    }, FETCH_INTERVAL);

    return () => clearInterval(fetchingNotifications);
  }, [dispatch]);
};
