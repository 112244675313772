import styled from "styled-components";
import { colors } from "../../../components/library/theme";

export const Container = styled.section`
  border-radius: 8px;
  background-color: #fff;
  padding: 8px 16px;

  @media(max-width: 768px){
    padding: 0;
  }
`
export const Title = styled.p`
  margin: 0;
  font-weight: 600;
  line-height: 24px;

  @media(max-width: 768px){
    font-weight: 600;
    font-size: 16px;
  }
`
export const TitleWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`
export const LessonsCount = styled.p`
  margin: 0 0 0 auto;
  white-space: nowrap;
`
export const ProgressWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
  margin-top: 8px;
`
export const ProgressContainer = styled.div`
  background-color: ${colors.interface.grey200};
  border-radius: 4px;
  height: 4px;
  width: 100%;
`
export const ProgressBar = styled.div`
  background-color: ${colors.brand.pure};
  border-radius: 4px;
  height: 4px;
  width: ${props => props.value}%;
`
export const Skeleton = styled.div`
  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
  height: 69px;

  @keyframes shine {
  to {
    background-position-x: -200%;
  }
}
`