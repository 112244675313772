import * as Dialog from "@radix-ui/react-dialog";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Container } from "semantic-ui-react";
import styled from "styled-components";
import { getMockTemplateById } from "../../selectors/mockTemplateSelectors";
import { getSearchParams } from "../../utils/getSearchParams";
import { getThemeByUniversity } from "../../utils/getThemeByUniversity";
import Button from "../library/Button";
import Link from "../library/Link";
import Page from "../library/Page";
import { ModalReview } from "../modalReview";
import getUrlParams from "../modalReview/getUrlParams";
import QuestionContainer from "../questions/QuestionContainer";
import { isQuestionsCommentsPage } from "./../library/utils.js";
import MockModalPreferences from "./MockModalPreferences.js";
import MockReportPanelContainer from "./MockReportPanelContainer";
import { MockRevisionReport } from "./MockRevisionReport";
import MockTimer from "./MockTimer";
import TextHighlighter from "./TextHighlighter";
const buttonWrapperStyle = {
  display: "flex",
  justifyContent: "center",
  flexWrap: "wrap",
  gap: "10px"
};

const Wrapper = styled.div`
  @media screen and (min-width: 990px) {
    max-width: 50vw;
    margin: 0 auto;
  }
`;

function Mock(props) {
  const {
    mock,
    dispatch,
    finishMockHandler,
    isFinished,
    isExam,
    lessonMock = false,
    isPreExam,
    isFrialUser
  } = props;
  const history = useHistory();
  const searchParams = getSearchParams();

  const backlinkClickHandler = useCallback(() => {
    const isMedtrackExam = searchParams.get("tab") === "medtrack";
    const isTheLastExam = searchParams.get("isTheLastExam") === "true";
    const isMedtrackOrigin = searchParams.get("isMedtrackOrigin") === "true";

    if (isMedtrackExam && isTheLastExam && isMedtrackOrigin) {
      history.push("/medtrack");
    } else if (isMedtrackOrigin) {
      history.push("/medtrack");
    } else {
      history.goBack();
    }
  }, [history]);
  const [modalOpen, setModalOpen] = useState(false);
  const [mockRevisionCard, setMockRevisionCard] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const { IES } = getThemeByUniversity();

  useEffect(() => {
    if (isFrialUser) return;

    if (isFinished && !isPreExam) {
      let findReviewTypeBody;

      if (lessonMock) {
        findReviewTypeBody = {
          classroom_id: getUrlParams().classroomId
        };
      } else {
        findReviewTypeBody = {
          mock_id: getUrlParams().mockId,
          user_id: mock.userId
        };
      }

      fetch(`${process.env.METRICOAPI_URL}revisions/findReviewType`, {
        method: "POST",
        body: JSON.stringify(findReviewTypeBody),
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then((response) => response.json())
        .then((json) => {
          if (
            json.review_type === "EXTENSIVE" ||
            json.review_type === "REVIEW"
          ) {
            const values = {
              userId: mock.userId,
              lesson_id: lessonMock ? getUrlParams().lessonId : json.lesson_id,
              review_type: json.review_type
            };
            fetch(
              `${process.env.METRICOAPI_URL}revisions/userQuestionsDetails`,
              {
                method: "POST",
                body: JSON.stringify(values),
                headers: {
                  "Content-Type": "application/json"
                }
              }
            )
              .then((response) => response.json())
              .then((json) => {
                setMockRevisionCard(true);
                setModalData(json);
                if (json.user.hasRevision) {
                  setButtonDisabled(true);
                  setModalOpen(false);
                } else {
                  setModalOpen(true);
                }
              })
              .catch((err) => {
                setMockRevisionCard(false);
              });
          }
        })
        .catch((err) => {});
    }
  }, [isFinished]);
  const Component = lessonMock ? Wrapper : Page;

  return (
    <Component
      title={!!mock?.title ? mock?.title : "Simulado"}
      backlinkClickHandler={backlinkClickHandler}
      action={
        (!isExam || isFinished) &&
        !isPreExam && (
          <div style={buttonWrapperStyle}>
            <TextHighlighter />
            <MockModalPreferences {...props} />
            {mock && !mock.preExam && (
              <Link
                component={Button}
                icon="file pdf outline"
                content={"Exportar para PDF"}
                href={`/mocks/${mock?.id}/export`}
                target="_blank"
                primary
              />
            )}
          </div>
        )
      }
    >
      <Container fluid={isQuestionsCommentsPage()}>
        {mock?.endsAt && !isFinished && (
          <MockTimer
            endsAt={mock?.endsAt}
            currentTime={mock?.currentTime}
            onEnd={finishMockHandler}
          />
        )}
        {!isPreExam &&
          isFinished &&
          mockRevisionCard &&
          !IES &&
          !buttonDisabled && (
            <MockRevisionReport
              title={modalData?.module?.name.split(" - ")[0]}
              impact={modalData?.module?.relevance}
              buttonDisabled={buttonDisabled}
              setModalOpen={setModalOpen}
            />
          )}
        {isFinished && <MockReportPanelContainer />}
        <QuestionContainer
          isFinished={isFinished}
          isExam={isExam}
          mock={mock}
        />
      </Container>
      {modalOpen && (
        <Dialog.Root open={modalOpen} onOpenChange={setModalOpen}>
          <ModalReview
            onClose={() => setModalOpen(false)}
            data={modalData}
            reviewType={modalData?.user?.review_type}
            setButtonDisabled={setButtonDisabled}
            dispatch={dispatch}
          />
        </Dialog.Root>
      )}
    </Component>
  );
}

function mapStateToProps(state, ownProps) {
  const mockTemplate =
    ownProps.mock && getMockTemplateById(state, ownProps.mock.mockTemplateId);
  const isPreExam = mockTemplate ? mockTemplate.preExam : false;
  const isFrialUser = state?.isFrialUser;

  return {
    isPreExam,
    isFrialUser,
    ...ownProps
  };
}

export default connect(mapStateToProps)(Mock);
