import styled, { css } from "styled-components";

export const ButtonContainer = styled.div(
  ({ isUserScrolling, isFloating }) => css`
    width: ${isUserScrolling ? "48px" : 0};
    position: ${isFloating ? "fixed" : "relative"};
    transition: 300ms;
    bottom: ${isFloating ? `54px` : `0px`};
    left: ${isFloating ? `50%` : `0%`};
    ${isFloating ? `transform: translateX(calc(-50% + 125px))` : ``};

    box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06),
      0px 4px 8px -2px rgba(16, 24, 40, 0.1);

    & > button {
      width: ${isUserScrolling ? "48px" : 0};
      height: ${isUserScrolling ? "48px" : 0};
      padding: 0;
    }

    @media (max-width: 992px) {
      ${isFloating ? `transform: translateX(calc(-50%))` : ``};
    }
  `
);
