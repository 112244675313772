import styled, { createGlobalStyle } from "styled-components";
import { uiColors } from "../library/theme";

export const GlobalStyles = createGlobalStyle`
  @media(max-width: 992px) {
    #platform-page {
      padding: 166px 0px 0px !important;
    }
  }
`;
export const Container = styled.div`
  display: flex;
  height: 70px;
  background-color: ${uiColors.backgroundDark};
  align-items: center;
  padding: 0 14px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 5px;
  position: fixed;
  z-index: 20;
  width: 100%;

  @media (max-width: 992px) {
    height: ${(props) => (props.isFrialUser ? "calc(70px + 96px)" : "70px")};
    align-items: flex-start;
  }
`;
export const HamburgerMenu = styled.div`
  display: none;
  height: 70px;
  align-items: center;
  justify-content: center;

  @media (max-width: 992px) {
    display: flex;
  }
`;
export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 65px;

  @media (max-width: 992px) {
    flex-grow: 1;
    height: 70px;
    gap: 0;
    align-items: center;
    justify-content: center;
  }
`;
export const RightWrapper = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
  gap: 32px;

  @media (max-width: 992px) {
    height: 70px;
  }
`;
export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  span {
    text-align: right;
    font-size: 12px;
    color: #fff;
  }

  span:first-of-type {
    font-weight: 600;
    line-height: 16px;
  }

  span:last-of-type {
    line-height: 18px;
  }

  @media (max-width: 992px) {
    display: none;
  }
`;
export const AlertTooltipContainer = styled.div`
  position: relative;
  z-index: 30;
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    display: none;
    min-width: unset;
    width: 232px;
    top: 137px;
    left: -108px;
    padding: 16px;

    b {
      font-size: 14px;
      font-weight: 600;
    }

    :after {
      top: -9px;
      bottom: unset;
      left: 48%;
      transform: rotate(45deg) translateX(50%);
      border-radius: 4px 0 0 0;
      border-width: 1px 0 0 1px;
    }

    @media (max-width: 700px) {
      top: 32px;
      bottom: initial;
      left: -5px;
      border-width: initial;

      :after {
        left: 84%;
      }
    }

    @media (max-width: 435px) {
      left: -51px;
    }
  }

  :hover {
    & > div {
      display: initial;
    }
  }
`;
