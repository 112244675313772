import React from "react";
import { Field } from "redux-form";
import { Select } from "semantic-ui-react";
import buildFormField from "./buildFormField";
import memoize from "fast-memoize";
import { useCallback } from "react";

const FormField = buildFormField(Select, (input, meta, rest) => ({
  ...input,
  value: rest.multiple ? [...input.value] : input.value,
  invalid: meta.touched && meta.invalid,
  onChange: useCallback((_, data) => input.onChange(data.value)),
  onBlur: null,
  ...rest,
}));

const buildValidator = memoize((required, other) => {
  return [(value) => (required && !value ? "required" : undefined)].concat(
    other || []
  );
});

export default function DropDownField(props) {
  return (
    <Field
      {...props}
      noResultsMessage="Nenhum resultado encontrado"
      component={FormField}
      validate={buildValidator(props.required, props.validate)}
    />
  );
}
