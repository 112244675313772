import React from "react";
import { Loader } from "semantic-ui-react";

export default function Spinner({ text, ...rest }) {
  return (
    <Loader active {...rest}>
      {text}
    </Loader>
  );
}
