import { createSelector } from "reselect";
import orderByDate from "../utils/orderByDate";

const getLessonsUsers = (state) => state.entities.lessonsUsers;
const getCurrentClassroomId = (state) => state.currentClassroomId;
const getProps = (_, props) => props;

export const getAllLessonsUsers = createSelector(
  getLessonsUsers,
  (lessonsUsers) => Object.values(lessonsUsers)
);

export const getAllLessonsUsersByClassroom = createSelector(
  getAllLessonsUsers,
  getCurrentClassroomId,
  (lessonsUsers, classroomId) =>
    lessonsUsers.filter((lessonsUser) => lessonsUser.classroomId == classroomId)
);

export const getLastUpdatedLessonsUserByClassroom = createSelector(
  getAllLessonsUsersByClassroom,
  (lessonsUsers) =>
    lessonsUsers
      .filter((lessonsUser) => lessonsUser.progress < 1)
      .sort(orderByDate("updatedAt"))[0]
);

export const getLessonsUser = createSelector(
  getAllLessonsUsers,
  getProps,
  (lessonsUsers, { lessonId }) => {
    return lessonsUsers.filter(
      (lessonsUser) => lessonsUser.lessonId === lessonId
    )[0];
  }
);

export const makeIsWatched = createSelector(
  getLessonsUser,
  (lessonsUser) => lessonsUser?.watched
);
