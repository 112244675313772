import * as Dialog from "@radix-ui/react-dialog";
import React from "react";
import { EMRButton } from "../../../componentsV2/Button/";
import {
  ButtonContainer,
  DialogClose,
  DialogContent,
  DialogOverlay,
  DialogTitle,
  Header,
} from "./styles";

export function DeleteModal({
  deleteMockHandler,
  isOpen,
  handleModal,
  mockTitle,
}) {
  return (
    <Dialog.Root open={isOpen} onOpenChange={handleModal}>
      <Dialog.Portal>
        <DialogOverlay>
          <DialogContent>
            <Header>
              <DialogTitle>
                <h2>
                  Você tem certeza que deseja excluir o simulado {mockTitle}?
                </h2>
              </DialogTitle>
            </Header>

            <ButtonContainer>
              <DialogClose asChild>
                <EMRButton mode={"cancel"} fontSize={"lg"}>
                  Cancelar
                </EMRButton>
              </DialogClose>

              <EMRButton
                mode={"standard"}
                fontSize={"lg"}
                onClick={() => deleteMockHandler()}
              >
                Confirmar
              </EMRButton>
            </ButtonContainer>
          </DialogContent>
        </DialogOverlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
