import styled from "styled-components";

export const WarningContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
  align-items: start;
  padding: 16px 0 16px 16px;
  background-color: #f7fafc;
  border-radius: 4px;
  border: 1px solid #edf0f2;
  span {
    font-weight: 400;
    line-height: 21px;
    font-size: 14px;
  }
`;
