import React from "react";
import { Container } from "./styles";
import { IoLayers } from 'react-icons/io5'
import { colors } from "../../../../../components/library/theme";

export function LevelGroupName({ levelGroupName }) {
    return (
        <Container>
            <IoLayers color={colors.medtrack.grey300} />
            <p>{levelGroupName}</p>
        </Container>
    )
}   