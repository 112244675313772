import React from "react";
import Spinner from "../library/Spinner";
import Page from "../library/Page";
import ClassroomContentsContainer from "../classroomContents/ClassroomContentsContainer";
import FilterFormContainer from "./FilterFormContainer";
import LessonListItem from "../lessons/LessonListItem";
import BannersContainer from "../banner/BannersContainer";
import { css } from "glamor";
import { spacing } from "../library/theme";
import SurveyModalContainer from "../surveys/SurveyModalContainer";
import LessonProgressContainer from "../lessons/LessonProgressContainer";

const bannerClass = css({ marginBottom: spacing.m });

export default function Lessons(props) {
  const { classroom, selectedLevelId, lessons, expiredUser,isFrialUser } = props;

  return classroom ? (
    <Page title={classroom.name} backlink="/" action={<FilterFormContainer />}>
      {!expiredUser && <>
        <SurveyModalContainer classroomId={classroom?.id} />
        <BannersContainer bannerType="lessons" className={bannerClass} />
        <LessonProgressContainer />
        {!!selectedLevelId ? (
          <>
            {lessons.map((lesson) => (
              <LessonListItem
                key={`lesson${lesson.id}`}
                lesson={lesson}
                currentClassroomId={classroom.id}
                isFrialUser={isFrialUser}
              />
            ))}
          </>
        ) : (
          <ClassroomContentsContainer />
        )}
      </>}
    </Page>
  ) : (
    <Spinner text="Carregando informações do curso..." />
  );
}
