const initialState = [];

export default function purchasedLevelsReducer(state = initialState, action) {
  switch (action.type) {
    case "LEVELS_PURCHASED_FETCHED":
      return action.level_ids;
    default:
      return state;
  }
}
