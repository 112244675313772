import * as Dialog from "@radix-ui/react-dialog";
import React from "react";
import { FaArrowRight } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { trackClickEvents } from "../../../../services/mixpanel/events/trackClickEvents";
import { EMRButton } from "../../../componentsV2/Button";
import {
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogOverlay,
  DialogTitle,
  YoutubeVideo,
} from "./syles";

export function SidebarModal({
  variant,
  firstClassroom,
  guruUrl,
  completeGuruUrl,
}) {
  const modalContent = {
    metricas: {
      title: "Conheça o EMR Métricas",
      subtitle: "Olá! Seja bem-vindo(a) ao Métricas! ",
      description:
        "Nossa ferramenta fornece uma visão clara do seu progresso em relação às metas de estudo! Faça sua matrícula e tenha acesso a esse recurso exclusivo que mostra seu desempenho em tempo real.",
      videoUrl: process.env.METRICO_YOUTUBE_URL,
    },
    medtest: {
      title: "Conheça o MedTest",
      subtitle: "Olá! Seja bem-vindo(a) ao Medtest! ",
      description:
        "Nossa ferramenta permite que você teste sua performance diante dos seus concorrentes! Faça a sua matrícula e tenha acesso a esse recurso exclusivo para uma experiência de prova com todo conforto da realidade digital.",
      videoUrl: process.env.MEDTEST_YOUTUBE_URL,
    },
    frial: {
      title: "Aprenda como usar a plataforma",
      subtitle:
        "Assista ao vídeo abaixo para entender o funcionamento da plataforma e qual a melhor maneira de utilizá-la.",
      videoUrl: process.env.TRIAL_YOUTUBE_URL,
    },
  };

  function handleRedirect() {
    if (guruUrl) {
      trackClickEvents({
        eventName: "Clique em Matricule-se Já",
        content: {
          UrlGuru: completeGuruUrl,
          Local: `Modal do ${variant.toString()}`,
          Error: false,
        },
      });
      window.open(completeGuruUrl, "_self");
    } else {
      trackClickEvents({
        eventName: "Clique em Matricule-se Já",
        content: {
          Local: `Modal do ${variant.toString()}`,
          MessageError: `O Curso ${firstClassroom.name} com o classroom id ${firstClassroom.id} não possui o link do guru`,
          Error: true,
        },
      });
      throw new Error(
        `O Curso ${firstClassroom.name} com o classroom id ${firstClassroom.id} não possui o link do guru`
      );
    }
  }

  return (
    <Dialog.Portal>
      <DialogOverlay>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{modalContent[variant]?.title ?? ""}</DialogTitle>
            <DialogClose asChild>
              <IoClose size={30} />
            </DialogClose>
          </DialogHeader>

          <YoutubeVideo
            src={modalContent[variant]?.videoUrl ?? ""}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          />
          <DialogDescription>
            {modalContent[variant]?.subtitle ?? ""}
          </DialogDescription>

          <DialogDescription>
            {modalContent[variant]?.description ?? ""}
          </DialogDescription>

          <DialogClose asChild>
            <EMRButton
              onClick={handleRedirect}
              mode={"standard"}
              fontSize={"lg"}
              fullSized
            >
              Faça sua matrícula
              <FaArrowRight fill="#fff" />
            </EMRButton>
          </DialogClose>
        </DialogContent>
      </DialogOverlay>
    </Dialog.Portal>
  );
}
