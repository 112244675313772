import React, { useEffect, useState } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import { IoClose } from "react-icons/io5";
import Spinner from "../../library/Spinner";
import {
  fetchQuestionByAnnotationId,
  fetchQuestionById,
} from "../../../processes/questionProccess";
import { Alternative } from "../Alternative/index";
import Video from "../../library/Video";
import {
  DialogOverlay,
  DialogContent,
  QuestionTitle,
  CloseButton,
  QuestionDescription,
  AlternativesContainer,
  DeletedMessage,
  HeaderContainer,
  SpecialtyAndSubjectContainer,
  QuestionTitleContainer,
  QuestionNumber,
  Divider,
} from "./styles";
import {
  extraInfoStyle,
  questionsComments,
  videoWrapper,
} from "../../../components/questions/QuestionStyles";
import { getFormattedDescription } from "./getFormattedDescription";
import { getFormattedTitle } from "./getFormattedTitle";
import { QuestionAlternativesList } from "../../../components/questions/QuestionAnswersAlternatives";
import { QuestionDifficultyLevel } from "../../../components/questions/QuestionDifficultyLevel";
import { DifficultyWrapper } from "../../../components/questions/QuestionDifficultyLevel/styles";

export function QuestionModal({ annotationId, selectedQuestion }) {
  const [isLoading, setIsLoading] = useState(true);
  const [questionData, setQuestionData] = useState({});
  const { questionId, questionSpecialty, questionSubject, questionIndex } =
    selectedQuestion;

  useEffect(() => {
    const loadData = async () => {
      try {
        let question;
        if (questionId) {
          question = await fetchQuestionById(questionId);
        } else {
          const id = { id: annotationId };
          question = await fetchQuestionByAnnotationId(id);
        }
        setQuestionData(question || {});
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, [annotationId, questionId]);

  const {
    question,
    alternatives = [],
    cancelled,
    difficultyLevel,
  } = questionData;

  return (
    <Dialog.Portal>
      <DialogOverlay>
        <DialogContent>
          <HeaderContainer>
            {questionSpecialty && (
              <SpecialtyAndSubjectContainer>
                <p>{String(questionSpecialty).toLowerCase()}</p>
                <span>{String(questionSubject).toLowerCase()}</span>
              </SpecialtyAndSubjectContainer>
            )}
            <Dialog.Close asChild>
              <CloseButton>
                <IoClose size={30} />
              </CloseButton>
            </Dialog.Close>
          </HeaderContainer>

          {isLoading ? (
            <Spinner text="Carregando questão..." />
          ) : (
            <>
              {cancelled ? (
                <DeletedMessage>
                  O simulado com esta questão foi removido, por isso sua
                  resposta não está disponível.
                </DeletedMessage>
              ) : (
                <>
                  <QuestionTitleContainer>
                    <QuestionNumber>{questionIndex + 1}</QuestionNumber>
                    <QuestionTitle>
                      {getFormattedTitle(question?.title, question?.code)}
                    </QuestionTitle>
                  </QuestionTitleContainer>
                  <Divider />
                  <QuestionDescription>
                    {getFormattedDescription(
                      question?.description || question?.title
                    )}
                  </QuestionDescription>

                  <AlternativesContainer>
                    {alternatives.length > 0 ? (
                      alternatives.map((alternative) => (
                        <Alternative
                          key={alternative.id}
                          alternative={alternative}
                          questionData={questionData}
                        />
                      ))
                    ) : (
                      <p>Não há alternativas disponíveis</p>
                    )}
                  </AlternativesContainer>

                  <DifficultyWrapper>
                    <QuestionDifficultyLevel
                      difficultyLevel={difficultyLevel}
                    />
                  </DifficultyWrapper>

                  {question?.video_url && (
                    <div className={videoWrapper}>
                      <h3 className={questionsComments}>
                        Comentário da Questão
                      </h3>
                      <Video isQuestion url={question.video_url} />
                      {question?.hint && (
                        <EMRTip text={question.hint} title="Dica EMR" />
                      )}
                      {question?.extra_info && (
                        <>
                          {question?.is_comment_template ? (
                            <QuestionAlternativesList
                              text={question.extra_info}
                            />
                          ) : (
                            <p style={extraInfoStyle}>{question.extra_info}</p>
                          )}
                        </>
                      )}
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </DialogContent>
      </DialogOverlay>
    </Dialog.Portal>
  );
}
