import { logout } from "./logout";

export default function UnauthorizedMiddleware(request, ignore) {
  if (ignore) return;
  request.on("error", function error(err) {
    if (err?.response?.unauthorized) {
      logout();
    }
  });
}
