import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { fetchMocksIntelligent } from "../../processes/mockProccess";
import { makeMocksIntelligent } from "../../selectors/mockSelectors";
import MockTemplatesIntelligent from "./MockTemplatesIntelligent";

const defaultFilters = {
  myMocks: true,
  s: ["id desc"],
};

function MockTemplatesIntelligentContainer(props) {
  const { dispatch } = props;

  useEffect(() => {
    fetchMocksIntelligent(defaultFilters, dispatch);
  }, [dispatch, JSON.stringify(defaultFilters)]);

  const fetchHandler = useCallback(
    (_, { activePage = 1 }) => {
      makeMocksIntelligent(defaultFilters, dispatch, { page: activePage });
    },
    [dispatch]
  );

  return <MockTemplatesIntelligent fetchHandler={fetchHandler} {...props} />;
}

function mapStateToProps(state) {
  return {
    list: makeMocksIntelligent(state),
  };
}

export default connect(mapStateToProps)(MockTemplatesIntelligentContainer);
