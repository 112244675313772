import { setCookie } from "cookies-next";
import React, { useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import { apiPost } from "../../../../../../processes/helpers/api";
import { setSession } from "../../../../../../processes/sessionProcesses";
import { EMRButton } from "../../../../../Button";
import { AuthGirlIllustration } from "../../../../assets/AuthGirl";
import { ContentContainter, Description } from "./styles";
export default function SuccessStep({ credentials }) {
  const [isLoading, setIsLoading] = useState(false);

  function saveTheme() {
    setCookie(process.env.METRICO_THEME_COOKIE, IES, {
      domain: process.env.DOMAIN_URL,
    });
  }

  async function handleSignIn() {
    setIsLoading(true);
    await apiPost("/api/v1/auth/sign_in", true, true)
      .send(credentials)
      .then((res) => {
        saveTheme();
        setSession(res);
        window.location = "/";
      })
      .catch((e) => console.log(e))
      .finally(() => setIsLoading(false));
  }
  return (
    <ContentContainter>
      <AuthGirlIllustration />
      <Description>
        <h1>Dispositivo cadastrado com sucesso!</h1>
        <h2>
          Você já pode seguir usando nossa plataforma normalmente. Lembre-se de
          que você só pode usar seus dispositivos cadastrados. Em caso de
          dúvidas, entre em contato com nosso suporte. Bons estudos.
        </h2>
      </Description>
      <EMRButton fullSized onClick={handleSignIn} loading={isLoading}>
        Continuar Estudos
        <FaArrowRight />
      </EMRButton>
    </ContentContainter>
  );
}
