import React, { useState, useRef, useEffect } from "react";
import { IoChevronDown } from "react-icons/io5";
import { colors } from "../../../../../../components/library/theme";
import { AccordionContent, AccordionTitle, Container } from "./styles";

const DEBOUNCE_DELAY = 50;

export function AccordionsContainer({ title, children }) {
  const [isOpen, setIsOpen] = useState(false);
  const [maxHeight, setMaxHeight] = useState(0);
  const contentRef = useRef(null);
  const debounceTimer = useRef(null);

  function handleToggleAccordion() {
    setIsOpen((prev) => !prev);
  }

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current);
      }

      debounceTimer.current = setTimeout(() => {
        setMaxHeight(contentRef?.current?.scrollHeight);
      }, DEBOUNCE_DELAY);
    });

    const handleAnimationEnd = () => {
      setMaxHeight(contentRef?.current?.scrollHeight);
    };

    if (contentRef.current) {
      contentRef.current.addEventListener("animationend", handleAnimationEnd);
      resizeObserver.observe(contentRef.current);
    }

    return () => {
      resizeObserver.disconnect();
      if (contentRef.current) {
        contentRef.current.removeEventListener(
          "animationend",
          handleAnimationEnd
        );
      }
    };
  }, [contentRef, setMaxHeight]);

  return (
    <Container data-open={isOpen}>
      <AccordionTitle isOpen={isOpen} onClick={handleToggleAccordion}>
        <span>{title}</span>
        <IoChevronDown size={20} stroke={colors.medtrack.grey400} />
      </AccordionTitle>

      <AccordionContent ref={contentRef} maxHeight={isOpen ? 10000 : 0}>
        {children}
      </AccordionContent>
    </Container>
  );
}
