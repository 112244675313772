import styled from 'styled-components'

export const Container = styled.div`
  input {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    appearance: none;
    width: 24px;
    height: 24px;
    border: 2px solid #606a71;
    border-radius: 4px;
    transition: border 0.1s ease-in-out;
    position: relative;
  
    :focus {
      outline: none;
      border-color: #35bd78;
    }

    &::before {
      content: '';
      width: 6px;
      height: 12px;
      border: 2px solid #ffffff;
      border-top: 0;
      border-left: 0;
      transform: rotate(45deg);
      position: absolute;
      top: 3px;
      opacity: 0;
      transition: 0.1s ease-in-out;
    }

    &:checked {
      border-color: #35bd78;
      background: #35bd78;
      &::before {
        opacity: 1;
      }
    }
  }
`
