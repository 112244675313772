import * as React from "react";

export function Check({ success }) {
  return success ? (
    <svg xmlns="http://www.w3.org/2000/svg" width={88} height={88} fill="none">
      <circle cx={44} cy={44} r={42} fill="#D3FCE9" stroke="#35BD78" />
      <path
        stroke="#35BD78"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M57.75 33 38.5 55l-8.25-8.25"
      />
    </svg>
  ) : (
    <svg
      width="88"
      height="88"
      viewBox="0 0 88 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="44" cy="44" r="43.5" fill="#FFE9E9" stroke="#E64444" />
      <path
        d="M53.625 53.625L34.375 34.375M53.625 34.375L34.375 53.625"
        stroke="#E64444"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
