import React from "react"

export function ClipboardCheck({ fill }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M11.0483 1.6444H11.394C11.9084 1.6444 12.4017 1.85043 12.7654 2.21717C13.1291 2.58391 13.3334 3.08131 13.3334 3.59996V13.3777C13.3334 13.8964 13.1291 14.3938 12.7654 14.7605C12.4017 15.1273 11.9084 15.3333 11.394 15.3333H4.60614C4.09178 15.3333 3.59849 15.1273 3.23478 14.7605C2.87108 14.3938 2.66675 13.8964 2.66675 13.3777V3.59996C2.66675 3.08131 2.87108 2.58391 3.23478 2.21717C3.59849 1.85043 4.09178 1.6444 4.60614 1.6444H4.9519C5.00059 1.64435 5.04814 1.62952 5.08836 1.60183C5.12857 1.57415 5.15958 1.53489 5.17735 1.48918C5.27051 1.24714 5.43399 1.03911 5.64638 0.892333C5.85877 0.74556 6.11017 0.66689 6.36766 0.666626H9.63251C9.88999 0.66689 10.1414 0.74556 10.3538 0.892333C10.5662 1.03911 10.7297 1.24714 10.8228 1.48918C10.8406 1.53489 10.8716 1.57415 10.9118 1.60183C10.952 1.62952 10.9996 1.64435 11.0483 1.6444ZM6.06463 3.59996H9.93554C10.0641 3.59996 10.1874 3.54845 10.2784 3.45677C10.3693 3.36508 10.4204 3.24073 10.4204 3.11107C10.4204 2.98141 10.3693 2.85706 10.2784 2.76537C10.1874 2.67369 10.0641 2.62218 9.93554 2.62218H6.06463C5.93604 2.62218 5.81271 2.67369 5.72179 2.76537C5.63086 2.85706 5.57978 2.98141 5.57978 3.11107C5.57978 3.24073 5.63086 3.36508 5.72179 3.45677C5.81271 3.54845 5.93604 3.59996 6.06463 3.59996ZM10.8555 7.35099C11.1228 7.03459 11.083 6.56139 10.7666 6.29405C10.4502 6.02672 9.97703 6.06649 9.7097 6.38289L7.18264 9.37373L6.44664 8.61154C6.15891 8.31357 5.68411 8.30527 5.38614 8.593C5.08817 8.88073 5.07987 9.35553 5.3676 9.6535L6.68024 11.0129C6.82758 11.1654 7.03265 11.2485 7.24465 11.2415C7.45664 11.2344 7.65575 11.1379 7.79264 10.9759L10.8555 7.35099Z" fill={fill ?? ""} />
    </svg>
  )
}


