import styled from 'styled-components';
import { colors } from '../../../../components/library/theme';

export const Container = styled.span`
  width: fit-content;
  height: 37px;
  border-radius: 99999px;
  padding: 0px 16px;
  background-color: ${colors.interface.grey150};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  white-space: nowrap;

  > span {
    white-space: normal;
  }
  grid-area: bigAreaTag;

  @media (max-width: 740px) {
    width: 100%;
    order: 2;
  }
`;
