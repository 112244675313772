import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import QuestionRatingForm from "./QuestionRatingForm";
import { createQuestionRating } from "../../processes/ratingProcesses";
import { getQuestionRating } from "../../selectors/ratingSelectors";

function mapStateToProps(state, ownProps) {
  const { questionId } = ownProps;

  return {
    initialValues: {},
    ratingableId: questionId,
    rated: getQuestionRating(state, questionId),
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    destroyOnUnmount: false,
    onSubmit: createQuestionRating,
  })(QuestionRatingForm)
);
