import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { setUnreadCount } from "../../../../reducers/notificationsReducer";
import { selectUnreadCount } from "../../../../selectors/notificationsUserSelectors";
import * as services from "../../services";
import { NotifierDropdown } from "../NotifierDropdown";
import { NotifierIcon } from "../NotifierIcon";
import { NotifierContainer } from "./styles";

function Notifier({ unreadCount, dispatch }) {
  const [notifications, setNotifications] = useState([]);
  const [isDropdownOpened, setIsDropdownOpened] = useState(false);
  const location = useLocation();
  const isInNotificationRoute = location.pathname.includes("/notifications");

  async function getNotifications() {
    try {
      const { notifications } = await services.getNotifications({
        size: 6,
        onlyUnread: true,
      });
      setNotifications(notifications);
    } catch (err) {
      console.error(err);
    }
  }

  async function handleNotificationClick(id) {
    await services.readNotification(id);
    getUnreadNotificationsCount();
    getNotifications();
  }

  async function getUnreadNotificationsCount() {
    try {
      const count = await services.getUnreadNotificationsCount();
      dispatch(setUnreadCount(count));
    } catch (err) {
      console.error(err);
    }
  }

  function handleNotifierClick() {
    if (isInNotificationRoute) return;

    setIsDropdownOpened((prev) => !prev);
  }

  function handleDropdownClose() {
    setIsDropdownOpened(false);
  }

  useEffect(() => {
    if (isInNotificationRoute) {
      setIsDropdownOpened(false);
    }
  }, [location]);

  useEffect(() => {
    if (isDropdownOpened) {
      getNotifications();
      getUnreadNotificationsCount();
    }
  }, [isDropdownOpened]);

  return (
    <NotifierContainer>
      <NotifierIcon
        onClick={handleNotifierClick}
        isActived={isInNotificationRoute}
        hasNewNotifications={unreadCount > 0 ? unreadCount : null}
      />

      {isDropdownOpened && (
        <NotifierDropdown
          notificationsData={notifications}
          notificationsCount={unreadCount}
          onClose={handleDropdownClose}
          onNotificationClick={handleNotificationClick}
        />
      )}
    </NotifierContainer>
  );
}

const mapStateToProps = (state) => {
  return {
    unreadCount: selectUnreadCount(state),
  };
};

export default connect(mapStateToProps)(Notifier);
