import React from 'react';
import GlobalStyles from './globalStyles';
import * as S from './styles';
import {
  IoClose,
  IoAlertCircle,
  IoCheckmarkCircle,
  IoCloseCircle,
} from 'react-icons/io5';
import { NotificationToastIcon } from './icons/NotificationToastIcon';

export function ToastBody({ children, variant, onClose }) {
  const iconVariants = {
    error: <IoCloseCircle size={26} />,
    warning: <IoAlertCircle size={26} />,
    success: <IoCheckmarkCircle size={26} />,
    notification: <NotificationToastIcon />,
  };

  return (
    <>
      <GlobalStyles />
      <S.ToastContainer variant={variant}>
        <S.ToastContentWrapper variant={variant}>
          <S.CloseBtn onClick={onClose}>
            <IoClose size={16} />
          </S.CloseBtn>
          {iconVariants[variant]}
          <span>{children}</span>
        </S.ToastContentWrapper>

        <S.ProgressBar variant={variant}>
          <S.Progress variant={variant} />
        </S.ProgressBar>
      </S.ToastContainer>
    </>
  );
}
