import React from "react"
import { Container, Line } from "./styles"

function Divisor() {
  return (
    <Container>
      <Line />
      <span>Ou</span>
      <Line />
    </Container>
  )
}

export default Divisor