import React, { useEffect, useState } from "react";
import { IoArrowUp } from "react-icons/io5";
import { EMRButton } from "../../../Button";
import { ButtonContainer } from "./styles";

export function ScrollTopButton({ isFloating = true }) {
  const [isUserScrolling, setIsUserScrolling] = useState(false);
  const [isEnabled, setIsEnabled] = useState(true);
  const scrollYLimit = document.body.scrollHeight - 100;

  function handleScrollTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  useEffect(() => {
    function handleScroll() {
      if (window.scrollY > 600) {
        setIsUserScrolling(true);
      } else {
        setIsUserScrolling(false);
      }
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    function handleButtonTranslate() {
      const currentScrollY = window.innerHeight + window.scrollY;

      if (currentScrollY >= scrollYLimit) {
        setIsEnabled(false);
      } else {
        setIsEnabled(true);
      }
    }

    window.addEventListener("scroll", handleButtonTranslate);

    return () => {
      window.removeEventListener("scroll", handleButtonTranslate);
    };
  });

  return (
    <>
      {(isEnabled || !isFloating) && (
        <ButtonContainer
          isUserScrolling={isUserScrolling}
          isFloating={isFloating}
        >
          <EMRButton fullSized onClick={handleScrollTop}>
            <IoArrowUp size={24} />
          </EMRButton>
        </ButtonContainer>
      )}
    </>
  );
}
