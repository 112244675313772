import { createSelector } from "reselect";
import { getClassroomsWithMocksEnabled } from "./classroomSelectors";

const getReports = (state) => state.entities.reports;

export const getSelectedClassroomId = (state) =>
  state?.reportsUtils?.selectedClassroomId ||
  getClassroomsWithMocksEnabled(state).find((el) => true)?.id;

export const getReportByClassroomId = createSelector(
  getReports,
  getSelectedClassroomId,
  (reports, classroomId) => reports[classroomId] ?? null
);

export const getScoreChartData = createSelector(
  getReportByClassroomId,
  (report) => {
    const scoreReport = report?.mocks?.scores ?? null;
    return scoreReport && scoreReport.datasets
      ? {
          labels: scoreReport.labels,
          user: scoreReport.datasets.user,
          all: scoreReport.datasets.all,
          top: scoreReport.datasets.top,
        }
      : null;
  }
);
