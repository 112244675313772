import React from "react";

const RecalculateIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    {...props}
  >
    <rect width={32} height={32} fill="#9BA5AB" rx={16} />
    <path
      fill="#fff"
      d="M16 16.875a.875.875 0 1 0 0-1.75.875.875 0 0 0 0 1.75Z"
    />
    <path
      fill="#fff"
      d="M16 8.417c-4.181 0-7.583 3.402-7.583 7.583s3.402 7.583 7.583 7.583 7.583-3.402 7.583-7.583S20.182 8.417 16 8.417Zm3.83 4.132-1.708 4.272a2.333 2.333 0 0 1-1.3 1.3l-4.273 1.71a.292.292 0 0 1-.38-.38l1.71-4.272a2.332 2.332 0 0 1 1.3-1.3l4.273-1.71a.292.292 0 0 1 .379.38Z"
    />
  </svg>
);

export default RecalculateIcon;
