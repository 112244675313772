import React from "react";
import { css } from "glamor";
import { Form, TextAreaField } from "../library/forms";
import Button from "../library/Button";
import { spacing } from "../library/theme";

const container = {
  borderBottom: "1px solid rgba(0,0,0,0.1)",
  padding: spacing.m,
  paddingTop: 0,
};
const buttonStyle = { marginTop: spacing.xs, marginRight: 0 };

export default function SignInForm(props) {
  return (
    <Form onSubmit={props.handleSubmit} style={container}>
      <TextAreaField
        name="text"
        placeholder="Faça uma anotação..."
        maxLength={140}
        rows={5}
      />
      <Button size="small" style={buttonStyle} fluid>
        Criar
      </Button>
    </Form>
  );
}
