const getSubjects = (action) => action.entities.subjects;

export default function subjectsReducer(state = {}, action) {
  switch (action.type) {
    case "SUBJECTS_FETCHED":
      return { ...state, ...getSubjects(action) };
    default:
      return state;
  }
}
