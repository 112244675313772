import React from "react";
import { css } from "glamor";
import LobbyLayout from "../lobby/LobbyLayout";
import { createTextMask } from "redux-form-input-masks";
import Link from "../library/Link";
import Button from "../library/Button";
import { CardForm, TextField } from "../library/forms";
import { spacing } from "../library/theme";
import { Grid } from "semantic-ui-react";

const marginTopClass = css({ marginTop: spacing.m });
const signInClass = css({
  display: "flex",
  width: "100%",
  justifyContent: "center",
  marginTop: spacing.m,
  marginBottom: spacing.m,
  paddingTop: spacing.m,
});
const paragraphMargin = {
  marginRight: spacing.xxs,
};

const phoneMask = createTextMask({
  pattern: "99 99999-9999",
  guide: false,
  allowEmpty: true,
});

export default function SignUpForm(props) {
  return (
    <LobbyLayout>
      <CardForm onSubmit={props.handleSubmit} error={props.error}>
        <TextField
          name="name"
          type="text"
          icon="user"
          iconPosition="left"
          label="Nome"
          placeholder="Nome completo"
          required
          fluid
        />
        <TextField
          name="email"
          type="email"
          icon="envelope"
          iconPosition="left"
          label="E-mail"
          placeholder="Digite seu e-mail..."
          required
          fluid
        />
        <TextField
          name="phone"
          type="phone"
          icon="phone"
          iconPosition="left"
          label="Telefone"
          placeholder="Número"
          required
          fluid
          {...phoneMask}
        />
        <div className={marginTopClass}>
          <Grid columns="equal" verticalAlign="bottom" stackable>
            <Grid.Column>
              <TextField
                name="password"
                type="password"
                icon="lock"
                iconPosition="left"
                label="Senha"
                placeholder="*******"
                required
                fluid
              />
            </Grid.Column>
            <Grid.Column>
              <TextField
                name="confirmPassword"
                type="password"
                icon="lock"
                iconPosition="left"
                label="Confirme sua senha"
                placeholder="*******"
                required
                fluid
              />
            </Grid.Column>
          </Grid>
        </div>
        <div className={marginTopClass}>
          <Button
            color="primary"
            disabled={props.pristine || props.submitting}
            size="large"
            fluid
          >
            Criar conta
          </Button>
        </div>
      </CardForm>
      <div className={signInClass}>
        <p style={paragraphMargin}>
          Já possui uma conta? <Link to="/signin">Ir para o login</Link>
        </p>
      </div>
    </LobbyLayout>
  );
}
