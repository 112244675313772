import { connect } from "react-redux";
import { getStatistic } from "../../selectors/statisticsSelector";
import ProeficiencyComparisonCard from "./ProeficiencyComparisonCard";

function mapStateToProps(state, ownProps) {
  const { type, id, statitsticKey } = ownProps;

  return {
    allSuccessRate: getStatistic(state, { type, id, key: statitsticKey })
  };
}

export default connect(mapStateToProps)(ProeficiencyComparisonCard);
