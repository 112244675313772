import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth'
import { auth } from '../../../../api/firebase'


const provider = new GoogleAuthProvider()

export function authWithGoogle() {
  return signInWithPopup(auth, provider)
}

