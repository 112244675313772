const setReviewsFields = (data) => {

  const filtered = Object.keys(data.user.revision).reduce((acc, item) => {
    const objKey = data.user.revision[item]
    if (objKey === 'FUTURE' || objKey === 'NOT_SELECTED') {
      acc[item] = data.user.revision[item] === 'FUTURE' ? true : false
    }
    return acc
  }, {})
  return filtered
};

export default setReviewsFields;
