export const initialState = { days: 0, hours: 0, minutes: 0, seconds: 0 }

export function calculateCountdown(DateTimeFrialEnds, nowTimeFromAPI) {
  const diff = (DateTimeFrialEnds.getTime() - nowTimeFromAPI.getTime()) / 1000

  if (diff < 0) return initialState

  const days = Math.floor(diff / 24 / 60 / 60);
  const hoursLeft = Math.floor((diff) - (days * 86400));
  const hours = Math.floor(hoursLeft / 3600);
  const minutesLeft = Math.floor((hoursLeft) - (hours * 3600));
  const minutes = Math.floor(minutesLeft / 60);
  const seconds = Math.floor(diff % 60);
  return ({ days, hours, minutes, seconds })
}