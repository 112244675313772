import styled from "styled-components";
import { colors } from "../../components/library/theme";

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  padding-top: 60px;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  min-height: 100vh;
  @media screen and (max-width: 768px) {
    padding: 48px 24px 0px 24px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 580px;
  width: 100%;
`;

export const Title = styled.h1`
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  color: ${colors.interface.grey400};
`;

export const Illustration = styled.figure`
  display: flex;
  width: fit-content;
  align-self: center;
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Paragraph = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`;

export const ButtonContainer = styled.span`
  display: flex;
  width: 100%;
  gap: 16px;

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;
