import styled, { css } from "styled-components";

const logoVariants = {
  QUESTION: css`
    top: 0;
    right: 5px;
    width: 27%;
    height: 14%;
    border-radius: 0 0 12px 12px;
    background-color: ${(props) => props.theme};
  `,

  B2B: css`
    top: 3%;
    right: 1%;
    width: 9%;
    height: auto;
  `,
};

export const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  max-width: 1920px;
  margin: 0 auto;

  &:fullscreen button {
    bottom: 15px !important;
    height: 40px !important;
    right: 15px !important;
    width: 40px !important;
  }

  &:fullscreen .wrapper {
    max-width: unset;
    max-width: 1920px;
    padding-top: unset;
    width: 100%;
    height: unset;
    aspect-ratio: 16/9;

    @media (min-width: 2560px) and (max-height: 1080px) {
      max-width: 1920px;
    }

    @media (min-width: 2560px) and (min-height: 1440px) {
      max-width: 2560px;
    }

    @media (min-width: 3840px) {
      max-width: 3840px;
    }
  }
`;
export const Wrapper = styled.div`
  position: relative;
  height: 0;
  padding-top: 56.25%;
  max-width: 1920px;
  width: 100%;
  aspect-ratio: 16/9;
`;
export const LogoWrapper = styled.div`
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  z-index: 1;

  @media (max-width: 1250px) {
    right: 8px;
  }

  ${({ variant }) => logoVariants[variant || "B2B"]};
`;
export const Logo = styled.img`
  height: 100%;
  object-fit: contain;
  width: 100%;
`;
export const FullScreenButton = styled.button`
  bottom: 8px;
  cursor: pointer;
  height: 32px;
  min-height: unset;
  position: absolute;
  opacity: 0;
  right: 8px;
  width: 32px;
  z-index: 1;

  @media (min-width: 2250px) {
    right: 20px;
    bottom: 20px;
  }
`;
