import React from "react";
import { css } from "glamor";
import Link from "../library/Link";
import { spacing, styles, uiColors } from "../library/theme";
import { AiFillCheckCircle } from "react-icons/ai";
import { MdRadioButtonUnchecked } from "react-icons/md";

const container = css(styles.transition, styles.roundedCorners, {
  display: "flex",
  flex: 1,
  padding: `${spacing.s} ${spacing.s}`,
  cursor: "pointer",
  justifyContent: "space-between",
  "& + &": {
    borderTop: `1px solid ${uiColors.backgroundDark}20`,
  },
  ":hover": {
    backgroundColor: `${uiColors.backgroundDark}20`,
  },
});
const contentWrapper = css({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
});
const textWrapper = css({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  height: "100%",
  width: "100%",
});
const iconWrapper = {
  marginLeft: spacing.s,
  display: "flex",
  alignItems: "center",
};

export default function LevelMockListItem(props) {
  const { onClickHandler, mockTemplate, compact, isFinished } = props;

  return (
    <Link className={container} onClick={onClickHandler}>
      <div className={contentWrapper}>
        <div className={!compact ? textWrapper : undefined}>
          <p className={css(compact && styles.bodyTextSmall)}>
            {mockTemplate.name}
          </p>
        </div>
        <div style={iconWrapper}>
          {isFinished ? (
            <AiFillCheckCircle size={22} />
          ) : (
            <MdRadioButtonUnchecked size={22} />
          )}
        </div>
      </div>
    </Link>
  );
}
