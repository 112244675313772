import { mixpanel } from "..";
// @ts-check
/**
 * @param {{
 * eventType: 'newMockModal' | 'createMock'
 * mockTemplateType: string,
 * formCreateMock: object,
 * isFrialUser
 * }} createMockData
 */

export function trackCreateNewMockTemplateEvent(createMockData) {
  if (createMockData.eventType === "newMockModal") {
    mixpanel.track("Plataforma - Simulados - clique em Criar novo simulado", {
      MockTemplateType: createMockData.mockTemplateType,
      UserFrial: createMockData.isFrialUser
    });
  }

  if (createMockData.eventType === "createMock") {
    const {
      mockName,
      specialty,
      subject,
      state,
      organizers,
      examType,
      isOnlyUnansweredQuestions,
      isOnlyIncorrectQuestions,
      isExamSimulated,
      mockQuestionsType,
      mockType,
      years
    } = createMockData.formCreateMock;

    mixpanel.track("Plataforma - Novo Simulado Criado", {
      MockType: mockType,
      MockName: mockName,
      Specialty: specialty,
      Subject: subject,
      State: state,
      Organizers: organizers,
      Years: years,
      ExamType: examType,
      IsOnlyUnansweredQuestions: isOnlyUnansweredQuestions,
      IsOnlyIncorrectQuestions: isOnlyIncorrectQuestions,
      IsExamSimulated: isExamSimulated,
      MockQuestionsType: mockQuestionsType,
      UserFrial: createMockData.isFrialUser
    });
  }
}
