import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { trackClickEvents } from "../../../services/mixpanel/events/trackClickEvents";
import { getClassrooms } from "../../selectors/classroomSelectors";
import { getCurrentUser, isExpiredUser } from "../../selectors/userSelectors";
import { addDays } from "../../utils/addDays";
import { Warning } from "./Warning";
import { calculateCountdown, initialState } from "./calculateCountdown";
import { Card } from "./card";
import { getNowDateTime } from "./getNowDateTime";
import { Button, CardsWrapper, Container } from "./styles";

function Timer(props) {
  const {
    createdAt,
    expired,
    isFrialUser,
    dispatch,
    guruUrl,
    firstClassroom,
    completeGuruUrl
  } = props;

  if (!createdAt || !isFrialUser) return null;

  const dateTimeApi = useRef(new Date());
  const countdownTimer = useRef(null);
  const endTime = useRef(addDays(createdAt, parseInt(process.env.FRIAL_DAYS)));
  const [counter, setCounter] = useState(initialState);
  function addSecond() {
    const dateCopy = dateTimeApi.current;
    dateCopy.setSeconds(dateTimeApi.current.getSeconds() + 1);
    dateTimeApi.current = dateCopy;
  }

  function timer() {
    if (dateTimeApi.current) {
      addSecond();
      const counterData = calculateCountdown(
        endTime.current,
        dateTimeApi.current
      );
      setCounter(counterData);

      if (counterData === initialState) {
        clearTimeout(countdownTimer.current);
        dispatch({ type: "SET_EXPIRATE_USER" });
      }
    }
  }

  useEffect(() => {
    getNowDateTime().then((datetime) => (dateTimeApi.current = datetime));
    countdownTimer.current = setInterval(timer, 1000);
  }, []);

  function redirect() {
    if (guruUrl) {
      trackClickEvents({
        eventName: "Clique em Matricule-se Já",
        content: {
          UrlGuru: completeGuruUrl,
          Local: "Modal de tempo esgotado",
          Error: false
        }
      });
      window.open(completeGuruUrl, "_self");
    } else {
      trackClickEvents({
        eventName: "Clique em Matricule-se Já",
        content: {
          Local: "Modal de tempo esgotado",
          MessageError: `O Curso ${firstClassroom.name} com o classroom id ${firstClassroom.id} não possui o link do guru`,
          Error: true
        }
      });
      throw new Error(
        `O Curso ${firstClassroom.name} com o classroom id ${firstClassroom.id} não possui o link do guru`
      );
    }
  }

  return (
    <Container>
      {expired && <Warning onRedirect={redirect} />}
      <CardsWrapper>
        <Card label="Dias" value={counter.days} />
        <Card label="Horas" value={counter.hours} />
        <Card label="Min" value={counter.minutes} />
        <Card label="Seg" value={counter.seconds} />
      </CardsWrapper>
      <Button onClick={redirect}>MATRICULE-SE JÁ!</Button>
    </Container>
  );
}

function mapStateToProps(state) {
  const user = getCurrentUser(state);
  const createdAt = user?.createdAt;
  const expired = isExpiredUser(state);
  const isFrialUser = state.isFrialUser;
  const classrooms = getClassrooms(state);
  const firstClassroom = Object.values(classrooms)[0];
  const guruUrl = firstClassroom?.guruLink;
  const completeGuruUrl =
    guruUrl + `?name=${user?.name}&email=${user?.email}&phone=${user?.phone}`;
  return {
    user,
    createdAt,
    expired,
    isFrialUser,
    guruUrl,
    firstClassroom,
    completeGuruUrl
  };
}

export default connect(mapStateToProps)(Timer);
