import { createSelector } from "reselect";
import { getLevelGroupsByClassroom } from "./levelGroupSelectors";
import { getLevels } from "./levelSelectors";

export const getClassrooms = (state) => state.entities.classrooms;
const getClassroomList = (state) => state.classroomsList;
export const getCurrentClassroomId = (state) => state.currentClassroomId;
export const getClassroomId = (_, id) => id;
const getClassroomFetchedIds = (state) => state.classroomsList.classroomIds;

export const getAllClassrooms = createSelector(getClassrooms, (classrooms) =>
  Object.values(classrooms)
);

export const getClassroomsWithMocksEnabled = createSelector(
  getAllClassrooms,
  (classrooms) => classrooms.filter((classroom) => classroom.mocksEnabled)
);

export const getFetchedClassrooms = createSelector(
  getClassrooms,
  getClassroomFetchedIds,
  (classrooms, ids) =>
    Object.values(classrooms).filter((classroom) => ids.includes(classroom.id))
);

export const currentClassroom = createSelector(
  getClassrooms,
  getCurrentClassroomId,
  (classrooms, id) => classrooms[id]
);

export const getLevelsByClassroom = createSelector(
  getLevelGroupsByClassroom,
  getLevels,
  (levelGroups, levels) => {
    const levelGroupIds = levelGroups.map((item) => item.id);
    return Object.values(levels).filter((level) =>
      levelGroupIds.includes(level?.levelGroupId)
    );
  }
);

export const getClassroomById = createSelector(
  getClassrooms,
  getClassroomId,
  (classrooms, classroomId) => classrooms[classroomId]
);

export const getMocksEnabled = createSelector(getAllClassrooms, (classrooms) =>
  classrooms.some((classroom) => !!classroom?.mocksEnabled)
);

export const getClassroomsLoading = createSelector(
  getClassroomList,
  (classroomsList) => classroomsList.loading
);
