import styled from "styled-components";
import { colors } from '../../../frontend/components/library/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  align-items: center;
  justify-content: center;
  gap: 32px;
  width: 100vw;
  background: ${colors.interface.grey150};
  bottom: 0;
  right: 0;
  z-index: 1000;
  padding: 32px;

  p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: ${colors.interface.grey400};
  }

  button {
    font-weight: 600;
  }

  @media (min-width: 992px) {
    width: 390px;
    right: 32px;
    bottom: 32px;
  }
`;
