import React from "react";
import FieldBlock from "./FieldBlock";
import Hint from "../Hint";

export default (InputComponent) => (props) => {
  const { input, style, label, hint, meta, ...rest } = props;
  const id = input.id || input.name + input.value;
  return (
    <FieldBlock style={style}>
      <InputComponent id={id} label={label} {...input} {...rest} />
      {hint && <Hint>{hint}</Hint>}
    </FieldBlock>
  );
};
