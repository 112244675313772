import React from "react";
import { css } from "glamor";
import { spacing, styles } from "./theme";

const hintStyles = css(styles.bodyTextSmall, {
  marginTop: spacing.xxs,
  opacity: 0.5,
});

const extraSpace = css({
  marginTop: spacing.xs,
});

export default function Hint(props) {
  const { children, spaced, italic, className, ...rest } = props;
  return (
    <div className={css(hintStyles, className, spaced && extraSpace)} {...rest}>
      {children}
    </div>
  );
}
