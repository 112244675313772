import styled from "styled-components";
import { colors } from "../../../components/library/theme";

export const Container = styled.section`
  background: linear-gradient(270deg, #c0e5d4 0%, rgba(255, 255, 255, 0) 100%);
  border-radius: 8px;
  display: grid;
  padding: ${(props) =>
    props.hasVideoToKeepWatching ? "32px 32px 32px 0" : "32px 32px 0 0"};
  grid-template-columns: 1.5fr 1fr;

  & > img {
    display: block;
  }

  @media (max-width: 1300px) {
    display: flex;
    flex-direction: column;
    background: #fff;
    padding: ${(props) =>
      props.hasVideoToKeepWatching ? "8px 16px" : "8px 16px 0"};

    & > img {
      display: none;
    }
  }

  @media (max-width: 768px) {
    padding: 0;
  }
`;
export const Title = styled.h2`
  font-size: 24px;
  font-weight: 600;
  color: ${colors.interface.grey400};
  margin: ${(props) => (props.hasVideoToKeepWatching ? "12px 0" : "12px 0 0")};

  @media (max-width: 1300px) {
    text-align: center;
    margin: 0;
    width: 100%;
  }
`;
export const InfoText = styled.p`
  font-size: 16px;
  line-height: 24px;

  @media (max-width: 1300px) {
    order: 2;
    text-align: center;
    width: 100%;
  }
`;
export const Description = styled.p`
  margin: 0;
  font-size: 16px;
  font-weight: 600;

  @media (max-width: 1300px) {
    text-align: center;
  }
`;
export const InfosWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: auto 0;
  padding-bottom: ${(props) => (props.hasVideoToKeepWatching ? "0" : "32px")};

  img {
    display: none;
  }

  @media (max-width: 1300px) {
    img {
      display: block;
    }
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 12px;

  @media (max-width: 1300px) {
    flex-direction: column;
    order: 5;
    width: 100%;

    button {
      width: 100%;
    }
  }
`;
export const VideoThumb = styled.button`
  align-items: center;
  aspect-ratio: 320 / 176;
  border: none;
  background-color: #c4dcd1;
  border-radius: 4px;
  display: flex;
  width: auto;
  height: 176px;
  justify-content: center;
  background-image: ${(props) => `url(${props.image})`};
  background-size: cover;
  background-position: center;
  margin: auto 0;

  @media (max-width: 1300px) {
    flex-direction: column;
    justify-content: space-between;
    position: static;
    width: 100%;
    transform: unset;
    height: 100%;
    margin-bottom: 16px;

    svg {
      margin-top: auto;
    }
  }
`;

export const ProgressContainer = styled.div`
  background-color: ${colors.brand.pure};
  bottom: 0;
  height: 4px;
  left: 0;
  position: absolute;
  width: 100%;

  @media (max-width: 1300px) {
    margin-top: auto;
    position: static;
  }
`;
export const Progress = styled.div`
  background-color: ${colors.brand.dark000};
  height: 4px;
  width: ${(props) => `${props.value}%`};
`;
export const TagContainer = styled.div`
  background-color: #fff;
  gap: 4px;
  display: flex;
  align-items: center;
  padding: 0 8px;
  border-radius: 80px;

  @media (max-width: 1300px) {
    display: none;
  }
`;
export const Image = styled.img`
  height: 226px;
  margin: auto 42px 0 auto;
  bottom: 0;

  @media (max-width: 1300px) {
    order: 3;
    height: 192px;
    margin: auto;
  }
`;
export const VideoInfos = styled.div`
  span {
    font-size: 14px;
  }

  @media (max-width: 1300px) {
    text-align: center;
    width: 100%;
  }
`;
export const Skeleton = styled.div`
  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  height: 268px;
  animation: 1.5s shine linear infinite;

  @keyframes shine {
    to {
      background-position-x: -200%;
    }
  }
  @media (max-width: 1300px) {
    height: 522px;
  }
`;

export const MobileVideoThumb = styled.div`
  display: none;

  @media (max-width: 1300px) {
    display: block;
    width: 100%;
    max-height: 300px;

    > button {
      max-height: 300px;
    }
  }
`;

export const DesktopVideoThumb = styled.div`
  display: flex;
  justify-content: flex-end;

  @media (max-width: 1300px) {
    display: none;
  }
`;
