import React from "react";


export function SurgeryIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2146_1279)">
        <path d="M8.29488 8.98743C7.64409 8.98743 6.99329 8.98974 6.3425 8.98512C6.23219 8.9865 6.12247 8.96873 6.01826 8.93262C5.72237 8.82125 5.61506 8.50187 5.80509 8.25057C6.03648 7.94388 6.29478 7.65711 6.54439 7.36427C7.85003 5.83458 9.15605 4.30546 10.4625 2.77693C11.0155 2.12951 11.5598 1.4743 12.1236 0.836108C12.6483 0.241773 13.3196 -0.0556824 14.115 0.00865579C14.7161 0.0574143 15.1979 0.362371 15.5549 0.847071C16.199 1.72184 16.1385 2.75327 15.4053 3.61015C13.9817 5.27371 12.5597 6.93852 11.1393 8.60458C10.9053 8.87924 10.614 8.9909 10.2614 8.98859C9.60602 8.98455 8.95031 8.98743 8.29488 8.98743Z" fill="#FFA24D" />
        <path d="M8.97026 10.0013C9.00584 11.2352 8.63735 12.286 7.76557 13.1432C6.80731 14.0854 5.63877 14.6821 4.40631 15.1717C3.50243 15.5309 2.56008 15.7487 1.60067 15.8782C1.0754 15.9478 0.543196 15.9613 0.016776 16.0003L0 15.9599C0.0289242 15.9256 0.0546667 15.8895 0.0850371 15.8572C1.8766 13.9413 3.66836 12.025 5.46031 10.1083C5.49048 10.0722 5.5286 10.0436 5.5717 10.0246C5.6148 10.0056 5.66171 9.99685 5.70877 9.99895C6.74021 10.0027 7.77164 10.0013 8.80366 10.0013H8.97026Z" fill="#FFA24D" />
      </g>
      <defs>
        <clipPath id="clip0_2146_1279">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
