import React from "react";
import { Smarthphone } from "./icons/smarthphone";
import { Illustration } from "./icons/illustration";
import { Card } from "./card";
import { Video } from "./video";
import {
  Arrow,
  Infos,
  List,
  Main,
  Section,
  Title,
  TitleWrapper
} from "./styles";
import { ChildText } from "./childText";
import emrLogo from "../../../../assets/images/logo.svg";
import { Warning } from "./warning";

export function SafetyOnBoarding() {
  return (
    <Main>
      <img src={emrLogo} />

      <Section>
        <TitleWrapper>
          <Title>
            A EMR sempre priorizando você! Ainda mais segura e fácil.
            <Arrow size={20} />
          </Title>
          <Illustration />
        </TitleWrapper>
      </Section>

      <Section>
        <Infos>
          Aqui, nos comprometemos a entregar a você a EMR mais segura, confiável
          e acessível. Valorizamos profundamente seus feedbacks e buscamos
          facilitar seu acesso sem comprometer sua segurança. Investimos tempo e
          esforço contínuos para aprimorar nossa plataforma, assegurando uma
          experiência tranquila e protegida para você.
        </Infos>
      </Section>

      <Video />

      <Warning>
        Lembre-se de atualizar seu Aplicativo em seu celular, é fundamental que
        o app esteja atualizado para que você consiga acessar corretamente.
      </Warning>

      <Card
        icon={Smarthphone}
        title="Acesse de onde e quando quiser"
        text="Sim, você pode acessar sua conta EMR de qualquer dispositivo, seja tablet, celular ou computador. Basta liberar seu celular como dispositivo principal no aplicativo da EMR e, com seu leitor de QR code, você pode abrir o acesso à sua conta onde quer que esteja, garantindo segurança em cada acesso. Vamos ver como é simples?"
      >
        <List>
          <li>1. Abra o aplicativo EMR em seu celular;</li>
          <li>2. Vá para 'Minha conta';</li>
          <li>3. Selecione 'Conectar dispositivos';</li>
          <li>
            4. Aponte a câmera para o código QR na tela do dispositivo que você
            deseja acessar.
          </li>
        </List>

        <ChildText>
          Fácil, né? E se por acaso não conseguir acessar, não há problema.
          Mantemos também o acesso via código de acesso, bastando seguir os
          mesmos passos anteriores, mas inserindo o código na seção
          correspondente. Queremos garantir que você tenha acesso de forma
          descomplicada, seja por QR code ou código de acesso.
        </ChildText>
      </Card>

      <Warning>
        Para garantir sua segurança, seu QR code é gerado novamente a cada
        poucos segundos. Então, se por acaso não conseguir acessar na primeira
        tentativa, não se preocupe. Basta tentar novamente, e você terá o acesso
        garantido. Estamos aqui para tornar sua experiência o mais seguro e
        simples possível.
      </Warning>

      <Card
        text={
          <>
            Dúvidas, entre em contato com o time de atendimento através da
            plataforma ou através do WhatsApp pelo número{" "}
            <a target="_blank" href="https://wa.me/5581982763620">
              (81) 98276-3620
            </a>
            .<br></br>
          </>
        }
      />
    </Main>
  );
}
