import React from 'react';
import { ClosedAlternativeContainer, Percentual } from './styles';

export function ClosedAlternative({ alternative, questionData }) {
  function getCorrectAnswer(alternativeId) {
    const correctAnswer = questionData?.alternatives.filter(
      (item) => item.value === true
    );
    const correctAnswerId = correctAnswer[0]?.id;

    const userAnswerId = questionData?.user.answer.alternatives
      ? questionData?.user.answer.alternatives[0].id
      : correctAnswer[0].id;

    if (alternativeId === correctAnswerId) return 'rightAnswer';
    if (alternativeId === userAnswerId && alternativeId !== correctAnswerId) {
      return 'wrongAnswer';
    }
    return 'regular';
  }

  function formatPercentual(number) {
    number = number.toString().replace(".", ",")
    return `${number}%`
  }

  return (
    <ClosedAlternativeContainer
      styleType={getCorrectAnswer(alternative.id)}
      key={alternative.id}
    >
      {alternative.title}
      <Percentual>{formatPercentual(alternative.answers_percent)}</Percentual>
    </ClosedAlternativeContainer>
  );
}
