import React from 'react'
import { ButtonWrapper, Checkmark, Container, ErrorIcon, Logo, TextWrapper } from './styles'
import emrLogo from "../../../../../../assets/images/EMR-logo.svg";
import checkmark from "./assets/checkmark.svg";
import errorIcon from "./assets/error.svg";
import { EMRButton } from '../../../Button';
import { Spinner } from '../../../Spinner';

export default function RegisterLoadingStep({ step, isFrialUser }) {

  function handleOpenSupport() {
    window.open(
      "https://wa.me/558182763620",
      "_blank",
      "noopener,noreferrer"
    );
  }

  const content = {
    initial: {
      title: "Estamos processando seu pagamento, só mais um minuto :)",
      subtitle: "Em instantes você será direcionado para finalizar seu cadastro e acessar a plataforma",
      icon: <Spinner />,
      subtitleFrial: "Em instantes você será direcionado para a plataforma.",
      action: null
    },
    waiting: {
      title: "Ainda estamos processando seu pagamento...",
      subtitle: "Em instantes você será direcionado para finalizar seu cadastro e acessar a plataforma",
      subtitleFrial: "Em instantes você será direcionado para a plataforma.",
      icon: <Spinner />,
      action: null
    },
    success: {
      title: "Tudo certo, obrigado por aguardar!",
      subtitle: "Em instantes você será direcionado para finalizar seu cadastro e acessar a plataforma",
      subtitleFrial: "Seu pagamento foi confirmado. Em instantes você será direcionado para a plataforma.",
      icon: <Checkmark src={checkmark} />,
      action: null
    },
    error: {
      title: "Infelizmente ocorreu algum erro :(",
      subtitle: "Mas não se preocupe, entre em contato com nosso suporte para que possamos resolver!",
      subtitleFrial: "Mas não se preocupe, entre em contato com nosso suporte para que possamos resolver!",
      icon: <ErrorIcon src={errorIcon} />,
      action:
        <ButtonWrapper >
          <EMRButton mediumWeight fullSized onClick={handleOpenSupport}>Falar com suporte</EMRButton>
        </ButtonWrapper>
    }
  }

  return (
    <Container>
      <Logo src={emrLogo} alt="Logo da EMR" />
      <TextWrapper>
        <h2>
          {content[step].title}
        </h2>
        <small>{ content[step][isFrialUser ? "subtitleFrial" : "subtitle" ]}</small>
      </TextWrapper>
      {content[step].icon}

      {content[step].action}
    </Container>
  )
}
