import styled from "styled-components"
import { colors } from "../../../../components/library/theme"

export const Container = styled.div`
  align-items: center;
  display: flex;
  gap: 16px;
  margin: 16px 0;

  span {
    font-size: 14px;
    color: ${colors.interface.grey300};
  }

  @media (max-width: 768px) {
    padding: 0;
    margin-bottom: 64px;
  }
`
export const Line = styled.hr`
  flex-grow: 1;
  background-color: ${colors.interface.grey300};
  border: none;
  height: 1px;
`