export async function requestHasEmergencyAccess(email) {
  const hasEmergencyAccess = await fetch(
    `${process.env.USER_PROXY_URL}/auth/has-emergency-access?email=${email}`,
    {
      method: "GET",
      headers: {
        "api-token": process.env.USER_PROXY_API_TOKEN,
        "Content-Type": "application/json",
        "access-date": new Date().toISOString(),
        product: "PLATAFORMA_EMR",
      },
    }
  );

  const response = await hasEmergencyAccess.json();

  return response;
}
