import memoize from "fast-memoize";
import React, { useCallback } from "react";
import { Field } from "redux-form";
import buildFormField from "./buildFormField";
import RatingInput from "./RatingInput";

const FormField = buildFormField(RatingInput, (input, meta, rest) => {
  return {
    ...input,
    onRate: useCallback((_, { rating }) => input.onChange(rating), []),
    ...rest,
  };
});

let id = 0;
function memoizedId(x) {
  if (!x.__memoizedId) x.__memoizedId = ++id;
  return { __memoizedId: x.__memoizedId };
}

const buildValidator = memoize(
  (required) => {
    return [
      (value) => {
        if (required && !value) {
          return "required";
        }
        return undefined;
      },
    ].concat([]);
  },
  {
    serializer: (args) => {
      const argumentsWithFuncIds = Array.from(args).map((x) => {
        if (typeof x === "function") return memoizedId(x);
        return x;
      });
      return JSON.stringify(argumentsWithFuncIds);
    },
  }
);

export default function RatingField(props) {
  const { required } = props;

  return (
    <Field
      {...props}
      component={FormField}
      validate={buildValidator(required)}
    />
  );
}
