import React, { useEffect } from "react";
import { connect } from "react-redux";
import Classroom from "./Classroom";
import { currentClassroom } from "../../selectors/classroomSelectors";
import { getLessonsByLevel } from "../../selectors/lessonSelectors";
import { formValueSelector } from "redux-form";
import { fetchSurveys } from "../../processes/surveyProcesses";
import { fetchCertificate } from "../../processes/certificateProccess";
import { getSession } from "../../processes/sessionProcesses";
import { fetchSurveysUsers } from "../../processes/surveysUsersProcesses";
import { getCurrentUser, isExpiredUser } from "../../selectors/userSelectors";

function ClassroomContainer(props) {
  const { dispatch, classroom, surveyUserLoading } = props;
  const shouldFetchCertificateRequest = classroom && classroom.id && classroom.meta.hasCertificate

  useEffect(() => {
    fetchSurveysUsers(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (!surveyUserLoading && classroom?.id) {
      fetchSurveys(dispatch, classroom?.id);
    }
  }, [dispatch, classroom?.id, surveyUserLoading]);

  useEffect(() => {
    if (shouldFetchCertificateRequest) {
      const userId = getSession().currentUserId
      const classroomId = classroom.id

      fetchCertificate(dispatch, classroomId, userId)
    }

  }, [classroom])

  useEffect(() => {
    if (!classroom) return

    const userId = getSession().currentUserId
    const shouldFetchGenerateCertificateRequest = classroom.meta.progress >= 1 && classroom.meta.hasCertificate

    if (shouldFetchGenerateCertificateRequest) {

      fetch(`/certificate?user_id=${userId}&classroom_id=${classroom.id}`, {
        method: "POST",
        body: JSON.stringify({
          username: props.user.name,
          moduleName: classroom.name,
        }),
        headers: {
          "Content-Type": "application/json"
        }
      })
    }
  }, [classroom])

  return <Classroom {...props} />;
}

function mapStateToProps(state) {
  const classroomFilterFormSelector = formValueSelector("classroomFilterForm");
  const selectedLevelId = classroomFilterFormSelector(state, "selectedLevelId");
  const expiredUser = isExpiredUser(state)
  const isFrialUser = state?.isFrialUser;
  return {
    user: getCurrentUser(state),
    classroom: currentClassroom(state),
    selectedLevelId,
    lessons: !!selectedLevelId
      ? getLessonsByLevel(state, { level: { id: selectedLevelId } })
      : [],
    surveyUserLoading: state.surveyUtils.loading,
    expiredUser,
    isFrialUser
  };
}

export default connect(mapStateToProps)(ClassroomContainer);
