import React from "react";
import { Card, Progress } from "semantic-ui-react";
import { decorationColors, spacing, styles, uiColors } from "../library/theme";

const headerStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  margin: spacing.s,
  paddingBottom: spacing.s,
  borderBottom: `1px solid ${uiColors.border}`,
  color: uiColors.backgroundDarkest,
  ...styles.bodyTextLarge,
};
const descriptionStyle = {
  padding: `0 ${spacing.s} ${spacing.s}`,
};
const progressBarStyle = {
  textAlign: "right",
};
const labelWrapper = {
  display: "flex",
  gap: spacing.m,
};
const labelStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: spacing.xs,
};
const allStyle = {
  ...labelStyle,
  minWidth: 110,
};
const userLabelStyle = {
  borderRadius: "50%",
  height: 8,
  width: 8,
  backgroundColor: uiColors.background,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};
const allLabelStyle = {
  borderRadius: "50%",
  height: 8,
  width: 8,
  minWidth: 8,
  backgroundColor: decorationColors.decorationBlue,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export default function ProeficiencyComparisonCard(props) {
  const { name, userSuccessRate, allSuccessRate } = props;

  if (!userSuccessRate || !allSuccessRate) {
    return null;
  }

  return (
    <Card fluid>
      <Card.Header style={headerStyle}>
        {name}
        <div style={labelWrapper}>
          <div style={labelStyle}>
            <div style={userLabelStyle} />
            <p>Meu</p>
          </div>
          <div style={allStyle}>
            <div style={allLabelStyle} />
            <p>Demais alunos</p>
          </div>
        </div>
      </Card.Header>
      <Card.Description style={descriptionStyle}>
        <div style={progressBarStyle}>
          <p>{userSuccessRate}%</p>
          <Progress percent={userSuccessRate} color="green" size="tiny" />
        </div>
        <div style={progressBarStyle}>
          <p>{allSuccessRate}%</p>
          <Progress percent={allSuccessRate} color="blue" size="tiny" />
        </div>
      </Card.Description>
    </Card>
  );
}
