const initialState = {
    isB2B: null
  }
  
  export default function userTypeReducer(state = initialState, action) {
  
    switch (action.type) {
      case "IS_B2B":
        return (
          {
            ...state, isB2B: true
          }
        )
      default:
        return state;
    }
  }
  