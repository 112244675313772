import * as React from "react";
import { colors } from "../../../components/library/theme";

export function AuthenticationIPIllustration(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={306}
      height={280}
      fill="none"
      {...props}
    >
      <path
        fill={colors.brand.pure}
        d="M286.283 145.879c3.013-19.794.858-40.174-4.399-59.38-4.721-17.236-10.673-34.932-24.529-47.036-16.788-14.662-37.747-16.575-59.021-14.09-42.101 4.905-54.124-13.13-85.273-12.037-31.15 1.092-58.471 14.207-67.217 40.987-8.746 26.78 4.919 54.652-1.642 70.494-6.561 15.842-25.138 31.699-14.208 54.652 10.93 22.953 34.976 40.987 90.757 45.356 55.781 4.37 104.378 6.561 136.076-22.403 17.14-15.695 26.245-35.474 29.456-56.543Z"
        opacity={0.1}
      />
      <path
        fill="#fff"
        d="m258.595 210.764.051-6.011-74.812-18.181-130.27 43.59v6.26c-.006.302.096.595.286.829.228.267.524.467.857.579l73.236 30.225a7.926 7.926 0 0 0 1.862.477c1.423.203 2.87.166 4.281-.11a8.553 8.553 0 0 0 1.877-.579l121.759-55.613c.253-.089.47-.259.616-.483.2-.287.291-.635.257-.983Z"
      />
      <mask
        id="a"
        width={206}
        height={83}
        x={53}
        y={186}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: "luminance",
        }}
      >
        <path
          fill="#fff"
          d="m258.598 210.762.051-6.011-74.812-18.181-130.27 43.59v6.26c-.005.302.096.595.286.829.228.267.525.467.858.579l73.236 30.225a7.926 7.926 0 0 0 1.862.477c1.423.203 2.87.166 4.281-.11a8.57 8.57 0 0 0 1.877-.579l121.759-55.613c.253-.089.469-.259.615-.483.2-.287.291-.635.257-.983Z"
        />
      </mask>
      <g fill="#000" mask="url(#a">
        <path
          d="M258.343 211.759c.198-.284.289-.63.256-.975l.052-6.011-74.812-18.203-50.408 16.861v65.07c.22 0 .448-.059.66-.103a8.553 8.553 0 0 0 1.877-.579l121.759-55.591c.251-.085.467-.249.616-.469ZM131.413 268.773v-10.094h-2.551v9.779a8.057 8.057 0 0 0 2.551.315Z"
          opacity={0.2}
        />
      </g>
      <path
        stroke="#263238"
        strokeMiterlimit={10}
        strokeWidth={0.733}
        d="m258.595 210.764.051-6.011-74.812-18.181-130.27 43.59v6.26c-.006.302.096.595.286.829.228.267.524.467.857.579l73.236 30.225a7.926 7.926 0 0 0 1.862.477c1.423.203 2.87.166 4.281-.11a8.553 8.553 0 0 0 1.877-.579l121.759-55.613c.253-.089.47-.259.616-.483.2-.287.291-.635.257-.983Z"
      />
      <path
        fill="#fff"
        d="m220.321 197.283-9.582-2.427a2.616 2.616 0 0 0-.432-.073h-.469a4.303 4.303 0 0 0-.44.037 2.114 2.114 0 0 0-.359.088.474.474 0 0 0-.213.132.14.14 0 0 0-.018.069.14.14 0 0 0 .018.07.325.325 0 0 0 .154.139c.101.055.21.095.322.118l9.582 2.448c.148.033.297.057.447.073h.477c.149-.006.299-.021.447-.044.122-.017.243-.046.359-.087a.57.57 0 0 0 .205-.132.138.138 0 0 0 0-.147.4.4 0 0 0-.161-.147 1.75 1.75 0 0 0-.33-.117"
      />
      <path
        fill={colors.brand.pure}
        d="m248.39 205.559-16.165-4.12h-3.511a6.46 6.46 0 0 1-.844-.073c-.271-.037-.549-.074-.813-.118a8.306 8.306 0 0 1-.733-.161l-28.188-7.235-.572-.176a6.334 6.334 0 0 1-.469-.198 2.727 2.727 0 0 1-.352-.22 1.081 1.081 0 0 1-.22-.228l-.564-.821-14.471-3.665-110.206 37.981 69.556 26.634 107.552-47.6Z"
      />
      <path
        fill="#263238"
        d="M258.859 204.261a1.821 1.821 0 0 0-.675-.513 5.791 5.791 0 0 0-1.253-.44l-70.59-17.594a10.076 10.076 0 0 0-1.349-.227c-.487-.043-.977-.06-1.466-.051-.476.006-.951.042-1.422.11-.408.054-.81.145-1.202.271L55.25 228.439a5.362 5.362 0 0 0-1.312.623c-.276.16-.496.4-.63.689a.667.667 0 0 0 .087.675c.224.27.518.473.85.586l73.706 29.844a7.928 7.928 0 0 0 1.862.484c1.423.203 2.87.166 4.281-.11a8.57 8.57 0 0 0 1.877-.579l122.33-55.393c.255-.093.473-.265.624-.491a.5.5 0 0 0-.066-.506Zm-118.028 48.898-69.548-26.634 110.198-38.04 14.471 3.666.565.821c.061.086.135.163.219.227.112.083.229.156.352.22.14.066.294.132.47.198l.571.176 28.173 7.265a10.636 10.636 0 0 0 1.547.278c.278.03.557.059.843.074.286.014.572 0 .865 0h2.646l16.165 4.12-107.537 47.629Zm67.987-58.142a.485.485 0 0 1 .212-.132c.117-.039.238-.069.36-.088.145-.02.292-.032.439-.036h.47c.146.012.29.036.432.073l9.582 2.426c.113.028.224.067.33.118a.398.398 0 0 1 .161.146.148.148 0 0 1 .021.074.144.144 0 0 1-.021.073.552.552 0 0 1-.205.132 1.855 1.855 0 0 1-.36.088 4.175 4.175 0 0 1-.447.044h-.476a4.223 4.223 0 0 1-.448-.073l-9.581-2.449a1.158 1.158 0 0 1-.323-.117.322.322 0 0 1-.154-.14.155.155 0 0 1-.01-.071.152.152 0 0 1 .025-.068h-.007Zm-106.65 46.288a2.055 2.055 0 0 1-.572.322c-.268.1-.546.174-.828.22a5.433 5.433 0 0 1-.917.095 5.688 5.688 0 0 1-.894-.044c-.25-.037-.496-.1-.733-.19a1.31 1.31 0 0 1-.477-.301.432.432 0 0 1-.117-.344.612.612 0 0 1 .242-.352c.17-.141.363-.25.572-.323.268-.1.545-.174.828-.22a6.158 6.158 0 0 1 1.796-.044c.25.035.496.097.733.184.179.06.342.16.477.293a.386.386 0 0 1 .104.157c.022.06.029.124.02.187a.603.603 0 0 1-.234.36Z"
      />
      <path
        fill="#fff"
        d="M101.793 240.307a3.56 3.56 0 0 0-.733-.183 6.157 6.157 0 0 0-1.796.044c-.282.046-.56.12-.828.22a1.858 1.858 0 0 0-.572.323.613.613 0 0 0-.242.351.435.435 0 0 0 .117.345c.135.134.298.236.477.301.237.089.482.153.733.19.297.038.596.052.894.044.308-.006.614-.038.917-.095.282-.046.56-.12.828-.22.207-.076.4-.185.572-.323a.596.596 0 0 0 .227-.359.397.397 0 0 0-.124-.344 1.256 1.256 0 0 0-.477-.294"
      />
      <path
        stroke="#263238"
        strokeMiterlimit={10}
        strokeWidth={0.733}
        d="m77.34 208.521 2.097 3.549M46.537 156.42l28.437 48.091M101.396 198.243l1.194 2.859M93.246 178.758l6.81 16.289M235.201 153.723l-14.985 36.815M238.899 144.64l-1.855 4.537M247.214 189.783l-3.269 3.6M268.312 166.574l-17.829 19.61M88.374 203.134l12.184 23.554M237.853 188.507l-13.409 18.276M152.146 188.507l3.247 16.245"
      />
      <path
        fill="#fff"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.733}
        d="M202.547 135.988h-98.35c-5.029 0-9.106 3.249-9.106 7.256v11.147c0 4.007 4.077 7.255 9.106 7.255h98.35c5.029 0 9.106-3.248 9.106-7.255v-11.147c0-4.007-4.077-7.256-9.106-7.256Z"
      />
      <path
        fill={colors.brand.pure}
        d="M153.748 50.218a33.876 33.876 0 0 0-23.964 9.921 33.88 33.88 0 0 0-9.926 23.962c0 18.716 33.89 66.382 33.89 66.382s33.891-47.651 33.891-66.382a33.87 33.87 0 0 0-9.927-23.962 33.88 33.88 0 0 0-23.964-9.921Zm.301 50.165a16.047 16.047 0 1 1 0-32.094 16.047 16.047 0 0 1 0 32.094Z"
      />
      <mask
        id="b"
        width={69}
        height={101}
        x={119}
        y={50}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: "luminance",
        }}
      >
        <path
          fill="#fff"
          d="M153.752 50.217a33.885 33.885 0 0 0-33.89 33.883c0 18.716 33.89 66.382 33.89 66.382s33.891-47.651 33.891-66.382a33.889 33.889 0 0 0-20.922-31.306 33.88 33.88 0 0 0-12.969-2.577Zm.301 50.165a16.046 16.046 0 1 1 0-32.092 16.046 16.046 0 0 1 0 32.092Z"
        />
      </mask>
      <g mask="url(#b)">
        <path
          fill="#000"
          d="M175.877 84.343a21.825 21.825 0 1 1-43.65 0 21.825 21.825 0 0 1 43.65 0Z"
          opacity={0.1}
        />
      </g>
      <path
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={0.733}
        d="M153.748 50.218a33.876 33.876 0 0 0-23.964 9.921 33.88 33.88 0 0 0-9.926 23.962c0 18.716 33.89 66.382 33.89 66.382s33.891-47.651 33.891-66.382a33.87 33.87 0 0 0-9.927-23.962 33.88 33.88 0 0 0-23.964-9.921Zm.301 50.165a16.047 16.047 0 1 1 0-32.094 16.047 16.047 0 0 1 0 32.094Z"
      />
    </svg>
  );
}
