import React from "react";
import { Route, Switch } from "react-router-dom";
import Lobby from "../components/Lobby";
import RequireSignIn from "../components/lobby/RequireSignIn";
import NewPasswordFormContainer from "../components/recoveryPassword/NewPasswordFormContainer";
import RecoveryPasswordFormContainer from "../components/recoveryPassword/RecoveryPasswordFormContainer";
import SignUpFormContainer from "../components/signUp/SignUpFormContainer";
import { AccessDenied } from "../componentsV2/AccessDenied";
import Device from "../componentsV2/Device";
import ForgotPassword from "../componentsV2/Frial/forgotPassword";
import Register from "../componentsV2/Frial/register";
import ResetPassword from "../componentsV2/Frial/resetPassword";
import SignIn from "../componentsV2/Frial/signIn";
import SignUp from "../componentsV2/Frial/signUp";
import SignInSecurityContainer from "../componentsV2/SignInSecurity/SignInSecurityContainer";
import MobileValidation from "../componentsV2/SignInSecurity/screens/MobileValidation";
import { SafetyOnBoarding } from "../componentsV2/SafetyOnBoarding";

export default function LobbyRoutes(props) {
  return (
    <Lobby {...props}>
      <Switch>
        <Route path="/" component={SignIn} exact />
        <Route path="/signin-v2" component={SignIn} exact />
        <Route path="/forgot-password-v2" component={ForgotPassword} exact />
        <Route path="/reset-password-v2" component={ResetPassword} exact />
        <Route path="/signup-v2" component={SignUp} exact />
        <Route path="/register-v2" component={Register} exact />
        <Route path="/register-device" component={Device} exact />
        <Route path="/register-v2/final" component={Register} exact />
        <Route path="/signin" component={SignIn} exact />
        <Route
          path="/signin-security"
          component={SignInSecurityContainer}
          exact
        />
        <Route path="/authenticate-mobile" component={MobileValidation} exact />
        <Route path="/signup" component={SignUpFormContainer} exact />
        <Route
          path="/forgotten-password"
          component={RecoveryPasswordFormContainer}
          exact
        />
        <Route
          path="/reset-password"
          component={NewPasswordFormContainer}
          exact
        />
        <Route
          path="/segurancadaplataforma"
          component={SafetyOnBoarding}
          exact
        />

        <Route path="/access-denied" component={AccessDenied} exact />
        <Route path="*" component={RequireSignIn} />
      </Switch>
    </Lobby>
  );
}
