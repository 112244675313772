import React, { useCallback } from "react";
import { css } from "glamor";
import { uiColors } from "../library/theme";
import { getColor } from "../../utils/getColor";

const container = css({
  display: "flex",
  borderRadius: 8,
  background: uiColors.fieldBackground,
  flexDirection: "column",
  alignItems: "center",
});
const circularProgress = css({
  position: "relative",
  height: 48,
  width: 48,
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "::before": {
    content: '""',
    position: "absolute",
    height: 37,
    width: 37,
    borderRadius: "50%",
    backgroundColor: uiColors.fieldBackground,
  },
});
const progressValue = css({
  position: "relative",
  fontSize: 12,
  fontWeight: 600,
});

export default function CircularProgress({ percent = 0 }) {
  const circularProgressHandler = useCallback(
    () =>
      css(circularProgress, {
        background: `conic-gradient(${getColor(percent, true)} ${
          percent * 3.6
        }deg, #ededed 0deg)`,
      }),
    [percent]
  );

  return (
    <div className={container}>
      <div className={circularProgressHandler()}>
        <span className={progressValue}>{percent}%</span>
      </div>
    </div>
  );
}
