import React, { useState } from "react"
import { newPassword } from "../../../processes/sessionProcesses"
import { EMRButton } from "../../Button"
import Input from "../../Input"
import Template from "../template"
import { ButtonWrapper, Subtitle, Title, ResetPasswordForm } from "./styles"
import { getValidator } from "../validations";

function ResetPassword() {
  const [loading, setLoading] = useState(false)
  const [formErrors, setFormErrors] = useState({
    password: "",
    password_confirmation: ""
  });

  function validateField(field, ...params) {
    const validator = getValidator(field);
    return validator(...params);
  }

  function validateResetPassword(formData) {
    const resetPasswordData = {
      password: formData.get('password'),
      password_confirmation: formData.get('repeatPassword'),
    }

    const errors = {
      password: validateField("password", resetPasswordData.password),
      password_confirmation: validateField("repeatPassword", resetPasswordData.password_confirmation, resetPasswordData.password),
    };

    setFormErrors(errors);

    const hasValidationError = Object.values(errors).some((error) => Boolean(error));

    if (hasValidationError) return false;

    return resetPasswordData
  }

  async function handleResetPassword(e) {
    e.preventDefault()

    const formData = new FormData(e.currentTarget)
    const validatedResetPasswordData = validateResetPassword(formData)

    if (validatedResetPasswordData) {
      const resetPasswordData = {
        password: formData.get('password'),
        password_confirmation: formData.get('repeatPassword')
      }

      setLoading(true);

      await newPassword({
        password: resetPasswordData.password,
        password_confirmation: resetPasswordData.password_confirmation
      }, null, '/signin-v2')

      setLoading(false);
    }

    return
  }

  return (
    <Template>
      <Title>Crie uma senha nova.</Title>
      <Subtitle>Lembre-se que sua senha deve ter no mínimo 6 caracteres.</Subtitle>

      <ResetPasswordForm onSubmit={handleResetPassword}>
        <Input
          name="password"
          label='Nova senha*'
          type='password' error={formErrors.password}
        />
        <Input
          name="repeatPassword"
          label='Confirmar senha nova*'
          error={formErrors.password_confirmation}
          ispassword={true}
        />

        <ButtonWrapper>
          <EMRButton loading={loading} fullSized type="submit">Salvar</EMRButton>
        </ButtonWrapper>
      </ResetPasswordForm>
    </Template>
  )
}

export default ResetPassword