import React from "react";
import { SkeletonStyle } from "../styles";
import {
  Container,
  ItemDescription,
  SecondDescription,
  DescriptionContainer,
} from "./styles";
export function Item() {
  return (
    <Container>
      <SkeletonStyle width="4px" height="48px" borderRadius="8px" />
      <ItemDescription>
        <DescriptionContainer>
          <SkeletonStyle width="20px" height="20px" borderRadius="9999px" />
          <SecondDescription>
            <SkeletonStyle width="220px" height="15px" borderRadius="40px" />
            <SkeletonStyle width="93px" height="32px" borderRadius="58px" />
          </SecondDescription>
        </DescriptionContainer>
        <SkeletonStyle width="32px" height="32px" borderRadius="999px" />
      </ItemDescription>
    </Container>
  );
}
