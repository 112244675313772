import * as React from "react";
import { colors } from "../../../../../../components/library/theme";

export function InProgressIllustration() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={290}
      height={250}
      fill="none"
      className="illustration"
    >
      <path
        stroke="#C2CED6"
        strokeMiterlimit={10}
        strokeWidth={0.811}
        d="M66.983 81.805s-15.557-46.526 38.705-40.847c54.263 5.678 28.337-14.915 48.841-27.694 20.505-12.78 80.92 26.978 55.645 65.325-25.276 38.346 40.393 3.268 54.092 60.934 8.453 35.586-12.52 61.778-29.822 76.625"
      />
      <path
        fill="#1D2224"
        d="M239.735 171.689c-4.078-23.867-18.517-45.225-41.975-52.869a125.435 125.435 0 0 0-18.357-4.455v.032a13.257 13.257 0 0 1-2.246 5.498c-2.445 3.385-5.814 6.166-9.716 7.614-3.982 1.49-8.915 1.683-12.612-.66-2.225-1.408-4.384-3.887-4.774-6.563-.445-3.015 1.075-5.868 3.214-7.86-18.126.149-35.177 3.163-48.841 6.595a33.616 33.616 0 0 0 1.959 5.842 22.822 22.822 0 0 0 4.838 7.01 44.383 44.383 0 0 0 6.618-8.47 9.53 9.53 0 0 1 4.506 3.171 9.696 9.696 0 0 1 2.028 5.156v.097c.717 6.838-5.797 11.976-11.271 15.136-6.323 3.675-13.372 6.426-20.296 8.713-2.856.947-6.566 1.753-9.58.766a7.204 7.204 0 0 1-.949-.383c-3.005-1.49-5.017-4.612-6.586-7.466-4.47-8.106-7.378-16.942-9.349-26.011-13.678 1.767-15.245-7.595-16.057-9.891l-11.701 33.063a16.234 16.234 0 0 0-.195 7.27 16.142 16.142 0 0 0 2.998 6.612 15.936 15.936 0 0 0 5.576 4.598 15.748 15.748 0 0 0 7.01 1.642h48.442l22.005 57.047 6.236 16.191h35.622c-2.89 0-4.471-.713-5.618-1.876a6.438 6.438 0 0 1-1.855-4.515c0-3.506 2.846-6.38 7.473-6.38h.327c-.35 0-.685-.032-1.035-.064.217-.024.432-.06.645-.108a6.538 6.538 0 0 1-.685-.053 8.918 8.918 0 0 0 5.463-2.776 9.096 9.096 0 0 0 2.429-5.671c.006-.11.006-.22 0-.33v-.17a9.11 9.11 0 0 0-2.315-5.868 8.93 8.93 0 0 0-5.548-2.909 8.18 8.18 0 0 1 .708-.041c-.211-.053-.445-.085-.665-.117.347-.032.697-.055 1.032-.055l26.062.14 4.794.02h5.691c.263 0 .529 0 .78.032l8.453.044 5.647.032a8.877 8.877 0 0 1 6.275 2.681 9.071 9.071 0 0 1 2.577 6.374 8.887 8.887 0 0 1-.904 3.888 10.783 10.783 0 0 0 6.375-3.213c4.066-4.197 5.818-10.48 7.029-16.065 2.141-9.949 2.014-20.432.318-30.425Zm-65.846.672c-4.91 7.638-13.271 6.952-13.271 6.952s-.694.683-2.312-2.226c-1.619-2.909-6.228-4.898-6.228-4.898-.367 2.92-1.127 7.36-8.67 1.606-7.543-5.754-5.269-14.143-4.624-18.574.644-4.431 3.214-5.901 3.214-5.901l3.034-1.618s-8.777-2.281-9.199-2.366c-.422-.085-8.239-6.701-.306-8.447 7.933-1.747 20.299 5.199 22.689 6.572 2.39 1.373 16.358 13.314 16.358 13.314s4.251 7.959-.685 15.586Z"
      />
      <path
        fill="#1D2224"
        d="M66.33 122.587s6.615-.813 9.092-5.585l-2.16 12.241s26.317-9.055 31.144-10.223c0 0 1.554 10.133 7.745 12.852l6.387-8.208s8.008 2.045 5.817 13.075c-2.19 11.029-32.49 20.248-37.627 20.034-5.139-.213-10.67-3.408-16.335-20.581l-4.063-13.605Z"
      />
      <path
        stroke="#1D2224"
        strokeMiterlimit={10}
        strokeWidth={0.811}
        d="M77.105 95.317c1.677 2.758 3.361 5.509 5.101 8.226 1.072 1.674 3.026 4.06.948 5.81a4.204 4.204 0 0 1-2.344.876c-1.335.12-2.737-.205-3.549-1.364l-4.046-6.777a6.225 6.225 0 0 0-3.586-2.787 6.156 6.156 0 0 0-4.506.41M92.416 104.242c1.058 2.541.34 4.18-.896 5.004-2.023 1.341-4.731.698-6.164-1.259L77.128 95.33"
      />
      <path
        stroke="#1D2224"
        strokeMiterlimit={10}
        strokeWidth={0.811}
        d="M84.076 91.637c2.994 3.68 6.772 10.515 8.861 13.369 2.09 2.854 5.32 1.133 5.32 1.133 2.625-1.428 2.18-3.797 1.032-6.674-2.826-7.063-6.86-11.76-11.36-14.839-13.945-9.534-28.25-2.5-32.507 13.957l-16.834 47.17a16.238 16.238 0 0 0-.195 7.271 16.142 16.142 0 0 0 2.998 6.612 15.936 15.936 0 0 0 5.576 4.598 15.761 15.761 0 0 0 7.01 1.642h48.442l28.25 73.24"
      />
      <path
        stroke="#1D2224"
        strokeMiterlimit={10}
        strokeWidth={0.811}
        d="M101.373 96.225c3.468-1.215 3.309-6.805-1.612-8.692-5.436-2.086-15.09-5.036-17.514-5.772a.1.1 0 0 0-.052-.02c-.17-.053-.289-.097-.381-.117l-.136-.044M152.61 152.124c-3.034-3.687-11.34-5.775-16.762-6.786a4.267 4.267 0 0 1-2.368-1.332 4.35 4.35 0 0 1-1.1-2.503 4.448 4.448 0 0 1 .211-1.864 4.345 4.345 0 0 1 1.758-2.223 4.279 4.279 0 0 1 2.742-.651c9.248.946 18.785 3.888 25.839 10.223 0 0 34.198 26.377 38.281 28.944 1.685 1.034 3.257-.187 2.116-1.811l-.359-.619a55.684 55.684 0 0 0-7.095-9.709M153.245 112.422c-43.009.362-79.978 16.819-79.978 16.819l3.69-20.909"
      />
      <path
        fill="#1D2224"
        d="M220.042 226.214s-4.685-1.072-5.93 2.719c-1.246 3.792-2.546 9.824 6.41 10.18 8.956.356 3.696-1.422 3.179-4.352-.518-2.93-3.659-8.547-3.659-8.547Z"
      />
      <path
        stroke="#1D2224"
        strokeMiterlimit={10}
        strokeWidth={0.811}
        d="M148.485 156.935s-5.054-3.873-9.606.053c-.192.17-.359.367-.497.584"
      />
      <g opacity={0.77}>
        <path
          fill="#fff"
          d="M85.33 107.974c1.444 1.96 4.15 2.6 6.164 1.256 1.222-.818 1.939-2.448.907-4.965.188.277.358.522.526.747 2.087 2.866 5.32 1.139 5.32 1.139 2.625-1.428 2.182-3.797 1.035-6.677a36.77 36.77 0 0 0-4.384-8.032c1.17-1.108 2.56-2.226 4.026-1.736 1.581.532 2.023 2.536 2.202 4.207l.251 2.322 1.266 11.438c.422 3.798.844 7.65 1.76 11.357a33.704 33.704 0 0 0 1.96 5.842 22.8 22.8 0 0 0 4.838 7.01 44.398 44.398 0 0 0 6.618-8.471 9.54 9.54 0 0 1 4.505 3.172 9.694 9.694 0 0 1 2.029 5.156v.096c.717 6.838-5.797 11.976-11.271 15.137-6.323 3.674-13.372 6.426-20.297 8.713-2.855.947-6.566 1.753-9.58.765a7.058 7.058 0 0 1-.948-.382c-3.005-1.49-5.017-4.612-6.586-7.466-7.514-13.656-10.635-29.335-12.28-44.863 3.29-.459 6.566-.97 9.803-1.674 0 0 0 .023.02.032l3.731 6.254.307.52c.809 1.168 2.21 1.481 3.549 1.364a4.135 4.135 0 0 0 2.34-.876c2.075-1.753.128-4.131-.947-5.804"
          opacity={0.77}
        />
        <path
          fill={colors.brand.pure}
          d="M85.33 107.974c1.444 1.96 4.15 2.6 6.164 1.256 1.222-.818 1.939-2.448.907-4.965.188.277.358.522.526.747 2.087 2.866 5.32 1.139 5.32 1.139 2.625-1.428 2.182-3.797 1.035-6.677a36.77 36.77 0 0 0-4.384-8.032c1.17-1.108 2.56-2.226 4.026-1.736 1.581.532 2.023 2.536 2.202 4.207l.251 2.322 1.266 11.438c.422 3.798.844 7.65 1.76 11.357a33.704 33.704 0 0 0 1.96 5.842 22.8 22.8 0 0 0 4.838 7.01 44.398 44.398 0 0 0 6.618-8.471 9.54 9.54 0 0 1 4.505 3.172 9.694 9.694 0 0 1 2.029 5.156v.096c.717 6.838-5.797 11.976-11.271 15.137-6.323 3.674-13.372 6.426-20.297 8.713-2.855.947-6.566 1.753-9.58.765a7.058 7.058 0 0 1-.948-.382c-3.005-1.49-5.017-4.612-6.586-7.466-7.514-13.656-10.635-29.335-12.28-44.863 3.29-.459 6.566-.97 9.803-1.674 0 0 0 .023.02.032l3.731 6.254.307.52c.809 1.168 2.21 1.481 3.549 1.364a4.135 4.135 0 0 0 2.34-.876c2.075-1.753.128-4.131-.947-5.804"
          opacity={0.77}
        />
        <path
          fill={colors.brand.pure}
          d="M86.216 108.9c1.28 1.753 3.468 1.531 5.277.33 1.092-.73 1.988-.517 1.066-2.769.17.246.194-.584.344-.4 1.864 2.559.023-1.057.023-1.057 2.601 3.321 8.263.192 7.225-2.375-1.098-2.766-2.254-6.856-3.72-8.9 1.157-1.104.934-.94 2.405-.062a3.07 3.07 0 0 1 1.384 2.351c.119 1.114.237 2.18.356 3.266l1.127 10.224c.379 3.397.754 6.829 1.572 10.135a29.838 29.838 0 0 0 1.752 5.211c1.034 2.311 4.358 6.158 6.173 7.919 2.257-2.264 4.962-6.426 6.618-9.192 3.179.987 3.04 4.06 3.265 7.416v.088c.642 6.102-5.173 10.685-10.045 13.509-5.647 3.281-11.939 5.734-18.12 7.779-2.55.847-5.862 1.577-8.552.683a6.15 6.15 0 0 1-.847-.341c-2.682-1.332-4.48-4.116-5.881-6.666-6.136-11.138-8.982-23.782-10.549-36.459a3.713 3.713 0 0 1 .709-2.668 3.641 3.641 0 0 1 2.343-1.422c1.76-.292 3.515-.584 5.257-.975l.05.023 3.329 5.582c.725 1.037.844.225 2.037.12.754-.068 2.217-.216 2.8-.71 1.853-1.56.807-2.205-.153-3.698"
          opacity={0.77}
        />
      </g>
      <path
        stroke="#1D2224"
        strokeMiterlimit={10}
        strokeWidth={0.811}
        d="M147.865 162.572s-9.597-3.189-9.742 2.173"
      />
      <path
        fill="#fff"
        d="m61.962 217.202-18.823-48.739a6.469 6.469 0 0 1-.051-2.879c.2-.951.611-1.843 1.203-2.609a6.346 6.346 0 0 1 2.214-1.813 6.278 6.278 0 0 1 2.775-.647h74.765c1.679 0 3.308.571 4.627 1.621a7.557 7.557 0 0 1 2.641 4.168l20.606 48.728a6.45 6.45 0 0 1-1.185 5.45 6.35 6.35 0 0 1-2.208 1.795 6.265 6.265 0 0 1-2.76.641H69.213a7.418 7.418 0 0 1-4.6-1.597 7.554 7.554 0 0 1-2.651-4.119Z"
        opacity={0.54}
      />
      <path
        stroke="#1D2224"
        strokeMiterlimit={10}
        strokeWidth={0.811}
        d="M150.994 170.794s1.558 1.443.965 3.506c-.439 1.527-1.942 2.491-3.43 1.968-4.74-1.656-9.008-4.469-10.048-9.25a23.47 23.47 0 0 1-.315-7.361c.228-2.276.56-4.732 1.317-6.897 1.156-3.324 4.697-5.64 8.133-4.039"
      />
      <path
        fill={colors.brand.pure}
        d="m61.962 217.202-18.823-48.739a6.469 6.469 0 0 1-.051-2.879c.2-.951.611-1.843 1.203-2.609a6.346 6.346 0 0 1 2.214-1.813 6.278 6.278 0 0 1 2.775-.647h74.765c1.679 0 3.308.571 4.627 1.621a7.557 7.557 0 0 1 2.641 4.168l20.606 48.728a6.45 6.45 0 0 1-1.185 5.45 6.35 6.35 0 0 1-2.208 1.795 6.265 6.265 0 0 1-2.76.641H69.213a7.418 7.418 0 0 1-4.6-1.597 7.554 7.554 0 0 1-2.651-4.119Z"
      />
      <path
        stroke="#1D2224"
        strokeLinecap="square"
        strokeLinejoin="round"
        strokeWidth={0.811}
        d="m61.962 217.202-18.823-48.739a6.469 6.469 0 0 1-.051-2.879c.2-.951.611-1.843 1.203-2.609a6.346 6.346 0 0 1 2.214-1.813 6.278 6.278 0 0 1 2.775-.647h74.765c1.679 0 3.308.571 4.627 1.621a7.557 7.557 0 0 1 2.641 4.168l20.606 48.728a6.45 6.45 0 0 1-1.185 5.45 6.35 6.35 0 0 1-2.208 1.795 6.265 6.265 0 0 1-2.76.641H69.213a7.418 7.418 0 0 1-4.6-1.597 7.554 7.554 0 0 1-2.651-4.119Z"
      />
      <path
        fill={colors.brand.pure}
        d="m64.713 215.24-17.366-44.982a5.968 5.968 0 0 1-.046-2.656 5.93 5.93 0 0 1 1.11-2.407 5.847 5.847 0 0 1 2.042-1.671 5.78 5.78 0 0 1 2.56-.598h68.974a6.841 6.841 0 0 1 4.268 1.495 6.97 6.97 0 0 1 2.436 3.845l19.014 44.956a5.965 5.965 0 0 1-1.095 5.029 5.856 5.856 0 0 1-2.037 1.655 5.776 5.776 0 0 1-2.547.592H71.4a6.842 6.842 0 0 1-4.237-1.467 6.977 6.977 0 0 1-2.45-3.791Z"
      />
      <path
        stroke="#1D2224"
        strokeMiterlimit={10}
        strokeWidth={0.811}
        d="m212.329 208.183 13.225 30.93M275.06 239.113H14.96M152.058 99.514c-2.144 2.141-5.346 2.138-7.17.158M94.897 91.432c1.17-1.107 2.56-2.226 4.026-1.735 1.581.531 2.023 2.535 2.202 4.206.507 4.592 1.013 9.179 1.517 13.761.642 5.842 1.318 11.824 3.72 17.192a22.822 22.822 0 0 0 4.838 7.011"
      />
      <path
        stroke="#1D2224"
        strokeMiterlimit={10}
        strokeWidth={0.811}
        d="M124.363 131.832v-.097a9.709 9.709 0 0 0-2.029-5.156 9.544 9.544 0 0 0-4.506-3.171 44.343 44.343 0 0 1-6.618 8.47 45.04 45.04 0 0 1-24.46 12.674c.444 1.791 1.36 3.421 1.993 5.144.633 1.724.948 3.719.18 5.381-.972 2.12-3.757 1.843-5.694 1.373h-.052"
      />
      <path
        stroke="#1D2224"
        strokeMiterlimit={10}
        strokeWidth={0.811}
        d="M73.211 102.062c-3.236.703-6.514 1.215-9.803 1.673 1.645 15.528 4.754 31.208 12.28 44.863 1.57 2.854 3.58 5.977 6.586 7.466.307.151.624.279.948.383 3.015 1.002 6.725.181 9.58-.765 6.937-2.29 13.974-5.039 20.297-8.714 5.459-3.163 11.973-8.298 11.271-15.136a10.068 10.068 0 0 0-.454-2.214"
      />
      <path
        fill={colors.brand.pure}
        d="M127.325 71.087a10.057 10.057 0 0 0 3.166 5.257 9.877 9.877 0 0 0 5.605 2.402 5.322 5.322 0 0 1 1.087-2.295c2.312-2.755 3.298 2.412 10.693-1.297 10.248-5.153 14.138-2.197 14.138-2.197.341.257.289 2.629.347 3.061.289 2.462.549 4.913.835 7.373.173 1.5.344 2.99.506 4.48.139-.253.294-.498.462-.733.934-3.339 2.601-7.282 6.196-5.585a5.154 5.154 0 0 1 1.85 1.54c2.384 3.166.578 6.224-1.916 8.488a12.972 12.972 0 0 1-2.084 1.884c.411.859 2.087 4.268 2.087 4.28.173.344 6.031-6.357 6.468-7.087a17.169 17.169 0 0 0 1.034-5.582c.096-7.837-3.06-16.96-9.34-21.455-1.558-1.112-1.061-2.184-1.156-3.698a.402.402 0 0 0-.526-.353.401.401 0 0 0-.194.14c-1.968 2.29-6.841 2.063-6.841 2.063-16.912-4.282-22.596 3.318-26.01 4.673a6.34 6.34 0 0 1-4.335.073 2.634 2.634 0 0 1-.604 1.272c-.312.36-.715.627-1.167.773l.087.791a2.655 2.655 0 0 1-.388 1.732Z"
      />
      <path
        stroke="#1D2224"
        strokeMiterlimit={10}
        strokeWidth={0.811}
        d="M136.11 78.76a9.88 9.88 0 0 1-5.605-2.403 10.057 10.057 0 0 1-3.166-5.257 13.668 13.668 0 0 1-.358-2.389l.133-.014c.182-.014.361-.054.532-.117a2.6 2.6 0 0 0 1.167-.773c.312-.36.521-.8.604-1.272.053-.261.064-.53.032-.794l-.433-3.868s3.057 2.299 6.45.955c3.393-1.343 9.089-8.944 26.01-4.673 0 0 3.821.228 5.78-2.062a.398.398 0 0 1 .631-.01.414.414 0 0 1 .089.223c.101 1.516.656 2.588 2.216 3.698 6.28 4.498 9.436 13.617 9.338 21.454-.029 3.251-1.098 6.461-2.749 9.216-.436.73-6.294 7.43-6.467 7.086 0 0-1.677-3.42-2.087-4.28l-.098-.189.188-.126a19.656 19.656 0 0 0 1.994-1.568c2.491-2.264 4.3-5.322 1.916-8.488a5.154 5.154 0 0 0-1.85-1.54c-3.607-1.697-5.262 2.246-6.196 5.585a9.138 9.138 0 0 0-.332 1.957l-.13-1.212c-.162-1.49-.333-2.98-.506-4.48-.289-2.46-.561-4.911-.835-7.373-.044-.421 0-2.805-.347-3.062 0 0-3.893-2.956-14.138 2.197-7.396 3.71-8.381-1.46-10.693 1.297a5.312 5.312 0 0 0-1.188 3.117.391.391 0 0 0 0 .108c-.034.604-.046 1.206-.06 1.796-.055 5.693.563 11.64 2.774 16.927 2.688 6.409 9.8 12.73 17.195 9.741 2.312-.937 4.385-2.447 6.28-4.051"
      />
      <path
        fill="#1D2224"
        d="M141.177 92.643c.735-.249 1.09-1.18.793-2.079-.298-.9-1.136-1.427-1.872-1.178-.735.248-1.09 1.18-.792 2.079.298.9 1.135 1.427 1.871 1.178ZM152.182 92.288c.735-.249 1.09-1.18.792-2.08-.298-.9-1.135-1.427-1.871-1.178-.735.25-1.09 1.18-.793 2.08.298.9 1.136 1.427 1.872 1.178Z"
      />
      <path
        stroke="#1D2224"
        strokeMiterlimit={10}
        strokeWidth={0.811}
        d="M147.967 94.214s-3.661 2.1-3.884-.079c-.069-.671-.965-7.252-.965-7.252s-2.96-3.17-7.494-.827M147.146 85.038s5.913-2.985 9.826 1.317M152.575 108.9l.679 3.522c-2.141 1.995-3.655 4.855-3.213 7.866.393 2.67 2.554 5.156 4.771 6.555 3.705 2.337 8.633 2.153 12.621.666 3.898-1.46 7.271-4.232 9.716-7.615a13.281 13.281 0 0 0 2.243-5.494v-.035M153.247 112.422l1.324 6.362"
      />
      <path
        stroke="#1D2224"
        strokeMiterlimit={10}
        strokeWidth={0.811}
        d="m171.961 96.226 2 13.422a5.562 5.562 0 0 0 1.852 3.378 5.454 5.454 0 0 0 3.584 1.339 125.452 125.452 0 0 1 18.369 4.452c23.458 7.647 37.897 29.005 41.974 52.869 1.697 9.993 1.824 20.473-.306 30.41-1.211 5.594-2.962 11.877-7.028 16.065-5.28 5.463-13.384 3.214-19.623.745-19.372-7.615-35.484-21.162-49.396-36.672-7.439-8.296-17.051-14.944-17.051-14.944"
      />
      <path
        fill="#C2CED6"
        d="M71.109 205.172c-2.174-.021-4.067-1.794-4.555-4.262-.965-4.855-1.22-12.362 5.823-14 8.8-2.045 15.569 7.641 18.886 13.763 1.116 2.045-.147 4.674-2.225 4.674l-17.93-.175ZM74.017 182.787c1.868-1.256 1.897-4.532.064-7.316s-4.833-4.022-6.701-2.766c-1.869 1.257-1.897 4.532-.065 7.316 1.834 2.785 4.834 4.023 6.702 2.766Z"
      />
      <path
        fill="#fff"
        d="M218.644 226.375c-.136.021-.289.041-.422.073h-.052l-20.557-.108-30.844-.16h-.35a6.82 6.82 0 0 1-.685-.053 8.903 8.903 0 0 0 5.471-2.775 9.078 9.078 0 0 0 2.421-5.681c.006-.11.006-.22 0-.33v-.17a9.095 9.095 0 0 0-2.027-5.694 8.928 8.928 0 0 0-5.128-3.124c-.211-.053-.442-.085-.665-.117.35-.032.697-.055 1.035-.055l26.059.14 4.797.02 6.471.032a8.907 8.907 0 0 1 5.779 2.896 9.087 9.087 0 0 1 2.345 6.072c0 .424-.032.848-.096 1.267a9.061 9.061 0 0 1-2.981 5.518 8.878 8.878 0 0 1-5.822 2.214h15.658c-.901 0-.65 0-.407.035Z"
      />
      <path
        fill={colors.brand.light200}
        d="M176.94 237.238a6.25 6.25 0 0 0 4.482 1.876h-15.137c-2.89 0-4.468-.713-5.619-1.876a6.46 6.46 0 0 1-1.852-4.515c0-3.506 2.844-6.38 7.471-6.38h15.137a6.263 6.263 0 0 0-3.511 1.072 6.361 6.361 0 0 0-2.329 2.865 6.434 6.434 0 0 0 1.372 6.958h-.014Z"
      />
      <path
        fill={colors.brand.pure}
        d="M226.936 217.501a8.877 8.877 0 0 1-.908 3.888 8.47 8.47 0 0 1-1.716 2.45 8.878 8.878 0 0 1-6.28 2.609c.139-.032.289-.053.422-.073-.243-.024-.494-.032.41-.032h-15.661a8.881 8.881 0 0 0 5.826-2.214 9.059 9.059 0 0 0 2.983-5.521 8.27 8.27 0 0 0 .095-1.268 9.082 9.082 0 0 0-2.346-6.073 8.904 8.904 0 0 0-5.78-2.894l8.453.044 5.647.032a8.88 8.88 0 0 1 6.275 2.68 9.076 9.076 0 0 1 2.58 6.372Z"
      />
      <path
        stroke="#1D2224"
        strokeLinecap="square"
        strokeLinejoin="round"
        strokeWidth={0.811}
        d="M226.939 217.498a9.065 9.065 0 0 1-2.649 6.347 8.878 8.878 0 0 1-6.31 2.606l-51.419-.269c-.344 0-.687-.026-1.034-.062a8.88 8.88 0 0 0 5.627-2.932 9.056 9.056 0 0 0 2.279-5.969 9.054 9.054 0 0 0-2.218-5.992 8.88 8.88 0 0 0-5.596-2.992 9.415 9.415 0 0 1 1.035-.052l51.419.269a8.88 8.88 0 0 1 6.281 2.672 9.073 9.073 0 0 1 2.585 6.374Z"
      />
      <path
        stroke="#1D2224"
        strokeLinecap="square"
        strokeLinejoin="round"
        strokeWidth={0.811}
        d="M212.105 217.337a9.067 9.067 0 0 1-2.614 6.36 8.87 8.87 0 0 1-6.293 2.642h-36.585a10.204 10.204 0 0 1-1.034-.058 8.883 8.883 0 0 0 5.611-2.962 9.062 9.062 0 0 0 2.248-5.98c0-2.205-.8-4.333-2.248-5.981a8.883 8.883 0 0 0-5.611-2.962c.344-.032.691-.056 1.034-.056h36.585a8.88 8.88 0 0 1 6.29 2.641 9.073 9.073 0 0 1 2.617 6.356Z"
      />
      <path
        stroke="#1D2224"
        strokeLinecap="square"
        strokeLinejoin="round"
        strokeWidth={0.811}
        d="M158.814 232.725c0 .838.163 1.667.48 2.441a6.38 6.38 0 0 0 1.37 2.069c1.156 1.168 2.725 1.878 5.618 1.878h52.577c-.904 0-.658-.018-.413-.041a6.301 6.301 0 0 1-3.986-2.1 6.434 6.434 0 0 1-1.598-4.247c0-1.566.569-3.077 1.598-4.247a6.301 6.301 0 0 1 3.986-2.1c-.245-.024-.491-.041.413-.041h-52.577c-4.63.003-7.468 2.877-7.468 6.388Z"
      />
      <path
        stroke="#1D2224"
        strokeLinecap="square"
        strokeLinejoin="round"
        strokeWidth={0.811}
        d="M175.084 232.725a6.44 6.44 0 0 0 1.855 4.513 6.303 6.303 0 0 0 4.465 1.875h36.307c.243 0 .489-.018.735-.041a6.304 6.304 0 0 1-3.987-2.1 6.434 6.434 0 0 1-1.598-4.247c0-1.566.569-3.077 1.598-4.247a6.304 6.304 0 0 1 3.987-2.1 7.595 7.595 0 0 0-.735-.041h-36.307a6.304 6.304 0 0 0-4.464 1.876 6.443 6.443 0 0 0-1.856 4.512Z"
      />
      <path
        fill="#C2CED6"
        d="M116.234 183.986h-16.218a3.792 3.792 0 0 1-2.089-.632 3.847 3.847 0 0 1-1.408-1.682 1.676 1.676 0 0 1 .728-2.16c.245-.132.52-.198.798-.194h17.241c-.812 1.382-.398 3.301.948 4.668Z"
      />
      <path
        fill={colors.brand.pure}
        d="M126.715 182.298a1.698 1.698 0 0 1-.489 1.19 1.663 1.663 0 0 1-1.179.495h-8.82c-1.347-1.364-1.76-3.283-.948-4.674h7.789a3.802 3.802 0 0 1 2.087.627c.622.408 1.112.99 1.413 1.675.093.217.143.45.147.687Z"
      />
      <path
        fill="#fff"
        d="M122.657 183.986a1.636 1.636 0 0 1-.159.254c-1.156 1.598-3.667 1.752-5.618.31a6.259 6.259 0 0 1-.653-.564c-1.347-1.364-1.76-3.283-.948-4.674.046-.089.099-.174.159-.254 1.156-1.609 3.656-1.752 5.607-.321.235.177.456.373.662.584 1.335 1.358 1.751 3.266.95 4.665Z"
      />
      <path
        fill={colors.brand.pure}
        d="M130.793 192.589a1.702 1.702 0 0 1-.495 1.19 1.667 1.667 0 0 1-1.181.492h-17.019c.821-1.396.41-3.3-.939-4.673h15.987c.743 0 1.469.219 2.09.629.622.41 1.111.994 1.41 1.681.095.214.145.446.147.681Z"
      />
      <path
        fill="#C2CED6"
        d="M112.102 194.27h-8.008a3.795 3.795 0 0 1-2.088-.625 3.858 3.858 0 0 1-1.412-1.677 1.745 1.745 0 0 1-.147-.68 1.687 1.687 0 0 1 .487-1.191 1.65 1.65 0 0 1 1.178-.492h9.051c1.35 1.364 1.769 3.269.939 4.665Z"
      />
      <path
        fill="#fff"
        d="M112.108 194.27a1.148 1.148 0 0 1-.147.257c-1.156 1.606-3.659 1.753-5.607.318a5.852 5.852 0 0 1-.665-.584c-1.338-1.364-1.748-3.268-.948-4.673.047-.09.1-.176.159-.257 1.156-1.598 3.668-1.753 5.616-.307.232.17.45.359.653.564 1.349 1.381 1.768 3.286.939 4.682Z"
      />
      <path
        fill={colors.brand.pure}
        d="M117.766 204.485h-10.19a3.802 3.802 0 0 1-2.087-.627 3.858 3.858 0 0 1-1.413-1.674 1.682 1.682 0 0 1 .123-1.606c.152-.235.361-.428.607-.561.245-.132.52-.2.799-.196h11.213c-.804 1.399-.39 3.3.948 4.664Z"
      />
      <path
        fill="#C2CED6"
        d="M134.286 202.815a1.69 1.69 0 0 1-.491 1.183 1.652 1.652 0 0 1-1.174.487h-14.849c-1.338-1.364-1.751-3.268-.948-4.673h13.815c.742 0 1.468.219 2.09.629.621.41 1.111.994 1.409 1.681.096.218.146.454.148.693Z"
      />
      <path
        fill="#fff"
        d="M124.2 204.485c-.046.09-.1.176-.159.257-1.156 1.607-3.656 1.753-5.607.319a6.327 6.327 0 0 1-.662-.584c-1.338-1.364-1.751-3.269-.948-4.674.045-.09.097-.176.157-.257 1.156-1.598 3.667-1.735 5.618-.307.231.171.45.359.653.564 1.347 1.382 1.757 3.298.948 4.682Z"
      />
      <path
        fill="#1D2224"
        d="m47.216 111.363.256.073-.096.374a4.702 4.702 0 0 1-.16-.447Z"
      />
      <path
        fill="#C2CED6"
        d="M92.44 134.731c3.803-1.129 5.802-5.78 4.464-10.388-1.338-4.608-5.507-7.428-9.311-6.3-3.805 1.129-5.804 5.78-4.465 10.388 1.338 4.608 5.507 7.428 9.311 6.3Z"
      />
    </svg>
  );
}
