import React from "react";
import { IoCheckmark } from "react-icons/io5";
import { Line, Step, StepContainer, StepsWrapper } from "./styles";

export default function DeviceStepper({ currentStep }) {
  const steps = [
    { step: "initial", description: "Cadastre seu dispositivo" },
    { step: "second", description: "Confirmação de segurança" },
    { step: "final", description: "Continue seus estudos" },
  ];

  return (
    <StepsWrapper>
      <Line />
      {steps.map((step, index) => (
        <StepContainer key={index} isActive={step.step === currentStep}>
          <Step
            key={step.step}
            isActive={step.step === currentStep}
            currentStep={currentStep}
          >
            <IoCheckmark size={24} />
          </Step>
          <span>{step.description}</span>
        </StepContainer>
      ))}
    </StepsWrapper>
  );
}
