import { SubmissionError } from "redux-form";
import { apiPost } from "./helpers/api";
import { notifySubmitSucceeded } from "./notifierProcesses";

export const submitSupport = (values, dispatch, props) => {
  return apiPost("/api/v1/supports")
    .send(values)
    .then((response) => {
      notifySubmitSucceeded(dispatch, response.body.data);
      props.reset();
    })
    .catch((err) => {
      throw new SubmissionError({ _error: err.response.body.errors });
    });
};
