import React from 'react'
import { Line, Step, StepsWrapper } from './styles'
import { IoCheckmark } from 'react-icons/io5';

export default function Stepper({currentStep}) {
  const steps = ["initial", "guru" , "final"];

  return (
    <StepsWrapper>
      <Line/>
      {steps.map((step) => 
        <Step key={step} isActive={step === currentStep} currentStep={currentStep}>
          <IoCheckmark size={24}/> 
        </Step>
        )}
    </StepsWrapper>
  )
}
