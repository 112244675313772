import React from 'react';

export function StarIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3125 15C12.2073 15.0004 12.1046 14.9676 12.0191 14.9062L8 11.9925L3.98094 14.9062C3.89507 14.9685 3.79163 15.0019 3.68555 15.0015C3.57948 15.0011 3.47628 14.967 3.39087 14.9041C3.30546 14.8412 3.24227 14.7528 3.21043 14.6516C3.17859 14.5504 3.17975 14.4417 3.21375 14.3412L4.78125 9.69843L0.718748 6.91249C0.630752 6.85221 0.564337 6.76537 0.529208 6.66466C0.494079 6.56395 0.492077 6.45464 0.523494 6.35271C0.55491 6.25078 0.6181 6.16157 0.70383 6.09811C0.78956 6.03465 0.893337 6.00027 0.999998 5.99999H6.01187L7.52437 1.3453C7.55696 1.24481 7.62053 1.15722 7.70598 1.0951C7.79143 1.03297 7.89436 0.999512 8 0.999512C8.10564 0.999512 8.20857 1.03297 8.29402 1.0951C8.37947 1.15722 8.44304 1.24481 8.47562 1.3453L9.98812 6.00155H15C15.1068 6.0015 15.2108 6.03564 15.2968 6.09898C15.3828 6.16232 15.4462 6.25152 15.4778 6.35353C15.5094 6.45554 15.5076 6.56499 15.4725 6.66586C15.4374 6.76672 15.3709 6.8537 15.2828 6.91405L11.2187 9.69843L12.7853 14.34C12.8107 14.4151 12.8178 14.4952 12.8061 14.5737C12.7944 14.6521 12.7643 14.7267 12.7181 14.7912C12.6719 14.8557 12.611 14.9082 12.5405 14.9445C12.47 14.9808 12.3918 14.9998 12.3125 15Z"
        fill="#E9C610"
      />
    </svg>
  );
}
