import React, { useState } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import {
  CheckboxText,
  DialogContent,
  DialogOverlay,
  IllustrationWrapper,
  Infos,
  StyledEMRButton,
  Title,
} from "./styles";
import { Illustration } from "./illustration";
import { CheckBox } from "../CheckBox";

import { medtrackApi } from "../../../../../services/medtrack";
import { useMedTrack } from "../../hooks/useMedTrack";

export function ModalActiveRevision({ onClose }) {
  const { dontShowAgain, setDontShowAgain } = useMedTrack();

  async function handleSendAction() {
    if (dontShowAgain) {
      await medtrackApi.post("/user-activity/register", {
        action: "DONT_SHOW_REVISION_MODE_MODAL",
      });
    }
    onClose();
  }

  return (
    <Dialog.Root open={true}>
      <Dialog.Portal>
        <DialogOverlay>
          <DialogContent>
            <IllustrationWrapper>
              <Illustration />
            </IllustrationWrapper>
            <Title>
              Você está ativando <span>Medtrack Revisão</span>
            </Title>
            <Infos>
              O modo que surgiu para te guiar nos estudos entre os vários
              assuntos abordados até a realização de sua prova. Aqui você terá
              flexibilidade para definir sua disponibilidade de tempo semanal
              para seus estudos.
            </Infos>
            <CheckBox
              isChecked={dontShowAgain}
              onCheck={() => setDontShowAgain((prev) => !prev)}
              variant="square"
            >
              <CheckboxText>Não mostrar novamente</CheckboxText>
            </CheckBox>
            <StyledEMRButton onClick={handleSendAction}>
              Entendi
            </StyledEMRButton>
          </DialogContent>
        </DialogOverlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
