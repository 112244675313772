import * as React from "react";

export function SatisfiedFace() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8931_1123)">
        <path
          d="M34.139 5.86096C30.6304 2.36257 25.945 0.295821 20.9957 0.063459C16.0465 -0.168903 11.188 1.44977 7.3671 4.60405C3.54617 7.75832 1.03651 12.2222 0.327181 17.1259C-0.38215 22.0295 0.759664 27.0216 3.53012 31.1294C6.30058 35.2371 10.5012 38.1662 15.3134 39.3459C20.1256 40.5256 25.2047 39.8713 29.5608 37.5105C33.9169 35.1498 37.2381 31.2518 38.8771 26.576C40.516 21.9002 40.3554 16.7817 38.4265 12.2179C37.4218 9.84165 35.9658 7.68279 34.139 5.86096ZM20 37.6668C10.2585 37.6668 2.33314 29.7415 2.33314 20C2.33314 10.2585 10.2585 2.33319 20 2.33319C29.7414 2.33319 37.6668 10.2585 37.6668 20C37.6668 29.7415 29.7414 37.6668 20 37.6668Z"
          fill="#606A71"
        />
        <path
          d="M19.7387 30.7569C16.6864 30.7569 13.8479 29.4354 11.2991 26.8264C11.0876 26.6042 10.9721 26.3077 10.9776 26.0009C10.9832 25.6942 11.1093 25.402 11.3287 25.1876C11.5482 24.9732 11.8432 24.8539 12.15 24.8554C12.4568 24.857 12.7506 24.9793 12.9678 25.196C15.1059 27.3845 17.4391 28.4694 19.8997 28.4218C23.8992 28.3439 27.014 25.3126 27.0448 25.2823C27.1527 25.1712 27.2817 25.0828 27.4242 25.0221C27.5667 24.9614 27.7198 24.9296 27.8747 24.9288C28.0296 24.9279 28.1831 24.9578 28.3263 25.0169C28.4695 25.0759 28.5995 25.1629 28.7087 25.2728C28.8179 25.3826 28.9041 25.5131 28.9624 25.6566C29.0206 25.8001 29.0497 25.9538 29.0479 26.1086C29.0462 26.2635 29.0136 26.4165 28.9521 26.5586C28.8906 26.7008 28.8014 26.8292 28.6897 26.9365C28.5376 27.0887 24.8997 30.6584 19.9454 30.7546C19.8763 30.756 19.8073 30.7569 19.7387 30.7569Z"
          fill="#606A71"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.7643 11.9688C24.588 11.9688 23.6332 13.5749 23.6332 15.5534C23.6332 17.532 24.588 19.1377 25.7643 19.1377C26.9407 19.1377 27.8955 17.5315 27.8955 15.5534C27.8955 13.5754 26.9403 11.9688 25.7643 11.9688ZM14.2356 11.9688C13.0597 11.9688 12.1045 13.5749 12.1045 15.5534C12.1045 17.532 13.0597 19.1377 14.2356 19.1377C15.4116 19.1377 16.3668 17.5315 16.3668 15.5534C16.3668 13.5754 15.412 11.9688 14.2356 11.9688Z"
          fill="#606A71"
        />
      </g>
      <defs>
        <clipPath id="clip0_8931_1123">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
