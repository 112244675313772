import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import {
  fetchReports,
  selectedClassroomIdChanged,
} from "../../processes/reportProcesses";
import {
  getClassroomById,
  getClassroomsWithMocksEnabled,
} from "../../selectors/classroomSelectors";
import {
  getSelectedClassroomId,
  getScoreChartData,
  getReportByClassroomId,
} from "../../selectors/reportSelectors";
import MyPerformance from "./MyPerformance";

function MyPerformanceContainer(props) {
  const { dispatch, selectedClassroomId } = props;

  useEffect(() => {
    fetchReports(dispatch, selectedClassroomId);
  }, [dispatch, selectedClassroomId]);

  const selectClassroomHandler = useCallback(
    (_, e) => {
      selectedClassroomIdChanged(dispatch, e.value);
    },
    [dispatch]
  );

  return (
    <MyPerformance selectClassroomHandler={selectClassroomHandler} {...props} />
  );
}

function mapStateToProps(state) {
  const selectedClassroomId = getSelectedClassroomId(state);
  const classroom = getClassroomById(state, selectedClassroomId);

  return {
    scoreChartData: getScoreChartData(state),
    classroom,
    classrooms: getClassroomsWithMocksEnabled(state),
    classroomReport: getReportByClassroomId(state, selectedClassroomId),
    selectedClassroomId,
    status: state.reportsUtils.status,
  };
}

export default connect(mapStateToProps)(MyPerformanceContainer);
