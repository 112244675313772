import { createSelector } from "reselect";
import { orderByPosition } from "../utils/orderByPosition";
import { getMockId } from "./mockSelectors";

export const getAlternatives = (state) => state.entities.alternatives;
const getQuestionId = (_, id) => id;
const getSelectedAlternative = (state) => state.mock.selectedAlternatives;
const getProps = (_, props) => props;
const getDiscardAlternatives = (state) => state.discardAlternatives;

export const getAlternativesFromQuestion = createSelector(
  getAlternatives,
  getQuestionId,
  (alternatives, questionId) =>
    Object.values(alternatives)
      .filter((alternative) => alternative.questionId === questionId)
      .sort(orderByPosition)
);

export const makeIsSelected = createSelector(
  getSelectedAlternative,
  getProps,
  (selectedAlternatives, props) => {
    const { questionId, alternativeId } = props;
    return selectedAlternatives[questionId]?.id == alternativeId;
  }
);

export const getSelectedAlternativeFromQuestion = createSelector(
  getSelectedAlternative,
  getQuestionId,
  (selectedAlternative, questionId) => selectedAlternative[questionId]
);

export const makeDiscardAlternative = createSelector(
  getDiscardAlternatives,
  getQuestionId,
  getMockId,
  (discardAlternatives, id, mockId) => {
    return !!discardAlternatives.filter(
      (dA) => dA.alternativeId == id && dA.mockId == mockId
    )[0];
  }
);
