import styled from "styled-components";

export const Container = styled.section`
  display: flex;
  gap: 20px;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1440px;
  padding: 20px;

  @media(max-width: 768px){
    background-color: #fff;
    padding: 20px;    
  }
`