import CryptoJS from 'crypto-js';

class Doctor {
  checkUp() {
    try {
      const device = this.getDevice();
      const platform = this.getPlatform();
      const userAgent = this.getUserAgent();
      const browser = this.getBrowser();
      const webgl = this.getWebGLFingerprint();
      const canvas = this.getCanvasFingerprint();

      const fingerprint = {
        device,
        platform,
        userAgent,
        browser,
        webgl,
        canvas,
      };

      const hash = this.generateHash(JSON.stringify(fingerprint));

      return hash;
    } catch (e) {
      alert(e, 'error');
      return 'error';
    }
  }

  generateHash(data) {
    const encrypted = CryptoJS.MD5(data);
    return encrypted.toString();
  }

  getUserAgent() {
    const regex = /\(([^)]+)\)/;
    const matches = window.navigator.userAgent.match(regex);
    return matches[1];
  }

  getBrowser() {
    const userAgent = navigator.userAgent;

    if (userAgent.indexOf('Firefox') > -1) {
      return 'Firefox';
    } else if (userAgent.indexOf('Chrome') > -1) {
      return 'Chrome';
    } else if (userAgent.indexOf('Safari') > -1) {
      return 'Safari';
    } else if (userAgent.indexOf('Opera') > -1) {
      return 'Opera';
    } else if (userAgent.indexOf('MSIE') > -1) {
      return 'IE';
    } else {
      return 'Unknown';
    }
  }

  getDevice() {
    const userAgent = navigator.userAgent;

    if (userAgent.indexOf('windows') > -1) {
      return 'windows';
    } else if (userAgent.indexOf('Mac') > -1) {
      return 'Mac';
    } else if (userAgent.indexOf('linux') > -1) {
      return 'linux';
    } else if (userAgent.indexOf('ubuntu') > -1) {
      return 'ubuntu';
    } else if (userAgent.indexOf('Android') > -1) {
      return 'Android';
    } else if (userAgent.indexOf('iPhone') > -1) {
      return 'iPhone';
    } else if (userAgent.indexOf('iPad') > -1) {
      return 'iPad';
    } else if (userAgent.indexOf('Windows Phone') > -1) {
      return 'Windows Phone';
    } else {
      return 'Unknown';
    }
  }

  getPlatform() {
    return navigator.platform;
  }

  getCanvasFingerprint() {
    var result = [];
    var canvas = document.createElement('canvas');
    canvas.width = 2000;
    canvas.height = 200;
    canvas.style.display = 'inline';
    var ctx = canvas.getContext('2d');
    ctx.rect(0, 0, 10, 10);
    ctx.rect(2, 2, 6, 6);
    result.push(
      'canvas winding:' +
        (ctx.isPointInPath(5, 5, 'evenodd') === false ? 'yes' : 'no')
    );

    ctx.textBaseline = 'alphabetic';
    ctx.fillStyle = '#f60';
    ctx.fillRect(125, 1, 62, 20);
    ctx.fillStyle = '#069';
    ctx.font = '11pt no-real-font-123';
    ctx.fillText('Cwm fjordbank glyphs vext quiz, \ud83d\ude03', 2, 15);
    ctx.fillStyle = 'rgba(102, 204, 0, 0.2)';
    ctx.font = '18pt Arial';
    ctx.fillText('Cwm fjordbank glyphs vext quiz, \ud83d\ude03', 4, 45);

    ctx.globalCompositeOperation = 'multiply';
    ctx.fillStyle = 'rgb(255,0,255)';
    ctx.beginPath();
    ctx.arc(50, 50, 50, 0, Math.PI * 2, true);
    ctx.closePath();
    ctx.fill();
    ctx.fillStyle = 'rgb(0,255,255)';
    ctx.beginPath();
    ctx.arc(100, 50, 50, 0, Math.PI * 2, true);
    ctx.closePath();
    ctx.fill();
    ctx.fillStyle = 'rgb(255,255,0)';
    ctx.beginPath();
    ctx.arc(75, 100, 50, 0, Math.PI * 2, true);
    ctx.closePath();
    ctx.fill();
    ctx.fillStyle = 'rgb(255,0,255)';

    ctx.arc(75, 75, 75, 0, Math.PI * 2, true);
    ctx.arc(75, 75, 25, 0, Math.PI * 2, true);
    ctx.fill('evenodd');

    result.push('canvas fp:' + canvas.toDataURL());
    return result.join('~');
  }

  getWebGLFingerprint() {
    var gl;
    var fa2s = function (fa) {
      gl.clearColor(0.0, 0.0, 0.0, 1.0);
      gl.enable(gl.DEPTH_TEST);
      gl.depthFunc(gl.LEQUAL);
      gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
      return '[' + fa[0] + ', ' + fa[1] + ']';
    };
    var maxAnisotropy = function (gl) {
      var anisotropy,
        ext =
          gl.getExtension('EXT_texture_filter_anisotropic') ||
          gl.getExtension('WEBKIT_EXT_texture_filter_anisotropic') ||
          gl.getExtension('MOZ_EXT_texture_filter_anisotropic');
      return ext
        ? ((anisotropy = gl.getParameter(ext.MAX_TEXTURE_MAX_ANISOTROPY_EXT)),
          0 === anisotropy && (anisotropy = 2),
          anisotropy)
        : null;
    };

    var canvas = document.createElement('canvas');
    try {
      gl =
        canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
    } catch (e) {}
    if (!gl) {
      gl = null;
    }

    if (!gl) {
      return null;
    }

    var result = [];
    var vShaderTemplate =
      'attribute vec2 attrVertex;varying vec2 varyinTexCoordinate;uniform vec2 uniformOffset;void main(){varyinTexCoordinate=attrVertex+uniformOffset;gl_Position=vec4(attrVertex,0,1);}';
    var fShaderTemplate =
      'precision mediump float;varying vec2 varyinTexCoordinate;void main() {gl_FragColor=vec4(varyinTexCoordinate,0,1);}';
    var vertexPosBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, vertexPosBuffer);
    var vertices = new Float32Array([
      -0.2, -0.9, 0, 0.4, -0.26, 0, 0, 0.732134444, 0,
    ]);
    gl.bufferData(gl.ARRAY_BUFFER, vertices, gl.STATIC_DRAW);
    vertexPosBuffer.itemSize = 3;
    vertexPosBuffer.numItems = 3;
    var program = gl.createProgram(),
      vshader = gl.createShader(gl.VERTEX_SHADER);
    gl.shaderSource(vshader, vShaderTemplate);
    gl.compileShader(vshader);
    var fshader = gl.createShader(gl.FRAGMENT_SHADER);
    gl.shaderSource(fshader, fShaderTemplate);
    gl.compileShader(fshader);
    gl.attachShader(program, vshader);
    gl.attachShader(program, fshader);
    gl.linkProgram(program);
    gl.useProgram(program);
    program.vertexPosAttrib = gl.getAttribLocation(program, 'attrVertex');
    program.offsetUniform = gl.getUniformLocation(program, 'uniformOffset');
    gl.enableVertexAttribArray(program.vertexPosArray);
    gl.vertexAttribPointer(
      program.vertexPosAttrib,
      vertexPosBuffer.itemSize,
      gl.FLOAT,
      !1,
      0,
      0
    );
    gl.uniform2f(program.offsetUniform, 1, 1);
    gl.drawArrays(gl.TRIANGLE_STRIP, 0, vertexPosBuffer.numItems);
    if (gl.canvas != null) {
      result.push(gl.canvas.toDataURL());
    }
    result.push('extensions:' + gl.getSupportedExtensions().join(';'));
    result.push(
      'webgl aliased line width range:' +
        fa2s(gl.getParameter(gl.ALIASED_LINE_WIDTH_RANGE))
    );
    result.push(
      'webgl aliased point size range:' +
        fa2s(gl.getParameter(gl.ALIASED_POINT_SIZE_RANGE))
    );
    result.push('webgl alpha bits:' + gl.getParameter(gl.ALPHA_BITS));
    result.push(
      'webgl antialiasing:' +
        (gl.getContextAttributes().antialias ? 'yes' : 'no')
    );
    result.push('webgl blue bits:' + gl.getParameter(gl.BLUE_BITS));
    result.push('webgl depth bits:' + gl.getParameter(gl.DEPTH_BITS));
    result.push('webgl green bits:' + gl.getParameter(gl.GREEN_BITS));
    result.push('webgl max anisotropy:' + maxAnisotropy(gl));
    result.push(
      'webgl max combined texture image units:' +
        gl.getParameter(gl.MAX_COMBINED_TEXTURE_IMAGE_UNITS)
    );
    result.push(
      'webgl max cube map texture size:' +
        gl.getParameter(gl.MAX_CUBE_MAP_TEXTURE_SIZE)
    );
    result.push(
      'webgl max fragment uniform vectors:' +
        gl.getParameter(gl.MAX_FRAGMENT_UNIFORM_VECTORS)
    );
    result.push(
      'webgl max render buffer size:' +
        gl.getParameter(gl.MAX_RENDERBUFFER_SIZE)
    );
    result.push(
      'webgl max texture image units:' +
        gl.getParameter(gl.MAX_TEXTURE_IMAGE_UNITS)
    );
    result.push(
      'webgl max texture size:' + gl.getParameter(gl.MAX_TEXTURE_SIZE)
    );
    result.push(
      'webgl max varying vectors:' + gl.getParameter(gl.MAX_VARYING_VECTORS)
    );
    result.push(
      'webgl max vertex attribs:' + gl.getParameter(gl.MAX_VERTEX_ATTRIBS)
    );
    result.push(
      'webgl max vertex texture image units:' +
        gl.getParameter(gl.MAX_VERTEX_TEXTURE_IMAGE_UNITS)
    );
    result.push(
      'webgl max vertex uniform vectors:' +
        gl.getParameter(gl.MAX_VERTEX_UNIFORM_VECTORS)
    );
    result.push(
      'webgl max viewport dims:' + fa2s(gl.getParameter(gl.MAX_VIEWPORT_DIMS))
    );
    result.push('webgl red bits:' + gl.getParameter(gl.RED_BITS));
    result.push('webgl renderer:' + gl.getParameter(gl.RENDERER));
    result.push(
      'webgl shading language version:' +
        gl.getParameter(gl.SHADING_LANGUAGE_VERSION)
    );
    result.push('webgl stencil bits:' + gl.getParameter(gl.STENCIL_BITS));
    result.push('webgl vendor:' + gl.getParameter(gl.VENDOR));
    result.push('webgl version:' + gl.getParameter(gl.VERSION));

    try {
      var extensionDebugRendererInfo = gl.getExtension(
        'WEBGL_debug_renderer_info'
      );
      if (extensionDebugRendererInfo) {
        result.push(
          'webgl unmasked vendor:' +
            gl.getParameter(extensionDebugRendererInfo.UNMASKED_VENDOR_WEBGL)
        );
        result.push(
          'webgl unmasked renderer:' +
            gl.getParameter(extensionDebugRendererInfo.UNMASKED_RENDERER_WEBGL)
        );
      }
    } catch (e) {}

    if (!gl.getShaderPrecisionFormat) {
      return result.join('~');
    }

    result.push(
      'webgl vertex shader high float precision:' +
        gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.HIGH_FLOAT).precision
    );
    result.push(
      'webgl vertex shader high float precision rangeMin:' +
        gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.HIGH_FLOAT).rangeMin
    );
    result.push(
      'webgl vertex shader high float precision rangeMax:' +
        gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.HIGH_FLOAT).rangeMax
    );
    result.push(
      'webgl vertex shader medium float precision:' +
        gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.MEDIUM_FLOAT).precision
    );
    result.push(
      'webgl vertex shader medium float precision rangeMin:' +
        gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.MEDIUM_FLOAT).rangeMin
    );
    result.push(
      'webgl vertex shader medium float precision rangeMax:' +
        gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.MEDIUM_FLOAT).rangeMax
    );
    result.push(
      'webgl vertex shader low float precision:' +
        gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.LOW_FLOAT).precision
    );
    result.push(
      'webgl vertex shader low float precision rangeMin:' +
        gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.LOW_FLOAT).rangeMin
    );
    result.push(
      'webgl vertex shader low float precision rangeMax:' +
        gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.LOW_FLOAT).rangeMax
    );
    result.push(
      'webgl fragment shader high float precision:' +
        gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.HIGH_FLOAT).precision
    );
    result.push(
      'webgl fragment shader high float precision rangeMin:' +
        gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.HIGH_FLOAT).rangeMin
    );
    result.push(
      'webgl fragment shader high float precision rangeMax:' +
        gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.HIGH_FLOAT).rangeMax
    );
    result.push(
      'webgl fragment shader medium float precision:' +
        gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.MEDIUM_FLOAT)
          .precision
    );
    result.push(
      'webgl fragment shader medium float precision rangeMin:' +
        gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.MEDIUM_FLOAT)
          .rangeMin
    );
    result.push(
      'webgl fragment shader medium float precision rangeMax:' +
        gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.MEDIUM_FLOAT)
          .rangeMax
    );
    result.push(
      'webgl fragment shader low float precision:' +
        gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.LOW_FLOAT).precision
    );
    result.push(
      'webgl fragment shader low float precision rangeMin:' +
        gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.LOW_FLOAT).rangeMin
    );
    result.push(
      'webgl fragment shader low float precision rangeMax:' +
        gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.LOW_FLOAT).rangeMax
    );
    result.push(
      'webgl vertex shader high int precision:' +
        gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.HIGH_INT).precision
    );
    result.push(
      'webgl vertex shader high int precision rangeMin:' +
        gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.HIGH_INT).rangeMin
    );
    result.push(
      'webgl vertex shader high int precision rangeMax:' +
        gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.HIGH_INT).rangeMax
    );
    result.push(
      'webgl vertex shader medium int precision:' +
        gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.MEDIUM_INT).precision
    );
    result.push(
      'webgl vertex shader medium int precision rangeMin:' +
        gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.MEDIUM_INT).rangeMin
    );
    result.push(
      'webgl vertex shader medium int precision rangeMax:' +
        gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.MEDIUM_INT).rangeMax
    );
    result.push(
      'webgl vertex shader low int precision:' +
        gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.LOW_INT).precision
    );
    result.push(
      'webgl vertex shader low int precision rangeMin:' +
        gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.LOW_INT).rangeMin
    );
    result.push(
      'webgl vertex shader low int precision rangeMax:' +
        gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.LOW_INT).rangeMax
    );
    result.push(
      'webgl fragment shader high int precision:' +
        gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.HIGH_INT).precision
    );
    result.push(
      'webgl fragment shader high int precision rangeMin:' +
        gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.HIGH_INT).rangeMin
    );
    result.push(
      'webgl fragment shader high int precision rangeMax:' +
        gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.HIGH_INT).rangeMax
    );
    result.push(
      'webgl fragment shader medium int precision:' +
        gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.MEDIUM_INT).precision
    );
    result.push(
      'webgl fragment shader medium int precision rangeMin:' +
        gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.MEDIUM_INT).rangeMin
    );
    result.push(
      'webgl fragment shader medium int precision rangeMax:' +
        gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.MEDIUM_INT).rangeMax
    );
    result.push(
      'webgl fragment shader low int precision:' +
        gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.LOW_INT).precision
    );
    result.push(
      'webgl fragment shader low int precision rangeMin:' +
        gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.LOW_INT).rangeMin
    );
    result.push(
      'webgl fragment shader low int precision rangeMax:' +
        gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.LOW_INT).rangeMax
    );
    return result.join('~');
  }
}

const doctor = new Doctor();
export function generateFingerprint() {
  return doctor.checkUp();
}
