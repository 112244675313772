import React from "react";
import { Grid } from "semantic-ui-react";
import { spacing } from "../library/theme";
import Empty from "./Empty";
import ProeficiencyComparisonCardContainer from "./ProeficiencyComparisonCardContainer";

const container = {
  padding: spacing.m,
};

export default function MyPerformanceSpeciality({ specialities }) {
  return (
    <div style={container}>
      <Grid>
        {specialities?.length ? (
          specialities.map((speciality) => (
            <Grid.Column computer={8} tablet={16}>
              <ProeficiencyComparisonCardContainer
                type="Speciality"
                statitsticKey="value"
                key={`speciality${speciality.id}Report`}
                {...speciality}
              />
            </Grid.Column>
          ))
        ) : (
          <Empty text="Não há dados disponíveis para estatísticas por especialidade." />
        )}
      </Grid>
    </div>
  );
}
