import { connect } from "react-redux";
import Banners from "./Banners";
import { getBannersByType } from "../../selectors/bannerSelectors";

function mapStateToProps(state, ownProps) {
  const { bannerType } = ownProps;

  return {
    banners: getBannersByType(state, bannerType),
  };
}

export default connect(mapStateToProps)(Banners);
