import React from 'react';
import logo from '../../../../assets/images/logo.svg';
import revalidaLogo from '../../../../assets/images/logorevalidav2.svg';
import { getThemeByUniversity } from '../../utils/getThemeByUniversity';
import { ACTIVE_SCREEN } from './enum';
import CodeValidationScreenContainer from './screens/CodeValidationScreen/CodeValidationScreenContainer';
import { DeviceValidated } from './screens/DeviceValidated';
import { Container, Logo } from './styles';
import QRCodeGeneration from './screens/QRCodeGeneration';
import UnauthorizedAccessScreen from './screens/UnauthorizedAccessScreen';

export function SignInSecurity({ location }) {
  const credentials = {
    email: location.state.email,
    password: location.state.password,
  };

  const isRevalida = getThemeByUniversity().IES === 'revalida';

  function checkCurrentStep() {
    const activeScreen = location.state.activeScreen;

    if (activeScreen === ACTIVE_SCREEN.QR_CODE_VALIDATION) {
      return <QRCodeGeneration location={location} />;
    }

    if (activeScreen === ACTIVE_SCREEN.CODE_VALIDATION) {
      return <CodeValidationScreenContainer location={location} />;
    }

    if (activeScreen === ACTIVE_SCREEN.DEVICE_VALIDATED) {
      return <DeviceValidated credentials={credentials} />;
    }

    if (activeScreen === ACTIVE_SCREEN.UNAUTHORIZED_ACCESS) {
      return <UnauthorizedAccessScreen location={location} />;
    }

    /* ----------------- telas da v1 abaixo --------------- */

    // if (activeScreen === ACTIVE_SCREEN.REGISTER_DEVICE) {
    //   return <RegisterDeviceScreenContainer location={location} />;
    // }

    // if (activeScreen === ACTIVE_SCREEN.ALTERNATIVE_ACCESS) {
    //   return <AlternativeAccessScreenContainer location={location} />;
    // }

    // if (activeScreen === ACTIVE_SCREEN.INVALID_FINGERPRINT) {
    //   return <InvalidFingerprint credentials={credentials} />;
    // }

    // if (activeScreen === ACTIVE_SCREEN.REDIRECT_TO_APP) {
    //   return <RedirectToApp credentials={credentials} />;
    // }

    // if (activeScreen === ACTIVE_SCREEN.VALIDATE_BROWSER) {
    //   return (
    //     <ValidateBrowser
    //       credentials={{
    //         email: location.state.credentials.email,
    //         password: location.state.credentials.password
    //       }}
    //     />
    //   );
    // }
  }
  return (
    <Container>
      <Logo src={isRevalida ? revalidaLogo : logo} />
      {checkCurrentStep()}
    </Container>
  );
}
