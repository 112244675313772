import { createSelector } from "reselect";

const getOrganizers = (state) => state.entities.organizers;
const getProps = (_, props) => props;
export const getOrganizerStatistics = (state) => state.organizerStatistics;

export const getAllOrganizers = createSelector(getOrganizers, (organizers) =>
  Object.values(organizers)
);

export const getOrganizerStatisticsSelectedId = createSelector(
  getOrganizerStatistics,
  (organizerStatistics) => organizerStatistics.selectedOrganizerId
);

export const getOrganizerStatisticsSubjects = createSelector(
  getOrganizerStatistics,
  getProps,
  ({ organizers }, id) => {
    if (!id || !organizers) return;
    return organizers[id]?.data;
  }
);

export const getOrganizerById = createSelector(
  getOrganizers,
  getProps,
  (organizers, id) => organizers[id]
);

export const getOrganizersFromStates = createSelector(
  getAllOrganizers,
  getProps,
  (organizers, stateIds) =>
    organizers.filter(
      (organizer) =>
        !stateIds?.find(() => true) || stateIds.includes(organizer.stateId)
    )
);

export const getOrganizersWithMockTemplates = createSelector(
  getOrganizersFromStates,
  (organizers) => organizers.filter((organizer) => organizer?.hasMockTemplates)
);

export const getOrganizersWithSlug = createSelector(
  getAllOrganizers,
  (organanizers) => organanizers.filter((organaizer) => !!organaizer.slug)
);

export const getTotalQuestionsCount = createSelector(
  getOrganizerStatisticsSubjects,
  (data) => data?.reduce((prev, cur) => prev + cur.subject.questionCount, 0)
);

export const getTotalRelevanceInPercentage = createSelector(
  getOrganizerStatisticsSubjects,
  (data) => {
    const relevanceSum = Math.ceil(
      data?.reduce((prev, cur) => prev + cur.subject.relevanceInPercentage, 0)
    );

    return relevanceSum > 100 ? 100 : relevanceSum;
  }
);

export const getTotalAlternativesCount = createSelector(
  getOrganizerStatisticsSubjects,
  (data) =>
    data?.reduce(
      (prev, cur) =>
        cur.user.alternativesCount ? prev + cur.user.alternativesCount : prev,
      0
    )
);
