import styled from 'styled-components';
import { colors } from '../../components/library/theme';
import { EMRButton } from '../Button';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  padding-top: 48px;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  min-height: 100vh;
  @media screen and (max-width: 768px) {
    padding: 24px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 580px;
  min-width: 342px;
  width: 100%;
`;

export const Title = styled.h1`
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  color: ${colors.interface.grey400};
`;

export const Logo = styled.img`
  height: 48px;
`;

export const Illustration = styled.figure`
  display: flex;
  width: fit-content;
  align-self: center;
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Paragraph = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  strong {
    font-weight: 600;
  }
`;

export const WarningContainer = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  gap: 8px;
  padding: 16px 0 16px 16px;
  background-color: #f7fafc;
  border-radius: 4px;
  border: 1px solid #edf0f2;
`;

export const WarningText = styled.span`
  font-weight: 400;
  line-height: 21px;
  font-size: 14px;
`;

export const ButtonContainer = styled.span`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
`;

export const ReturnButton = styled(EMRButton)`
  width: 100%;
  height: 48px;
`;
