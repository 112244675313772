import styled from 'styled-components';
import { colors } from '../../../../components/library/theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-bottom: 40px;
  max-width: 580px;
  button {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
  }
`;

export const CodeWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
export const Logo = styled.img`
  width: 200px;
`;
export const OptionsContainer = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  flex-direction: column;
  gap: 32px;
  a {
    color: ${colors.brand.stepper};
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    transition-duration: 200ms;
  }
  a:hover {
    color: #009a5a;
  }
`;

export const ButtonsContainer = styled.div`
  font-family: Poppins;
  width: 100%;
  display: flex;

  gap: 16px;
  button {
    font-weight: 600;
    font-size: 16px;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 8px;
  }
`;
export const OutlineButton = styled.button`
  width: 100%;
  height: 48px;
  font-family: Poppins;
  border-radius: 4px;
  cursor: pointer;
  line-height: 1;
  padding: 0 30px;
  background-color: transparent;
  border: 1px solid #9ba5ab;
  color: #9ba5ab;
  line-height: 24px;
  transition-duration: 200ms;
  :hover {
    background-color: #9ba5ab;
    color: white;
  }
`;
export const Title = styled.span`
  font-weight: 600;
  font-size: 16px;
  color: ${colors.medtrack.grey500};
`;

export const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  img {
    width: 300px;
  }
`;

export const Info = styled.span`
  font-size: 14px;
  line-height: 21px;
  color: ${colors.medtrack.grey500};

  strong {
    font-weight: 600;
  }
`;

export const ChangeNumber = styled.button`
  width: 100%;
  height: 48px;
  border-radius: 4px;
  background-color: ${colors.medtrack.brand};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ChangeNumberText = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: white;
`;

export const Back = styled.span`
  color: ${colors.medtrack.brand};
  font-size: 16px;
`;

export const Phone = styled.span`
  font-weight: bold;
  color: ${colors.medtrack.grey500};
`;

export const Edit = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;

export const EditButton = styled.button`
  height: 32px;
  width: 32px;
  background-color: ${colors.medtrack.grey100};
  border-radius: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
