import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import LessonMock from "./LessonMock";
import { createMockWithLoading } from "../../processes/mockProccess";
import { getMockFromMockTemplate } from "../../selectors/mockSelectors";
import {
  getCurrentLesson,
  makeIsLessonMock
} from "../../selectors/lessonSelectors";
import { createOrUpdateLessonsUser } from "../../processes/lessonUsersProcesses";
import { makeIsWatched } from "../../selectors/lessonsUserSelectors";
import { fetchMockTemplate } from "../../processes/mockTemplateProccesses";

export const LessonMockContainer = (props) => {
  const {
    dispatch,
    lesson,
    mock,
    currentClassroomId,
    isWatched,
    isLessonMock
  } = props;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await fetchMockTemplate(dispatch, lesson?.mockTemplateId);
      setLoading(false);
    };
    fetchData();
  }, [lesson?.mockTemplateId]);

  useEffect(() => {
    mock?.id &&
      lesson.mockTemplateId &&
      dispatch({ type: "CURRENT_MOCK_CHANGED", payload: { id: mock?.id } });
  }, [dispatch, mock?.id, lesson.mockTemplateId]);

  const createMockHandler = useCallback(
    (setLoading) => {
      !mock?.id &&
        lesson.mockTemplateId &&
        createMockWithLoading(
          {
            mockTemplateId: lesson.mockTemplateId,
            lessonMock: true
          },
          setLoading,
          dispatch
        );
    },
    [dispatch, mock?.id, lesson.mockTemplateId]
  );

  useEffect(() => {
    const isLessonMockComplete = Boolean(mock?.finishedAt) && isWatched;

    if (isLessonMock && !isLessonMockComplete) {
      createOrUpdateLessonsUser(
        dispatch,
        currentClassroomId,
        lesson.id,
        mock?.progress / 100
      );
    }
  }, [dispatch, mock, lesson, currentClassroomId]);

  return (
    <LessonMock
      loading={loading}
      createMockHandler={createMockHandler}
      {...props}
    />
  );
};

function mapStateToProps(state) {
  const lesson = getCurrentLesson(state, state.currentLessonId);

  return {
    lesson,
    mock: getMockFromMockTemplate(state, lesson.mockTemplateId),
    currentClassroomId: state.currentClassroomId,
    isWatched: makeIsWatched(state, { lessonId: lesson?.id }),
    isLessonMock: makeIsLessonMock(state, lesson?.id)
  };
}

export default connect(mapStateToProps)(LessonMockContainer);
