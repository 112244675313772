import React, { useCallback, useEffect } from "react";
import { css } from "glamor";
import { spacing, styles, uiColors } from "../library/theme";
import { Button } from "semantic-ui-react";
import { CardForm, TextAreaField } from "../library/forms";
import Link from "../library/Link";
import { useErrorNotebook } from "../../context/errorNotebook";
import { getMockTemplateById } from "../../selectors/mockTemplateSelectors";
import { getMock } from "../../selectors/mockSelectors";
import { connect } from "react-redux";

const container = css(styles.shadow, styles.roundedCorners, {
  backgroundColor: uiColors.fieldBackground,
  overflow: "hidden",
  marginTop: spacing.xs,
  transition: "height 0.3s",
  padding: spacing.m
});
const titleClass = css({
  display: "block",
  border: "2px solid transparent",
  borderLeftWidth: 0,
  borderTopRightRadius: spacing.xxs,
  borderBottomRightRadius: spacing.xxs,
  transition: "all 0.3s"
});
const valueTextClass = css({
  borderTop: `1px solid ${uiColors.border}`,
  paddingTop: spacing.m,
  marginTop: spacing.m
});
const actions = css({
  borderTop: `1px solid ${uiColors.border}`,
  padding: spacing.m,
  marginTop: spacing.m,
  marginLeft: `-${spacing.m}`,
  marginRight: `-${spacing.m}`,
  marginBottom: `-${spacing.m}`,
  backgroundColor: uiColors.backgroundLightest
});
const actionButtons = css({ display: "flex", justifyContent: "space-between" });
const controls = {
  display: "flex",
  justifyContent: "flex-end",
  marginTop: spacing.m
};

function QuestionsListItem(props) {
  const {
    question,
    toogleValueText,
    showValueText,
    onAnswer,
    answer,
    isPreExam
  } = props;
  const onAnswerRight = useCallback(() => onAnswer(true), [onAnswer]);
  const onAnswerWrongly = useCallback(() => onAnswer(false), [onAnswer]);
  const { markQuestionAnswered } = useErrorNotebook();

  useEffect(() => {
    if (answer) markQuestionAnswered(question.parentId);
  }, []);

  return (
    <div className={container}>
      <p className={titleClass}>{question?.title}</p>
      <CardForm
        onSubmit={props.handleSubmit}
        error={props.error}
        {...props.formMessage}
      >
        <TextAreaField
          label="Sua Resposta"
          name="valueText"
          disabled={answer}
          placeholder="Sua Resposta"
          fluid
        />

        <div className={actions}>
          <div className={actionButtons}>
            {!isPreExam && (
              <Link onClick={toogleValueText} size="tiny">
                {showValueText ? "Ocultar Resposta" : "Exibir resposta"}
              </Link>
            )}
            <Button
              disabled={props.pristine || props.submitting || answer}
              size="tiny"
              onClick={() => markQuestionAnswered(question.parentId)}
            >
              Salvar Resposta
            </Button>
          </div>
          <div>
            {showValueText && !isPreExam && (
              <div className={valueTextClass}>
                <p>{question?.valueText} </p>
              </div>
            )}
          </div>
        </div>
      </CardForm>
      {answer && !isPreExam && (
        <div style={controls}>
          <Button.Group>
            <Button
              onClick={answer?.value === true ? undefined : onAnswerRight}
              color={answer?.value === true ? "green" : undefined}
              disabled={props.submitting || answer?.value === false}
            >
              Acertei
            </Button>
            <Button
              onClick={answer?.value === false ? undefined : onAnswerWrongly}
              color={answer?.value === false ? "red" : undefined}
              disabled={props.submitting || answer?.value === true}
            >
              Errei
            </Button>
          </Button.Group>
        </div>
      )}
    </div>
  );
}

function mapStateToProps(state, ownProps) {
  const mock = getMock(state);
  const mockTemplate = mock && getMockTemplateById(state, mock.mockTemplateId);
  const isPreExam = mockTemplate ? mockTemplate.preExam : false;

  return {
    isPreExam,
    ...ownProps
  };
}

export default connect(mapStateToProps)(QuestionsListItem);
