import styled from "styled-components";
import { EMRButton } from "../../../../Button";

export const ContainerFrialStartMedtrackScreen = styled.section`
  display: flex;
  gap: 20px;
  flex-direction: column;
  margin: 0 auto;
  min-height: calc(100vh - 70px);
`;

export const Container = styled.div`
  background-color: #fff;
  border-radius: 8px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px;
  padding: 105px 0 48px;

  @media (max-width: 991px) {
    padding: 16px;
  }
`;

export const Wrapper = styled.div`
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 720px;
  width: 100%;
`;

export const DetailsBox = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  margin-top: 48px;
  width: 100%;

  @media (max-width: 991px) {
    grid-template-columns: 1fr;
    gap: 24px;
  }
`;

export const Content = styled.div`
  display: flex;
  gap: 16px;

  div {
    width: 32px;
  }

  p {
    font-size: 16px;
    color: #606a71;
  }

  svg {
    width: 32px;
    fill: #9ba5ab;
  }
`;

export const Button = styled(EMRButton)`
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  height: 56px;
  margin-top: 32px;

  svg {
    min-height: 24px;
    min-width: 24px;
  }

  @media (max-width: 991px) {
    height: 40px;
    font-size: 14px;
  }
`;

export const BoxImage = styled.div`
  display: flex;
  border: 1px solid #c2ced6;
  border-radius: 12px;
  position: relative;
  padding: 32px 50px;

  b {
    font-weight: 600;
  }

  svg {
    position: absolute;
    bottom: 0;
    left: 33px;
    width: auto;
  }

  div {
    margin-left: 45%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    h2 {
      font-size: 18px;
      color: #606a71;
      margin-bottom: 0;
    }

    p {
      font-size: 16px;
      color: #606a71;
      line-height: 1.5;
    }
  }

  @media (max-width: 1110px) {
    position: unset;
    flex-direction: column-reverse;
    justify-content: center;
    padding: 24px 26px 0px;

    svg {
      position: unset;
      margin: 0 auto;
    }

    div {
      margin-left: unset;
      display: flex;
      flex-direction: column;
      align-items: center;

      h2 {
        font-size: 16px;
      }

      p {
        text-align: center;
        font-size: 14px;
      }
    }
  }
`;
