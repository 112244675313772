import { getThemeByUniversity } from "../../../utils/getThemeByUniversity";
import { onUserAuth } from "../@functions/onUserAuth";

const { IES } = getThemeByUniversity();
const LOCAL_STORAGE_KEY = "register-data";

function getDataFromLocalStorage() {
  const data = localStorage.getItem(LOCAL_STORAGE_KEY);
  return data ? JSON.parse(data) : null;
}

function saveDataToLocalStorage(data) {
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(data));
}

async function getHealthcareFields() {
  const response = await fetch("/api/v1/healthcare_role_descriptions");

  if (response.status !== 200) {
    throw new Error("Error getting healthcare fields");
  }

  const data = await response.json();

  if (IES !== "revalida") {
    return data
      .filter((exam) => parseInt(exam.id) !== 1)
      .map(({ id, title }) => ({ id, name: title }));
  }

  return data.map(({ id, title }) => ({ id, name: title }));
}

async function getStates() {
  const response = await fetch("/api/v1/all_states");

  if (response.status !== 200) throw new Error("Error getting states");

  const data = await response.json();

  return data.filter((state) => state.name !== "Nacional");
}

async function signUpWithProvider(provider) {
  const userCredential = await onUserAuth(provider);

  if (!userCredential) throw new Error("Error signing up with provider");

  const { user } = userCredential;

  const idToken = await user.getIdToken();

  const response = await fetch(`/api/v1/auth2/${provider}/sign_up`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: idToken
    },
    body: JSON.stringify({
      name: user.displayName,
      email: user.email,
      phone: user.phoneNumber,
      revalida: IES === "revalida" ? "true" : "false"
    })
  });
  const data = await response.json();
  if (data.success === false) throw new Error(data.errors.join("\n"));
  if (response.status !== 200)
    throw new Error("Error signing up with provider");

  return {
    userData: {
      name: user.displayName,
      email: user.email,
      phoneNumber: user.phoneNumber
    },
    token: data.token
  };
}

async function signUpWithProviderStep2({
  provider,
  token,
  email,
  phone,
  healthcare_role_description,
  preferred_exam_state,
  exam_year,
  utm_source,
  utm_campaign,
  utm_medium,
  utm_content,
  utm_term
}) {
  const response = await fetch(`/api/v1/auth2/${provider}/sign_up_step_2`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: token
    },
    body: JSON.stringify({
      email,
      phone,
      healthcare_role_description,
      preferred_exam_state,
      exam_year,
      revalida: IES === "revalida" ? "true" : "false"
    })
  });

  const rdResponse = await fetch("/api/v1/rd_station", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      email,
      phone,
      healthcare_role_description,
      preferred_exam_state,
      exam_year,
      utm_source,
      utm_campaign,
      utm_medium,
      utm_content,
      utm_term
    })
  });

  const activeResponse = await fetch("/api/v1/active_campaign", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      email,
      phone,
      healthcare_role_description,
      preferred_exam_state,
      exam_year,
      utm_source,
      utm_campaign,
      utm_medium,
      utm_content,
      utm_term
    })
  });

  const data = await response.json();
  if (data.success === false) throw new Error(data.errors.join("\n"));
  if (response.status !== 200)
    throw new Error("Error signing up with provider");

  const headers = [...response.headers];
  const client = headers.find((arr) => arr[0] === "client")[1];
  const accessToken = headers.find((arr) => arr[0] === "access-token")[1];
  const uid = headers.find((arr) => arr[0] === "uid")[1];

  const loginData = {
    currentUserId: data.id,
    headers: {
      uid: uid,
      client: client,
      "access-token": accessToken
    }
  };

  return loginData;
}

async function signUpWithEmail({
  name,
  email,
  phone,
  healthcare_role_description,
  preferred_exam_state,
  super_simulado_lp,
  banca_super_simulado,
  exam_year,
  utm_source,
  utm_campaign,
  utm_medium,
  utm_content,
  utm_term
}) {
  const response = await fetch(`/api/v1/auth2/sign_up`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      name,
      email,
      phone,
      super_simulado_lp,
      healthcare_role_description,
      preferred_exam_state,
      exam_year
    })
  });

  const rdResponse = await fetch("/api/v1/rd_station", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      name,
      email,
      phone,
      healthcare_role_description,
      preferred_exam_state,
      exam_year,
      utm_source,
      utm_campaign,
      utm_medium,
      utm_content,
      utm_term,
      banca_super_simulado
    })
  });
  const activeResponse = await fetch("/api/v1/active_campaign", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      name,
      email,
      phone,
      healthcare_role_description,
      preferred_exam_state,
      exam_year,
      utm_source,
      utm_campaign,
      utm_medium,
      utm_content,
      utm_term,
      banca_super_simulado
    })
  });

  const data = await response.json();
  if (data.success === false) throw new Error(data.errors.join("\n"));
  if (response.status !== 200)
    throw new Error("Erro ao se cadastrar com email");
  return { token: data.token };
}

async function signUpWithEmailStep2({ password, token, super_simulado_lp }) {
  const response = await fetch("/api/v1/auth2/sign_up/step_2", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: token
    },
    body: JSON.stringify({
      password,
      revalida: IES === "revalida" ? "true" : "false",
      super_simulado_lp
    })
  });
  const data = await response.json();
  if (data.success === false) throw new Error(data.errors.join("\n"));
  if (response.status !== 200)
    throw new Error("Erro ao se cadastrar com email");
}

async function changeUserPassword({ marketplace_id, email, password }) {
  const response = await fetch("/auth2/change_password", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      marketplace_id,
      email,
      password
    })
  });
  const data = await response.json();
  if (data.success === false) throw new Error(data.errors.join("\n"));
  if (response.status !== 200)
    throw new Error("Erro ao se cadastrar com email");
  return;
}

export {
  getDataFromLocalStorage,
  getHealthcareFields,
  getStates,
  saveDataToLocalStorage,
  signUpWithEmail,
  signUpWithEmailStep2,
  signUpWithProvider,
  signUpWithProviderStep2,
  changeUserPassword
};
