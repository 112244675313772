import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchLessons } from "../../processes/lessonProcesses";
import { fetchLessonsUsers } from "../../processes/lessonUsersProcesses";
import { fetchLevelGroups } from "../../processes/levelGroupProcesses";
import { fetchLevels } from "../../processes/levelProcesses";
import {
  getClassroomById,
  getCurrentClassroomId,
  getFetchedClassrooms,
  getMocksEnabled,
} from "../../selectors/classroomSelectors";
import { getLevelIdByLesson } from "../../selectors/lessonSelectors";
import { getLastUpdatedLessonsUserByClassroom } from "../../selectors/lessonsUserSelectors";
import {
  getLessonsByLevelGroup,
  getLevelGroupByClassroom,
} from "../../selectors/levelGroupSelectors";
import { getLevelById } from "../../selectors/levelSelectors";
import { orderByPosition } from "../../utils/orderByPosition";
import { getUnreadNotificationsCount } from "../Notifications/services";
import { toast } from "../Toast";
import { BannerCarousel } from "./bannerCarousel";
import { BottomBanner } from "./bottomBanner";
import { Provider } from "./context";
import { ContinueWatching } from "./continueWatching";
import { Progress } from "./progress";
import { Shortcuts } from "./shortcuts";
import { Container } from "./styles";

function Home(props) {
  const {
    dispatch,
    lessonsUser,
    levelGroup,
    mocksEnabled,
    classrooms,
    classroomFound,
    isB2BUser,
    isFrialUser,
  } = props;
  const history = useHistory();

  function mountWords(count) {
    if (count === 1) {
      return `${count} nova notificação!`;
    }
    return `${count} novas notificações!`;
  }
  async function getUnreadCount() {
    try {
      const count = await getUnreadNotificationsCount(true);

      if (count > 0) {
        toast.notification(`Você recebeu ${mountWords(count)}`);
      }
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    getUnreadCount();
  }, []);

  useEffect(() => {
    if (isB2BUser) {
      history.push("/classrooms");
    }

    if (classrooms[0]?.id && !classroomFound) {
      fetchLessons(dispatch, classrooms[0]?.id);
      fetchLevelGroups(dispatch, classrooms[0]?.id);
      fetchLevels(dispatch, classrooms[0]?.id);
      fetchLessonsUsers(dispatch, classrooms[0]?.id);
    }
  }, [dispatch, classrooms, isB2BUser]);

  return (
    <Provider
      dispatch={dispatch}
      lessonsUser={lessonsUser}
      levelGroup={levelGroup}
      isFrialUser={isFrialUser}
    >
      <Container>
        <BannerCarousel />
        <Progress />
        <ContinueWatching {...props} />
        {mocksEnabled && <Shortcuts />}
        {mocksEnabled && <BottomBanner />}
      </Container>
    </Provider>
  );
}

function mapStateToProps(state) {
  const lessonsUser = getLastUpdatedLessonsUserByClassroom(state);
  const levelId = getLevelIdByLesson(state, lessonsUser?.lessonId);
  const level = getLevelById(state, levelId);
  const levelGroup = getLevelGroupByClassroom(state, level?.levelGroupId);
  const levelGroups = state.entities.levelGroups;
  const orderedLevelGroups = Array.from(Object.values(levelGroups)).sort(
    orderByPosition
  );
  const firstLevelGroup = orderedLevelGroups[0];
  const lessons = getLessonsByLevelGroup(state, {
    levelGroup: { id: firstLevelGroup?.id },
  });
  const orderedLessons = lessons.sort(orderByPosition);
  const classroomFound = lessonsUser
    ? getClassroomById(state, lessonsUser.classroomId)
    : null;
  const classrooms = getFetchedClassrooms(state);
  const mocksEnabled = getMocksEnabled(state);
  const firstLesson = orderedLessons[0];
  const firstLessonId = firstLesson?.id;
  const isB2BUser = state.userType.isB2B;
  const currentClassroomId = getCurrentClassroomId(state);
  const isFrialUser = state.isFrialUser;
  return {
    classroomFound,
    levelGroups,
    lessonsUser,
    level,
    levelGroup,
    lessons,
    classrooms,
    firstLessonId,
    mocksEnabled,
    isB2BUser,
    currentClassroomId,
    isFrialUser,
  };
}

export default connect(mapStateToProps)(Home);
