import React, { useEffect, useState } from "react";
import { Group, InputContainer, RandomError } from "./styles";
import Autocomplete from "./Autocomplete";
import AutocompleteTags from "./AutocompleteTags";
import { toast } from "../../../../../../componentsV2/Toast";
import { getMedicalResidences, getSpecialties, getStates } from "./utils";

export function FrialMetricsOnboarding({
  chosenResidences,
  setChosenResidences,
  errorStateResidences,
  hasApprovalStatesError,
  setSpecialtyId,
  approvalStates,
  setApprovalStates,
}) {
  const [specialties, setSpecialties] = useState([])
  const [states, setStates] = useState([])
  const [enareResidence, setEnareResidence] = useState()
  const [enareFGVResidence, setEnareFGVResidence] = useState()
  const [inepResidence, setInepResidence] = useState()
  const [aocpResidence, setAocpResidence] = useState()
  const [residences, setResidences] = useState([])

  function formatResidences(residences_) {
    const firstEnareResidence = residences_.find(residence => residence.name.toUpperCase().includes('EXAME NACIONAL DE RESIDÊNCIA'))
    const firstEnareFGVResidence = residences_.find(residence => residence.name.toUpperCase().includes('ENARE-FGV'))
    const firstInepResidence = residences_.find(residence => residence.name.toUpperCase().includes('INEP'))
    const firstAocpResidence = residences_.find(residence => residence.name.toUpperCase().includes('AOCP'))

    firstEnareResidence && setEnareResidence({ ...firstEnareResidence, name: firstEnareResidence.name.slice(4) })
    firstEnareFGVResidence && setEnareFGVResidence({ ...firstEnareFGVResidence, name: firstEnareFGVResidence.name.slice(0) })
    firstInepResidence && setInepResidence({ ...firstInepResidence, name: firstInepResidence.name.slice(4) })
    firstAocpResidence && setAocpResidence({ ...firstAocpResidence, name: firstAocpResidence.name.slice(4) })

    const filteredResidences = residences_.filter(
      residence =>
        !residence.name.toUpperCase().includes('EXAME NACIONAL DE RESIDÊNCIA') &&
        !residence.name.toUpperCase().includes('ENARE-FGV') &&
        !residence.name.toUpperCase().includes('INEP') &&
        !residence.name.toUpperCase().includes('AOCP') &&
        !residence.name.toUpperCase().includes('SUS-PE')
    )

    return filteredResidences
  }

  function filterResidences(states, residences) {
    return states.reduce((acc, state) => {
      const filtered = residences.filter(residence => residence.state.id === state.id)
      acc = acc.concat(filtered)
      return acc
    }, [])
  }

  function handleFilterResidences() {
    const filteredResidences = filterResidences(approvalStates, residences).reduce((acc, residence) => {
      const index = chosenResidences.findIndex(_residence => residence.id === _residence.id)
      index === -1 && acc.push(residence)
      return acc
    }, [])

    const isEnareSelected = chosenResidences.findIndex(residence => residence.name.toUpperCase().includes('EXAME NACIONAL DE RESIDÊNCIA')) < 0 ? false : true
    const isEnareFGVSelected = chosenResidences.findIndex(residence => residence.name.toUpperCase().includes('ENARE-FGV')) < 0 ? false : true
    const isInepSelected = chosenResidences.findIndex(residence => residence.name.toUpperCase().includes('INEP')) < 0 ? false : true
    const isAocpSelected = chosenResidences.findIndex(residence => residence.name.toUpperCase().includes('AOCP')) < 0 ? false : true

    if (!isEnareSelected) {
      enareResidence && filteredResidences.push(enareResidence)
    }

    if (!isEnareFGVSelected) {
      enareFGVResidence && filteredResidences.push(enareFGVResidence)
    }

    if (!isInepSelected) {
      inepResidence && filteredResidences.push(inepResidence)
    }

    if (!isAocpSelected) {
      aocpResidence && filteredResidences.push(aocpResidence)
    }

    if (approvalStates.length === 0) {
      return [...filteredResidences, ...residences]
    }

    return filteredResidences
  }

  function handleSelectSpecialty(selectedSpecialty) {
    const specialtyName = specialties.find(specialty => specialty.name === selectedSpecialty.name)
    specialtyName && setSpecialtyId(specialtyName.id)
  }

  useEffect(() => {
    getSpecialties().then(specialties => {
      setSpecialties(specialties)
    })

    getStates().then(states => {
      setStates(states)
    })

    getMedicalResidences().then(_residences => {
      const formattedResidences = formatResidences(_residences)
      setResidences(formattedResidences)
    })
  }, [])

  return (
    <Group>
      <InputContainer>
        <label htmlFor="intended-specialty">Especialidade pretendida</label>
        <Autocomplete
          onSelect={handleSelectSpecialty}
          name="Especialidade pretendida"
          inputName=""
          placeholder="Selecione a especialidade"
          data={specialties}
          htmlFor="intended-specialty"
          defaultValue={''}
        />
      </InputContainer>

      <InputContainer>
        <label htmlFor="states-where-pass">Estados onde quer passar</label>
        <AutocompleteTags
          className="states-autocomplete"
          onSelectTag={option => {
            const index = approvalStates.findIndex(state => state.id === option.id)
            index === -1 && setApprovalStates([...approvalStates, option])
          }}
          htmlFor="states-where-pass"
          placeholder={'Indique os estados onde quer passar'}
          tags={states.reduce((acc, state) => {
            const index = approvalStates.findIndex((_state) => state.id === _state.id)
            index === -1 && acc.push({ id: state.id, name: state.name })
            return acc
          }, [])}
          selectedTags={approvalStates}
          onDelete={index => {
            const id = approvalStates[index].id
            const temp = [...approvalStates]
            temp.splice(index, 1)
            setApprovalStates(temp)
            const filtered = chosenResidences.filter(residence => residence.state?.id !== id)
            setChosenResidences(filtered)
          }}
          hasValidationError={hasApprovalStatesError}
          errorMessage={'Selecione ao menos um estado'}
        />
      </InputContainer>

      {approvalStates.length > 0 && (
        <InputContainer>
          <label htmlFor="banks-want-pass">Bancas que deseja passar</label>

          <AutocompleteTags
            variant={'complementary'}
            htmlFor="banks-want-pass"
            onSelectTag={option => {
              if (chosenResidences.length < 5) {
                const index = chosenResidences.findIndex(residence => option.id === residence.id)
                index === -1 && setChosenResidences([...chosenResidences, option])
              } else {
                toast.warning('Indique no máximo 5 bancas onde quer passar')
              }
            }}
            placeholder={'Selecione a banca'}
            tags={handleFilterResidences()}
            selectedTags={chosenResidences}
            showNumberingPosition
            onDelete={index => {
              const temp = [...chosenResidences]
              temp.splice(index, 1)
              setChosenResidences(temp)
            }}
            hasValidationError={errorStateResidences}
            className="residences-autocomplete"
            errorMessage={'Informe as bancas que deseja passar'}
          />
        </InputContainer>
      )}
    </Group>
  );
}
