import React, { useEffect, useState } from 'react'
import { ButtonWrapper, SmallText, Text } from '../styles'
import Input from '../../../Input'
import { EMRButton } from '../../../Button'
import Stepper from '../Stepper'
import { getValidator } from "../../validations"
import Template from "../../template"
import * as services from "../../signUp/services"
import { signIn } from '../../../../processes/sessionProcesses'
import { LoadingText } from './styles'
import { setCookie } from 'cookies-next'
import { trackClickEvents } from '../../../../../services/mixpanel/events/trackClickEvents'

export default function RegisterFinalStep() {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    password: "",
    repeatPassword: "",
    marketplace_id: "",
  })

  const [errors, setErrors] = useState({
    password: "",
    repeatPassword: "",
  })

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setData(state => ({
      ...state,
      name: urlParams.get('name'),
      email: urlParams.get('email'),
      phoneNumber: urlParams.get('phone'),
      marketplace_id: urlParams.get('transactioncode')
    }))
  }, [])

  function changeData(field, value) {
    setData((state) => ({ ...state, [field]: value }))
  }

  const validatePassword = (e) => {
    trackClickEvents({eventName:"Clique em Senha (Tela finalizar cadastro)"})
    const pw = e.target.value
    setErrors((state) => ({
      ...state,
      password: validateField("password", pw),
    }))
  }

  const validateRepeatInput = (e) => {
    const pw = e.target.value
    setErrors((state) => ({
      ...state,
      repeatPassword: validateField("repeatPassword", pw, data.password),
    }))
  }

  function validateField(field, ...params) {
    const validator = getValidator(field)
    return validator(...params)
  }

  const isDisabled = !data.password || !data.repeatPassword || errors.password || errors.repeatPassword

  async function handleFinishRegister() {
    trackClickEvents({eventName:"Clique em Acessar plataforma (Tela finalizar cadastro)"})
    try {
      setLoading(true);

      await services.changeUserPassword({
        email: data.email,
        marketplace_id: data.marketplace_id,
        password: data.password,
      });

      await signIn({
        password: data.password,
        email: data.email,
      });

      setCookie(process.env.METRICO_THEME_COOKIE, IES, { domain: process.env.DOMAIN_URL });
      window.location = "/";
    } catch (error) {
      console.log(error);
      alert("não foi possível salvar a senha");
    } finally {
      setLoading(false);
    }
  }

  return (
    <Template>
      <Text>
        Finalize seu cadastro, <strong>{data.name}!</strong>
      </Text>

      <Stepper currentStep="final" />
      <Input
        onBlur={validatePassword}
        value={data.password}
        onChange={(e) => changeData("password", e.target.value)}
        label="Senha*"
        type="password"
        error={errors.password}
      />
      <Input
        onBlur={validateRepeatInput}
        value={data.repeatPassword}
        onChange={(e) => changeData("repeatPassword", e.target.value)}
        label="Confirme sua senha*"
        ispassword={true}
        error={errors.repeatPassword}
      />
      <SmallText>Sua senha deve conter no mínimo 6 caracteres.</SmallText>

      {loading ?
        <LoadingText>Um momento, você será redirecionado...</LoadingText>
        :
        <ButtonWrapper onClick={handleFinishRegister} disabled={isDisabled}>
          <EMRButton fullSized>Acessar plataforma EMR</EMRButton>
        </ButtonWrapper>
      }
    </Template>
  )
}
