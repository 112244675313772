import React, { useContext } from 'react';
import { IoMdCheckmark } from 'react-icons/io';
import { context } from '../context';
import {
  Circle,
  Container,
  Line,
  Bar,
  modeColors,
  StepContent,
} from './styles';

function CircleEl({ mode, label, isCurrentStep }) {
  return (
    <StepContent isCurrentStep={isCurrentStep}>
      <Circle mode={mode}>
        <IoMdCheckmark size={16} fill={modeColors[mode].fill} />
      </Circle>
      <p>{label}</p>
    </StepContent>
  );
}

export function StepsIndicator() {
  const { step } = useContext(context);

  return (
    <>
      <Container>
        <Line>
          <Bar fullSize={step === 1} />
        </Line>
        <CircleEl
          mode={step === 0 ? 'active' : 'done'}
          label="Boas-vindas"
          isCurrentStep={step === 0}
        />
        <CircleEl
          mode={step === 1 ? 'active' : 'disabled'}
          label="Seleção do curso"
          isCurrentStep={step === 1}
        />
      </Container>
    </>
  );
}
