import * as React from "react";
import { colors } from "../../../../../../components/library/theme";

export function HalfProgressIllustration() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={342}
      height={244}
      fill="none"
      className="illustration"
    >
      <path
        stroke="#CCDDE2"
        strokeMiterlimit={10}
        strokeWidth={0.791}
        d="M221.248 227.286c3.669 0 7.237-.17 10.704-.51 23.406-2.242 41.912-11.825 54.219-25.204 6.51-6.973 11.433-15.372 14.407-24.58a61.607 61.607 0 0 0 2.387-27.092c-8.505-62.732-79.93-32.226-49.987-70.38 29.943-38.156-28.675-86.683-51.167-75.198-22.493 11.484 2.312 36.047-53.271 24.435-55.583-11.612-44.081 39.408-44.081 39.408"
      />
      <path
        fill="#2E2E2E"
        d="M71.606 173.144s-25.456-18.957-35.283 2.008c0 0 10.335-38.042 45.588-51.647 0 0 10.076-5.822 10.52-2.306.443 3.517 3.365 5.312 4.644 5.27 1.279-.043 12.075.658 12.45-1.017.375-1.675-.341-6.693 5.013-6.728 5.353-.036 34.403 7.376 42.71 15.21 0 0-14.458 18.128-6.728 38.116l-6.513-1.64s4.017 36.122-1.46 57.133H47.954s9.456 1.236 19.154-5.95l14.083-18.769s6.176-9.717 18.793-11.106c0 0 7.181-.814 7.113-4.359-.068-3.545 2.588 2.27 6.138-2.854.433 0 9.06.517 9.06.517s6.479.63 7.697-5.376c1.217-6.006 5.705-48.163 4.092-50.695-1.613-2.532-2.23-2.564-15.843-2.479-13.613.085-25.575-2.734-27.307 4.299-1.733 7.034-3.734 33.682-3.734 33.682s-7.673 2.086-13.518 10.717c.02-.004-2.076 4.203-2.076-2.026Z"
      />
      <path
        stroke="#000"
        strokeMiterlimit={10}
        strokeWidth={0.791}
        d="M71.596 160.342v16.595M143.576 168.64c15.021 8.286 56.534 25.218 58.007-39.904 0 0-1.787-17.102 2.872-19.333 4.658-2.231 12.17-9.296 2.496-26.4l6.312-6.541a4.894 4.894 0 0 0 1.304-3.409 4.878 4.878 0 0 0-1.398-3.368 4.526 4.526 0 0 0-3.282-1.355 4.535 4.535 0 0 0-3.243 1.452l-12.546 13.812s-1.817-3.542-5.04-1.697c-3.222 1.846-15.041 9.675-9.674 26.026l-13.96 41.646"
      />
      <path
        stroke="#000"
        strokeMiterlimit={10}
        strokeWidth={0.791}
        d="M194.074 83.587s3.345 5.287-3.775 10.692M208.999 87.11s2.083 6.797-6.322 11.18c-2.974 1.552-6.237-1.713-4.832-4.847.024-.046.044-.096.068-.145"
      />
      <path
        stroke="#000"
        strokeMiterlimit={10}
        strokeWidth={0.791}
        d="M211.553 95.504s-1.838 6.636-6.867 6.955c-4.451.28-3.704-4.434-3.704-4.434"
      />
      <path
        fill={colors.brand.pure}
        d="M125.499 91.346c-.341.092-.555-.202-.873-.145-4.559-.864-7.948-4.604-8.33-6.403.129.98 1.862.882 1.568 1.884-.054.191-.163.117-.252.3-.143.355-.14-.13-.341.189-1.118-1.718-1.766-2.572-1.964-3.517a7.356 7.356 0 0 1-.232 3.028 3.303 3.303 0 0 1-.208.556c-.682 1.671-2.104 3.084-4.481 3.435a9.258 9.258 0 0 1-1.797.06c-1.705-.103-3.178-.205-4.433-.262-4.194-.195-5.698.135-5.227 2.947.259 1.271.626 2.517 1.098 3.721.832 2.274 1.411 6.212.559 10.501v.024c-.989 4.994-4.12 8.854-10.827 12.48a31.656 31.656 0 0 1-3.669 1.675c-11.638 4.484-39.478 15.841-51.017 57.487-1.228 4.438-2.145 8.475-2.793 12.161l-.317-.191c-7.88-4.905-13.02-15.908-11.458-25.374.778-4.699 3.724-7.979 7.502-10.447 2.653-1.707 5.49-3.106 7.867-5.213 7.877-6.987 1.58-17.852 5.94-26.206 4.918-9.399 12.618-15.192 22.67-15.67 2.53.113 5.378.421 8.14.283 4.01.149 8.109-.191 11.045-2.922 2.312-2.125 2.793-5.588 3.67-8.478.986-3.258 1.809-6.568 2.465-9.916a28.997 28.997 0 0 1 3.307-8.998 20.462 20.462 0 0 1 7.035-7.317 18.706 18.706 0 0 1 8.249-2.539 16.64 16.64 0 0 1 7.502 2.323c.783.481 1.524 1.033 2.213 1.65 5.077 4.498 7.509 12.112 7.389 18.894Z"
      />
      <path
        stroke="#000"
        strokeMiterlimit={10}
        strokeWidth={0.791}
        d="M202.528 102.063s-1.627 2.096-.737 4.087c.89 1.99 3.1 2.454 5.248 1.583M206.934 83.003l-4.494 4.664M87.214 163.998l3.66-32.758a5.512 5.512 0 0 1 1.719-3.436 5.13 5.13 0 0 1 3.484-1.377h33.602c.735 0 1.462.16 2.134.471a5.24 5.24 0 0 1 1.764 1.332 5.483 5.483 0 0 1 1.088 1.964c.226.726.303 1.494.224 2.253l-4.896 47.203a5.518 5.518 0 0 1-1.71 3.466 5.129 5.129 0 0 1-3.501 1.393h-12.023"
      />
      <path
        fill="#000"
        d="M126.929 134.77c.757 0 1.371-.637 1.371-1.424 0-.786-.614-1.423-1.371-1.423s-1.371.637-1.371 1.423c0 .787.614 1.424 1.371 1.424Z"
      />
      <path
        stroke="#000"
        strokeMiterlimit={10}
        strokeWidth={0.791}
        d="M104.303 171.597c3.546.829 4.256.843 7.696 1.374 2.169.336 7.127.616 7.216-2.879.082-3.071-3.236-4.873-5.579-5.854a31.402 31.402 0 0 0-8.412-2.043 47.406 47.406 0 0 0-3.577-.291c-8.304-.354-17.882 1.732-23.601 8.478-13.248 15.611-30.864 25.647-30.864 25.647"
      />
      <path
        stroke="#000"
        strokeMiterlimit={10}
        strokeWidth={0.791}
        d="M113.8 173.146c1.705.75 4.594 2.351 3.41 5.418-.539 1.416-1.517 1.919-2.861 2.125-2.902.435-6.015-.206-8.866-.826-1.053-.23-2.922-.651-3.962-.949"
      />
      <path
        stroke="#000"
        strokeMiterlimit={10}
        strokeWidth={0.791}
        d="M110.777 180.758s3.1-.039 2.353 2.953c-1.231 4.958-10.796 2.713-15.27 1.672M110.957 118.994c14.574.662 36.784 5.099 56.923 23.192M221.254 227.289l-173.3.269M143.576 161.869s3.41 32.959 0 65.42"
      />
      <path
        fill="#fff"
        d="m294.058 146.814 29.971-79.755c.371-1.543.399-3.154.081-4.71a10.65 10.65 0 0 0-1.914-4.27 10.173 10.173 0 0 0-3.524-2.966 9.772 9.772 0 0 0-4.42-1.061h-119.06c-2.673 0-5.269.934-7.368 2.652-2.1 1.718-3.581 4.121-4.206 6.82l-32.817 79.738a10.854 10.854 0 0 0-.046 4.688 10.658 10.658 0 0 0 1.929 4.239 10.162 10.162 0 0 0 3.517 2.939 9.771 9.771 0 0 0 4.4 1.05h121.907c2.655 0 5.234-.921 7.327-2.618 2.092-1.697 3.579-4.071 4.223-6.746Z"
        opacity={0.54}
      />
      <path
        fill={colors.brand.pure}
        d="m294.058 146.814 29.971-79.755c.371-1.543.399-3.154.081-4.71a10.65 10.65 0 0 0-1.914-4.27 10.173 10.173 0 0 0-3.524-2.966 9.772 9.772 0 0 0-4.42-1.061h-119.06c-2.673 0-5.269.934-7.368 2.652-2.1 1.718-3.581 4.121-4.206 6.82l-32.817 79.738a10.854 10.854 0 0 0-.046 4.688 10.658 10.658 0 0 0 1.929 4.239 10.162 10.162 0 0 0 3.517 2.939 9.771 9.771 0 0 0 4.4 1.05h121.907c2.655 0 5.234-.921 7.327-2.618 2.092-1.697 3.579-4.071 4.223-6.746Z"
      />
      <path
        stroke="#000"
        strokeLinecap="square"
        strokeLinejoin="round"
        strokeWidth={0.791}
        d="m294.058 146.814 29.971-79.755c.371-1.543.399-3.154.081-4.71a10.65 10.65 0 0 0-1.914-4.27 10.173 10.173 0 0 0-3.524-2.966 9.772 9.772 0 0 0-4.42-1.061h-119.06c-2.673 0-5.269.934-7.368 2.652-2.1 1.718-3.581 4.121-4.206 6.82l-32.817 79.738a10.854 10.854 0 0 0-.046 4.688 10.658 10.658 0 0 0 1.929 4.239 10.162 10.162 0 0 0 3.517 2.939 9.771 9.771 0 0 0 4.4 1.05h121.907c2.655 0 5.234-.921 7.327-2.618 2.092-1.697 3.579-4.071 4.223-6.746Z"
      />
      <path
        fill={colors.brand.pure}
        d="m289.675 143.585 27.651-73.58a10.01 10.01 0 0 0 .075-4.343 9.828 9.828 0 0 0-1.765-3.939 9.395 9.395 0 0 0-3.25-2.736 9.013 9.013 0 0 0-4.076-.98H198.464c-2.466 0-4.861.862-6.798 2.447-1.937 1.586-3.303 3.803-3.879 6.294l-30.277 73.561a10.016 10.016 0 0 0-.041 4.325 9.807 9.807 0 0 0 1.78 3.909 9.375 9.375 0 0 0 3.245 2.711 9.02 9.02 0 0 0 4.059.968h112.469a10.728 10.728 0 0 0 6.756-2.417c1.93-1.565 3.302-3.754 3.897-6.22Z"
      />
      <path
        fill="#fff"
        d="M206.392 71.535c1.456-2.27 3.84-3.279 5.323-2.255 1.483 1.023 1.504 3.697.048 5.967a7.011 7.011 0 0 1-.764.977l10.793 23.663c1.705-1.193 3.614-1.487 4.971-.548.877.605 1.364 1.636 1.518 2.833l7.372-2.585 6.449-2.239c.682-.244 1.401-.478 2.104-.708a9.975 9.975 0 0 1 1.094-2.327c2.046-3.187 5.391-4.603 7.471-3.187 2.081 1.417 2.108 5.124.12 8.294l12.276 17.434c2.677-2.28 5.831-2.985 8.017-1.477 2.973 2.054 3.018 7.416.099 11.974-.116.18-.249.329-.372.503l8.184 11.612c.024.035.054.078.075.113.207.324.35.687.422 1.069.071.382.07.774-.004 1.155a3.03 3.03 0 0 1-.43 1.066c-.209.323-.477.6-.789.815a2.822 2.822 0 0 1-1.03.438 2.78 2.78 0 0 1-2.139-.451 2.919 2.919 0 0 1-.784-.82l-7.266-11.385c-2.299 1.385-4.74 1.65-6.53.411-2.844-1.962-2.981-6.934-.447-11.333l-11.768-18.44c-1.736 1.226-3.652 1.537-5.026.592a3.728 3.728 0 0 1-1.457-2.376c-.293.081-.583.17-.876.255l-6.557 1.859-8.392 2.348a9.826 9.826 0 0 1-1.255 2.935c-2.046 3.188-5.388 4.604-7.472 3.17-2.083-1.434-2.114-5.192-.071-8.382a9.69 9.69 0 0 1 1.023-1.3l-10.274-24.127c-1.238.874-2.606 1.1-3.584.424-1.504-1.02-1.528-3.693-.072-5.967Z"
      />
      <path
        fill="#D7E8F0"
        d="M284.548 72.844h15.921c.729 0 1.441-.221 2.051-.635a3.864 3.864 0 0 0 1.383-1.696c.094-.212.143-.442.147-.676a1.751 1.751 0 0 0-.487-1.202 1.62 1.62 0 0 0-1.16-.498h-16.938c.808 1.399.406 3.332-.917 4.707Z"
      />
      <path
        fill={colors.brand.pure}
        d="M274.248 71.147c.001.45.174.882.481 1.2.306.318.722.497 1.155.497h8.658c1.324-1.375 1.726-3.308.931-4.707h-7.645c-.728 0-1.44.22-2.049.631-.61.413-1.091 1-1.385 1.692-.092.217-.142.45-.146.687Z"
      />
      <path
        fill="#fff"
        d="M278.226 72.842c.045.091.098.178.157.259 1.136 1.611 3.598 1.77 5.514.311.227-.173.441-.364.641-.57 1.323-1.374 1.726-3.307.931-4.706a1.687 1.687 0 0 0-.157-.255c-1.125-1.622-3.587-1.77-5.503-.322-.23.176-.448.369-.652.577-1.313 1.377-1.715 3.3-.931 4.706Z"
      />
      <path
        fill={colors.brand.pure}
        d="M270.241 81.523c.004.45.179.882.488 1.2.308.318.725.496 1.159.496h16.709c-.808-1.416-.405-3.329.921-4.703h-15.686c-.728 0-1.441.22-2.051.634a3.87 3.87 0 0 0-1.383 1.696 1.828 1.828 0 0 0-.157.677Z"
      />
      <path
        fill="#D7E8F0"
        d="M288.605 83.22h7.843c.727 0 1.439-.22 2.049-.63a3.864 3.864 0 0 0 1.385-1.69 1.78 1.78 0 0 0 .147-.688c0-.223-.042-.443-.124-.65a1.695 1.695 0 0 0-.354-.55 1.632 1.632 0 0 0-.53-.368 1.57 1.57 0 0 0-.626-.128h-8.869c-1.33 1.374-1.729 3.297-.921 4.703Z"
      />
      <path
        fill="#fff"
        d="M288.605 83.219c.035.092.084.18.143.258 1.129 1.622 3.591 1.77 5.504.322.232-.175.45-.369.651-.58 1.313-1.374 1.705-3.297.931-4.703a1.679 1.679 0 0 0-.153-.259c-1.139-1.611-3.601-1.77-5.514-.311a5.238 5.238 0 0 0-.641.57c-1.33 1.374-1.729 3.297-.921 4.703Z"
      />
      <path
        fill={colors.brand.pure}
        d="M283.033 93.525h10.002c.727 0 1.439-.22 2.049-.632a3.85 3.85 0 0 0 1.384-1.692c.094-.212.145-.443.147-.676a1.747 1.747 0 0 0-.485-1.206c-.308-.32-.726-.5-1.162-.501h-11.004c.784 1.406.382 3.329-.931 4.707Z"
      />
      <path
        fill="#D7E8F0"
        d="M266.825 91.835c.002.45.175.88.482 1.196.307.317.722.494 1.155.493h14.574c1.313-1.377 1.705-3.3.931-4.706h-13.562c-.728 0-1.441.22-2.05.634a3.857 3.857 0 0 0-1.383 1.696 1.78 1.78 0 0 0-.147.687Z"
      />
      <path
        fill="#fff"
        d="M276.724 93.524c.045.09.098.175.157.255 1.125 1.622 3.587 1.771 5.504.323.23-.177.447-.37.651-.578 1.313-1.377 1.705-3.3.931-4.706a1.701 1.701 0 0 0-.157-.259c-1.135-1.61-3.597-1.749-5.514-.311a5.807 5.807 0 0 0-.641.57c-1.323 1.374-1.739 3.308-.931 4.706Z"
      />
      <path
        fill={colors.brand.pure}
        d="M218.374 142.129h10.001a3.646 3.646 0 0 0 2.049-.631c.61-.413 1.091-1 1.385-1.692a1.733 1.733 0 0 0-.117-1.622 1.635 1.635 0 0 0-.597-.566 1.572 1.572 0 0 0-.786-.195h-11.001c.781 1.406.378 3.328-.934 4.706Z"
      />
      <path
        fill="#D7E8F0"
        d="M178.667 140.441c0 .222.043.442.125.647.082.206.203.392.355.549a1.597 1.597 0 0 0 1.153.493h38.073c1.313-1.378 1.705-3.3.931-4.706h-37.057c-.728 0-1.441.22-2.05.634a3.864 3.864 0 0 0-1.384 1.696c-.093.216-.143.45-.146.687Z"
      />
      <path
        fill="#fff"
        d="M212.059 142.13c.045.09.097.175.157.255 1.125 1.625 3.587 1.771 5.503.322.23-.176.448-.369.652-.577 1.312-1.378 1.705-3.301.931-4.706a1.785 1.785 0 0 0-.157-.259c-1.139-1.611-3.601-1.749-5.514-.312a5.774 5.774 0 0 0-.641.571c-1.323 1.374-1.726 3.307-.931 4.706Z"
      />
      <path
        stroke="#000"
        strokeMiterlimit={10}
        strokeWidth={0.791}
        d="M32.844 193.14a25.294 25.294 0 0 1-3.672-1.867c-7.888-4.904-13.027-15.904-11.461-25.373.78-4.7 3.727-7.976 7.519-10.447 2.65-1.707 5.5-3.103 7.867-5.21 7.877-6.987 1.575-17.855 5.936-26.206 5.303-10.128 13.828-16.067 25.06-15.681 5.392.184 12.392 1.47 16.795-2.628 2.312-2.125 2.796-5.588 3.672-8.478a101.658 101.658 0 0 0 2.47-9.916 28.832 28.832 0 0 1 3.286-8.998 20.495 20.495 0 0 1 7.035-7.327c5.456-3.269 13.064-3.57 18.547-.21.781.483 1.52 1.034 2.21 1.647 5.074 4.52 7.502 12.13 7.386 18.915"
      />
      <path
        stroke="#000"
        strokeMiterlimit={10}
        strokeWidth={0.791}
        d="M115.195 83.056c.283 1.998 1.453 3.839 3.53 4.392 2.346.62 3.59 2.992 4.252 5.375a16.47 16.47 0 0 1-.682 10.557c-1.282 3.138-3.567 6.729-7.431 7.292-7.068 1.062-10.588-2.047-10.588-2.047"
      />
      <path
        fill="#000"
        d="M109.303 94.047c.671 0 1.214-.565 1.214-1.261s-.543-1.26-1.214-1.26c-.67 0-1.214.564-1.214 1.26 0 .696.544 1.26 1.214 1.26ZM119.98 93.212c.671 0 1.214-.565 1.214-1.261s-.543-1.26-1.214-1.26c-.67 0-1.213.564-1.213 1.26 0 .697.543 1.26 1.213 1.26Z"
      />
      <path
        stroke="#000"
        strokeMiterlimit={10}
        strokeWidth={0.791}
        d="M106.481 186.79c1.766 1.126.341 2.982-.941 3.608-2.203 1.063-5.051 1.251-7.451 1.548-18.254 2.231-24.348 28.253-35.805 33.091-11.458 4.837-42.206 8.371-27.209-45.734 11.547-41.639 39.386-52.996 51.024-57.48a31.756 31.756 0 0 0 3.663-1.671c6.707-3.619 9.848-7.494 10.833-12.49v-.022c.853-4.288.28-8.226-.556-10.503a21.94 21.94 0 0 1-1.111-3.722c-.621-3.623 2.073-3.113 9.636-2.681 3.472.212 5.436-1.417 6.282-3.492.082-.18.15-.367.201-.56a7.068 7.068 0 0 0 .122-3.626"
      />
      <path
        stroke="#000"
        strokeMiterlimit={10}
        strokeWidth={0.791}
        d="M114.533 93.09s1.275 4.842 1.146 5.557c-.13.715-1.763.163-1.763.163M113.653 102.924c-2.445 1.367-5.272.506-6.377-1.771M95.428 126.469c-1.5-1.625-2.802-4.016-3.658-7.475M109.31 126.427l1.654-7.433 1.797-8.011"
      />
      <path
        fill="#D7E8F0"
        d="M116.06 156.025c.636-3.239-1.084-6.338-3.841-6.921-2.757-.584-5.507 1.569-6.143 4.807-.636 3.239 1.084 6.338 3.841 6.921 2.757.584 5.507-1.569 6.143-4.807Z"
      />
      <path
        stroke="#CCDDE2"
        strokeMiterlimit={10}
        strokeWidth={0.791}
        d="m221.547 208.722-2.796 16.226a1.994 1.994 0 0 1-.65 1.165c-.342.297-.773.46-1.219.461h-14.625c-.45 0-.886-.166-1.23-.468a1.998 1.998 0 0 1-.646-1.183l-2.557-16.201h23.723ZM224.208 206.908c-.04.493-.256.953-.606 1.289-.351.336-.81.523-1.287.524h-25.234c-.47 0-.924-.181-1.274-.508a2.006 2.006 0 0 1-.133-2.778 1.92 1.92 0 0 1 .638-.477c.243-.111.504-.168.769-.168h25.234c.261 0 .519.056.759.165.239.108.454.267.632.466.177.198.313.433.4.689.086.256.121.528.102.798ZM210.493 204.763c0-.832 2.046-11.198.341-20.388-.927-5.043-2.973-9.728-7.092-12.295"
      />
      <path
        stroke="#CCDDE2"
        strokeMiterlimit={10}
        strokeWidth={0.791}
        d="M210.839 184.389s.467-6.984 6.278-7.465M211.459 195.044a8.869 8.869 0 0 1 5.661-1.7M211.464 190.082s-4.221-5.191-10.966-1.083"
      />
      <path
        stroke="#CCDDE2"
        strokeMiterlimit={10}
        strokeWidth={0.791}
        d="M208.49 187.994s-9.507-9.016-11.368 3.187c0 0 2.676 1.81 6.629 0 3.952-1.809 4.739-3.187 4.739-3.187ZM212.939 179.049s-.341-4.059 4.188-5.387c4.528-1.328 7.096 4.229 7.096 4.229s-3.495 4.012-11.284 1.158ZM213.981 193.341s-.156-4.104 5.115-3.258c5.272.847 6.138 5.518 6.138 5.518s-8.634 3.286-11.253-1.828M207.63 175.888c0 .291-3.962-.354-4.215-.446-1.92-.595-3.284-1.976-4.18-3.803-.277-.567-1.876-3.744-1.422-4.296.013 0 9.432-3.152 9.817 8.545ZM300.584 176.99c-2.973 9.209-7.896 17.61-14.407 24.584-12.313 13.379-30.819 22.962-54.219 25.204V176.99h68.626Z"
      />
      <path
        stroke="#CCDDE2"
        strokeMiterlimit={10}
        strokeWidth={0.791}
        d="M286.177 201.574c-12.313 13.38-30.819 22.963-54.219 25.204v-25.204h54.219ZM279.048 189.772c0-1.986-1.55-3.596-3.463-3.596-1.912 0-3.462 1.61-3.462 3.596s1.55 3.597 3.462 3.597c1.913 0 3.463-1.611 3.463-3.597Z"
      />
    </svg>
  );
}
