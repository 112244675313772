import styled from 'styled-components'
import { colors } from '../../../components/library/theme'
import * as Dialog from '@radix-ui/react-dialog'


export const DialogOverlay = styled(Dialog.Overlay)`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: fixed;
  overflow: auto;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
  padding: 0;
`

export const DialogContent = styled(Dialog.Content)`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background-color: ${colors.interface.white000};
  padding: 24px;
  width: min(584px, calc(100vw - 48px));
  margin: auto;
  gap: 24px;
`


export const DialogTitle = styled.h2`
    color: ${colors.interface.grey400};
    font-size: 18px;
    font-weight: 600;
    margin: 0;
`
export const DialogHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const DialogDescription = styled.p`
    color: ${colors.interface.grey400};
    font-size: 16px;
    font-weight: 400;
    line-height: 24px; 
`

export const YoutubeVideo = styled.iframe`
    width: 100%;
    height: 302px;
    border: 0;

    @media(max-width: 460px){
      height: 178px;
     }
`

export const DialogClose = styled(Dialog.Close)`
  cursor: pointer;

  svg {
    color: ${colors.interface.grey400};
  }
`


