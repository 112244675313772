import { mixpanel, mixpanelApi } from "..";
// @ts-check
/**
 * @param {{
 * allowPasswordChange: boolean,
 * avatarUrl: string,
 * email: string,
 * id: number,
 * isAdmin: boolean,
 * name: string,
 * phone: string
 * }} userData
 */

export function trackLoginEvent(userData) {
  const { email, id, isAdmin, name, phone, avatarUrl } = userData;
  const profileData = {
    UserId: id,
    Email: email,
    Name: name,
    IsAdmin: isAdmin,
    Phone: phone,
    AvatarUrl: avatarUrl,
  };

  mixpanel.identify(email);
  mixpanelApi.setProfile(profileData);
}
