import React from "react";
import { useHistory } from "react-router-dom";
import { EMRButton } from "../../../../../../componentsV2/Button";
import { AuthenticationIllustration } from "../../../../assets/Authentication";
import {
  ButtonContainer,
  Content,
  Description,
  Illustration,
  Paragraph,
  ReturnButton,
  Title,
} from "../../../../styles";

export default function WelcomeStep({ advanceStep }) {
  const history = useHistory();

  function handleToSignIn() {
    history.push("/");
  }
  return (
    <>
      <Illustration>
        <AuthenticationIllustration />
      </Illustration>
      <Content>
        <Description>
          <Title>Bem-vindo(a) ao Eu Médico Residente</Title>
          <Paragraph>
            Como forma de garantir maior segurança na sua conta Eu Médico
            Residente pedimos que você cadastre o dispositivo utilizado
            atualmente. Clique em continuar e siga as instruções para realizar
            rapidamente o cadastro.
          </Paragraph>
        </Description>
        <ButtonContainer>
          <EMRButton
            fullSized
            fontSize={"lg"}
            mediumWeight
            onClick={advanceStep}
          >
            Continuar
          </EMRButton>
          <ReturnButton
            fullSized
            fontSize={"lg"}
            mediumWeight
            mode="transparent"
            onClick={handleToSignIn}
          >
            Voltar para o login
          </ReturnButton>
        </ButtonContainer>
      </Content>
    </>
  );
}
