import React from "react";
import { SliderContainer, SliderInput } from "./styles";
import { colors } from "../../../../components/library/theme";

const TRACK_COLOR = colors.brand.pure;
const TRACK_BACKGROUND_COLOR = colors.interface.grey150;
const MIN_AVAILABLE_HOURS = 5;
const MAX_AVAILABLE_HOURS = 60;

function getSliderBackground(availableHours) {
  const valueRange = MAX_AVAILABLE_HOURS - MIN_AVAILABLE_HOURS;
  const gradientValue = (availableHours - MIN_AVAILABLE_HOURS) / valueRange * 100;
  const backgroundColor = `linear-gradient(to right, ${TRACK_COLOR} 0%, ${TRACK_COLOR} ${gradientValue}%, ${TRACK_BACKGROUND_COLOR} ${gradientValue}%, ${TRACK_BACKGROUND_COLOR} 100%)`;

  return backgroundColor;
}

export function Slider({ availableHours, ...rest }) {
  return (
    <SliderContainer>
      <SliderInput
        {...rest}
        style={{ background: getSliderBackground(availableHours) }}
        type="range"
        min={MIN_AVAILABLE_HOURS}
        max={MAX_AVAILABLE_HOURS}
        step={1}
        defaultValue={availableHours}
      />

      <span>{availableHours}&nbsp;horas</span>
    </SliderContainer>
  );
}
