import React from "react";
import { connect } from "react-redux";
import AnnotationListItem from "./AnnotationListItem";
import { deleteAnnotation } from "../../processes/annotationProcesses";

function AnnotationListItemContainer(props) {
  const { dispatch, annotation } = props;
  const deleteHandler = React.useCallback(
    () => deleteAnnotation(dispatch, annotation),
    [dispatch, annotation]
  );
  return <AnnotationListItem deleteHandler={deleteHandler} {...props} />;
}

function mapStateToProps(_, ownProps) {
  return {
    annotation: ownProps.annotation,
  };
}

export default connect(mapStateToProps)(AnnotationListItemContainer);
