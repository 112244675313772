import { getCookie } from "cookies-next";
import React, { useEffect, useState } from "react";
import { apiGet } from "../../../processes/helpers/api";
import { EMRButton } from "../../Button";
import android from "../android.svg";
import web from "../computer.svg";
import confirmedandroid from "../confirmedandroid.svg";
import confirmedios from "../confirmedios.svg";
import confirmedweb from "../confirmedweb.svg";
import greenellipse from "../ellipsegreen.svg";
import ios from "../ios.svg";
import redellipse from "../redellipse.svg";
import warning from "../warning.svg";
import {
  ButtonsContainer,
  ContentContainer,
  Description,
  DeviceContainer,
  DeviceInfoContainer,
  DeviceTitleContainer,
  WarningContainer,
} from "./styles";

const platformImages = {
  WEB: {
    confirmed: confirmedweb,
    unconfirmed: web,
  },
  ANDROID: {
    confirmed: confirmedandroid,
    unconfirmed: android,
  },
  IOS: {
    confirmed: confirmedios,
    unconfirmed: ios,
  },
};
export function FirstStep({ advanceStep, isLoading }) {
  const email = getCookie("email");
  const [currentDevice, setCurrentDevice] = useState({
    userAgent: "",
    location: "",
  });

  const [currentFingerprint, setCurrentFingerprint] = useState("fingerprint");
  const [fingerprints, setFingerprints] = useState([]);

  function checkPlatformImage(platform, confirmed = false) {
    const toLowerCasePlatform = platform.toLowerCase();

    if (toLowerCasePlatform.includes("android")) {
      return confirmed
        ? platformImages.ANDROID.confirmed
        : platformImages.ANDROID.unconfirmed;
    }
    if (toLowerCasePlatform.includes("ios")) {
      return confirmed
        ? platformImages.IOS.confirmed
        : platformImages.IOS.unconfirmed;
    }
    if (
      toLowerCasePlatform.includes("windows") ||
      toLowerCasePlatform.includes("linux")
    ) {
      return confirmed
        ? platformImages.WEB.confirmed
        : platformImages.WEB.unconfirmed;
    }
  }
  const parseUserAgent = (userAgent) => {
    let operatingSystem = "Unknown";

    if (userAgent.match(/Windows/i)) {
      operatingSystem = "Windows";
    } else if (userAgent.match(/Android/i)) {
      operatingSystem = "Android";
    } else if (userAgent.match(/iPad|iPhone|iPod/i)) {
      operatingSystem = "iOS";
    } else if (userAgent.match(/Mac/i)) {
      operatingSystem = "Mac OS";
    } else if (userAgent.match(/Linux/i)) {
      operatingSystem = "Linux";
    }

    return operatingSystem;
  };
  async function getFingerprints() {
    const response = await apiGet(`${process.env.USER_PROXY_URL}/devices`)
      .set("api-token", process.env.USER_PROXY_API_TOKEN)
      .query(`email=${email}`);
    setFingerprints(response.body);
  }
  useEffect(() => {
    getFingerprints();
    const userAgent = navigator.userAgent;
    const language = navigator.language;
    setCurrentDevice({ userAgent, language });
  }, []);
  return (
    <ContentContainer>
      <DeviceContainer>
        <img src={checkPlatformImage(currentDevice?.userAgent)} />
        <DeviceInfoContainer>
          <DeviceTitleContainer>
            <span>{parseUserAgent(currentDevice?.userAgent)}</span>
            <img src={greenellipse} />
          </DeviceTitleContainer>
          <h1>Local não identificado</h1>
          <h2>Conectado agora</h2>
        </DeviceInfoContainer>
      </DeviceContainer>
      {fingerprints?.map((fingerprint, index) => {
        return (
          <DeviceContainer key={index}>
            <img src={checkPlatformImage(fingerprint.os, true)} />
            <DeviceInfoContainer>
              <DeviceTitleContainer>
                <span>{parseUserAgent(fingerprint.os)}</span>
                <img src={redellipse} />
              </DeviceTitleContainer>
              <h1>{fingerprint.location}</h1>
              <h2>Conectado há {fingerprint.daysSinceLastSignIn} dias</h2>
            </DeviceInfoContainer>
          </DeviceContainer>
        );
      })}
      <Description>
        <h1>Bem-vindo(a) ao Eu Médico Residente</h1>
        <h2>
          Para continuar usando a plataforma, solicitamos que você cadastre o
          dispositivo utilizado atualmente. Dessa forma, você garante maior
          segurança na sua conta Eu Médico Residente e pode seguir estudando com
          tranquilidade.
        </h2>
      </Description>
      <WarningContainer>
        <img src={warning} />
        <span>
          Você só poderá acessar através dos dispositivos cadastrados.
        </span>
      </WarningContainer>
      <ButtonsContainer>
        <EMRButton
          fullSized
          loading={isLoading}
          onClick={() =>
            advanceStep({ email, fingerprint: currentFingerprint })
          }
        >
          Cadastrar
        </EMRButton>
        <a>Voltar para o login</a>
      </ButtonsContainer>
    </ContentContainer>
  );
}
