import styled from 'styled-components';
import { colors } from '../../../../components/library/theme';

export const Radio = styled.button`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  background: none;
  border: none;
  color: ${colors.medtrack.grey500};
  font-size: 14px;
  cursor: pointer;
`;

export const Wrapper = styled.div`
  display: flex;
  gap: 36px;
  align-items: center;
  justify-content: flex-start;
`;
