import * as React from "react";

export function AngryFace() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8931_1135)">
        <path
          d="M38.4263 12.2175C36.4964 7.63995 32.9346 3.94273 28.4322 1.84348C23.9298 -0.255763 18.808 -0.607273 14.061 0.857185C9.31406 2.32164 5.28051 5.4976 2.74335 9.76857C0.206183 14.0395 -0.653591 19.1009 0.330879 23.9701C1.31535 28.8393 4.07385 33.169 8.07094 36.1189C12.068 39.0688 17.0186 40.4283 21.9616 39.9336C26.9047 39.4389 31.4876 37.1253 34.8208 33.4418C38.1541 29.7583 39.9998 24.9678 39.9998 20C40.0023 17.3266 39.4672 14.68 38.4263 12.2175ZM19.9998 37.6669C10.2583 37.6669 2.33299 29.7415 2.33299 20C2.33299 10.2586 10.2583 2.33323 19.9998 2.33323C29.7413 2.33323 37.6666 10.2586 37.6666 20C37.6666 29.7415 29.7413 37.6669 19.9998 37.6669Z"
          fill="#606A71"
        />
        <path
          d="M12.1327 30.7326C11.9021 30.7329 11.6766 30.6648 11.4848 30.5369C11.2929 30.4091 11.1432 30.2273 11.0545 30.0145C10.9659 29.8016 10.9423 29.5673 10.9868 29.341C11.0313 29.1148 11.1418 28.9068 11.3044 28.7434C11.4561 28.5908 15.0711 24.9981 20.0249 24.8703C23.1555 24.79 26.0729 26.0929 28.6954 28.7434C28.8061 28.8516 28.8942 28.9808 28.9544 29.1234C29.0147 29.266 29.0459 29.4192 29.0464 29.574C29.0468 29.7289 29.0164 29.8822 28.957 30.0252C28.8976 30.1681 28.8103 30.2978 28.7002 30.4067C28.5902 30.5156 28.4595 30.6015 28.3159 30.6594C28.1724 30.7173 28.0187 30.746 27.8639 30.7439C27.7091 30.7418 27.5562 30.7089 27.4143 30.6471C27.2723 30.5853 27.1441 30.4959 27.037 30.3841C24.8854 28.2095 22.548 27.1381 20.0841 27.2021C16.0851 27.3052 12.9913 30.3561 12.9591 30.3869C12.851 30.4962 12.7223 30.5831 12.5805 30.6425C12.4386 30.7018 12.2864 30.7325 12.1327 30.7326Z"
          fill="#606A71"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.735 11.9688C24.5586 11.9688 23.6034 13.5749 23.6034 15.5534C23.6034 17.532 24.5586 19.1377 25.735 19.1377C26.9114 19.1377 27.8661 17.5315 27.8661 15.5534C27.8661 13.5754 26.9114 11.9688 25.735 11.9688ZM14.2068 11.9688C13.0299 11.9688 12.0752 13.5749 12.0752 15.5534C12.0752 17.532 13.0299 19.1377 14.2068 19.1377C15.3836 19.1377 16.3379 17.5315 16.3379 15.5534C16.3379 13.5754 15.3827 11.9688 14.2068 11.9688Z"
          fill="#606A71"
        />
        <path
          d="M16.337 11.6939C16.1932 11.6937 16.0514 11.6604 15.9227 11.5964L11.6604 9.47923C11.5507 9.42472 11.4527 9.34912 11.3721 9.25675C11.2915 9.16438 11.23 9.05705 11.1909 8.94088C11.1518 8.82471 11.136 8.70197 11.1443 8.57969C11.1527 8.4574 11.185 8.33796 11.2395 8.22818C11.294 8.1184 11.3696 8.02043 11.462 7.93986C11.5544 7.85929 11.6617 7.79771 11.7779 7.75863C11.8941 7.71954 12.0168 7.70372 12.1391 7.71207C12.2614 7.72041 12.3808 7.75277 12.4906 7.80727L16.7528 9.9244C16.9415 10.0179 17.0931 10.1726 17.1828 10.3631C17.2725 10.5537 17.2951 10.769 17.2469 10.9741C17.1988 11.1791 17.0826 11.3619 16.9174 11.4925C16.7522 11.6232 16.5477 11.6942 16.337 11.6939ZM23.6044 11.6939C23.3938 11.6942 23.1892 11.6232 23.0241 11.4925C22.8589 11.3619 22.7427 11.1791 22.6946 10.9741C22.6464 10.769 22.669 10.5537 22.7587 10.3631C22.8484 10.1726 22.9999 10.0179 23.1887 9.9244L27.4514 7.80727C27.6731 7.69719 27.9294 7.67969 28.1641 7.75863C28.3987 7.83756 28.5923 8.00646 28.7024 8.22818C28.8125 8.44989 28.83 8.70626 28.7511 8.94088C28.6721 9.17549 28.5032 9.36915 28.2815 9.47923L24.0188 11.5964C23.89 11.6604 23.7482 11.6937 23.6044 11.6939Z"
          fill="#606A71"
        />
      </g>
      <defs>
        <clipPath id="clip0_8931_1135">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
