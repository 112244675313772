import React from 'react';
import Illustration from '../../components/classrooms/goalsModal/Illustration';
import { colors, styles } from '../library/theme';

const wrapperStyles = {
  display: 'block',
  fontSize: styles.bodyTextLarge.fontSize,
  height: '100%',
  width: '100%',
  margin: '160px auto',
};

const containerStyles = {
  maxWidth: 500,
  margin: '40px auto 0',
};

const paragraphStyles = {
  fontWeight: 500,
  fontSize: 16,
  color: colors.interface.grey300,
  lineHeight: 1.5,
};

const secondParagraphStyles = {
  marginTop: 32,
};

const illustrationStyle = {
  width: 198,
  height: 137,
  margin: 'auto',
};

const Empty = (props) => {
  function handlePlaceholderText() {
    switch (props.activeFilter) {
      case 'IN_PROGRESS':
        return 'Você ainda não tem nenhum simulado em andamento.';
      case 'DONE':
        return 'Você ainda não finalizou nenhum simulado inteligente.';
      case 'PENDING':
        return 'Você não possui nenhum simulado pendente.';
      default:
        return 'Para começar seu estudo direcionado, crie seu primeiro Simulado Inteligente.';
    }
  }

  return (
    <div style={wrapperStyles}>
      <div style={illustrationStyle}>
        <Illustration />
      </div>
      <div style={containerStyles}>
        <p style={paragraphStyles}>
          Os Simulados Inteligentes são criados pela plataforma de maneira
          personalizada, selecionando questões dos temas que são mais relevantes
          para suas bancas prioritárias e que você tem maior dificuldade, de
          acordo com seu desempenho.{' '}
        </p>
        <p style={{ ...paragraphStyles, ...secondParagraphStyles }}>
          {handlePlaceholderText()}
        </p>
      </div>
    </div>
  );
};
export default Empty;
