import styled from 'styled-components'

export const Container = styled.div`
  p {
    line-height: 2.4rem;
  }
`
export const CheckboxLabel = styled.label`
  display: flex;
  flex: auto;
  align-items: center;
  justify-content: flex-start;
  gap: 32px;
  color: ${props => props.checked ? '#363E46' : '#C2CED6'};
  height: 48px;
  width: 100%;
  border: 0.1rem solid #c2ced6;
  border-radius: 4px;
  user-select: none;
  cursor: pointer;
  padding: 0 8px;
`
