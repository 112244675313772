import React, { useCallback } from "react";
import { connect } from "react-redux";
import { goToIndex } from "../../processes/questionProccess";
import {
  makeClosedQuestionStatus,
  makeOpenQuestionStatus,
  getAnswer,
  makeOpenQuestionAnswered
} from "../../selectors/answerSelector";
import { getMock, getMockPreferences } from "../../selectors/mockSelectors";
import { getMockTemplateById } from "../../selectors/mockTemplateSelectors";
import { getQuestionByIndex } from "../../selectors/questionSelectors";
import QuestionPaginationItem from "./QuestionPaginationItem";

function QuestionPaginationItemContainer(props) {
  const { dispatch, index, answered } = props;

  const goToIndexHandler = useCallback(
    () => goToIndex(dispatch, index),
    [dispatch, index]
  );

  return (
    <QuestionPaginationItem
      {...props}
      answered={answered}
      goToIndexHandler={goToIndexHandler}
    />
  );
}

function mapStateToProps(state, ownProps) {
  const question = getQuestionByIndex(state, ownProps.index);
  const mock = getMock(state);
  const { isFinished, isExam } = ownProps;

  const status =
    question?.questionType === "alternatives"
      ? makeClosedQuestionStatus(state, {
          mockId: mock?.id,
          questionId: question?.id
        })
      : makeOpenQuestionStatus(state, {
          mockId: mock?.id,
          questionId: question?.id
        });

  const answerParams = {
    mockId: mock?.id,
    questionId: question?.id
  };

  const answered =
    question?.questionType === "alternatives"
      ? getAnswer(state, answerParams)
      : makeOpenQuestionAnswered(state, answerParams);

  const { showCorrectAnswer } = getMockPreferences(state);
  const mockTemplate = getMockTemplateById(state, mock.mockTemplateId);
  const isPreExam = mockTemplate ? mockTemplate.preExam : false;

  return {
    answered,
    status: answered && question?.cancelled ? "green" : status,
    currentIndex: state.mock.currentIndex,
    showAnswer: isPreExam ? false : isFinished || (!isExam && !!status),
    showCorrectAnswer
  };
}

export default connect(mapStateToProps)(QuestionPaginationItemContainer);
