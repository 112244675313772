import styled from "styled-components";

export const RightAnswerBadge = styled.div`
  background-color: #35bd78;
  display: flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 15px;
  width: 138px;
  height: 29px;
  margin-top: 16px;
`;
export const CheckIconContainer = styled.div`
  height: 20px;
  margin-right: 8px;
  margin-top: 1px;
  width: 20px;
`;

export const RightAnswerText = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 1.5;
  color: #ffffff;
`;
