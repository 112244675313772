import React, { useEffect, useRef } from 'react';
import {
  IoCreateOutline,
  IoEllipsisVertical,
  IoTrashOutline,
} from 'react-icons/io5';
import { colors } from '../../components/library/theme';
import { Button, Container, MoreButton, Wrapper } from './styles';
import { PinIcon } from '../Notifications/icons';

export function Popover({
  onEdit,
  onDelete,
  onPin,
  editText = 'Editar anotação',
  deleteText = 'Excluir',
  pinText = 'Fixar notificação',
}) {
  const ref = useRef(null);
  const show = () => (ref.current.style.display = 'flex');
  const hide = () => (ref.current.style.display = 'none');

  function handleEdit() {
    if (onEdit) {
      onEdit();
      hide();
      return;
    }

    onPin();
    hide();
  }

  function handleDelete() {
    onDelete();
    hide();
  }

  useEffect(() => {
    function onClick(e) {
      if (ref.current && !ref.current.contains(e.target)) hide();
    }

    document.addEventListener('click', onClick, true);
    return () => {
      document.removeEventListener('click', onClick, true);
    };
  }, []);

  return (
    <Wrapper>
      <MoreButton onClick={show}>
        <IoEllipsisVertical
          fill={colors.interface.grey300}
          size={onPin ? 16 : 24}
        />
      </MoreButton>

      <Container ref={ref} handlePin={onPin}>
        <Button handlePin={onPin} onClick={handleEdit}>
          {onEdit ? (
            <>
              <IoCreateOutline size={24} />
              {editText}
            </>
          ) : (
            <>
              <PinIcon />
              {pinText}
            </>
          )}
        </Button>

        {onPin && <hr />}
        <Button handlePin={onPin} onClick={handleDelete}>
          <IoTrashOutline
            size={onEdit ? 24 : 16}
            color={onEdit ? colors.interface.grey400 : colors.interface.grey500}
          />
          {deleteText}
        </Button>
      </Container>
    </Wrapper>
  );
}
