import * as React from "react";

export function PinIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={12} height={16} fill="none">
      <path
        fill="#606A71"
        d="M1.558 1.583c0-.507.397-.916.889-.916h7.11c.492 0 .89.41.89.916a.902.902 0 0 1-.89.917h-.819l.317 4.245a4.779 4.779 0 0 1 2.208 2.713l.028.086a.934.934 0 0 1-.122.825.885.885 0 0 1-.722.381h-8.89a.882.882 0 0 1-.721-.38.929.929 0 0 1-.123-.826l.028-.086A4.779 4.779 0 0 1 2.95 6.745L3.266 2.5h-.82a.902.902 0 0 1-.888-.917Zm3.555 10.084h1.778v2.75a.902.902 0 0 1-.889.916.902.902 0 0 1-.889-.916v-2.75Z"
      />
    </svg>
  );
}
