import React from "react";
import Page from "../library/Page";
import NotificationListItemContainer from "./NotificationListItemContainer";
import Spinner from "../library/Spinner";
import { spacing, uiColors } from "../library/theme";

const emptyStyle = {
  textAlign: "center",
};

const notificationWrapper = {
  paddingBottom: spacing.m,
  borderBottom: `1px solid ${uiColors.border}`,
  marginBottom: spacing.m,
};

export default function NotificationsPage(props) {
  const { notification, notifications, loading } = props;

  if (loading) {
    return <Spinner />;
  }

  return (
    <Page title="Notificações">
      {notification && (
        <div style={notificationWrapper}>
          <NotificationListItemContainer
            key={`notification${notification.id}`}
            notification={notification}
            selected
            full
          />
        </div>
      )}
      {notifications.length ? (
        notifications.map((item) => (
          <NotificationListItemContainer
            key={`notification${item.id}`}
            notification={item}
            full
          />
        ))
      ) : (
        <div style={emptyStyle}>Nenhuma notificação por aqui</div>
      )}
    </Page>
  );
}
