import React from "react";
import ReactDom from "react-dom";
import { insertToastContainer } from "./insertToastContainer";
import { ToastBody } from "./toastBody";

function removeToast(el) {
  const t = el.firstChild;
  t.classList.remove("toastAppear");
  t.classList.add("toastRemove");
  setTimeout(() => el.remove(), 500);
}

function insertToast(children, variant = "success") {
  const el = document.createElement("div");
  ReactDom.render(
    <ToastBody onClose={() => removeToast(el)} variant={variant}>
      {children}
    </ToastBody>,
    el
  );

  insertToastContainer().prepend(el);

  setTimeout(() => {
    removeToast(el);
  }, 5000);
}

export const toast = {
  success: (children) => insertToast(children, "success"),
  warning: (children) => insertToast(children, "warning"),
  error: (children) => insertToast(children, "error"),
  notification: (children) => insertToast(children, "notification"),
};
