const initialState = {
  isModalVisible: false,
  isBypassEnabled: false,
}

export default function onlyEnrolledStudentsModalReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case "SHOW_ONLY_ENROLLED_STUDENTS_MODAL":
      return {
        ...state,
        isModalVisible: true,
      }
    case "HIDE_ONLY_ENROLLED_STUDENTS_MODAL":
      return {
        ...state,
        isModalVisible: false,
      }
    case "ENABLE_MODAL_BYPASS":
      return {
        ...state,
        isBypassEnabled: true,
      }
    case "DISABLE_MODAL_BYPASS":
      return {
        ...state,
        isBypassEnabled: false,
      }
    default:
      return state;
  }
}
