import React from "react";
import Image from "../../library/Image";

const DESKTOP_BREAKPOINT = 991;

export function getRangePosition(range) {
  const screenWidth = window.innerWidth;
  const isDesktopScreen = screenWidth > DESKTOP_BREAKPOINT;
  const TOOLTIP_HEIGHT_OFFSET = isDesktopScreen ? 54 : 12;
  const rangeRect = range.getBoundingClientRect();
  const topPosition = isDesktopScreen
    ? rangeRect.top - TOOLTIP_HEIGHT_OFFSET
    : rangeRect.bottom + TOOLTIP_HEIGHT_OFFSET;

  return {
    top: topPosition,
    left: (rangeRect.left + rangeRect.right) / 2,
    width: rangeRect.width,
  };
}

export function getHighlightPosition(highlightNodes) {
  const screenWidth = window.innerWidth;
  const isDesktopScreen = screenWidth > DESKTOP_BREAKPOINT;
  const TOOLTIP_HEIGHT_OFFSET = screenWidth > DESKTOP_BREAKPOINT ? 54 : 12;
  const firstDom = highlightNodes[0].getBoundingClientRect();
  const lastDom =
    highlightNodes[highlightNodes.length - 1].getBoundingClientRect();
  const topPosition = isDesktopScreen
    ? firstDom.top - TOOLTIP_HEIGHT_OFFSET
    : lastDom.bottom + TOOLTIP_HEIGHT_OFFSET;

  const position = {
    top: topPosition,
    left: ((firstDom.left + lastDom.right) / 2),
    width: firstDom.width,
  };

  return position;
}

export function transform(node, index) {
  if (node.type === "tag" && node.name === "img") {
    return (
      <Image
        src={node.attribs?.src}
        height={node.attribs?.height}
        width={node.attribs?.width}
        alt={node.attribs?.alt}
        key={index}
        preview
      />
    );
  }
}
