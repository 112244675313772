import styled from 'styled-components';
import { Spinner } from '../../../Spinner';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 108px;
  padding: 24px 24px 0;
`;

export const Logo = styled.img`
  height: 48px;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: #606a71;

  h1 {
    font-size: 16px;
    font-weight: 600;
  }

  span {
    font-size: 14px;
  }
`;

export const StyledSpinner = styled(Spinner)`
  width: 94px;
  height: 94px;
`;
