import React from "react";
import { IoNotifications } from "react-icons/io5";
import { NotificationsInfo, NotifierIconContainer } from "./styles";

/**
 * NotifierIcon Component
 * @param {Object} props - The props of the component
 * @param {boolean} props.isActived
 * @param {boolean} props.hasNewNotifications
 * @param {function} props.onClick
 *
 * @returns {JSX.Element} - The NotifierIcon component
 */

export function NotifierIcon({ isActived, hasNewNotifications, onClick }) {
  return (
    <NotifierIconContainer
      onClick={onClick}
      isActived={isActived}
      id="notifier-icon"
    >
      <IoNotifications size={24} color="#fff" />
      {hasNewNotifications && (
        <NotificationsInfo id="notifications-info" isActived={isActived} />
      )}
    </NotifierIconContainer>
  );
}
