import React from "react";
import { SelectedAlternativeContainer } from "./styles";

export function SelectedAlternative({ alternative }) {
  return (
    <SelectedAlternativeContainer
      styleType={alternative.type}
      letter={alternative.letter}
      key={alternative.id}
      customColor={alternative.customColor}
    >
      {alternative.title}
    </SelectedAlternativeContainer>
  );
}
