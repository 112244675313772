import { YEARS } from "./signUp/consts";

function name(value) {
  const isValid = value.trim().length >= 1;
  return isValid ? "" : "Nome inválido";
}

function email(value) {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const isValid = emailRegex.test(value);
  return isValid ? "" : "E-mail inválido";
}

function phoneNumber(value, ies) {
  const numberRegex =
    ies === "revalida"
      ? /^\+?[1-9]\d{1,14}$/
      : /^\(?\d{2}\)?[\s-]?\d{4,5}[\s-]?\d{4}$/;
  const isValid = numberRegex.test(value);
  return isValid ? "" : "Número inválido";
}

function healthCareField(validNames, value) {
  const isValid = validNames.includes(value);
  return isValid ? "" : "Selecione uma opção";
}

function stateSelected(validStates, value) {
  const isValid = validStates.includes(value);
  return isValid ? "" : "Selecione uma opção";
}

function yearSelected(value) {
  const isValid = YEARS.includes(value);
  return isValid ? "" : "Selecione uma opção";
}

function password(value) {
  const isValid = value.length >= 6;
  return isValid ? "" : "A senha é muito curta";
}

function repeatPassword(value, password) {
  const isValid = value === password;
  return isValid ? "" : "As duas senhas devem ser iguais";
}

const validations = {
  name,
  email,
  phoneNumber,
  healthCareField,
  stateSelected,
  yearSelected,
  password,
  repeatPassword
};

export const getValidator = (field) => {
  return validations[field];
};
