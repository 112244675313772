import React, { useCallback, useEffect, useRef, useState } from "react";
import { Container, Header, CongratulationsMessage, Submessage, Wrapper, ButtonContainer, PersonImg, ConfettiImg } from './styles';
import logoImg from "../../../../../../assets/images/logo.svg"
import { EMRButton } from "../../../Button"
import peopleImg from "./assets/people.png"
import { FaArrowRight } from 'react-icons/fa';
import ReactCanvasConfetti from "react-canvas-confetti";
import { useHistory } from "react-router-dom"

const canvasStyles = {
  position: "fixed",
  pointerEvents: "none",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0
};

function getAnimationSettings(angle, originX, screenWidth) {
  switch (true) {
    case screenWidth < 992:
      return {
        particleCount: 6,
        angle,
        spread: 100,
        origin: { x: originX, y: 0.9 },
        colors: ["#CFFCF7", "#7CDCD8", "#FFCE45", "#FE5E9A", "#65EF82", "#B67DDB"],
        scalar: 0.8,
        startVelocity: 20
      }
    case screenWidth < 1240:
      return {
        particleCount: 6,
        angle,
        spread: 100,
        origin: { x: originX, y: 0.9 },
        colors: ["#CFFCF7", "#7CDCD8", "#FFCE45", "#FE5E9A", "#65EF82", "#B67DDB"],
        scalar: 1,
        startVelocity: 30
      }
    case screenWidth < 1480:
      return {
        particleCount: 6,
        angle,
        spread: 100,
        origin: { x: originX, y: 0.9 },
        colors: ["#CFFCF7", "#7CDCD8", "#FFCE45", "#FE5E9A", "#65EF82", "#B67DDB"],
        scalar: 1.2,
        startVelocity: 40
      }
    default:
      return {
        particleCount: 6,
        angle,
        spread: 100,
        origin: { x: originX, y: 0.9 },
        colors: ["#CFFCF7", "#7CDCD8", "#FFCE45", "#FE5E9A", "#65EF82", "#B67DDB"],
        scalar: 1.5,
        startVelocity: 55
      };
  }
}

export function Congratulations({ user }) {
  const refAnimationInstance = useRef(null);
  const [intervalId, setIntervalId] = useState();
  const [intervalId2, setIntervalId2] = useState();
  const [timeToRedirect, setTimeToRedirect] = useState(7)
  const firstName = user.name.split(" ")[0]
  const history = useHistory()
  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance;
  }, []);

  const nextTickAnimation = () => {
    if (refAnimationInstance.current) {
      refAnimationInstance.current(getAnimationSettings(40, 0, window.innerWidth));
      refAnimationInstance.current(getAnimationSettings(140, 1, window.innerWidth));
    }
  };

  const startAnimation = useCallback(() => {
    if (!intervalId) {
      setIntervalId(setInterval(nextTickAnimation, 50));
    }
  }, [nextTickAnimation, intervalId]);

  const startCountDowm = useCallback(() => {
    if (!intervalId) {
      setIntervalId2(setInterval(() => setTimeToRedirect(prevState => prevState - 1), 1000));
    }
  }, [intervalId]);

  if (timeToRedirect <= 0) {
    handleRedirectToHome()
  }

  function handleRedirectToHome() {
    history.push("/")
  }

  useEffect(() => {
    window.addEventListener("resize", nextTickAnimation)

    return () => {
      window.removeEventListener("resize", nextTickAnimation)
    }
  }, [])

  useEffect(() => {
    startAnimation()
    startCountDowm()

    return () => {
      clearInterval(intervalId);
      clearInterval(intervalId2);
    };
  }, [intervalId, intervalId2]);
  return (
    <Container>
      <Header>
        <img src={logoImg} alt="logo" />
      </Header>
      <Wrapper>
        <div>
          <CongratulationsMessage>Parabéns, {firstName}.<br />Estamos muito felizes com sua chegada!</CongratulationsMessage>
          <Submessage>Sua trilha rumo a aprovação inicia agora. Bons estudos!</Submessage>

          <ButtonContainer>
            <EMRButton fontSize='lg' mediumWeight onClick={handleRedirectToHome}>
              Comece agora ({timeToRedirect}s) <FaArrowRight fill="#fff" />
            </EMRButton>
          </ButtonContainer>
        </div>

        <PersonImg src={peopleImg} />
      </Wrapper>


      <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />
    </Container>
  );
}