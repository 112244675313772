import React from "react";
import { css } from "glamor";

const container = css({
  height: "100%",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export default function DefaultError(props) {
  return (
    <div className={container}>
      <h3>Ops, aconteceu um erro</h3>
    </div>
  );
}
