import React from "react";
import { Bar, BarWrapper, Container, Value } from "./styles";

export function ProgressBar({ progress }) {
  return (
    <Container>
      <BarWrapper>
        <Bar value={progress} />
      </BarWrapper>
      <Value>{progress}%</Value>
    </Container>
  );
}
