import React from "react";
import { IoSearch } from "react-icons/io5";
import { Container } from "./styles";

export function SearchInput(props) {
  return (
    <Container>
      <input type="text" {...props} placeholder="O que você procura?" />
      <IoSearch size={20} />
    </Container>
  );
}
