import React from 'react';
import {
  orderByStartDateOptions,
  periodOptions,
} from '../../hooks/useMedTrack';
import {
  GynecAndObstetricsIcon,
  MedicalClinicIcon,
  PediatricsIcon,
  PreventiveMedicineIcon,
  SurgeryIcon,
} from '../../icons';

export const filterByPeriod = [
  {
    value: periodOptions.ALL,
    label: 'Todo período',
  },
  {
    value: periodOptions.MONTH,
    label: 'Este mês',
  },
  {
    value: periodOptions.WEEK,
    label: 'Esta semana',
  },
  {
    value: periodOptions.LAST_WEEK,
    label: 'Semana anterior',
  },
];

export const filterByStatus = [
  {
    value: '',
    label: 'Todos',
  },
  {
    value: 'uncompleted',
    label: 'Pendentes',
  },
  {
    value: 'completed',
    label: 'Finalizados',
  },
];

export const filterByBigArea = [
  {
    value: '',
    label: 'Todas',
  },
  {
    value: 'surgery',
    label: 'Cirurgia',
    icon: <SurgeryIcon />,
  },
  {
    value: 'medicalClinic',
    label: 'Clínica médica',
    icon: <MedicalClinicIcon />,
  },
  {
    value: 'preventiveMedicine',
    label: 'Med. preventiva',
    icon: <PreventiveMedicineIcon />,
  },
  {
    value: 'gynecAndObstetrics',
    label: 'Ginec. e obstetrícia',
    icon: <GynecAndObstetricsIcon />,
  },
  {
    value: 'pediatrics',
    label: 'Pediatria',
    icon: <PediatricsIcon />,
  },
];

export const filterByStartDate = [
  {
    value: orderByStartDateOptions.ASC,
    label: 'Mais recentes',
  },
  {
    value: orderByStartDateOptions.DESC,
    label: 'Menos recentes',
  },
];
