import React from "react";

export function Computer() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28 4H4C2.89543 4 2 4.89543 2 6V22C2 23.1046 2.89543 24 4 24H28C29.1046 24 30 23.1046 30 22V6C30 4.89543 29.1046 4 28 4Z"
        stroke="#606A71"
        stroke-width="1.5"
        stroke-linejoin="round"
      />
      <path
        d="M19 28L18.5 24H13.5L13 28H19Z"
        fill="#606A71"
        stroke="#606A71"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M23 28H9"
        stroke="#606A71"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2 19V22C2.00148 22.53 2.21267 23.0378 2.58743 23.4126C2.96218 23.7873 3.47002 23.9985 4 24H28C28.53 23.9985 29.0378 23.7873 29.4126 23.4126C29.7873 23.0378 29.9985 22.53 30 22V19H2ZM16 23C15.8022 23 15.6089 22.9414 15.4444 22.8315C15.28 22.7216 15.1518 22.5654 15.0761 22.3827C15.0004 22.2 14.9806 21.9989 15.0192 21.8049C15.0578 21.6109 15.153 21.4327 15.2929 21.2929C15.4327 21.153 15.6109 21.0578 15.8049 21.0192C15.9989 20.9806 16.2 21.0004 16.3827 21.0761C16.5654 21.1518 16.7216 21.28 16.8315 21.4444C16.9414 21.6089 17 21.8022 17 22C17 22.2652 16.8946 22.5196 16.7071 22.7071C16.5196 22.8946 16.2652 23 16 23Z"
        fill="#606A71"
      />
    </svg>
  );
}
