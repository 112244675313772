import React from "react"

export function BookSearch() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M6.78785 11.3611C6.78861 10.5828 7.01611 9.82186 7.44208 9.17282C7.86805 8.52379 8.47373 8.01522 9.1839 7.71031C9.89406 7.40539 10.6774 7.31755 11.4367 7.4577C12.1959 7.59785 12.8976 7.95981 13.4546 8.49863V2.49996C13.4546 2.01373 13.263 1.54741 12.922 1.2036C12.5811 0.85978 12.1186 0.666626 11.6364 0.666626L4.36358 0.666626C3.56019 0.667596 2.78997 0.989832 2.22188 1.56265C1.65379 2.13547 1.33421 2.9121 1.33325 3.72218V12.2777C1.33421 13.0878 1.65379 13.8645 2.22188 14.4373C2.78997 15.0101 3.56019 15.3323 4.36358 15.3333H10.7273C9.68248 15.3333 8.68047 14.9148 7.94168 14.1699C7.2029 13.4249 6.78785 12.4146 6.78785 11.3611ZM4.36358 4.9444C4.36358 4.78233 4.42744 4.62689 4.5411 4.51228C4.65476 4.39768 4.80891 4.33329 4.96965 4.33329H9.81818C9.97892 4.33329 10.1331 4.39768 10.2467 4.51228C10.3604 4.62689 10.4242 4.78233 10.4242 4.9444C10.4242 5.10648 10.3604 5.26192 10.2467 5.37652C10.1331 5.49113 9.97892 5.55551 9.81818 5.55551H4.96965C4.80891 5.55551 4.65476 5.49113 4.5411 5.37652C4.42744 5.26192 4.36358 5.10648 4.36358 4.9444ZM14.4891 15.1542C14.3755 15.2688 14.2214 15.3332 14.0606 15.3332C13.8999 15.3332 13.7458 15.2688 13.6322 15.1542L12.1734 13.6833C11.7413 13.9613 11.2398 14.1097 10.7273 14.1111C10.1879 14.1111 9.66058 13.9498 9.21208 13.6476C8.76357 13.3454 8.41401 12.9159 8.20759 12.4134C8.00116 11.911 7.94715 11.358 8.05239 10.8246C8.15762 10.2911 8.41737 9.80112 8.79879 9.41653C9.18021 9.03193 9.66617 8.77002 10.1952 8.66391C10.7243 8.5578 11.2726 8.61226 11.771 8.8204C12.2693 9.02854 12.6953 9.38102 12.9949 9.83325C13.2946 10.2855 13.4546 10.8172 13.4546 11.3611C13.4532 11.8778 13.3061 12.3836 13.0303 12.8192L14.4891 14.2901C14.6028 14.4047 14.6666 14.5601 14.6666 14.7222C14.6666 14.8842 14.6028 15.0396 14.4891 15.1542Z"
        fill="ffff"
      />
    </svg>
  );
}
