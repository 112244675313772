import React, { useEffect, useState } from "react";
import { Modal, Tab } from "semantic-ui-react";
import { spacing, colors } from "../library/theme";
import MockModalExamFormContainer from "./MockModalExamFormContainer";
import MockModalFormContainer from "./MockModalFormContainer";

const tabOptions = {
  color: colors.semantic,
  secondary: true,
  pointing: true
};

const tabStyle = {
  backgroundColor: "inherit",
  border: "none",
  padding: `${spacing.m} 0 0`,
  maxWidth: "100%",
  minHeight: 235
};

export default function MockModal(props) {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const examType = urlParams.get("examType");
    if (examType === "integra") setActiveIndex(1);
  }, []);

  const mockFieldsPanes = [
    {
      menuItem: "Simulado Personalizado",
      render: () => (
        <Tab.Pane style={tabStyle}>
          <MockModalFormContainer {...props} />
        </Tab.Pane>
      )
    },
    {
      menuItem: "Prova na Íntegra",
      render: () => (
        <Tab.Pane style={tabStyle}>
          <MockModalExamFormContainer {...props} />
        </Tab.Pane>
      )
    }
  ];

  return (
    <Modal open>
      <Modal.Header>
        <h2>Novo Simulado</h2>
        <p>Configure as opções abaixo para criar um novo simulado.</p>
      </Modal.Header>
      <Modal.Content>
        <Tab
          onTabChange={(_, data) => setActiveIndex(data.activeIndex)}
          activeIndex={activeIndex}
          panes={mockFieldsPanes}
          menu={tabOptions}
        />
      </Modal.Content>
    </Modal>
  );
}
