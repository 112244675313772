import React, { useState } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import {
  QuestionsBySpecialtyContainer,
  MockQuestionsContainer,
  MockTitle,
  QuestionsContainer,
  SpecialtyCard,
  SpecialtyTitleContainer,
  SubjectContainer,
  TitleWrapper,
  Wrapper,
  FavoriteQuestionsNumber,
  TitleContainer,
  Divider,
} from "./styles";
import { FilterButton } from "../../../componentsV2/FilterButton";
import Pagination from "../../library/Pagination";
import { QuestionModal } from "../QuestionModal";
import { trackClickEvents } from "../../../../services/mixpanel/events/trackClickEvents";
import Actions from "./actions";
import { getQuestionTitle } from "./cleanHTMLTags";
import Placeholder from "./placeholder";
import AlternativeContainer from "../AlternativeContainer/index";
import { filterOptions } from "./filters";
import { handleOpenConfirmDeleteModal } from "./unfavorite";

export default function FavoriteQuestionsPage(props) {
  const {
    favoriteQuestions,
    selectedFilter,
    setSelectedFilter,
    isLoadingQuestions,
    currentPage,
    setCurrentPage,
    fetchFavoriteQuestions,
  } = props;
  const [selectedQuestion, setSelectedQuestion] = useState({
    questionId: 0,
    questionSpecialty: "",
    questionIndex: 0,
  });
  const [questionModalOpen, setQuestionModalOpen] = useState(false);
  const hasFavoriteQuestions = favoriteQuestions?.data?.length > 0;

  function handleSelectQuestion(question, specialty, subject, index) {
    trackClickEvents({
      eventName: "Questões favoritas - interação de navegação",
      content: {
        BotaoVerQuestao: question.code,
      },
    });
    setSelectedQuestion({
      questionId: question.id,
      questionSpecialty: specialty,
      questionSubject: subject,
      questionIndex: index,
    });
    setQuestionModalOpen(true);
  }

  return (
    <>
      <Wrapper>
        <FilterButton
          data={filterOptions}
          isLoading={isLoadingQuestions}
          selectedValue={selectedFilter}
          setSelectedValue={setSelectedFilter}
          style={{ marginTop: 10 }}
          isFavoriteQuestionsFilter={true}
        />
        {hasFavoriteQuestions ? (
          <QuestionsBySpecialtyContainer>
            {favoriteQuestions.data.map(
              (specialty, questionsBySpecialtyIndex) => (
                <SpecialtyCard key={questionsBySpecialtyIndex}>
                  <SubjectContainer>
                    <SpecialtyTitleContainer isFirstSpecialtySubject={false}>
                      <h3>
                        {String(specialty?.specialty || "").toLowerCase()}
                      </h3>
                      <span>{String(specialty.subject).toLowerCase()}</span>
                    </SpecialtyTitleContainer>

                    <QuestionsContainer>
                      {specialty.questions.map((question, questionIndex) => (
                        <MockQuestionsContainer
                          key={questionIndex}
                          isFirstQuestion={questionsBySpecialtyIndex === 0}
                        >
                          <TitleWrapper>
                            <TitleContainer>
                              <FavoriteQuestionsNumber>
                                {questionsBySpecialtyIndex + 1}
                              </FavoriteQuestionsNumber>
                              <MockTitle>
                                {getQuestionTitle(question.title)}
                              </MockTitle>
                            </TitleContainer>

                            <Actions
                              handleOpenConfirmDeleteModal={() =>
                                handleOpenConfirmDeleteModal(
                                  question,
                                  fetchFavoriteQuestions
                                )
                              }
                              handleSelectQuestion={() =>
                                handleSelectQuestion(
                                  question,
                                  specialty.specialty,
                                  specialty.subject,
                                  questionsBySpecialtyIndex
                                )
                              }
                            />
                          </TitleWrapper>
                          <Divider />
                          <AlternativeContainer
                            answer={question.answer}
                            question_type={question.question_type}
                            title={question.title}
                          />
                        </MockQuestionsContainer>
                      ))}
                    </QuestionsContainer>
                  </SubjectContainer>
                </SpecialtyCard>
              )
            )}
          </QuestionsBySpecialtyContainer>
        ) : (
          <Placeholder />
        )}
        <Pagination
          fetchHandler={(_, pageNumber) => setCurrentPage(pageNumber)}
          list={{
            totalPages: favoriteQuestions.total_pages,
            currentPage: currentPage,
          }}
          loading={isLoadingQuestions}
        />
      </Wrapper>

      {questionModalOpen && (
        <Dialog.Root open onOpenChange={setQuestionModalOpen}>
          <QuestionModal
            selectedQuestion={selectedQuestion}
            onClose={() => setQuestionModalOpen(false)}
          />
        </Dialog.Root>
      )}
    </>
  );
}
