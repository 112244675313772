const getLevelGroups = (action) => action.entities.levelGroups;

export default function levelGroupsReducer(state = {}, action) {
  switch (action.type) {
    case "LEVEL_GROUPS_FETCHED":
      return { ...state, ...getLevelGroups(action) };
    default:
      return state;
  }
}
