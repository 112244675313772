import styled from "styled-components";
import { colors } from "../../../library/theme";

export const HighlightTooltipContainer = styled.div`
  display: none;
  box-sizing: border-box;
  position: fixed;
  border: 1px solid ${colors.interface.grey400};
  background-color: ${colors.interface.grey500};
  border-radius: 4px;
  color: ${colors.interface.white000};
  text-align: center;
  font-size: 12px;
  line-height: 18px;
  overflow: visible;
  z-index: 1;
  left: ${(props) => `${props.left}px`};
  top: ${(props) => `${props.top}px`};
  transform: translateX(-50%);
  box-shadow: 0px 2px 4px -2px #1018280f, 0px 4px 8px -2px #1018281a;

  &[data-visible="true"] {
    display: flex;
  }

  &::after {
    content: "";
    position: absolute;
    height: 10px;
    width: 10px;
    background-color: ${colors.interface.grey500};
    transform: rotate(45deg);
    border: 1px solid ${colors.interface.grey400};
    border-radius: 4px 0 0 0;
    border-width: 1px 0 0 1px;
    bottom: -5.5px;
    left: 50%;
    transform: translateX(-50%) rotate(225deg);
  }
  
  @media (max-width: 991px) {
    &::after {
      bottom: 35px;
      transform: translateX(-50%) rotate(45deg);
    }
  }
`;

export const TooltipDivider = styled.hr`
  border-left: none;
  border-color: ${colors.interface.grey400};
  height: 24px;
  margin: auto 0;
`;

export const TooltipAction = styled.button`
  all: unset;
  cursor: pointer;
  height: 40px;
  padding: 0 8px;

  &:hover {
    text-decoration: underline;
  }
`;
