import styled from "styled-components";

export const Title = styled.h2`
  margin: 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.3;
`

export const Subtitle = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;

  margin-bottom: 16px;
`

export const ButtonWrapper = styled.fieldset`
  border: 0;
  margin-top: auto;
  padding: 0;

  button:last-of-type {
    margin-top: 8px;
  }
`