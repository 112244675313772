import { getCookie, setCookie } from "cookies-next";

class Refresher {
  #refreshPromise = null;

  constructor() {}

  get refreshToken() {
    return getCookie("upRefreshToken");
  }

  set refreshToken(v) {
    return setCookie("upRefreshToken", v);
  }

  get token() {
    return getCookie("upJwt");
  }

  set token(v) {
    return setCookie("upJwt", v);
  }

  /**
   * @returns { Promise<boolean> } true if refresh succeded
   */
  async refresh() {
    if (!this.#refreshPromise) {
      this.#refreshPromise = this.#refresh();
    }

    return this.#refreshPromise;
  }

  async #refresh() {
    const response = await fetch(
      `${process.env.USER_PROXY_URL}/auth/refresh-token?token=${this.refreshToken}`,
    );

    const body = await response.json();
    if (response.ok) {
      this.token = body.jwt;
      this.refreshToken = body.refreshToken;
    }

    this.#refreshPromise = null;

    return response.ok;
  }
}

export const refresher = new Refresher();
