export const filterOptions = [
  {
    action: "ALL",
    title: "Todos"
  },
  {
    action: "SURGERY",
    title: "Cirurgia"
  },
  {
    action: "MEDIC_CLINIC",
    title: "Clínica médica"
  },
  {
    action: "GYNECOLOGY_OBSTETRICS",
    title: "Ginecologia e obstetrícia"
  },
  {
    action: "PEDIATRICS",
    title: "Pediatria"
  },
  {
    action: "PREVENTIVE_MEDICINE",
    title: "Medicina preventiva"
  }
];
