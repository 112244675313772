const getRatings = (action) => action.entities.ratings;

export default function ratingsReducer(state = {}, action) {
  switch (action.type) {
    case "RATING_CREATED":
    case "RATINGS_FETCHED":
      return { ...state, ...getRatings(action) };
    default:
      return state;
  }
}
