import React from "react";
import { colors } from "../../../../../components/library/theme";

export function Illustration({ isRevision }) {
  return (
    <>
      {isRevision ? (
        <svg
          width="404"
          height="198"
          viewBox="0 0 404 198"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="revision-illustration"
        >
          <path
            d="M137.852 1.32422H376.002C377.414 1.32422 378.768 1.88522 379.767 2.88381C380.765 3.8824 381.327 5.23679 381.327 6.649V182.131H132.576V6.649C132.576 5.24535 133.131 3.89843 134.118 2.90126C135.106 1.90408 136.448 1.33731 137.852 1.32422Z"
            fill="#92E3A9"
            stroke="#263238"
            strokeWidth="1.24121"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M152.436 15.0518H361.405C362.818 15.0518 364.172 15.6128 365.171 16.6114C366.169 17.6099 366.73 18.9643 366.73 20.3765V184.563H147.124V20.3765C147.124 18.9665 147.683 17.614 148.679 16.6157C149.675 15.6175 151.026 15.055 152.436 15.0518Z"
            fill="#9BA5AB"
          />
          <g clipPath="url(#clip0_2740_19535)">
            <path
              d="M217.978 85.0234H222.719V86.5618H220.05V87.3744H222.316V88.826H220.059V89.8864H222.852V91.4247H217.99L217.978 85.0234Z"
              fill="white"
            />
            <path
              d="M224.309 88.3923V85.0234H226.378V88.2807C226.329 88.7091 226.427 89.1428 226.66 89.5111C227.218 90.0563 228.122 90.0793 228.71 89.5642C228.981 89.1906 229.102 88.7357 229.051 88.2825V85.0252H231.039V88.3923C231.099 89.1446 230.883 89.8934 230.429 90.5059C228.847 91.8708 226.453 91.8602 224.884 90.4794C224.456 89.8669 224.254 89.1322 224.309 88.394V88.3923Z"
              fill="white"
            />
            <path
              d="M234.321 91.4265V85.0234H236.309L238.185 87.3566L240.052 85.0234H242.02V91.4247H239.952V89.3393C239.952 88.8348 239.963 88.2772 239.972 87.8364L239.952 87.8275C239.652 88.2347 239.318 88.6507 238.998 89.0579L238.134 90.1377L237.255 89.0579C236.924 88.6507 236.602 88.2347 236.3 87.8275L236.28 87.8364C236.291 88.279 236.3 88.8366 236.3 89.3393V91.4247H234.319L234.321 91.4265Z"
              fill="white"
            />
            <path
              d="M243.772 85.0343H248.51V86.5727H245.842V87.3853H248.1V88.8369H245.842V89.8973H248.625V91.4321H243.767L243.772 85.0343ZM246.399 84.4077H245.118L245.996 82.9561H247.607L246.399 84.4077Z"
              fill="white"
            />
            <path
              d="M250.159 85.0233H252.251C253.439 84.9366 254.629 85.1933 255.669 85.758C257.084 86.8343 257.327 88.817 256.214 90.1837C256.112 90.3094 256 90.428 255.879 90.5359C254.825 91.221 253.558 91.5344 252.293 91.4211H250.164L250.159 85.0233ZM252.308 89.8863C253.626 89.8863 254.157 89.6561 254.468 89.3304C254.73 89.0224 254.864 88.6329 254.843 88.2346C254.884 87.7248 254.649 87.2291 254.221 86.9281C253.63 86.6325 252.965 86.5068 252.303 86.5652H252.231V89.8898H252.306L252.308 89.8863Z"
              fill="white"
            />
            <path
              d="M258.375 85.0234H260.443V91.4247H258.375V85.0234Z"
              fill="white"
            />
            <path
              d="M266.019 84.923C266.707 84.9194 267.388 85.0416 268.029 85.2859V87.0721C267.429 86.7393 266.751 86.5569 266.061 86.541C265.064 86.4171 264.152 87.1004 264.024 88.0652C264.018 88.113 264.013 88.1626 264.011 88.2103C264.011 89.1397 264.774 89.8992 266.1 89.8992C266.814 89.885 267.511 89.6921 268.126 89.3433V91.1384C267.456 91.3968 266.738 91.5261 266.017 91.519C263.577 91.519 261.939 90.0851 261.939 88.2298C262.065 86.2719 263.806 84.7849 265.831 84.9053C265.893 84.9088 265.955 84.9141 266.017 84.9212H266.021L266.019 84.923Z"
              fill="white"
            />
            <path
              d="M273.281 84.9206C273.263 84.9206 273.242 84.9188 273.224 84.9171C271.223 84.8055 269.508 86.2837 269.395 88.2186C269.395 89.8897 270.676 91.3024 272.388 91.5184C274.271 91.7574 275.999 90.4739 276.245 88.6505C276.492 86.8289 275.164 85.1578 273.279 84.9206H273.281ZM272.931 89.888V89.8968C272.922 89.8968 272.911 89.8968 272.902 89.8968C271.932 89.9127 271.13 89.1657 271.113 88.2274C271.113 87.3441 271.827 86.6147 272.739 86.5652C273.687 86.5138 274.5 87.2166 274.553 88.1336C274.606 89.0506 273.879 89.8366 272.931 89.888Z"
              fill="white"
            />
            <path
              d="M217.979 93.5652H223.487C224.998 93.4908 226.506 93.7263 227.916 94.2556C229.479 94.815 230.504 96.2719 230.467 97.8846C230.486 99.6212 229.298 101.154 227.568 101.625L231.752 107.456H226.674L223.16 102.183H222.462V107.456H217.972L217.979 93.5669V93.5652ZM223.581 99.4725C224.185 99.515 224.789 99.4177 225.347 99.1893C225.766 98.9804 226.015 98.5502 225.981 98.097C226.012 97.5943 225.704 97.1287 225.217 96.9464C224.599 96.7499 223.947 96.672 223.299 96.7162H222.473V99.4743H223.581V99.4725Z"
              fill="white"
            />
            <path
              d="M231.989 93.5654H242.276V96.9042H236.479V98.6691H241.383V101.815H236.479V104.116H242.538V107.455H231.994L231.987 93.5672L231.989 93.5654Z"
              fill="white"
            />
            <path
              d="M243.369 102.999C244.98 103.805 246.747 104.274 248.555 104.378C249.471 104.378 250.126 104.095 250.126 103.387C250.126 102.395 248.863 102.395 246.857 101.776C244.459 101.05 242.804 100.011 242.804 97.7679C242.804 95.063 245.183 93.3564 248.711 93.3564C250.477 93.3653 252.226 93.7105 253.855 94.3726V98.0158C252.271 97.2156 250.534 96.7465 248.751 96.6367C247.926 96.6367 247.356 96.8669 247.356 97.4865C247.356 98.3875 248.577 98.3875 250.494 98.9824C252.958 99.7489 254.679 100.823 254.679 103.164C254.679 105.964 252.171 107.671 248.51 107.671C246.749 107.667 245.002 107.342 243.366 106.711L243.369 102.999Z"
              fill="white"
            />
            <path
              d="M255.53 93.5654H260.02V107.444H255.53V93.5654Z"
              fill="white"
            />
            <path
              d="M261.633 93.5657H266.173C268.752 93.3816 271.329 93.9375 273.585 95.1625C276.656 97.4957 277.189 101.796 274.775 104.766C274.551 105.041 274.306 105.299 274.042 105.538C272.472 106.784 270.054 107.455 266.261 107.455H261.638L261.631 93.5657H261.633ZM266.297 104.113C269.149 104.113 270.308 103.614 270.983 102.906C271.549 102.238 271.838 101.39 271.791 100.528C271.879 99.4218 271.366 98.3508 270.44 97.6975C269.158 97.0585 267.714 96.7859 266.277 96.9115H266.127V104.124L266.297 104.115V104.113Z"
              fill="white"
            />
            <path
              d="M277.281 93.5654H287.57V96.9042H281.773V98.6691H286.681V101.815H281.777V104.116H287.836V107.455H277.288L277.279 93.5672L277.281 93.5654Z"
              fill="white"
            />
            <path
              d="M295.698 102.427C294.819 101.392 294.063 100.452 293.278 99.4515L293.234 99.471C293.256 100.641 293.278 101.831 293.278 103.001V107.451H288.963V93.5742H293.174L297.358 98.5982C298.237 99.6338 298.993 100.581 299.778 101.574L299.822 101.555C299.8 100.384 299.778 99.1948 299.778 98.0247V93.5742H304.093V107.451H299.883L295.698 102.427Z"
              fill="white"
            />
            <path
              d="M309.603 96.9042H304.774V93.5654H318.928V96.9042H314.097V107.451H309.607L309.603 96.9042Z"
              fill="white"
            />
            <path
              d="M319.607 93.5654H329.897V96.9042H324.099V98.6691H329.003V101.815H324.099V104.116H330.158V107.455H319.614L319.605 93.5672L319.607 93.5654Z"
              fill="white"
            />
            <path
              d="M198.174 111.98C197.935 111.98 197.697 111.947 197.468 111.88L185.459 108.369C184.421 108.072 183.709 107.148 183.713 106.1V85.3149C183.713 84.0084 184.808 82.9481 186.16 82.9463H210.188C211.539 82.9463 212.636 84.0067 212.638 85.3149V106.1C212.641 107.144 211.933 108.066 210.897 108.366L198.888 111.874C198.658 111.943 198.418 111.977 198.176 111.979L198.174 111.98ZM188.961 97.6466V99.2062C188.965 100.369 189.941 101.311 191.143 101.311H194.195V106.834C194.195 107.999 195.172 108.943 196.375 108.943H197.986V101.309H198.361V106.37H199.972C201.177 106.37 202.152 105.425 202.152 104.262V101.315H207.864C209.066 101.311 210.04 100.369 210.04 99.2062V97.6483H202.154V97.2819H207.388V95.7223C207.384 94.5592 206.41 93.6175 205.207 93.6175H202.156V88.0925C202.152 86.9276 201.175 85.9841 199.968 85.9876H198.357V93.6139H197.982V88.5545H196.375C195.172 88.5545 194.197 89.4963 194.193 90.6593V93.6104H188.489C187.284 93.6104 186.307 94.5539 186.307 95.7188C186.307 95.7188 186.307 95.7205 186.307 95.7223V97.2801H194.193V97.6466H188.961ZM198.367 97.6466H197.986V97.2801H198.361V97.643L198.367 97.6466Z"
              fill="white"
            />
          </g>
          <path
            d="M378.906 196.739H122.312C108.869 196.739 97.9592 188.597 97.9592 178.556H403.296C403.246 188.597 392.348 196.739 378.906 196.739Z"
            fill="#92E3A9"
            stroke="#263238"
            strokeWidth="1.24121"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M138.634 13.6367H71.6953"
            stroke="#606A71"
            strokeWidth="2.48241"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M138.634 82.5234H102.241"
            stroke="#606A71"
            strokeWidth="2.48241"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M95.0921 82.5234H80.1479"
            stroke="#606A71"
            strokeWidth="2.48241"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M138.634 56.9414H63.9006"
            stroke="#606A71"
            strokeWidth="2.48241"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M138.634 44.6045H117.831"
            stroke="#606A71"
            strokeWidth="2.48241"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M138.634 113.28H63.9006"
            stroke="#606A71"
            strokeWidth="2.48241"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M138.634 140.5H117.831"
            stroke="#606A71"
            strokeWidth="2.48241"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M93.1433 25.3408H26.2051"
            stroke="#606A71"
            strokeWidth="2.48241"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.8163 25.3408H1.51758"
            stroke="#606A71"
            strokeWidth="2.48241"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <defs>
            <clipPath id="clip0_2740_19535">
              <rect
                width="147.862"
                height="31.1586"
                fill="white"
                transform="translate(182.734 82.002)"
              />
            </clipPath>
          </defs>
        </svg>
      ) : (
        <svg
          width="246"
          height="248"
          viewBox="0 0 246 248"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="illustration"
        >
          <path
            d="M44.9609 47.5331C45.0421 47.4367 45.1381 47.3478 45.2266 47.2564C62.5647 29.9664 90.5816 27.7434 112.953 34.7977C123.89 38.2434 132.471 47.9258 142.753 52.7299C146.175 54.3231 149.782 55.3728 153.725 55.4815C164.042 55.7656 173.678 48.9163 183.968 49.7042C197.105 50.6922 204.841 64.0302 206.711 75.8862C209.796 95.4906 200.861 116.762 189.737 132.424C183.631 141.03 176.76 148.973 170.168 157.184C165.053 163.574 161.683 173.239 168.446 179.878C177.425 188.689 180.187 169.19 190.773 173.641C217.126 184.719 214.12 208.468 208.138 218.635C202.676 227.934 192.148 234.174 181.412 235.055C179.708 235.196 177.995 235.196 176.29 235.055"
            stroke="#C2CED6"
            strokeWidth="0.6102"
            strokeMiterlimit="10"
          />
          <path
            d="M92.457 171.289C92.457 171.316 92.457 171.343 92.457 171.371C92.457 173.186 100.462 180.109 101.667 181.391"
            stroke="#C2CED6"
            strokeWidth="0.6102"
            strokeMiterlimit="10"
          />
          <path
            d="M67.5538 234.963C65.1258 235.044 62.8306 235.034 60.651 234.945C35.4262 231.835 16.4522 181.388 46.1321 166.074"
            stroke="#C2CED6"
            strokeWidth="0.6102"
            strokeMiterlimit="10"
          />
          <path
            d="M96.1628 104.228V53.1159C96.1609 51.6354 95.5742 50.2159 94.5316 49.1691C93.4888 48.1221 92.0753 47.5332 90.6008 47.5312H19.5068C18.0344 47.5364 16.6239 48.1268 15.5839 49.1733C14.5439 50.2198 13.959 51.6374 13.957 53.1159V154.458C13.959 155.937 14.5459 157.355 15.5889 158.401C16.6318 159.446 18.0454 160.033 19.5191 160.032H90.6131C91.3026 160.037 91.9865 159.905 92.6254 159.645"
            stroke="#1D2224"
            strokeWidth="0.6102"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M89.0856 50.9492H21.0297C18.5 50.9492 16.4492 53.0085 16.4492 55.5484V152.027C16.4492 154.567 18.5 156.626 21.0297 156.626H89.0856C91.6155 156.626 93.6661 154.567 93.6661 152.027V55.5484C93.6661 53.0085 91.6155 50.9492 89.0856 50.9492Z"
            fill={colors.brand.pure}
          />
          <path
            d="M87.6783 59.1289H23.2903C22.0254 59.1289 21 60.1584 21 61.4285V76.614C21 77.8839 22.0254 78.9136 23.2903 78.9136H87.6783C88.9432 78.9136 89.9685 77.8839 89.9685 76.614V61.4285C89.9685 60.1584 88.9432 59.1289 87.6783 59.1289Z"
            fill={colors.brand.pure}
          />
          <path
            d="M55.0628 67.4625C56.189 67.4625 57.1021 66.5456 57.1021 65.4148C57.1021 64.2838 56.189 63.3672 55.0628 63.3672C53.9366 63.3672 53.0234 64.2838 53.0234 65.4148C53.0234 66.5456 53.9366 67.4625 55.0628 67.4625Z"
            fill="#D3FCE9"
          />
          <path
            d="M49.3606 74.6728C49.3449 73.912 49.4804 73.156 49.7596 72.4483C50.0386 71.7409 50.4556 71.0965 50.9857 70.5531C51.5161 70.0094 52.149 69.5774 52.8477 69.2828C53.5463 68.9878 54.2964 68.8359 55.0543 68.8359C55.8122 68.8359 56.5623 68.9878 57.2609 69.2828C57.9595 69.5774 58.5925 70.0094 59.1229 70.5531C59.653 71.0965 60.07 71.7409 60.3489 72.4483C60.6281 73.156 60.7637 73.912 60.7479 74.6728H49.3606Z"
            fill="#D3FCE9"
          />
          <path
            d="M27.8945 87.7461H81.5201"
            stroke="white"
            strokeWidth="0.6102"
            strokeMiterlimit="10"
          />
          <path
            d="M27.8945 93.043H81.5201"
            stroke="white"
            strokeWidth="0.6102"
            strokeMiterlimit="10"
          />
          <path
            d="M27.8945 127.18H81.5201"
            stroke="white"
            strokeWidth="0.6102"
            strokeMiterlimit="10"
          />
          <path
            d="M40.3516 132.188H69.0671"
            stroke="white"
            strokeWidth="0.6102"
            strokeMiterlimit="10"
          />
          <path
            d="M41.4885 113.075L37.2106 117.64C36.8637 118.01 36.4475 118.308 35.9858 118.517C35.524 118.725 35.0261 118.841 34.5199 118.856C34.0138 118.872 33.5098 118.787 33.0362 118.607C32.5627 118.427 32.1292 118.155 31.7605 117.806C31.3917 117.458 31.0948 117.04 30.8869 116.577C30.6791 116.113 30.5642 115.613 30.5487 115.105C30.5332 114.597 30.6178 114.091 30.7971 113.615C30.9765 113.14 31.2476 112.704 31.5944 112.334L35.8699 107.77L41.4885 113.075Z"
            fill="#EFFFF7"
          />
          <path
            d="M45.752 108.51L41.4741 113.08L35.8555 107.774L40.1359 103.207C40.838 102.462 41.8055 102.026 42.8271 101.994C43.8485 101.963 44.8409 102.338 45.5872 103.039C46.3269 103.745 46.7592 104.716 46.7899 105.741C46.8209 106.765 46.448 107.76 45.752 108.51Z"
            fill="#D3FCE9"
          />
          <path
            d="M62.3825 105.536C62.2005 104.652 64.0061 103.189 64.5965 102.871C66.7933 101.715 70.1734 102.587 71.3763 104.795C74.1389 109.858 66.3776 117.076 62.3702 118.859C61.9102 118.489 50.946 110.254 53.5142 104.983C56.0825 99.7116 61.7454 102.389 62.3825 105.536Z"
            fill="#D3FCE9"
          />
          <path
            d="M54.1133 114.559H60.0886"
            stroke="white"
            strokeWidth="0.6102"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M57.0977 117.558V111.559"
            stroke="white"
            strokeWidth="0.6102"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M172.5 115.907C172.5 115.907 158.7 108.44 149.799 106.634C149.799 106.634 149.738 107.968 148.257 111.261C146.776 114.553 142.407 124.282 142.407 124.282L135.849 119.281L135.947 126.738L117.955 146.033L106.582 121.037L108.097 114.005L101.618 116.497L98.1565 104.098C98.1565 104.098 81.094 107.951 66.3979 119.824C51.7019 131.697 47.4928 150.205 46.1275 166.075C44.7622 181.945 46.4031 198.679 67.2835 204.333C67.2835 204.333 77.094 206.803 85.0816 203.498C87.5712 202.465 91.1874 201.502 94.9807 200.657L94.8577 200.702C94.8577 200.702 93.1357 206.701 88.1468 209.967C88.1468 209.967 96.8872 216.636 111.822 219.543C126.757 222.45 158.316 224.189 174.222 210.332C190.129 196.476 190.778 173.631 190.778 173.631C190.778 173.631 195.944 129.524 172.5 115.907ZM90.7765 154.888C90.2348 154.87 89.7104 154.693 89.2686 154.378C88.8267 154.063 88.4868 153.624 88.2912 153.117C88.0956 152.609 88.0531 152.055 88.1687 151.524C88.2843 150.992 88.5532 150.507 88.9416 150.127C89.3301 149.748 89.8213 149.492 90.3537 149.391C90.8863 149.289 91.4366 149.348 91.9362 149.558C92.4361 149.768 92.8631 150.122 93.1642 150.574C93.4653 151.027 93.6274 151.558 93.6301 152.102C93.6171 152.851 93.3098 153.564 92.7753 154.086C92.2407 154.608 91.5222 154.896 90.7765 154.888ZM151.423 180.317C151.005 182.451 148.764 195.952 143.224 197.538C137.684 199.123 132.215 195.675 119.293 197.022C119.293 197.022 116.508 197.128 112.651 197.474C104.594 187.996 112.705 174.488 112.705 174.488C112.705 174.488 131.017 168.888 139.748 172.929C148.478 176.97 151.849 178.178 151.423 180.317Z"
            fill={colors.brand.pure}
          />
          <path
            d="M166.787 217.688V235.052C138.866 235.052 101.09 235.007 73.7991 234.973C73.4621 226.036 72.9307 205.479 75.189 205.333C78.0795 205.155 81.1373 203.295 84.6649 207.524C88.1926 211.752 102.96 219.315 123.646 221.094C144.332 222.872 161.776 217.979 165.651 215.862C165.653 215.865 166.787 213.835 166.787 217.688Z"
            fill={colors.brand.pure}
          />
          <path
            d="M90.7749 147.026C93.6432 147.026 95.9778 149.308 95.9778 152.114C95.9778 154.92 93.6432 157.202 90.7749 157.202C87.9065 157.202 85.5744 154.92 85.5744 152.114C85.5744 149.308 87.9065 147.026 90.7749 147.026ZM90.7749 145.245C86.9225 145.245 83.8008 148.32 83.8008 152.114C83.8008 155.908 86.9225 158.983 90.7749 158.983C94.6272 158.983 97.749 155.908 97.749 152.114C97.749 148.32 94.6248 145.242 90.7749 145.242V145.245Z"
            fill="white"
          />
          <path
            d="M126 114.242L121.528 119.288C121.382 119.407 121.213 119.494 121.031 119.545C120.85 119.596 120.66 119.609 120.474 119.584C120.287 119.559 120.107 119.496 119.946 119.4C119.784 119.303 119.644 119.174 119.533 119.022L116.387 112.224L122.512 107.336L126 114.242Z"
            fill={colors.brand.pure}
          />
          <path
            d="M123.154 117.438L125.051 125.088L119.516 132.145L115.383 122.941L118.446 116.707"
            fill={colors.brand.pure}
          />
          <path
            d="M142.001 62.6455C141.017 62.8678 140.156 63.6335 139.499 64.4511C138.888 65.2178 138.371 66.0554 137.959 66.9458C137.959 66.9458 135.883 58.0538 135.007 50.34C134.714 47.8305 133.839 46.4868 132.773 45.81C130.577 44.412 127.607 45.9063 127.607 45.9063L117.743 51.3008C115.85 52.3478 113.767 53.0014 111.617 53.2225C110.613 53.3386 109.598 53.3386 108.594 53.2225C103.16 50.5252 103.081 39.8845 109.684 39.8104C109.883 39.9966 110.069 40.1954 110.242 40.4056C110.147 40.2095 110.059 40.0144 109.977 39.8203C110.346 39.8314 110.714 39.8729 111.076 39.9438C111.617 40.05 112.122 40.6107 112.459 41.0034C110.705 37.5849 112.574 33.2772 116.031 31.9706C118.131 31.1777 120.481 31.3284 122.673 31.7928C123.02 31.8619 123.364 31.9434 123.711 32.0398C129.206 33.7045 134.018 36.5969 137.573 41.4258C142.072 47.5341 143.196 55.2676 142.001 62.6455Z"
            fill="#2E2E2E"
          />
          <path
            d="M79.342 155.027C73.3223 181.95 96.4291 177.44 126.456 172.132C137.184 170.238 146.313 174.913 151.602 179.275"
            stroke="#1D2224"
            strokeWidth="0.6102"
            strokeLinejoin="round"
          />
          <path
            d="M98.1546 104.098C84.509 107.469 68.4107 115.647 59.5547 126.748C47.9608 141.259 45.5327 160.554 45.796 178.618C46.042 196.266 64.46 208.455 81.1781 204.641"
            stroke="#1D2224"
            strokeWidth="0.6102"
            strokeLinejoin="round"
          />
          <path
            d="M105.219 97.5039H105.23"
            stroke="#1D2224"
            strokeWidth="0.6102"
            strokeLinejoin="round"
          />
          <path
            d="M153.078 156.023C153.078 156.023 153.57 167.703 151.602 179.278C150.709 184.561 149.304 189.822 147.113 193.942C146.524 195.03 145.659 195.943 144.606 196.587C143.553 197.231 142.348 197.584 141.115 197.61C138.867 197.651 136.619 197.561 134.382 197.341C115.101 195.523 97.0295 199.512 83.8512 203.896C82.9656 204.188 82.0751 204.435 81.1797 204.637C89.492 212.21 110.877 226.21 151.309 220.139C205.675 211.988 188.684 139.906 188.684 139.906C187.208 132.14 182.598 124.93 177.056 119.469C170.778 113.294 158.606 109.211 150.33 106.625"
            stroke="#1D2224"
            strokeWidth="0.6102"
            strokeLinejoin="round"
          />
          <path
            d="M121.256 93.0559C121.256 93.0559 104.714 90.0919 106.931 70.5048C107.915 61.7907 109.812 56.4604 111.605 53.2148"
            stroke="#1D2224"
            strokeWidth="0.6102"
            strokeLinejoin="round"
          />
          <path
            d="M136.923 87.8983C139.978 83.3139 139.511 77.2279 140.47 75.6965C141.946 73.3203 147.85 65.9078 144.308 63.2378C143.491 62.6227 142.711 62.4968 142.003 62.64C139.619 63.1736 137.961 66.9401 137.961 66.9401C137.961 66.9401 135.887 58.0483 135.009 50.332C134.131 42.6157 127.607 45.9008 127.607 45.9008L117.742 51.2929C115.85 52.3421 113.767 52.9964 111.617 53.2167C110.492 53.3499 109.354 53.3407 108.232 53.1898C100.606 52.2018 100.114 37.7054 108.861 39.3578C109.403 39.464 109.907 40.0247 110.244 40.4174C108.487 36.999 110.36 32.6913 113.816 31.3847C115.917 30.5918 118.266 30.7425 120.458 31.2068C127.302 32.6567 133.336 35.6526 137.575 41.4225C142.076 47.5357 143.203 55.2696 142.003 62.6474"
            stroke="#1D2224"
            strokeWidth="0.6102"
            strokeLinejoin="round"
          />
          <path
            d="M119.182 74.6575H115.637L115.868 71.8368L116.815 60.418"
            stroke="#1D2224"
            strokeWidth="0.6102"
            strokeLinejoin="round"
          />
          <path
            d="M115.867 71.8343H106.13C105.44 71.8343 104.778 71.5589 104.29 71.0689C103.802 70.5788 103.527 69.9141 103.527 69.221V60.418H119.985M119.985 60.418V69.221C119.985 69.9144 120.26 70.5791 120.749 71.0691C121.237 71.5592 121.899 71.8343 122.59 71.8343H132.245C132.936 71.8343 133.598 71.5592 134.087 71.0691C134.575 70.5791 134.85 69.9144 134.851 69.221V60.418H119.985Z"
            stroke="#1D2224"
            strokeWidth="0.6102"
            strokeLinejoin="round"
          />
          <path
            d="M119.191 78.062C119.191 78.062 121.703 78.9512 124.51 74.6484"
            stroke="#1D2224"
            strokeWidth="0.6102"
            strokeLinejoin="round"
          />
          <path
            d="M117.848 146.896L136.18 126.469L135.845 119.276L142.184 124.762L150.329 106.63L150.352 106.583C148.36 105.405 146.501 104.013 144.809 102.431C144.03 101.697 143.292 100.929 142.595 100.129C141.773 99.1916 141.006 98.2068 140.298 97.1798L139.724 96.3672"
            stroke="#1D2224"
            strokeWidth="0.6102"
            strokeLinejoin="round"
          />
          <path
            d="M129.881 118.57L126.108 113.351L122.083 108.026L130.531 102.893L139.741 96.3672L142.597 100.129L143.557 101.396L141.227 104.392L123.035 127.837V127.847L119.594 132.143"
            stroke="#1D2224"
            strokeWidth="0.6102"
            strokeLinejoin="round"
          />
          <path
            d="M112.231 115.604L122.071 108.036L109.983 95.9058L105.402 100.243L119.584 132.153L117.85 146.901L106.581 121.038L108.089 114.008L101.609 116.493L97.8477 104.225C102.171 102.55 106.08 99.9515 109.304 96.6098L109.988 95.8984"
            stroke="#1D2224"
            strokeWidth="0.6102"
            strokeLinejoin="round"
          />
          <path
            d="M126.109 114.245L121.63 119.291C121.483 119.409 121.314 119.497 121.133 119.547C120.952 119.598 120.763 119.611 120.576 119.586C120.39 119.561 120.211 119.499 120.049 119.402C119.888 119.305 119.748 119.177 119.637 119.024L116.488 112.227"
            stroke="#1D2224"
            strokeWidth="0.6102"
            strokeLinejoin="round"
          />
          <path
            d="M123.266 117.438L125.165 125.087"
            stroke="#1D2224"
            strokeWidth="0.6102"
            strokeLinejoin="round"
          />
          <path
            d="M118.563 116.707L115.5 122.941"
            stroke="#1D2224"
            strokeWidth="0.6102"
            strokeLinejoin="round"
          />
          <path
            d="M139.731 96.3701L136.922 87.8906C136.157 88.9666 135.171 89.8654 134.031 90.5261"
            stroke="#1D2224"
            strokeWidth="0.6102"
            strokeLinejoin="round"
          />
          <path
            d="M111.923 87.8906L109.992 95.8984"
            stroke="#1D2224"
            strokeWidth="0.6102"
            strokeLinejoin="round"
          />
          <path
            d="M125.274 67.5359C125.989 67.5359 126.568 66.9543 126.568 66.2367C126.568 65.5192 125.989 64.9375 125.274 64.9375C124.56 64.9375 123.98 65.5192 123.98 66.2367C123.98 66.9543 124.56 67.5359 125.274 67.5359Z"
            fill="#1D2224"
          />
          <path
            d="M113.989 67.5359C114.704 67.5359 115.283 66.9543 115.283 66.2367C115.283 65.5192 114.704 64.9375 113.989 64.9375C113.275 64.9375 112.695 65.5192 112.695 66.2367C112.695 66.9543 113.275 67.5359 113.989 67.5359Z"
            fill="#1D2224"
          />
          <path
            d="M150.12 106.707C150.12 106.707 158.824 110.061 151.006 123.624C148.546 127.877 147.213 130.397 146.531 131.839"
            stroke="#1D2224"
            strokeWidth="0.6102"
            strokeLinejoin="round"
          />
          <path
            d="M131.653 161.709C131.653 161.709 128.251 160.18 128.354 159.239C128.457 158.298 135.301 135.823 140.054 132.914C144.806 130.004 147.852 132.513 147.852 132.513C147.852 132.513 152.467 135.023 151.077 141.576C149.687 148.129 141.175 163.781 141.175 163.781L137.837 162.635"
            stroke="#1D2224"
            strokeWidth="0.6102"
            strokeLinejoin="round"
          />
          <path
            d="M94.853 105.035C94.853 105.035 89.9084 121.337 90.917 145.615"
            stroke="#1D2224"
            strokeWidth="0.6102"
            strokeLinejoin="round"
          />
          <path
            d="M90.805 159.254C94.8849 159.234 98.1766 156.021 98.1574 152.077C98.1382 148.133 94.8153 144.953 90.7353 144.973C86.6554 144.993 83.3637 148.206 83.3829 152.15C83.4021 156.093 86.725 159.274 90.805 159.254Z"
            stroke="#1D2224"
            strokeWidth="0.6102"
            strokeLinejoin="round"
          />
          <path
            d="M166.805 235.055V215.288V214.781"
            stroke="#1D2224"
            strokeWidth="0.6102"
            strokeLinejoin="round"
          />
          <path
            d="M73.9688 234.981V205.318V205.309"
            stroke="#1D2224"
            strokeWidth="0.6102"
            strokeLinejoin="round"
          />
          <path
            d="M38.6758 234.922C38.6758 234.922 53.2734 234.949 73.8021 234.976C101.108 235.011 138.877 235.055 166.79 235.055C188.31 235.055 203.963 235.011 204.428 234.922"
            stroke="#1D2224"
            strokeWidth="0.6102"
            strokeLinejoin="round"
          />
          <path
            d="M132.724 163.779C133.717 163.779 134.522 162.971 134.522 161.974C134.522 160.976 133.717 160.168 132.724 160.168C131.731 160.168 130.926 160.976 130.926 161.974C130.926 162.971 131.731 163.779 132.724 163.779Z"
            fill="white"
          />
          <path
            d="M136.943 164.131C137.936 164.131 138.741 163.322 138.741 162.325C138.741 161.328 137.936 160.52 136.943 160.52C135.95 160.52 135.145 161.328 135.145 162.325C135.145 163.322 135.95 164.131 136.943 164.131Z"
            fill="white"
          />
          <path
            d="M194.2 80.7425C210.588 80.7425 223.872 67.4038 223.872 50.9494C223.872 34.4952 210.588 21.1562 194.2 21.1562C177.812 21.1562 164.527 34.4952 164.527 50.9494C164.527 67.4038 177.812 80.7425 194.2 80.7425Z"
            fill={colors.brand.pure}
          />
          <path
            d="M194.092 12.8516C187.432 12.8518 180.889 14.6123 175.121 17.956C169.353 21.2997 164.564 26.1088 161.234 31.9002C157.902 37.6909 156.148 44.2603 156.148 50.9476C156.148 57.6349 157.902 64.2043 161.234 69.995C164.564 75.7864 169.353 80.5955 175.121 83.9391C180.889 87.283 187.432 89.0434 194.092 89.0436C200.752 89.0434 207.295 87.283 213.063 83.9391C218.83 80.5955 223.62 75.7864 226.95 69.995C230.28 64.2038 232.033 57.6346 232.033 50.9476C232.033 44.2606 230.28 37.6914 226.95 31.9002C223.62 26.1088 218.83 21.2997 213.063 17.956C207.295 14.6123 200.752 12.8518 194.092 12.8516Z"
            fill={colors.brand.light200}
          />
          <path
            d="M202.904 41.2916L201.891 40.5753L212.176 25.7578L213.189 26.4741L202.904 41.2916Z"
            fill="white"
          />
          <path
            d="M184.477 63.9336L181.492 68.2185L184.187 70.1113L187.172 65.8264L184.477 63.9336Z"
            fill="white"
          />
          <path
            d="M204.647 45.168L190.6 65.3307C189.862 66.4051 188.61 66.8324 187.823 66.2816L183.832 63.4831C183.048 62.9323 183.023 61.6158 183.771 60.5388L197.818 40.3762C198.556 39.3018 199.808 38.872 200.595 39.4253L204.585 42.2263C205.37 42.7746 205.394 44.0911 204.647 45.168Z"
            fill="white"
          />
          <path
            d="M187.459 74.7758C187.187 75.1806 186.795 75.4906 186.34 75.6625C186.155 75.7408 185.953 75.7722 185.753 75.7542C185.552 75.7361 185.36 75.6689 185.191 75.5588L176.089 69.159C175.449 68.7095 175.435 67.6227 176.042 66.7508C176.316 66.3432 176.708 66.0315 177.166 65.8591C177.352 65.7847 177.553 65.7561 177.752 65.7754C177.951 65.7946 178.142 65.8616 178.31 65.9702L187.412 72.37C188.04 72.8171 188.059 73.8989 187.459 74.7758Z"
            fill="white"
          />
          <path
            d="M206.396 118.445C212.27 118.445 217.031 113.665 217.031 107.768C217.031 101.87 212.27 97.0898 206.396 97.0898C200.523 97.0898 195.762 101.87 195.762 107.768C195.762 113.665 200.523 118.445 206.396 118.445Z"
            fill="#D3FCE9"
          />
          <path
            d="M202.969 108.383C204.859 108.383 206.391 106.845 206.391 104.947C206.391 103.05 204.859 101.512 202.969 101.512C201.079 101.512 199.547 103.05 199.547 104.947C199.547 106.845 201.079 108.383 202.969 108.383Z"
            fill="white"
          />
          <path
            d="M35.869 215.457C41.7422 215.457 46.5035 210.676 46.5035 204.779C46.5035 198.882 41.7422 194.102 35.869 194.102C29.9957 194.102 25.2344 198.882 25.2344 204.779C25.2344 210.676 29.9957 215.457 35.869 215.457Z"
            fill="#D3FCE9"
          />
          <path
            d="M173.472 103.718C176.408 103.718 178.788 101.329 178.788 98.3806C178.788 95.4327 176.408 93.043 173.472 93.043C170.536 93.043 168.156 95.4327 168.156 98.3806C168.156 101.329 170.536 103.718 173.472 103.718Z"
            fill="#D3FCE9"
          />
          <path
            d="M33.1875 205.184C35.0773 205.184 36.6093 203.646 36.6093 201.748C36.6093 199.851 35.0773 198.312 33.1875 198.312C31.2977 198.312 29.7656 199.851 29.7656 201.748C29.7656 203.646 31.2977 205.184 33.1875 205.184Z"
            fill="white"
          />
          <path
            d="M211.808 212.425C214.744 212.425 217.124 210.035 217.124 207.088C217.124 204.14 214.744 201.75 211.808 201.75C208.872 201.75 206.492 204.14 206.492 207.088C206.492 210.035 208.872 212.425 211.808 212.425Z"
            fill="#D3FCE9"
          />
          <path
            d="M171.574 98.9991C172.62 98.9991 173.468 98.1477 173.468 97.0972C173.468 96.047 172.62 95.1953 171.574 95.1953C170.528 95.1953 169.68 96.047 169.68 97.0972C169.68 98.1477 170.528 98.9991 171.574 98.9991Z"
            fill="white"
          />
          <path
            d="M209.61 207.313C210.34 207.313 210.931 206.719 210.931 205.987C210.931 205.254 210.34 204.66 209.61 204.66C208.88 204.66 208.289 205.254 208.289 205.987C208.289 206.719 208.88 207.313 209.61 207.313Z"
            fill="white"
          />
          <path
            d="M86.4957 36.6324C89.4318 36.6324 91.8118 34.2426 91.8118 31.2947C91.8118 28.3468 89.4318 25.957 86.4957 25.957C83.5597 25.957 81.1797 28.3468 81.1797 31.2947C81.1797 34.2426 83.5597 36.6324 86.4957 36.6324Z"
            fill="#D3FCE9"
          />
          <path
            d="M85.695 32.7179C86.7412 32.7179 87.5892 31.8665 87.5892 30.816C87.5892 29.7655 86.7412 28.9141 85.695 28.9141C84.649 28.9141 83.8008 29.7655 83.8008 30.816C83.8008 31.8665 84.649 32.7179 85.695 32.7179Z"
            fill="white"
          />
          <path
            d="M195.756 179.938C206.631 179.938 215.446 171.087 215.446 160.168C215.446 149.25 206.631 140.398 195.756 140.398C184.882 140.398 176.066 149.25 176.066 160.168C176.066 171.087 184.882 179.938 195.756 179.938Z"
            fill="white"
          />
          <path
            d="M195.761 175.395C204.136 175.395 210.925 168.579 210.925 160.17C210.925 151.762 204.136 144.945 195.761 144.945C187.387 144.945 180.598 151.762 180.598 160.17C180.598 168.579 187.387 175.395 195.761 175.395Z"
            fill={colors.brand.pure}
          />
          <path
            d="M195.575 157.086C195.396 156.202 197.199 154.74 197.802 154.421C200.001 153.265 203.379 154.137 204.584 156.345C207.344 161.408 199.583 168.628 195.578 170.409C195.115 170.039 184.151 161.804 186.722 156.533C189.293 151.262 194.941 153.942 195.575 157.086Z"
            fill="white"
          />
        </svg>
      )}
    </>
  );
}
