import React from "react";
import { Title } from "./styles";

export function MedtrackTitle({ isRevision }) {
  return (
    <Title>
      Bem-vindo(a) ao{" "}
      {isRevision ? (
        <i style={{ fontWeight: "bold" }}>Medtrack Revisão</i>
      ) : (
        "Medtrack"
      )}
    </Title>
  );
}
