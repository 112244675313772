import { connect } from "react-redux";
import buildForm from "../library/forms/buildForm";
import CommentForm from "./CommentForm";
import { createComment } from "../../processes/commentProcesses";

const form = buildForm("commentForm");

function mapStateToProps(state, ownProps) {
  const currentClassroomId = state.currentClassroomId;
  return {
    initialValues: {
      classroomId: currentClassroomId,
    },
    lessonId: ownProps.lessonId,
    currentClassroomId,
  };
}

export default connect(mapStateToProps)(
  form.connect({
    onSubmit: createComment,
  })(CommentForm)
);
