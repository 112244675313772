import styled from "styled-components";
import { colors, spacing } from "../../library/theme";
import * as Dialog from "@radix-ui/react-dialog";

export const DialogOverlay = styled(Dialog.Overlay)`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: fixed;
  overflow: auto;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: rgba(54, 62, 70, 0.3);
  z-index: 10;
`;

export const DialogContent = styled(Dialog.Content)`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background-color: ${colors.interface.white000};
  padding: 24px;
  width: min(772px, calc(100vw - 48px));
  margin: 54px 14px 32px 24px;
  position: relative;
  min-height: 60vh;
`;

export const DeletedMessage = styled.span`
  font-size: 14px;
  color: ${colors.interface.grey400};
  background-color: ${colors.interface.grey100};
  border-radius: 4px;
  text-align: center;
  width: 100%;
  display: block;
  padding: 12px 0;
  margin: 16px 0;
`;

export const QuestionTitle = styled.h2`
  color: ${colors.interface.grey500};
  font-size: 16px;
  line-height: 21px;
  margin: 8px 0 0;
`;

export const QuestionDescription = styled.p`
  color: ${colors.interface.grey400};
  font-size: 14px;
  line-height: 21px;
  margin: 16px 0 32px;

  p,
  span {
    font-size: 14px;
  }
`;

export const QuestionImg = styled.img`
  object-fit: contain;
  border-radius: 6px;
  width: 300px;

  + ol {
    margin-top: 24px;
  }
`;

export const CloseButton = styled.button`
  align-self: flex-end;
  background-color: transparent;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.2s;

  &:hover {
    background-color: ${colors.interface.grey100};
  }

  svg {
    line-height: 0;
    color: ${colors.interface.grey400};
  }

  @media (max-width: 1024px) {
    background-color: ${colors.interface.grey100};
  }
`;

export const AlternativesContainer = styled.ol`
  margin: 0 0 16px;
  counter-reset: list;
  padding: 0;
`;

export const QuestionCommentWrapper = styled.div`
  margin-bottom: ${spacing.m};
  padding: ${spacing.m} 0;
`;
