import styled from "styled-components";

export const DeviceTitleContainer = styled.div`
  display: flex;
  color: #606a71;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  gap: 8px;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  padding-top: 50px;
  justify-content: start;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  min-height: 100vh;
  @media screen and (max-width: 768px) {
    padding: 50px 24px 0px 24px;
  }
`;

export const Logo = styled.img`
  width: 200px;
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  h1 {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    line-height: 24px;
    color: #606a71;
  }
  h2 {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #606a71;
  }
`;
export const WarningContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
  padding: 16px 0 16px 16px;
  background-color: #f7fafc;
  border-radius: 4px;
  border: 1px solid #edf0f2;
  span {
    font-weight: 400;
    line-height: 21px;
    font-size: 14px;
  }
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  padding-top: 8px;
  flex-direction: column;
  align-items: center;
  display: flex;
  gap: 32px;
  a {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    :hover {
      color: #009a5a;
      transition-duration: 200ms;
    }
  }
  button {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 580px;
  gap: 24px;
  align-items: center;
`;

export const DeviceInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  h1 {
    font-size: 14px;
    font-weight: 400px;
    line-height: 21px;
    margin: 0;
    color: #606a71;
  }
  h2 {
    color: #9ba5ab;
    font-size: 12px;
    font-weight: 400px;
    line-height: 18px;
  }
`;

export const DeviceContainer = styled.div`
  width: 100%;
  display: flex;
  border: 1px solid #c2ced6;
  border-radius: 4px;
  align-items: center;
  gap: 24px;
  padding: 24px;
`;
