import { mixpanel } from "..";
// @ts-check
/**
 * @param {{
 * name: string,
 * email: string,
 * signUpDate: Date,
 * provider: string,
 * }} userData
 */

export function trackSignUpEvent(userData) {
  const { name, email, signUpDate, provider } = userData;
  const signUpData = {
    Email: email,
    Name: name,
    SignUpDate: signUpDate.toISOString(),
    Provider: provider.toUpperCase(),
  };

  mixpanel.track("Plataforma - Fez Cadastro", signUpData);
}
