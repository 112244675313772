import * as React from 'react';
import { colors } from '../../../components/library/theme';

export function NotificationToastIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={120}
      height={120}
      fill="none"
    >
      <g clipPath="url(#a)">
        <path
          fill={colors.brand.pure}
          d="M2.57 50.19S.352 71.597 16.163 89.347c15.81 17.75 40.262 23.692 59.987 29.033 19.724 5.34 36.554-2.866 41.188-19.452 4.635-16.586-9.899-24.642-15.497-45.705-5.597-21.062-3.997-27.294-19.831-42.735-15.835-15.44-44.148-13.595-61.272 3.979C3.614 32.039 2.57 50.191 2.57 50.191Z"
        />
        <path
          fill="#fff"
          d="M2.57 50.19S.352 71.597 16.163 89.347c15.81 17.75 40.262 23.692 59.987 29.033 19.724 5.34 36.554-2.866 41.188-19.452 4.635-16.586-9.899-24.642-15.497-45.705-5.597-21.062-3.997-27.294-19.831-42.735-15.835-15.44-44.148-13.595-61.272 3.979C3.614 32.039 2.57 50.191 2.57 50.191Z"
          opacity={0.9}
        />
        <mask
          id="b"
          width={114}
          height={94}
          x={14}
          y={18}
          maskUnits="userSpaceOnUse"
          style={{
            maskType: 'luminance',
          }}
        >
          <path
            fill="#fff"
            d="M127.558 18H43.277v25A31.87 31.87 0 0 0 15.48 75.759c.19 5.262-2.638 8.697.02 13.242 3.67 4.171 10.717 10.058 15.21 12.804 4.492 2.745 14.782 7.36 21.289 9.696 4.839 1.502 4.066-5.791 8.835-8.022a31.87 31.87 0 0 0 12.055-9.831h54.668V18Z"
          />
        </mask>
        <g mask="url(#b)">
          <path
            fill="#fff"
            d="M11.644 106.619s8.823-30.722 10.49-37.29c1.665-6.567 4.185-10.84 9.383-11.882 5.199-1.043 14.796-2.01 14.796-2.01s8.342-.837 14.386-.418c6.044.419 11.574 3.65 14.491 5.86 2.917 2.21 11.364 9.798 11.364 9.798l-1.042-17.512 7.61.623s5.524 24.394 6.252 28.881c.729 4.487.733 6.467 0 6.986-.732.519.524.837-6.148-1.356-6.672-2.194-14.49-5.211-18.764-6.463-4.274-1.251-4.797-1.46-4.797-1.46s-10.464 15.746-13.792 20.438a80.916 80.916 0 0 0-6.077 10.464"
          />
          <mask
            id="c"
            width={89}
            height={59}
            x={11}
            y={53}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="M11.644 106.619s8.823-30.722 10.49-37.29c1.665-6.567 4.185-10.84 9.383-11.882 5.199-1.043 14.796-2.01 14.796-2.01s8.342-.837 14.386-.418c6.044.419 11.574 3.65 14.491 5.86 2.917 2.21 11.364 9.798 11.364 9.798l-1.042-17.512 7.61.623s5.524 24.394 6.252 28.881c.729 4.487.733 6.467 0 6.986-.732.519.524.837-6.148-1.356-6.672-2.194-14.49-5.211-18.764-6.463-4.274-1.251-4.797-1.46-4.797-1.46s-10.464 15.746-13.792 20.438a80.916 80.916 0 0 0-6.077 10.464"
            />
          </mask>
          <g mask="url(#c)">
            <path
              fill="#000"
              d="M99.362 89.621c.733-.519.733-2.511 0-6.985-.418-2.675-2.574-12.432-4.232-19.874.657 8.79.913 17.379-.418 18.982-2.93 3.466-7.212-1.088-7.212-1.088l-.816-6.756S72.093 63.14 66.618 61.314c-5.475-1.825-11.301-2.189-16.964-.364-5.663 1.825-10.397 3.65-13.863 7.115-3.466 3.466-15.487 31.192-15.487 31.192l-1.36 8.233 30.84 3.733a81.027 81.027 0 0 1 6.077-10.464c3.349-4.692 13.792-20.438 13.792-20.438s.523.21 4.796 1.46c4.274 1.252 12.093 4.274 18.764 6.463 6.672 2.19 5.42 1.9 6.15 1.377Z"
              opacity={0.2}
            />
          </g>
          <path
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={0.334}
            d="M11.644 106.619s8.823-30.722 10.49-37.29c1.665-6.567 4.185-10.84 9.383-11.882 5.199-1.043 14.796-2.01 14.796-2.01s8.342-.837 14.386-.418c6.044.419 11.574 3.65 14.491 5.86 2.917 2.21 11.364 9.798 11.364 9.798l-1.042-17.512 7.61.623s5.524 24.394 6.252 28.881c.729 4.487.733 6.467 0 6.986-.732.519.524.837-6.148-1.356-6.672-2.194-14.49-5.211-18.764-6.463-4.274-1.251-4.797-1.46-4.797-1.46s-10.464 15.746-13.792 20.438a80.916 80.916 0 0 0-6.077 10.464"
          />
          <path
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={0.334}
            d="m72.628 75.507-2.976 4.839 8.807-4.839"
          />
          <path
            fill="#fff"
            d="M86.541 70.648s.42 8.652.938 10.008c.52 1.356 2.817 1.98 2.817 1.98"
          />
          <path
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={0.334}
            d="M86.541 70.648s.42 8.652.938 10.008c.52 1.356 2.817 1.98 2.817 1.98"
          />
          <path
            fill="#263238"
            d="M40.605 70.384s-1.139 2.7-.57 2.7c.57 0 4.266-1.419 5.973-1.419 1.708 0 3.554 2.984 3.554 2.984s4.977-3.695 6.542-3.269c.767.257 1.47.674 2.063 1.223a.695.695 0 0 0 1.151-.498c.03-.766.055-1.813.055-3.144 0-3.126-2.7-11.657-7.676-12.084-4.977-.427-5.831 3.127-7.953 7.392-2.122 4.265-3.14 6.115-3.14 6.115Z"
          />
          <path
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={0.334}
            d="M51.307 51.989s-4.797.636-6.986 2.729c-2.189 2.092-1.46 5.42-2.608 8.442-1.146 3.022-3.126 9.175-2.092 9.07 1.033-.105 4.692-3.022 5.734-2.294 1.042.729 3.44 3.767 3.859 3.96.419.193 5.734-3.646 6.463-4.064.728-.419 4.273 4.185 4.378 2.93.104-1.256.314-8.652-.419-13.135-.732-4.483-5.93-7.534-8.33-7.639Z"
          />
          <path
            fill="#fff"
            d="M52.973 46.15s-1.771 5.42-3.24 7.312c-1.47 1.892-2.093 2.189-2.604 6.567-.51 4.378 1.771 14.281 2.512 14.072.74-.21 7.923-11.674 10.322-13.553 2.398-1.88 4.9-3.855 4.9-3.855s-5.838-1.775-7.818-4.273a55.831 55.831 0 0 1-4.072-6.27Z"
          />
          <mask
            id="d"
            width={18}
            height={29}
            x={47}
            y={46}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="M52.973 46.15s-1.771 5.42-3.24 7.312c-1.47 1.892-2.093 2.189-2.604 6.567-.51 4.378 1.771 14.281 2.512 14.072.74-.21 7.923-11.674 10.322-13.553 2.398-1.88 4.9-3.855 4.9-3.855s-5.838-1.775-7.818-4.273a55.831 55.831 0 0 1-4.072-6.27Z"
            />
          </mask>
          <g mask="url(#d)">
            <path
              fill="#000"
              d="M60.997 59.74c2.03-1.595 3.863-3.043 3.863-3.043s-5.839-1.774-7.818-4.273a55.834 55.834 0 0 1-4.069-6.258 52.283 52.283 0 0 1-2.122 5.391c.883 2.474 3.344 6.509 10.146 8.183Z"
              opacity={0.2}
            />
            <path
              fill="#263238"
              d="M57.041 52.407a55.816 55.816 0 0 1-4.068-6.257s-.595 1.82-1.377 3.716c.649 1.88 1.95 4.676 4.282 6.325a19.41 19.41 0 0 0 6.178 2.712l2.804-2.21s-5.839-1.787-7.819-4.286Z"
            />
          </g>
          <path
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={0.334}
            d="M52.973 46.15s-1.771 5.42-3.24 7.312c-1.47 1.892-2.093 2.189-2.604 6.567-.51 4.378 1.771 14.281 2.512 14.072.74-.21 7.923-11.674 10.322-13.553 2.398-1.88 4.9-3.855 4.9-3.855s-5.838-1.775-7.818-4.273a55.831 55.831 0 0 1-4.072-6.27Z"
          />
          <path
            fill="#fff"
            d="M75.178 32.287s-.31 6.986-.105 9.275c.205 2.29 1.042 6.279-.837 10.008-1.88 3.73-3.44 6.153-5.211 6.776-1.77.624-7.505-.623-12.092-4.064-4.588-3.44-5.42-10.526-6.153-12.82-.733-2.294.104-10.113 4.797-12.616A16.554 16.554 0 0 1 67.877 27.7c2.507.833 7.3 4.588 7.3 4.588Z"
          />
          <mask
            id="e"
            width={26}
            height={32}
            x={50}
            y={27}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="M75.178 32.287s-.31 6.986-.105 9.275c.205 2.29 1.042 6.279-.837 10.008-1.88 3.73-3.44 6.153-5.211 6.776-1.77.624-7.505-.623-12.092-4.064-4.588-3.44-5.42-10.526-6.153-12.82-.733-2.294.104-10.113 4.797-12.616A16.554 16.554 0 0 1 67.877 27.7c2.507.833 7.3 4.588 7.3 4.588Z"
            />
          </mask>
          <g mask="url(#e)">
            <path
              fill="#000"
              d="M55.593 28.846c-.669.363-1.275.83-1.795 1.386l2.415 1.716s3.102-3.102 10.045 1.276c3.583 2.26 6.672 2.625 8.828 2.445.05-1.859.117-3.382.117-3.382s-4.792-3.767-7.295-4.604c-4.101-1.218-8.514-.801-12.315 1.163Z"
              opacity={0.2}
            />
          </g>
          <path
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={0.334}
            d="M75.178 32.287s-.31 6.986-.105 9.275c.205 2.29 1.042 6.279-.837 10.008-1.88 3.73-3.44 6.153-5.211 6.776-1.77.624-7.505-.623-12.092-4.064-4.588-3.44-5.42-10.526-6.153-12.82-.733-2.294.104-10.113 4.797-12.616A16.554 16.554 0 0 1 67.877 27.7c2.507.833 7.3 4.588 7.3 4.588Z"
          />
          <path
            fill="#263238"
            d="M65.333 41.709c0 .74-.289 1.343-.644 1.343-.356 0-.645-.602-.645-1.343s.289-1.348.645-1.348c.355 0 .644.603.644 1.348ZM72.947 42.408c0 .745-.289 1.347-.645 1.347-.356 0-.644-.602-.644-1.347s.288-1.344.644-1.344c.356 0 .645.603.645 1.344Z"
          />
          <path
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={0.334}
            d="M70.904 40.42s-1.98 1.98-1.461 2.812c.519.833 1.733 1.302 1.356 2.511-.377 1.21-3.127 1.675-4.064 0"
          />
          <path
            fill="#263238"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={0.334}
            d="M60.792 46.882a8.601 8.601 0 0 0 6.362 3.127s-4.278 2.813-6.153 2.511c-1.875-.301-.21-5.638-.21-5.638Z"
          />
          <path
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={0.334}
            d="M61.792 37.732s1.816-2.511 5.441-.95M71.632 37.812s2.294-.528 3.127 1.674"
          />
          <path
            fill="#263238"
            d="M51.516 44.274s.938-4.064 3.231-5.525c2.294-1.46 4.483-1.146 3.127-3.126-1.356-1.98-2.093-3.964.837-4.902 2.93-.937 8.652.628 11.988 1.88 3.336 1.251 9.798 1.674 11.468-3.026 1.03-2.897-1.984-5.203-4.185-6.388-2.44-1.306-5.178-1.954-7.932-1.469-1.674.289-3.27.946-4.943 1.113-2.834.29-5.333-1.025-8.158-.343a11.234 11.234 0 0 0-6.048 3.767c-2.616 3.215-3.349 9.054.615 18.02Z"
          />
          <mask
            id="f"
            width={35}
            height={24}
            x={48}
            y={21}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="M51.516 44.274s.938-4.064 3.231-5.525c2.294-1.46 4.483-1.146 3.127-3.126-1.356-1.98-2.093-3.964.837-4.902 2.93-.937 8.652.628 11.988 1.88 3.336 1.251 9.798 1.674 11.468-3.026 1.03-2.897-1.984-5.203-4.185-6.388-2.44-1.306-5.178-1.954-7.932-1.469-1.674.289-3.27.946-4.943 1.113-2.834.29-5.333-1.025-8.158-.343a11.234 11.234 0 0 0-6.048 3.767c-2.616 3.215-3.349 9.054.615 18.02Z"
            />
          </mask>
          <g
            stroke="gray"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={0.334}
            mask="url(#f)"
          >
            <path d="M75.655 21.521s5.416 2.93 1.624 7.798c-3.792 4.868-7.362 1.52-16.357-.213s-14.298 2.708-14.298 2.708M47.38 37.138s1.734-3.349 7.367-4.332c5.634-.984 5.96-.109 5.96-.109" />
          </g>
          <path
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={0.334}
            d="M51.516 44.274s.938-4.064 3.231-5.525c2.294-1.46 4.483-1.146 3.127-3.126-1.356-1.98-2.093-3.964.837-4.902 2.93-.937 8.652.628 11.988 1.88 3.336 1.251 9.798 1.674 11.468-3.026 1.03-2.897-1.984-5.203-4.185-6.388-2.44-1.306-5.178-1.954-7.932-1.469-1.674.289-3.27.946-4.943 1.113-2.834.29-5.333-1.025-8.158-.343a11.234 11.234 0 0 0-6.048 3.767c-2.616 3.215-3.349 9.054.615 18.02Z"
          />
          <path
            fill="#fff"
            d="M53.873 42.793s-2.704-6.19-5.086-2.968c-2.381 3.223 1.934 7.3 5.174 6.509"
          />
          <path
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={0.334}
            d="M53.873 42.793s-2.704-6.19-5.086-2.968c-2.381 3.223 1.934 7.3 5.174 6.509"
          />
          <path
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={0.334}
            d="M83.31 42.085s-1.77-.209-2.092 1.256c-.323 1.465-.628 3.767 0 3.767.627 0 1.77-.31 2.189-1.256.418-.945.837-3.859.837-3.859l-.934.092Z"
          />
          <path
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={0.334}
            d="M86.123 41.562s-2.511-.418-2.813.523c-.3.942-.728 4.186-.418 4.274.31.088 2.189-.728 2.189-.728l1.042-4.069Z"
          />
          <path
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={0.334}
            d="M89.459 41.771s-2.93-1.875-3.646-.937c-.716.937-.732 4.796-.732 4.796a1.594 1.594 0 0 0 1.46-.418 40.498 40.498 0 0 0 1.147-1.457s2.093.52 2.399.419c.305-.1 1.042 0 .837-1.042-.205-1.042-1.465-1.36-1.465-1.36Z"
          />
          <path
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={0.334}
            d="M93.423 43.65a9.811 9.811 0 0 1-3.127-3.544c-1.042-2.19-1.565-2.093-2.093-1.147-.527.946.1 1.875.52 2.708.418.833 2.188 2.817 2.188 2.817l2.512-.833Z"
          />
          <path
            fill={colors.brand.pure}
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={0.334}
            d="M74.671 58.233s-1.51 12.905-1.276 13.604c.234.699 2.787 1.51 3.37 1.046.581-.465.234-1.394 0-1.976-.235-.581 1.975-11.393 1.975-11.393l-4.069-1.28Z"
          />
          <path
            fill="#fff"
            d="m36.603 62.704 7.652 23.895s17.58-10.238 21.698-15.579c4.118-5.34 6.496-11.363 7.952-11.928 1.457-.566 6.597.837 7.35 1.674.754.837.578 1.754-.334 2.616-.913.862-1.432.983-1.432.983s-.046 1.88-.456 2.206c-.41.327-.314-.096-.419.322a3.251 3.251 0 0 1-.481 1.256c-.301.419-.419.636-.707.75-.6.255-1.224.448-1.863.577-1.125.29-2.27.5-3.424.628-.724.125-5.441 9.447-9.87 15.001-4.428 5.554-18.416 18.291-20.614 18.358-2.197.067-15.106-21.254-17.872-26.49"
          />
          <mask
            id="g"
            width={59}
            height={46}
            x={23}
            y={58}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="m36.603 62.704 7.652 23.895s17.58-10.238 21.698-15.579c4.118-5.34 6.496-11.363 7.952-11.928 1.457-.566 6.597.837 7.35 1.674.754.837.578 1.754-.334 2.616-.913.862-1.432.983-1.432.983s-.046 1.88-.456 2.206c-.41.327-.314-.096-.419.322a3.251 3.251 0 0 1-.481 1.256c-.301.419-.419.636-.707.75-.6.255-1.224.448-1.863.577-1.125.29-2.27.5-3.424.628-.724.125-5.441 9.447-9.87 15.001-4.428 5.554-18.416 18.291-20.614 18.358-2.197.067-15.106-21.254-17.872-26.49"
            />
          </mask>
          <g mask="url(#g)">
            <path
              fill="#000"
              d="M78.133 68.14c.246-.38.41-.807.481-1.255.092-.419 0 0 .419-.322.418-.323.456-2.206.456-2.206s.52-.121 1.431-.984c.913-.862 1.093-1.804.335-2.616-.757-.812-5.905-2.218-7.35-1.674-.376.147-.837.67-1.339 1.457.766 1.255 1.674 2.021.837 4.215-1.256 3.348-4.185 7.534-6.697 11.092-2.511 3.557-23.44 23.23-25.95 22.811-2.052-.343-8.008-7.534-10.126-10.142 4.538 7.28 9.728 14.968 11.046 14.93 2.19-.067 16.194-12.8 20.614-18.358 4.42-5.558 9.125-14.884 9.85-14.993a24.521 24.521 0 0 0 3.423-.627 9.594 9.594 0 0 0 1.862-.578c.31-.113.407-.322.708-.75Z"
              opacity={0.2}
            />
          </g>
          <path
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={0.334}
            d="m36.603 62.704 7.652 23.895s17.58-10.238 21.698-15.579c4.118-5.34 6.496-11.363 7.952-11.928 1.457-.566 6.597.837 7.35 1.674.754.837.578 1.754-.334 2.616-.913.862-1.432.983-1.432.983s-.046 1.88-.456 2.206c-.41.327-.314-.096-.419.322a3.251 3.251 0 0 1-.481 1.256c-.301.419-.419.636-.707.75-.6.255-1.224.448-1.863.577-1.125.29-2.27.5-3.424.628-.724.125-5.441 9.447-9.87 15.001-4.428 5.554-18.416 18.291-20.614 18.358-2.197.067-15.106-21.254-17.872-26.49"
          />
          <path
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={0.334}
            d="M44.255 86.6s-2.005.083-3.705 5.44"
          />
          <path
            fill="#fff"
            d="M75.563 69.468a9.594 9.594 0 0 0 1.863-.578c.31-.113.418-.322.707-.75.246-.38.41-.807.481-1.255.092-.419.364-.56.77-.887.406-.327.093-1.641.093-1.641s.519-.121 1.431-.984c.912-.862 1.093-1.804.335-2.616s-5.906-2.218-7.35-1.674-3.629 6.099-7.417 11.26L70 73.23c1.105-1.842 1.892-3.09 2.152-3.136 1.15-.128 2.29-.338 3.411-.627Z"
          />
          <mask
            id="h"
            width={16}
            height={16}
            x={66}
            y={58}
            maskUnits="userSpaceOnUse"
            style={{
              maskType: 'luminance',
            }}
          >
            <path
              fill="#fff"
              d="M75.563 69.468a9.594 9.594 0 0 0 1.863-.578c.31-.113.418-.322.707-.75.246-.38.41-.807.481-1.255.092-.419.364-.56.77-.887.406-.327.093-1.641.093-1.641s.519-.121 1.431-.984c.912-.862 1.093-1.804.335-2.616s-5.906-2.218-7.35-1.674-3.629 6.099-7.417 11.26L70 73.23c1.105-1.842 1.892-3.09 2.152-3.136 1.15-.128 2.29-.338 3.411-.627Z"
            />
          </mask>
          <g mask="url(#h)">
            <path
              fill="#000"
              d="M78.133 68.14c.246-.38.41-.807.481-1.255.092-.419 0 0 .419-.322.418-.323.456-2.206.456-2.206s.52-.121 1.431-.984c.913-.862 1.093-1.804.335-2.616-.757-.812-5.905-2.218-7.35-1.674-.376.147-.837.67-1.339 1.457.766 1.255 1.674 2.021.837 4.215-1.256 3.348-4.185 7.534-6.697 11.092-2.511 3.557-23.44 23.23-25.95 22.811-2.052-.343-8.008-7.534-10.126-10.142 4.538 7.28 9.728 14.968 11.046 14.93 2.19-.067 16.194-12.8 20.614-18.358 4.42-5.558 9.125-14.884 9.85-14.993a24.521 24.521 0 0 0 3.423-.627 9.594 9.594 0 0 0 1.862-.578c.31-.113.407-.322.708-.75Z"
              opacity={0.2}
            />
          </g>
          <path
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={0.334}
            d="M75.563 69.468a9.594 9.594 0 0 0 1.863-.578c.31-.113.418-.322.707-.75.246-.38.41-.807.481-1.255.092-.419.364-.56.77-.887.406-.327.093-1.641.093-1.641s.519-.121 1.431-.984c.912-.862 1.093-1.804.335-2.616s-5.906-2.218-7.35-1.674-3.629 6.099-7.417 11.26L70 73.23c1.105-1.842 1.892-3.09 2.152-3.136 1.15-.128 2.29-.338 3.411-.627Z"
          />
          <path
            fill={colors.brand.pure}
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={0.334}
            d="M71.008 49.8c-1.15 0-2.093 1.674-2.093 3.7 0 2.025.934 3.7 2.093 3.7l25.533 7.714V41.772L71.008 49.8Z"
          />
          <path
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={0.334}
            d="m85.373 46.719-9.961 2.867M88.706 45.76l-2.085.603"
          />
          <path
            fill="#263238"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={0.334}
            d="m74.277 48.77-3.269 1.03c-1.15 0-2.093 1.674-2.093 3.7 0 2.025.934 3.7 2.093 3.7l2.951.891a10.179 10.179 0 0 1 .318-9.321Z"
          />
          <path
            fill="#D6D6D6"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={0.334}
            d="M98.739 69.04c4.836 0 8.756-6.957 8.756-15.54 0-8.584-3.92-15.542-8.756-15.542s-8.757 6.958-8.757 15.542c0 8.583 3.92 15.54 8.757 15.54Z"
          />
          <path
            fill="#D6D6D6"
            d="m98.738 50.05-8.342-2.23-.728 5.943.938 6.044 8.132-2.858.314-6.73"
          />
          <path
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={0.334}
            d="m98.738 50.05-8.342-2.23-.728 5.943.938 6.044 8.132-2.858.314-6.73"
          />
          <path
            fill="#263238"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={0.334}
            d="M98.739 36.56c-5.27 0-9.544 7.585-9.544 16.94 0 9.354 4.274 16.939 9.544 16.939 5.269 0 9.543-7.585 9.543-16.94 0-9.354-4.274-16.939-9.543-16.939Zm.234 31.476c-4.39 0-7.953-6.508-7.953-14.537 0-8.028 3.562-14.532 7.953-14.532s7.953 6.505 7.953 14.532c0 8.029-3.558 14.537-7.953 14.537Z"
          />
          <path
            fill={colors.brand.pure}
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={0.334}
            d="M98.739 56.948c1.072 0 1.942-1.544 1.942-3.449 0-1.904-.87-3.449-1.942-3.449-1.073 0-1.943 1.545-1.943 3.45 0 1.904.87 3.448 1.942 3.448Z"
          />
          <path
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={0.334}
            d="m109.474 40.223 11.485-3.558M110.981 52.646l10.539.376M119.712 68.128l2.185.703M110.228 65.069l7.752 2.503M110.605 45.87l5.646-.754M107.968 69.208l4.144 3.39"
          />
        </g>
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h120v120H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
