import React from "react";
import { Field } from "redux-form";
import buildFormField from "./buildFormField";
import SearchInput from "./SearchInput";

const FormField = buildFormField(SearchInput, (input, meta, rest) => {
  return {
    ...input,
    autoComplete: "off",
    ...rest,
    invalid: `${meta.touched && (meta.invalid || rest.invalid)}`,
  };
});

export default function SearchField(props) {
  return <Field {...props} component={FormField} />;
}
