import * as S from './styles';

import React, { useEffect, useRef, useState } from 'react';
import ArrowDownIcon from './arrowDownIcon';
import { StarIcon } from './StarIcon';
import { MedTrackTooltip } from '../../../MedTrackTooltip';
import { bigAreaNames } from '../constants';
import { StyledSpinner } from '../styles';

export function DropdownAutocomplete({
  options,
  selected,
  setSelected,
  error,
  label,
  disabled,
  setFilterError,
  isBigAreaOptionsLoading,
  levelName,
}) {
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [isActive, setIsActive] = useState(false);
  const isFirstOptionSelected =
    options && selected?.levelName === options[0] ? true : false;
  const [isSelected, setIsSelected] = useState(isFirstOptionSelected);
  const modalRef = useRef(null);

  function handleClick() {
    setIsActive((event) => !event);
  }

  function handleFilter(event) {
    const userInputSearch = event.target.value.toLowerCase();
    setSelected(userInputSearch);

    const filteredOptions = options.map((option) => {
      const filteredLevels = option.levels.filter((level) =>
        level?.levelName.toLowerCase().includes(userInputSearch)
      );

      return { bigArea: option.bigArea, levels: filteredLevels };
    });

    const filteredOptionsWithLevels = filteredOptions.filter(
      (filteredOption) => filteredOption.levels.length > 0
    );

    setFilteredOptions(filteredOptionsWithLevels);
  }

  function handleSelection(level) {
    setSelected(level);
    setIsSelected(true);
    setIsActive(false);
    setFilterError();
  }

  function resetDropdown() {
    setIsActive(false);
    setIsSelected(false);
    setSelected('');
    setFilteredOptions(options);
    setFilterError();
  }

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  useEffect(() => {
    function checkIfClickedOutside(e) {
      if (
        isActive &&
        modalRef.current &&
        !modalRef.current.contains(e.target)
      ) {
        setIsActive(false);
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isActive]);

  useEffect(() => {
    if (isBigAreaOptionsLoading || disabled) {
      resetDropdown();
    }
  }, [isBigAreaOptionsLoading, disabled]);

  return (
    <div>
      <S.ContainerDropdown
        isActive={isActive}
        ref={modalRef}
        hasValidationError={error}
      >
        <S.InputWrapper
          isActive={isActive || isSelected}
          hasValidationError={error}
        >
          <S.DropdownButton
            isBigAreaOptionsLoading={isBigAreaOptionsLoading}
            disabled={disabled}
            placeholder={label}
            onClick={handleClick}
            onInput={handleFilter}
            value={levelName ?? selected?.levelName}
          />

          <S.Icon>
            {isBigAreaOptionsLoading ? (
              <StyledSpinner inline size="small" />
            ) : (
              <ArrowDownIcon />
            )}
          </S.Icon>
        </S.InputWrapper>

        {isActive && (
          <S.DropdownContent>
            {filteredOptions?.length > 0 ? (
              filteredOptions?.map((filteredOption) => (
                <div key={filteredOption.bigArea}>
                  <S.BigAreaTitle>
                    {bigAreaNames[filteredOption.bigArea]}
                  </S.BigAreaTitle>

                  {filteredOption?.levels?.map((level) => (
                    <S.OptionContainer
                      key={level.levelName}
                      onClick={() => handleSelection(level)}
                    >
                      {level.levelName}
                      {level.isRecommended && <StarIcon />}
                    </S.OptionContainer>
                  ))}
                </div>
              ))
            ) : (
              <S.BigAreaTitle>Nenhum assunto encontrado.</S.BigAreaTitle>
            )}
          </S.DropdownContent>
        )}
      </S.ContainerDropdown>
      {error && <S.Error>{error}</S.Error>}
    </div>
  );
}
