import React, { useEffect } from 'react';
import { requestValidateFingerprint } from '../../utils/requestValidateDevice';
import { Container, Logo, StyledSpinner, TextWrapper } from './styles';

import CryptoJS from 'crypto-js';
import { apiPost } from '../../../../processes/helpers/api';
import { saveTheme } from '../../../Frial/signIn';
import { setSession } from '../../../../processes/sessionProcesses';
import { getCookie } from 'cookies-next';
import logo from '../../../../../../assets/images/logo.svg';
import revalidaLogo from '../../../../../../assets/images/logorevalidav2.svg';

export default function MobileValidation() {
  const param = new URLSearchParams(window.location.search);
  const authenticate = param.get('authenticate');
  const email = getCookie('@emr:email');
  const encryptedPassword = getCookie('@emr:password');

  function signIn() {
    const password = CryptoJS.AES.decrypt(
      encryptedPassword,
      'emr-encrypt'
    ).toString(CryptoJS.enc.Utf8);

    if (email && password) {
      apiPost('/api/v1/auth/sign_in', true, true)
        .send({ email, password })
        .then((res) => {
          saveTheme();
          setSession(res);
          window.location = '/';
        });
    }
  }

  async function handleValidation() {
    try {
      const response = await requestValidateFingerprint(email, authenticate);

      if (response.isValid) {
        signIn();
      }
    } catch (err) {
      alert(err);
      console.log(err);
    }
  }

  useEffect(() => {
    handleValidation();
  }, []);
  return (
    <Container>
      <Logo src={IES === 'revalida' ? revalidaLogo : logo} />
      <StyledSpinner />
      <TextWrapper>
        <h1>Verificando credenciais...</h1>
        <span>
          Esta ação pode levar alguns segundos. Em breve você será
          redirecionado.
        </span>
      </TextWrapper>
    </Container>
  );
}
