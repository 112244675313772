const getNotifications = (action) => action.entities.notifications;

export function notificationsReducer(state = {}, action) {
  switch (action.type) {
    case "NOTIFICATIONS_FETCHED":
      return { ...state, ...getNotifications(action) };
    default:
      return state;
  }
}

export const SET_UNREAD_COUNT = "SET_UNREAD_COUNT";
export const setUnreadCount = (count) => ({
  type: SET_UNREAD_COUNT,
  payload: count,
});

const initialState = {
  notifications: [],
  unreadCount: 0,
};

export function notificationsUserReducer(state = initialState, action) {
  switch (action.type) {
    case SET_UNREAD_COUNT:
      return {
        ...state,
        unreadCount: action.payload,
      };
    default:
      return state;
  }
}
