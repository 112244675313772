import { detectPlatform } from './detectPlatform';

export async function requestValidateCode(
  code,
  ignoreIp = false,
  fingerprint,
  email
) {
  const response = await fetch(
    `${process.env.USER_PROXY_URL}/auth/validate-2fa-code?code=${code}&ignore_ip=${ignoreIp}&fingerprint=${fingerprint}&email=${email}`,
    {
      method: 'GET',
      headers: {
        'api-token': process.env.USER_PROXY_API_TOKEN,
        'Content-Type': 'application/json',
        'access-date': new Date().toISOString(),
        platform: detectPlatform(),
        product: 'PLATAFORMA_EMR',
      },
    }
  );
  return response;
}
