import { connect, shallowEqual } from "react-redux";
import { makeIsWatched } from "../../selectors/lessonsUserSelectors";
import { makeIsRatingPending } from "../../selectors/ratingSelectors";
import LessonListItem from "./LessonListItem";
import { getCurrentUserId } from "../../selectors/userSelectors";
import React from "react";

function mapStateToProps(state, ownProps) {
  const lesson = ownProps?.lesson;
  const userId = getCurrentUserId(state);

  return {
    currentClassroomId: state.currentClassroomId,
    userId,
    currentLessonId: state.currentLessonId,
    isWatched: makeIsWatched(state, { lessonId: lesson?.id }),
    isRatingPending: makeIsRatingPending(state, { lessonId: lesson?.id }),
    ...ownProps
  };
}

const areStatesEqual = (next, prev) =>
  shallowEqual(next.currentClassroomId, prev.currentClassroomId) &&
  shallowEqual(next.currentUserId, prev.currentUserId) &&
  shallowEqual(next.currentLessonId, prev.currentLessonId);

export default connect(mapStateToProps, null, null, {
  pure: true,
  areStatesEqual,
})(React.memo(LessonListItem));
