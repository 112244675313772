import { connect } from "react-redux";
import MockReportPanel from "./MockReportPanel";
import { getMock } from "../../selectors/mockSelectors";

function mapStateToProps(state) {
  return {
    mock: getMock(state),
  };
}

export default connect(mapStateToProps)(MockReportPanel);
