import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  width: 100%;
  cursor: pointer;

  svg {
    right: ${(props) => (props.iconPosition === 'right' ? '24px' : 'none')};
    left: ${(props) => (props.iconPosition === 'left' ? '16px' : 'none')};
  }
`

export const Paragraph = styled.p`
  font-size: 16px;
  color: #606A71;
`

export const List = styled.ul`
  background-color: #fff;
  border-radius: 4px;
  border: solid 1px #27EA8F;
  display: ${(props) => (props.visible ? 'block' : 'none')};
  list-style: none;
  position: absolute;
  top: calc(100% + 4px);
  width: 100%;
  z-index: 2;
  max-height: 200px;
  overflow-y: auto;
  margin: 0;
  padding-inline-start: 0;

  span {
    width: 100%;
    color: #606A71;
    font-size: 14px;
    line-height: 40px;
    cursor: not-allowed;
    padding-left: 16px;
  }

  li:hover {
    background-color: #D3FCE9;
  }

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #C2CED6;
    border: 2px solid #EDF0F2;
    border-radius: 20px;
    height: 76px;
  }

  ::-webkit-scrollbar-track {
    background: #EDF0F2;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
  }
`
export const Addbutton = styled.li`
  color: #35BD78;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  height: 40px;
  line-height: 40px;
  padding-left: 16px;
`
export const Item = styled.li`
  color: #606A71;
  cursor: pointer;
  font-size: 12px;
  height: 40px;
  line-height: 40px;
  padding-left: 16px;
  white-space: nowrap;
`
export const Overflow = styled.div`
  left: 0;
  top: 0;
  height: 100%;
  position: fixed;
  width: 100%;
  z-index: 1;
`
export const CustomInput = styled.input`
  height: 48px;
  width: 100%;
  border: none;
  flex-grow: 1;
  color: ${(props) => (props.value ? '#606A71' : '#C2CED6')};
  font-size: 14px;
  line-height: 1.5;
  padding: 0 12px;
  border: 1px solid;
  border-color: ${(props) => (props.isDropdownListVisible ? '#27EA8F' : '#C2CED6')};
  border-radius: 4px;

  &::placeholder {
    color: #C2CED6;
    background-color: #fff;
  }

  &:focus-visible {
    outline: none;
  }

  @media only screen and (min-width: 1025px) {
    padding: 0 16px;
  }
`
