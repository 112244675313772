import React from "react";
import { DetailsBox, Content, Circle } from "./style";
import { BookSearch } from "../../../components/TypeTag/icons/BookSearch";
import RecalculateIcon from "./icons/RecalculateIcon";
import { IoCalendar, IoBook, IoSparkles, IoCompass } from "react-icons/io5";

const infoCards = [
  {
    id: 1,
    icon: <RecalculateIcon />,
    description:
      "Recalcule as atividades da sua rota de estudos sempre que precisar.",
  },
  {
    id: 2,
    icon: <IoBook size={32} />,
    description: "Conteúdos priorizados para o que importa.",
  },
  {
    id: 3,
    icon: <IoCalendar size={32} />,
    description: "Ajuste o cronograma de acordo com sua rotina.",
  },
  {
    id: 4,
    icon: <IoSparkles size={32} />,
    description: "Equilíbrio de estudos entre teoria, prática e revisão.",
  },
];

export function IconDetails() {
  return (
    <DetailsBox>
      {infoCards.map((card) => (
        <Content key={card.id}>
          <div>{card.icon}</div>
          <p>{card.description}</p>
        </Content>
      ))}
    </DetailsBox>
  );
}
