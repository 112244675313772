import { css } from "glamor";
import React from "react";
import {
  spacing,
  typographyColors,
  uiColors,
  utilityColors,
} from "../library/theme";
import Link from "../library/Link";
import Button from "../library/Button";

const container = css({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: spacing.xs,
  width: 320,
  cursor: "pointer",
  ":hover": {
    backgroundColor: `${uiColors.background}20`,
    color: utilityColors.success,
  },
  "& + &": {
    borderTop: `1px solid ${uiColors.border}`,
  },
});
const titleClass = css({
  color: typographyColors.defaultTitle,
  weight: "bold",
});
const description = {
  fontSize: 10,
  color: typographyColors.defaultBody,
};
const fluidClass = css({
  width: "100%",
});
const infoStyle = {
  whiteSpace: "pre-line",
  flex: 1,
  flexGrow: 1,
  width: 243,
};

export default function NotificationListItem(props) {
  const { full, readNotification, fileNotification, notificationsUser } = props;
  const { title, text, link } = props.notification;

  const containerClass = css(container, full && fluidClass);

  return (
    <div className={containerClass}>
      <Link
        href={link}
        target="_blank"
        onClick={readNotification}
        style={infoStyle}
      >
        <h5 className={titleClass}>{title}</h5>
        <span style={description}>{text}</span>
      </Link>
      {!notificationsUser?.filed && (
        <Button circular icon="envelope open" onClick={fileNotification} />
      )}
    </div>
  );
}
