import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import SupportForm from "./SupportForm";
import { submitSupport } from "../../processes/supportProcesses";

function mapStateToProps() {
  return {
    initialValues: {},
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: "support",
    onSubmit: submitSupport,
  })(SupportForm)
);
