import styled, { css } from "styled-components";

const paragraphVariations = {
  default: css`
    font-size: 26px;
  `,
  paragrafo02: css`
    font-size: 22px;

    @media screen and (min-width: 1025px) {
      font-size: 26px;
    }
  `,
  paragrafo03: css`
    font-size: 22px;
  `
};

export const ParagraphVariants = styled.p`
  color: ${(props) => props.color || "#606A71"};
  ${(props) => paragraphVariations[props.variant || "default"]}
`;
