import React from "react";
import { css } from "glamor";
import LobbyLayout from "../lobby/LobbyLayout";
import Link from "../library/Link";
import Button from "../library/Button";
import { CardForm, TextField } from "../library/forms";
import { spacing, typographyColors } from "../library/theme";

const linkClass = css({
  color: typographyColors.defaultBody,
  "&:hover": { color: "rgba(0,0,0, 0.5)" },
});
const buttonMargin = css({ marginTop: spacing.m });

export default function SignInForm(props) {
  return (
    <LobbyLayout>
      <CardForm onSubmit={props.handleSubmit} error={props.error}>
        <p></p>
        <TextField
          name="email"
          type="email"
          icon="user"
          iconPosition="left"
          label="E-mail"
          placeholder="E-mail ou nome de usuário..."
          hint="Enviaremos as instruções para recuperar sua senha através do seu email
          cadastrado."
          required
          fluid
        />
        <div className={buttonMargin}>
          <Button
            color="primary"
            disabled={props.pristine || props.submitting}
            size="large"
            fluid
          >
            Recuperar senha
          </Button>
        </div>
      </CardForm>
      <Link to="/signin" className={linkClass}>
        Voltar para o login
      </Link>
    </LobbyLayout>
  );
}
