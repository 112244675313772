import { TooltipContainer, Tooltip, TooltipTitle } from './styles';
import React from 'react';

export function NotificationsTooltip({ children, title }) {
  return (
    <TooltipContainer>
      {children}
      <Tooltip>
        <TooltipTitle>{title}</TooltipTitle>
      </Tooltip>
    </TooltipContainer>
  );
}
