import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { EMRButton } from '../../../Button';
import { InvalidFingerprintIllustration } from '../../assets/InvalidFingerprintIllustration';
import { FaArrowRight } from 'react-icons/fa';

import {
  ButtonContainer,
  Content,
  Description,
  Illustration,
  Paragraph,
  Title,
} from '../../styles';
import { Wrapper } from './styles';
import { RadioButton } from './RadioButton';
import { ACTIVE_SCREEN } from '../../enum';

export default function InvalidFingerprint({ credentials }) {
  const [isDeviceOrBrowser, setIsDeviceOrBrowser] =
    useState('different-device');
  const history = useHistory();

  function handleAdvanceStep() {
    if (isDeviceOrBrowser === 'different-device') {
      return history.replace('/signin-security', {
        activeScreen: ACTIVE_SCREEN.UNAUTHORIZED_ACCESS,
        credentials: credentials,
      });
    }

    return history.replace('/signin-security', {
      activeScreen: ACTIVE_SCREEN.VALIDATE_BROWSER,
      credentials: credentials,
    });
  }

  function handleChoice(userChoice) {
    setIsDeviceOrBrowser(userChoice);
  }

  return (
    <>
      <Illustration>
        <InvalidFingerprintIllustration />
      </Illustration>
      <Content>
        <Description>
          <Title>Este acesso é de um local ou navegador diferente.</Title>
          <Paragraph>
            Parece que você está acessando seu dispositivo de um local ou
            navegador diferente do primeiro acesso. Por favor, informe-nos como
            você está prosseguindo com sua conexão para continuar o acesso:
          </Paragraph>
        </Description>

        <Wrapper>
          <RadioButton
            onClick={() => handleChoice('different-device')}
            checked={isDeviceOrBrowser === 'different-device'}
            label="Outro dispositivo"
          />
          <RadioButton
            onClick={() => handleChoice('different-browser')}
            checked={isDeviceOrBrowser === 'different-browser'}
            label="Outro navegador"
          />
        </Wrapper>

        <ButtonContainer>
          <EMRButton
            fullSized
            fontSize={'lg'}
            mediumWeight
            onClick={handleAdvanceStep}
          >
            Avançar
            <FaArrowRight />
          </EMRButton>
        </ButtonContainer>
      </Content>
    </>
  );
}
