import React from "react";
import { BoxImage } from "./style";
import Illustration from "./illustration";

export function Banner() {
  return (
    <BoxImage>
      <Illustration />
      <div>
        <h2>Bem-vindo(a) ao Medtrack</h2>
        <p>
          O MedTrack <b>personaliza sua trilha</b> de estudos adaptando o
          cronograma <b>conforme seu tempo</b> e as bancas escolhidas. Tudo para
          você se preparar da melhor forma.
        </p>
      </div>
    </BoxImage>
  );
}
