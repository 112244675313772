import React, { useContext } from "react";
import { BsFillPlayFill } from "react-icons/bs";
import { EMRButton } from "../../Button";
import {
  ButtonWrapper,
  Container,
  Description,
  Image,
  InfosWrapper,
  InfoText,
  Progress,
  ProgressContainer,
  Skeleton,
  Title,
  VideoInfos,
  VideoThumb,
  MobileVideoThumb,
  DesktopVideoThumb,
} from "./styles";
import { Tag } from "./tag";
import image from "./image.png";
import { Context } from "../context";
import { useHistory } from "react-router-dom";
import { trackClickEvents } from "../../../../services/mixpanel/events/trackClickEvents";

function formatLevelGroupName(moduleName) {
  const shouldSplitName = moduleName?.includes("-");

  if (!shouldSplitName) return moduleName;

  const formattedName = moduleName.split("-")[0].trim(" ");

  return formattedName;
}

function formatLessoName(lessonName) {
  const shouldSplitName = lessonName?.includes("-");

  if (!shouldSplitName) return lessonName;

  const formattedName = lessonName.split("-")[0].trim(" ");

  return formattedName;
}

export function ContinueWatching(props) {
  const {
    classroomFound,
    classrooms,
    firstLessonId,
    level,
    currentClassroomId,
    isFrialUser,
  } = props;
  const { lessonsUser, levelGroup, loading, hasVideoToKeepWatching } =
    useContext(Context);
  const classroomName = lessonsUser?.classroom?.name ?? classrooms[0]?.name;
  const classroomId = currentClassroomId ?? classrooms[0]?.id;
  const lessonId = lessonsUser?.lessonId ?? firstLessonId;
  const history = useHistory();

  function handleContinue() {
    history.push(`/classrooms/${classroomId}/lessons/${lessonId}`);
    trackClickEvents({
      eventName: `Início - interação de navegação`,
      content: {
        ClassroomId: classroomId,
        LessonId: lessonId,
        UserFrial: isFrialUser,
        NomeDoBotao: hasVideoToKeepWatching ? "Continuar" : "Assistir agora",
      },
    });
  }

  function handleSeeModules() {
    history.push(`/classrooms/${classroomId}`);
    trackClickEvents({
      eventName: "Início - interação de navegação",
      content: {
        ClassroomId: classroomId,
        UserFrial: isFrialUser,
        NomeDoBotao: "Ver módulos",
      },
    });
  }

  return loading || !lessonId || !classroomId ? (
    <Skeleton />
  ) : (
    <Container hasVideoToKeepWatching={hasVideoToKeepWatching}>
      <InfosWrapper hasVideoToKeepWatching={hasVideoToKeepWatching}>
        <Tag text="Aula" />
        <Title hasVideoToKeepWatching={hasVideoToKeepWatching}>
          {hasVideoToKeepWatching
            ? "Continue assistindo"
            : "Comece agora seu curso!"}
        </Title>
        {!hasVideoToKeepWatching && (
          <InfoText>
            Você também pode olhar os módulos disponíveis para você.
          </InfoText>
        )}

        {hasVideoToKeepWatching && (
          <VideoInfos>
            <span>{`${
              levelGroup ? `${formatLevelGroupName(levelGroup?.name)} |` : ""
            } ${formatLessoName(level?.name)} - ${classroomName}`}</span>
            <Description>{lessonsUser?.lesson?.name}</Description>
          </VideoInfos>
        )}

        {hasVideoToKeepWatching ? (
          <MobileVideoThumb>
            <VideoThumb
              onClick={() =>
                history.push(`/classrooms/${classroomId}/lessons/${lessonId}`)
              }
              image={classroomFound?.imageUrl}
            >
              <BsFillPlayFill size={30} color="#fff" />
              <ProgressContainer>
                <Progress value={Math.round(lessonsUser?.progress * 100)} />
              </ProgressContainer>
            </VideoThumb>
          </MobileVideoThumb>
        ) : (
          <Image src={image} />
        )}

        <ButtonWrapper>
          <EMRButton
            mode="standard"
            fontSize="md"
            mediumWeight
            style={{ minWidth: 163 }}
            onClick={handleContinue}
          >
            <BsFillPlayFill size={30} color="#fff" />
            {hasVideoToKeepWatching ? "Continuar" : "Assistir agora"}
          </EMRButton>

          <EMRButton
            mode="outline"
            fontSize="md"
            mediumWeight
            style={{ minWidth: 163 }}
            onClick={handleSeeModules}
          >
            Ver módulos
          </EMRButton>
        </ButtonWrapper>
      </InfosWrapper>

      {hasVideoToKeepWatching ? (
        <DesktopVideoThumb>
          <VideoThumb
            onClick={() =>
              history.push(`/classrooms/${classroomId}/lessons/${lessonId}`)
            }
            image={classroomFound?.imageUrl}
          >
            <BsFillPlayFill size={30} color="#fff" />
            <ProgressContainer>
              <Progress value={Math.round(lessonsUser?.progress * 100)} />
            </ProgressContainer>
          </VideoThumb>
        </DesktopVideoThumb>
      ) : (
        <Image src={image} />
      )}
    </Container>
  );
}
