import React from "react";
import styled from "styled-components";
import { colors } from "../../components/library/theme";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
  margin-top: 16px;
`;
const Title = styled.span`
  font-size: 14px;
  font-weight: 600;
  color: ${colors.interface.grey400};
`;
const Text = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: ${colors.interface.grey400};
`;

export function ChildText({ icon, title, text, children }) {
  return (
    <>
      {icon && (
        <Wrapper>
          {icon()}
          <Title>{title}</Title>
        </Wrapper>
      )}
      <Text>{text || children}</Text>
    </>
  );
}
