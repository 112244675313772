import React from 'react';
import { IoMdCheckmark } from 'react-icons/io';
import { Checkbox, CheckboxWrapper } from './styles';

export function CheckBox({
  isDisabled = false,
  isLoading = false,
  onCheck,
  isChecked,
  variant = 'round',
  children,
}) {
  const hasChildren = Boolean(children);

  return (
    <CheckboxWrapper
      disabled={isDisabled}
      onClick={onCheck}
      variant={variant}
      hasChildren={hasChildren}
    >
      <Checkbox
        isLoading={isLoading}
        disabled={isDisabled}
        isChecked={isChecked}
      >
        <IoMdCheckmark />
      </Checkbox>

      {children}
    </CheckboxWrapper>
  );
}
