import React from "react";
import { connect } from "react-redux";
import NotRegisteredDevice from ".";
import { getSignInAttemptFails } from "../../processes/sessionProcesses";

function NotRegisteredDeviceContainer(props) {
  const { dispatch } = props;
  const signInAttemptFailsHandler = React.useCallback(
    () => getSignInAttemptFails(dispatch),
    [dispatch]
  );

  React.useEffect(() => {
    signInAttemptFailsHandler(dispatch);
  }, [dispatch]);

  return <NotRegisteredDevice {...props} />;
}

function mapStateToProps(state) {
  return {
    userSignInAttemptFails: state.userSignInAttemptFails,
  };
}

export default connect(mapStateToProps)(NotRegisteredDeviceContainer);
