import React from "react";

export function Key() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.6313 10.4481C13.6313 11.2606 13.6312 12.0481 13.8875 12.7856C11.1938 15.9481 4.08125 24.3044 3.41875 24.9419C3.28708 25.06 3.18175 25.2045 3.1096 25.3661C3.03746 25.5276 3.00011 25.7025 3 25.8794C3 26.4106 3.325 26.9231 3.6 27.2106C4.0125 27.6419 5.775 29.2731 6.1 28.9606C7.0625 28.0231 7.25625 27.7731 7.65 27.3856C8.24375 26.8044 7.5875 25.6169 7.79375 25.1356C8 24.6544 8.21875 24.5606 8.575 24.4856C8.93125 24.4106 9.5625 24.6669 10.0562 24.6731C10.575 24.6794 10.8562 24.4606 11.2437 24.0981C11.5562 23.8106 11.7812 23.5419 11.7875 23.1231C11.8 22.5606 10.9875 21.8169 11.5938 21.2231C12.2 20.6294 13.075 21.6106 13.7188 21.5356C14.3625 21.4606 15.1438 20.5669 15.225 20.1856C15.3063 19.8044 14.4937 18.8231 14.6187 18.2669C14.6625 18.0794 15.0437 17.6419 15.3313 17.5794C15.6188 17.5169 16.8937 18.0106 17.1812 17.9481C17.5312 17.8731 17.9375 17.5044 18.2687 17.2981C19.2375 17.7169 20.1187 17.8856 21.25 17.8856C25.5312 17.8856 29 14.5481 29 10.4356C29 6.32312 25.5312 3 21.25 3C16.9688 3 13.6313 6.33562 13.6313 10.4481ZM25 9C25 9.39556 24.8827 9.78224 24.6629 10.1111C24.4432 10.44 24.1308 10.6964 23.7654 10.8478C23.3999 10.9991 22.9978 11.0387 22.6098 10.9616C22.2219 10.8844 21.8655 10.6939 21.5858 10.4142C21.3061 10.1345 21.1156 9.77814 21.0384 9.39018C20.9613 9.00222 21.0009 8.60009 21.1522 8.23463C21.3036 7.86918 21.56 7.55682 21.8889 7.33706C22.2178 7.1173 22.6044 7 23 7C23.5304 7 24.0391 7.21071 24.4142 7.58579C24.7893 7.96086 25 8.46957 25 9Z"
        fill="#606A71"
      />
    </svg>
  );
}
