import styled from "styled-components";

export const Slide = styled.a``
export const Image = styled.img`
  border-radius: 8px;
  width: 100%;
`
export const DesktopCarousel = styled.section`
  display: ${props => props.visible ? 'none' : 'unset'};

  @media(max-width: 768px){
    display: none;
  }
`
export const MobileCarousel = styled.section`
  display: none;

  @media(max-width: 768px){     
    display: ${props => props.visible ? 'none' : 'unset'};
  }
`
export const Skeleton = styled.div`
  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  width: 100%;
  aspect-ratio: 45 / 7;
  animation: 1.5s shine linear infinite;
  
  @keyframes shine {
    to {
      background-position-x: -200%;
    }
  }

  @media(max-width: 768px){
    width: 100%;
    aspect-ratio: 9/5;
  }
`