import * as S from "./styles";

import React, { useEffect, useRef, useState } from "react";
import ArrowDownIcon from "./arrowDownIcon";

const Dropdown = ({
  options,
  selected,
  setSelected,
  optionsIcons,
  selectedIcon,
  setSelectedIcon,
  error,
  label,
  ...props
}) => {
  const [isActive, setIsActive] = useState(false);
  const [isSelected, setIsSelected] = useState(
    options && selected !== options[0] ? false : true
  );
  const modalRef = useRef(null);

  const handleClick = () => {
    setIsActive((event) => !event);
  };

  useEffect(() => {
    function checkIfClickedOutside(e) {
      if (
        isActive &&
        modalRef.current &&
        !modalRef.current.contains(e.target)
      ) {
        setIsActive(false);
      }
    }
    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isActive]);

  return (
    <div {...props}>
      <S.ContainerDropdown
        isActive={isActive}
        ref={modalRef}
        hasValidationError={error}
      >
        <S.InputWrapper
          isActive={isActive || isSelected}
          hasValidationError={error}
          isFullHeight={props.isFullHeight}
        >
          <S.DropdownButton
            placeholder=" "
            isSelected={isSelected}
            onClick={handleClick}
            readOnly
          />

          {label && (
            <S.Label
              isActive={isActive || isSelected}
              hasValidationError={error}
            >
              {label}
            </S.Label>
          )}

          {isSelected && (
            <S.SelectedItemContainer>
              {optionsIcons && selectedIcon}
              <span>{selected}</span>
            </S.SelectedItemContainer>
          )}

          <S.Icon>
            <ArrowDownIcon />
          </S.Icon>
        </S.InputWrapper>

        {isActive && (
          <S.DropdownContent>
            {options && options?.length > 0 ? (
              options.map((option, index) => (
                <S.OptionContainer
                  key={index}
                  onClick={() => {
                    setIsSelected(true);
                    setSelected(option);
                    setIsActive(false);
                    optionsIcons &&
                      setSelectedIcon &&
                      setSelectedIcon(optionsIcons[index]);
                  }}
                >
                  {optionsIcons && optionsIcons[index]}
                  {option}
                </S.OptionContainer>
              ))
            ) : (
              <S.OptionContainer>Nenhum resultado encontrado</S.OptionContainer>
            )}
          </S.DropdownContent>
        )}
      </S.ContainerDropdown>
      {error && <S.Error>{error}</S.Error>}
    </div>
  );
};

export default Dropdown;
