import { setCookie } from 'cookies-next';

export async function requestUserProxySignin(email, password) {
  const response = await fetch(`${process.env.USER_PROXY_URL}/auth/login`, {
    method: 'POST',
    headers: {
      'api-token': process.env.USER_PROXY_API_TOKEN,
      'Content-Type': 'application/json',
      'access-date': new Date().toISOString(),
      product: 'PLATAFORMA_EMR',
    },
    body: JSON.stringify({
      email,
      password,
    }),
  });
  return response;
}

export async function requestUserProxySigninProvider(firebaseToken) {
  const response = await fetch(`${process.env.USER_PROXY_URL}/auth/provider`, {
    method: 'GET',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'api-token': process.env.USER_PROXY_API_TOKEN,
      'Content-Type': 'application/json',
      'access-date': new Date().toISOString(),
      product: 'PLATAFORMA_EMR',
      Authorization: `Bearer ${firebaseToken}`,
    },
  });
  return response;
}

export function setUserProxyCookie(userProxySinginResponse) {
  setCookie('upJwt', userProxySinginResponse?.jwt);
  setCookie('upRefreshToken', userProxySinginResponse?.refreshToken);
  setCookie('upUser', JSON.stringify(userProxySinginResponse?.user));
}
