import React, { useEffect, useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import {
  Wrapper,
  Backdrop,
  Info,
  InfosWrapper,
  Link,
  Modal,
  Subtitle,
  Title,
  ImageWrapper
} from "./styles";
import { setCookie } from "cookies-next";
import { getThemeByUniversity } from "../../../utils/getThemeByUniversity";
import { WelcomeImage } from "../../../componentsV2/WelcomeImage";

const GoalsModal = () => {
  const { IES } = getThemeByUniversity();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const session = JSON.parse(localStorage.getItem("session"));
    const values = { platform_id: session.currentUserId };

    fetch(`${process.env.METRICOAPI_URL}user`, {
      method: "POST",
      body: JSON.stringify(values),
      headers: { "Content-Type": "application/json" }
    })
      .then((response) => response.json())
      .then((data) => {
        if (
          !data?.intended_specialty ||
          !data?.medical_residence_to_graduate ||
          !data?.states_to_graduate
        ) {
          setVisible(true);
        }
      });

    fetch(
      `${process.env.METRICOAPI_URL}user/check-onboarding-status/${session.currentUserId}`
    )
      .then((res) => res.json())
      .then((data) => setVisible(data?.proceed_with_onboarding));
  }, []);

  function getTitle() {
    if (IES === "revalida") {
      return "O Revalida 2024 chegou!";
    }
    if (!IES) {
      return "O Eu Médico Residente 2024 chegou!";
    }
    return "O extensivo 2024 chegou!";
  }

  useEffect(() => {
    if (visible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    getTitle();
  }, [visible]);

  const handleClick = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage("openMetrics");
    } else {
      const session = JSON.parse(localStorage.getItem("session"));
      setCookie(process.env.METRICO_USER_ID_COOKIE, session.currentUserId, {
        domain: process.env.DOMAIN_URL
      });

      window.open(process.env.METRICO_URL, "_self");
    }
  };

  return (
    <Backdrop visible={visible}>
      <Wrapper>
        <Modal>
          <WelcomeImage />

          <InfosWrapper>
            <Title>{getTitle()}</Title>
            <Subtitle>
              Defina suas metas para acompanhar sua progressão nos estudos.
            </Subtitle>
            <Info>
              Antes de continuar a utilizar a plataforma EMR, defina rapidamente
              as metas dos seus estudos. Dessa forma, você poderá acompanhar seu
              progresso e otimizar seus estudos em pontos mais críticos. Através
              dessas informações, nossa Inteligência Artificial poderá guiar
              você rumo à conquista dos seus objetivos.
            </Info>
          </InfosWrapper>
          <Link onClick={handleClick}>
            Definir minhas metas
            <FaArrowRight fill="#fff" />
          </Link>
        </Modal>
      </Wrapper>
    </Backdrop>
  );
};

export default GoalsModal;
