import React, { useEffect, useState } from "react";
import {
  ButtonContainer,
  Filter,
  OpenFilterButton,
  DialogOverlay,
  DialogContent,
  DialogTrigger,
  CloseButton,
  ModalHeader,
} from "./styles";
import {
  filterByPeriod,
  filterByStatus,
  filterByBigArea,
  filterByStartDate,
} from "./constants";
import { EMRButton } from "../../../Button";
import { IoFilter } from "react-icons/io5";
import * as Dialog from "@radix-ui/react-dialog";
import { useMedTrack } from "../../hooks/useMedTrack";
import { colors } from "../../../../components/library/theme";
import { IoClose } from "react-icons/io5";

export function MobileFilter({
  onChangeFilters,
  onFilter,
  isR3CM,
  isRevision,
}) {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const { activeFilters, resetFilters, clearReset } = useMedTrack();

  function handleSubmit(event) {
    event.preventDefault();
    onFilter();
    setIsFilterOpen(false);
  }

  function handleResetFilters(event) {
    event.preventDefault();
    resetFilters();
    setIsFilterOpen(false);
  }

  useEffect(() => {
    if (isFilterOpen) clearReset();
  }, [isFilterOpen]);

  return (
    <Dialog.Root open={isFilterOpen} onOpenChange={setIsFilterOpen}>
      <DialogTrigger asChild>
        <OpenFilterButton mode="gray">
          <IoFilter size={24} />
          Filtro
        </OpenFilterButton>
      </DialogTrigger>

      <Dialog.Portal>
        <DialogOverlay>
          <DialogContent>
            <Filter onSubmit={handleSubmit}>
              <ModalHeader>
                <h1>Filtrar por</h1>

                <Dialog.Close asChild>
                  <CloseButton>
                    <IoClose size={24} color={colors.interface.grey300} />
                  </CloseButton>
                </Dialog.Close>
              </ModalHeader>

              <div>
                {!isR3CM && (
                  <fieldset>
                    <h2>Grande área</h2>
                    {filterByBigArea.map((item, index) => (
                      <label htmlFor={item.id} key={index}>
                        <input
                          id={item.id}
                          type="radio"
                          name="bigArea"
                          value={item.value}
                          checked={activeFilters.bigArea === item.value}
                          onChange={(event) => onChangeFilters(event)}
                        />
                        {item.icon}
                        {item.label}
                      </label>
                    ))}
                  </fieldset>
                )}

                <hr />

                {!isRevision && (
                  <>
                    <fieldset>
                      <h2>Período</h2>
                      {filterByPeriod.map((item, index) => (
                        <label htmlFor={item.id} key={index}>
                          <input
                            id={item.id}
                            type="radio"
                            name="period"
                            value={item.value}
                            checked={activeFilters.period === item.value}
                            onChange={(event) => onChangeFilters(event)}
                          />
                          {item.label}
                        </label>
                      ))}
                    </fieldset>

                    <hr />

                    <fieldset>
                      <h2>Ordenação</h2>
                      {filterByStartDate.map((item, index) => (
                        <label htmlFor={item.id} key={index}>
                          <input
                            id={item.id}
                            type="radio"
                            name="startDate"
                            value={item.value}
                            checked={
                              activeFilters.orderByStartDate === item.value
                            }
                            onChange={(event) => onChangeFilters(event)}
                          />
                          {item.label}
                        </label>
                      ))}
                    </fieldset>
                  </>
                )}

                <hr />

                <fieldset>
                  <h2>Status</h2>
                  {filterByStatus.map((item, index) => (
                    <label htmlFor={item.id} key={index}>
                      <input
                        id={item.id}
                        type="radio"
                        name="status"
                        value={item.value}
                        checked={activeFilters.status === item.value}
                        onChange={(event) => onChangeFilters(event)}
                      />
                      {item.label}
                    </label>
                  ))}
                </fieldset>
              </div>

              <hr />

              <h3>
                Defina como deseja visualizar ou limpe para manter visualização
                padrão.
              </h3>

              <ButtonContainer>
                <EMRButton
                  onClick={handleResetFilters}
                  fontSize={"md"}
                  fullSized
                  mode="cancel"
                >
                  Limpar filtro
                </EMRButton>

                <EMRButton
                  mode={"standard"}
                  fontSize={"md"}
                  fullSized
                  type="submit"
                >
                  Aplicar filtro
                </EMRButton>
              </ButtonContainer>
            </Filter>
          </DialogContent>
        </DialogOverlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
