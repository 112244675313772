import { replace } from "connected-react-router";
import { normalize, schema } from "normalizr";
import { SubmissionError } from "redux-form";
import toQueryString from "../utils/toQueryString";
import { apiDel, apiGet, apiPost, apiPut } from "./helpers/api";

const mockSchema = new schema.Entity("mocks", {
  meta: { mockTemplate: new schema.Entity("mockTemplates") },
});

export const fetchMocksIntelligent = (values, dispatch, props) => {
  dispatch({ type: "MOCKS_LIST_FETCH_REQUESTED" });
  const filters = toQueryString(values);
  return apiGet(`/api/v1/mocks?${filters}page=${props?.page || 1}`).then(
    (response) => {
      dispatch({
        type: "MOCKS_LIST_FETCHED",
        ...normalize(response.body.data, new schema.Array(mockSchema)),
        totalPages: response.body.totalPages,
        currentPage: response.body.currentPage,
        totalCount: response.body.totalCount,
      });
    }
  );
};

export const fetchMocksList = (values, dispatch, props) => {
  dispatch({ type: "MOCKS_LIST_FETCH_REQUESTED" });
  const filters = toQueryString(values);
  return apiGet(`/api/v1/mocks?${filters}page=${props?.page || 1}`).then(
    (response) => {
      dispatch({
        type: "MOCKS_LIST_FETCHED",
        ...normalize(response.body.data, new schema.Array(mockSchema)),
        totalPages: response.body.totalPages,
        currentPage: response.body.currentPage,
        totalCount: response.body.totalCount,
      });
    }
  );
};

export const fetchMock = (dispatch, mockId) => {
  apiGet(`/api/v1/mocks/${mockId}`).then((response) => {
    dispatch({
      type: "MOCK_FETCHED",
      ...normalize(response.body.data, mockSchema),
    });
  });
};

export const createMock = (values, dispatch, props) => {
  const { lessonMock = false, quantity } = values;
  let questionsQuantity = quantity;

  if (Number(quantity) > 120) questionsQuantity = "120";
  if (Number(quantity) < 1) questionsQuantity = "1";

  const formattedValues = { ...values, quantity: questionsQuantity };

  dispatch({ type: "MOCK_SUBMITTED" });
  return apiPost("/api/v1/mocks/")
    .send(formattedValues)
    .then((response) => {
      dispatch({
        type: "MOCK_CREATED",
        ...normalize(response.body.data, mockSchema),
      });
      !lessonMock && dispatch(replace(`/mocks/${response.body.data.id}`));
      props?.reset();
    })
    .catch((err) => {
      throw new SubmissionError({ _error: err.response.body.errors });
    });
};

export const createMockWithLoading = (values, setLoading, dispatch, props) => {
  const { lessonMock = false, isMedtrackOrigin = false } = values;
  setLoading(true);
  dispatch({ type: "MOCK_SUBMITTED" });
  return apiPost("/api/v1/mocks/")
    .send(values)
    .then((response) => {
      dispatch({
        type: "MOCK_CREATED",
        ...normalize(response.body.data, mockSchema),
      });

      let redirectUrl = `/mocks/${response.body.data.id}`;
      if (isMedtrackOrigin) {
        redirectUrl += "?isMedtrackOrigin=true";
      }

      !lessonMock && dispatch(replace(redirectUrl));
      props?.reset();
      setLoading(false);
    })
    .catch((err) => {
      setLoading(false);

      throw new SubmissionError({ _error: err.response.body.errors });
    });
};

export const updateMockTitle = (dispatch, mockId, newTitle) => {
  dispatch({ type: "MOCK_TITLE_UPDATE_REQUESTED" });
  return apiPut(`/api/v1/mocks/${mockId}/update_title`)
    .send({ title: newTitle, mockId })
    .then((response) => {
      dispatch({
        type: "MOCK_TITLE_UPDATED",
        mockId,
        newTitle,
      });
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};

export const finishMock = (dispatch, mockId) =>
  //SKIP VALIDATION needs forceMockCompletion
  apiPut(`/api/v1/mocks/${mockId}`)
    .send({ skipCompletionVerification: true })
    .then((response) =>
      dispatch({
        type: "MOCK_UPDATED",
        ...normalize(response.body.data, mockSchema),
      })
    );

export const deleteMock = (dispatch, mockId) => {
  dispatch({ type: "LOADING" });
  apiDel(`/api/v1/mocks/${mockId}`)
    .then((response) => {
      dispatch({
        type: "MOCK_DELETED",
        mockId,
      });
    })
    .catch((error) => {
      console.error("Erro ao excluir o simulado:", error);
    })
    .finally(() => {
      dispatch({ type: "LOADED" });
    });
};

export const updateMock = (dispatch, mockId, newTitle) => {
  return apiPut(`/api/v1/mocks/${mockId}`)
    .send({ title: newTitle })
    .then((response) => {
      dispatch({
        type: "MOCK_UPDATED",
        ...normalize(response.body.data, mockSchema),
      });
    })
    .catch((err) => {
      throw new Error(err);
    });
};

export const fetchPaginatedMock = async (status, type, page, classroomId) => {
  const userId = JSON.parse(localStorage.getItem("session")).currentUserId;
  const body = {
    userId: userId,
    typeMocks: type,
    page: page ? page : "",
    progress: status ? status : "",
    idClassrooms: classroomId ? classroomId : "",
  };

  const reponse = await fetch(
    `${process.env.SIMULADO_INTELIGENTE}platformMocks/findMocksUser`,
    {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const mockList = reponse.json();

  return mockList;
};

export const fetchMedtrackMocks = async ({ activeMedtrackType }) => {
  const courseType = encodeURIComponent(activeMedtrackType);

  const response = await apiGet(
    `/api/v1/medtrack_mock_templates/progress?course_type=${courseType}`
  );
  return response.body;
};
