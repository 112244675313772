import React from 'react';
import {
  CheckboxContainer,
  Text,
  HiddenCheckbox,
  StyledCheckbox,
  TextWrapper,
} from './styles';
import { IoCheckmark } from 'react-icons/io5';
import { colors } from '../../../../../components/library/theme';

export function LessonCheckbox({ title, subTitle, todo, handleTaskStatus }) {
  return (
    <CheckboxContainer
      isChecked={todo.isCheckedByUser}
      onClick={handleTaskStatus}
    >
      <HiddenCheckbox
        onChange={handleTaskStatus}
        isChecked={todo.isCheckedByUser}
      />

      <StyledCheckbox isChecked={todo.isCheckedByUser}>
        <IoCheckmark color={colors.medtrack.white000} size={12} />
      </StyledCheckbox>

      <TextWrapper>
        <Text>{title}</Text>
        {subTitle && <span>{subTitle}</span>}
      </TextWrapper>
    </CheckboxContainer>
  );
}
