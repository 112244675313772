import styled, { css } from 'styled-components';
import { colors } from '../../components/library/theme';

const annotationFilterStyles = css`
  flex-wrap: wrap;

  button {
    flex: 0;
  }

  @media (max-width: 640px) {
    button:nth-child(4) {
      flex: 0;
    }

    button:nth-child(6) {
      flex: 0;
    }

    button {
      flex: 1;
    }
  }
`;

const defaultFilterStyles = css`
  button {
    flex: 0;
  }

  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    button {
      width: 100%;
    }
  }
`;

const buttonDisabled = css`
  opacity: 0.45;
`;

const hoverButton = css`
  &:hover {
    background-color: ${colors.brand.pure};
    color: ${colors.interface.white000};
  }
`;

const activeButton = css`
  font-weight: 400;
  color: ${colors.interface.white000};
  background-color: ${colors.brand.pure};
`;

export const Container = styled.div`
  display: flex;
  gap: 8px;

  ${({ isAnnotationBookFilter }) => (isAnnotationBookFilter ? annotationFilterStyles : defaultFilterStyles)}
`;

export const Button = styled.button`
  white-space: nowrap;
  width: fit-content;
  height: 32px;
  padding: 0 16px;
  font-weight: 400;
  font-size: 14px;
  display: inline-flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  text-align: center;
  line-height: 18px;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  transition: all 0.2s ease-in-out;
  font-family: 'Poppins' !important;
  color: ${colors.interface.grey500};
  background: none;
  border: none;
  background-color: ${colors.interface.grey200};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  ${({ disabled }) => (disabled ? buttonDisabled : hoverButton)}
  ${({ active }) => active && activeButton}
`;
