import { setCookie } from 'cookies-next';
import { apiPost } from '../../../../processes/helpers/api';
import {
  requestUserProxySigninProvider,
  setUserProxyCookie,
} from '../../../SignInSecurity/utils/requestUserProxySignin';
import { onUserAuth } from '../../@functions/onUserAuth';
import { LOGIN_STATUS } from './constants';

export async function handleLogin(accessToken, provider) {
  const response = await fetch(`/api/v1/auth2/${provider}/sign_in`, {
    method: 'POST',
    headers: {
      Authorization: accessToken,
      'Access-Control-Expose-Headers': '*',
    },
  });

  const client = response.headers.get('client');
  const platformAccessToken = response.headers.get('access-token');
  const json = await response.json();

  if (json.id) {
    const userEnrolment = await apiPost(
      '/api/v1/auth/validate_user_enabled_enrollment'
    ).send({
      email: json.email,
    });

    if (userEnrolment.body.has_active_enrollment === false) {
      return { status: LOGIN_STATUS.DENIED };
    }

    const data = {
      currentUserId: json.id,
      createdAt: json.createdAt,
      headers: {
        uid: json.email,
        client: client,
        'access-token': platformAccessToken,
      },
    };

    localStorage.setItem('session', JSON.stringify(data));
    setCookie(process.env.METRICO_THEME_COOKIE, IES, {
      domain: process.env.DOMAIN_URL,
    });

    return { status: LOGIN_STATUS.SUCCESS };
  } else {
    console.error(json.errors[0]);

    return { status: LOGIN_STATUS.ERROR };
  }
}
/**
 *
 * @returns {Promise<boolean>} true if the user is logged in
 */
async function handleUserProxyLoginProvider(accessToken) {
  const userProxySignInResponse = await requestUserProxySigninProvider(
    accessToken
  );
  if (!userProxySignInResponse || !userProxySignInResponse.ok) {
    return false;
  }
  const userProxySignInResponseBody = await userProxySignInResponse.json();
  setUserProxyCookie(userProxySignInResponseBody);
  return true;
}

export async function handleUserLogin(provider) {
  try {
    const { user } = await onUserAuth(provider);
    const hasErrorOnUserProxySignIn = await handleUserProxyLoginProvider(
      user.accessToken
    );

    if (hasErrorOnUserProxySignIn)
      return await handleLogin(user.accessToken, provider);

    return { status: LOGIN_STATUS.ERROR };
  } catch (error) {
    console.error(error);

    return { status: null };
  }
}
