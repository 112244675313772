import { deleteCookie } from "cookies-next";

export function logout() {
  localStorage.removeItem("session");
  deleteCookie("upJwt");
  deleteCookie("upRefreshToken");
  deleteCookie("upUser");
  if (
    window.location.pathname !== "/signin" &&
    window.location.pathname !== "/signup-v2"
  )
    window.location.replace("/signin-v2?max_session=true");
}
