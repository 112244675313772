import styled from "styled-components";
import CalendarSVG from "./illustration";

export const Container = styled.div`
  width: 100%;
  max-width: 720px; 
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  padding: 24px;
  font-size: 14px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  h1 {
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }

  svg {
    cursor: pointer;
    color: #9BA5AB;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  border: 1px solid #C2CED6;
  border-radius: 4px;

  p {
    flex: 1;
    color: #555;
    margin-right: 16px;

    span {
      font-weight: bold;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 16px 0 0 0;

    li {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      color: #555;

      svg {
        color: #35bd78;
        margin-right: 8px;
      }
    }
  }
`;

export const AlertSection = styled.div`
  margin: 16px 0;
  padding: 16px;
  display: flex;
  align-items: center;
  color: #888;
  border: 1px solid #EDF0F2;
  background-color: #F7FAFC;
  border-radius: 4px;

  svg {
    margin-right: 8px;
    color: #C2CED6;
  }
`;

export const Button = styled.button`
  width: 100%;
  height: 48px;
  padding: 8px 24px;
  background-color: #35bd78;
  border: none;
  color: white;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 60%;
  border-radius: 4px;
  cursor: pointer;

  svg {
    margin-right: 8px;
  }
`;

export const StyledCalendarSVG = styled(CalendarSVG)`
  flex-shrink: 0;
  width: 208px; 
  height: 208px; 
`;

