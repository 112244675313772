import React, { useCallback } from "react";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { createMock } from "../../processes/mockProccess";
import { getMockFromMockTemplate } from "../../selectors/mockSelectors";
import LevelMockListItem from "./LevelMockListItem";

function LevelMockListItemContainer(props) {
  const { dispatch, mockTemplate, mock } = props;

  const onClickHandler = useCallback(() => {
    mock
      ? dispatch(push(`/mocks/${mock?.id}`))
      : createMock({ mockTemplateId: mockTemplate.id }, dispatch);
  }, [dispatch, mockTemplate, mock]);

  return <LevelMockListItem onClickHandler={onClickHandler} {...props} />;
}

function mapStateToProps(state, ownProps) {
  const { mockTemplate } = ownProps;
  const mock = getMockFromMockTemplate(state, mockTemplate.id);

  return {
    mock,
    isFinished: !!mock?.finishedAt,
  };
}

export default connect(mapStateToProps)(LevelMockListItemContainer);
