import styled from "styled-components";
import { colors } from "../../components/library/theme";
import { EMRButton } from "../Button";

export const Overlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);

  @media (max-width: 500px) {
    padding: 4px;
  }
`;
export const Card = styled.div`
  max-width: 496px;
  padding: 24px;
  background-color: white;
  border-radius: 4px;
  width: 100%;
`;
export const Title = styled.h3`
  color: ${colors.interface.grey500};
  font-size: 18px;
  font-weight: 600;
  line-height: 1.3;
`;
export const Info = styled.p`
  margin: ${(props) => (props?.hasChildren ? "8px 0 0 0" : "8px 0 32px 0")};
  font-size: 16px;
  line-height: 1.5;
`;
export const Actions = styled.div`
  display: flex;
  gap: 16px;

  @media (max-width: 500px) {
    flex-direction: column-reverse;
  }
`;
export const Cancel = styled(EMRButton)``;
export const Confirm = styled(EMRButton)``;
