import styled from "styled-components";
import { colors } from "../../../../components/library/theme";

export const FiltersContainer = styled.div`
  display: flex;
  gap: 8px;
  width: fit-content;

  @media screen and (max-width: 740px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
  }
`;

export const Filter = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  gap: 8px;
  min-width: 132px;
  width: fit-content;
  height: 40px;
  padding: 0 16px;

  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 4px;
  font-size: 14px;
  font-weight: ${(props) => (props.isFilterActive ? 600 : 400)};
  color: ${(props) =>
    props.isFilterActive
      ? colors.interface.white000
      : colors.interface.grey400};
  background-color: ${(props) =>
    props.isFilterActive ? props.colors.icon : colors.interface.white000};
  transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out;

  svg path {
    fill: ${(props) =>
      props.isFilterActive ? colors.interface.white000 : props.colors.icon};
  }

  &:hover {
    border-color: ${(props) =>
      props.isFilterActive ? "transparent" : props.colors.hover};
  }

  @media screen and (max-width: 740px) {
    width: 100%;
  }
`;
