import React, { useEffect, useState } from "react";
import { Spinner } from "../../../Spinner";
import { Container, TextWrapper } from "./styles";
import { detectPlatform } from "../../utils/detectPlatform";
import { useHistory } from "react-router-dom";
import CryptoJS from "crypto-js";
import { setCookie } from "cookies-next";
import { PhoneIllustration } from "./PhoneIllustration";
import { EMRButton } from "../../../Button";
import { WarningInfoBox } from "../../components/WarningInfoBox";

export default function RedirectToApp({ credentials }) {
  const [appHasNotLoaded, setAppHasNotLoaded] = useState(false);
  const history = useHistory();
  const isDesktop = detectPlatform() === "DESKTOP";

  function saveCredentials() {
    const encryptedPassword = CryptoJS.AES.encrypt(
      credentials.password,
      "emr-encrypt"
    ).toString();

    setCookie("@emr:email", credentials.email, {
      domain: process.env.DOMAIN_URL
    });

    setCookie("@emr:password", encryptedPassword, {
      domain: process.env.DOMAIN_URL
    });
  }

  function handleAppStore() {
    const isAndroid = navigator.userAgent.toLowerCase().indexOf("android") > -1;

    if (isAndroid) {
      window.location.href =
        "http://play.google.com/store/apps/details?id=com.eumedicoresidente&hl=pt_BR";
      return;
    }

    window.location.href =
      "http://itunes.apple.com/lb/app/emr/id1580470882?mt=8";
  }

  useEffect(() => {
    saveCredentials();
    window.location = "EMR://authenticate";
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setAppHasNotLoaded(true);
    }, 5000);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    if (isDesktop) {
      history.push("/");
    }
  }, [isDesktop]);

  return (
    <Container>
      <PhoneIllustration />

      <TextWrapper>
        <h1>Confirmação de segurança</h1>
        <span>
          Para continuar{" "}
          <strong>abriremos automaticamente nosso aplicativo</strong> no seu
          dispositivo para confirmação de segurança.
        </span>{" "}
        <WarningInfoBox text="Se você não possui nosso aplicativo instalado em seu dispositivo, você será redirecionado para a tela de download, através da loja de aplicativos." />
      </TextWrapper>
      {appHasNotLoaded ? (
        <EMRButton mediumWeight fullSized onClick={handleAppStore}>
          Continuar
        </EMRButton>
      ) : (
        <Spinner />
      )}
    </Container>
  );
}
