import { apiGet } from "./helpers/api";
import { normalize, schema } from "normalizr";

const survey = new schema.Entity("surveys");

export const fetchSurveys = (dispatch, classroomId) => {
  apiGet(`/api/v1/classrooms/${classroomId}/surveys`).then((response) => {
    dispatch({
      type: "SURVEYS_FETCHED",
      ...normalize(response.body.data, new schema.Array(survey)),
    });
  });
};
