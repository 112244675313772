import { orderByAlphabetic } from "./orderByAlphabetic";

export default function makeDropDownOptions(
  collection = [],
  blank,
  sort = orderByAlphabetic
) {
  const options = [];
  if (blank) options.push({ text: blank, value: "" });
  collection.sort(sort).map((item) =>
    options.push({
      text: `${item.name || item.title}`,
      value: item.id,
    })
  );
  return options;
}
