import { createSelector } from "reselect";
import { getAlternatives } from "./alternativeSelectors";
import { getMock } from "./mockSelectors";
import {
  getOpenQuestionsByQuestion,
  getQuestionsFromMock,
} from "./questionSelectors";

const getAnswers = (state) => state.entities.answers;
const getProps = (_, props) => props;

export const getAnswer = createSelector(
  getAnswers,
  getProps,
  (answers, props) => {
    const collection = Object.values(answers).filter(
      (answer) =>
        answer.mockId === props.mockId && answer.questionId === props.questionId
    );

    return collection.length ? collection[collection.length - 1] : null;
  }
);

export const getAlternativeAnswered = createSelector(
  getAnswer,
  getAlternatives,
  (answer, alternatives) => alternatives[answer?.alternativeId]
);

export const makeClosedQuestionStatus = createSelector(
  getAlternativeAnswered,
  (answer) => {
    const status = answer?.value ? "green" : "red";
    return !!answer ? status : undefined;
  }
);

const getStatusColor = (answers) => {
  if (answers.true > answers.false || !answers.false) {
    return "green";
  } else if (answers.false > answers.true || !answers.true) {
    return "red";
  } else {
    return "orange";
  }
};

const countAnswerValuesOccurences = (answereds) =>
  answereds
    .map((a) => a.value)
    .reduce((acc, curr) => {
      if (curr === null || curr === undefined) return {};
      return acc[curr] ? ++acc[curr] : (acc[curr] = 1), acc;
    }, {});

const displayStatusEnabled = (answereds, questionIds, answerValues) =>
  Object.values(answereds).length === questionIds.length &&
  Object.values(answereds).length ===
    Object.values(answerValues).reduce((a, b) => a + b, 0);

export const makeOpenQuestionStatus = createSelector(
  getOpenQuestionsByQuestion,
  getAnswers,
  getProps,
  (openQuestions, answers, props) => {
    const questionIds = openQuestions.map((q) => q.id);
    const answereds = Object.values(answers).filter(
      (a) => a.mockId === props.mockId && questionIds.includes(a.questionId)
    );
    let answerValues;
    answerValues = countAnswerValuesOccurences(answereds);
    const status = getStatusColor(answerValues);
    const displayStatus = displayStatusEnabled(
      answereds,
      questionIds,
      answerValues
    );

    return displayStatus ? status : undefined;
  }
);

export const getAnswersFromMock = createSelector(
  getAnswers,
  getMock,
  (answers, mock) =>
    Object.values(answers).filter((answer) => answer.mockId === mock?.id)
);

export const makeIsComplete = createSelector(
  getAnswersFromMock,
  getQuestionsFromMock,
  (answers, questions) => {
    return (
      answers.filter((answer) => answer.value !== null).length ===
      questions.reduce(
        (total, current) =>
          current.questionIds.length > 0
            ? current.questionIds.length + total
            : total + 1,
        0
      )
    );
  }
);

export const makeOpenQuestionAnswered = createSelector(
  getOpenQuestionsByQuestion,
  getProps,
  getAnswers,
  (openQuestions, props, answers) => {
    const questionIds = openQuestions.map((q) => q.id);
    const answereds = Object.values(answers).filter(
      (a) => a.mockId === props.mockId && questionIds.includes(a.questionId)
    );
    return questionIds?.length === answereds?.length;
  }
);
