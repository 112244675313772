import { actionTypes } from "redux-form";
import { LOCATION_CHANGE } from "connected-react-router";

function setMessage(state, type, payload) {
  return {
    ...state,
    [payload.formName]: {
      type: type,
      id: payload.id,
      visible: true,
      message: payload.message,
    },
  };
}

function clearMessage(state, payload) {
  return {
    ...state,
    [payload.formName]: {
      id: undefined,
      visible: false,
      message: "",
    },
  };
}

export default function formMessagesReducer(state = {}, action) {
  switch (action.type) {
    case "FORM_CHANGED":
      const hasMessage = state[action.payload.formName];
      return hasMessage ? clearMessage(state, action.payload) : state;
    case "FORM_SUBMIT_SUCCEEDED":
      return setMessage(state, "success", action.payload);
    case "FORM_SUBMIT_SUCCESS_EXPIRED":
      const { formName } = action.payload;
      return state[formName] && state[formName].id === action.payload.id
        ? clearMessage(state, action.payload)
        : state;
    case "FORM_SUBMIT_FAILED":
      return setMessage(state, "error", action.payload);
    case "FORM_WARNING_REQUESTED":
      return setMessage(state, "warning", action.payload);
    case "CLOSE_MODAL":
    case LOCATION_CHANGE:
    case actionTypes.INITIALIZE:
      return {};
    default:
      return state;
  }
}
