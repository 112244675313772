import * as React from 'react';

export function OffensiveIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
      <rect width={24} height={24} fill="#FFA24D" rx={12} />
      <g clipPath="url(#a)">
        <path
          fill="#fff"
          d="M15.78 10.402a8.214 8.214 0 0 0-1.46-2.46c-1.084-1.256-2.51-2.067-3.633-2.067a.438.438 0 0 0-.423.547c.38 1.45-.407 2.654-1.239 3.93-.69 1.056-1.4 2.148-1.4 3.398A4.38 4.38 0 0 0 12 18.125a4.38 4.38 0 0 0 4.375-4.375c0-1.184-.2-2.31-.595-3.348Zm-2.896 6.047c-.282.3-.636.364-.884.364s-.602-.063-.884-.364c-.282-.301-.428-.778-.428-1.386 0-.688.24-1.211.474-1.717.135-.294.273-.593.355-.912a.219.219 0 0 1 .342-.124c.25.194.472.42.66.675.497.656.793 1.433.793 2.078 0 .608-.148 1.087-.428 1.386Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M5 5h14v14H5z" />
        </clipPath>
      </defs>
    </svg>
  );
}
