import { apiGet, apiPost } from "./helpers/api";
import { normalize, schema } from "normalizr";
import { fetchMock } from "./mockProccess";
import { notifySubmitSucceeded } from "./helpers/formMessages";

const answer = new schema.Entity("answers");

export const fetchAnswers = (dispatch, mockId) => {
  apiGet(`/api/v1/mocks/${mockId}/answers`).then((response) => {
    dispatch({
      type: "ANSWERS_FETCHED",
      ...normalize(response.body.data, new schema.Array(answer)),
    });
  });
};

export const createAnswer = (dispatch, mockId, alternative) => {
  const { id: alternativeId, ...rest } = alternative;
  return apiPost(`/api/v1/mocks/${mockId}/answers`)
    .send({ answer: { alternativeId, ...rest } })
    .then((response) => {
      dispatch({
        type: "ANSWER_CREATED",
        ...normalize(response.body.data, answer),
      });
      fetchMock(dispatch, mockId);
    });
};

export const createOrUpdateQuestionAnswer = (values, dispatch, props) =>
  apiPost(`/api/v1/mocks/${props.mockId}/answers`)
    .send({
      question_id: props.question.id,
      mock_id: props.mockId,
      ...values,
    })
    .then((response) => {
      dispatch({
        type: "ANSWER_CREATED",
        ...normalize(response.body.data, answer),
      });
      fetchMock(dispatch, props.mockId);
      notifySubmitSucceeded(
        dispatch,
        props.form,
        "Resposta salva com sucesso!",
        props.question.id
      );
    });
