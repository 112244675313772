import React, { useMemo } from "react";
import { css } from "glamor";
import { Accordion, Icon } from "semantic-ui-react";
import LevelListItemContainer from "../levels/LevelListItemContainer";
import { styles, spacing, uiColors, typographyColors } from "../library/theme";
import { AiFillCheckCircle } from "react-icons/ai";
import { MdRadioButtonUnchecked } from "react-icons/md";

const layout = css({
  overflow: "hidden",
  "& + &": { marginTop: 1 },
});
const content = css({
  margin: 0,
  padding: `${spacing.m} !important`,
  border: `1px solid ${uiColors.border}`,
  borderTop: 0,
});
const titleWrapper = css(styles.roundedCorners, {
  border: `1px solid ${uiColors.border} !important`,
  background: uiColors.fieldBackground,
  padding: `${spacing.s} !important`,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  lineHeight: 1,
});
const title = css({
  margin: 0,
  paddingRight: spacing.s,
  flex: 1,
  maxWidth: "100%",
  minWidth: "140px",
});
const compactTitle = css({
  borderRadius: 0,
  border: 0,
  backgroundColor: uiColors.fieldBackground,
});
const compactContent = css({ border: 0 });
const iconStyle = { color: typographyColors.defaultTitle };
const titleWithDuration = css({
  flex: 1,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: spacing.xs,
  lineHeight: 1,
});
const warningStyle = css({
  color: uiColors.background,
  minWidth: "140px",
});
const checkCircle = {
  color: uiColors.background,
  minWidth: 22,
};
const titleClass = css({
  flex: 1,
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  gap: spacing.xs,
});

export default function LevelGroupListItem({
  levelGroup,
  levels,
  handleClick,
  active,
  compact,
  isCompleted,
  hasRatingWarning,
}) {
  const titleWrapperClass = css(titleWrapper, compact && compactTitle);
  const contentClass = css(content, compact && compactContent);
  const memoizedLevels = useMemo(
    () =>
      levels?.map((level, index) => (
        <LevelListItemContainer
          key={index}
          level={level}
          compact={compact}
          levelGroupPosition={levelGroup.position}
        />
      )),
    [levels, compact]
  );

  if (!memoizedLevels) return null;

  return (
    <Accordion className={layout.toString()}>
      <Accordion.Title
        className={titleWrapperClass.toString()}
        onClick={handleClick}
      >
        <div className={titleClass}>
          <div className={titleWithDuration}>
            {isCompleted ? (
              <AiFillCheckCircle style={checkCircle} size={22} />
            ) : (
              <MdRadioButtonUnchecked style={checkCircle} size={22} />
            )}
            <div className={titleClass}>
              <h5 className={title}>{levelGroup.name}</h5>
              {hasRatingWarning && (
                <div className={warningStyle}>Avaliações pendentes</div>
              )}
            </div>
          </div>
        </div>
        <Icon name={active ? "chevron up" : "chevron down"} style={iconStyle} />
      </Accordion.Title>
      <Accordion.Content className={contentClass.toString()} active={active}>
        {active && memoizedLevels}
      </Accordion.Content>
    </Accordion>
  );
}
