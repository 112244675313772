import { createSelector } from "reselect";
import { getLessonId } from "./lessonSelectors";

const getAnnotations = (state) => state.entities.annotations;

export const getAnnotationsByLesson = createSelector(
  getLessonId,
  getAnnotations,
  (lessonId, annotations) =>
    Object.values(annotations).filter(
      (annotation) => annotation.lessonId === lessonId
    )
);
