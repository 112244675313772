import { css } from "glamor";
import React, { useEffect, useState } from "react";
import { CgFileDocument } from "react-icons/cg";
import { FaGraduationCap, FaSignOutAlt } from "react-icons/fa";
import { AiOutlineDashboard } from "react-icons/ai";
import { GoGraph } from "react-icons/go";
import { IoIosHelpCircle } from "react-icons/io";
import {
  IoBook,
  IoCompass,
  IoHome,
  IoMedal,
  IoOpenOutline,
  IoPieChart,
  IoLockClosedOutline,
} from "react-icons/io5";
import { matchPath } from "react-router-dom";
import { EMRButton } from "../../componentsV2/Button";
import { makeIsMobile } from "../../utils/useScreenWidth";
import MenuItem from "../library/MenuItem";
import { Tooltip } from "../Tooltip";
import { setCookie } from "cookies-next";
import styled from "styled-components";
import { getThemeByUniversity } from "../../utils/getThemeByUniversity";
import { useHistory } from "react-router-dom";
import { setContentViewByExamInProgress } from "../../processes/contentBlockProcess";
import { SidebarModal } from "./SidebarModal";
import * as Dialog from "@radix-ui/react-dialog";
import { trackClickEvents } from "../../../services/mixpanel/events/trackClickEvents";
import { FlashcardsIcon } from "./FlashcardsIcon";
import { FrialModal } from "./FrialModal";
import { FrialButton, FrialButtonsContainer } from "./styles";
import { VideoDuration } from "./VideoDurationIcon";
import { verifyIsSuperSimulado } from "../../componentsV2/SuperSimuladoModal/verifySuperSimulado";

export const ButtonContainer = styled.div`
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  margin: auto 16px 24px 16px;
`;

export const TooltipContainer = styled.div`
  pointer-events: none;
  display: none;

  @media (max-width: 991px) {
    display: none;
  }
`;

const FixedTooltipContainer = styled.div`
  width: 100%;
  position: relative;
`;

const FixedTooltip = styled.p`
  display: flex;
  align-items: center;
  position: absolute;
  top: -66px;
  left: 0;
  height: 52px;
  width: 100%;
  background: #edf0f2;
  padding: 8px;
  font-size: 12px;
  border-radius: 4px;
  border: 1px solid #c2ced6;

  ::after {
    content: "";
    display: block;
    position: absolute;
    height: 10px;
    width: 10px;
    border: solid #c2ced6;
    background-color: #edf0f2;
    border-top-left-radius: 2px;
    border-width: 0 0 1px 1px;
    transform: translate(-50%, -50%) rotate(315deg);
    bottom: -10px;
    left: 50%;
  }
`;

const userInfo = css({
  display: "none",
  padding: "24px 24px 20px 28px",

  "& > span": {
    display: "block",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontSize: "14px",
    lineHeight: "21px",
  },

  "& > span:first-child": {
    fontWeight: 600,
  },

  "@media (max-width: 991px)": { display: "block" },
});

const desktopSidebar = css({
  display: "flex",
  flexDirection: "column",
  height: "100%",

  "@media (min-width: 992px)": {
    position: "fixed",
    left: 0,
    top: 70,
    width: 250,
    height: "calc(100vh - 70px)",
  },
});

export default function MainMenu(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userHasMedtestExams, setUserHasMedtestExams] = useState(false);
  const [shouldCheckForExamInProgress, setShouldCheckForExamInProgress] =
    useState(false);
  const [isFetchingB2BUserData, setIsFetchingB2BUserData] = useState(false);
  const [isSidebarModalOpen, setIsSidebarModalOpen] = useState(false);
  const [sidebarModalVariant, setSidebarModalVariant] = useState(null);
  const [superSimulado, setSuperSimulado] = useState(null);
  const {
    location,
    mocksEnabled,
    logoutHandler,
    isB2BUserExamInProgress,
    dispatch,
    medtrackEnabled,
    currentUser,
    isFrial,
    isB2BUser,
    isFrialRequestLoaded,
    guruUrl,
    completeGuruUrl,
    firstClassroom,
    classrooms,
  } = props;

  const hasFlashcards = Object.values(classrooms).some(
    (classroom) => classroom.has_flashcards
  );

  const currentPath = location.pathname;
  const isMobile = makeIsMobile();
  const { IES } = getThemeByUniversity();
  const history = useHistory();
  const isContentBlocked = isB2BUserExamInProgress || isFetchingB2BUserData;
  const session = JSON.parse(localStorage.getItem("session"));
  const userPlatformID = session.currentUserId;
  const userPlatformName = currentUser?.name;
  const userPlatformEmail = currentUser?.email;

  function trackEventMenu({ label, isFrial }) {
    trackClickEvents({
      eventName: `clique no menu lateral`,
      content: {
        UserFrial: isFrial,
        NomeDaPagina: label,
      },
    });
  }

  async function getExamsByUser() {
    try {
      const requestBody = {
        userPlatformID: userPlatformID,
      };

      const examsResponse = await fetch(
        `${process.env.MEDTESTAPI_URL}mock-template/findExamsByUser`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      const examsData = await examsResponse.json();

      return examsData.exams;
    } catch (error) {
      console.error(error);
    }
  }

  function blockContent() {
    setContentViewByExamInProgress(dispatch, true);

    const isOnMocksPage = matchPath(currentPath, {
      path: "/mocks",
      exact: false,
    });
    const isOnAnnotationsBookPage = matchPath(currentPath, {
      path: "/annotations-book",
      exact: false,
    });
    const isOnLessonsPage = matchPath(currentPath, {
      path: "/classrooms/",
      exact: false,
    });

    if (isOnMocksPage || isOnAnnotationsBookPage || isOnLessonsPage) {
      history.push("/classrooms");
    }
  }

  function unBlockContent() {
    setContentViewByExamInProgress(dispatch, false);
  }

  async function checkIfUserHasMedtestExam() {
    setIsFetchingB2BUserData(true);
    const exams = await getExamsByUser();
    setIsFetchingB2BUserData(false);

    if (exams?.length > 0) setUserHasMedtestExams(true);

    if (!isB2BUser) {
      setShouldCheckForExamInProgress(false);
      setContentViewByExamInProgress(dispatch, false);
      return;
    }

    const hasExamInProgress = await checkIfUserHasExamInProgress();
    setShouldCheckForExamInProgress(true);
    setContentViewByExamInProgress(dispatch, hasExamInProgress);

    if (hasExamInProgress) {
      blockContent();
    }
  }

  async function checkIfUserHasExamInProgress() {
    try {
      const isExamInProgressResponse = await fetch(
        `${process.env.MEDTESTAPI_URL}user/${userPlatformID}/check-exam-in-progress/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const isExamInProgressData = await isExamInProgressResponse.json();
      const isExamInProgress = isExamInProgressData.inProgress;

      return isExamInProgress;
    } catch (error) {
      console.error(error);
    }
  }

  const tooltipRef = React.useRef(null);

  function handleMouseOver(event) {
    const elementProps = event.target.getBoundingClientRect();
    const top = elementProps.top;
    const width = elementProps.width;
    const height = elementProps.height / 2;
    tooltipRef.current.style.display = "block";
    tooltipRef.current.style.top = `${top + height}px`;
    tooltipRef.current.style.left = `${width - 24}px`;
    tooltipRef.current.style.position = "fixed";
    tooltipRef.current.style.zIndex = 999;
  }

  function navigateToMedtest() {
    checkIfUserHasMedtestExam();

    setCookie(process.env.METRICO_USER_ID_COOKIE, userPlatformID, {
      domain: process.env.DOMAIN_URL,
    });

    setCookie(process.env.MEDTEST_USER_NAME, userPlatformName, {
      domain: process.env.DOMAIN_URL,
    });

    setCookie(process.env.MEDTEST_USER_EMAIL, userPlatformEmail, {
      domain: process.env.DOMAIN_URL,
    });

    window.open(process.env.MEDTEST_URL, "_blank", "noopener,noreferrer");
  }

  function navigateToMetricas() {
    setCookie(process.env.METRICO_USER_ID_COOKIE, userPlatformID, {
      domain: process.env.DOMAIN_URL,
    });

    window.open(process.env.METRICO_URL, "_blank", "noopener,noreferrer");
  }

  function handleCloseSidebarModal() {
    setIsSidebarModalOpen(false);
    setSidebarModalVariant(null);
  }

  function handleClickOnMedtest() {
    trackEventMenu({ label: "Medtest", isFrial });
    navigateToMedtest();
  }

  function handleClickOnMetricas() {
    trackEventMenu({ label: "Métricas EMR", isFrial });
    if (isFrial) {
      setIsSidebarModalOpen(true);
      setSidebarModalVariant("metricas");
    } else {
      navigateToMetricas();
    }
  }

  function handleCloseFrialModal() {
    setIsModalOpen(false);
  }

  useEffect(() => {
    if (isFrial) verifyIsSuperSimulado().then(setSuperSimulado);
    setCookie("isFrialCheckout", isFrial, {
      domain: process.env.DOMAIN_URL,
    });
  }, [isFrial]);

  useEffect(() => {
    checkIfUserHasMedtestExam();
  }, [isB2BUser]);

  useEffect(() => {
    setCookie(
      "session",
      JSON.stringify({
        headers: {
          "access-token": session.headers["access-token"],
          client: session.headers.client,
          uid: session.headers.uid,
        },
      }),
      {
        domain: process.env.DOMAIN_URL,
      }
    );
  }, []);

  useEffect(() => {
    if (!shouldCheckForExamInProgress) return;

    const checkIfUserHasExamInProgressInterval = setInterval(async () => {
      const hasExamInProgress = await checkIfUserHasExamInProgress();
      hasExamInProgress ? blockContent() : unBlockContent();
    }, 15000);

    return () => clearInterval(checkIfUserHasExamInProgressInterval);
  }, [shouldCheckForExamInProgress]);

  function formatDate(date) {
    if (!date) return "";
    return new Date(date).toLocaleDateString("pt-br");
  }

  function isMedtestButtonEnabled() {
    if (isFrial) {
      if (superSimulado?.next_exam) {
        const avaiableDay = new Date(superSimulado?.next_exam).getTime();
        const today = new Date().getTime();
        return today < avaiableDay;
      }
      return true;
    }

    return !userHasMedtestExams;
  }

  return (
    <>
      <TooltipContainer ref={tooltipRef}>
        <Tooltip isContentBlocked={isContentBlocked}>
          Para manter a integridade do processo avaliativo, algumas
          funcionalidades da sua plataforma estarão desativadas apenas durante a
          realização do seu simulado.
        </Tooltip>
      </TooltipContainer>

      <div className={desktopSidebar}>
        <div>
          <div className={userInfo}>
            <span>{currentUser?.name}</span>
            <span>{currentUser?.email}</span>
          </div>

          {!isB2BUser && (
            <MenuItem
              to="/"
              active={!!matchPath(currentPath, { path: "/", exact: true })}
              label="Início"
              icon={IoHome}
              mouseHover={isContentBlocked && handleMouseOver}
              tooltip={tooltipRef}
              onClick={() => trackEventMenu({ label: "Início", isFrial })}
            />
          )}

          {medtrackEnabled && (
            <MenuItem
              to="/medtrack"
              active={
                !!matchPath(currentPath, { path: "/medtrack", exact: true })
              }
              label="MedTrack"
              icon={IoCompass}
              mouseHover={isContentBlocked && handleMouseOver}
              tooltip={tooltipRef}
              onClick={() => trackEventMenu({ label: "Medtrack", isFrial })}
            />
          )}

          <MenuItem
            to={isContentBlocked ? "" : "/classrooms"}
            active={
              !!matchPath(currentPath, { path: "/classrooms", exact: true })
            }
            isDisabled={isContentBlocked}
            label="Meus Cursos"
            icon={FaGraduationCap}
            mouseHover={isContentBlocked && handleMouseOver}
            tooltip={tooltipRef}
            onClick={() => trackEventMenu({ label: "Meus Cursos", isFrial })}
          />

          {hasFlashcards && (
            <MenuItem
              to={isContentBlocked ? "" : "/flashcards"}
              active={
                !!matchPath(currentPath, { path: "/flashcards", exact: true })
              }
              isDisabled={isContentBlocked}
              label="Flashcards"
              icon={FlashcardsIcon}
              mouseHover={isContentBlocked && handleMouseOver}
              tooltip={tooltipRef}
              onClick={() => trackEventMenu({ label: "Flashcards", isFrial })}
            />
          )}

          {mocksEnabled && (
            <MenuItem
              to={isContentBlocked ? "" : "/mocks"}
              active={!!matchPath(currentPath, { path: "/mocks", exact: true })}
              isDisabled={isContentBlocked}
              label="Simulados"
              icon={CgFileDocument}
              onClick={() => {
                !isContentBlocked &&
                  trackEventMenu({ label: "Simulados", isFrial });
              }}
              mouseHover={isContentBlocked && handleMouseOver}
              tooltip={tooltipRef}
            />
          )}

          <MenuItem
            to={isContentBlocked ? "" : "/annotations-book"}
            active={
              !!matchPath(currentPath, {
                path: "/annotations-book",
                exact: true,
              })
            }
            isDisabled={isContentBlocked}
            label="Caderno de erros"
            icon={IoBook}
            mouseHover={isContentBlocked && handleMouseOver}
            tooltip={tooltipRef}
            onClick={() => {
              trackEventMenu({ label: "Caderno de erros", isFrial });
            }}
          />
          <MenuItem
            to="/help"
            active={!!matchPath(currentPath, { path: "/help", exact: true })}
            label="Central de Ajuda"
            icon={IoIosHelpCircle}
            mouseHover={isContentBlocked && handleMouseOver}
            tooltip={tooltipRef}
            onClick={() => {
              trackEventMenu({ label: "Central de Ajuda", isFrial });
            }}
          />

          {isMobile && (
            <MenuItem
              onClick={logoutHandler}
              label="Sair"
              icon={FaSignOutAlt}
            />
          )}
        </div>

        {isFrialRequestLoaded && (
          <ButtonContainer>
            <FixedTooltipContainer>
              {isFrial && superSimulado?.is_lp_super_simulado_user && (
                <FixedTooltip>
                  Super Simulado estará disponível no dia{" "}
                  {formatDate(superSimulado?.next_exam)}.
                </FixedTooltip>
              )}

              <EMRButton
                mode="redirect"
                fontSize="lg"
                onClick={handleClickOnMedtest}
                // disabled={isMedtestButtonEnabled()}
              >
                <IoMedal />
                Medtest
                <IoOpenOutline />
              </EMRButton>
            </FixedTooltipContainer>

            <EMRButton
              mode="redirect"
              fontSize="lg"
              onClick={handleClickOnMetricas}
            >
              <IoPieChart />
              {IES ? "Métricas" : "Métricas EMR"}
              {isFrial ? <IoLockClosedOutline size={16} /> : <IoOpenOutline />}
            </EMRButton>
          </ButtonContainer>
        )}
      </div>

      {isSidebarModalOpen && (
        <Dialog.Root
          open={isSidebarModalOpen}
          onOpenChange={setIsSidebarModalOpen}
        >
          <SidebarModal
            userEmail={userPlatformEmail}
            variant={sidebarModalVariant}
            onClose={handleCloseSidebarModal}
            guruUrl={guruUrl}
            firstClassroom={firstClassroom}
            completeGuruUrl={completeGuruUrl}
          />
        </Dialog.Root>
      )}

      {isModalOpen && (
        <Dialog.Root open={isModalOpen} onOpenChange={setIsModalOpen}>
          <FrialModal onClose={handleCloseFrialModal} />
        </Dialog.Root>
      )}
    </>
  );
}
