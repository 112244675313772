import React from "react";
import Spinner from "../library/Spinner";
import GraphCard from "./GraphCard";
import DefaultError from "../library/DefaultError";
import QuestionInfoCard from "./QuestionInfoCard";
import LessonsCard from "./LessonsCard";
import { css } from "glamor";
import { spacing, uiColors } from "../library/theme";
import MocksInfo from "./MocksInfo";
import { statusConstants } from "../../utils/statusConstants";

const container = {
  maxWidth: 1200,
  margin: "0 auto",
};
const cardsWrapper = css({
  display: "flex",
  flexWrap: "wrap",
  gap: spacing.m,
  paddingBottom: spacing.l,
  marginBottom: spacing.l,
  borderBottom: `solid 1px ${uiColors.border}`,
});
const cardClass = css({
  flex: 1,
  minWidth: 250,
});
const paneContainer = {
  padding: spacing.m,
};

export default function MyPerformanceGeneral({
  classroom,
  status,
  loading,
  classroomReport,
  scoreChartData,
}) {
  return (
    <div style={paneContainer}>
      {status == statusConstants.LOADING && (
        <Spinner text="Carregando informações..." />
      )}
      {status == statusConstants.ERROR && <DefaultError />}
      {status == statusConstants.RESOLVED && (
        <div style={container}>
          <div className={cardsWrapper}>
            <div className={cardClass}>
              <LessonsCard
                loading={loading}
                lessonsData={classroomReport?.lessons}
              />
            </div>
            <div className={cardClass}>
              <MocksInfo loading={loading} mocksData={classroomReport?.mocks} />
            </div>
          </div>
          <QuestionInfoCard mocksData={classroomReport?.mocks} />
          <GraphCard scoreChartData={scoreChartData} classroom={classroom} />
        </div>
      )}
    </div>
  );
}
