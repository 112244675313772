import { createSelector } from "reselect";
import { getAllLevels, getLevel, getLevels } from "./levelSelectors";
import { getCurrentClassroomId } from "./classroomSelectors";
import { orderByPosition } from "../utils/orderByPosition";
import { getLevelGroupsByClassroom } from "./levelGroupSelectors";

const getLessons = (state) => state.entities.lessons;
export const getLessonId = (_, id) => id;
export const getCurrentLessonId = (state) => state.currentLessonId;

export const getCurrentLesson = createSelector(
  getLessons,
  getLessonId,
  (lessons, id) => lessons[id]
);

export const getLevelIdByLesson = createSelector(
  getCurrentLesson,
  (lesson) => lesson?.levelId
);

export const getLessonsByLevel = createSelector(
  getLevel,
  getLessons,
  (level, lessons) =>
    Object.values(lessons)
      .filter((lesson) => lesson.levelId === level.id)
      .sort(orderByPosition)
);

export const getCurrentLevel = createSelector(
  getLevels,
  getLessons,
  getCurrentLessonId,
  (levels, lessons, lessonId) => {
    const currentLesson = lessons[lessonId];
    return levels[currentLesson?.levelId];
  }
);

export const listOfLessons = createSelector(
  getLessons,
  getCurrentClassroomId,
  (lessons, classroomId) =>
    Object.values(lessons)
      .filter((lesson) => lesson.classroomIds.includes(Number(classroomId)))
      .sort(orderByPosition)
);

export const orderedListOfLessons = createSelector(
  getLevelGroupsByClassroom,
  getAllLevels,
  listOfLessons,
  (levelGroups, levels, lessons) => {
    const allLessonsOrdered = [];
    levelGroups?.forEach((levelGroup) => {
      levels
        ?.filter((level) => level?.levelGroupId === levelGroup?.id)
        .sort(orderByPosition)
        .forEach((level) => {
          lessons
            ?.filter((lesson) => lesson?.levelId === level?.id)
            .forEach((lesson) => allLessonsOrdered.push(lesson));
        });
    });
    return allLessonsOrdered;
  }
);

const nextOrPrevLesson = (lessons, currentId, value = 1) => {
  const lesson = lessons.filter((lesson) => lesson.id === currentId)[0];
  const idx = lessons?.indexOf(lesson);
  return lessons[idx + value];
};

export const getNextLesson = createSelector(
  orderedListOfLessons,
  getCurrentLessonId,
  (lessons, currentId) => nextOrPrevLesson(lessons, currentId)
);

export const getPrevLesson = createSelector(
  orderedListOfLessons,
  getCurrentLessonId,
  (lessons, currentId) => nextOrPrevLesson(lessons, currentId, -1)
);

export const makeIsLessonMock = createSelector(
  getLessons,
  getLessonId,
  (lessons, lessonId) => lessons[lessonId]?.lessonType === "mock"
);

export const makeIsLessonPdf = createSelector(
  getLessons,
  getLessonId,
  (lessons, lessonId) => lessons[lessonId]?.lessonType === "pdf"
);
