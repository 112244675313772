import * as Dialog from "@radix-ui/react-dialog";
import styled from "styled-components";
import Spinner from "../../../../components/library/Spinner";
import { colors } from "../../../../components/library/theme";
import { EMRButton } from "../../../../componentsV2/Button";

export const DialogOverlay = styled(Dialog.Overlay)`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: fixed;
  overflow: auto;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: rgba(54, 62, 70, 0.3);
  z-index: 10;
  padding: 0;
  animation: overlayShow 300ms cubic-bezier(0.16, 1, 0.3, 1);

  @keyframes overlayShow {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const DialogContent = styled(Dialog.Content)`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background-color: ${colors.interface.white000};
  padding: 24px;
  width: min(600px, calc(100vw - 48px));
  margin: auto;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  animation: contentShow 300ms cubic-bezier(0.16, 1, 0.3, 1);

  @keyframes contentShow {
    from {
      opacity: 0;
      transform: scale(0.96);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
`;

export const CloseButton = styled.button`
  align-self: flex-end;
  background-color: transparent;
  width: 40px;
  height: 40px;
  border-radius: 100% !important;
  border-style: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.2s ease-in-out;
  z-index: 1;

  svg {
    line-height: 0;
    transition: color 0.2s ease-in-out;
  }

  &:hover {
    background-color: ${colors.interface.grey100};

    svg {
      color: ${colors.interface.grey500} !important;
    }
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > h3 {
    font-size: 18px;
    font-weight: 600;
    margin: 0px;
  }
`;

export const Strong = styled.strong`
  font-weight: 600;
`;

export const Text = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 8px;
`;
export const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;

  button {
    width: 100%;
  }

  @media (max-width: 991px) {
    flex-direction: column-reverse;
  }
`;

export const DialogClose = styled(Dialog.Close)`
  cursor: pointer;

  svg {
    color: ${colors.interface.grey400};
  }
`;

export const StyledSpinner = styled(Spinner)`
  border-color: rgba(0, 0, 0, 0.14) transparent transparent;
`;

export const StyledEMRButton = styled(EMRButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-weight: 600;
  font-size: 16px;

  @media (max-width: 991px) {
    font-size: 14px;
    height: 40px;
  }
`;

export const TextArea = styled.textarea`
  height: 140px;
  resize: none;
  font-size: 14px;
  color: #606a71;
  padding: 16px;
  border: 1px solid #c2ced6;
  border-radius: 4px;
  margin-bottom: 16px;
  &:focus {
    outline: none;
    border-color: ${colors.medtrack.green150};
  }
  ::placeholder {
    color: #c2ced6;
    font-weight: 400;
  }
`;

export const Label = styled.label`
  color: #606a71;
  font-size: 14px;
`;
