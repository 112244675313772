import React from "react";
import { css } from "glamor";
import { Container } from "semantic-ui-react";
import { spacing } from "./theme";

const layout = css({ maxWidth: 1366 });
const paddedStyle = css({
  padding: spacing.m,
});

export default ({ className, padded, ...rest }) => {
  const containerClass = css(layout, padded && paddedStyle, className);
  return <Container className={containerClass.toString()} {...rest} />;
};
