import React from "react";
import { IoTime } from "react-icons/io5";
import { ClipboardCheck } from "../../TypeTag/icons/ClipboardCheck";

export function getDescriptionIcon({ category, subcategory }) {
  if (category === "practice") {
    return subcategory === "questions" ? (
      <ClipboardCheck fill={"#C2CED6"} />
    ) : null;
  }

  if (category === "revision" || category === "revision_lesson") {
    return <IoTime fill={"#C2CED6"} size={16} />;
  }

  return null;
}
