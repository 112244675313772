import { createSelector } from "reselect";
import { getMock } from "./mockSelectors";
import { orderByCode } from "../utils/orderByCode";
import { orderByPosition } from "../utils/orderByPosition";

const getQuestions = (state) => state.entities.questions;
const getCurrentIndex = (state) => state.mock.currentIndex;
const getQuestionsMarkedAsFavorite = (state) =>
  state.mock.questionsMarkedAsFavoriteIds;
const getIndex = (_, index) => index;
const getId = (_, id) => id;
const getProps = (_, props) => props;

export const getQuestionsFromMock = createSelector(
  getQuestions,
  getMock,
  (questions, mock) => {
    const values = Object.values(questions).filter((question) =>
      mock?.questionIds?.includes(question.id)
    );

    if (mock?.title?.toLowerCase()?.includes("simulado inteligente"))
      return values.sort(orderByPosition);
    return values.sort(mock?.mockTemplateId ? orderByPosition : orderByCode);
  }
);

export const makeCurrentQuestion = createSelector(
  getQuestionsFromMock,
  getCurrentIndex,
  (questions, currentIndex) => questions[currentIndex]
);

export const getQuestionByIndex = createSelector(
  getQuestionsFromMock,
  getIndex,
  (questions, index) => Object.values(questions)[index]
);

export const makeIsMarkedAsFavorite = createSelector(
  getQuestionsMarkedAsFavorite,
  getId,
  (questionIds, id) => {
    return questionIds.includes(id);
  }
);

export const getQuestionsFromCurrentQuestion = createSelector(
  makeCurrentQuestion,
  getQuestions,
  (currentQuestion, questions) => {
    return Object.values(questions).filter((question) =>
      currentQuestion.questionIds.includes(question.id)
    );
  }
);

export const getOpenQuestionsByQuestion = createSelector(
  getQuestions,
  getProps,
  (questions, props) => {
    const currentQuestion = questions[props.questionId];
    return Object.values(questions).filter((q) =>
      currentQuestion?.questionIds.includes(q.id)
    );
  }
);
