import * as Dialog from "@radix-ui/react-dialog";
import React, { useState, useCallback } from "react";
import { IoClose } from "react-icons/io5";
import Input from "../../../componentsV2/Input";
import { EMRButton } from "../../../componentsV2/Button";
import {
  ButtonContainer,
  DialogClose,
  DialogContent,
  DialogOverlay,
  DialogTitle,
  DialogSubTitle,
  Header,
  Form,
} from "./styles";

export function EditModal({
  isOpen,
  handleModal,
  mock,
  updateMockHandler,
  setNewTitle,
}) {
  const [mockName, setMockName] = useState(mock.name);

  const handleSubmit = (event) => {
    event.preventDefault();
    updateMockHandler(mock.id, mockName);
    handleModal();
    window.location.reload();
  };

  return (
    <Dialog.Root open={isOpen} onOpenChange={handleModal}>
      <Dialog.Portal>
        <DialogOverlay>
          <DialogContent>
            <Header>
              <DialogTitle>
                <h2>Alterar nome do simulado</h2>
                <button>
                  <IoClose onClick={handleModal} size={24} />
                </button>
              </DialogTitle>
              <DialogSubTitle>
                Para identificar seu simulado de forma diferente, altere abaixo
                o nome do simulado {mock.name}.
              </DialogSubTitle>
            </Header>

            <Form onSubmit={handleSubmit}>
              <Input
                onChange={(e) => {
                  setMockName(e.target.value);
                  setNewTitle(e.target.value);
                }}
                label="Nome do simulado"
              />

              <ButtonContainer>
                <DialogClose asChild>
                  <EMRButton mode={"cancel"} fontSize={"lg"}>
                    Cancelar
                  </EMRButton>
                </DialogClose>

                <EMRButton mode={"standard"} fontSize={"lg"} type="submit">
                  Salvar alterações
                </EMRButton>
              </ButtonContainer>
            </Form>
          </DialogContent>
        </DialogOverlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
