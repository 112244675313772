import React from "react"
import { Button, Container, Text } from "./styles"
import googleLogo from "./google.svg"
import appleLogo from "./apple.svg"
import { trackClickEvents } from "../../../../../services/mixpanel/events/trackClickEvents"


function SignUpWith({ onRegisterWith }) {

  function handleRegisterGoogle(){
    trackClickEvents({eventName:"Clique em Cadastre-se com o Google"})
    onRegisterWith('google')
  }

  function handleRegisterApple(){
    trackClickEvents({eventName:"Clique em Cadastre-se com o Apple"})
    onRegisterWith('apple')
  }

  return (
    <Container>
      <Text>Faça cadastro com</Text>
      <Button onClick={handleRegisterGoogle}>
        <img src={googleLogo} />
        <span>Cadastre-se com o Google</span>
      </Button>
      <Button onClick={handleRegisterApple}>
        <img src={appleLogo} />
        <span>Cadastre-se com a Apple</span>
      </Button>
    </Container>
  )
}

export default SignUpWith
