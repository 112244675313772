import React, { useEffect, useRef, useState } from "react"
import { Button, Error, InputWrapper, Label } from "./styles"
import { IoEyeOffOutline, IoEyeOutline } from 'react-icons/io5'
import { colors } from "../../components/library/theme"
import InputMask from 'react-input-mask'

function Input(props) {
  const inputRef = useRef(null)
  const parentRef = useRef(null)

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const RED = colors.status.red300
  const GREEN = colors.brand.pure
  const GREY = colors.interface.grey200

  function handleFocus() {
    const parent = parentRef.current

    parent.style.borderColor = props.error ? RED : GREEN
  }

  function handleBorderColor(e) {
    const parent = parentRef.current

    if (e.target.value === '') {
      parent.style.borderColor = props.error ? RED : GREY
    } else {
      parent.style.borderColor = props.error ? RED : GREEN
    }
  }
  useEffect(() => {

    if (inputRef.current) {
      inputRef.current.addEventListener('change', handleBorderColor)
      inputRef.current.addEventListener('focus', handleFocus)
      inputRef.current.addEventListener('blur', handleBorderColor)
    }

    if (parentRef.current) {
      const hasContent = inputRef.current.value !== ''
      const hasError = Boolean(props.error)

      parentRef.current.style.borderColor = GREY
      if (hasContent) parentRef.current.style.borderColor = GREEN
      if (hasError) parentRef.current.style.borderColor = RED
    }

    if (inputRef.current && props.autoFocus) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 500);
    }
  }, [props.error])

  return (
    <div>
      <InputWrapper hasValidationError={props.error} ref={parentRef}>
        {
          props.inputMask ?
            <InputMask
              inputRef={(ref) => inputRef.current = ref}
              type={showPassword ? 'text' : props.ispassword ? 'password' : 'text'}
              placeholder=" "
              {...props}
              mask={props.inputMask}
              maskChar={null}
            />
            :
            <input
              ref={inputRef}
              type={showPassword ? 'text' : props.ispassword ? 'password' : 'text'}
              placeholder=" "
              {...props}
            />
        }


        <Label>{props.label}</Label>
        {props.ispassword && (
          <Button onClick={togglePasswordVisibility} type="button">
            {showPassword ? <IoEyeOutline size={16} color={colors.interface.grey400} />
              :
              <IoEyeOffOutline size={16} color={colors.interface.grey400} />
            }
          </Button>
        )}
      </InputWrapper>
      {props.error && <Error>{props.error}</Error>}
    </div>
  );
}

export default Input