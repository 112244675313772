import request, { get, patch, post, put } from "superagent";
import normalizeObject from "../../lib/normalizeObject";
import middleware from "./railsMiddleware";
import unauthorizedMiddleware from "./unauthorizedMiddleware";

export function serializeSnakeCase(data) {
  return JSON.stringify(normalizeObject(data, "snake"));
}

export function apiGet(url) {
  return get(url)
    .use(middleware)
    .use(unauthorizedMiddleware)
    .type("json")
    .accept("json");
}

export function apiPost(url, useCamel = false, ignoreRedirect) {
  if (!useCamel) {
    return post(url)
      .use(middleware)
      .use((e) => unauthorizedMiddleware(e, ignoreRedirect))
      .type("json")
      .accept("json")
      .serialize(serializeSnakeCase);
  }

  return post(url)
    .use(middleware)
    .use((e) => unauthorizedMiddleware(e, ignoreRedirect))
    .type("json")
    .accept("json");
}

export function apiPatch(url) {
  return patch(url)
    .use(middleware)
    .use(unauthorizedMiddleware)
    .type("json")
    .accept("json")
    .serialize(serializeSnakeCase);
}

export function apiDel(url) {
  return request
    .del(url)
    .use(middleware)
    .use(unauthorizedMiddleware)
    .type("json")
    .accept("json");
}

export function apiPut(url, skipSerialize = false) {
  if (skipSerialize) {
    return put(url)
      .use(middleware)
      .use(unauthorizedMiddleware)
      .type("json")
      .accept("json");
  }

  return put(url)
    .use(middleware)
    .use(unauthorizedMiddleware)
    .type("json")
    .accept("json")
    .serialize(serializeSnakeCase);
}
