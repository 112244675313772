import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { fetchQuestions } from '../../processes/questionProccess'
import { fetchAnswers } from '../../processes/answerProccess'
import { fetchAlternatives } from '../../processes/alternativeProccess'
import { makeCurrentQuestion } from '../../selectors/questionSelectors'
import Question from './Question'
import {
  getAlternativeAnswered,
  makeOpenQuestionAnswered,
} from '../../selectors/answerSelector'
import { getMock } from '../../selectors/mockSelectors'
import { getMockPreferences } from '../../selectors/mockSelectors.js'
import Spinner from '../../components/library/Spinner'
import { getMockTemplateById } from '../../selectors/mockTemplateSelectors'

function QuestionContainer(props) {
  const [loading, setLoading] = useState(true)
  const { dispatch, mock } = props
  const mockId = mock?.id

  useEffect(() => {
    if (mockId) {
      fetchQuestions(dispatch, mockId, () => setLoading(false))
      fetchAnswers(dispatch, mockId)
      fetchAlternatives(dispatch, mockId)
    }
  }, [dispatch, mockId])

  return loading ? <Spinner /> : <Question {...props} />
}

function mapStateToProps(state, ownProps) {
  const { isExam, isFinished } = ownProps
  const question = makeCurrentQuestion(state)
  const mock = getMock(state)
  const answered =
    question?.questionType === 'alternatives'
      ? getAlternativeAnswered(state, {
        questionId: question?.id,
        mockId: mock?.id,
      })
      : makeOpenQuestionAnswered(state, {
        questionId: question?.id,
        mockId: mock?.id,
      })

  const { showQuestionVideoUrl, showQuestionExtraInfo } =
    getMockPreferences(state)
  const mockTemplate = getMockTemplateById(state, mock?.mockTemplateId)
  const isPreExam = mockTemplate ? mockTemplate.preExam : false

  return {
    index: state.mock.currentIndex,
    showAnswer: isPreExam ? false : isFinished || (answered && !isExam),
    replied: state.mock.selectedAlternatives[question?.id],
    question,
    mock,
    showQuestionVideoUrl,
    showQuestionExtraInfo,
  }
}

export default connect(mapStateToProps)(QuestionContainer)
