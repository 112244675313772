import React, { useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import makeDropDownOptions from "../../utils/makeDropDownOptions";
import {
  CheckBoxField,
  DropDownField,
  Form,
  TextField,
  RadioField,
  Label
} from "../library/forms";
import { spacing } from "../library/theme";
import MockModalFooter from "./MockModalFooter";

const difficultyOptions = [
  { text: "Fácil", value: "easy" },
  { text: "Média", value: "medium" },
  { text: "Difícil", value: "hard" }
];

const questionsTypeWrapper = {
  marginTop: spacing.xxs,
  display: "flex",
  flexWrap: "wrap"
};

const MAX_QUESTION_QUANTITY = 120;
const MIN_QUESTION_QUANTITY = 1;

export default function MockModalForm(props) {
  const {
    error,
    specialities,
    subjects,
    states,
    organizers,
    examTypeCategories,
    years,
    onlyAnsweredWrong,
    notAnswered,
    hasContent
  } = props;

  const [questionsType, setQuestionsType] = useState(
    props.initialValues.questionsType
  );

  function handleValidateMaxValue(e) {
    if (e.target.value > MAX_QUESTION_QUANTITY) {
      e.target.value = "120";
    }
  }

  function handleValidateMinValue(e) {
    if (e.target.value < MIN_QUESTION_QUANTITY || e.target.value === "") {
      e.target.value = "1";
    }
  }

  useEffect(() => {
    const mockQuestionsQuantity = document.getElementById("questionsQuantity");

    mockQuestionsQuantity.addEventListener("keyup", (e) => {
      handleValidateMaxValue(e);
    });

    mockQuestionsQuantity.addEventListener("change", (e) => {
      handleValidateMaxValue(e);
      handleValidateMinValue(e);
    });
  }, []);

  return (
    <Form error={error}>
      <Grid>
        <Grid.Column width={16}>
          <TextField
            label="Nome"
            name="title"
            placeholder="Nome do Simulado"
            fluid
          />
        </Grid.Column>

        <Grid.Column computer={8} mobile={16}>
          <DropDownField
            name="specialitiesIdIn"
            label="Especialidade"
            options={makeDropDownOptions(
              specialities,
              "Todas as Especialidades"
            )}
            placeholder="Selecione uma Especialidade"
            fluid
            search
            inline
            multiple
            selection
            clearable
          />
        </Grid.Column>
        <Grid.Column computer={8} mobile={16}>
          <DropDownField
            name="subjectsIdIn"
            label="Assunto"
            options={makeDropDownOptions(subjects, "Todos os Assuntos")}
            placeholder="Selecione um Assunto"
            fluid
            search
            inline
            multiple
            selection
            clearable
          />
        </Grid.Column>
        <Grid.Column computer={8} mobile={16}>
          <DropDownField
            name="organizersStateIdIn"
            label="Estado"
            options={makeDropDownOptions(states, "Todos os Estados")}
            placeholder="Selecione um Estado"
            fluid
            search
            inline
            multiple
            selection
            clearable
          />
        </Grid.Column>
        <Grid.Column computer={8} mobile={16}>
          <DropDownField
            name="organizersIdIn"
            label="Banca"
            options={makeDropDownOptions(organizers, "Todas as Bancas")}
            placeholder="Selecione uma Banca"
            fluid
            search
            inline
            multiple
            selection
            clearable
          />
        </Grid.Column>

        <Grid.Column computer={5} mobile={16}>
          <DropDownField
            name="yearMatchesAny"
            label="Ano"
            options={makeDropDownOptions(years, "Todos os Anos")}
            placeholder="Selecione um Ano"
            fluid
            inline
            multiple
            selection
            clearable
          />
        </Grid.Column>
        <Grid.Column computer={6} mobile={16}>
          <DropDownField
            name="examTypeExamTypeCategoryIdIn"
            label="Tipo de Prova"
            options={makeDropDownOptions(
              examTypeCategories,
              "Todos os Tipos de Prova"
            )}
            disabled={!!!examTypeCategories}
            placeholder="Selecione um Tipo de Prova"
            fluid
            search
            inline
            multiple
            selection
            clearable
          />
        </Grid.Column>
        <Grid.Column computer={5} mobile={16}>
          <TextField
            id="questionsQuantity"
            name="quantity"
            type="number"
            label="Quantidade de Questões"
            placeholder="120"
            defaultValue={MAX_QUESTION_QUANTITY}
            fluid
            min={MIN_QUESTION_QUANTITY}
            max={MAX_QUESTION_QUANTITY}
          />
        </Grid.Column>

        <Grid.Column computer={8} mobile={16}>
          <CheckBoxField
            label="Apenas questões que ainda não respondi"
            name="notAnswered"
            disabled={
              onlyAnsweredWrong ||
              questionsType === "open" ||
              questionsType === "mixed"
            }
          />
        </Grid.Column>

        <Grid.Column computer={8} mobile={16}>
          <CheckBoxField
            label="Apenas questões que eu errei"
            name="onlyAnsweredWrong"
            disabled={notAnswered}
          />
        </Grid.Column>

        <Grid.Column width={8}>
          <CheckBoxField
            label="Simular Prova"
            hint="Você só poderá ver o resultado das questões após o termino do simulado."
            name="exam"
          />
        </Grid.Column>

        <Grid.Column computer={8} mobile={16}>
          <CheckBoxField
            label="Apenas questões favoritadas"
            name="favorite_by_user"
          />
        </Grid.Column>

        <Grid.Column width={16}>
          <Label htmlFor="questionsType">Tipos de Questões do Simulado</Label>
          <div style={questionsTypeWrapper}>
            <RadioField
              setQuestionsType={setQuestionsType}
              label="Apenas questões fechadas"
              name="questionsType"
              value="closed"
            />
            <RadioField
              setQuestionsType={setQuestionsType}
              label="Questões Abertas e Fechadas"
              name="questionsType"
              value="mixed"
            />
            <RadioField
              setQuestionsType={setQuestionsType}
              label="Apenas questões abertas"
              name="questionsType"
              value="open"
            />
          </div>
        </Grid.Column>
        <Grid.Column
          computer={8}
          mobile={16}
          style={{ paddingTop: spacing.xxs }}
        >
          <DropDownField
            name="difficultyLevelIn"
            label="Nível de dificuldade"
            placeholder="Selecione os níveis de dificuldade das questões"
            options={difficultyOptions}
            fluid
            inline
            selection
            multiple
          />
        </Grid.Column>
      </Grid>
      <MockModalFooter hasContent={hasContent} showCount {...props} />
    </Form>
  );
}
