import {
  ActionsContainer,
  UnfavoriteButton,
  UnfavoriteButtonMobile
} from "./styles";
import React from "react";
import { EMRButton } from "../../../componentsV2/Button";
import { FiExternalLink } from "react-icons/fi";
import { IoHeartDislike } from "react-icons/io5";
import { Tooltip } from "../../Tooltip";

export default function Actions({
  handleSelectQuestion,
  handleOpenConfirmDeleteModal
}) {
  return (
    <ActionsContainer>
      <UnfavoriteButton onClick={handleOpenConfirmDeleteModal}>
        <IoHeartDislike size={24} />
        <Tooltip>Desfavoritar</Tooltip>
      </UnfavoriteButton>
      <UnfavoriteButtonMobile>
        <p>Desfavoritar</p>
        <IoHeartDislike size={24} />
      </UnfavoriteButtonMobile>
      <EMRButton
        mode={"transparent"}
        fontSize={"md"}
        onClick={handleSelectQuestion}
      >
        Ver questão
        <FiExternalLink size={24} />
      </EMRButton>
    </ActionsContainer>
  );
}
