export async function createResidences({ chosenResidences, specialtyId, userId, statesToGraduate }) {
  const medicalResidencesToGraduate = chosenResidences.map((residence, residenceIndex) => ({
    medical_residence_id: residence.id,
    priority: residenceIndex + 1
  }))

  const graphqlQuery = {
    operationName: "UpdateUser",
    query: `mutation UpdateUser($updateUserInput: UpdateUserInput) {
              updateUser(updateUserInput: $updateUserInput) {
                id
              }
            }`,
    variables: {
      updateUserInput: {
        id: userId,
        medical_residence_to_graduate: medicalResidencesToGraduate,
        intended_specialty: {
          id: specialtyId
        },
        graduation_year: new Date().getFullYear(),
        states_to_graduate: statesToGraduate,
      }
    }
  };

  const options = {
    "method": "POST",
    "headers": {
      "content-type": "application/json",
    },
    "body": JSON.stringify(graphqlQuery)
  };

  await fetch(`${process.env.METRICOAPI_URL}graphql`, options)
}

export async function getSpecialties() {
  const graphqlQuery = {
    operationName: "IntendedSpecialties",
    query: `query IntendedSpecialties {
                intendedSpecialties {
                  id
                  name
                }
              }`,
  };

  const options = {
    "method": "POST",
    "headers": {
      "content-type": "application/json",
    },
    "body": JSON.stringify(graphqlQuery)
  };

  const response = await fetch(`${process.env.METRICOAPI_URL}graphql`, options)
  const responseJson = await response.json()
  return responseJson.data?.intendedSpecialties
}

export async function getStates() {
  const graphqlQuery = {
    operationName: "States",
    query: `query States {
              states {
                name
                id
              }
            }`,
  };

  const options = {
    "method": "POST",
    "headers": {
      "content-type": "application/json",
    },
    "body": JSON.stringify(graphqlQuery)
  };

  const response = await fetch(`${process.env.METRICOAPI_URL}graphql`, options)
  const responseJson = await response.json()
  return responseJson.data?.states
}

export async function getMedicalResidences() {
  const graphqlQuery = {
    query: `{
              medicalResidences {
                id
                name
                state {
                  id
                  name
                }
              }
            }`,
  };

  const options = {
    "method": "POST",
    "headers": {
      "content-type": "application/json",
    },
    "body": JSON.stringify(graphqlQuery)
  };

  const response = await fetch(`${process.env.METRICOAPI_URL}graphql`, options)
  const responseJson = await response.json()
  return responseJson.data?.medicalResidences
}

export async function createGoalInput(userId) {
  const graphqlQuery = {
    operationName: "CreateGoal",
    query: `mutation CreateGoal($createGoalInput: CreateGoalInput!) {
              createGoal(createGoalInput: $createGoalInput) {
                id
              }
            }`,
    variables: {
      createGoalInput: {
        user: {
          id: userId
        },
        excellent: 100,
        good: 70,
        regular: 50,
        bad: 49,
        question_goal: {
          question_goal: 80,
          period: "WEEKLY",
        },
        classes_attended: 0,
        correct_question: 0,
        study_time: 0,
      }
    },
  };

  const options = {
    "method": "POST",
    "headers": {
      "content-type": "application/json",
    },
    "body": JSON.stringify(graphqlQuery)
  };

  await fetch(`${process.env.METRICOAPI_URL}graphql`, options)
}