import React from "react";

export function Time() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.82524 21.75H17.1743C18.1024 21.75 18.8407 20.9864 18.7409 20.1066C18.0963 14.4375 14.2498 14.5312 14.2498 12C14.2498 9.46875 18.1455 9.60938 18.7404 3.89344C18.8341 3.01312 18.1024 2.25 17.1743 2.25H6.82524C5.89711 2.25 5.16727 3.01312 5.25914 3.89344C5.85399 9.60938 9.74977 9.42188 9.74977 12C9.74977 14.5781 5.90321 14.4375 5.25914 20.1066C5.15883 20.9864 5.89711 21.75 6.82524 21.75Z"
        stroke="#9BA5AB"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.0924 20.25H7.92821C7.19696 20.25 6.99071 19.4062 7.50352 18.8831C8.74477 17.625 11.2502 16.7241 11.2502 15.2812V10.5C11.2502 9.56953 9.46899 8.85938 8.36696 7.35C8.18508 7.10109 8.20336 6.75 8.66555 6.75H15.356C15.7502 6.75 15.8356 7.09828 15.656 7.34766C14.5699 8.85938 12.7502 9.56484 12.7502 10.5V15.2812C12.7502 16.7123 15.3616 17.4844 16.519 18.8845C16.9854 19.4489 16.8223 20.25 16.0924 20.25Z"
        fill="#9BA5AB"
      />
    </svg>
  );
}
