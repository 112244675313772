import React from "react";
import { Form, Message } from "semantic-ui-react";

export default function FormComponent(props) {
  const { children, error, ...rest } = props;
  return (
    <div>
      {error && <Message content={error} error />}
      <Form {...rest} error={!!error}>
        {children}
      </Form>
    </div>
  );
}
