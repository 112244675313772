import { replace } from "connected-react-router";
import { setCookie } from "cookies-next";
import { normalize, schema } from "normalizr";
import { SubmissionError } from "redux-form";
import { trackLoginEvent } from "../../services/mixpanel/events/trackLoginEvent";
import { apiDel, apiGet, apiPost, apiPut } from "./helpers/api";
import { getQueryParam } from "./helpers/getQueryParam";
import { notifySubmitSucceeded } from "./notifierProcesses";

export const user = new schema.Entity("users");

export const getSession = () => JSON.parse(localStorage.getItem("session"));
export const setSession = (response) => {
  localStorage.setItem(
    "session",
    JSON.stringify({
      headers: {
        "access-token": response.headers["access-token"],
        client: response.headers.client,
        uid: response.headers.uid
      },
      currentUserId: response.body.data.id,
      createdAt: response.body.data.createdAt,
      isFrial: response.body.data.isFrial
    })
  );

  setCookie(
    "session",
    JSON.stringify({
      headers: {
        "access-token": response.headers["access-token"],
        client: response.headers.client,
        uid: response.headers.uid
      }
    }),
    {
      domain: process.env.DOMAIN_URL
    }
  );
};

export const signIn = (values, dispatch) => {
  return apiPost("/api/v1/auth/sign_in")
    .send(values)
    .then((response) => {
      setSession(response);
      trackLoginEvent(response.body.data);
      if (dispatch) {
        dispatch({
          type: "USER_FETCHED",
          ...normalize(response.body.data, user)
        });
        dispatch({ type: "CURRENT_USER_FETCHED", id: response.body.data.id });
        dispatch(replace("/"));
      }
    })
    .catch((err) => {
      throw new SubmissionError({ _error: err.response.body.errors });
    });
};

export const getSignInAttemptFails = (dispatch) => {
  const userSignInAttemptFailsResponse = 1;
  dispatch({
    type: "SIGN_IN_ATTEMPT_FAILS",
    value: userSignInAttemptFailsResponse
  });
};

export const recoveryPassword = (values, dispatch) => {
  return apiPost("/api/v1/auth/password")
    .send({
      ...values,
      redirectUrl: `https://${window.location.host}/reset-password`
    })
    .then((response) => {
      notifySubmitSucceeded(dispatch, response.body.message);
      dispatch(replace("/signin"));
    })
    .catch((err) => {
      throw new SubmissionError({ _error: err.response.body.errors });
    });
};

export const newPassword = (values, dispatch, redirect) => {
  return apiPut("/api/v1/auth/password")
    .send(values)
    .set("Uid", getQueryParam("uid"))
    .set("Access-Token", getQueryParam("access-token"))
    .set("Client", getQueryParam("client"))
    .then((response) => {
      if (!redirect && dispatch) {
        notifySubmitSucceeded(dispatch, response.body.message);
        dispatch(replace("/signin"));
      } else {
        window.location = redirect;
      }
    })
    .catch((err) => {
      throw new SubmissionError({ _error: err.response.body.errors });
    });
};

export const signOut = (dispatch, toFrialSignup) => {
  apiDel("/api/v1/auth/sign_out").then(() => {
    dispatch({ type: "CURRENT_USER_REMOVED", id: null });
    localStorage.removeItem("session");
    dispatch(replace(toFrialSignup ? "/signup-v2" : "/signin-v2"));
  });
};

export const sessionValidate = (dispatch) => {
  return apiGet("/api/v1/auth/validate_token").then((response) => {
    dispatch({
      type: "USER_FETCHED",
      ...normalize(response.body.data, user)
    });
  });
};

export const userType = (dispatch) => {
  apiGet("/api/v1/classrooms/list_classroom_user_type").then((response) => {
    if (response.body["B2B"]) {
      dispatch({ type: "IS_B2B" });
    }
  });
};

export const isFrialUser = (dispatch, user) => {
  dispatch({
    type: "CHECK_FRIAL_FETCHED",
    is_frial: user.isFrial
  });
};
