import React from 'react';
import CodeValidationScreen from '.';
import { ACTIVE_SCREEN } from '../../enum';
import { useHistory } from 'react-router-dom';

export default function CodeValidationScreenContainer({ location }) {
  const history = useHistory();
  const credentials = {
    email: location.state.email,
    password: location.state.password,
  };

  function handleSecurityPush(activeScreen, props = {}) {
    return history.push('/signin-security', {
      activeScreen,
      ...props,
    });
  }

  function handleGoBack() {
    handleSecurityPush(ACTIVE_SCREEN.QR_CODE_VALIDATION, {
      ...credentials,
      hasEmergencyAccess: location.state.hasEmergencyAccess,
      fingerprint: location.state.fingerprint,
    });
  }

  return (
    <CodeValidationScreen location={location} handleGoBack={handleGoBack} />
  );
}
