const getUrlParams = () => {
  const url = document.location.pathname.split("/");
  const urlParams = {
    classroomId: parseInt(url[2]),
    lessonId: parseInt(url[4]),
    mockId:  parseInt(url[2]),
  };
  return urlParams;
};

export default getUrlParams;
