const getSurveys = (action) => action.entities.surveys;

export default function surveysReducer(state = {}, action) {
  switch (action.type) {
    case "SURVEYS_FETCHED":
      return { ...state, ...getSurveys(action) };
    default:
      return state;
  }
}
