import React from 'react'
import { Select } from 'semantic-ui-react'
import Page from '../library/Page'
import makeDropDownOptions from '../../utils/makeDropDownOptions'
import { statusConstants } from '../../utils/statusConstants'
import MyPerformanceGeneral from './MyPerformanceGeneral'
import MyPerformanceSpecialityContainer from './MyPerformanceSpecialityContainer'
import MyPerformanceSubjectContainer from './MyPerformanceSubjectContainer'
import { colors } from '../library/theme'

export default function MyPerformance(props) {
  const { classrooms, selectClassroomHandler, selectedClassroomId, status } =
    props

  return (
    <Page
      title="Meu Desempenho"
      panels={[
        {
          menuItem: {
            content: 'Geral',
            color: colors.semantic
          },
          pageAction: (
            <Select
              options={makeDropDownOptions(classrooms)}
              onChange={selectClassroomHandler}
              value={selectedClassroomId}
              disabled={status == statusConstants.LOADING}
              fluid
            />
          ),
          render: () => <MyPerformanceGeneral {...props} />
        },
        {
          menuItem: {
            content: 'Especialidade',
            color: colors.semantic
          },
          render: () => <MyPerformanceSpecialityContainer />
        },
        {
          menuItem: {
            content: 'Assuntos',
            color: colors.semantic
          },
          render: () => <MyPerformanceSubjectContainer />
        }
      ]}
    ></Page>
  )
}
