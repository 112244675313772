import React, { useContext } from "react";
import { IoArrowForward } from "react-icons/io5";
import { context } from "../context";
import { Illustration } from "./illustration";
import {
  Button,
  IllustrationWrapper,
  Text,
  Title,
  TitleWrapper,
} from "./styles";

export function Step1() {
  const { user, setStep, loading } = useContext(context);

  if (!user) return <></>;

  function firstName() {
    const names = user.name.split(" ");
    return names[0];
  }

  function handleClick() {
    setStep(1);
  }

  return (
    <>
      <TitleWrapper>
        <Title>Bem-vindo(a), {firstName()}!</Title>
        <Text>
          Que bom ter você conosco! Conheça nossa plataforma e melhore seus
          aprendizados com nossos recursos.
        </Text>
      </TitleWrapper>
      <IllustrationWrapper>
        <Illustration />
      </IllustrationWrapper>
      <Button loading={loading} onClick={handleClick}>
        Avançar
        <IoArrowForward size={24} />
      </Button>
    </>
  );
}
