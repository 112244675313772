import styled from 'styled-components'
import { colors } from '../../../components/library/theme'
import Illustration from './Illustration'
import {Link as LinkReact} from "react-router-dom"
export const Backdrop = styled.div`
  align-items: center;
  background: rgba(0, 0, 0, 0.75);
  display: ${props => props.visible ? 'flex' : 'none'};
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  overflow: auto;
  padding: 16px;
  z-index: 1001;
`
export const Modal = styled.div`
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  flex-direction: column; 
  max-width: 406px;
  padding: 24px;
  width: 100%;  
`
export const Link = styled(LinkReact)`
  background-color: ${colors.brand.pure};
  border: none;
  color: #fff !important;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 4px;
  text-align: center;
  width: 100%;
  margin-top: 32px;

  &:hover {
    background: ${colors.brand.dark000};
  }
`
export const Info = styled.p`
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
`
export const Image = styled(Illustration)`
  height: 350px;
  max-width: 510px;
  width: 100%;
 
  @media(max-width: 768px) {
    width: 299px;
    height: 206px;
  }
`
export const Title = styled.h1`
  font-size: 18px;
  font-weight: 600;
  line-height: 1.3;
  margin: 24px 0;
  width: 100%;
  text-align: left;
  color: #606A71;
`