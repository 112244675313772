import React, { useCallback, useState } from "react";
import { Card, Select } from "semantic-ui-react";
import { spacing, uiColors, utilityColors } from "../library/theme";
import { css } from "glamor";

const container = {
  paddingBottom: spacing.l,
  marginBottom: spacing.l,
  borderBottom: `solid 1px ${uiColors.border}`,
};
const answersClass = css({
  backgroundColor: uiColors.fieldBackground,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexWrap: "wrap",
  padding: `${spacing.s} 0`,
});
const answerHeader = css({
  display: "flex",
  justifyContent: "end",
  marginLeft: "auto",
  marginBottom: spacing.m,
  "@media(max-width: 753px)": {
    width: "100%",
  },
});
const answerItem = css({
  flex: 1,
  minWidth: 140,
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  "@media(max-width: 575px)": {
    minWidth: "100%",
  },
});
const middleAnswerItem = css(answerItem, {
  borderLeft: `solid 1px ${uiColors.border}`,
  borderRight: `solid 1px ${uiColors.border}`,
  "@media(max-width: 575px)": {
    padding: `${spacing.m} 0`,
    margin: `${spacing.m} 0`,
    border: "none",
    borderTop: `solid 1px ${uiColors.border}`,
    borderBottom: `solid 1px ${uiColors.border}`,
  },
});
const totalNumber = {
  color: "blue",
};
const rightNumber = {
  color: uiColors.background,
};
const wrongNumber = {
  color: utilityColors.danger,
};
const selectStyle = {
  width: 150,
};
const periodLabel = {
  display: "flex",
  alignItems: "center",
  marginRight: spacing.m,
  whiteSpace: "pre",
};

const periodOptions = [
  { key: "week", value: "week", text: "Semana" },
  { key: "fortnight", value: "fortnight", text: "Quinzena" },
  { key: "month", value: "month", text: "Mês" },
  { key: "year", value: "year", text: "Ano" },
];

export default function QuestionInfoCard({ mocksData }) {
  const [period, setPeriod] = useState("week");
  const setSelectedOption = useCallback((_, e) => setPeriod(e.value));

  return mocksData?.answersByPeriod ? (
    <div style={container}>
      <div className={answerHeader}>
        <div style={periodLabel}>
          <h4>Filtrar por período </h4>
        </div>
        <Select
          style={selectStyle}
          options={periodOptions}
          onChange={setSelectedOption}
          defaultValue="week"
          fluid
        />
      </div>
      <Card fluid padded>
        <Card.Content>
          <Card.Header>Questões Resolvidas - Todos os Simulados</Card.Header>
        </Card.Content>
        <Card.Content>
          <div className={answersClass}>
            <div className={answerItem}>
              <h4>Questões Respondidas</h4>
              <h1 style={totalNumber}>
                {mocksData.answersByPeriod[period].total}
              </h1>
            </div>
            <div className={middleAnswerItem}>
              <h4>Questões Certas</h4>
              <h1 style={rightNumber}>
                {mocksData.answersByPeriod[period].correct}
              </h1>
            </div>
            <div className={answerItem}>
              <h4>Questões Erradas</h4>
              <h1 style={wrongNumber}>
                {mocksData.answersByPeriod[period].incorrect}
              </h1>
            </div>
          </div>
        </Card.Content>
      </Card>
    </div>
  ) : null;
}
