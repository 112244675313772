class HighlightsStorage {
  key;

  constructor(id) {
    this.key =
      id !== undefined ? `highlight-storage-${id}` : "highlight-storage";
  }

  storeToJson() {
    const store = sessionStorage.getItem(this.key);
    let sources;
    try {
      sources = store ? JSON.parse(store) : [];
    } catch (e) {
      sources = [];
    }
    return sources;
  }

  jsonToStore(stores) {
    sessionStorage.setItem(this.key, JSON.stringify(stores));
  }

  save(data) {
    const stores = this.storeToJson();
    const map = {};
    stores.forEach((store, idx) => (map[store.highlightSource.id] = idx));

    if (!Array.isArray(data)) {
      data = [data];
    }

    data.forEach((store) => {
      if (map[store.highlightSource.id] !== undefined) {
        stores[map[store.highlightSource.id]] = store;
      } else {
        stores.push(store);
      }
    });
    this.jsonToStore(stores);
  }

  remove(id) {
    const stores = this.storeToJson();
    const index = stores.findIndex((store) => store.highlightSource.id === id);
    if (index !== -1) {
      stores.splice(index, 1);
    }
    this.jsonToStore(stores);
  }

  getAll() {
    return this.storeToJson();
  }

  getStoredQuestionHighlights(questionId) {
    return this.storeToJson().filter(
      ({ highlightSource }) =>
        highlightSource.extra.questionId === questionId
    );
  }
}

export default HighlightsStorage;
