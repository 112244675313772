import { normalize, schema } from "normalizr";
import { apiGet } from "./helpers/api";

const departmentSchema = new schema.Entity("departments");
const specialitySchema = new schema.Entity("specialities");
const stateSchema = new schema.Entity("states");
const yearSchema = new schema.Entity("years");
const examTypeCategorySchema = new schema.Entity("examTypeCategories");

export const valuesSchema = new schema.Values(
  {
    departments: new schema.Array(departmentSchema),
    specialities: new schema.Array(specialitySchema),
    states: new schema.Array(stateSchema),
    years: new schema.Array(yearSchema),
    examTypeCategories: new schema.Array(examTypeCategorySchema),
  },
  (input, parent, key) => `${key}`
);

export const fetchConfigs = (dispatch) => {
  apiGet("/api/v1/config").then((response) => {
    dispatch({
      type: "CONFIGS_FETCHED",
      ...normalize(response.body.data[0], valuesSchema),
    });
  });
};
