import React from "react";
import PropTypes from "prop-types";

export function CheckIcon({
  height = "16",
  width = "16",
  fill = "#35BD78",
  ...props
}) {
  return (
    <svg
      {...props}
      width={height}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 1.50018C4.41594 1.50018 1.5 4.41612 1.5 8.00018C1.5 11.5842 4.41594 14.5002 8 14.5002C11.5841 14.5002 14.5 11.5842 14.5 8.00018C14.5 4.41612 11.5841 1.50018 8 1.50018ZM11.3828 5.82175L7.18281 10.8217C7.13674 10.8766 7.07941 10.921 7.01471 10.9517C6.95001 10.9825 6.87945 10.999 6.80781 11.0002H6.79938C6.72929 11.0002 6.66 10.9854 6.59599 10.9569C6.53198 10.9283 6.47468 10.8867 6.42781 10.8346L4.62781 8.83456C4.5821 8.78607 4.54654 8.72894 4.52322 8.66651C4.4999 8.60409 4.4893 8.53763 4.49203 8.47105C4.49477 8.40447 4.51078 8.33911 4.53914 8.2788C4.56749 8.2185 4.60761 8.16447 4.65715 8.11989C4.70668 8.07532 4.76463 8.04109 4.82757 8.01923C4.89052 7.99737 4.95721 7.98831 5.02371 7.99259C5.09021 7.99687 5.15518 8.01439 5.21481 8.04414C5.27444 8.07389 5.32752 8.11525 5.37094 8.16581L6.78625 9.73831L10.6172 5.17862C10.7031 5.07927 10.8247 5.01773 10.9556 5.00729C11.0866 4.99686 11.2164 5.03837 11.317 5.12286C11.4175 5.20735 11.4808 5.32802 11.4931 5.4588C11.5055 5.58958 11.4658 5.71995 11.3828 5.82175Z"
        fill={fill}
      />
    </svg>
  );
};

CheckIcon.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
