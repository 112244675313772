import React from "react";
import { css } from "glamor";
import { styles } from "./theme";

const alignments = {
  left: css({ textAlign: "left" }),
  right: css({ textAlign: "right" }),
  center: css({ textAlign: "center" }),
};
const layout = css({
  "& + &": {
    marginTop: "1.5em",
  },
});

const smallText = css(styles.bodyTextSmall);
const whiteText = css(styles.whiteText);

export default function Paragraph(props) {
  const { children, align, small, white, ...rest } = props;
  const className = css(
    styles.bodyText,
    styles.wrappingText,
    layout,
    small && smallText,
    white && whiteText,
    alignments[align]
  );
  return (
    <div className={className} {...rest}>
      {children}
    </div>
  );
}
