import { apiGet, apiPost } from "./helpers/api";
import { normalize, schema } from "normalizr";
import { fetchClassroms } from "./classroomProcesses";
import { fetchLevels } from "./levelProcesses";

const lessonsUser = new schema.Entity("lessonsUsers");

export const fetchLessonsUsers = (dispatch, classroomId) => {
  apiGet(`/api/v1/classrooms/${classroomId}/lessons_users`).then((response) => {
    dispatch({
      type: "LESSONS_USERS_FETCHED",
      ...normalize(response.body.data, new schema.Array(lessonsUser)),
    });
  });
};

export const createOrUpdateLessonsUser = (
  dispatch,
  classroomId,
  lessonId,
  progress
) => {
  apiPost(`/api/v1/classrooms/${classroomId}/lessons_users`)
    .send({ classroomId, lessonId, progress })
    .then((response) => {
      if (progress > 0.8) {
        fetchClassroms(dispatch);
        fetchLevels(dispatch, classroomId);
      }
      dispatch({
        type: "LESSONS_USER_UPDATED",
        ...normalize(response.body.data, lessonsUser),
      });
    })
    .catch(
      (err) =>
        new Error(
          `Error on create or update the lesson user: ${err.response.body.errors}`
        )
    );
};
