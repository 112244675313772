import React from "react";
import { Tab, Grid, Header } from "semantic-ui-react";

export default function MockInfoTabPane({
  total = "--",
  completed = "--",
  correct = "--",
  incorrect = "--",
}) {
  return (
    <Tab.Pane>
      <Grid
        columns="equal"
        celled="internally"
        textAlign="center"
        verticalAlign="middle"
        centered
        padded
        doubling
        stackable
      >
        <Grid.Row>
          <Grid.Column>
            <Header as="h1" color="blue">
              <p>Total</p>
              {total}
            </Header>
          </Grid.Column>
          <Grid.Column>
            <Header as="h1" color="green">
              <p>Finalizados</p>
              {completed}
            </Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Header as="h1" color="green">
              <p>Respostas Certas</p>
              {correct}
            </Header>
          </Grid.Column>
          <Grid.Column>
            <Header as="h1" color="red">
              <p>Respostas Erradas</p>
              {incorrect}
            </Header>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Tab.Pane>
  );
}
