import React from "react"

export function PracticeIcon() {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Icons">
        <path id="Subtract" fillRule="evenodd" clipRule="evenodd" d="M11.5482 1.64446H11.8939C12.4083 1.64446 12.9016 1.8505 13.2653 2.21723C13.629 2.58397 13.8333 3.08137 13.8333 3.60002V13.3778C13.8333 13.8964 13.629 14.3938 13.2653 14.7606C12.9016 15.1273 12.4083 15.3334 11.8939 15.3334H5.10607C4.59171 15.3334 4.09841 15.1273 3.73471 14.7606C3.371 14.3938 3.16667 13.8964 3.16667 13.3778V3.60002C3.16667 3.08137 3.371 2.58397 3.73471 2.21723C4.09841 1.8505 4.59171 1.64446 5.10607 1.64446H5.45182C5.50052 1.64442 5.54807 1.62958 5.58828 1.6019C5.62849 1.57421 5.65951 1.53495 5.67728 1.48924C5.77043 1.2472 5.93391 1.03917 6.1463 0.892394C6.35869 0.745621 6.6101 0.666951 6.86758 0.666687H10.1324C10.3899 0.666951 10.6413 0.745621 10.8537 0.892394C11.0661 1.03917 11.2296 1.2472 11.3227 1.48924C11.3405 1.53495 11.3715 1.57421 11.4117 1.6019C11.4519 1.62958 11.4995 1.64442 11.5482 1.64446ZM6.56455 3.60002H10.4355C10.564 3.60002 10.6874 3.54851 10.7783 3.45683C10.8692 3.36514 10.9203 3.24079 10.9203 3.11113C10.9203 2.98147 10.8692 2.85712 10.7783 2.76543C10.6874 2.67375 10.564 2.62224 10.4355 2.62224H6.56455C6.43596 2.62224 6.31264 2.67375 6.22171 2.76543C6.13078 2.85712 6.0797 2.98147 6.0797 3.11113C6.0797 3.24079 6.13078 3.36514 6.22171 3.45683C6.31264 3.54851 6.43596 3.60002 6.56455 3.60002ZM11.3554 7.35105C11.6227 7.03465 11.583 6.56145 11.2666 6.29411C10.9502 6.02678 10.477 6.06656 10.2096 6.38295L7.68256 9.37379L6.94656 8.6116C6.65883 8.31363 6.18403 8.30533 5.88607 8.59306C5.5881 8.88079 5.5798 9.35559 5.86753 9.65356L7.18016 11.0129C7.32751 11.1655 7.53257 11.2486 7.74457 11.2415C7.95657 11.2345 8.15567 11.138 8.29257 10.976L11.3554 7.35105Z" fill="white" />
      </g>
    </svg>
  );
}
