import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  display: flex;
  gap: 12px;
`;
export const Progress = styled.div`
  height: 32px;
  width: 32px;
`;
export const ProgressText = styled.p`
  font-weight: 500;
`;
