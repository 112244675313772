import React, { useCallback } from "react";
import { connect } from "react-redux";
import LessonContent from "./LessonContent";
import { getMockFromMockTemplate } from "../../selectors/mockSelectors";
import { createOrUpdateLessonsUser } from "../../processes/lessonUsersProcesses";
import {
  makeIsWatched,
  getLessonsUser,
} from "../../selectors/lessonsUserSelectors";

function LessonContentContainer(props) {
  const { dispatch, lesson, currentClassroomId, isWatched } = props;

  const onProgressHandler = useCallback(
    ({ played }) =>
      createOrUpdateLessonsUser(
        dispatch,
        currentClassroomId,
        lesson.id,
        played
      ),
    [dispatch, lesson, currentClassroomId]
  );

  const onEndedHandler = useCallback(
    () => createOrUpdateLessonsUser(dispatch, currentClassroomId, lesson.id, 1),
    [dispatch, lesson, currentClassroomId]
  );

  return (
    <LessonContent
      onProgressHandler={onProgressHandler}
      onEndedHandler={onEndedHandler}
      {...props}
    />
  );
}

function mapStateToProps(state, ownProps) {
  const lesson = ownProps.lesson;

  return {
    mock: getMockFromMockTemplate(state, lesson.mockTemplateId),
    currentClassroomId: state.currentClassroomId,
    isWatched: makeIsWatched(state, { lessonId: lesson?.id }),
    progress: getLessonsUser(state, { lessonId: lesson?.id })?.progress,
  };
}

export default connect(mapStateToProps)(LessonContentContainer);
