import React from "react";
import { connect } from "react-redux";
import { getQuestionsFromCurrentQuestion } from "../../selectors/questionSelectors";
import QuestionsList from "./QuestionsList";

function mapStateToProps(state) {
  return {
    questions: getQuestionsFromCurrentQuestion(state),
  };
}

export default connect(mapStateToProps)(QuestionsList);
