import styled from "styled-components";
import { colors } from "../../library/theme";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  .divider {
    display: ${(props) => (props.isLastAlternative ? "none" : "block")};
    margin-top: 8px;
  }

  @media (max-width: 768px) {
    gap: 16px;
  }
`;
export const AnnotationNumberContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  span {
    font-weight: 400;
    font-size: 12px;
    line-height: 1.5;
    color: ${colors.interface.grey200};
  }
`;
export const AnnotationNumber = styled.h4`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.3;
  color: ${colors.interface.grey400};
  background-color: ${colors.interface.grey000};
  border-radius: 50%;
  border: 1px solid ${colors.interface.grey100};
  height: 32px;
  width: 32px;
  margin: 0;
`;
export const AnnotationTextArea = styled.textarea`
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  color: ${colors.interface.grey400};
  resize: none;
  border-radius: 4px;
  border: 1px solid ${colors.interface.grey200};
  padding: 16px;

  :focus-within,
  :focus {
    outline: none;
    border: 1px solid ${colors.brand.light200};
  }

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;
export const TextAreaControls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    color: ${colors.interface.grey200};
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  @media (max-width: 768px) {
    align-items: flex-start;
    flex-direction: column;
    gap: 16px;
  }
`;
export const ButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  margin-top: 8px;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    width: 100%;
    margin: 0;

    button {
      width: 100%;
      height: 48px;
    }
  }
`;
export const AnnotationText = styled.p`
  word-wrap: break-word;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.interface.grey400};
  margin-bottom: 8px;

  p {
    font-size: 16px;
  }

  @media (max-width: 1024px) {
    font-size: 14px;
  }
  @media (max-width: 640px) {
    margin: 0px;
  }
`;
