import React, { useCallback, useEffect, useRef, useState } from "react";
import { HighlighterMenu } from "./HighlighterMenu";
import MarkerIcon from "./MarkerIcon";
import { HighlighterButton, HighlighterTooltipContainer, HighlighterWrapper } from "./styles";
import { Tooltip } from "../../Tooltip";
import { connect } from "react-redux";

/**
 * Component for highlighting text with various options and a menu for controlling highlighter settings.
 *
 * @param {Object} props - The props for the component.
 * @param {boolean} props.isHighlighterActive - Whether the highlighter is active.
 * @param {"yellow" | "red" | "green" | "white"} props.highlightColor - The current color of the highlighter.
 * @param {boolean} props.hasStoredHighlights - Whether there are stored highlights.
 * @param {function} props.dispatch - The dispatch function for Redux actions.
 * @returns {JSX.Element} The rendered TextHighlighter component.
 */
function TextHighlighter({
  isHighlighterActive,
  highlightColor,
  hasStoredHighlights,
  dispatch,
}) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const wrapperRef = useRef(null);


  /**
   * Handle switching the highlighter color.
   *
   * @param {"yellow" | "red" | "green" | "white"} color - The new color to set for the highlighter.
   */
  function handleSwitchColor(color) {
    dispatch({
      type: "MOCK_QUESTION_CHANGE_HIGHLIGHTER_COLOR",
      highlightColor: color,
    });
  }

  function handleCloseMenu() {
    setIsMenuOpen(false);
  }

  function toggleMenu() {
    setIsMenuOpen((prev) => !prev);
  }

  function handleToggleHighlighter() {
    dispatch({
      type: "MOCK_QUESTION_TOGGLE_HIGHLIGHTER",
      isHighlighterActive: !isHighlighterActive,
    });
  }

  function handleClearTextHighlights() {
    dispatch({
      type: "MOCK_QUESTION_CLEAR_HIGHLIGHTS",
      clearHighlightsTriggered: true,
    });
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target)
      ) {
        handleCloseMenu();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <HighlighterWrapper ref={wrapperRef}>
      <HighlighterTooltipContainer data-open={isMenuOpen}>
        <HighlighterButton
          type="button"
          data-active={isHighlighterActive}
          onClick={toggleMenu}
        >
          <MarkerIcon />
        </HighlighterButton>

        <Tooltip>
          Use a ferramenta de marcar textos no enunciado e nas alternativas das
          questões. Ao finalizar o simulado as marcações feitas irão sumir.
        </Tooltip>
      </HighlighterTooltipContainer>

      <HighlighterMenu
        isMenuOpen={isMenuOpen}
        onCloseMenu={handleCloseMenu}
        isHighlighterActive={isHighlighterActive}
        selectedHighlightColor={highlightColor}
        onToggleHighlighter={handleToggleHighlighter}
        onSelectColor={handleSwitchColor}
        onClearTextHighlights={handleClearTextHighlights}
        hasStoredHighlights={hasStoredHighlights}
      />
    </HighlighterWrapper>
  );
}

/**
 * Map the Redux state to the props for the TextHighlighter component.
 *
 * @returns {{ 
 *  isHighlighterActive: boolean,
 *  highlightColor: string,
 *  hasStoredHighlights: boolean
 *  }}
 */
function mapStateToProps(state) {
  const isHighlighterActive = state.mock.isHighlighterActive;
  const highlightColor = state.mock.highlightColor;
  const hasStoredHighlights = state.mock.hasStoredHighlights;

  return {
    isHighlighterActive,
    highlightColor,
    hasStoredHighlights,
  };
}

export default connect(mapStateToProps)(TextHighlighter);
