import { apiGet } from "./helpers/api";
import { normalize, schema } from "normalizr";
import toQueryString from "../utils/toQueryString";

const organizer = new schema.Entity("organizers");

export const fetchOrganizers = (dispatch, stateIdIn) => {
  const filter = toQueryString({ stateIdIn });
  apiGet(`/api/v1/organizers?${filter}`).then((response) => {
    dispatch({
      type: "ORGANIZERS_FETCHED",
      ...normalize(response.body.data, new schema.Array(organizer)),
    });
  });
};
