import styled from 'styled-components'
import { SearchIcon } from './SearchIcon'

export const Container = styled.div`
  position: relative;
  width: 100%;
  background-color: #fff;
  background-color: #fff;
  border-radius:4px;

  .autoCompleteTags_icon {
    path {
      fill: ${(props) => (props.isTagListVisible ? '#27EA8F' : '#C2CED6')};
    }
  }
`
export const TagList = styled.ul`
  background-color: #fff;
  border-radius: 4px;
  border: solid 1px #27EA8F;
  display: ${(props) => (props.isTagListVisible ? 'flex' : 'none')};
  flex-wrap: wrap;
  gap: 8px;
  list-style: none;
  max-height: 200px;
  overflow-y: auto;
  padding: 10px;
  position: absolute;
  top: calc(100% + 4px);
  width: 100%;
  z-index: 2;
  margin: 0;

  li:hover {
    background-color: #EFFFF7;
  }

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #C2CED6;
    border: 2px solid #EDF0F2;
    border-radius: 20px;
    height: 76px;
  }

  ::-webkit-scrollbar-track {
    background: #EDF0F2;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
  }

  ::-webkit-scrollbar:horizontal {
    height: 8px;
  }

  .empty-results-item {
    cursor: not-allowed;
  }
`
export const EmptyResultsText = styled.span`
  width: 100%;
  color: #606A71;
  font-size: 14px;
  line-height: 32px;
  padding-left: 6px;
  cursor: not-allowed;
`
export const FilteredTag = styled.li`
  user-select: none;
  align-items: center;
  border: 1px solid #C2CED6;
  border-radius: 39px;
  color: #363E46;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  gap: 4px;
  height: 40px;
  line-height: 40px;
  padding: 16px;

  span {
    display: block;
    max-width: calc(100vw - 120px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  svg {
    position: unset;
  }
`
export const InputContainer = styled.div`
  display: flex;
  border: 1px solid;
  border-color: ${(props) => (props.isTagListVisible ? '#27EA8F' : '#C2CED6')};
  border-color: ${(props) => props.hasValidationError && '#E64444'};
  border-radius: 4px;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
  padding: 11px 40px 11px 11px;
  min-height: 48px;

  input {
    flex: 1;
    min-width: ${({ placeholderCount }) => `${placeholderCount * 8}px`};
    border: none;
    font-size: 14px;
    padding-left: 5px;

    ::placeholder {
      color: #C2CED6;
    }

    &:focus-visible {
      outline: none;
    }
  }
`
export const SelectedTag = styled.div`
  align-items: center;
  background-color: #D3FCE9;
  border: none;
  border-radius: 39px;
  display: flex;
  gap: 8px;
  height: 24px;
  padding: ${({ showNumberingPosition }) => (showNumberingPosition ? '10px 10px 10px 2px' : '10px')};
  font-size: 14px;
  color: #606A71;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: calc(100vw - 135px);

  small {
    display: ${({ showNumberingPosition }) => (showNumberingPosition ? 'block' : 'none')};
    width: 20px;
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    text-align: center;
    color: #fff;
    flex-shrink: 0;
    font-weight: 600;
    background-color: #35BD78;
    border-radius: 50%;
  }

  span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  svg {
    position: unset;
    flex-shrink: 0;
  }

  @media only screen and (min-width: 673px) {
    max-width: ${({ variant }) => (variant === 'complementary' ? '100%' : '530px')};
  }
`

export const StyledSearchIcon = styled(SearchIcon)`
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 16px;
`

export const ErrorText = styled.p`
  font-size: 12px !important;
  color: #E64444 !important;
`

export const ResidencesDescription = styled.div`
  margin-top: 4px;

  small {
    font-size: 12px;
    color: #9BA5AB;
  }

  & > div {
    display: flex;
    gap: 8px;
    border: 1px solid #EDF0F2;
    border-radius:4px;
    background-color: #F7FAFC;
    padding: 16px;
    margin-top: 16px;

    div {
      width: 24px;
      height: 24px;
      flex-shrink: 0;

      svg {
        margin: auto;
      }
    }

    p {
      font-size: 14px;
      color: #606A71;
    }
  }
`
