import React from 'react';
import * as S from './styles';
import blockedContent from '../../../../../../assets/images/blockedContent.svg';

export function FilterNotFound() {
  return (
    <S.Container>
      <img src={blockedContent} alt="Ilustração" />
      <div>
        <S.Title>
          Ops! Esse padrão de filtragem não possui nenhuma tarefa disponível
        </S.Title>
        <S.SubTitle>Configure novamente ou limpe o filtro</S.SubTitle>
      </div>
    </S.Container>
  );
}
