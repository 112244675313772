import React from "react";
import { LampIcon } from "./assets/LampIcon";
import { Container, LampIconContainer, Text, TextBox, Title } from "./styles";

/**
 *
 * @param {{title: string, text: string}} props
 */
export function EMRTip({ title = "Dica EMR", text }) {
  return (
    <Container>
      <LampIconContainer>
        <LampIcon />
        <Title>{title}</Title>
      </LampIconContainer>
      <TextBox>
        <Text>{text}</Text>
      </TextBox>
    </Container>
  );
}
