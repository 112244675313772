import { mixpanel } from "..";
// @ts-check
/**
 * @param {{
 * questionId: number,
 * mockTemplateName: string,
 * isFrialUser
 * }} questionData
 */

export function trackMarkQuestionAsDoubtEvent(questionData) {
  mixpanel.track("Plataforma - Marcar como dúvida", {
    MockTemplateName: questionData.mockTemplateName,
    QuestionId: questionData.questionId,
    UserFrial: questionData.isFrialUser
  });
}
