import React from "react";
import { css } from "glamor";
import { Progress } from "semantic-ui-react";
import { spacing, uiColors } from "../library/theme";

const container = css({
  padding: spacing.s,
  paddingBottom: 0,
  borderTop: `1px solid ${uiColors.border}`,
});
const wrapper = css({
  display: "flex",
  justifyContent: "space-between",
  "> p": {
    marginBottom: spacing.xs,
  },
});

export default function ClassroomProgress({
  value,
  lessonsCount = 0,
  lessonsWatchedCount = 0,
}) {
  const progress = value * 100;
  return (
    <div className={container}>
      <div className={wrapper}>
        <p>
          <small>{`${progress.toFixed(0)}%`}</small>
        </p>
        <p>
          <small>{`${lessonsWatchedCount} / ${lessonsCount} Aulas`}</small>
        </p>
      </div>
      <Progress percent={progress} color="green" size="tiny" />
    </div>
  );
}
