import React from "react";
import { Tab, Menu } from "semantic-ui-react";
import { css } from "glamor";
import MockInfoTabPane from "./MockInfoTabPane";

const menuItemClass = css({
  fontSize: 16,
  maxHeight: 44,
});

export default function MocksInfo(props) {
  const { mocksData } = props;

  const panes = [
    {
      menuItem: (
        <Menu.Item className={menuItemClass} key="emr">
          Simulados Gerais
        </Menu.Item>
      ),
      render: () => (
        <MockInfoTabPane
          total={mocksData?.highlightedTemplatesCount}
          completed={mocksData?.finishedHighlightedMocksCount}
          correct={mocksData?.userCorrectAnswersCount}
          incorrect={mocksData?.userIncorrectAnswersCount}
        />
      ),
    },
    {
      menuItem: (
        <Menu.Item className={menuItemClass} key="user">
          Meus Simulados
        </Menu.Item>
      ),
      render: () => (
        <MockInfoTabPane
          total={mocksData?.userCustomMocksCount}
          completed={mocksData?.userFinishedCustomMocksCount}
          correct={mocksData?.userCustomMocksCorrectAnswersCount}
          incorrect={mocksData?.userCustomMocksIncorrectAnswersCount}
        />
      ),
    },
  ];

  return <Tab panes={panes} />;
}
