const initialState = false;

export default function isExpiredUserReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_EXPIRATE_USER":
      return true;
    default:
      return state;
  }
}
