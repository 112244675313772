import styled from 'styled-components';
import { colors } from '../../components/library/theme';

export const Wrapper = styled.div`
  height: 32px;
  margin-left: auto;
  position: relative;
  width: 32px;
`;
export const MoreButton = styled.button`
  align-items: center;
  background-color: transparent;
  border: 0;
  border-radius: 32px;
  cursor: pointer;
  display: flex;
  height: 32px;
  justify-content: center;
  width: 32px;

  &:hover {
    background-color: ${colors.interface.grey100};
  }

  svg {
    min-width: 24px;
  }
`;
export const Container = styled.div`
  background-color: ${colors.interface.white000};
  border: solid 1px ${colors.interface.grey100};
  border-radius: 4px;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
    0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  display: none;
  flex-direction: column;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 32px;
  width: 193px;
  z-index: 99999;

  hr {
    width: 100%;
    border: 1px solid ${colors.interface.grey100};
    border-bottom: 0;
    margin: 0;
  }

  ${({ handlePin }) =>
    handlePin &&
    `
    top: 100%;
    right: 0;
  `}
`;
export const Button = styled.button`
  display: flex;
  gap: 8px;
  align-items: center;
  background-color: transparent;
  border: 0;
  color: ${({ handlePin }) =>
    handlePin ? colors.interface.grey500 : colors.interface.grey300};
  cursor: pointer;
  padding: 0px 16px;
  height: 50px;
  font-size: 14px;
  font-weight: 400;
  width: 196px;
  position: relative;

  &:first-child::after {
    background-color: ${colors.interface.grey100};
    bottom: 0;
    content: '';
    height: 1px;
    position: absolute;
    width: 145px;
    ${({ handlePin }) => handlePin && 'display: none'}
  }

  &:hover {
    background-color: ${colors.brand.light100};
    color: ${({ handlePin }) =>
      handlePin ? colors.interface.grey400 : colors.interface.grey600};
  }

  &:hover svg {
    fill: ${colors.interface.grey400};
  }
`;
