import { connect } from "react-redux";
import { formValueSelector, reduxForm } from "redux-form";
import { createMock } from "../../processes/mockProccess";
import { fetchMockTemplatesExam } from "../../processes/mockTemplateProccesses";
import { getConfig } from "../../selectors/configSelectors";
import {
  getMockTemplatesExam,
  getMockTemplatesExamYears,
} from "../../selectors/mockTemplateSelectors";
import { getOrganizersWithMockTemplates } from "../../selectors/organizerSelectors";
import debounce from "../../utils/debounce";
import MockModalExamForm from "./MockModalExamForm";

const formName = "mockExamForm";

function mapStateToProps(state) {
  const selector = formValueSelector(formName);
  const { stateIdEq, organizerIdEq, yearEq } = selector(
    state,
    "stateIdEq",
    "organizerIdEq",
    "yearEq"
  );

  return {
    initialValues: {},
    states: getConfig(state, "states"),
    years: getMockTemplatesExamYears(state),
    organizers: getOrganizersWithMockTemplates(state, [stateIdEq]),
    stateIdEq,
    organizerIdEq,
    yearEq,
    mockTemplates: getMockTemplatesExam(state, yearEq),
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: formName,
    onSubmit: createMock,
    onChange: debounce((values, dispatch) => {
      const { organizerIdEq } = values;
      if (organizerIdEq) {
        fetchMockTemplatesExam({ organizerIdEq }, dispatch);
      }
    }, 500),
  })(MockModalExamForm)
);
