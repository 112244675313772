import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: #606a71;
  gap: 32px;
  margin: auto;
  padding: 24px;
  max-width: 480px;

  h1 {
    font-size: 16px;
    color: #606a71;
    font-weight: 600;
  }

  svg {
    margin: auto;
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
