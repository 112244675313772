import styled from "styled-components";
import { colors } from "../../components/library/theme";

const mockVariants = {
  mock: `
    td:nth-child(4) {
      display: block;
      width: 15rem;
      order: -2;
      margin: auto;
    }
    td:nth-child(3) {
      order: -1;
      font-weight: 500;
      font-size: 16px;
    }
  `,
  mockTemplate: `
    td:nth-child(3) {
      display: block;
      width: 15rem;
      order: -2;
      margin: auto;
    }
    td:nth-child(2) {
      order: -1;
      font-weight: 500;
      font-size: 16px;
    }
  `,
};

export const TableWrapper = styled.div`
  border-radius: 4px;
  border: 1px solid ${colors.interface.grey200};
  margin-top: ${({ variant }) => (variant === "mock" ? "0" : "20px")};
  margin-bottom: 14px;
  overflow: visible;

  @media (max-width: 768px) {
    border: none;
  }
`;

export const StyledTable = styled.table`
  width: 100%;
  background: ${colors.interface.white000};
  overflow: hidden;
  border-collapse: collapse;
  text-align: left;
  font-size: 14px;
  border-radius: 4px;
  overflow: visible;

  thead {
    border-bottom: 1px solid ${colors.interface.grey200};
    height: 56px;

    th {
      white-space: nowrap;
      font-weight: 500;
      font-size: 14;
      color: ${colors.interface.grey600};
      padding: 0 12px;
    }
  }

  tbody {
    td {
      padding: 12px 12px;
      text-align: left;
      border-bottom: 1px solid ${colors.interface.grey200};
      color: ${colors.interface.grey400};

      span {
        display: none;
      }
    }

    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }

  @media (max-width: 1290px) {
    thead th {
      white-space: normal;
    }
  }

  @media (max-width: 1248px) {
    thead th {
      padding: 8px;
    }

    tbody td {
      padding: 8px;
    }
  }

  @media (max-width: 768px) {
    display: block;
    width: 100%;
    background-color: transparent;
    padding: 0;
    border: 0;

    thead {
      display: none;
    }

    tbody {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 12px;
      padding: 0;
      border: 0;
      margin-top: ${({ variant }) => (variant === "mock" ? "0" : "20px")};

      tr {
        display: flex;
        flex-direction: column;
        text-align: left;
        align-items: flex-start;
        border-bottom: none;
        width: 100%;
        background: ${colors.interface.white000};
        padding: 14px 16px;
        border-radius: 4px;
        border: 1px solid ${colors.interface.grey200};
      }

      td {
        display: flex;
        border: none;
        gap: 8px;

        span {
          display: block;
          font-weight: 500;
        }
      }

      td:last-child {
        display: block;
        width: 100%;
      }

      ${({ variant }) => mockVariants[variant]}
    }
  }
`;
