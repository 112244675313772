import { css } from "glamor";
import React from "react";
import { Icon, Message } from "semantic-ui-react";
import { spacing } from "../library/theme";

const layout = css({
  position: "fixed !important",
  width: "320px !important",
  margin: "0 auto !important",
  top: `90px !important`,
  right: `${spacing.m} !important`,
  zIndex: 100,
});

const notifierColorType = (type) => {
  switch (type) {
    case "success":
      return "green";
    case "warning":
      return "yellow";
    case "error":
      return "red";
    default:
      return "grey";
  }
};

export default function Notifier({
  visible,
  title,
  message,
  icon,
  type,
  onClickHandler,
}) {
  return (
    <Message
      color={notifierColorType(type)}
      onDismiss={onClickHandler}
      hidden={!visible}
      className={layout.toString()}
      compact
      icon
    >
      {icon && <Icon name={icon} />}
      <Message.Content>
        {title && <Message.Header>{title}</Message.Header>}
        {message}
      </Message.Content>
    </Message>
  );
}
