import styled from "styled-components";

export const QuestionAnswer = styled.span`
  color: #363e46;
`;

export const IconWrapper = styled.span`
  margin-top: 4px;
  margin-right: 0.5rem;

  svg {
    margin-right: 0.5rem;
  }
`;

export const Alternative = styled.div`
  display: flex;
  margin-top: 16px;
`;

export const AlternativeText = styled.span`
  size: 14px;
  line-height: 1.5;
`;

export const AlternativesWrapper = styled.div`
  margin-bottom: 16px;
  ${Enunciation} {
    margin: 16px 0;
  }
`;

export const Enunciation = styled.p`
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
`;
