const initialState = {
  ids: [],
  loading: false,
  pagination: { currentPage: 1, totalPages: 1, totalCount: undefined },
};

const getFetchedIds = (action) =>
  action.result ? action.result : initialState.ids;

export default function mocksListReducer(state = initialState, action) {
  switch (action.type) {
    case "MOCKS_LIST_FETCH_REQUESTED":
      return { ...state, loading: true };
    case "MOCKS_LIST_FETCHED":
      return {
        loading: false,
        ids: getFetchedIds(action),
        pagination: {
          currentPage:
            action?.currentPage || initialState.pagination.currentPage,
          totalPages: action?.totalPages || initialState.pagination.totalPages,
          totalCount: action?.totalCount || initialState.pagination.totalCount,
        },
      };
    default:
      return state;
  }
}
