import styled, { css } from 'styled-components';
import { colors } from '../../../library/theme';

export const OpenAlternativeContainer = styled.li(
  ({ hasAlternatives }) => css`
    border: 1px solid ${colors.interface.grey100};
    border-radius: 4px;
    list-style: none;
    width: 100%;
    margin-top: -16px;
    position: ${hasAlternatives ? 'relative' : 'initial'};

    + li {
      margin-top: 20px;
    }
  `
);

export const Status = styled.span(
  ({ isCorrect, hasAlternatives }) => css`
    min-width: ${isCorrect ? '66px' : '62px'};
    padding: 0 8px;
    height: 38px;
    display: grid;
    place-content: center;
    color: #ffffff;
    border-radius: 4px;
    background-color: ${isCorrect
      ? colors.status.green200
      : colors.status.red300};

    ${!hasAlternatives &&
    `
      position: absolute; 
      top: -18px; 
      right: 16px; 
      `}
  `
);

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 16px;
  gap: 24px;
  border-bottom: 1px solid ${colors.interface.grey100};
`;

export const AlternativeTitle = styled.span`
  flex: 1;
  font-size: 16px;
  color: ${colors.interface.grey400};
  position: relative;
  margin-left: 24px;

  ::before {
    content: counter(list, upper-alpha) ') ';
    counter-increment: list;
    position: absolute;
    top: 0;
    left: -24px;
    color: ${colors.interface.grey400};
    font-weight: 600;
    font-size: 16px;
  }
`;

export const ContentContainer = styled.div`
  padding: 16px;
`;

export const UserAnswerContainer = styled.div``;

export const RightAnswerContainer = styled.div`
  background-color: ${colors.interface.grey100};
  border-radius: 4px;
  padding: 16px;
  margin-top: 16px;
`;

export const AnswerTitle = styled.span`
  display: block;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.3;
  margin-bottom: 8px;
`;

export const AnswerDescription = styled.p`
  font-size: 16px;
  line-height: 1.5;
`;
