import styled from "styled-components";
import { colors } from "../../../components/library/theme";

export const Container = styled.div`
  width: 100%;
`;
export const Title = styled.h2`
  margin: 0;
  font-size: 16px;
  color: ${colors.interface.grey400};
  text-align: center;
  font-weight: 400;
  margin: 24px 0;
`;

const threeCardsStyle = `
  display: grid;
  grid-template-columns: 1fr 305px 1fr;
`;

const twoCardsOrLess = `
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
`;

export const Wrapper = styled.div`
  ${(props) => (props.length === 3 ? threeCardsStyle : twoCardsOrLess)}
  gap: 16px;
  align-items: flex-end;
  width: 100%;

  @media (max-width: 991px) {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
  }
`;
export const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  min-width: 360px;
`;
