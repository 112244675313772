import { createSelector } from "reselect";

const getStatistics = (state) => state.entities.statistics;
const getProps = (_, props) => props;

const getAllStatistics = createSelector(getStatistics, (statistics) =>
  Object.values(statistics)
);

export const getStatistic = createSelector(
  getAllStatistics,
  getProps,
  (statistics, { type, id, key }) =>
    statistics.find(
      (statistic) =>
        statistic.statisticableType == type &&
        statistic.statisticableId == id &&
        statistic.key == key
    )
);
