const getAnnotations = (action) => action.entities.annotations;

export default function annotationsReducer(state = {}, action) {
  switch (action.type) {
    case "ANNOTATION_CREATED":
    case "ANNOTATIONS_FETCHED":
      return { ...state, ...getAnnotations(action) };
    case "ANNOTATION_DELETED":
      if (state[action.id]) {
        let newState = { ...state };
        delete newState[action.id];
        return newState;
      }
    default:
      return state;
  }
}
