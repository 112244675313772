import React from 'react';
import { UpToDateIcon } from '../../icons';
import * as S from './styles';

export function UpToDate() {
  return (
    <S.Wrapper>
      <UpToDateIcon />
      <strong>Você está atualizado(a)!</strong>
    </S.Wrapper>
  );
}
