import { getCookie } from "cookies-next";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { apiGet } from "../../../../../../processes/helpers/api";
import { EMRButton } from "../../../../../Button";
import android from "../../../../assets/android.svg";
import web from "../../../../assets/computer.svg";
import confirmedandroid from "../../../../assets/confirmedandroid.svg";
import confirmedios from "../../../../assets/confirmedios.svg";
import confirmedweb from "../../../../assets/confirmedweb.svg";
import greenellipse from "../../../../assets/ellipsegreen.svg";
import ios from "../../../../assets/ios.svg";
import redellipse from "../../../../assets/redellipse.svg";
import warning from "../../../../assets/warning.svg";
import { ACTIVE_SCREEN } from "../../../../enum";
import { ButtonContainer, ReturnButton } from "../../../../styles";
import { generateFingerprint } from "../../../../utils/generateFingerprint";
import {
  ContentContainer,
  Description,
  DeviceContainer,
  DeviceInfoContainer,
  DeviceTitleContainer,
  WarningContainer
} from "./styles";

const platformImages = {
  WEB: {
    confirmed: confirmedweb,
    unconfirmed: web
  },
  ANDROID: {
    confirmed: confirmedandroid,
    unconfirmed: android
  },
  IOS: {
    confirmed: confirmedios,
    unconfirmed: ios
  }
};

export default function RegisterNewDeviceStep({ advanceStep, credentials }) {
  const history = useHistory();
  const email = getCookie("email");
  const [currentDevice, setCurrentDevice] = useState({
    userAgent: "",
    location: ""
  });
  const [isLoading, setIsLoading] = useState(false);
  const [fingerprints, setFingerprints] = useState([]);
  const fingerprint = generateFingerprint();

  function checkPlatformImage(platform, confirmed = false) {
    const toLowerCasePlatform = platform.toLowerCase();

    if (
      toLowerCasePlatform.includes("iphone") ||
      toLowerCasePlatform.includes("ipad")
    ) {
      return confirmed
        ? platformImages.IOS.confirmed
        : platformImages.IOS.unconfirmed;
    }
    if (
      toLowerCasePlatform.includes("windows") ||
      toLowerCasePlatform.includes("linux") ||
      toLowerCasePlatform.includes("mac")
    ) {
      return confirmed
        ? platformImages.WEB.confirmed
        : platformImages.WEB.unconfirmed;
    } else {
      return confirmed
        ? platformImages.ANDROID.confirmed
        : platformImages.ANDROID.unconfirmed;
    }
  }
  const parseUserAgent = (userAgent) => {
    let operatingSystem = "Unknown";

    if (userAgent.match(/Windows/i)) {
      operatingSystem = "Windows";
    } else if (userAgent.match(/Android/i)) {
      operatingSystem = "Android";
    } else if (userAgent.match(/iPad|iPhone|iPod/i)) {
      operatingSystem = "iOS";
    } else if (userAgent.match(/Mac/i)) {
      operatingSystem = "Mac OS";
    } else if (userAgent.match(/Linux/i)) {
      operatingSystem = "Linux";
    }

    return operatingSystem;
  };
  async function getFingerprints() {
    const response = await apiGet(`${process.env.USER_PROXY_URL}/devices`)
      .set("api-token", process.env.USER_PROXY_API_TOKEN)
      .query(`email=${email}`);
    setFingerprints(response.body);
  }

  async function handleRegister() {
    setIsLoading(true);
    advanceStep({ email, fingerprint });
  }

  function handleToAlternativeAccess() {
    history.replace("/signin-security", {
      activeScreen: ACTIVE_SCREEN.ALTERNATIVE_ACCESS,
      fingerprint: fingerprint,
      credentials: credentials
    });
  }

  useEffect(() => {
    getFingerprints();
    const userAgent = navigator.userAgent;
    const language = navigator.language;
    setCurrentDevice({ userAgent, language });
  }, []);

  return (
    <ContentContainer>
      <Description>
        <h1>Cadastre o dispositivo atual</h1>
        <h2>
          Para continuar usando a plataforma, solicitamos que você cadastre o
          dispositivo utilizado atualmente. Dessa forma, você garante maior
          segurança na sua conta Eu Médico Residente e pode seguir estudando com
          tranquilidade.
        </h2>
      </Description>
      <DeviceContainer>
        <img src={checkPlatformImage(currentDevice?.userAgent)} />
        <DeviceInfoContainer>
          <DeviceTitleContainer>
            <span>{parseUserAgent(currentDevice?.userAgent)}</span>
            <img src={greenellipse} />
          </DeviceTitleContainer>
          <h1>Local não identificado</h1>
          <h2>Conectado agora</h2>
        </DeviceInfoContainer>
      </DeviceContainer>
      {fingerprints?.map((fingerprint) => {
        const daysSinceLastSignIn = fingerprint?.daysSinceLastSignIn;
        return (
          <DeviceContainer>
            <img src={checkPlatformImage(fingerprint?.os, true)} />
            <DeviceInfoContainer>
              <DeviceTitleContainer>
                <span>{fingerprint?.os}</span>
                <img src={redellipse} />
              </DeviceTitleContainer>
              <h1>Local não identificado</h1>
              <h2>
                Conectado há {daysSinceLastSignIn} dia
                {daysSinceLastSignIn > 1 ? "s" : ""}
              </h2>
            </DeviceInfoContainer>
          </DeviceContainer>
        );
      })}
      <WarningContainer>
        <img src={warning} />
        <span>
          Você só poderá acessar através dos dispositivos cadastrados, com
          limite de um dispositivo desktop, um tablet e um smartphone.
        </span>
      </WarningContainer>
      <ButtonContainer>
        <EMRButton
          fullSized
          fontSize={"lg"}
          mediumWeight
          loading={isLoading}
          onClick={handleRegister}
        >
          Cadastrar
        </EMRButton>
        <ReturnButton
          fullSized
          fontSize={"lg"}
          mediumWeight
          mode="transparent"
          onClick={handleToAlternativeAccess}
        >
          Acessos alternativos
        </ReturnButton>
      </ButtonContainer>
    </ContentContainer>
  );
}
