import styled from 'styled-components';
import { colors } from '../../../../../components/library/theme';
import { EMRButton } from '../../../../Button';
import * as Dialog from '@radix-ui/react-dialog';
import { Spinner } from '../../../../Spinner';

export const DropdownLabel = styled.div`
  font-size: 14px;
  line-height: 21px;
  color: ${colors.interface.grey400};
  margin-top: 24px;
`;

export const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;
  margin: 8px 0 24px;

  div {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  span {
    font-size: 12px;
    color: ${colors.medtrack.grey400};
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  margin-top: 24px;

  button {
    width: 100%;
  }

  @media (max-width: 991px) {
    flex-direction: column-reverse;
  }
`;

export const StyledEMRButton = styled(EMRButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-weight: 600;
  font-size: 16px;

  @media (max-width: 991px) {
    font-size: 14px;
    height: 40px;
  }
`;

export const StyledSpinner = styled(Spinner)`
  border-color: rgba(0, 0, 0, 0.14) transparent transparent;
`;
