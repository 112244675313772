import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import FilterForm from "./FilterForm";
import { getLevelsByClassroom } from "../../selectors/classroomSelectors";

function mapStateToProps(state) {
  return {
    initialValues: {},
    levels: getLevelsByClassroom(state),
  };
}

export default connect(mapStateToProps)(
  reduxForm({ form: "classroomFilterForm" })(FilterForm)
);
