import React, { createRef } from "react";
import { css } from "glamor";
import Button from "../library/Button";
import { Form, TextField } from "../library/forms";
import { spacing } from "../library/theme";
import { Grid, Card, Image } from "semantic-ui-react";
import Container from "../library/Container";

const titleStyle = { padding: spacing.xs };
const buttonMargin = css({
  display: "flex",
  flex: 1,
  marginTop: spacing.m,
  justifyContent: "flex-end",
});
const userNameStyle = { marginTop: spacing.xs };
const avatarStyle = { margin: "0 auto" };

export default function ProfileEditForm(props) {
  return (
    <Container>
      <Grid padded centered>
        <Grid.Column computer={2} mobile={16} textAlign="center">
          <Image
            src={props.currentUser?.avatarUrl}
            size="small"
            circular
            style={avatarStyle}
          />
          <h3 style={userNameStyle}>{props.currentUser?.name}</h3>
        </Grid.Column>
        <Grid.Column computer={8} mobile={16}>
          <Form onSubmit={props.handleSubmit} error={props.error}>
            <Card fluid>
              <Card.Header>
                <h3 style={titleStyle}>Editar meu Perfil</h3>
              </Card.Header>
              <Card.Content>
                <TextField
                  name="name"
                  label="Seu nome"
                  placeholder="E-mail ou nome de usuário..."
                  required
                  fluid
                />
                <TextField
                  name="email"
                  type="email"
                  label="E-mail"
                  placeholder="E-mail ou nome de usuário..."
                  disabled
                  required
                  fluid
                />
              </Card.Content>
            </Card>
            <Card fluid>
              <Card.Header>
                <h3 style={titleStyle}>Alterar minha senha</h3>
              </Card.Header>
              <Card.Content>
                <TextField
                  name="password"
                  type="password"
                  icon="lock"
                  iconPosition="left"
                  label="Nova senha"
                  placeholder="*******"
                  fluid
                />
                <TextField
                  name="passwordConfirmation"
                  type="password"
                  icon="lock"
                  iconPosition="left"
                  label="Repita a nova senha"
                  placeholder="*******"
                  fluid
                />
                <TextField
                  name="currentPassword"
                  type="password"
                  icon="lock"
                  iconPosition="left"
                  label="Senha Atual"
                  hint="É necessário digitar a senha atual para confirmar. Ao atualizar a senha, você será deslogado e redirecionado para a tela de login."
                  placeholder="*******"
                  fluid
                />
              </Card.Content>
            </Card>
            <div className={buttonMargin}>
              <Button color="red" size="large">
                Atualizar meu perfil
              </Button>
            </div>
          </Form>
        </Grid.Column>
      </Grid>
    </Container>
  );
}
