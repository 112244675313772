import styled from "styled-components";
import { colors } from "../../../../../components/library/theme";
import { EMRButton } from "../../../../Button";

export const ContainerWeeklyStudyTimeScreen = styled.section`
  display: flex;
  gap: 20px;
  flex-direction: column;
  margin: 0 auto;
  min-height: calc(100vh - 70px);
  padding: 24px;
`;

export const Container = styled.div`
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 991px) {
    padding: 16px;
  }
`;

export const SliderWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 24px;
  flex-direction: column;
  gap: 8px;

  * {
    opacity: ${props => props.isFrial ? 0.4 : 1};
  }

  input {
    pointer-events: ${props => props.isFrial ? 'none' : 'auto'};
  }

  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: ${colors.interface.grey400};
  }

  & > span {
    font-size: 12px;
    line-height: 18px;
    color: ${colors.interface.grey300};
  }
`;

export const Button = styled(EMRButton)`
  width: 100%;
  font-size: 16px;
  font-weight: 600;

  svg {
    min-height: 24px;
    min-width: 24px;
  }

  @media (max-width: 991px) {
    height: 40px;
    font-size: 14px;
  }
`;
export const Wrapper = styled.div`
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 698px;
  width: 100%;
  gap: 24px;

  .illustration {
    max-height: min(27vh, 246px);
    width: auto;
    margin-bottom: 24px;
  }

  .revision-illustration {
    max-height: min(27vh, 246px);
    width: auto;
    margin-bottom: 24px;
    margin-left: -120px;
  }

  @media (max-width: 991px) {
    .illustration {
      width: 223px;
      height: 223px;
      max-height: unset;
    }

    .revision-illustration {
      width: 80%;
      max-height: unset;
      margin-left: 0;
      margin-bottom: 0;
      align-self: flex-start;
    }

    hr:last-of-type {
      display: block;
      margin: 16px 0;
    }

    div:last-of-type {
      margin-bottom: 0;
    }
  }
`;
export const Title = styled.h2`
  margin: 0;
  font-size: 24px;
  font-weight: 600;
  color: ${colors.interface.grey400};

  @media (max-width: 991px) {
    font-size: 14px;
  }
`;
export const Info = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.interface.grey400};

  b {
    font-weight: 600;
    color: ${colors.interface.grey400};
  }

  strong {
    font-weight: 600 !important;
  }

  @media (max-width: 991px) {
    font-size: 14px;
  }
`;
export const Hr = styled.hr`
  width: 100%;
  margin: 0;
  border: solid 1px ${colors.interface.grey100};
  border-bottom: 0;
`;
export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;

  h2 {
    font-size: 16px;
  }
  
  @media (max-width: 991px) {
    h2 {
      font-size: 14px;
    }
  }
`;
export const Instructions = styled.p`
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  color: ${colors.interface.grey400};
  line-height: 1.5;
`;

export const BigAreaTitleWrapper = styled.span`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  gap: 8px;
`;

export const BigAreaTitle = styled(Instructions)`
  color: ${colors.medtrack.grey500};
  width: fit-content;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
`;

export const Subtitle = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: ${colors.interface.grey500};
  line-height: 1.5;
  width: 100%;
`;
