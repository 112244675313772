import styled from "styled-components";

export const Container = styled.div`
  width: 40px;
  height: 40px;
  min-width: 40px;

  svg{
    width: 100%;
  }
  
  @media( max-width: 768px){
    width: 24px;
    height: 24px;
    min-width: 24px
  }
`;
