import React, { useContext, useEffect, useState } from "react";
import { Context } from "../context";
import { Container, Skeleton } from "./styles";

export function BottomBanner() {
  const { banners, loadingBanners } = useContext(Context);
  const [bottomBanner, setBottomBanner] = useState(null);

  useEffect(() => {
    if (banners && banners.bottom && banners.bottom.length > 0)
      setBottomBanner(banners.bottom[0]);
  }, [banners]);

  return (
    <>
      {loadingBanners && <Skeleton />}
      {bottomBanner && (
        <Container>
          <a target="_blank" href={bottomBanner.url}>
            <img src={bottomBanner.image.url} />
          </a>

          <a target="_blank" href={bottomBanner.url}>
            <img src={bottomBanner.image_mobile.url} />
          </a>
        </Container>
      )}
    </>
  );
}
