import React, { useEffect } from "react";
import { connect } from "react-redux";
import { fetchOrganizers } from "../../processes/organizerProccess";
import {
  changeExamTypeCategory,
  fetchExternalOrganizerStatistics,
} from "../../processes/organizerStatatisticProcesses";
import { getOrganizerStatistics } from "../../selectors/organizerSelectors";
import Statistics from "./Statistics";
import { getConfig } from "../../selectors/configSelectors";
import { fetchConfigs } from "../../processes/configProccess";
import { orderByPosition } from "../../utils/orderByPosition";
import { isExpiredUser } from "../../selectors/userSelectors";

function StatisticsContainer(props) {
  const { dispatch, organizerStatistics, examTypeCategories, expiredUser } = props;
  const { organizerId, examTypeCategoryId, examTypeSlug } = organizerStatistics;
  const initialExamTypeCategory = examTypeCategories[0]?.id;

  useEffect(() => {
    changeExamTypeCategory(dispatch, initialExamTypeCategory);
  }, [dispatch, initialExamTypeCategory]);

  useEffect(() => {
    if (!expiredUser) {
      fetchOrganizers(dispatch);
      fetchConfigs(dispatch);
    }
  }, [dispatch]);

  useEffect(() => {
    if (!expiredUser) fetchExternalOrganizerStatistics(dispatch, organizerStatistics);
  }, [dispatch, organizerId, examTypeCategoryId, examTypeSlug]);

  if (expiredUser) return null

  return <Statistics {...props} />;
}

function mapStateToProps(state) {
  const expiredUser = isExpiredUser(state)

  return {
    expiredUser,
    organizerStatistics: getOrganizerStatistics(state),
    examTypeCategories:
      getConfig(state, "examTypeCategories").sort(orderByPosition) || [],
  };
}

export default connect(mapStateToProps)(StatisticsContainer);
