import { mixpanel } from "..";
// @ts-check
/**
 * @param {'string'} courseName
 */

export function trackAccessToCourseEvent(courseName,isFrialUser) {
  mixpanel.track("Plataforma - Cursos", {
    CourseName: courseName,
    UserFrial: isFrialUser
  });
}
