import * as Dialog from "@radix-ui/react-dialog";
import React, { useState } from "react";
import { IoClose, IoSend } from "react-icons/io5";
import { colors } from "../../../../components/library/theme";
import { apiPost } from "../../../../processes/helpers/api";
import { SmileRating } from "./SmileRating";
import {
  ButtonContainer,
  CloseButton,
  DialogClose,
  DialogContent,
  DialogOverlay,
  HeaderContainer,
  Strong,
  StyledEMRButton,
  StyledSpinner,
  Text,
  TextArea,
} from "./styles";

export function FeedbackModal() {
  const [isShowFeedbackModal, setIsShowFeedbackModal] = useState(true);
  const session = JSON.parse(localStorage.getItem("session"));
  const userId = session.currentUserId;
  const [isSendingFeedback, setIsSendingFeedback] = useState(false);
  const [text, setText] = useState("");
  const [rate, setRate] = useState("");
  const isButtonDisabled = rate === "";
  const feedbackBody = {
    text,
    rate,
    user_id: userId,
    product: "MEDTRACK",
  };

  async function handleModalOpenChange() {
    setIsShowFeedbackModal(!isShowFeedbackModal);
  }

  async function handleSendFeedback() {
    setIsSendingFeedback(true);
    await apiPost("/api/v1/feedback", false, false).send(
      JSON.stringify(feedbackBody)
    );
    setIsSendingFeedback(false);
  }

  async function handleCloseModal() {
    setIsSendingFeedback(true);
    await apiPost("/api/v1/feedback", false, false).send(
      JSON.stringify({
        ...feedbackBody,
        rate: "CANCELADO",
        text: "CANCELADO",
      })
    );
    setIsSendingFeedback(false);
  }

  async function setModalText(e) {
    e.preventDefault();
    setText(e.target.value);
  }

  return (
    <Dialog.Root
      open={isShowFeedbackModal}
      onOpenChange={handleModalOpenChange}
    >
      <Dialog.Portal>
        <DialogOverlay>
          <DialogContent>
            <HeaderContainer>
              <h3>Deixe um feedback</h3>
              <Dialog.Close asChild>
                <CloseButton onClick={handleCloseModal}>
                  <IoClose size={24} color={colors.interface.grey300} />
                </CloseButton>
              </Dialog.Close>
            </HeaderContainer>

            <Text>
              O que você está achando do <Strong>Medtrack?</Strong> Seu feedback
              vai contribuir para tornar nossos serviços ainda melhores.
            </Text>

            <SmileRating
              description={rate}
              onChange={(rate) => {
                setRate(rate);
              }}
            />

            <TextArea
              id="feedback_area"
              placeholder="Descreva aqui com mais detalhes (opcional)"
              onChange={setModalText}
            />

            <ButtonContainer>
              <DialogClose asChild>
                <StyledEMRButton
                  mode={"cancel"}
                  fullSized
                  onClick={handleCloseModal}
                >
                  Cancelar
                </StyledEMRButton>
              </DialogClose>

              <Dialog.Close asChild>
                <StyledEMRButton
                  mode={"standard"}
                  fullSized
                  disabled={isSendingFeedback || isButtonDisabled}
                  onClick={handleSendFeedback}
                >
                  {isSendingFeedback ? (
                    <StyledSpinner inline size="small" />
                  ) : (
                    <>
                      <IoSend size={24} />
                      Enviar feedback
                    </>
                  )}
                </StyledEMRButton>
              </Dialog.Close>
            </ButtonContainer>
          </DialogContent>
        </DialogOverlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
