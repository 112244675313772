import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { fetchMockTemplatesHighlighted } from "../../processes/mockTemplateProccesses";
import {
  getAllClassrooms,
  getCurrentClassroomId
} from "../../selectors/classroomSelectors";
import { makeMockTemplatesHighlightedList } from "../../selectors/mockTemplateSelectors";
import MockTemplatesHighlighted from "./MockTemplatesHighlighted";
import { getMockFromMockTemplate } from "../../selectors/mockSelectors";

const defaultFilters = {
  highlightTrue: true,
  s: ["id desc"],
  onlyDistinct: true
};

function MockTemplatesHighlightedContainer(props) {
  const { dispatch, currentClassroomId } = props;
  const filters = {
    ...defaultFilters,
    classroomsIdOrLessonsClassroomsIdIn: currentClassroomId
  };

  useEffect(() => {
    fetchMockTemplatesHighlighted(filters, dispatch);
  }, [dispatch, JSON.stringify(filters)]);

  const fetchHandler = useCallback(
    (_, { activePage = 1 }) => {
      fetchMockTemplatesHighlighted(filters, dispatch, { page: activePage });
    },
    [dispatch, JSON.stringify(filters)]
  );

  const changeCurrentClassroomHandler = useCallback(
    (_, { value }) => {
      dispatch({ type: "CURRENT_CLASSROOM_CHANGED", id: value });
    },
    [dispatch]
  );

  return (
    <MockTemplatesHighlighted
      fetchHandler={fetchHandler}
      changeCurrentClassroomHandler={changeCurrentClassroomHandler}
      {...props}
    />
  );
}

function mapStateToProps(state) {
  const allMocks = makeMockTemplatesHighlightedList(state);
  const mocks = [];

  allMocks.data.forEach((mockTemplate) => {
    const mock = getMockFromMockTemplate(state, mockTemplate.id);
    mocks.push(mock) 
   });
 

  return {
    list: makeMockTemplatesHighlightedList(state),
    mocks,
    currentClassroomId: getCurrentClassroomId(state),
    classrooms: getAllClassrooms(state)
  };
}

export default connect(mapStateToProps)(MockTemplatesHighlightedContainer);
