import * as ScrollArea from "@radix-ui/react-scroll-area";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { colors } from "../../../../components/library/theme";

export const NotifierDropdownContainer = styled.div`
  position: fixed;
  width: 400px;
  transform: translate(-50%);
  background-color: ${colors.interface.white000};
  border-radius: 4px;
  box-shadow: 0px 2px 4px -2px #1018280f;
  box-shadow: 0px 4px 8px -2px #1018281a;
  top: 60px;

  @media (max-width: 992px) {
    width: 96%;
    max-width: 400px;
    transform: translate(-100%, 6px);
    left: 98%;
  }
`;

export const NotifierDropdownHeader = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px;
  border-bottom: 1px solid ${colors.interface.grey100};
`;

export const NotifierDropdownFooter = styled.span`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 16px;
  border-top: 1px solid ${colors.interface.grey100};
`;

export const NotifierDropdownFooterText = styled(Link)`
  font-size: 14px;
  font-weight: 600;
  color: ${colors.brand.pure};

  &:hover {
    cursor: pointer;
  }
`;

export const NotifierDropdownTitle = styled.h1`
  font-size: 14px;
  font-weight: 600;
  color: ${colors.interface.grey400};
  margin: 0;

  @media (max-width: 992px) {
    font-size: 12px;
  }
`;

export const NotifierDropdownNotificationCount = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-color: ${colors.status.red200};
  border-radius: 100%;
  color: ${colors.interface.white000};
`;

export const NotifierDropdownScrollArea = styled(ScrollArea.Root)`
  width: 100%;
  height: 365px;
  overflow: hidden;
  padding: 8px 16px;
  --scrollbar-size: 8px;
`;

export const NotifierDropdownScrollAreaViewport = styled(ScrollArea.Viewport)`
  width: 100%;
  height: 100%;
`;

export const NotifierDropdownScrollAreaItemContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const NotifierDropdownScrollAreaScrollbar = styled(ScrollArea.Scrollbar)`
  display: flex;
  user-select: none;
  touch-action: none;
  padding: 2px;
  background-color: transparent;

  &[data-orientation="vertical"] {
    width: var(--scrollbar-size);
  }
`;

export const NotifierDropdownScrollAreaThumb = styled(ScrollArea.Thumb)`
  flex: 1;
  position: relative;
  background-color: ${colors.interface.grey200};
  border-radius: var(--scrollbar-size);
`;

export const NotifierDropdownScrollAreaCorner = styled(ScrollArea.Corner)`
  background-color: ${colors.interface.grey200};
`;

export const NotifierDropdownItemContainer = styled.div(
  ({ wasRead }) => css`
    display: flex;
    align-items: start;
    padding: 16px;
    gap: 8px;
    background-color: ${wasRead
      ? colors.interface.white000
      : colors.medtrack.grey000};
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid ${colors.interface.grey100};
  `,
);

export const NotifierDropdownItemContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

export const NotifierDropdownItemHeader = styled.span`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const NotifierDropdownItemTitle = styled.h2`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 600;
  color: ${colors.interface.grey500};
  line-height: 1.5;
  margin: 0;
`;

export const NotifierDropdownItemDate = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  color: ${colors.interface.grey300};
  vertical-align: top;

  @media (max-width: 992px) {
    font-size: 10px;
  }
`;

export const NotifierDropdownItemDescription = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  color: ${colors.interface.grey500};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;

  -webkit-line-clamp: 3;
  text-overflow: ellipsis;

  @media (max-width: 992px) {
    font-size: 10px;
  }
`;

export const NotifierDropdownEmptyData = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: center;
  height: 324px;
`;

export const NotifierDropdownEmptyDataTitle = styled.h1`
  font-size: 14px;
  font-weight: 400;
  color: ${colors.interface.grey400};
  line-height: 1.5;
`;

export const NotificationDescriptionContainer = styled.div`
  max-height: 35px;
  overflow: hidden;
`;
