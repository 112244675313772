import React from "react";
import { ConnectedRouter as Router } from "connected-react-router";
import { Switch } from "react-router-dom";
import RoutesContainer from "./RoutesContainer";

export default function Routes({ history }) {
  return (
    <Router history={history}>
      <Switch>
        <RoutesContainer />
      </Switch>
    </Router>
  );
}
