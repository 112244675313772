import React from "react";
import { useRef, useState } from "react";
import { Input, InputWrapper, InputsWrapper, Wrapper } from "./styles";

const digitsLength = 6;

export function CodeInput({ errorMessage, onChange, onFocus }) {
  const [currentInput, setCurrentInput] = useState(0);
  const nextInput = useRef(null);
  const prevInput = useRef(null);
  const digits = useRef(new Array(digitsLength).fill(0));
  const values = useRef({});

  function getInputValues() {
    let code = "";
    for (let i = 0; i < digitsLength; i++) {
      const value = document.getElementById(`input_code_${i}`).value;
      if (value) code += value;
    }
    return code;
  }

  function handleKeyDown(e, index) {
    const isBackspace = e.key === "Backspace";
    const currentValue = values[index];

    if (isBackspace) {
      values.current[index] = "";
      setTimeout(() => {
        !currentValue &&
          isBackspace &&
          prevInput.current &&
          prevInput.current.focus();
      }, 50);
    } else {
      values.current[index] = e.key;
      setTimeout(() => {
        nextInput.current && nextInput.current.focus();
      }, 50);
    }
  }

  return (
    <InputWrapper>
      <Wrapper>
        <InputsWrapper>
          {digits.current.map((_, index) => (
            <Input
              key={index}
              id={`input_code_${index}`}
              style={{ textTransform: "uppercase" }}
              ref={
                index === currentInput + 1
                  ? nextInput
                  : index === currentInput - 1
                  ? prevInput
                  : null
              }
              onFocus={() => {
                setCurrentInput(index);
                onFocus();
              }}
              onKeyDown={(e) => handleKeyDown(e, index)}
              onChange={() => onChange(getInputValues())}
              maxLength={1}
              placeholder="0"
              errorMessage={errorMessage}
            />
          ))}
        </InputsWrapper>
        {errorMessage && <p>{errorMessage}</p>}
      </Wrapper>
    </InputWrapper>
  );
}