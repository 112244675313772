import React, { useEffect, useState } from "react";
import { IoInformationCircle } from "react-icons/io5";
import { medtrackApi } from "../../../../../../services/medtrack";
import { Divider } from "../../../../../components/Divider";
import { useMedTrack } from "../../../hooks/useMedTrack";
import { CheckBox, MedTrackTooltip } from "../../index";
import { DropdownAutocomplete } from "./DropdownAutocomplete";
import { StarIcon } from "./DropdownAutocomplete/StarIcon";
import {
  ButtonContainer,
  CheckBoxContainer,
  DropdownLabel,
  StyledEMRButton,
  StyledSpinner,
} from "./styles";

export function LevelSelect({
  onLevelSelect,
  onCloseModal,
  isCompleted,
  isUpdatingLevel,
  levelName,
}) {
  const [bigAreaLevels, setbigAreaLevels] = useState();
  const [levelSelected, setLevelSelected] = useState();
  const [isBigAreaChecked, setIsBigAreaChecked] = useState(false);
  const [isBigAreaOptionsLoading, setIsBigAreaOptionsLoading] = useState(false);
  const [filterError, setFilterError] = useState();
  const isDisabled = isCompleted || isBigAreaOptionsLoading || isUpdatingLevel;
  const { basesBigArea, getSchedule } = useMedTrack();

  async function getLevels() {
    setIsBigAreaOptionsLoading(true);

    const bigAreaParam = new URLSearchParams();
    if (Boolean(basesBigArea)) {
      bigAreaParam.append("bigArea", basesBigArea.name);
    }

    if (isBigAreaChecked) {
      bigAreaParam.delete("bigArea");
    }
    try {
      const schedules = await getSchedule();
      const defaultScheduleId = schedules.find(
        (schedule) => schedule.type === 'DEFAULT'
      ).id;
      const response = await medtrackApi.get(
        `/level/find-with-bigarea/?${bigAreaParam}&scheduleId=${defaultScheduleId}`
      );
      const { data } = response.body;
      if (data) setbigAreaLevels(data);
      setIsBigAreaOptionsLoading(false);
    } catch (error) {
      console.error(error);
      setIsBigAreaOptionsLoading(false);
    }
  }

  function handleSaveLevel() {
    if (Boolean(levelSelected)) {
      onLevelSelect(levelSelected.id);
    } else {
      setFilterError("Selecione um assunto válido");
    }
  }

  function handleCheckbox() {
    if (isDisabled) return;
    setIsBigAreaChecked((prev) => !prev);
    setFilterError();
  }

  useEffect(() => {
    if (!isCompleted) getLevels();
  }, [isBigAreaChecked, isCompleted]);

  return (
    <>
      <DropdownLabel>Assunto da tarefa</DropdownLabel>

      <DropdownAutocomplete
        options={bigAreaLevels}
        selected={levelSelected}
        setSelected={(value) => setLevelSelected(value)}
        error={filterError}
        isBigAreaOptionsLoading={isBigAreaOptionsLoading}
        setFilterError={setFilterError}
        label="Busque e selecione o assunto"
        disabled={isDisabled}
        levelName={isCompleted ? levelName : undefined}
      />

      <CheckBoxContainer>
        <div>
          <CheckBox
            isLoading={isBigAreaOptionsLoading}
            isDisabled={isDisabled}
            onCheck={handleCheckbox}
            isChecked={isBigAreaChecked}
            variant="square"
          >
            <label>Ver assuntos de outras grandes-áreas</label>
          </CheckBox>

          <MedTrackTooltip
            width="212px"
            variant="subjectModal"
            text="Você pode acionar o checkbox para ter acesso a assuntos que não são do seu rodízio atual"
          >
            <IoInformationCircle size={20} fill="#C2CED6" />
          </MedTrackTooltip>
        </div>
        <div>
          <StarIcon />
          <span>Assuntos sugeridos</span>
        </div>
      </CheckBoxContainer>

      <Divider variant="light" />

      <ButtonContainer>
        <StyledEMRButton
          mode="cancel"
          fullSized
          onClick={onCloseModal}
          disabled={isUpdatingLevel}
        >
          Cancelar
        </StyledEMRButton>

        <StyledEMRButton
          mode="standard"
          fullSized
          disabled={isUpdatingLevel || isCompleted}
          onClick={handleSaveLevel}
        >
          {isUpdatingLevel ? (
            <StyledSpinner inline size="small" />
          ) : (
            "Salvar alterações"
          )}
        </StyledEMRButton>
      </ButtonContainer>
    </>
  );
}