import React, { createContext, useEffect, useState } from 'react';
import { apiGet, apiPost } from '../../processes/helpers/api';
import { useHistory } from 'react-router-dom';
import { fetchClassroms } from '../../processes/classroomProcesses';

export const context = createContext({});

export function Provider({ children, user, dispatch }) {
  const history = useHistory();
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(true);
  const [suggestionsData, setSuggestionsData] = useState({});
  const [selectedClassRoom, setSelectedClassRoom] = useState({});

  async function fetchSuggestions() {
    const response = await apiGet('/api/v1/classroom_suggestion');
    setSuggestionsData(response.body);
    setLoading(false);
  }

  useEffect(() => {
    fetchSuggestions();
  }, []);

  function finish(classroom_id) {
    setLoading(true);
    apiPost('/api/v1/user/create_user_enrollment')
      .send(JSON.stringify({ classroom_id: classroom_id }))
      .then(() => {
        fetchClassroms(dispatch);
        dispatch({
          type: 'CURRENT_CLASSROOM_CHANGED',
          id: classroom_id,
        });
      });
  }

  const values = {
    user,
    step,
    setStep,
    suggestionsData,
    loading,
    finish,
    setSelectedClassRoom,
    selectedClassRoom,
  };

  return <context.Provider value={values}>{children}</context.Provider>;
}
