const LOCAL_STORAGE_KEY = "@monitor-user-use:total-time";

let startTime = 0;
let videoIsPlaying = false;
let videoPlayedTime = 0;
let lastMouseMoveTime = new Date().getTime();
let stopped = false;
let videoStartedAt = 0;
let videoEndedAt = 0;

function bootstrap() {
  init();
  eventsSub();
}

function init() {
  setInterval(tick, 5000);
  startCounting();
  post();
}

function post() {
  //avisa todos iFrames que eventos serão mapeados
  const data = {
    method: "addEventListener",
    value: "timeupdate"
  };
  const player = document.querySelectorAll("iframe");
  player.forEach(function (iframe) {
    iframe.contentWindow.postMessage(JSON.stringify(data), "*"); //Ativa mensageria entre pagina e Iframe
  });
}

//Função para verificar alterações na DOM - usado para SPA para detectar mudanças na rota
bodyDOM = document.querySelector("body");
var previousUrl = "/";
var observer = new MutationObserver(function () {
  if (videoIsPlaying) pauseVideo()
  if (window.location.pathname !== previousUrl) {

    stopCounting(true, previousUrl); //para o contador quando a rota muda passando a url anterior a atual
    previousUrl = window.location.pathname;
  }
});

observer.observe(bodyDOM, {
  //inicia o oberservador de DOM
  childList: true,
  subtree: true
});

window.addEventListener('beforeunload', function () {
  //Dispara evento para quando o usuario tentar fechar a aba / navegador
  stopCounting(true);
  const time = Date.now();
  while ((Date.now() - time) < 3500) { }
  return true;
});

const videoInterval = setInterval(() => {
  //inicia o contador do player
  if (videoIsPlaying) videoPlayedTime++; //a cada segundo acresenta um se video estiver rodando
}, 1000);

function playVideo() {
  videoIsPlaying = true; //indica para o contador que o video esta rodando
  stopCounting(); //para o contador da navegação
}

function pauseVideo() {
  //eventos quando video para
  videoIsPlaying = false; //informa que video parou para o contador
  sendToServer(videoPlayedTime); //envia para o backend o quanto o video rodou
  videoPlayedTime = 0; //zera o contador
  startCounting(); //reinicia o contador de navegação
}

function eventsSub() {
  document.addEventListener("visibilitychange", handleVisibilityChange);
  document.addEventListener("mousemove", handleMouseMove);
  document.addEventListener("touchmove", handleMouseMove);
  window.addEventListener(
    "message",
    function (evt) {
      //escuta todos eventos do video
      if (evt) {
        const data = evt.data;
        if (data.event === "play") {
          //se for dado play no video
          playVideo();
        } else if (data.event === "pause") {
          //se video for pausado
          pauseVideo();
        }
      }
    },
    false
  );
}

function handleVisibilityChange() {
  //escuta se a aba da EMR esta ativa ou não
  if (document.visibilityState === "visible") {
    startCounting();
  } else {
    stopCounting();
  }
}

function startCounting() {
  if (videoIsPlaying) return; //Se houver algum video rodando o contador é ignorado
  startTime = new Date().getTime();
  stopped = false;
}

function stopCounting(isClosing, changedURL) {
  //para o contador da navegação 

  const endTime = new Date().getTime();
  if (!stopped || isClosing) {
    const totalTime = parseInt((endTime - startTime) / 1000);
    if (changedURL) {
      sendToServer(totalTime, changedURL);
    } else {
      sendToServer(totalTime);
    }
  }

  startTime = 0;
  stopped = true;
}

function handleMouseMove() {
  if (videoIsPlaying) return;
  lastMouseMoveTime = new Date().getTime();
  if (stopped) startCounting();
}

function sendToServer(seconds, url) {
  if (seconds < 1) return; //se tempo for menor que zero ignora
  if (seconds > 100000) return;

  const user = JSON.parse(localStorage.getItem("session"))

  if (user) {
    const userId = user.currentUserId; //pega o usuario salvo
    const body = {
      userId: userId,
      path: url || window.location.pathname, //url da pagina aberta (sem dominio)
      time: seconds
    };

    fetch(`${process.env.METRICOAPI_URL}user-use/create`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
      },
      keepalive: true, //IMPORTANTE - avisa para o navegador terminar o envio dos dados mesmo em fechamento
    }).then((response) => {
      if (!user?.isFrial) return;
      if (response.status === 404) {
        const payload = {
          platformId: user.currentUserId,
          email: user.headers.uid,
        };
        fetch(`${process.env.METRICOAPI_URL}user/create`, {
          method: "POST",
          body: JSON.stringify(payload),
          headers: {
            "Content-Type": "application/json",
          },
          keepalive: true,
        }).catch((error) => {
          console.error(error)
        });
      }
    });
  }
}

function minutesToSec(minutes) {
  return minutes * 60;
}

function tick() {
  const endTime = new Date().getTime();
  const mouseMoveTime = endTime - lastMouseMoveTime;
  const mouseMoveTimeInSeconds = mouseMoveTime / 1000;
  if (mouseMoveTimeInSeconds > minutesToSec(5)) stopCounting(); //para por inatividade em 5 minutos
}

bootstrap();