import React from 'react';
import { NotificationsTooltip } from '../NotificationsTooltip';
import { categories } from './constant';
import { NotificationIcon } from './styles';

/**
 * Renderiza icones de acordo com as variantes de categoria das notificações.
 *
 * @param {object} props
 * @param {'achievements' | 'events' | 'materials' | 'roadmap' | 'news' | 'offensive' | 'security' | 'suggestion' | 'warnings'} [props.variant] - Define o ícone a ser renderizado.
 * @param {boolean} [props.wasRead=false] - Se verdadeiro, o botão ficará com a cor cinza.
 */

export function NotificationsIcon({ variant, wasRead }) {
  const category = categories[variant];
  if (!category) return null;

  const Icon = category.icon;

  return Icon ? (
    <NotificationsTooltip title={categories[variant].title}>
      <NotificationIcon wasRead={wasRead}>
        <Icon />
      </NotificationIcon>
    </NotificationsTooltip>
  ) : (
    <></>
  );
}
