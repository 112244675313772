import React from "react";
import { Field } from "redux-form";
import memoize from "fast-memoize";
import { spacing } from "../theme";

const labelStyle = {
  display: "flex",
  alignItems: "center",
  gap: spacing.s,
  marginRight: spacing.m,
};

const buildValidator = memoize((required, other) => {
  return [(value) => (required && !value ? "required" : undefined)].concat(
    other || []
  );
});

export default function CheckBoxField(props) {
  return (
    <label style={labelStyle}>
      <Field
        {...props}
        onChange={()=> props.setQuestionsType(props.value)}
        component="input"
        type="radio"
        validate={buildValidator(props.required, props.validate)}
      />
      {props.label}
    </label>
  );
}
