import React from "react";
import QuestionAlternativesListItemContainer from "./QuestionAlternativesListItemContainer";

export default function QuestionAlternativesList({
  alternatives,
  replied,
  ...rest
}) {
  return alternatives.length
    ? alternatives.map((alternative, index) => (
        <QuestionAlternativesListItemContainer
          key={index}
          index={index}
          alternative={alternative}
          replied={replied}
          {...rest}
        />
      ))
    : null;
}
