import React from "react";
import Video from "../library/Video";
import PDF from "../library/PDF";
import LessonMockContainer from "./LessonMockContainer";

const parseVimeoUrl = (url) => {
  let vimeoUrl;
  const parsedURL = new URL(url);
  if (parsedURL?.hostname === "player.vimeo.com") {
    const videoId = parsedURL.pathname.replace("/external/", "").split(".")[0];
    vimeoUrl = `https://vimeo.com/${videoId}`;
  }
  return vimeoUrl ? vimeoUrl : url;
};

export default function LessonContent({
  lesson,
  onProgressHandler,
  onEndedHandler,
  progress = 0,
}) {
  switch (lesson.lessonType) {
    case "mock":
      return <LessonMockContainer />;
    case "pdf":
      return lesson.fileUrl ? <PDF file={lesson.fileUrl} /> : null;
    default:
      return lesson.videoUrl ? (
        <Video
          url={parseVimeoUrl(lesson.videoUrl)}
          onProgressHandler={onProgressHandler}
          onEndedHandler={onEndedHandler}
          progress={progress}
        />
      ) : null;
  }
}
