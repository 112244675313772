import { createSelector } from "reselect";
import { getLessonId } from "./lessonSelectors";

const getComments = (state) => state.entities.comments;

export const getCommentsByLesson = createSelector(
  getLessonId,
  getComments,
  (lessonId, comments) =>
    Object.values(comments).filter(
      (comment) =>
        comment.commentableType === "Lesson" &&
        comment.commentableId === lessonId
    )
);
