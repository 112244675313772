import { createSelector } from "reselect";
import { orderByPosition } from "../utils/orderByPosition";
import { getLevelGroup } from "./levelGroupSelectors";

export const getLevels = (state) => state.entities.levels;
export const getLevel = (_, props) => props.level;
export const getLevelId = (_, id) => id;

export const getLevelById = createSelector(
  getLevels,
  getLevelId,
  (levels, id) => levels[id]
);

export const getAllLevels = createSelector(getLevels, (levels) =>
  Object.values(levels)
);

export const getLevelsByLevelGroup = createSelector(
  getLevelGroup,
  getLevels,
  (levelGroup, levels) =>
    levels &&
    Object.values(levels)
      .filter((level) => level?.levelGroupId === levelGroup?.id)
      .sort(orderByPosition)
);
