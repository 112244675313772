import React, { useState } from 'react';
import { EMRButton } from '../../../componentsV2/Button';
import { Popover } from '../../../componentsV2/Popover';
import { convertDateToString } from '../../../utils/formatDate';
import {
  AnnotationContainer,
  AnnotationNumber,
  AnnotationNumberContainer,
  AnnotationText,
  AnnotationTextArea,
  ButtonsContainer,
  Divider,
  TextAreaControls,
} from './styles';

import { connect } from 'react-redux';
import confirmDialog from '../../../componentsV2/confirmDialog';
import {
  notifyNotSubmitSucceeded,
  notifySubmitSucceeded,
  notifyWarningSucceeded,
} from '../../../processes/notifierProcesses';
import { getQuestionTitle } from '../../../utils/getQuestionTitle';

function Annotation(props) {
  const {
    annotationId,
    annotationIndex,
    isLastAnnotation,
    annotationUpdateDate,
    annotationDescription,
    mockQuestionName,
    questionNumber,
    dispatch,
    refetchAnnotations,
    page,
    filter,
  } = props;
  const [isEditingAnnotation, setIsEditingAnnotation] = useState(false);
  const [annotationText, setAnnotationText] = useState(annotationDescription);
  const [tempAnnotation, setTempAnnotationText] = useState(
    annotationDescription
  );
  const [isAnnotationTextUntouched, setIsAnnotationTextUntouched] =
    useState(true);
  const textAreaCharacterCount = tempAnnotation.length;

  function handleChangeAnnotationText(event) {
    setTempAnnotationText(event.target.value);
    setIsAnnotationTextUntouched(false);
  }

  function handleSaveAnnotationText() {
    const requestBody = {
      annotation: tempAnnotation,
    };

    try {
      fetch(`/api/v1/notes/${annotationId}`, {
        method: 'PUT',
        body: JSON.stringify(requestBody),
        headers: {
          'Content-Type': 'application/json',
        },
      }).then(
        notifySubmitSucceeded(dispatch, 'Revisão cadastrada com sucesso!'),
        window.scrollTo(0, 0),
        setIsEditingAnnotation(false),
        setIsAnnotationTextUntouched(true),
        setAnnotationText(tempAnnotation)
      );
    } catch {
      notifyNotSubmitSucceeded(dispatch, 'A edição não pode ser salva');
      window.scrollTo(0, 0);
    }
  }

  function handleOpenConfirmDeleteModal() {
    confirmDialog.show({
      title: 'Deseja excluir esta anotação?',
      text: `Ao realizar esta ação você irá excluir permanentemente sua anotação ${
        annotationIndex + 1
      } da ”${getQuestionTitle(mockQuestionName, questionNumber)}".`,
      onYes: () => handleDeleteAnnotationText(),
    });
  }

  function handleDeleteAnnotationText() {
    const requestBody = {
      is_delete: true,
    };

    try {
      fetch(`api/v1/notes/${annotationId}`, {
        method: 'DELETE',
        body: JSON.stringify(requestBody),
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((res) => {
        if (res.status === 200) {
          notifyWarningSucceeded(dispatch, 'Anotação excluída.');
          window.scrollTo(0, 0);
          refetchAnnotations({
            bigAreaFilter: filter === 'ALL' ? '' : filter,
            page: page,
          });
        } else {
          notifyNotSubmitSucceeded(dispatch, 'Erro ao excluir anotação.');
          window.scrollTo(0, 0);
        }
      });
    } catch {
      notifyNotSubmitSucceeded(dispatch, 'Erro ao excluir anotação.');
      window.scrollTo(0, 0);
    }
  }

  return (
    <AnnotationContainer isLastAnnotation={isLastAnnotation}>
      <AnnotationNumberContainer>
        <AnnotationNumber>{annotationIndex + 1}</AnnotationNumber>
        <span>{convertDateToString(annotationUpdateDate)}</span>
        <Popover
          onEdit={() => setIsEditingAnnotation(true)}
          onDelete={handleOpenConfirmDeleteModal}
        />
      </AnnotationNumberContainer>

      {isEditingAnnotation ? (
        <>
          <AnnotationTextArea
            readOnly={false}
            rows={8}
            maxLength={600}
            onChange={(event) => handleChangeAnnotationText(event)}
            defaultValue={annotationText}
          />

          <TextAreaControls>
            <span>{textAreaCharacterCount}/600 caracteres</span>

            <ButtonsContainer>
              <EMRButton
                mode={'cancel'}
                fontSize={'md'}
                shortHeight
                mediumWeight
                onClick={() => setIsEditingAnnotation(false)}
                style={{ minWidth: 207 }}
              >
                Cancelar
              </EMRButton>

              <EMRButton
                mode={'standard'}
                fontSize={'md'}
                shortHeight
                mediumWeight
                onClick={handleSaveAnnotationText}
                style={{ minWidth: 207 }}
                disabled={isAnnotationTextUntouched}
              >
                Salvar alteração
              </EMRButton>
            </ButtonsContainer>
          </TextAreaControls>
        </>
      ) : (
        <AnnotationText>{annotationText}</AnnotationText>
      )}

      <Divider className="divider" />
    </AnnotationContainer>
  );
}

export default connect()(Annotation);
