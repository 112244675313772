import React from "react";
import { Comment } from "semantic-ui-react";
import { fromNow } from "../../utils/formatDate";
import Paragraph from "../library/Paragraph";

export default function CommentListItem(props) {
  const { comment } = props;
  return (
    <Comment>
      <Comment.Avatar src={comment.meta.user.avatarUrl} />
      <Comment.Content>
        <Comment.Author as="a">{comment.meta.user.name}</Comment.Author>
        <Comment.Metadata>{fromNow(comment.createdAt)}</Comment.Metadata>
        <Comment.Text>{comment.text}</Comment.Text>
        {comment.pristine && (
          <Paragraph>
            (Este comentário só será exibido após aprovação.)
          </Paragraph>
        )}
      </Comment.Content>
      {comment.meta.replies.length > 0 && (
        <Comment.Group>
          {comment.meta.replies.map((reply, index) => (
            <Comment key={index}>
              <Comment.Avatar src={reply.meta.user.avatarUrl} />
              <Comment.Content>
                <Comment.Author as="a">{reply.meta.user.name}</Comment.Author>
                <Comment.Metadata>{fromNow(reply.createdAt)}</Comment.Metadata>
                <Comment.Text>{reply.text}</Comment.Text>
              </Comment.Content>
            </Comment>
          ))}
        </Comment.Group>
      )}
    </Comment>
  );
}
