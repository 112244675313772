import React from "react";
import { css, select } from "glamor";
import {
  spacing,
  styles,
  typographyColors,
  uiColors,
  utilityColors,
} from "../library/theme";
import Popup from "../library/Popup";
import { Button, Icon } from "semantic-ui-react";
import { AiOutlineClose } from "react-icons/ai";
import { GrUndo } from "react-icons/gr";
import { getMock } from "../../selectors/mockSelectors";
import { connect } from "react-redux";
import { getMockTemplateById } from "../../selectors/mockTemplateSelectors";

const container = {
  position: "relative",
};
const selectedClass = css({
  border: `2px solid ${uiColors.backgroundDarker}`,
  borderLeftWidth: 0,
  transition: "all 0.3s",
});
const alternativeContainer = css(
  styles.shadow,
  styles.roundedCorners,
  {
    display: "flex",
    backgroundColor: uiColors.fieldBackground,
    overflow: "hidden",
    cursor: "pointer",
    marginTop: spacing.xs,
  },
  select(`&:hover > span`, selectedClass)
);
const alternativeValue = css({
  display: "flex",
  minWidth: spacing.xl,
  maxWidth: spacing.xl,
  flex: 1,
  backgroundColor: uiColors.backgroundDarker,
  justifyContent: "center",
  alignItems: "center",
});
const alternativeValueText = css({
  color: typographyColors.inverseTitle,
});
const alternativeText = css({
  padding: `${spacing.l} 50px ${spacing.l} ${spacing.s}`,
  display: "block",
  width: "100%",
  alignItems: "center",
  border: "2px solid transparent",
  borderLeftWidth: 0,
  borderTopRightRadius: spacing.xxs,
  borderBottomRightRadius: spacing.xxs,
  transition: "all 0.3s",
});

const disabledClass = css({ pointerEvents: "none" });
const correctClass = css(
  select(`& .${alternativeValue}`, {
    backgroundColor: utilityColors.success,
  }),
  select(`& .${alternativeText}`, {
    borderColor: utilityColors.success,
  })
);
const incorrectClass = css(
  select(`& .${alternativeValue}`, {
    backgroundColor: utilityColors.danger,
  }),
  select(`& .${alternativeText}`, {
    borderColor: utilityColors.danger,
  })
);
const rightContentClass = css({
  position: "absolute",
  top: "50%",
  transform: "translate(0, -50%)",
  right: spacing.s,
  cursor: "pointer",
  color: typographyColors.defaultBody,
  marginLeft: "auto",
  paddingLeft: spacing.m,
});
const discartedTitle = {
  textDecoration: "line-through",
  borderColor: "transparent",
  opacity: 0.5,
};
const dimContent = { opacity: 0.5 };

const numberToCharCode = (num) => String.fromCharCode(65 + num);

function QuestionAlternativesListItem({
  index,
  alternative,
  answered,
  createAnswerHandler,
  selectAlternativeHandler,
  isAnswer,
  isSelected,
  isExam,
  isFinished,
  isDiscarted,
  toggleDiscardAlternative,
  showCorrectAnswer,
  isPreExam
}) {
  const showDiscarted =
    isDiscarted &&
    !isSelected &&
    !isAnswer &&
    (!answered || (isExam && !isFinished));

  const showAnswer = isFinished || (answered && !isExam);
  const isCorrect = answered && alternative?.value;
  const answerClass = answered?.value ? correctClass : incorrectClass;
  const className = isPreExam
    ? css(alternativeContainer, isAnswer && selectedClass, answered && disabledClass)
    : css(
      alternativeContainer,
      showCorrectAnswer && showAnswer && isCorrect && correctClass,
      showCorrectAnswer && showAnswer && isAnswer && answerClass,
      isExam && !isFinished && isAnswer && selectedClass,
      (showAnswer || isAnswer || isFinished || showDiscarted) && disabledClass);

  const alternativeTextClass = css(
    alternativeText,
    isSelected && !isAnswer && selectedClass
  );
  const showAverage = !!answered && (!isExam || isFinished);
  const discardAleternative =
    isSelected || isAnswer ? undefined : (
      <Popup
        position="right center"
        on={["click", "hover"]}
        content={showDiscarted ? "Desfazer" : "Excluir alternativa"}
        trigger={
          <Button
            toggle
            circular
            icon={showDiscarted ? GrUndo : AiOutlineClose}
          />
        }
      />
    );

  return (
    <div style={container}>
      <div
        className={className}
        onClick={isExam ? createAnswerHandler : selectAlternativeHandler}
      >
        <div className={css(alternativeValue, showDiscarted && dimContent)}>
          <h3 className={alternativeValueText}>{numberToCharCode(index)}</h3>
        </div>

        <span
          className={css(alternativeTextClass, showDiscarted && discartedTitle)}
        >
          {alternative.title}
        </span>
      </div>
      <div
        className={rightContentClass}
        onClick={showAverage ? undefined : toggleDiscardAlternative}
      >
        {showAverage ? (
          <>
            {alternative.average === null ? (
              <Popup
                position="right center"
                content="Não há dados suficientes para calcular as estatísticas da alternativa"
                trigger={
                  <Icon
                    inverted
                    name="question circle"
                    size="large"
                    color="grey"
                  />
                }
              />
            ) : (
              isPreExam ? "" : `${alternative.average}%`
            )}
          </>
        ) : (
          discardAleternative
        )}
      </div>
    </div>
  );
}

function mapStateToProps(state, ownProps) {
  const mock = getMock(state)
  const mockTemplate = getMockTemplateById(state, mock.mockTemplateId)
  const isPreExam = mockTemplate ? mockTemplate.preExam : false

  return ({
    isPreExam,
    ...ownProps
  })
}

export default connect(mapStateToProps)(QuestionAlternativesListItem)
