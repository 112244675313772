import styled from "styled-components";
import { colors } from "../../../components/library/theme";

export const Text = styled.span`
  font-size: 16px;
  line-height: 24px;

  & > strong {
    font-weight: 500;
  }
`

export const SmallText = styled.small`
  font-size: 14px;
  line-height: 21px;
`

export const ButtonWrapper = styled.fieldset`
  display: flex;
  flex-direction: column;
  gap: 16px;

  border: 0;
  margin-top: auto;
  padding: 0;
`

export const Terms = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: ${colors.interface.grey400};
  
  a {
    color: ${colors.interface.grey400};
    font-weight: 500;
    text-decoration: underline;
  }
`