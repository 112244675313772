import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import Mock from "./Mock";
import { getMock } from "../../selectors/mockSelectors";
import { fetchMock, finishMock } from "../../processes/mockProccess";
import MockExamInvalidTime from "./MockInvalidTime";
import { isAfterToday } from "../../utils/formatDate";
import { getCurrentUser } from "../../selectors/userSelectors";
import { getMockPreferences } from "../../selectors/mockSelectors.js";

function MockContainer(props) {
  const { dispatch, mock, currentMockId, isAdmin } = props;

  const finishMockHandler = useCallback(
    () => finishMock(dispatch, mock?.id),
    [dispatch, mock]
  );

  useEffect(() => {
    return () => sessionStorage.removeItem('highlight-storage');
  }, []);

  useEffect(() => {
    if (!mock && currentMockId) fetchMock(dispatch, currentMockId);
  }, [dispatch, mock, currentMockId]);

  const changeShowPreference = useCallback(
    (preference) => {
      return () => dispatch({ type: "MOCK_PREFERENCE_CHANGED", preference });
    },
    [dispatch]
  );

  return isAfterToday(mock?.startsAt) && !isAdmin ? (
    <MockExamInvalidTime startsAt={mock?.startsAt} />
  ) : (
    <Mock
      finishMockHandler={finishMockHandler}
      changeShowPreference={changeShowPreference}
      {...props}
    />
  );
}

function mapStateToProps(state) {
  const mock = getMock(state);
  const { showQuestionVideoUrl, showQuestionExtraInfo, showCorrectAnswer } =
    getMockPreferences(state);
  return {
    mock,
    currentMockId: state.mock.currentMockId,
    isFinished: !!mock?.finishedAt,
    isExam: mock?.exam,
    isAdmin: getCurrentUser(state)?.isAdmin,
    showQuestionVideoUrl,
    showQuestionExtraInfo,
    showCorrectAnswer
  };
}

export default connect(mapStateToProps)(MockContainer);
