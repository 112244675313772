import React, { useCallback, useState } from "react";
import { Modal, Icon, Checkbox, List } from "semantic-ui-react";
import Button from "../library/Button";
import { styles, spacing } from "../library/theme";

const modalContentStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};
const modalContentItemStyle = {
  display: "flex",
  alignItems: "center",
  gap: spacing.xs,
  margin: `${spacing.xs} 0`,
  fontSize: styles.bodyTextLarge,
};

export default function MockModalPreferences(props) {
  const {
    showQuestionVideoUrl,
    showQuestionExtraInfo,
    showCorrectAnswer,
    changeShowPreference,
  } = props;

  const [openModal, setOpenModal] = useState(false);

  const openModalHandler = useCallback(() => {
    setOpenModal(true);
  }, []);

  const closeModalHandler = useCallback(() => {
    setOpenModal(false);
  }, []);

  return (
    <Modal
      size="mini"
      onOpen={openModalHandler}
      open={openModal}
      trigger={
        <Button>
          <Icon name="sliders horizontal" />
          Preferências
        </Button>
      }
    >
      <Modal.Header>Preferências do simulado</Modal.Header>
      <Modal.Content style={modalContentStyle}>
        <div>
          <div style={modalContentItemStyle}>
            <Checkbox
              toggle
              checked={showQuestionVideoUrl}
              onChange={changeShowPreference("showQuestionVideoUrl")}
            />
            Exibir comentário em vídeo
          </div>
          <div style={modalContentItemStyle}>
            <Checkbox
              toggle
              checked={showQuestionExtraInfo}
              onChange={changeShowPreference("showQuestionExtraInfo")}
            />
            Exibir comentário escrito
          </div>
          <div style={modalContentItemStyle}>
            <Checkbox
              toggle
              checked={showCorrectAnswer}
              onChange={changeShowPreference("showCorrectAnswer")}
            />
            Exibir resposta correta
          </div>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={closeModalHandler} primary>
          Aplicar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
