const getNotificationsUsers = (action) => action.entities.notificationsUsers;

export default function notificationsUsersReducer(state = {}, action) {
  switch (action.type) {
    case "NOTIFICATIONS_USER_CREATED":
    case "NOTIFICATIONS_USER_UPDATED":
    case "NOTIFICATIONS_USERS_FETCHED":
      return { ...state, ...getNotificationsUsers(action) };
    default:
      return state;
  }
}
