import React, { useState } from "react";
import {
  ButtonContainer,
  CloseButton,
  DialogClose,
  DialogContent,
  DialogOverlay,
  DropDownWrapper,
  Hr,
  StyledEMRButton,
  StyledSpinner,
  TextWrapper,
  Title,
  TitleWrapper,
} from "./styles";
import * as Dialog from "@radix-ui/react-dialog";
import { colors } from "../../../../components/library/theme";
import { IoClose } from "react-icons/io5";
import { Infobox } from "../Infobox";
import { BigAreaDropdown } from "../BigAreaDropdown";
import { medtrackApi } from "../../../../../services/medtrack";
import { useMedTrack } from "../../hooks/useMedTrack";

export function ModalBigArea({ isOpen, setIsOpen, isFirstAccess }) {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedBigArea, setSelectedBigArea] = useState();
  const { getBasesBigArea } = useMedTrack();

  async function handleSaveBigArea() {
    setIsLoading(true);
    try {
      await medtrackApi.put("/big-area-priority", {
        bigAreaId: selectedBigArea?.id,
      });
      setIsLoading(false);
      getBasesBigArea();
      setIsOpen(false);
    } catch (erros) {
      console.log(erros);
      setIsLoading(false);
    }
  }

  return (
    <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Portal>
        <DialogOverlay>
          <DialogContent>
            <TitleWrapper>
              <Title>Ajustar grande-área prioritária</Title>

              <Dialog.Close asChild>
                <CloseButton>
                  <IoClose size={24} color={colors.interface.grey300} />
                </CloseButton>
              </Dialog.Close>
            </TitleWrapper>

            <TextWrapper>
              <p>
                Fique a vontade para ajustar a grande-área que deseja focar em
                seus estudos. Lembre-se sempre de considerar seu desempenho em
                cada grande-área.
              </p>
            </TextWrapper>

            <Hr />

            <DropDownWrapper>
              <p>Defina seu rodízio de estudos:</p>
              <BigAreaDropdown
                selectedBigArea={selectedBigArea}
                setSelectedBigArea={setSelectedBigArea}
                isFirstAccess={isFirstAccess}
              />
            </DropDownWrapper>

            <Infobox variant={"lightGrey"}>
              Ao definir uma nova grande-área suas tarefas serão ajustadas
              automaticamente conforme a área definida. Esta ação não poderá ser
              desfeita.
            </Infobox>

            <Hr />

            <ButtonContainer>
              <DialogClose asChild>
                <StyledEMRButton mode={"cancel"} fullSized>
                  Cancelar
                </StyledEMRButton>
              </DialogClose>

              <StyledEMRButton
                mode={"standard"}
                fullSized
                onClick={handleSaveBigArea}
                disabled={isLoading || !selectedBigArea}
              >
                {isLoading ? (
                  <StyledSpinner inline size="small" />
                ) : (
                  "Salvar alterações"
                )}
              </StyledEMRButton>
            </ButtonContainer>
          </DialogContent>
        </DialogOverlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
