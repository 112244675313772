import styled from "styled-components";
export const ContentContainter = styled.div`
  max-width: 580px;
  align-items: center;
  img {
    width: 300px;
  }
  display: flex;
  flex-direction: column;
  gap: 40px;
  button {
    font-weight: 600;
    font-size: 16px;
    line-height: 24pxe;
  }
`;
export const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  h1 {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    line-height: 24px;
    color: #606a71;
  }
  h2 {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #606a71;
  }
`;
