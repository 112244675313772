import React, { useState } from "react";
import MockProgressBar from "./MockProgressBar";
import { utilityColors, stateColors } from "../library/theme";
import { css } from "glamor";
import { IoPlay } from "react-icons/io5";
import { useHistory } from "react-router-dom";
import { trackContinueMockTemplateEvent } from "../../../services/mixpanel/events/trackContinueMockTemplateEvent";
import { EMRButton } from "../../componentsV2/Button";
import formatDate, {
  fromNow,
  isAfterToday,
  isBeforeToday,
} from "../../utils/formatDate";
import MockStatus from "./MockStatus";
import styled from "styled-components";

const paragraphInvalid = css({
  display: "inline",
});
const notStartedClass = css(paragraphInvalid, {
  color: stateColors.empty,
});
const availableToDoClass = css(paragraphInvalid, {
  color: utilityColors.information,
});

const StatusTd = styled.td`
  @media (max-width: 768px) {
    width: 100% !important;
    display: flex !important;
    align-items: center;
    justify-content: space-between;
  }
`;

export default function MockTemplateListItem({
  mockTemplate,
  createMockHandler,
  isAdmin,
  mock,
  isTheLastExam,
  user,
  dispatch,
  isFrialUser,
  activeTabName,
}) {
  const history = useHistory();
  const searchParams = history.location.search;
  const [loading, setLoading] = useState(false);
  const progress = !mockTemplate.progress ? 0 : mockTemplate.progress;
  const isFinished =
    !!mockTemplate.finished_at ||
    mockTemplate.progress === 1 ||
    mock?.progress > 99;
  const mockLinkLabelNotFinished =
    progress > 0 ? "Continuar Simulado" : "Começar Simulado";
  const mockLinkLabel = isFinished
    ? "Visualizar Simulado"
    : mockLinkLabelNotFinished;

  const getStatus = () => {
    if (isFinished) return "Finalizado";
    if (progress === 0 || progress === null) return "Pendente";
    if (progress > 0 && progress < 1) return "Em andamento";
  };

  function handleClick(id) {
    trackContinueMockTemplateEvent({
      name: mockTemplate.name,
      score: mockTemplate.score,
      progress: mockTemplate.progress,
      status: getStatus(),
      isFrialUser,
      mockTemplateType: activeTabName,
    });

    history.push(`/mocks/${id}${searchParams}&isTheLastExam=${isTheLastExam}`);
  }

  async function createMock() {
    try {
      await createMockHandler(setLoading);

      trackContinueMockTemplateEvent({
        name: mockTemplate.name,
        score: mockTemplate.score,
        progress: 0,
        status: getStatus(),
        isFrialUser,
        mockTemplateType: activeTabName,
      });
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <tr>
      <td>
        <span>Criação:</span>
        <div style={{ paddingRight: 0 }}>
          {formatDate(mockTemplate.created_at, "DD/MM/YYYY")}
        </div>
      </td>
      <td>
        <div style={{ paddingRight: 0 }}>
          {mockTemplate.name}

          {isAfterToday(mockTemplate.startAt) ? (
            <p className={notStartedClass}>{` - Inicia ${fromNow(
              mockTemplate.startAt
            )}`}</p>
          ) : (
            isAfterToday(mockTemplate.endAt) && (
              <p className={availableToDoClass}>
                {` - Finaliza ${fromNow(endAt)}`}
              </p>
            )
          )}
        </div>
      </td>
      <StatusTd>
        {mockTemplate && isBeforeToday(mockTemplate.startAt) ? (
          <MockStatus value={progress} isFinished={isFinished} />
        ) : (
          <MockStatus value={0} isFinished={false} />
        )}
      </StatusTd>
      <td>
        <span>Nota:</span>
        <div style={{ paddingRight: 0 }}>
          {isFinished ? mockTemplate?.score : "---"}
        </div>
      </td>
      <td>
        <span>Progresso:</span>
        <MockProgressBar value={progress} />
      </td>

      <td width="20%">
        {mockTemplate.haveMock ? (
          <EMRButton
            onClick={() => handleClick(mockTemplate.id)}
            style={{ padding: "0 2%" }}
            mode="outline"
            mediumWeight
            fullSized
            shortHeight
          >
            <IoPlay size={20} />
            {isAfterToday(mockTemplate.startAt)
              ? "Começar Simulado"
              : mockLinkLabel}
          </EMRButton>
        ) : (
          <EMRButton
            onClick={createMock}
            style={{ padding: "0 2%" }}
            mode="outline"
            fullSized
            shortHeight
            mediumWeight
            disabled={
              (mockTemplate.startAt !== null &&
                new Date(mockTemplate.startAt).getTime() > Date.now() &&
                !isAdmin) ||
              loading
            }
          >
            <IoPlay size={20} />
            Começar Simulado
          </EMRButton>
        )}
      </td>
    </tr>
  );
}
