import * as React from 'react';

export function SuggestionIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
      <rect width={24} height={24} fill="#35BD78" rx={12} />
      <path
        fill="#fff"
        d="M12.875 17.688h-1.75a.437.437 0 0 0 0 .875h1.75a.438.438 0 0 0 0-.875Zm.438-1.313h-2.626a.437.437 0 0 0 0 .875h2.626a.438.438 0 0 0 0-.875Zm1.788-9.66A4.35 4.35 0 0 0 12 5.437a4.364 4.364 0 0 0-4.375 4.375c0 1.274.489 2.467 1.34 3.27l.12.113c.492.46 1.165 1.091 1.165 1.648v.656a.438.438 0 0 0 .438.438h.656a.218.218 0 0 0 .219-.22v-3.203a.22.22 0 0 0-.14-.205 3.575 3.575 0 0 1-.716-.392.44.44 0 0 1 .009-.743.44.44 0 0 1 .499.032c.202.143.592.355.785.355.193 0 .583-.212.786-.355a.437.437 0 0 1 .507.711 3.577 3.577 0 0 1-.715.392.22.22 0 0 0-.14.204v3.205a.219.219 0 0 0 .218.219h.656a.438.438 0 0 0 .438-.438v-.656c0-.544.666-1.174 1.153-1.635l.134-.127c.875-.836 1.338-1.968 1.338-3.27a4.352 4.352 0 0 0-1.274-3.098Z"
      />
    </svg>
  );
}
