import React from "react";
import { Container, Description, Title, Paragraph } from "./styles";
import { MedicsIllustration } from "./assets/MedicsIllustration";
import emrLogo from "../../../../assets/images/logo.svg";
import { EMRButton } from "../Button";
import { useHistory } from "react-router-dom";

export function AccessDenied() {
  const history = useHistory();

  function handleReturnToSignin() {
    history.push("/");
  }

  return (
    <Container>
      <img src={emrLogo} />

      <Description>
        <MedicsIllustration />
        <Title>Bem-vindo(a) ao Eu Médico Residente</Title>
        <Paragraph>
          Esta conta já teve seus cursos finalizados ou expirados. Por esse
          motivo, você não tem mais acesso ao nosso conteúdo. Caso tenha
          interesse em novos cursos, acesse nosso site para saber mais.
        </Paragraph>
      </Description>

      <EMRButton
        fullSized
        fontSize={"lg"}
        mediumWeight
        onClick={handleReturnToSignin}
      >
        Voltar para o login
      </EMRButton>

      <a
        href="https://www.eumedicoresidente.com.br/cursos"
        target="_blank"
        rel="noopener noreferrer"
      >
        Conhecer novos cursos
      </a>
    </Container>
  );
}
