import { connect } from "react-redux";
import { getAlternativesFromQuestion } from "../../selectors/alternativeSelectors";
import QuestionAlternativesList from "./QuestionAlternativesList";

function mapStateToProps(state, ownProps) {
  return {
    alternatives: getAlternativesFromQuestion(state, ownProps.questionId),
  };
}

export default connect(mapStateToProps)(QuestionAlternativesList);
