import React from "react";
import { connect, shallowEqual } from "react-redux";
import LevelListItem from "./LevelListItem";
import { getLessonsByLevel } from "../../selectors/lessonSelectors";
import { getMockTemplateFromLevel } from "../../selectors/mockTemplateSelectors";

function LevelListItemContainer(props) {
  if (!props.lessons.length && !props.mockTemplates.length) return null;
  return <LevelListItem {...props} />;
}

function mapStateToProps(state, ownProps) {
  const { level } = ownProps;
  const isFrialUser = state.isFrialUser;
  const purchasedLevels = state.purchasedLevels;
  const lessons = getLessonsByLevel(state, ownProps);
  const isLevelPurchased = purchasedLevels?.some(
    (level) => level === lessons[0]?.levelId
  );
  const isMedtrackEnabled = state?.isMedTrackFirstAccess?.medtrackEnabled;

  return {
    isFrialUser,
    lessons,
    mockTemplates: getMockTemplateFromLevel(state, level?.id),
    isLevelPurchased,
    isMedtrackEnabled,
  };
}

const areStatesEqual = (next, prev) =>
  shallowEqual(next.entities.lessons, prev.entities.lessons) &&
  shallowEqual(next.purchasedLevels, prev.purchasedLevels);

export default connect(mapStateToProps, null, null, {
  pure: true,
  areStatesEqual,
})(LevelListItemContainer);
