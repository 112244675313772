import styled from 'styled-components';
import { colors } from '../../../../components/library/theme';

export const ContainerDropdown = styled.div`
  width: 100%;
  z-index: ${({ isActive }) => (isActive ? '1' : '0')};
  position: relative;
  user-select: none;
  height: 48px;
  background: ${colors.interface.white000};
`;

export const InputWrapper = styled.div`
  border: 1px solid;
  border-color: ${(props) =>
    props.isActive ? colors.medtrack.green150 : colors.interface.grey200};
  border-color: ${(props) => props.hasValidationError && colors.status.red300};
  border-radius: 4px;
  display: flex;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
  overflow: visible;
  border-radius: 4px;

  &:focus-within {
    border-color: ${(props) =>
    props.isActive ? colors.brand.pure : colors.interface.grey200};
    border-color: ${(props) =>
    props.hasValidationError && colors.status.red300};
  }

  @media (min-width: 1600px) {
    height: 48px;
  }

  input {
    cursor: pointer;
    border: 0;
    height: 100%;
    width: 100%;
    outline: none;
    font-size: 14px;
    border-radius: 4px;

    @media (max-width: 1600px) {
      font-size: 14px;
    }

    &:-webkit-autofill {
      -webkit-font-family: 'Poppins';
      -webkit-box-shadow: 0 0 0 30px white inset;
    }
  }
`;

export const DropdownButton = styled.input`
  padding: 12px 16px;
  height: 40px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${colors.interface.grey200};
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    color: ${colors.interface.grey400};
  }

  @media (min-width: 1600px) {
    height: 48px;
    font-size: 14px;
  }
`;

export const Label = styled.label`
  background-color: #fff;
  left: 12px;
  top: ${(props) => (props.isActive ? '-13px' : '50%')};
  transform: ${(props) => (props.isActive ? 'unset' : 'translateY(-50%)')};
  transition: 150ms;
  padding: 0 4px;
  position: absolute;
  pointer-events: none;
  color: ${(props) =>
    props.isActive ? colors.brand.pure : colors.interface.grey400};
  color: ${(props) =>
    props.hasValidationError && props.isActive && colors.status.red300};
  height: ${(props) => (props.isActive ? '22px' : 'auto')};
`;

export const SelectedItemContainer = styled.div`
  gap: 8px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  color: ${colors.interface.grey400};
  transform: translateY(-50%);
  position: absolute;
  left: 12px;
  top: 50%;

  span {
    color: ${colors.interface.grey400};
  }

  div:first-child {
    width: 24px;
    height: 24px;

    svg {
      scale: 0.5;
    }
  }
`;

export const DropdownContent = styled.div`
  position: absolute;
  top: 110%;
  left: 0;
  background: ${colors.interface.white000};
  box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06),
    0px 4px 8px -2px rgba(16, 24, 40, 0.1);
  width: 100%;
  border: 1px solid ${colors.brand.light200};
  border-radius: 4px;
  max-height: 16rem;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 0.8rem; /* width of the entire scrollbar */
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${colors.interface
    .grey200}; /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    border: 2px solid ${() => colors.interface.grey100}; /* creates padding around scroll thumb */
    height: 76px;
  }

  ::-webkit-scrollbar-track {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    background: ${colors.interface.grey100}; /* color of the tracking area */
  }

  @media (min-width: 1600px) {
    max-height: 14rem;
  }
`;
export const Icon = styled.div`
  position: absolute;
  right: 16px;
  transform: translateY(-50%);
  top: 50%;
  pointer-events: none;
  display: flex;
`;
export const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 8px;
  padding: 0 16px;
  height: 40px;
  font-size: 14px;
  color: ${colors.interface.grey400};

  div:first-child {
    width: 24px;
    height: 24px;

    svg {
      scale: 0.5;
    }
  }

  :hover {
    background: ${colors.brand.light100};
  }

  @media (min-width: 1600px) {
    height: 48px;
  }
`;

export const Error = styled.small`
  color: ${colors.status.red300};
  font-size: 14px;

  @media (min-width: 1600px) {
    font-size: 14px;
  }
`;
