import React from "react";
import propTypes from "prop-types";

export function WhiteCheckIcon({
  fill = "#FFFFFF",
  height = "16.25",
  width = "16.25",
  ...props
}) {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 18 17"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 0.374939C4.51992 0.374939 0.875 4.01986 0.875 8.49994C0.875 12.98 4.51992 16.6249 9 16.6249C13.4801 16.6249 17.125 12.98 17.125 8.49994C17.125 4.01986 13.4801 0.374939 9 0.374939ZM13.2285 5.77689L7.97852 12.0269C7.92093 12.0955 7.84927 12.1509 7.76839 12.1894C7.68752 12.2279 7.59932 12.2485 7.50977 12.2499H7.49922C7.41162 12.2499 7.325 12.2315 7.24499 12.1958C7.16497 12.1601 7.09335 12.108 7.03477 12.0429L4.78477 9.54291C4.72762 9.4823 4.68317 9.41088 4.65403 9.33285C4.62488 9.25482 4.61162 9.17175 4.61504 9.08852C4.61846 9.0053 4.63848 8.92359 4.67392 8.84821C4.70936 8.77283 4.75952 8.7053 4.82143 8.64958C4.88335 8.59386 4.95578 8.55108 5.03447 8.52375C5.11315 8.49642 5.19651 8.4851 5.27963 8.49045C5.36276 8.49579 5.44398 8.5177 5.51851 8.55488C5.59305 8.59207 5.6594 8.64378 5.71367 8.70697L7.48281 10.6726L12.2715 4.97299C12.3789 4.8488 12.5309 4.77187 12.6945 4.75883C12.8582 4.74578 13.0205 4.79768 13.1462 4.90329C13.2719 5.00889 13.351 5.15974 13.3664 5.32321C13.3818 5.48668 13.3323 5.64965 13.2285 5.77689Z"
        fill="white"
      />
    </svg>
  );
}

WhiteCheckIcon.propTypes = {
  fill: propTypes.string,
  height: propTypes.number,
  width: propTypes.number,
};
