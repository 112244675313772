import React from "react";
import { css } from "glamor";

const container = css({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export default function EmptyGraph(props) {
  return (
    <div className={container}>
      <h4>Gráfico não disponível</h4>
    </div>
  );
}
