import React from "react";
import congratsIcon from "../../../../../../assets/images/congrats-icon.png";
import {
  NotifierDropdownEmptyData,
  NotifierDropdownEmptyDataTitle,
} from "./styles";

export function NotifierEmptyData() {
  return (
    <NotifierDropdownEmptyData>
      <img src={congratsIcon} />
      <NotifierDropdownEmptyDataTitle>
        Você está atualizado(a)!
      </NotifierDropdownEmptyDataTitle>
    </NotifierDropdownEmptyData>
  );
}
