import React from "react";
import { spacing, typographyColors } from "../library/theme";

const container = {
  width: "100%",
  display: "flex",
  justifyContent: "center",
  fontWeight: 400,
  color: typographyColors.defaultBody,
  padding: spacing.l,
};

export default function Empty({ text = "Não há informações disponíveis." }) {
  return <div style={container}>{text}</div>;
}
