import { mededuApi } from "../../../services/mededu";

/**
 * @param {object} params
 * @param {number} [params.page] - default to 1
 * @param {number} [params.size] - default to 10
 * @param {boolean} [params.onlyUnread] - default to false
 *
 */
export async function getNotifications(params) {
  const response = await mededuApi.get(
    `/notifications?page=${params.page ?? 1}&size=${params.size}${
      params.onlyUnread ? "&status=unread" : ""
    }`,
  );
  return {
    notifications: response?.body?.data ?? [],
    pagination: response?.body?.pagination,
  };
}

/**
 * @returns {Promise<number>}
 */
export async function getUnreadNotificationsCount(onlyLastWeek = false) {
  const response = await mededuApi.get(
    `/notifications/unread?${onlyLastWeek ? "lastWeek=true" : ""}`,
  );

  return response?.body?.data?.count ?? 0;
}

/**
 * @param {string} id
 */
export async function pinNotification(id) {
  await mededuApi.post(`/notifications/${id}/pin`);
}

export async function markAllAsRead() {
  await mededuApi.post(`/notifications/read-all`);
}

/**
 * @param {string} id
 */
export async function deleteNotification(id) {
  await mededuApi.del(`/notifications/${id}`);
}

/**
 * @param {string} id
 */
export async function readNotification(id) {
  await mededuApi.post(`/notifications/${id}/read`);
}
