import React, { useEffect } from "react";
import {
  Alternative,
  AlternativesWrapper,
  Enunciation,
  IconWrapper,
  QuestionAnswer,
} from "./styles";
import { CrossIcon } from "./assets/CrossIcon";
import { CheckIcon } from "./assets/CheckIcon";

/**
 * Component to list and format question alternatives based on their correctness.
 * @param {{text: string, isQuestionInverted: boolean}} props - Full text containing all question items and their details.
 * @returns {JSX.Element} - A React component displaying formatted question alternatives.
 */
export function QuestionAlternativesList({ text, isQuestionInverted }) {
  // Remover a parte da resposta do texto
  const textWithoutResponse = removeAnswer(text);

  // Encontrar o índice onde começa a primeira alternativa
  const firstAlternativeIndex = textWithoutResponse.search(/\n [A-E]\) /);

  // Dividir o texto em introdução e alternativas
  const introText = textWithoutResponse.substring(0, firstAlternativeIndex);
  const alternativesText = textWithoutResponse.substring(firstAlternativeIndex);

  // Formatar as alternativas
  const formattedText = formatAlternatives(alternativesText);

  // Renderizar a introdução e as alternativas
  const content = (
    <>
      <Enunciation>{introText}</Enunciation>
      {renderTextWithHighlights(formattedText, isQuestionInverted)}
    </>
  );

  return <AlternativesWrapper>{content}</AlternativesWrapper>;
}

/**
 * Replaces the pattern in question alternatives to enhance readability.
 * @param {string} text - The original text with questions.
 * @returns {string} - The text with formatted alternatives.
 */
function formatAlternatives(text) {
  return text.replace(/([A-E])\)/g, "$1 -");
}

/**
 * Removes the "Resposta: <Alternativa>" part from the text.
 * @param {string} text - The original text.
 * @returns {string} - The text without the "Resposta: <Alternativa>" part.
 */
function removeAnswer(text) {
  const regex = /\s*Resposta:\s*[A-E]\s*$/;
  return text.replace(regex, "");
}

/**
 * Renders text lines with highlights and icons based on correctness.
 * @param {string} text - The text to be rendered, already formatted.
 * @param {boolean} isQuestionInverted - Flag to determine if the question's correctness display should be inverted.
 * @returns {Array<JSX.Element>} - A list of JSX elements representing the text lines.
 */
function renderTextWithHighlights(text, isQuestionInverted) {
  const lines = text.split("\n").filter((line) => line.trim()); // Filtra linhas vazias

  return lines.map((line, index) => {
    const regex = /^\s*([A-E]) - (CORRETA|INCORRETA), (.*)/;
    const match = line.match(regex);

    if (match) {
      const isCorrect = match[2] === "CORRETA";

      let Icon;
      if (isQuestionInverted) {
        // Invert only icons
        Icon = isCorrect ? <CrossIcon /> : <CheckIcon />;
      } else {
        Icon = isCorrect ? <CheckIcon /> : <CrossIcon />;
      }

      return renderAlternativeLine(match, Icon, index);
    } else {
      return renderNonMatchingLine(line, index);
    }
  });
}

/**
 * Renders a line with an icon and highlighted text.
 * @param {Array} match - Regex match result containing parts of the line.
 * @param {JSX.Element|null} Icon - The icon to be displayed (correct or incorrect).
 * @param {number} index - The current index in the map function.
 * @returns {JSX.Element} - A formatted line with an icon and highlights.
 */
function renderAlternativeLine(match, Icon, index) {
  return (
    <Alternative key={index}>
      {Icon && <IconWrapper>{Icon}</IconWrapper>}
      <QuestionAnswer>
        <span>
          <b>
            {match[1]} - {match[2]}
          </b>
          , {match[3]}
        </span>
      </QuestionAnswer>
    </Alternative>
  );
}

function renderNonMatchingLine(line, index) {
  return (
    <Alternative key={index}>
      <span>{line}</span>
    </Alternative>
  );
}
