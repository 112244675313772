import React from "react";

export function GynecAndObstetricsIcon() {
  return (
    <svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2675_754)">
        <path d="M10.1739 6.1875C10.1739 3.39258 7.89538 1.125 5.08696 1.125C2.27853 1.125 0 3.39258 0 6.1875C0 8.5957 1.69212 10.6137 3.95652 11.1234V12.9375H2.68478C2.45163 12.9375 2.26087 13.1273 2.26087 13.3594V14.7656C2.26087 14.9977 2.45163 15.1875 2.68478 15.1875H3.95652V16.4531C3.95652 16.6852 4.14728 16.875 4.38043 16.875H5.79348C6.02663 16.875 6.21739 16.6852 6.21739 16.4531V15.1875H7.48913C7.72228 15.1875 7.91304 14.9977 7.91304 14.7656V13.3594C7.91304 13.1273 7.72228 12.9375 7.48913 12.9375H6.21739V11.1234C8.48179 10.6137 10.1739 8.5957 10.1739 6.1875ZM2.26087 6.1875C2.26087 4.63711 3.52908 3.375 5.08696 3.375C6.64484 3.375 7.91304 4.63711 7.91304 6.1875C7.91304 7.73789 6.64484 9 5.08696 9C3.52908 9 2.26087 7.73789 2.26087 6.1875Z" fill="#69BD35" />
      </g>
      <defs>
        <clipPath id="clip0_2675_754">
          <rect width="10.1739" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
