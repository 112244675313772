export function getQuestionTitle(questionTitle, questionCode) {
  const doc = document.createElement('div')
  doc.innerHTML = questionTitle
  const notFormattedQuestionTitle = doc.children[0].innerHTML
  const formattedQuestionTitle = notFormattedQuestionTitle.replace(
    /([()])/g,
    ''
  )

  const quentionNumber = questionCode.split('Q')
  const number = quentionNumber[quentionNumber.length - 1].match(/\d+/)[0]

  return formattedQuestionTitle + ' ' + 'Q' + number
}