import styled from 'styled-components'
import { colors } from '../../../../components/library/theme';
export const Container = styled.div`
  width: 100%;
  height: 100vh;
  background-color: ${colors.interface.white000};
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const Header = styled.header`
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.status.green250};

  img {
    filter: brightness(100);
  }
`

export const CongratulationsMessage = styled.p`
  text-align: center;
  font-weight: 600;
  font-size: 32px;
  color: ${colors.interface.grey500};
  line-height: 41.6px; 

  @media (max-width: 992px) {
    font-size: 24px;
    line-height: 31.2px;
  }
`
export const Submessage = styled.p`
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 23.4px; 
  margin-top: 16px;
`
export const ButtonContainer = styled.div`
  width: fit-content;
  display: flex;
  margin: 0 auto;
  margin-top: 40px;
`

export const PersonImg = styled.img`
  max-width: 460px;
  z-index: 2;

  @media (max-width: 992px) {
    max-width: 330px;
  }
`
export const ConfettiImg = styled.img`
  position: absolute;
  width: 100%;
  left: 50%;
  bottom: -10%;
  transform: translateX(-50%);
  z-index: 1;
`
export const Wrapper = styled.div`
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 64px;
  flex: 1;

  @media (max-width: 1440px) {
    gap: 32px;
  }
`