import * as Dialog from "@radix-ui/react-dialog";
import { css } from "glamor";
import React, { useCallback, useReducer, useState } from "react";
import { IoAlertCircle, IoBook, IoCheckmark } from "react-icons/io5";
import { Button, Icon, Modal } from "semantic-ui-react";
import styled from "styled-components";
import { AnnotationModal } from "../../componentsV2/AnnotationModal";
import { EMRButton } from "../../componentsV2/Button";
import { useErrorNotebook } from "../../context/errorNotebook";
import { spacing, uiColors } from "../library/theme";
import ReportErrorModalFormContainer from "../reportError/ReportErrorModalFormContainer";
import { trackAnsweredQuestionMockEvent } from "../../../services/mixpanel/events/trackAnsweredQuestionMockEvent";
import { connect } from "react-redux";
import { getMockTemplateById } from "../../selectors/mockTemplateSelectors";
import { FavoriteButton } from "./favoriteButton";

const flex = { display: "flex" };
const container = {
  ...flex,
  justifyContent: "space-between",
  margin: `${spacing.m} 0`,
  paddingBottom: spacing.m,
  borderBottom: `1px solid ${uiColors.border}`
};
const buttonsWrapper = css({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  gap: spacing.xs,

  "@media (max-width: 1200px)": {
    display: "grid",
    gridTemplateColumns: "1fr 1fr"
  },

  "@media (max-width: 600px)": {
    display: "grid",
    gridTemplateColumns: "1fr"
  }
});

const buttonsWrapperWhenPreExam = css({
  display: "flex",
  justifyContent: "center",
  gap: spacing.xs
});

const warningStyle = {
  textAlign: "center",
  paddingBottom: spacing.m,
  borderBottom: `1px solid ${uiColors.border}`,
  marginBottom: spacing.m
};

const StyledButton = styled(EMRButton)`
  padding: 0 16px;

  @media (max-width: 1200px) {
    width: 100%;
  }
`;

function modalReducer(state, action) {
  switch (action.type) {
    case "CLOSE_MODAL":
      return { open: false };
    case "OPEN_MODAL":
      return { open: true };
    default:
      throw new Error("Unsupported action...");
  }
}

export const FinishConfirmationModal = ({
  finishMockHandler,
  open,
  dispatch
}) => {
  const closeAction = useCallback(() => {
    dispatch({ type: "CLOSE_MODAL" });
  }, [dispatch]);

  const handleFinish = useCallback(() => {
    finishMockHandler();
    closeAction();
  }, [finishMockHandler, dispatch]);

  return (
    <Modal size="mini" open={open} onClose={closeAction}>
      <Modal.Header>Finalizar Simulado</Modal.Header>
      <Modal.Content>
        <p>
          Deseja encerrar o simulado? Não será possível editar ou criar novas
          respostas depois de finalizado.
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={closeAction}>
          Cancelar
        </Button>
        <Button positive onClick={handleFinish}>
          Confirmar
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

function QuestionNavigation({
  currentIndex,
  questionsLength,
  goToNextQuestionHandler,
  goToPrevQuestionHandler,
  finishMockHandler,
  markAsFavoriteHandler,
  createAnswerHandler,
  isAnswered,
  isMarkedAsFavorite,
  isFinished,
  isExam,
  isAlternativeMode,
  selectedAlternative,
  openReportErrorModal,
  toggleReportErrorModal,
  mock,
  questionId,
  dispatch,
  question,
  isPreExam,
  isFrialUser
}) {
  const { isQuestionAnswered } = useErrorNotebook();
  const [annotationModalOpen, setAnnotationModalOpen] = useState(false);
  const [modalState, modalDispatch] = useReducer(modalReducer, {
    open: false
  });
  const { open } = modalState;
  const isButtonDisabled =
    question.questionType === "alternatives"
      ? !isAnswered?.alternativeId
      : !isQuestionAnswered(question.id);

  const openConfirmation = useCallback(() => {
    modalDispatch({ type: "OPEN_MODAL" });
  }, [modalDispatch]);

  return (
    <>
      <ReportErrorModalFormContainer
        mockId={mock?.id}
        questionId={questionId}
        open={openReportErrorModal}
        toggleReportErrorModal={toggleReportErrorModal}
      />
      <FinishConfirmationModal
        open={open}
        finishMockHandler={finishMockHandler}
        dispatch={modalDispatch}
      />
      <div style={container}>
        <Button
          onClick={goToPrevQuestionHandler}
          disabled={currentIndex === 0}
          icon={<Icon name="angle left" />}
        ></Button>

        <div className={isPreExam ? buttonsWrapperWhenPreExam : buttonsWrapper}>
          <StyledButton
            onClick={toggleReportErrorModal}
            fontSize="md"
            mode="gray"
          >
            <IoAlertCircle size={24} />
            Reportar erros
          </StyledButton>

          {!isPreExam && (
            <FavoriteButton
              questionId={questionId}
              markAsFavoriteHandler={markAsFavoriteHandler}
              isMarkedAsFavorite={isMarkedAsFavorite}
              disabled={isButtonDisabled}
              mockTemplateName={mock.title}
              isFrialUser={isFrialUser}
            />
          )}

          {!isPreExam && (
            <StyledButton
              mode="outline"
              fontSize="md"
              style={{ fontWeight: 600 }}
              onClick={() => setAnnotationModalOpen(true)}
              disabled={isButtonDisabled}
            >
              <IoBook size={24} />
              Caderno de erros
            </StyledButton>
          )}

          {!isFinished && (
            <>
              {isAlternativeMode && !isExam && (
                <StyledButton
                  disabled={isAnswered || !selectedAlternative}
                  onClick={(e) => {
                    createAnswerHandler(e);
                    trackAnsweredQuestionMockEvent({
                      mockName: mock.title,
                      progress: mock.progress,
                      score: mock.score,
                      studyTime: "",
                      questionId: questionId,
                      questionNumber: currentIndex + 1,
                      isFrialUser
                    });
                  }}
                  fontSize="md"
                >
                  <IoCheckmark size={24} />
                  {isAnswered ? "Resposta Salva" : "Responder"}
                </StyledButton>
              )}

              {isExam && (
                <StyledButton
                  onClick={openConfirmation}
                  mode="blue"
                  fontSize="md"
                >
                  <IoCheckmark size={24} />
                  Finalizar simulado
                </StyledButton>
              )}
            </>
          )}
        </div>

        <Button
          onClick={goToNextQuestionHandler}
          disabled={questionsLength - 1 === currentIndex}
          icon={<Icon name="angle right" />}
        ></Button>
      </div>
      {!isFinished && (
        <p style={warningStyle}>
          <small>
            Ao finalizar o simulado, não será possível editar nem criar novas
            respostas.
          </small>
        </p>
      )}

      {annotationModalOpen && (
        <Dialog.Root
          open={annotationModalOpen}
          onOpenChange={setAnnotationModalOpen}
        >
          <AnnotationModal
            onClose={() => setAnnotationModalOpen(false)}
            dispatch={dispatch}
            question={question}
            mockId={mock?.id}
            questionId={questionId}
            onSuccess={() => setAnnotationModalOpen(false)}
          />
        </Dialog.Root>
      )}
    </>
  );
}

function mapStateToProps(state, ownProps) {
  const mockTemplate = getMockTemplateById(state, ownProps.mock.mockTemplateId);
  const isPreExam = mockTemplate ? mockTemplate.preExam : false;

  return {
    isPreExam,
    ...ownProps
  };
}

export default connect(mapStateToProps)(QuestionNavigation);
