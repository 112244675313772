import styled, { css } from "styled-components";
import { colors } from "../../../library/theme";

const answerTypeVariations = (color = "") => ({
  regular: css`
    ::before {
      background: ${colors.interface.grey500};
      border: 2px solid ${colors.interface.grey500};
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  `,

  rightAnswer: css`
    border: 2px solid ${colors.status.green200};
    box-shadow: 2.2px 2.2px 2px 0px ${colors.interface.grey100};

    ::before {
      background: ${colors.status.green200};
      border: 2px solid ${colors.status.green200};
    }
  `,

  custom: css`
    border: 2px solid ${color};
    box-shadow: 2.2px 2.2px 2px 0px ${colors.interface.grey100};

    ::before {
      background: ${color};
      border: 2px solid ${color};
    }
  `,

  wrongAnswer: css`
    border: 2px solid ${colors.status.red200};
    box-shadow: 2.2px 2.2px 2px 0px ${colors.interface.grey100};

    ::before {
      background: ${colors.status.red200};
      border: 2px solid ${colors.status.red200};
    }
  `
});

export const SelectedAlternativeContainer = styled.li`
  display: flex;
  gap: 24px;
  list-style: none;
  align-items: center;
  font-size: 14px;
  width: 100%;
  padding: 24px 0;
  padding-left: 70px;
  padding-right: 24px;
  border-radius: 4px;
  border: 1px solid ${colors.interface.grey100};
  box-shadow: 1.2px 1.2px 1px 0px ${colors.interface.grey100};
  overflow: hidden;
  text-align: left;
  position: relative;

  ::before {
    content: ${(props) =>
    props.letter ? `"${props.letter}"` : "counter(list, upper-alpha)"};
    counter-increment: list;
    position: absolute;
    inset: 0;
    width: 56px;
    color: ${colors.interface.white000};
    font-weight: 600;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  + li {
    margin-top: 20px;
  }

  ${(props) =>
    answerTypeVariations(props.customColor)[props.styleType || "regular"]}
`;
