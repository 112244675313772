import React, { useEffect, useRef, useState } from "react";
import { QuestionDescriptionContainer } from "./styles";
import ReactHtmlParser from "react-html-parser";
import HighlightTooltip from "./HighlightTooltip";
import HighlightsStorage from "./HighlightsStorage";
import Highlighter from "web-highlighter";
import { COLOR_MAPPER, DEFAULT_COLOR, HighlightRangePosition, SELECTION_TOOLTIP_ID } from "./highlighterConstants";
import { HighlightColors } from './highlightColors';
import { connect, shallowEqual } from "react-redux";
import { getHighlightPosition, getRangePosition, transform } from "./utils";
import QuestionAlternativesListContainer from "../QuestionAlternativesListContainer";
import QuestionsListContainer from "../QuestionsListContainer";

/**
 * QuestionDescription component to display question body including description and alternatives.
 *
 * @param {Object} props - Component properties.
 * @param {string} props.questionId - The ID of the question.
 * @param {string} props.description - The description of the question.
 * @param {"text" | "alternatives"} props.questionType - The type of the question.
 * @param {boolean} props.isFinished - Indicates if the question is finished.
 * @param {boolean} props.isExam - Indicates if the question is part of an exam.
 * @param {boolean} props.isHighlighterActive - Indicates if the highlighter tool is active.
 * @param {"yellow" | "red" | "green" | "white"} props.highlightColor - The color used for highlighting.
 * @param {boolean} props.clearHighlightsTriggered - Indicates if clear highlights action is triggered.
 * @param {Function} props.dispatch - Redux dispatch function.
 * @param {string} props.mockId - The ID of the mock.
 * @returns {JSX.Element} The rendered component.
 */
function QuestionDescription({
  questionId,
  description,
  questionType,
  isFinished,
  isExam,
  isHighlighterActive,
  highlightColor,
  clearHighlightsTriggered,
  mockId,
  dispatch,
}) {
  const selectedColorRef = useRef(highlightColor);
  const [highlightTooltips, setHighlightTooltips] = useState([]);
  const [selectionRange, setSelectionRange] = useState(null);
  const highlighterRef = useRef(null);
  const store = useRef(new HighlightsStorage()).current;
  const hasStoredHighlights = store.getStoredQuestionHighlights(questionId).length > 0;
  const isHighlighterActiveRef = useRef(isHighlighterActive);
  const questionDescriptionRef = useRef(null);

  function hideTooltips() {
    setHighlightTooltips((prev) => {
      if (prev.some((tooltip) => tooltip.isVisible)) {
        return prev.map((tooltip) => ({ ...tooltip, isVisible: false }));
      }
      return prev;
    });
  }

  /**
   * Delete a highlight by ID.
   *
   * @param {string} id - The ID of the highlight to delete.
   */
  function handleDeleteHighlight(id) {
    setHighlightTooltips((prev) => prev.filter((tooltip) => tooltip.id !== id));
    highlighterRef.current?.remove(id);
  }

  /**
   * Add a highlight from a selection range and make the highlighter active.
   */
  function handleAddHighlightFromRange() {
    highlighterRef.current?.fromRange(selectionRange);
    window.getSelection().removeAllRanges();
    setSelectionRange(null);

    dispatch({
      type: "MOCK_QUESTION_TOGGLE_HIGHLIGHTER",
      isHighlighterActive: true,
    });
  }

  /**
   * Copy the highlighted text to the clipboard.
   *
   * @param { string } id - The ID of the highlight text to be copied.
   */
  function handleCopyHighlightedText(id) {
    const highlightDoms = highlighterRef.current.getDoms(id);
    const highlightText = highlightDoms.map((highlightDom) => highlightDom.innerText).join("");
    hideTooltips();
    navigator.clipboard.writeText(highlightText);
  }

  /**
   * Toggle the visibility of a tooltip by highlight ID.
   *
   * @param {Object} param - Object parameter.
   * @param {string} param.highlightId - The ID of the highlight.
   */
  function toggleTooltipVisibility({ highlightId }) {
    setHighlightTooltips((prev) => {
      const highlightDoms = highlighterRef.current.getDoms(highlightId);
      const position = getHighlightPosition(highlightDoms);
      const hasTooltip = prev.find((tooltip) => tooltip.id === highlightId);

      if (hasTooltip) {
        return (
          prev.map((tooltip) =>
            tooltip.id === highlightId
              ? {
                ...tooltip,
                top: position.top,
                left: position.left,
                width: position.width,
                isVisible: !tooltip.isVisible,
              }
              : { ...tooltip, isVisible: false }
          )
        )
      }

      const tooltip = {
        id: highlightId,
        top: position.top,
        left: position.left,
        width: position.width,
        isDeleteTooltip: true,
        isVisible: true,
      };

      return ([...prev, tooltip])
    })
  }

  function clearSelectionTooltip() {
    setHighlightTooltips((prev) => {
      if (prev.some((tooltip) => tooltip.id === SELECTION_TOOLTIP_ID)) {
        return prev.filter((tooltip) => tooltip.id !== SELECTION_TOOLTIP_ID);
      }
      return prev;
    });

    setSelectionRange(null);
  }

  useEffect(() => {
    selectedColorRef.current = highlightColor;
    highlighterRef.current?.setOption({
      style: {
        className: COLOR_MAPPER[highlightColor].className,
      },
    });
  }, [highlightColor]);

  useEffect(() => {
    highlighterRef.current?.setOption({
      style: {
        className: COLOR_MAPPER[highlightColor].className,
      },
    });
  }, [isHighlighterActive]);

  useEffect(() => {
    if (clearHighlightsTriggered) {
      highlighterRef.current?.removeAll();
      setHighlightTooltips([]);

      dispatch({
        type: "MOCK_QUESTION_CLEAR_HIGHLIGHTS",
        clearHighlightsTriggered: false,
      });
    }
  }, [clearHighlightsTriggered]);

  useEffect(() => {
    const storedQuestionHighlights = store.getStoredQuestionHighlights(questionId);
    const highlighter = new Highlighter({
      $root: document.getElementById(questionId),
      exceptSelectors: ["b", "img", "button", "a"],
      style: {
        className: COLOR_MAPPER[selectedColorRef.current].className,
      },
    });
    highlighterRef.current = highlighter;

    storedQuestionHighlights.forEach(({ highlightSource }) =>
      highlighter.fromStore(
        highlightSource.startMeta,
        highlightSource.endMeta,
        highlightSource.text,
        highlightSource.id,
        highlightSource.extra
      )
    );

    highlighter.getDoms().forEach((highlightElement) => {
      const highlightId = highlighter.getIdByDom(highlightElement);
      const storedHighlight = storedQuestionHighlights.find(
        (storedHighlight) => storedHighlight.highlightSource.id === highlightId
      );
      const storedHighlightColor = storedHighlight?.highlightSource.extra.color;
      const storedHighlightPosition = storedHighlight?.highlightSource.extra.position;
      const tooltip = {
        id: highlightId,
        top: storedHighlightPosition?.top ?? 0,
        left: storedHighlightPosition?.left ?? 0,
        width: storedHighlightPosition?.width ?? 0,
        isDeleteTooltip: true,
        isVisible: false,
      };

      highlightElement.className = storedHighlightColor || DEFAULT_COLOR.className;

      setHighlightTooltips((prev) => {
        if (prev.find((tooltip) => tooltip.id === highlightId)) {
          return prev;
        }

        return [...prev, tooltip];
      });
    });

    highlighter
      .on(Highlighter.event.CLICK, ({ id }) => {
        toggleTooltipVisibility({
          highlightId: id,
        });
      })
      .on(Highlighter.event.CREATE, ({ sources }) => {
        const storedQuestionHighlights = store.getStoredQuestionHighlights(questionId);
        const [firstSource, lastSource] = [sources[0], sources[sources.length - 1]];
        const [firstStartOffset, firstParentIndex] = [firstSource.startMeta.textOffset, firstSource.startMeta.parentIndex];
        const [lastEndOffset, lastParentIndex] = [lastSource.endMeta.textOffset, lastSource.endMeta.parentIndex];

        let storedHighlights = [];
        let rangePosition = HighlightRangePosition.ISOLATED;

        storedQuestionHighlights.every(
          ({ highlightSource }) => {
            const {
              startMeta: { textOffset: startOffset, parentIndex: startParentIndex },
              endMeta: { textOffset: endOffset, parentIndex: endParentIndex },
              extra: { color: storedColor },
            } = highlightSource;

            const hasSameParent = firstParentIndex === startParentIndex && lastParentIndex === endParentIndex
              && firstParentIndex === lastParentIndex;
            const isWithinParentRange = firstParentIndex < startParentIndex && lastParentIndex > endParentIndex
              || (firstParentIndex <= startParentIndex && lastParentIndex > endParentIndex && firstStartOffset <= startOffset)
              || (firstParentIndex < startParentIndex && lastParentIndex >= endParentIndex && lastEndOffset >= endOffset);
            const isSameColor = COLOR_MAPPER[selectedColorRef.current].className === storedColor;

            const isExactMatch = firstStartOffset === startOffset && lastEndOffset === endOffset && hasSameParent;
            const isInsideRange = firstStartOffset >= startOffset && lastEndOffset <= endOffset && hasSameParent
              || (firstParentIndex > startParentIndex && lastParentIndex < endParentIndex)
              || (firstParentIndex >= startParentIndex && lastParentIndex < endParentIndex && firstStartOffset >= startOffset)
              || (firstParentIndex > startParentIndex && lastParentIndex <= endParentIndex && lastEndOffset <= endOffset);
            const containsRange = (firstStartOffset <= startOffset && lastEndOffset >= endOffset && hasSameParent) || isWithinParentRange;

            if (isExactMatch) {
              rangePosition = HighlightRangePosition.EXACT;
              storedHighlights.push(highlightSource);
              return false;
            }

            if (isInsideRange && isSameColor) {
              rangePosition = HighlightRangePosition.INSIDE;
              return true;
            }

            if (containsRange) {
              rangePosition = HighlightRangePosition.CONTAINS;
              storedHighlights.push(highlightSource);
              return true;
            }

            if (isInsideRange && !isSameColor) {
              rangePosition = HighlightRangePosition.ISOLATED;
              return false;
            }

            return true
          }
        );

        if (rangePosition === HighlightRangePosition.EXACT) {
          storedHighlights.forEach(storedHighlight => {
            highlighterRef.current?.remove(storedHighlight.id);
          })
        }

        if (rangePosition === HighlightRangePosition.CONTAINS) {
          storedHighlights.forEach(storedHighlight => {
            highlighterRef.current?.remove(storedHighlight.id);
          })
        }

        if (rangePosition === HighlightRangePosition.INSIDE) {
          sources.forEach(source => {
            highlighterRef.current?.remove(source.id);
          })
          clearSelectionTooltip();

          return
        }

        sources.forEach((source) => {
          const highlightDoms = highlighter.getDoms(source.id);
          const position = getHighlightPosition(highlightDoms);
          const tooltip = {
            id: source.id,
            top: position.top,
            left: position.left,
            width: position.width,
            isDeleteTooltip: true,
            isVisible: false,
          };

          store.save(
            sources.map((source) => ({
              highlightSource: {
                id: source.id,
                startMeta: source.startMeta,
                endMeta: source.endMeta,
                text: source.text,
                extra: {
                  color: COLOR_MAPPER[selectedColorRef.current].className,
                  questionId,
                  position,
                },
              },
            }))
          );

          if (isHighlighterActiveRef.current) {
            setHighlightTooltips((prev) => [...prev, tooltip]);
            return;
          }

          setHighlightTooltips((prev) => [
            ...prev
              .filter((tooltip) => tooltip.isDeleteTooltip)
              .map((_tooltip) => ({ ..._tooltip, isVisible: false })),
            tooltip,
          ]);
        });
      })
      .on(Highlighter.event.REMOVE, ({ ids }) => {
        ids.forEach((id) => store.remove(id));
      });
  }, []);

  useEffect(() => {
    isHighlighterActiveRef.current = isHighlighterActive;

    if (isHighlighterActive) {
      highlighterRef.current?.run();
    } else {
      highlighterRef.current?.stop();
    }
  }, [isHighlighterActive]);

  useEffect(() => {
    dispatch({
      type: "MOCK_QUESTION_CHECK_FOR_STORED_HIGHLIGHTS",
      hasStoredHighlights: store.getStoredQuestionHighlights(questionId).length > 0,
    });
  }, [hasStoredHighlights]);

  useEffect(() => {
    /**
     * Handles the 'mousedown' event on the document.
     *
     * This function performs the following tasks based on the target element of the event:
     * - If the target element has the class "highlight-control-btn", it does nothing.
     * - If the target element is a highlighter element, it does nothing.
     * - Otherwise, it hides tooltips.
     *
     * @param {MouseEvent} e - The 'mousedown' event object.
     */
    function handleDocumentMouseDown(e) {
      const isTooltipElement =
        e.target instanceof HTMLElement &&
        e.target.classList.contains("highlight-control-btn");
      const isHighlighterElement =
        e.target instanceof HTMLElement && e.target.dataset.highlightId;

      if (isTooltipElement) {
        return;
      }

      clearSelectionTooltip();

      if (isHighlighterElement) {
        return;
      }

      hideTooltips();
    }

    function handleScroll() {
      hideTooltips();

      if (!isHighlighterActiveRef.current) {
        clearSelectionTooltip();
      }
    }

    function handleSelection(event) {
      if (isHighlighterActiveRef.current) {
        return
      }

      const selection = event.target.getSelection();

      if (!selection || selection.rangeCount === 0) {
        return;
      }

      const range = selection.getRangeAt(0);
      const isEmptySelection = range.toString().trim() === "";
      const isRangeOutsideQuestionDescription = !questionDescriptionRef.current?.contains(range.commonAncestorContainer);

      if (selection.isCollapsed || isEmptySelection || isRangeOutsideQuestionDescription) {
        clearSelectionTooltip();
        return
      }

      const selectionPosition = getRangePosition(range);
      const selectionTooltip = {
        id: SELECTION_TOOLTIP_ID,
        top: selectionPosition.top,
        left: selectionPosition.left,
        width: selectionPosition.width,
        isDeleteTooltip: false,
        isVisible: true,
      };

      setSelectionRange(range)
      setHighlightTooltips((prev) => {
        const hasTempTooltip = prev.find((tooltip) => tooltip.id === SELECTION_TOOLTIP_ID);

        if (hasTempTooltip) {
          return (
            prev.map((tooltip) =>
              tooltip.id === SELECTION_TOOLTIP_ID
                ? {
                  ...tooltip,
                  top: selectionTooltip.top,
                  left: selectionTooltip.left,
                  width: selectionTooltip.width,
                }
                : tooltip
            )
          )
        }

        return ([...prev, selectionTooltip])
      })
    }

    document.addEventListener("wheel", handleDocumentMouseDown);
    document.addEventListener("scroll", handleDocumentMouseDown);
    document.addEventListener("mousedown", handleDocumentMouseDown);
    document.addEventListener("selectionchange", handleSelection);

    return () => {
      document.removeEventListener("wheel", handleScroll);
      document.removeEventListener("scroll", handleScroll);
      document.removeEventListener("mousedown", handleDocumentMouseDown);
      document.removeEventListener("selectionchange", handleSelection);
    };
  }, []);

  return (
    <>
      <HighlightColors />

      <div ref={questionDescriptionRef} id={questionId}>
        <QuestionDescriptionContainer>
          {ReactHtmlParser(description, { transform })}
        </QuestionDescriptionContainer>

        {questionType === "alternatives" && (
          <QuestionAlternativesListContainer
            questionId={questionId}
            isFinished={isFinished}
            isExam={isExam}
          />
        )}

        {questionType === "text" && (
          <QuestionsListContainer
            mockId={mockId}
            questionId={questionId}
            isFinished={isFinished}
            isExam={isExam}
          />
        )}
      </div>

      {highlightTooltips.map((tooltip) => (
        <HighlightTooltip
          key={tooltip.id}
          data-visible={tooltip.isVisible}
          top={tooltip.top}
          left={tooltip.left}
          width={tooltip.width}
          id={tooltip.id}
          isDeleteTooltip={tooltip.isDeleteTooltip}
          onAddHighlight={() => handleAddHighlightFromRange(tooltip.id)}
          onDeleteHighlight={() => handleDeleteHighlight(tooltip.id)}
          onCopyHighlightedText={() => handleCopyHighlightedText(tooltip.id)}
        />
      ))}
    </>
  );
}

/**
 * Map the Redux state to the props for the TextHighlighter component.
 *
 * @returns {{ 
*  isHighlighterActive: boolean,
*  highlightColor: "yellow" | "red" | "green" | "white",
*  clearHighlightsTriggered: boolean,
*  }}
*/
function mapStateToProps(state) {
  const { isHighlighterActive, highlightColor, clearHighlightsTriggered } = state.mock;

  return {
    isHighlighterActive,
    highlightColor,
    clearHighlightsTriggered,
  };
}

const areStatesEqual = (next, prev) =>
  shallowEqual(next.mock.isHighlighterActive, prev.mock.isHighlighterActive) &&
  shallowEqual(next.mock.highlightColor, prev.mock.highlightColor) &&
  shallowEqual(next.mock.clearHighlightsTriggered, prev.mock.clearHighlightsTriggered);


export default connect(mapStateToProps, null, null, {
  pure: true,
  areStatesEqual,
})(QuestionDescription);
