export async function requestUpdateIp(fingerprint) {
  const response = await fetch(`${process.env.USER_PROXY_URL}/devices`, {
    method: "PUT",
    headers: {
      "api-token": process.env.USER_PROXY_API_TOKEN,
      "Content-Type": "application/json",
      "access-date": new Date().toISOString(),
      ignore_ip: ignoreIp,
      product: "PLATAFORMA_EMR",
    },
  });
  return response;
}
