const initialState = {
  loading: false,
  examTypeCategoryId: null,
  examTypeSlug: null,
  organizerId: null,
  organizers: {},
};

const normalize = (action) => {
  return { [action.organizerId]: action.subjects };
};

export default function organizerStatisticsReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case "ORGANIZER_STATISTICS_FETCH_REQUESTED":
      return { ...state, loading: true };
    case "ORGANIZER_CHANGED":
      return { ...state, organizerId: action.organizerId };
    case "EXAM_TYPE_CATEGORY_CHANGED":
      return {
        ...state,
        examTypeCategoryId: action.examTypeCategoryId,
        examTypeSlug: null,
      };
    case "EXAM_TYPE_SLUG_CHANGED":
      return {
        ...state,
        examTypeSlug: action.examTypeSlug,
      };
    case "ORGANIZER_STATISTICS_FETCHED":
      return {
        ...state,
        loading: false,
        organizers: { ...state.organizers, ...normalize(action) },
      };
    default:
      return state;
  }
}
