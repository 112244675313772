import { apiGet } from "./helpers/api";
import { normalize, schema } from "normalizr";
import { setCookie } from "cookies-next";
const classroom = new schema.Entity("classrooms");

export const fetchClassroms = (dispatch) => {
  dispatch({ type: "CLASSROOMS_FETCH_REQUESTED" });
  apiGet("/api/v1/classrooms/").then((response) => {
    const data = response.body.data;

    const date = new Date()
    const dateTime = date.getTime() + (1000 * 60 * 60)

    const hasBases = data.some((data) =>{
      if( data != null){
        data.name.toLowerCase().includes("bases")
      }
   } );

    setCookie(process.env.METRICO_EXTENSIVO_BASES_COOKIE, hasBases, {
      domain: process.env.DOMAIN_URL,
      expires: new Date(dateTime)
    });

    dispatch({
      type: "CLASSROOMS_FETCHED",
      ...normalize(response.body.data, new schema.Array(classroom))
    });
  });
};
