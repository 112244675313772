const initialState = {
  isFirstAccess: null,
  medtrackEnabled: false,
  activeMedtrackType: null,
  hasRevisionMedtrack: false,
  hasDefaultMedtrack: false,
  hasRevisionSchedule: false,
  hasDefaultSchedule: false,
  userMedtrackOptions: [],
};

export default function medTrackReducer(state = initialState, action) {
  switch (action.type) {
    case "IS_FIRST_ACCESS": {
      return {
        ...state,
        isFirstAccess: true,
      };
    }
    case "ALLOW_ACCESS": {
      return {
        ...state,
        isFirstAccess: false,
      };
    }
    case "MEDTRACK_ENABLED": {
      return {
        ...state,
        medtrackEnabled: action.payload,
      };
    }
    case "MEDTRACK_ENABLED_REVISION": {
      return {
        ...state,
        hasRevisionMedtrack: action.payload,
      };
    }
    case "MEDTRACK_ENABLED_DEFAULT": {
      return {
        ...state,
        hasDefaultMedtrack: action.payload,
      };
    }
    case "MEDTRACK_TYPE": {
      const {
        activeMedtrackType,
        userMedtrackOptions,
        hasRevisionMedtrack,
        hasDefaultMedtrack,
      } = action.payload;

      const newState = {
        ...state,
        activeMedtrackType: state.activeMedtrackType,
        userMedtrackOptions: state.userMedtrackOptions,
        hasRevisionMedtrack: state.hasRevisionMedtrack,
        medtrackEnabled: state.medtrackEnabled,
        hasDefaultMedtrack: state.hasDefaultMedtrack,
      };

      if (activeMedtrackType !== undefined) {
        newState.activeMedtrackType = activeMedtrackType;
        newState.isBases = activeMedtrackType === "bases";
        newState.isR3CM = activeMedtrackType === "extensivo_r+_cm";
        newState.isRevision = activeMedtrackType === "revisao" || activeMedtrackType === "revisao_r+_cm";
        newState.medtrackEnabled = activeMedtrackType !== null;
      }

      if (userMedtrackOptions !== undefined) {
        newState.userMedtrackOptions = userMedtrackOptions;
      }

      if (hasRevisionMedtrack !== undefined) {
        newState.hasRevisionMedtrack = hasRevisionMedtrack;
      }

      if (hasDefaultMedtrack !== undefined) {
        newState.hasDefaultMedtrack = hasDefaultMedtrack;
      }

      return newState;
    }
    case "HAS_REVISION_SCHEDULE": {
      return {
        ...state,
        hasRevisionSchedule: action.payload,
      };
    }
    case "HAS_DEFAULT_SCHEDULE": {
      return {
        ...state,
        hasDefaultSchedule: action.payload,
      };
    }
    default:
      return state;
  }
}
