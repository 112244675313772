import React, { useState, useEffect } from "react";
import moment from "moment";
import { spacing, uiColors } from "../library/theme";

const container = {
  display: "flex",
  justifyContent: "space-between",
  paddingBottom: spacing.m,
  borderBottom: `1px solid ${uiColors.border}`,
};

export default function MockTimer({ currentTime, endsAt, onEnd }) {
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    const parsedCurrentTime = moment(currentTime).utc();
    const parsedEndsAt = moment(endsAt).utc();
    if (!currentTime && !endsAt) return;
    const diffTime = parsedEndsAt - parsedCurrentTime;
    const interval = 1000;
    let duration = moment.duration(diffTime, "milliseconds");

    const updateTimer = setInterval(() => {
      let time = duration - interval;
      duration = moment.duration(time, "milliseconds");
      setTimer(
        duration.hours() + ":" + duration.minutes() + ":" + duration.seconds()
      );
      if (time < 0) {
        clearInterval(updateTimer);
        onEnd();
      }
    }, interval);

    return () => {
      clearInterval(updateTimer);
    };
  }, [currentTime, endsAt]);

  return (
    <div style={container}>
      <p>
        <strong>Tempo Restante: </strong>
        {timer}
      </p>
      <p>O simulado será finalizado automaticamente ao final do tempo.</p>
    </div>
  );
}
