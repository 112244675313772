import React, { useState } from 'react';
import { formValueSelector, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { createMock } from '../../processes/mockProccess';
import MockModalForm from './MockModalForm';
import { getConfig } from '../../selectors/configSelectors';
import { getOrganizersFromStates } from '../../selectors/organizerSelectors';
import { getSubjectsFromSpecialities } from '../../selectors/subjectsSelectors';
import { fetchAvailableQuestionsCount } from '../../processes/questionProccess';
import debounce from '../../utils/debounce';
import { useEffect } from 'react';

const formName = 'mockForm';
const initialValues = {
  quantity: 120,
  questionsType: 'closed',
};

function MockModalFormContainer(props) {
  const { dispatch, initialized, initialize } = props;
  const params = new URLSearchParams(window.location.search);
  const [hasContent, setHasContent] = useState(false);

  useEffect(() => {
    const questionsQty = params.get('questions');
    const subjectIds = params.get('subjects');
    const subjectsFromParam = subjectIds
      ?.split(',')
      .map((subjectId) => Number(subjectId));

    const _hasContent = !!questionsQty || !!subjectIds;
    setHasContent(_hasContent);

    !initialized &&
      initialize({
        quantity: questionsQty || 120,
        questionsType: 'closed',
        subjectsIdIn: subjectsFromParam || undefined,
      });
  }, [dispatch, initialized]);

  return <MockModalForm hasContent={hasContent} {...props} />;
}

function mapStateToProps(state) {
  const selector = formValueSelector(formName);
  const {
    organizersStateIdIn,
    specialitiesIdIn,
    notAnswered,
    onlyAnsweredWrong,
  } = selector(
    state,
    'organizersStateIdIn',
    'specialitiesIdIn',
    'notAnswered',
    'onlyAnsweredWrong'
  );

  const organizers = getOrganizersFromStates(state, organizersStateIdIn);

  return {
    initialValues,
    specialities: getConfig(state, 'specialities'),
    subjects: getSubjectsFromSpecialities(state, specialitiesIdIn),
    states: getConfig(state, 'states'),
    years: getConfig(state, 'years')?.filter((year) => year.id),
    examTypeCategories: getConfig(state, 'examTypeCategories'),
    departments: getConfig(state, 'departments'),
    organizers,
    notAnswered,
    onlyAnsweredWrong,
    formName,
    availableQuestionsCount: state.mockModal.availableQuestionsCount,
    loading: state.mockModal.loading,
  };
}

export default connect(mapStateToProps)(
  reduxForm({
    form: formName,
    onSubmit: createMock,
    onChange: debounce(
      (values, dispatch) => fetchAvailableQuestionsCount(values, dispatch),
      500
    ),
  })(MockModalFormContainer)
);
