import { useState, useLayoutEffect } from "react";

const screenWidths = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};

export function useScreenWidth() {
  const [screenWidth, setScreenWidth] = useState(0);
  useLayoutEffect(() => {
    function updateSize() {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, [screenWidth]);

  return screenWidth;
}

export function makeIsMobile() {
  const screenWidth = useScreenWidth();
  return screenWidth < screenWidths.md;
}
