import React from "react";
import { connect } from "react-redux";
import MockProgress from "./MockProgress";
import { getMockByTemplateType } from "../../selectors/mockTemplateSelectors";
import {
  getMockInProgress,
  getIntelligentMockInProgress,
} from "../../selectors/mockSelectors";

function mapStateToProps(state, ownProps) {
  const { mockTemplateType, mode } = ownProps;

  const getProgress = () =>
    mode === "intelligent"
      ? getIntelligentMockInProgress(state)
      : getMockInProgress(state);

  return {
    mock: mockTemplateType
      ? getMockByTemplateType(state, mockTemplateType)
      : getProgress(),
    activeTabName: state.mock.activeMockTabName,
  };
}

export default connect(mapStateToProps)(MockProgress);
