import React, {
  createContext,
  useContext,
  useState,
} from "react";


const ErrorNotebookContext = createContext();

function ErrorNotebookProvider({ children }) {
  const [openQuestionsStatus, setOpenQuestionsStatus] = useState([]);

  function markQuestionAnswered(parentId){
    const newQuestionStatus = {
      parentId: parentId,
      isAnswered: true
    };
    setOpenQuestionsStatus(prevState => [...prevState,newQuestionStatus])
  }

  function isQuestionAnswered(parentId) {
    const questionStatus = openQuestionsStatus.find(q => q.parentId === parentId);

    return Boolean(questionStatus?.isAnswered)
  }

  const value = {
    openQuestionsStatus,
    setOpenQuestionsStatus,
    markQuestionAnswered,
    isQuestionAnswered
  };

  return (
    <ErrorNotebookContext.Provider value={value}>
      {children}
    </ErrorNotebookContext.Provider>
  );
}

function useErrorNotebook() {
  const context = useContext(ErrorNotebookContext);

  return context;
}

export { ErrorNotebookProvider, useErrorNotebook, ErrorNotebookContext };
