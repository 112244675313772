import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import StatisticsPanel from "./StatisticsPanel";
import { changeOrganizer } from "../../processes/organizerStatatisticProcesses";
import makeDropDownOptions from "../../utils/makeDropDownOptions";
import {
  getOrganizersWithSlug,
  getOrganizerStatistics,
  getOrganizerStatisticsSubjects,
  getTotalQuestionsCount,
  getTotalAlternativesCount,
  getTotalRelevanceInPercentage,
} from "../../selectors/organizerSelectors";
import { orderByAlphabetic } from "../../utils/orderByAlphabetic";
import { changeExamTypeCategory } from "../../processes/organizerStatatisticProcesses";
import { trackStatisticsEvent } from "../../../services/mixpanel/events/trackStatisticsEvent";

function StatisticsPanelContainer(props) {
  const { dispatch, organizer, organizerStatistics, examTypeCategory } = props;
  const { examTypeCategoryId } = organizerStatistics;

  useEffect(() => {
    changeExamTypeCategory(dispatch, examTypeCategory.id);
  }, [dispatch, examTypeCategory]);

  useEffect(() => {
    trackStatisticsEvent({
      tabName: examTypeCategory.name,
    });
  }, [examTypeCategory.name]);

  const changeOrganizerHandler = useCallback(
    (_, { value, options }) => {
      const organizer = options.find((option) => option.value === value);

      const selectedTab = document.querySelector('a.active.item')

      trackStatisticsEvent({
        filterByOrganizers: organizer.text,
        tabName: selectedTab.textContent
      })

      changeOrganizer(dispatch, value);
    },
    [dispatch, organizer]
  );

  const changeExamTypeCategoryHandler = useCallback(
    (id) => {
      changeExamTypeCategory(dispatch, id);
    },
    [dispatch]
  );

  const generalRClickHandler = useCallback(() => {
    changeExamTypeCategory(dispatch, examTypeCategoryId);
  }, [dispatch, examTypeCategoryId]);

  return (
    <StatisticsPanel
      changeOrganizerHandler={changeOrganizerHandler}
      changeExamTypeCategoryHandler={(e) => {
        changeExamTypeCategoryHandler(e);
      }}
      generalRClickHandler={generalRClickHandler}
      examTypeCategory={examTypeCategory}
      {...props}
    />
  );
}

function mapStateToProps(state, ownProps) {
  const organizersData = getOrganizersWithSlug(state);
  const organizerStatistics = getOrganizerStatistics(state);
  const organizerId = organizerStatistics?.organizerId;

  return {
    isRPlus: ownProps?.examTypeCategory?.name == "R+",
    organizers: makeDropDownOptions(organizersData, "", orderByAlphabetic),
    data: getOrganizerStatisticsSubjects(state, organizerId),
    organizerStatistics,
    totalQuestionsCount: getTotalQuestionsCount(state, organizerId),
    totalRelevanceInPercentage: getTotalRelevanceInPercentage(
      state,
      organizerId
    ),
    totalAlternativesCount: getTotalAlternativesCount(state, organizerId),
  };
}

export default connect(mapStateToProps)(StatisticsPanelContainer);
