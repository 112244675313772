import styled from "styled-components";

export const TooltipChartContainer = styled.div`
  color: #fff;
  font-weight: 400;
  font-size: 12px;
  bottom: 30px;
  border-radius: 4px;
  border: solid 1px #606a71;
  padding: 5px 8px;
  z-index: 1;
  max-width: 350px;
  max-height: 100px;
  background-color: #363e46;
  text-align: center;
  transform: translateX(calc(-50% + 10px));

  ::after {
    content: "";
    display: block;
    height: 10px;
    width: 10px;
    border: 1px solid #606a71;
    border-width: 1px 1px 0 0;
    background-color: #363e46;
    transform: rotate(135deg);
    border-top-right-radius: 2px;
    position: absolute;
    bottom: -6px;
    right: calc(50% - 5px);
  }
`;
