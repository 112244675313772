import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import React from "react";
import { hot } from "react-hot-loader/root";
import { Provider } from "react-redux";
import { applyMiddleware, compose, createStore } from "redux";
import applyGlobalStyles from "../components/library/applyGlobalStyles";
import { ErrorNotebookProvider } from "../context/errorNotebook";
import applyDevTools from "../lib/applyDevTools";
import { getSession } from "../processes/sessionProcesses";
import lmsReducer from "../reducers/lmsReducer";
import Routes from "../routes";
import { CookiesConsentBanner } from "../components/CookiesConsentBanner";
Bugsnag.start({
  apiKey: "ce34e41b4b9f93730c931ef9f4cb5cf2",
  plugins: [new BugsnagPluginReact()],
});

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

const currentUserId = getSession()?.currentUserId;
const initialState = { currentUserId };
const browserHistory = createBrowserHistory();
const store = createStore(
  lmsReducer(browserHistory),
  initialState,
  compose(
    applyMiddleware(routerMiddleware(browserHistory)),
    applyDevTools(window.__REDUX_DEVTOOLS_EXTENSION__)
  )
);

applyGlobalStyles();

function LMS() {
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <ErrorNotebookProvider>
          <CookiesConsentBanner />
          <Routes history={browserHistory} />
        </ErrorNotebookProvider>
      </Provider>
    </ErrorBoundary>
  );
}

if (module.hot) {
  module.hot.accept("../routes", () => {
    LMS();
  });
}

export default hot(LMS);
