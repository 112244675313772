import React, { useCallback } from "react";
import { Field } from "redux-form";
import ImageInput from "./ImageInput";
import memoize from "fast-memoize";
import buildFormField from "./buildFormField";
const IMAGE_MIME_TYPE = new RegExp("^image/.*$");

// memoize doesn't work with functions, so the first param is the field name
const toValue = (delegate, files) => {
  if (files && files.length > 0 && files[0].type.match(IMAGE_MIME_TYPE)) {
    delegate(files[0]);
  }
};

const buildValidator = memoize((required, other) => {
  return [(value) => (required && !value ? "required" : undefined)].concat(
    other || []
  );
});

const ImageFieldComponent = buildFormField(ImageInput, (input, meta, rest) => ({
  ...input,
  onChange: useCallback((files) => toValue(input.onChange, files), [
    input.onChange,
  ]),
  onBlur: useCallback((files) => toValue(input.onBlur, files), [input.onBlur]),
  onFileChange: useCallback((files) => toValue(input.onChange, files), [
    input.onChange,
  ]),
  clear: useCallback(() => input.onChange(""), [input]),
  invalid: meta.touched && meta.invalid,
  ...rest,
}));

export default function ImageField(props) {
  return (
    <Field
      {...props}
      type="file"
      component={ImageFieldComponent}
      validate={buildValidator(props.required, props.validate)}
    />
  );
}
