import QRCode from "qrcode";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { apiPost } from "../../../../processes/helpers/api";
import { setSession } from "../../../../processes/sessionProcesses";
import { EMRButton } from "../../../Button";
import { Warning } from "../../../SafetyOnBoarding/warning";
import { Spinner } from "../../../Spinner";
import EmergencyAccessModal from "../../components/EmergencyAccessModal";
import { ACTIVE_SCREEN, EMERGENCY_ACCESS_MODAL_TYPE } from "../../enum";
import {
  Container,
  Content,
  Description,
  Paragraph,
  Title,
  WarningContainer,
} from "../../styles";
import { detectPlatform } from "../../utils/detectPlatform";
import { requestEmergencyAccess } from "../../utils/requestEmergencyAccess";
import { detectOS } from "./detectOS";
import { ButtonWrapper, LoadingContainer, StyledQRCode } from "./styles";

export default function QRCodeGeneration({ location }) {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [QRCodeSrc, setQRCodeSrc] = useState();
  const [emergencyAccessModal, setEmergencyAccessModal] = useState({
    isOpened: false,
    modalType: EMERGENCY_ACCESS_MODAL_TYPE.DEFAULT,
  });

  const ref = useRef(null);

  function handleSecurityPush(activeScreen, props = {}) {
    return history.push("/signin-security", {
      email: location.state.email,
      password: location.state.password,
      fingerprint: location.state.fingerprint,
      activeScreen,
    });
  }

  async function handleToSignInSession() {
    if (location.state.hasEmergencyAccess) return;

    await requestEmergencyAccess(
      location.state.email,
      location.state.fingerprint
    );

    apiPost("/api/v1/auth/sign_in", true, true)
      .send({
        email: location.state.email,
        password: location.state.password,
      })
      .then((res) => {
        setSession(res);
        window.location = "/";
      });
  }

  function handleMovetoValidateCode() {
    handleSecurityPush(ACTIVE_SCREEN.CODE_VALIDATION, {
      hasEmergencyAccess: location.state.hasEmergencyAccess,
      ...location,
    });
  }

  function handleEmergencyAccessModal() {
    setEmergencyAccessModal((prevState) => ({
      ...prevState,
      isOpened: true,
    }));
  }

  function handleMoveToLogin() {
    history.push("signin-v2");
  }

  async function requestCode() {
    const { body } = await apiPost(
      `${process.env.USER_PROXY_URL}/auth/generate-2fa-qrcode`
    )
      .send({
        email: location.state.email,
        fingerprint: location.state.fingerprint,
      })
      .set("api-token", process.env.USER_PROXY_API_TOKEN)
      .set("product", "PLATAFORMA_EMR")
      .set("platform", detectPlatform());
    return body;
  }

  async function generateQRCode() {
    if (!ref.current) return;

    try {
      const response = await requestCode();
      setIsLoading(false);

      if (response?.isValid) {
        return handleSecurityPush(ACTIVE_SCREEN.DEVICE_VALIDATED);
      }

      QRCode.toDataURL(
        `${response?.code};${
          location.state.fingerprint
        };${detectPlatform()};${detectOS()}`
      ).then(setQRCodeSrc);

      setTimeout(() => {
        generateQRCode();
      }, 5000);
    } catch (err) {
      setIsLoading(true);
      setTimeout(() => {
        generateQRCode();
      }, 5000);
    }
  }

  useEffect(() => {
    generateQRCode();
  }, []);

  return (
    <>
      <Container ref={ref}>
        <Content>
          <Description>
            <Title>Confirmação de segurança.</Title>
            <Paragraph>
              Para prosseguir, acesse o aplicativo EMR em seu celular, faça o
              login e vá para “Minha Conta”.{" "}
              <strong>
                Você encontrará a opção “Conectar Dispositivos” para ler o QR
                code abaixo.
              </strong>{" "}
              Não é necessário realizar nenhuma outra ação. Ao liberar o acesso,
              esta página será redirecionada automaticamente.
            </Paragraph>
          </Description>

          {isLoading ? (
            <LoadingContainer>
              <Spinner />
            </LoadingContainer>
          ) : (
            <StyledQRCode src={QRCodeSrc} />
          )}

          <WarningContainer>
            <img src={Warning} />
            <span>
              Caso tenha alguma dificuldade em utilizar o QR code acima, clique
              no botão ”Usar código” para digitar o código de segurança
              manualmente.
            </span>
          </WarningContainer>
        </Content>

        <ButtonWrapper>
          <EMRButton
            fullSized
            mediumWeight
            mode="cancel"
            fontSize={"lg"}
            onClick={handleMoveToLogin}
          >
            Voltar ao login
          </EMRButton>
          <EMRButton
            onClick={handleMovetoValidateCode}
            fullSized
            mediumWeight
            fontSize={"lg"}
          >
            Usar código
          </EMRButton>
        </ButtonWrapper>

        {!location.hasEmergencyAccess && (
          <EMRButton
            style={{ width: "fit-content" }}
            fontSize={"lg"}
            mode="transparent"
            onClick={handleEmergencyAccessModal}
          >
            {"Acesso emergencial"}
          </EMRButton>
        )}
      </Container>

      {emergencyAccessModal.isOpened && (
        <EmergencyAccessModal
          isOpened={emergencyAccessModal.isOpened}
          modalType={emergencyAccessModal.modalType}
          handleToSignInSession={handleToSignInSession}
          handleCloseModal={() => {
            setEmergencyAccessModal((prevState) => ({
              ...prevState,
              isOpened: false,
            }));
          }}
        />
      )}
    </>
  );
}
