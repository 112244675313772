import React from "react";
import { connect } from "react-redux";
import AnnotationsList from "./AnnotationsList";

function AnnotationsListContainer(props) {
  return <AnnotationsList {...props} />;
}

function mapStateToProps(_, ownProps) {
  return {
    annotations: ownProps.annotations,
  };
}

export default connect(mapStateToProps)(AnnotationsListContainer);
