import React, { useEffect } from "react";
import { Button, Container, Text } from "./styles";
import googleLogo from "./google.svg";
import appleLogo from "./apple.svg";
import { handleUserLogin } from "./loginFunctions";
import { trackLoginWithProvider } from "../../../../../services/mixpanel/events/trackLoginWithProvider";
import { useHistory } from "react-router-dom";
import { toast } from "../../../Toast";

function LoginWith({ isSignIn }) {
  const buttonText = isSignIn ? "Entre com" : "Cadastre-se com";
  const history = useHistory();

  async function handleLoginWithProvider(provider) {
    const { status } = await handleUserLogin(provider.toLowerCase());

    switch (status) {
      case "SUCCESS":
        trackLoginWithProvider({ provider });
        window.location = "/";
        break;
      case "DENIED":
        history.push("/access-denied");
        break;
      case "ERROR":
        toast.error(`Erro ao entrar com ${provider}`);
        break;
    }
  }

  return (
    <Container>
      <Text>Faça login com</Text>
      <Button onClick={() => handleLoginWithProvider("Google")}>
        <img src={googleLogo} />
        <span>{buttonText} o Google</span>
      </Button>
      <Button onClick={() => handleLoginWithProvider("Apple")}>
        <img src={appleLogo} />
        <span>{buttonText} a Apple</span>
      </Button>
    </Container>
  );
}

export default LoginWith;
