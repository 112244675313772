import React, { useCallback, useEffect, useState } from "react";
import { connect, shallowEqual } from "react-redux";
import { getCertificateByClassroom } from "../../selectors/certificateSelectors";
import { currentClassroom } from "../../selectors/classroomSelectors";
import { getCurrentLevel } from "../../selectors/lessonSelectors";
import {
  makeIsCompleted,
  makeRatingWarning,
} from "../../selectors/levelGroupSelectors";
import { getLevelsByLevelGroup } from "../../selectors/levelSelectors";
import CertificateItem from "./CertificateItem";
import LevelGroupListItem from "./LevelGroupListItem";

function LevelGroupListItemContainer(props) {
  const {
    currentLevel,
    levelGroup,
    levels,
    index,
    certificate,
    isTheLastLevelGroup,
    currentClassroom,
    compact,
  } = props;
  const isActive = currentLevel
    ? currentLevel?.levelGroupId === levelGroup?.id
    : index === 0;
  const [active, setActive] = useState(isActive);
  const handleClick = useCallback(() => setActive(!active));

  const renderCertificateByCondition =
    currentClassroom &&
    currentClassroom.meta.hasCertificate &&
    isTheLastLevelGroup &&
    !compact;
  const isCertificateDisabled =
    currentClassroom && currentClassroom.meta.progress < 1;
  const [isCertificateActive, setIsCertificateActive] = useState(false);
  const handleCertificateAccordionClick = useCallback(() => {
    if (isCertificateDisabled) return;

    setIsCertificateActive(!isCertificateActive);
  });

  useEffect(() => setActive(isActive), [isActive]);

  if (!levels.length) return null;

  return (
    <>
      <LevelGroupListItem
        {...props}
        handleClick={handleClick}
        active={active}
      />

      {renderCertificateByCondition && (
        <CertificateItem
          disabled={isCertificateDisabled}
          certificatePNG={certificate.pngLink}
          certificatePDF={certificate.pdfLink}
          onClick={handleCertificateAccordionClick}
          active={isCertificateActive}
        />
      )}
    </>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentClassroom: currentClassroom(state),
    certificate: getCertificateByClassroom(state, ownProps),
    levels: getLevelsByLevelGroup(state, ownProps),
    currentLevel: getCurrentLevel(state),
    isCompleted: makeIsCompleted(state, ownProps),
    hasRatingWarning: makeRatingWarning(state, ownProps),
  };
}

const areStatesEqual = (next, prev) =>
  shallowEqual(next.entities.levels, prev.entities.levels);

export default connect(mapStateToProps, null, null, {
  pure: true,
  areStatesEqual,
})(LevelGroupListItemContainer);
