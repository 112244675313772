const getMocks = (action) => action.entities.mocks;

export default function mocksReducer(state = {}, action) {
  switch (action.type) {
    case "MOCK_CREATED":
    case "MOCK_UPDATED":
    case "MOCK_FETCHED":
    case "MOCKS_FETCHED":
    case "MOCK_TEMPLATE_FETCHED":
    case "MOCK_TEMPLATES_FETCHED":
    case "MOCKS_LIST_FETCHED":
    case "MOCK_TEMPLATES_HIGHLIGHTED_LIST_FETCHED":
    case "MOCK_TEMPLATES_EXERCISES_LIST_FETCHED":
    case "MOCK_TEMPLATES_EXAM_LIST_FETCHED":
    case "MOCK_TITLE_UPDATED":
      return { ...state, ...getMocks(action) };
    case "MOCK_DELETED":
      const { [action.mockId]: id, ...rest } = state;
      return rest;
    default:
      return state;
  }
}
