import React from 'react';
import { css } from "glamor";
import { EMRButton } from '../../componentsV2/Button';
import { IoArrowForward } from 'react-icons/io5';
import blockedContent from '../../../../assets/images/blockedContent.svg';
import { setCookie } from "cookies-next";

const container = css({
  maxWidth: '97%',
  maxHeight: '95%',
  textAlign: "center",
  width: "100%",
  backgroundColor: '#fff',
  borderRadius: '8px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '24px auto',
  height: '100%',

  '@media (max-width: 768px)': {
    maxWidth: '100%',
    padding: '24px 42px',
  },
});

const title = css({
  fontSize: '24px',
  fontWeight: 'normal',
  lineHeight: '32px',
  color: '#606A71',
  marginTop: '48px',
});


const description = css({
  maxWidth: '692px',
  fontSize: '16px',
  lineHeight: '24px',
  color: '#606A71',
  marginTop: '16px',
  marginBottom: '24px',

  '@media (max-width: 768px)': {
    maxWidth: '100%',
  },
});

const buttonText = css({
  fontSize: '16px',
  lineHeight: '24px',
  color: '#fff',
  fontWeight: 600
});


export default function PlaceholderContentBlocked({ user }) {

  function handleClickOnMedtest() {
    setCookie(
      process.env.METRICO_USER_ID_COOKIE,
      user?.id,
      {
        domain: process.env.DOMAIN_URL,
      }
    );


    setCookie(
      process.env.MEDTEST_USER_NAME,
      user?.name,
      {
        domain: process.env.DOMAIN_URL,
      }
    );

    setCookie(
      process.env.MEDTEST_USER_EMAIL,
      user?.email,
      {
        domain: process.env.DOMAIN_URL,
      }
    );

    window.open(
      process.env.MEDTEST_URL,
      "_blank",
      "noopener,noreferrer"
    );
  }


  return (
    <div className={container}>
      <img src={blockedContent} alt="Conteúdo bloqueado" />
      <h3 className={title}>Algumas funções da plataforma estão inativas</h3>
      <p className={description}>
        Para manter a integridade do processo avaliativo, algumas funcionalidades da sua plataforma estarão desativadas apenas durante a realização do seu simulado.
      </p>
      <EMRButton onClick={handleClickOnMedtest}>
        <p className={buttonText}>Ir para a prova</p>
        <IoArrowForward size={20} style={{ marginLeft: 8 }} />
      </EMRButton>
    </div>
  )
}