import styled from "styled-components";
import { colors } from "../../../../../../components/library/theme";

export const Box = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding: 16px;
  border-radius: 4px;
  margin-top: 24px;
  border: 1px solid ${colors.medtrack.green100};
  background-color: ${colors.medtrack.green000};
  gap: 24px;

  svg {
    fill: ${colors.medtrack.green200};
    flex-shrink: 0;
  }
`;

export const BoxContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  h3 {
    font-size: 14px;
    font-weight: 600;
    color: ${colors.medtrack.grey500};
    margin: 0;
  }

  p {
    font-size: 14px;
    color: ${colors.medtrack.grey500};
    margin: 0;
  }
`;
