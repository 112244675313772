import React, { useRef } from "react";
import { SearchInput } from "../SearchInput";

export function Search({ setSearch }) {
  const value = useRef("");
  const timer = useRef(null);

  function onKeyUp() {
    clearTimeout(timer.current);
    timer.current = setTimeout(function () {
      const searchText = value.current || "";
      setSearch(searchText.trim());
    }, 500);
  }

  function onChange(e) {
    value.current = e.target.value;
  }

  return (
    <SearchInput
      id="favorite_search_input"
      onKeyUp={onKeyUp}
      onChange={onChange}
    />
  );
}
