import React from "react";
import { connect } from "react-redux";
import Classrooms from "./Classrooms";
import { getCurrentUser } from "../../selectors/userSelectors";
import { getFetchedClassrooms } from "../../selectors/classroomSelectors";
import { isExpiredUser } from "../../selectors/userSelectors";

function ClassroomsContainer(props) {
  return <Classrooms {...props} />;
}

function mapStateToProps(state) {
  const expiredUser = isExpiredUser(state)
  const { isB2BUserExamInProgress } = state.isB2BUserExamInProgress

  return {
    user: getCurrentUser(state),
    classrooms: getFetchedClassrooms(state),
    loading: state.classroomsList.loading,
    expiredUser,
    isB2BUserExamInProgress,
  };
}

export default connect(mapStateToProps)(ClassroomsContainer);
