import { connect } from "react-redux";
import buildForm from "../library/forms/buildForm";
import ReportErrorModalForm from "./ReportErrorModalForm";
import { submitReportError } from "../../processes/reportErrorProcesses";

const form = buildForm("reportError");

function mapStateToProps(state, ownProps) {
  const { mockId, questionId } = ownProps;
  return {
    initialValues: {},
    mockId,
    questionId,
  };
}

export default connect(mapStateToProps)(
  form.connect({
    onSubmit: submitReportError,
  })(ReportErrorModalForm)
);
