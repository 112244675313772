import * as React from "react";
import { colors } from "../../../components/library/theme";

export function MedicsIllustration(props) {
  return (
    <svg
      width="282"
      height="281"
      viewBox="0 0 282 281"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M93.3368 42.5715C93.3368 42.5715 50.754 64.2703 29.5161 97.7037C8.27807 131.137 9.95845 174.136 35.7655 206.046C61.5725 237.956 110.899 233.792 152.201 254.024C193.502 274.256 239.069 270.086 258.599 227.239C278.128 184.392 248.679 168.358 243.621 118.784C238.563 69.2103 193.867 -3.37201 93.3368 42.5715Z"
        fill={colors.brand.pure}
      />
      <path
        opacity="0.9"
        d="M93.3368 42.5715C93.3368 42.5715 50.754 64.2703 29.5161 97.7037C8.27807 131.137 9.95845 174.136 35.7655 206.046C61.5725 237.956 110.899 233.792 152.201 254.024C193.502 274.256 239.069 270.086 258.599 227.239C278.128 184.392 248.679 168.358 243.621 118.784C238.563 69.2103 193.867 -3.37201 93.3368 42.5715Z"
        fill="white"
      />
      <path
        d="M263.066 153.707H260.734V153.308C260.733 152.219 260.299 151.175 259.529 150.405C258.759 149.635 257.715 149.201 256.626 149.2H255.047C253.957 149.2 252.911 149.632 252.14 150.403C251.368 151.173 250.934 152.218 250.933 153.308V153.707H248.606C248.295 153.706 247.987 153.767 247.7 153.886C247.412 154.004 247.151 154.178 246.931 154.398C246.712 154.618 246.537 154.879 246.419 155.167C246.3 155.454 246.239 155.762 246.24 156.073V166.852C246.24 167.48 246.489 168.081 246.933 168.525C247.377 168.969 247.979 169.218 248.606 169.218H263.066C263.693 169.217 264.294 168.967 264.738 168.524C265.181 168.08 265.431 167.479 265.432 166.852V156.073C265.432 155.446 265.183 154.844 264.739 154.4C264.296 153.956 263.694 153.707 263.066 153.707ZM252.057 153.308C252.058 152.516 252.374 151.757 252.935 151.197C253.495 150.638 254.255 150.324 255.047 150.324H256.626C257.417 150.324 258.176 150.638 258.736 151.198C259.296 151.757 259.61 152.517 259.61 153.308V153.707H252.057V153.308Z"
        fill="#263238"
      />
      <path
        d="M260.144 160.361H257.165V157.388H254.502V160.361H251.523V163.031H254.502V166.004H257.165V163.031H260.144V160.361Z"
        fill="white"
      />
      <path
        d="M258.458 89.4704C257.995 89.4704 257.536 89.3791 257.108 89.2016C256.68 89.0242 256.292 88.7642 255.964 88.4363C255.637 88.1085 255.378 87.7194 255.201 87.2913C255.024 86.8631 254.934 86.4043 254.934 85.941V76.432C254.934 75.9688 255.024 75.51 255.201 75.0818C255.378 74.6536 255.637 74.2645 255.964 73.9367C256.292 73.6089 256.68 73.3488 257.108 73.1714C257.536 72.994 257.995 72.9026 258.458 72.9026C258.921 72.9026 259.38 72.994 259.808 73.1714C260.236 73.3488 260.624 73.6089 260.952 73.9367C261.279 74.2645 261.538 74.6536 261.715 75.0818C261.892 75.51 261.982 75.9688 261.982 76.432V85.9242C261.985 86.3888 261.896 86.8495 261.72 87.2796C261.544 87.7097 261.285 88.1008 260.958 88.4304C260.63 88.76 260.241 89.0216 259.812 89.2C259.383 89.3785 258.923 89.4704 258.458 89.4704Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M258.458 89.4704C257.995 89.4704 257.536 89.3791 257.108 89.2016C256.68 89.0242 256.292 88.7641 255.964 88.4363C255.637 88.1085 255.378 87.7194 255.201 87.2912C255.024 86.8631 254.934 86.4043 254.934 85.941V81.1921H261.982V85.941C261.982 86.4043 261.892 86.8631 261.715 87.2912C261.538 87.7194 261.279 88.1085 260.952 88.4363C260.624 88.7641 260.236 89.0242 259.808 89.2016C259.38 89.3791 258.921 89.4704 258.458 89.4704Z"
        fill="#263238"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M240.733 75.4654C240.443 75.1035 240.227 74.6879 240.099 74.2425C239.97 73.7971 239.931 73.3307 239.983 72.8701C240.035 72.4095 240.178 71.9637 240.403 71.5584C240.628 71.1531 240.931 70.7963 241.295 70.5085L248.696 64.5626C249.429 63.9799 250.363 63.7119 251.294 63.8173C252.224 63.9226 253.074 64.3928 253.659 65.1246C254.241 65.8566 254.509 66.7899 254.403 67.7193C254.298 68.6487 253.828 69.4983 253.097 70.0814L245.689 76.0217C245.328 76.3111 244.912 76.5263 244.467 76.6549C244.022 76.7836 243.556 76.8232 243.096 76.7715C242.635 76.7198 242.189 76.5779 241.784 76.3537C241.378 76.1296 241.021 75.8277 240.733 75.4654Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M240.733 75.4653C240.443 75.1035 240.227 74.6879 240.099 74.2425C239.97 73.7971 239.931 73.3307 239.983 72.8701C240.035 72.4095 240.178 71.9637 240.403 71.5584C240.628 71.1531 240.931 70.7963 241.295 70.5085L244.998 67.5355L249.41 73.0375L245.706 76.0049C245.345 76.2978 244.929 76.5163 244.483 76.6477C244.037 76.7792 243.569 76.8211 243.107 76.7709C242.645 76.7208 242.197 76.5796 241.789 76.3555C241.382 76.1314 241.023 75.8289 240.733 75.4653Z"
        fill="#263238"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M205.489 49.5909C205.05 49.5911 204.622 49.7286 204.264 49.9843L196.076 43.6281C196.477 42.8853 196.666 42.0461 196.62 41.2031C196.574 40.3601 196.297 39.5461 195.817 38.8511L200.819 32.4555C201.243 32.6187 201.707 32.6421 202.145 32.5223C202.582 32.4025 202.97 32.1458 203.251 31.7897C203.532 31.4336 203.692 30.9969 203.707 30.5435C203.722 30.09 203.591 29.6437 203.334 29.2699C203.077 28.896 202.707 28.6143 202.278 28.4659C201.85 28.3175 201.385 28.3102 200.952 28.4452C200.518 28.5802 200.14 28.8502 199.871 29.2159C199.603 29.5816 199.458 30.0236 199.459 30.4773C199.457 30.9145 199.592 31.3413 199.847 31.6968L194.98 37.9294C194.327 37.3798 193.535 37.023 192.69 36.8989C191.846 36.7748 190.984 36.8884 190.201 37.2268C189.418 37.5653 188.744 38.1154 188.256 38.8152C187.768 39.5151 187.485 40.337 187.438 41.189H181.751C181.688 40.6841 181.447 40.2183 181.071 39.8762C180.694 39.5342 180.208 39.3385 179.699 39.3248C179.191 39.3111 178.694 39.4803 178.3 39.8017C177.906 40.123 177.64 40.5752 177.551 41.0759C177.462 41.5767 177.555 42.0929 177.814 42.5306C178.073 42.9683 178.481 43.2985 178.963 43.4612C179.445 43.6239 179.969 43.6082 180.44 43.4171C180.912 43.226 181.299 42.8721 181.531 42.4198H187.545C187.721 43.2369 188.116 43.9906 188.688 44.5998C189.261 45.209 189.988 45.6507 190.793 45.8773C191.597 46.1038 192.449 46.1068 193.255 45.8857C194.061 45.6647 194.791 45.2281 195.368 44.6228L203.511 50.9565C203.351 51.379 203.329 51.8418 203.45 52.2772C203.571 52.7127 203.829 53.098 204.184 53.3769C204.54 53.6558 204.975 53.8136 205.427 53.8274C205.879 53.8411 206.323 53.71 206.695 53.4532C207.067 53.1964 207.347 52.8275 207.495 52.4002C207.642 51.9728 207.649 51.5096 207.514 51.0782C207.379 50.6467 207.11 50.2697 206.746 50.0021C206.382 49.7345 205.941 49.5904 205.489 49.5909Z"
        fill="#263238"
      />
      <path
        d="M39.1543 89.5378C38.4736 89.5364 37.8212 89.2653 37.3399 88.7839C36.8586 88.3026 36.5875 87.6502 36.586 86.9695V65.6135H41.7227V86.9695C41.7227 87.6507 41.4521 88.3039 40.9704 88.7856C40.4888 89.2672 39.8355 89.5378 39.1543 89.5378Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.1543 89.5379C38.4736 89.5364 37.8212 89.2653 37.3399 88.784C36.8586 88.3026 36.5875 87.6502 36.586 86.9695V73.8356H41.7227V86.9695C41.7227 87.6507 41.4521 88.3039 40.9704 88.7856C40.4888 89.2673 39.8355 89.5379 39.1543 89.5379Z"
        fill="#263238"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.4589 64.3658H35.8554V66.2373H42.4589V64.3658Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.3706 89.5378C30.6899 89.5364 30.0375 89.2653 29.5562 88.7839C29.0749 88.3026 28.8038 87.6502 28.8023 86.9695V65.6135H33.939V86.9695C33.939 87.6507 33.6684 88.3039 33.1867 88.7856C32.7051 89.2672 32.0518 89.5378 31.3706 89.5378Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.3706 89.5379C30.6899 89.5364 30.0375 89.2653 29.5562 88.784C29.0749 88.3026 28.8038 87.6502 28.8023 86.9695V73.8356H33.939V86.9695C33.939 87.6507 33.6684 88.3039 33.1867 88.7856C32.7051 89.2673 32.0518 89.5379 31.3706 89.5379Z"
        fill="#263238"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.6752 64.3658H28.0717V66.2373H34.6752V64.3658Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M105.47 43.3133L107.516 38.4014C107.551 38.3223 107.608 38.2554 107.682 38.2093C107.755 38.1632 107.84 38.1401 107.926 38.1429C108.013 38.1472 108.096 38.1782 108.164 38.2315C108.233 38.2848 108.283 38.3579 108.308 38.4408L110.787 46.6123L112.675 40.5932C112.701 40.5086 112.753 40.4342 112.824 40.3807C112.894 40.3273 112.98 40.2974 113.069 40.2954C113.156 40.2978 113.241 40.3256 113.313 40.3755C113.385 40.4254 113.44 40.4953 113.473 40.5764L114.524 43.5999H120.206C121.369 42.3847 122.045 40.7848 122.106 39.1039C122.116 37.534 121.547 36.0153 120.506 34.8397C119.465 33.6641 118.027 32.9144 116.467 32.7346C114.908 32.5548 113.337 32.9576 112.056 33.8655C110.775 34.7735 109.875 36.1227 109.528 37.654C109.171 36.1335 108.268 34.7972 106.989 33.8993C105.711 33.0015 104.148 32.6047 102.596 32.7844C101.045 32.9641 99.6136 33.7078 98.5747 34.874C97.5357 36.0403 96.9617 37.5477 96.9617 39.1096C97.0241 40.6662 97.608 42.1566 98.6196 43.3414L105.47 43.3133Z"
        fill="#263238"
      />
      <path
        d="M114.226 44.443C114.139 44.4433 114.053 44.4164 113.982 44.3661C113.911 44.3157 113.856 44.2444 113.827 44.162L113.097 42.0601L111.18 48.169C111.153 48.2551 111.099 48.3302 111.026 48.3837C110.954 48.4373 110.866 48.4664 110.776 48.4669C110.685 48.4666 110.597 48.4368 110.525 48.382C110.453 48.3273 110.401 48.2505 110.377 48.1634L107.842 39.8065L106.156 43.8978C106.124 43.9743 106.07 44.0396 106.001 44.0855C105.932 44.1315 105.851 44.1562 105.768 44.1563H99.2996C100.688 45.7075 102.419 47.0787 103.711 48.3545C105.667 50.2878 107.583 52.2885 109.579 54.1543C112.136 51.6309 114.71 49.0963 117.244 46.5729C117.947 45.8704 118.711 45.1736 119.419 44.443H114.226Z"
        fill="#263238"
      />
      <path
        d="M19.4217 170.058L22.1774 171.013L25.1592 162.417L22.4035 161.461L19.4217 170.058Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.562"
        strokeMiterlimit="10"
      />
      <path
        d="M18.1153 171.422L22.3683 172.898L22.93 171.278L18.677 169.803L18.1153 171.422Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.562"
        strokeMiterlimit="10"
      />
      <path
        d="M19.3648 163.673L26.1771 166.036L31.5789 150.463L24.7667 148.1L19.3648 163.673Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.562"
        strokeMiterlimit="10"
      />
      <path
        d="M22.6772 157.011L27.7054 158.756L30.6872 150.159L25.659 148.415L22.6772 157.011Z"
        fill="#263238"
        stroke="#263238"
        strokeWidth="0.562"
        strokeMiterlimit="10"
      />
      <path
        d="M21.7878 163.243L24.5435 164.199L27.5253 155.602L24.7696 154.647L21.7878 163.243Z"
        fill="#263238"
        stroke="#263238"
        strokeWidth="0.562"
        strokeMiterlimit="10"
      />
      <path
        d="M18.8838 163.51L26.6678 166.21L27.3437 164.261L19.5598 161.561L18.8838 163.51Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.562"
        strokeMiterlimit="10"
      />
      <path
        d="M26.4277 147.776L30.4843 149.183L31.3849 146.586L27.3283 145.179L26.4277 147.776Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.562"
        strokeMiterlimit="10"
      />
      <path
        d="M24.1154 148.422L31.8994 151.122L32.5753 149.174L24.7913 146.474L24.1154 148.422Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.562"
        strokeMiterlimit="10"
      />
      <path
        d="M29.398 145.53L32.6576 136.122"
        stroke="#263238"
        strokeWidth="0.562"
        strokeMiterlimit="10"
      />
      <path
        d="M210.632 262.454C226.874 262.454 240.041 259.485 240.041 255.822C240.041 252.16 226.874 249.191 210.632 249.191C194.389 249.191 181.222 252.16 181.222 255.822C181.222 259.485 194.389 262.454 210.632 262.454Z"
        fill="#263238"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M187.955 105.926C187.955 105.926 183.976 108.359 184.021 109.427C184.066 110.495 182.537 129.968 182.363 132.402C182.189 134.835 179.874 139.859 179.694 142.141C179.514 144.423 180.087 146.71 180.756 147.761C181.425 148.812 183.881 152.173 183.881 152.173L190.484 141.972C190.646 139.745 190.68 137.51 190.585 135.279C190.484 130.418 187.955 105.926 187.955 105.926Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M213.363 57.6724C213.363 57.6724 206.338 57.2228 202.337 62.0954C198.335 66.9679 199.195 74.42 199.167 76.7074C199.139 78.9947 192.344 85.6994 193.097 88.4307C193.85 91.162 195.851 91.4711 195.211 93.7528C194.57 96.0346 190.389 97.4058 190.996 100.3C191.602 103.194 193.806 107.297 193.806 107.297L234.27 107.572C234.27 107.572 235.714 103.312 234.174 100.115C232.634 96.9169 230.448 95.8603 230.802 94.3373C231.156 92.8143 233.112 89.1613 232.359 86.4244C231.606 83.6874 228.987 82.9287 228.711 78.0562C228.436 73.1836 227.717 65.1302 223.85 61.1962C219.983 57.2622 213.363 57.6724 213.363 57.6724Z"
        fill="#263238"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M200.92 245.442C199.678 247.837 198.17 250.084 196.424 252.141C193.839 255.036 191.333 256.559 191.417 258.082C191.501 259.605 197.144 259.903 200.128 258.835C203.112 257.767 205.282 255.637 205.883 255.463C206.484 255.289 208.165 255.463 208.204 253.327C208.243 251.192 207.917 245.111 207.917 245.111L200.92 245.442Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M200.117 258.835C203.107 257.767 205.27 255.637 205.872 255.463C206.473 255.289 208.153 255.463 208.193 253.327C208.193 252.203 208.131 249.955 208.052 248.117C207.61 248.947 206.956 249.644 206.156 250.137C205.356 250.631 204.44 250.904 203.5 250.927C203.5 250.927 203.444 249.86 201.814 248.039C200.763 246.847 199.701 247.786 199.083 248.601L198.583 249.354L198.543 249.427C197.894 250.384 197.185 251.298 196.419 252.164C193.834 255.058 191.327 256.581 191.411 258.104C191.496 259.627 197.133 259.903 200.117 258.835Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M221.743 245.431C223.253 247.834 225.004 250.077 226.969 252.124C229.863 255.013 232.533 256.531 232.589 258.054C232.645 259.577 227.059 259.88 223.957 258.818C220.855 257.756 218.46 255.626 217.848 255.474C217.235 255.322 215.561 255.474 215.296 253.35C215.032 251.225 214.701 245.133 214.701 245.133L221.743 245.431Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M223.979 258.818C220.883 257.756 218.483 255.626 217.87 255.474C217.258 255.322 215.583 255.474 215.319 253.35C215.178 252.226 215.021 249.978 214.903 248.134C215.423 248.958 216.137 249.642 216.982 250.126C217.828 250.61 218.779 250.88 219.753 250.911C219.753 250.911 219.697 249.843 221.119 248.016C222.046 246.83 223.209 247.763 223.929 248.578C224.114 248.826 224.305 249.073 224.491 249.331L224.541 249.404C225.297 250.36 226.103 251.273 226.958 252.141C229.852 255.03 232.522 256.547 232.578 258.07C232.634 259.593 227.082 259.88 223.979 258.818Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M188.697 165.116C188.697 165.116 189.635 196.762 190.883 203C192.131 209.238 197.7 244.993 197.7 244.993C199.403 245.713 201.236 246.073 203.084 246.049H209.171L209.733 219.422L209.941 180.773L212.992 246.195C212.992 246.195 221.377 246.493 222.282 246.341C223.187 246.19 223.94 246.038 223.94 246.038L229.33 204.647L232.415 177.109L232.584 166.155C232.584 166.155 206.821 165.262 199.336 164.655C191.85 164.048 188.77 163.896 188.77 163.896L188.697 165.116Z"
        fill="#35BD78"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M223.485 220.99C223.417 221.518 223.35 222.007 223.271 222.456"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M224.176 182.892C224.176 182.892 224.699 205.439 223.783 217.679"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M196.554 181.082C196.554 181.082 197.2 195.998 197.841 199.341"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M220.568 171.579V182.965C222.027 183.358 223.519 183.615 225.025 183.735C227.334 183.886 231.645 183.735 231.645 183.735L231.954 179.424L220.568 171.579Z"
        fill="#263238"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M189.175 180.346C189.175 180.346 195.025 182.195 197.487 182.347C199.948 182.498 202.252 182.807 202.252 182.807L201.949 179.733L188.866 178.502L189.175 180.346Z"
        fill="#263238"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M206.934 93.8933L202.342 99.0694L189.607 108.359L192.322 164.666C192.322 164.666 202.472 167.397 211.183 168.156C219.894 168.915 231.094 166.92 231.094 166.92L229.448 107.91C229.448 107.91 224.491 100.604 221.95 98.631C219.41 96.6584 218.247 94.8319 214.881 94.5284C211.514 94.2249 206.934 93.8933 206.934 93.8933Z"
        fill="#35BD78"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M201.606 96.664L187.955 105.954L190.096 140.185L189.371 143.625L183.633 150.234L186.078 161.845L184.988 178.227C184.988 178.227 190.411 180.054 194.087 180.503C196.823 180.796 199.573 180.948 202.325 180.958L202.798 158.591C202.798 158.591 204.613 121.459 204.826 119.813C205.04 118.166 206.934 93.9608 206.934 93.9608L201.606 96.664Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M201.825 98.0634L195.216 114.637L202.348 117.599L195.615 118.824L201.578 142.186"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M205.787 89.4817C205.787 89.4817 208.159 105.302 210.025 108.955C211.891 112.608 212.419 113.822 212.419 113.822C212.419 113.822 216.477 101.492 217.291 99.6651C218.106 97.8386 218.977 91.2969 218.977 91.2969C218.977 91.2969 210.89 93.8877 205.787 89.4817Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M205.787 89.4817C205.787 89.4817 206.001 90.9204 206.349 93.0279C207.535 93.9327 210.677 96.1695 212.998 96.2875C214.768 96.3774 217.016 95.2309 218.511 94.3149C218.781 92.657 218.966 91.2969 218.966 91.2969C218.966 91.2969 210.89 93.8877 205.787 89.4817Z"
        fill="#263238"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M202.567 78.5451C202.567 78.5451 200.949 76.2409 199.858 78.5451C198.768 80.8493 201.544 84.2607 202.32 84.7271C204.523 86.0366 203.938 83.6031 203.938 83.6031L202.567 78.5451Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M222.67 79.5399C222.67 79.5399 224.035 77.157 225.373 79.405C226.711 81.653 224.3 85.188 223.58 85.6882C221.523 87.11 221.849 84.6709 221.849 84.6709L222.67 79.5399Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M202.185 76.5107C202.185 76.5107 208.052 75.1394 211.008 73.3916C212.819 72.298 214.398 70.8603 215.656 69.1597C215.656 69.1597 218.196 73.9592 220.433 75.9037C222.67 77.8482 223.136 77.9607 223.136 77.9607C223.136 77.9607 222.063 86.3625 220.849 89.2849C219.635 92.2073 215.673 95.0511 211.975 94.2306C209.873 93.7697 206.231 92.2523 205.175 89.6952C204.118 87.1381 202.185 76.5107 202.185 76.5107Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M217.157 82.9681C215.336 83.013 209.238 83.0243 206.091 81.1247C204.829 80.3903 203.648 79.5257 202.567 78.5451C202.679 79.1071 202.814 79.8096 202.96 80.5402L203.646 81.0404L205.366 87.5371L204.562 87.7114C204.732 88.3755 204.936 89.0302 205.175 89.6727C206.231 92.2298 209.873 93.7472 211.975 94.2081C215.667 95.023 219.618 92.1961 220.843 89.2793C220.554 88.7127 220.469 88.0638 220.602 87.4416C220.956 85.6039 221.394 82.3836 221.394 82.3836L222.647 81.2596C222.715 80.8493 222.771 80.4559 222.827 80.0963C221.31 81.1472 218.466 82.9344 217.157 82.9681Z"
        fill="#35BD78"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M210.463 80.2536C210.463 80.2536 208.502 77.8707 205.928 78.0899"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M215.516 80.7257C216.141 80.0677 216.919 79.5731 217.78 79.285C218.641 78.997 219.56 78.9243 220.456 79.0734"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M204.798 76.8985C204.798 76.8985 207.608 75.7745 210.098 76.3028"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M215.746 76.9153C216.486 76.5371 217.325 76.3956 218.148 76.5103C218.972 76.6249 219.74 76.99 220.349 77.556"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M208.575 79.4724C208.588 79.5588 208.583 79.6468 208.562 79.7315C208.54 79.8161 208.502 79.8957 208.45 79.9657C208.398 80.0356 208.333 80.0946 208.257 80.1392C208.182 80.1838 208.099 80.2131 208.013 80.2255C207.837 80.2057 207.675 80.1198 207.561 79.9852C207.446 79.8505 207.386 79.6773 207.395 79.5005C207.381 79.4141 207.385 79.3258 207.406 79.2408C207.427 79.1559 207.465 79.076 207.517 79.0058C207.57 78.9357 207.636 78.8767 207.711 78.8324C207.786 78.788 207.87 78.7591 207.957 78.7474C208.132 78.7685 208.293 78.8548 208.407 78.9891C208.522 79.1235 208.582 79.296 208.575 79.4724Z"
        fill="#263238"
      />
      <path
        d="M218.753 80.203C218.768 80.2897 218.765 80.3785 218.745 80.4641C218.724 80.5497 218.687 80.6302 218.634 80.7007C218.581 80.7712 218.515 80.8302 218.439 80.8741C218.362 80.9181 218.278 80.946 218.191 80.9561C218.015 80.9377 217.854 80.8529 217.739 80.7192C217.624 80.5855 217.564 80.4129 217.573 80.2368C217.557 80.1501 217.56 80.0613 217.581 79.9757C217.601 79.8901 217.639 79.8096 217.691 79.7391C217.744 79.6686 217.81 79.6096 217.887 79.5657C217.963 79.5217 218.047 79.4938 218.135 79.4837C218.31 79.5021 218.472 79.5869 218.586 79.7206C218.701 79.8543 218.761 80.0269 218.753 80.203Z"
        fill="#263238"
      />
      <path
        d="M233.887 102.7C233.887 102.7 237.164 104.217 238.383 106.634C239.603 109.05 249.354 135.234 249.309 137.212C249.264 139.191 246.038 141.472 243.014 141.781C239.991 142.09 235.585 136.611 234.927 135.701C234.27 134.79 234.151 124.135 234.151 124.135L233.887 102.7Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M218.444 95.7086L220.04 148.053L220.602 181.071C223.662 181.823 226.797 182.229 229.948 182.279C232.576 182.334 235.189 181.868 237.636 180.908C237.636 180.908 236.681 160.367 236.512 157.017C236.343 153.668 235.231 147.278 235.231 147.278L233.904 102.7C233.904 102.7 221.557 96.9225 218.444 95.7086Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M221.95 98.5973L227.61 117.059L222.451 118.458L227.469 120.892L221.209 140.781"
        fill="white"
      />
      <path
        d="M221.95 98.5973L227.61 117.059L222.451 118.458L227.469 120.892L221.209 140.781"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M225.896 115.485L218.876 149.115C218.876 149.115 223.676 150.633 227.183 150.801C230.69 150.97 237.996 150.948 237.996 150.948L245.302 119.599C245.302 119.599 230.92 118.222 225.896 115.485Z"
        fill="#263238"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M228.34 146.828L228.571 136.319L234.14 137.493C234.14 137.493 230.459 145.603 228.34 146.828Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M232.606 132.52C232.606 132.52 231.122 130.395 230.645 129.934C230.167 129.474 227.807 128.114 227.351 128.114C226.896 128.114 224.451 127.962 223.979 127.962C223.507 127.962 222.973 129.03 223.457 129.485C223.94 129.94 225.62 130.092 225.62 130.092L226.772 131.778L221.574 131.323C221.574 131.323 220.708 132.239 220.905 133.009C221.052 133.53 221.24 134.039 221.467 134.532C221.447 135.649 221.506 136.766 221.641 137.875C221.844 138.792 222.765 139.095 224.03 139.854C225.294 140.612 229.526 140.157 229.526 140.157H231.482L232.606 132.52Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M245.279 133.576L231.791 131.761C231.791 131.761 230.74 140.438 229.678 143.327C228.616 146.216 228.34 146.828 228.34 146.828C228.34 146.828 242.475 146.513 245.807 146.21C249.14 145.906 251.011 143.923 250.865 141.186C250.719 138.449 249.05 135.706 249.05 135.706"
        fill="white"
      />
      <path
        d="M245.279 133.576L231.791 131.761C231.791 131.761 230.74 140.438 229.678 143.327C228.616 146.216 228.34 146.828 228.34 146.828C228.34 146.828 242.475 146.513 245.807 146.21C249.14 145.906 251.011 143.923 250.865 141.186C250.719 138.449 249.05 135.706 249.05 135.706"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M189.034 143.051C189.034 143.051 192.181 145.024 192.968 145.637C193.755 146.249 193.822 150.2 194.323 150.959C194.823 151.718 194.233 152.178 193.322 152.178C192.412 152.178 191.035 149.29 191.035 149.29L190.979 148.222L184.549 150.357L182.863 147.469C182.863 147.469 184.774 143.051 189.034 143.051Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M142.197 262.454C158.439 262.454 171.607 259.485 171.607 255.822C171.607 252.16 158.439 249.191 142.197 249.191C125.955 249.191 112.788 252.16 112.788 255.822C112.788 259.485 125.955 262.454 142.197 262.454Z"
        fill="#263238"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M165.166 98.232C165.166 98.232 169.021 117.582 169.707 121.162C170.393 124.742 171.651 133.25 173.079 136.982C174.506 140.714 174.04 145.491 173.326 146.834C172.61 148.001 171.788 149.1 170.87 150.116L166.082 143.872C166.082 143.872 163.233 113.277 163.126 108.944C163.019 104.611 165.166 98.232 165.166 98.232Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M167.706 143.108C167.706 143.108 170.741 145.002 171.275 145.805C171.809 146.609 172.191 147.632 171.775 148.503C171.359 149.374 169.094 152.004 168.521 152.516C167.948 153.027 167.465 150.987 167.397 149.234C167.33 147.48 167.706 143.108 167.706 143.108Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M151.386 249.213C151.386 249.213 160.9 253.839 161.502 254.738C162.103 255.637 159.934 256.834 154.263 256.834C148.593 256.834 148.424 256.087 146.17 255.491C143.917 254.895 139.426 255.339 138.819 253.996C138.212 252.653 139.269 248.027 139.269 248.027L151.386 249.213Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M138.752 251.602C138.752 251.602 146.62 256.379 145.929 258.02C145.237 259.661 139.55 259.661 136.555 259.211C133.559 258.762 127.192 254.715 126.854 253.243C126.517 251.77 127.36 249.511 131.974 249.067C136.588 248.623 138.752 251.602 138.752 251.602Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M162.637 173.546C162.637 173.546 158.141 208.17 157.36 213.245C156.579 218.32 154.1 226.379 152.763 232.499C152.005 236.099 151.442 239.736 151.077 243.397C150.94 244.438 150.965 245.493 151.15 246.527C151.341 248.022 151.369 249.213 151.369 249.213C149.368 249.715 147.331 250.065 145.277 250.259C142.742 250.41 139.308 250.41 139.308 250.41L138.184 240.71C138.184 240.71 139.022 226.379 139.516 222.204C140.011 218.028 140.539 185.19 140.517 184.291C140.494 183.392 140.297 175.485 140.297 175.485L155.612 173.843C155.612 173.843 157.703 167.875 160.844 168.173C163.986 168.471 162.637 173.546 162.637 173.546Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M160.867 168.173C157.725 167.875 155.634 173.843 155.634 173.843L140.303 175.485C140.303 175.485 140.354 177.586 140.404 179.744C142.04 179.891 143.551 179.975 144.844 179.975C149.756 179.967 154.664 179.691 159.546 179.149L160.204 172.27L161.232 179.278L161.94 179.048C162.378 175.709 162.659 173.534 162.659 173.534C162.659 173.534 164.008 168.471 160.867 168.173Z"
        fill="#263238"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M124.309 174.591C124.309 174.591 124.309 174.804 124.342 175.192C124.55 179.272 125.787 202.916 126.163 207.277C126.585 212.054 124.477 217.275 124.584 221.602C124.691 225.93 125.708 249.365 125.708 249.365L126.832 253.243C126.832 253.243 129.473 251.456 132.008 251.456C134.543 251.456 138.752 251.602 138.752 251.602C138.752 251.602 138.971 248.466 138.949 247.426C138.926 246.386 138.831 242.649 138.831 242.649C138.831 242.649 143.119 223.097 143.377 221.456C143.636 219.815 146.963 191.457 146.94 190.546C146.918 189.636 147.643 176.963 147.643 176.963L124.309 174.591Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M147.485 179.947C147.581 178.216 147.648 176.979 147.648 176.979L124.309 174.591C124.309 174.591 124.309 174.804 124.342 175.192C124.342 175.687 124.404 176.468 124.46 177.44C129.917 178.418 139.387 179.958 144.844 179.958C145.743 179.975 146.62 179.964 147.485 179.947Z"
        fill="#263238"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M141.506 82.9512C141.506 82.9512 139.039 83.3671 138.1 84.4686C137.159 85.54 136.313 86.6908 135.571 87.908C135.442 88.3239 120.931 97.1305 120.931 97.1305L121.976 139.123C121.976 139.123 122.409 145.592 121.639 147.795C120.339 152.126 119.707 156.631 119.768 161.154C119.903 166.521 120.251 175.204 120.251 175.204C120.251 175.204 136.858 178.508 144.844 178.508C149.756 178.496 154.664 178.217 159.546 177.671L160.204 170.786L161.204 177.811L164.615 176.687L167.425 155.893C167.425 155.893 169.381 151.487 169.359 150.52C169.336 149.554 169.853 148.317 169.285 147.491C168.718 146.665 167.706 145.013 167.706 145.013C167.706 145.013 165.166 99.474 165.166 98.232C165.166 96.99 157.652 89.7008 157.096 89.4254C156.539 89.1501 153.111 84.0527 148.666 82.6814C144.22 81.3101 141.506 82.9512 141.506 82.9512Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M136.982 89.2849L135.318 104.408L142.152 107.584L140.444 112.715L157.534 142.748"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M156.073 90.9822L161.198 102.947L159.001 105.875L162.665 111.001L161.198 143.968"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M140.871 85.0194C140.995 87.9698 141.531 90.8884 142.461 93.691C143.939 97.8217 153.735 126.321 153.735 126.321L160.069 148.621C160.069 148.621 159.231 125.905 159.209 124.944C159.186 123.983 154.409 92.449 154.409 92.449C154.409 92.449 153.594 87.3573 152.307 85.4296C151.02 83.502 142.394 79.922 140.871 85.0194Z"
        fill="#35BD78"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M140.264 82.8163C140.264 82.8163 136.279 83.2266 136.982 89.2849C137.684 95.3433 138.611 99.474 138.611 99.474"
        stroke="#263238"
        strokeWidth="1.124"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M138.123 107.46C138.123 107.46 139.809 114.479 140.961 116.407C142.113 118.335 145.597 120.262 148.076 120.262"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M143.45 105.808C143.45 105.808 145.305 113.929 145.614 115.446C145.923 116.963 144.9 119.846 143.181 122.752"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M142.686 123.713C142.509 123.616 142.378 123.452 142.321 123.259C142.264 123.065 142.286 122.856 142.383 122.679L142.579 122.319C142.628 122.233 142.693 122.158 142.771 122.097C142.848 122.037 142.938 121.993 143.033 121.967C143.128 121.941 143.227 121.935 143.325 121.948C143.423 121.961 143.517 121.994 143.602 122.044C143.779 122.141 143.91 122.304 143.968 122.498C144.026 122.691 144.005 122.9 143.911 123.078L143.714 123.432C143.666 123.519 143.601 123.595 143.523 123.656C143.444 123.717 143.355 123.762 143.259 123.788C143.163 123.814 143.063 123.821 142.965 123.808C142.867 123.795 142.772 123.763 142.686 123.713Z"
        fill="#263238"
      />
      <path
        d="M148.997 120.414C148.997 120.513 148.977 120.611 148.939 120.703C148.9 120.794 148.843 120.876 148.772 120.945C148.7 121.014 148.616 121.067 148.523 121.103C148.431 121.138 148.332 121.154 148.233 121.15H147.823C147.62 121.146 147.428 121.062 147.287 120.917C147.146 120.772 147.068 120.577 147.07 120.375C147.069 120.276 147.088 120.178 147.126 120.087C147.164 119.996 147.22 119.913 147.291 119.844C147.362 119.775 147.446 119.722 147.539 119.686C147.631 119.651 147.73 119.635 147.828 119.639H148.239C148.441 119.644 148.633 119.728 148.774 119.873C148.916 120.018 148.996 120.212 148.997 120.414Z"
        fill="#263238"
      />
      <path
        d="M138.134 108.011L137.19 102.025C137.084 101.408 137.218 100.774 137.563 100.252C137.909 99.7302 138.441 99.3595 139.05 99.2155C139.706 99.0662 140.395 99.1772 140.972 99.5251C141.548 99.873 141.967 100.431 142.141 101.081L143.754 107.039"
        stroke="#263238"
        strokeWidth="1.124"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M160.349 112.056C161.702 111.92 162.672 110.546 162.515 108.987C162.359 107.427 161.136 106.272 159.783 106.408C158.431 106.543 157.461 107.918 157.617 109.477C157.773 111.037 158.996 112.191 160.349 112.056Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M153.437 85.3397C153.437 85.3397 158.849 92.9548 160.574 108.477"
        stroke="#263238"
        strokeWidth="1.124"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M160.718 110.274C161.457 110.206 161.991 109.451 161.911 108.589C161.83 107.727 161.167 107.083 160.428 107.152C159.689 107.22 159.156 107.975 159.236 108.837C159.316 109.7 159.98 110.343 160.718 110.274Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M168.628 144.294C168.296 145.322 168.024 146.369 167.813 147.429C167.813 147.879 167.706 149.222 166.655 148.919C165.604 148.615 165.919 143.248 166.054 142.652C166.189 142.057 168.746 143.984 168.746 143.984"
        fill="white"
      />
      <path
        d="M168.628 144.294C168.296 145.322 168.024 146.369 167.813 147.429C167.813 147.879 167.706 149.222 166.655 148.919C165.604 148.615 165.919 143.248 166.054 142.652C166.189 142.057 168.746 143.984 168.746 143.984"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M134.515 139.539C135.449 140.054 136.494 140.337 137.561 140.365C139.213 140.365 142.231 139.803 143.338 140.09C144.445 140.376 147.272 143.939 147.272 143.939C147.272 143.939 148.115 144.766 147.716 145.181C147.317 145.597 146.502 146.305 145.94 145.867C145.378 145.429 142.568 143.113 142.568 143.113L135.481 146.008L132.57 144.49L134.515 139.539Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M123.662 96.1694C123.662 96.1694 118.992 96.7314 118.2 97.9566C117.407 99.1818 104.61 127.282 104.639 128.524C104.667 129.766 130.226 149.88 130.226 149.88L135.745 139.376L121.937 126.574L127.928 112.67"
        fill="white"
      />
      <path
        d="M123.662 96.1694C123.662 96.1694 118.992 96.7314 118.2 97.9566C117.407 99.1818 104.61 127.282 104.639 128.524C104.667 129.766 130.226 149.88 130.226 149.88L135.745 139.376L121.937 126.574L127.928 112.67"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M132.278 154.955L145.777 155.23L145.71 146.834"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M135.498 146.008L131.929 147.34"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M139.567 76.8928C139.567 76.8928 141.101 88.8747 142.113 90.6618C143.124 92.449 150.318 99.6089 150.318 99.6089C150.318 99.6089 152.566 91.3531 152.819 89.4254C153.072 87.4978 153.268 79.7871 153.268 79.7871L139.539 75.9318L139.567 76.8928Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M139.567 76.8928C139.567 76.8928 139.747 78.2978 140.022 80.2143C141.098 81.2163 142.328 82.0397 143.664 82.6533C145.434 83.4345 150.155 83.8729 153.139 83.5244C153.218 81.5069 153.268 79.7871 153.268 79.7871L139.539 75.9318L139.567 76.8928Z"
        fill="#263238"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M135.588 66.434C135.588 66.434 133.75 64.5064 132.84 66.7038C131.929 68.9012 133.964 72.2114 135.048 72.3519C135.692 72.4358 136.346 72.388 136.97 72.2114C136.97 72.2114 137.74 75.3811 138.449 76.3421C139.842 78.1043 141.628 79.5168 143.664 80.4672C146.17 81.5912 154.561 81.9902 155.623 80.4672C156.685 78.9442 156.624 70.8289 156.607 70.1432C156.59 69.4576 156.472 59.6451 156.472 59.6451C156.472 59.6451 157.225 56.2394 156.202 53.8958C155.179 51.5523 144.912 48.2758 142.332 49.6302C139.752 50.9847 136.779 53.3451 136.577 56.3742C136.375 59.4034 136.498 64.3603 136.498 64.3603L135.588 66.434Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M150.762 62.1628C150.762 62.1628 152.476 70.0308 152.083 70.975C151.689 71.9191 150.458 71.936 148.919 71.1099C147.379 70.2837 148.182 69.1822 148.182 69.1822"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M144.793 71.2504C144.793 71.2504 145.535 73.3129 146.912 73.4534C148.289 73.5939 151.745 73.8637 151.745 73.8637"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M139.876 62.4382L140.095 65.7428C140.095 65.7428 141.354 66.434 144.243 66.434C147.131 66.434 148.081 65.7428 148.081 65.7428L148.003 62.4382C148.003 62.4382 143.81 59.9598 139.876 62.4382Z"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M150.773 62.4382L151.818 65.8833C151.818 65.8833 152.246 66.4453 155.14 66.4453C158.034 66.4453 158.984 65.754 158.984 65.754L158.9 62.4495C158.9 62.4495 154.702 59.9598 150.773 62.4382Z"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M148.019 62.4382H150.773"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M139.893 62.4382L134.464 65.6079"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M141.23 60.7859C141.23 60.7859 142.703 59.1336 146.041 60.5105"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M151.279 60.7859C151.775 60.3882 152.373 60.1386 153.004 60.066C153.636 59.9935 154.275 60.1008 154.848 60.3757"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M144.749 63.9556C144.749 64.5626 144.529 65.0796 144.226 65.0796C143.922 65.0796 143.664 64.585 143.664 63.9556C143.664 63.3262 143.883 62.8316 144.192 62.8316C144.501 62.8316 144.737 63.343 144.749 63.9556Z"
        fill="#263238"
      />
      <path
        d="M153.561 63.8151C153.561 64.4221 153.342 64.9391 153.038 64.9391C152.735 64.9391 152.476 64.4445 152.476 63.8151C152.476 63.1857 152.695 62.6911 152.999 62.6911C153.302 62.6911 153.544 63.2081 153.561 63.8151Z"
        fill="#263238"
      />
      <path
        d="M144.13 51.0802C144.13 51.0802 145.457 54.0082 143.81 55.076C143.302 55.3701 142.725 55.5249 142.138 55.5249C141.551 55.5249 140.974 55.3701 140.466 55.076C140.904 55.6807 141.148 56.4038 141.167 57.1501C141.186 57.8964 140.979 58.631 140.573 59.2573C139.766 60.6023 138.824 61.862 137.763 63.0171C137.763 63.0171 138.061 66.5745 137.443 66.9904C136.824 67.4063 135.138 66.7825 135.11 65.7372C135.082 64.6918 134.408 62.6012 134.38 61.5559C134.352 60.5105 133.868 57.7905 133.868 57.7905C133.868 57.7905 129.642 55.9078 131.884 53.6092C134.127 51.3106 138.887 49.4279 138.887 49.4279C138.887 49.4279 138.994 45.2466 141.888 43.9934C144.782 42.7401 151.009 40.8574 151.06 42.948C151.139 44.024 150.946 45.1025 150.498 46.084C150.498 46.084 155.719 45.8761 159.361 49.456C163.002 53.036 162.879 56.3574 160.996 56.3574C159.113 56.3574 158.501 56.7732 158.501 56.7732C158.501 56.7732 162.103 58.8639 160.248 59.9092C158.394 60.9545 156.483 59.7013 156.483 59.7013C156.483 59.7013 157.281 58.2401 154.932 56.3293C152.583 54.4185 144.13 51.0802 144.13 51.0802Z"
        fill="#263238"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M72.8181 262.454C89.0605 262.454 102.228 259.485 102.228 255.822C102.228 252.16 89.0605 249.191 72.8181 249.191C56.5757 249.191 43.4087 252.16 43.4087 255.822C43.4087 259.485 56.5757 262.454 72.8181 262.454Z"
        fill="#263238"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M92.1847 118.655V124.028L99.9965 127.608C99.9965 127.608 100.811 124.028 100.159 117.677L92.1847 118.655Z"
        fill="#999999"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M53.4123 247.583C51.6243 249.118 49.9349 250.765 48.3543 252.512C46.1063 255.047 45.6679 257.57 47.7586 258.779C49.8492 259.987 53.1313 259.672 56.7112 257.435C60.2912 255.199 60.145 254.299 62.2357 253.558C64.3263 252.816 65.6695 251.31 65.6695 248.927C65.6695 246.544 58.0544 246.24 56.4134 246.117C54.7723 245.993 53.4123 247.583 53.4123 247.583Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M65.6527 248.927C65.6527 248.927 62.2806 250.422 60.6452 251.782C59.0098 253.142 55.7502 258.037 51.9398 258.037C49.9994 258.038 48.0912 257.541 46.3985 256.592C46.401 257.045 46.5276 257.488 46.7644 257.873C47.0012 258.259 47.3393 258.572 47.7417 258.779C49.8323 259.97 53.1144 259.672 56.6944 257.435C60.2743 255.199 60.1282 254.299 62.2188 253.558C64.3095 252.816 65.6527 251.315 65.6527 248.927Z"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M91.2855 247.583C93.0735 249.118 94.7629 250.765 96.3435 252.512C98.5915 255.047 99.0298 257.57 96.9392 258.779C94.8486 259.987 91.5665 259.672 87.9865 257.435C84.4066 255.199 84.5527 254.299 82.4621 253.558C80.3714 252.816 79.0282 251.31 79.0282 248.927C79.0282 246.544 86.6433 246.24 88.2844 246.117C89.9254 245.993 91.2855 247.583 91.2855 247.583Z"
        fill="white"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M79.0451 248.927C79.0451 248.927 82.4171 250.422 84.0525 251.782C85.6879 253.142 88.9476 258.037 92.7579 258.037C94.6983 258.038 96.6066 257.541 98.2992 256.592C98.2968 257.045 98.1702 257.488 97.9334 257.873C97.6965 258.259 97.3585 258.572 96.9561 258.779C94.8654 259.97 91.5833 259.672 88.0034 257.435C84.4234 255.199 84.5696 254.299 82.4789 253.558C80.3883 252.816 79.0451 251.315 79.0451 248.927Z"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.0849 162.811C49.0849 162.811 49.9785 196.391 50.4281 201.32C50.8777 206.248 52.204 217.438 52.204 218.056C52.204 218.674 52.9458 243.43 52.9458 243.43L51.6026 245.678L52.0522 248.814L58.0207 250.006C61.0049 250.601 64.8883 251.501 65.484 250.905C66.0798 250.309 66.2315 249.112 66.5294 247.471C66.8272 245.83 67.4229 242.84 66.8272 240.457C66.2315 238.074 67.5747 212.695 67.7264 212.099C67.8782 211.503 71.312 186.989 71.312 186.989C71.312 186.989 73.56 207.142 75.1898 213.257C76.8196 219.371 76.2351 228.481 76.8308 232.061C77.4266 235.641 78.6236 247.735 78.7753 248.629C78.9271 249.522 79.2193 250.877 79.2193 250.877C79.2193 250.877 86.6827 249.831 88.0259 249.23C89.3877 248.728 90.7854 248.33 92.2071 248.039C92.2071 248.039 92.6511 239.53 92.0554 236.096C91.6213 233.683 91.3714 231.241 91.308 228.79C91.308 227.745 92.0554 194.615 92.0554 194.615L91.308 162.525C91.308 162.525 71.1602 164.615 63.399 163.121C55.6378 161.626 49.2366 160.732 49.2366 160.732L49.0849 162.811Z"
        fill="#35BD78"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M63.4103 163.109C55.649 161.62 49.2366 160.732 49.2366 160.732L49.0849 162.817C49.0849 162.817 49.1355 164.745 49.2198 167.735C52.7772 168.729 60.0045 170.477 66.9902 170.477C74.3749 170.477 86.1376 168.229 91.4316 167.156L91.3192 162.519C91.3192 162.519 71.1715 164.604 63.4103 163.109Z"
        fill="#263238"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M89.3353 238.732C89.4309 239.76 89.4927 240.351 89.4927 240.351"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M87.048 170.691C87.048 170.691 87.61 199.532 87.61 210.418C87.61 217.949 88.5204 229.38 89.0824 235.708"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56.8405 169.061L58.2005 200.353"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.9251 202.798V207.968"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M65.0007 83.9853C64.2659 83.9892 63.5426 84.1681 62.8908 84.5073C62.2389 84.8464 61.6772 85.336 61.2522 85.9354C60.3228 87.1618 59.2885 88.3051 58.1612 89.3524L44.8137 96.5179L46.1175 147.469L46.6065 164.891C46.6065 164.891 56.8574 168.308 66.6249 168.308C76.3925 168.308 93.8089 164.374 93.8089 164.374C93.8089 164.374 92.3477 96.4954 92.8366 96.4954C93.3255 96.4954 83.232 87.7282 78.6742 85.424C74.1164 83.1198 65.0007 83.9853 65.0007 83.9853Z"
        fill="#35BD78"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M61.196 90.1448C61.196 90.1448 66.3664 99.6707 68.5469 104.026C70.7275 108.382 74.7964 114.648 74.7964 114.648L81.6022 96.4167"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M65.0176 150.565L64.7535 142.962L49.394 142.068C49.394 142.068 49.5457 153.263 49.8436 153.87"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M65.203 155.949L65.13 153.814"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M83.4118 154.752L82.9622 142.22C82.9622 142.22 88.335 142.664 92.8141 139.679"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60.3811 112.355C60.8532 113.844 62.2919 114.721 63.5901 114.305C64.8883 113.889 65.5459 112.344 65.0682 110.854C64.5905 109.365 63.1574 108.488 61.8592 108.904C60.5609 109.32 59.9034 110.866 60.3811 112.355Z"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60.5328 86.2782C60.5328 86.2782 58.8075 92.9098 62.7303 110.911"
        stroke="#263238"
        strokeWidth="1.124"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M63.9385 111.529C63.8229 111.762 63.6428 111.957 63.4195 112.091C63.1963 112.225 62.9394 112.292 62.6792 112.284C62.4191 112.276 62.1666 112.194 61.9518 112.047C61.7371 111.9 61.569 111.694 61.4676 111.455C61.3662 111.215 61.3357 110.951 61.3797 110.695C61.4237 110.438 61.5404 110.199 61.716 110.007C61.8915 109.815 62.1185 109.677 62.37 109.61C62.6216 109.543 62.887 109.55 63.1349 109.629C63.4919 109.776 63.7765 110.058 63.927 110.414C64.0774 110.77 64.0816 111.17 63.9385 111.529Z"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M92.1847 95.54C92.1847 95.54 94.6238 97.3328 94.7867 97.9847C94.9497 98.6366 103.25 118.492 103.25 118.492L91.2068 120.268L91.3698 108.545"
        fill="#35BD78"
      />
      <path
        d="M92.1847 95.54C92.1847 95.54 94.6238 97.3328 94.7867 97.9847C94.9497 98.6366 103.25 118.492 103.25 118.492L91.2068 120.268L91.3698 108.545"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.4435 95.866C46.4435 95.866 43.6728 96.1919 43.0209 98.305C42.369 100.418 37.6538 121.909 37.6538 121.909L54.9072 120.268L53.2774 104.15"
        fill="#35BD78"
      />
      <path
        d="M46.4435 95.866C46.4435 95.866 43.6728 96.1919 43.0209 98.305C42.369 100.418 37.6538 121.909 37.6538 121.909L54.9072 120.268L53.2774 104.15"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M79.7701 85.2667C79.7701 85.2667 84.0469 87.7057 84.4684 93.7753C84.8899 99.8449 84.4684 101.385 84.4684 101.385"
        stroke="#263238"
        strokeWidth="1.124"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M86.4017 109.157C86.4017 109.157 86.0195 116.334 85.2383 118.408C84.4572 120.482 81.3662 122.937 78.9158 123.331"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M80.866 108.382C80.866 108.382 80.5232 116.66 80.4894 118.2C80.4557 119.74 82.0069 122.42 84.2324 124.989"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M84.8706 125.89L84.9361 125.837C85.2423 125.589 85.2892 125.14 85.0409 124.833L84.7259 124.445C84.4776 124.139 84.0281 124.092 83.722 124.34L83.6565 124.393C83.3503 124.642 83.3034 125.091 83.5517 125.397L83.8667 125.786C84.115 126.092 84.5645 126.139 84.8706 125.89Z"
        fill="#263238"
      />
      <path
        d="M78.0359 123.593L78.0516 123.676C78.125 124.063 78.4985 124.318 78.8858 124.244L79.3772 124.151C79.7645 124.078 80.019 123.704 79.9456 123.317L79.9299 123.234C79.8565 122.847 79.4831 122.593 79.0958 122.666L78.6043 122.759C78.217 122.833 77.9626 123.206 78.0359 123.593Z"
        fill="#263238"
      />
      <path
        d="M86.486 109.702L86.323 103.672C86.3078 103.043 86.0587 102.442 85.6243 101.987C85.1899 101.532 84.6015 101.255 83.9738 101.211C83.3072 101.161 82.6481 101.377 82.1405 101.812C81.6329 102.247 81.3181 102.865 81.265 103.532L80.7592 109.646"
        stroke="#263238"
        strokeWidth="1.124"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M100.485 123.539L96.602 122.724L82.9285 120.448L63.7531 116.795L59.2065 114.788C59.2065 114.788 54.9072 109.168 54.5363 108.606C54.1654 108.044 49.3041 110.006 49.4783 110.753C49.6525 111.501 50.2257 118.97 50.6023 119.442C50.9788 119.914 55.177 119.908 55.177 119.908C55.177 119.908 75.2067 129.575 76.0384 129.929C87.5988 134.801 93.1738 134.756 95.1464 135.077C97.119 135.397 98.406 134.599 99.5412 133.295C100.676 131.991 100.485 123.539 100.485 123.539Z"
        fill="#999999"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M53.9518 108.528C53.9518 108.528 51.6195 108.623 48.1632 110.956C46.8762 111.827 46.2018 112.451 46.2018 112.827C46.2755 113.275 46.4019 113.713 46.5784 114.131C48.8192 113.052 51.1659 112.208 53.5809 111.613"
        fill="#999999"
      />
      <path
        d="M53.9518 108.528C53.9518 108.528 51.6195 108.623 48.1632 110.956C46.8762 111.827 46.2018 112.451 46.2018 112.827C46.2755 113.275 46.4019 113.713 46.5784 114.131C48.8192 113.052 51.1659 112.208 53.5809 111.613"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M54.8847 114.508C54.8847 114.508 48.5397 116.092 47.5113 116.283C46.4828 116.475 46.2974 115.44 46.2974 115.07C46.2974 114.699 46.5784 114.131 47.6967 113.575C48.8151 113.018 54.4407 111.518 54.4407 111.518"
        fill="#999999"
      />
      <path
        d="M54.8847 114.508C54.8847 114.508 48.5397 116.092 47.5113 116.283C46.4828 116.475 46.2974 115.44 46.2974 115.07C46.2974 114.699 46.5784 114.131 47.6967 113.575C48.8151 113.018 54.4407 111.518 54.4407 111.518"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M55.5423 117.402C55.5423 117.402 48.1632 118.149 47.6012 117.683C47.4292 117.496 47.2988 117.274 47.2184 117.033C47.138 116.792 47.1095 116.536 47.1347 116.283L53.7663 114.878"
        fill="#999999"
      />
      <path
        d="M55.5423 117.402C55.5423 117.402 48.1632 118.149 47.6012 117.683C47.4292 117.496 47.2988 117.274 47.2184 117.033C47.138 116.792 47.1095 116.536 47.1347 116.283L53.7663 114.878"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M53.4853 117.773L47.7923 118.054C47.7923 118.054 48.0677 118.897 48.3543 118.987C49.703 119.32 51.0772 119.54 52.4625 119.644C53.3709 119.674 54.2761 119.768 55.1713 119.925"
        fill="#999999"
      />
      <path
        d="M53.4853 117.773L47.7923 118.054C47.7923 118.054 48.0677 118.897 48.3543 118.987C49.703 119.32 51.0772 119.54 52.4625 119.644C53.3709 119.674 54.2761 119.768 55.1713 119.925"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.2337 121.583C41.2337 121.583 43.8358 132.823 44.4877 133.632C45.1396 134.442 50.5124 133.632 55.8851 132.003C61.2578 130.373 84.0469 120.611 84.0469 120.611L87.3009 121.257C87.3009 121.257 85.9971 117.188 83.721 117.025C81.4449 116.862 54.9072 120.268 54.9072 120.268L41.2337 121.583Z"
        fill="#999999"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M64.006 76.8198C64.006 76.8198 64.006 77.0615 64.006 77.4717C64.0566 79.6129 64.2645 86.4131 64.8096 88.0542C65.4616 90.0043 74.2512 106.448 74.9256 105.145C75.6 103.841 79.6465 86.0984 79.6465 86.0984V79.1015C79.6465 79.1015 77.5277 80.8887 73.6218 80.8887C69.7159 80.8887 64.006 76.8198 64.006 76.8198Z"
        fill="#999999"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M77.0444 86.4637C77.9558 85.9071 78.8272 85.2874 79.6521 84.6091V79.1015C79.6521 79.1015 77.5333 80.8887 73.6274 80.8887C69.7215 80.8887 64.006 76.8198 64.006 76.8198C64.006 76.8198 64.006 77.0615 64.006 77.4717C64.006 78.2248 64.0622 79.5567 64.1296 81.0404C64.2838 81.2874 64.4566 81.5223 64.6467 81.7429C65.7707 83.0411 71.1546 86.3007 72.4585 86.6267C73.7623 86.9527 75.5776 87.273 77.0444 86.4637Z"
        fill="#263238"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M83.558 67.3782C83.558 67.3782 86.16 65.9114 86.16 68.682C86.16 71.4527 83.721 74.7067 83.721 74.7067L83.558 67.3782Z"
        fill="#999999"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60.2799 67.5412C60.2799 67.5412 56.6944 66.5633 56.6944 69.8229C56.6944 73.0825 59.954 75.19 59.954 75.19H62.202C62.202 75.19 63.5058 78.4496 64.6467 79.7478C65.7875 81.046 71.1546 84.3056 72.4585 84.6316C73.7623 84.9575 75.5495 85.2835 77.0163 84.4686C78.8592 83.3198 80.5573 81.9537 82.0743 80.3997C82.7262 79.5904 83.8671 74.0547 83.8671 74.0547C83.8671 74.0547 84.193 64.1242 83.5411 60.7072C82.8892 57.2903 78.6573 51.9176 72.7957 51.2656C66.934 50.6137 61.3983 55.3345 60.589 59.0774C59.7797 62.8204 60.2799 67.5412 60.2799 67.5412Z"
        fill="#999999"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M70.8568 70.1488C70.8568 70.1488 69.7328 72.4249 71.6717 73.0769C73.6106 73.7288 76.0665 73.5658 76.5554 72.5879C77.0444 71.61 76.2295 70.4748 76.2295 70.4748"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M68.9066 74.2177C68.9066 74.2177 72.9755 77.6347 78.5112 74.3807"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M71.1827 77.3087C71.1827 77.3087 74.6053 79.2645 76.8814 76.9828"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M69.2326 68.1931C69.6826 68.1931 70.0475 67.6093 70.0475 66.8892C70.0475 66.1691 69.6826 65.5854 69.2326 65.5854C68.7825 65.5854 68.4177 66.1691 68.4177 66.8892C68.4177 67.6093 68.7825 68.1931 69.2326 68.1931Z"
        fill="#263238"
      />
      <path
        d="M78.0223 66.7263C78.0223 67.4456 77.657 68.0301 77.2074 68.0301C76.7578 68.0301 76.3925 67.4681 76.3925 66.7263C76.3925 65.9844 76.7578 65.428 77.2074 65.428C77.657 65.428 78.0223 66.0069 78.0223 66.7263Z"
        fill="#263238"
      />
      <path
        d="M66.9509 65.1021C66.9509 65.1021 67.7658 63.7983 70.8849 64.2872"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M75.9035 64.4502C75.9035 64.4502 77.3704 62.1684 79.6521 64.4502"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M83.558 60.696C83.3465 59.7293 82.9803 58.8032 82.4733 57.9534C82.2837 58.8545 82.2175 59.7771 82.2766 60.696C82.5126 61.8874 83.35 63.5509 83.35 64.3827C83.35 65.2145 83.2264 68.5471 83.8221 68.075C83.8811 68.0127 83.9287 67.9404 83.9626 67.8615C83.9998 65.4664 83.8646 63.0717 83.558 60.696Z"
        fill="#263238"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M75.3752 53.952C76.4204 53.8966 77.4605 53.7708 78.4887 53.5755C76.8283 52.3324 74.8713 51.5458 72.8125 51.2937C66.9509 50.6418 61.4152 55.3626 60.6059 59.1055C59.7966 62.8485 60.2799 67.5693 60.2799 67.5693C60.2799 67.5693 61.5725 68.4685 62.0502 67.5187C62.5279 66.5689 62.0502 65.0178 62.5279 63.3543C63.0056 61.6907 63.7137 60.8534 63.8318 59.5439C63.9498 58.2344 63.118 56.3349 63.5957 55.2615C64.0734 54.188 67.0464 52.1705 68.9516 52.8842C71.0014 53.6606 73.1845 54.0235 75.3752 53.952Z"
        fill="#263238"
        stroke="#263238"
        strokeWidth="0.562"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
