import React from "react";
import {
  Container,
  ContainerFrialStartMedtrackScreen,
  Wrapper,
  Button,
} from "./style";
import { IconDetails } from "./IconDetails";
import { InfoBox } from "./InfoBox";
import { Banner } from "./Banner";
import { IoArrowForwardOutline } from "react-icons/io5";

export function FrialFirstAccess({ setShowFrialWelcomeScreen }) {
  function handleCloseWelcomeScreen() {
    setShowFrialWelcomeScreen(false);
  }

  return (
    <ContainerFrialStartMedtrackScreen>
      <Container>
        <Wrapper>
          <Banner />
          <IconDetails />
          <InfoBox />
          <Button onClick={handleCloseWelcomeScreen}>
            Continuar
            <IoArrowForwardOutline />
          </Button>
        </Wrapper>
      </Container>
    </ContainerFrialStartMedtrackScreen>
  );
}
