import { createGlobalStyle } from "styled-components";

export const HighlightColors = createGlobalStyle`
.blank-highlight {
  background-color: #acd2fe;
  cursor: pointer;
}

.yellow-highlight {
  background-color: #F7F161;
  cursor: pointer;
}

.red-highlight {
  background-color: #FF5B5B;
  color: #fff !important;
  cursor: pointer;
}

.green-highlight {
  background-color: #27ea8f;
  cursor: pointer;
}
`;